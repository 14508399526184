import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  numberAttribute,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AutotestAttributeDirective } from '../../../../autotests';
import { ErrorStateMatcher } from '../../../../models/game/services/error-state-matcher.service';
import { DigitOnlyDirective } from '../../../../modules/form/directives/digits-only/digits-only.directive';
import { ControlErrorDirective } from '../../../../modules/form-error/control-error.directive';
import { FormErrorModule } from '../../../form-error';
import { ErrorsMap } from '../../../form-error/types/errors-map.type';

@UntilDestroy()
@Component({
  selector: 'ultra-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, AutotestAttributeDirective, DigitOnlyDirective, FormErrorModule],
})
export class InputComponent implements OnInit {
  readonly id = `${Math.random()}`.replace('.', '');
  isPasswordVisible = false;

  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() type: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input({ transform: numberAttribute }) maxLength = 100;
  @Input() max: number;
  @Input() inputMode: string;
  @Input() iconSource: string;
  @Input() icon: string;
  @Input() step: number;
  @Input() cssClass: string;
  @Input() cssCountClass: string;
  /**
   * @deprecated use `control.disable()` instead.
   * @see https://github.com/angular/angular/issues/48350
   */
  @Input() readonly: boolean;
  @Input() canSeePassword = false;
  @Input() disabled: boolean;
  @Input() danger: boolean;
  @Input() control: UntypedFormControl = new UntypedFormControl(null);
  @Input() allowDigitsOnly = false;
  @Input() hideLength = true;
  @Input() ignoreFocusHandler = false;
  @Input() customErrors: ErrorsMap;
  @Input() inputErrorsStyle: string;
  @Input() matcher: ErrorStateMatcher = null;
  @Input() dataId: string;
  // Todo replace it in separate directive when apply form refactoring result in my-wallet.component
  @Input() isWalletAmount = false;
  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild('input', { read: ControlErrorDirective, static: true }) controlError: ControlErrorDirective;

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(@Optional() public parent: FormGroupDirective) {}

  ngOnInit(): void {
    if (this.max && !this.readonly) {
      this.listenChanges();
    }
  }

  // TODO: Move logic input password to field shopping cart
  showPassword(): void {
    this.isPasswordVisible = true;

    setTimeout(() => {
      this.isPasswordVisible = false;
      this.cdr.markForCheck();
    }, 1250);
  }

  get isEyeAvailable(): boolean {
    return (
      true === this.canSeePassword &&
      this.control.value !== null &&
      this.control.value.length > 0 &&
      false === this.isPasswordVisible
    );
  }

  listenChanges(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value < 0) {
        this.control.patchValue(0, { onlySelf: true });
      } else if (value > this.max) {
        this.control.patchValue(this.max, { onlySelf: true });
      }
      this.cdr.markForCheck();
    });
  }

  updateErrorState(rules: boolean): boolean {
    return this.matcher ? this.matcher.isErrorState(this.control, this.parent) : rules;
  }

  // Todo replace it in separate directive when new input will be used or new refactoring in my-wallet component will be implemented
  public keyDownHandler(e: KeyboardEvent): void {
    if (this.isWalletAmount) {
      const invalidChars = ['e', 'E', '+', '-'];
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
        return;
      }
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
        return;
      }

      const specialKeys: string[] = [
        'Backspace',
        'Ctrl',
        'Tab',
        'End',
        'Home',
        '-',
        'ArrowLeft',
        'ArrowRight',
        'Del',
        'Delete',
      ];
      if (specialKeys.indexOf(e.key) !== -1) {
        return;
      }
      const currentValue: string = this.input.nativeElement.value;
      const regex = new RegExp(/^\d*(\.\d{0,8})?$/g);
      const position = this.input.nativeElement.selectionStart;
      const next: string = [
        currentValue.slice(0, position),
        e.key === 'Decimal' ? '.' : e.key,
        currentValue.slice(position),
      ].join('');
      if (next && !regex.test(next)) {
        e.preventDefault();
        return;
      }
    }
  }

  pasteHandler(e: ClipboardEvent): void {
    if (this.isWalletAmount) {
      const value = e.clipboardData.getData('Text');
      const pattern = new RegExp(/^\d*(\.\d{0,8})?$/g);
      if (!pattern.test(value)) {
        e.preventDefault();
      }
    }
  }
}
