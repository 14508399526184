<div class="actions-header">
  <span class="text-uppercase overline-medium text-white-alpha-7" i18n>{{ action }}</span>
  <i
    container="ultra-id-card-actions"
    [ngbTooltip]="actionsTooltipInfo"
    tooltipClass="z-infinite"
    class="d-inline-block bg-white-alpha-7 icon-info"></i>
</div>
<div
  class="actions-content"
  [class.justify-content-center]="
    !revenueData ||
    (displayState?.status === actionStatuses.UNABLE && !displayState?.hasEndDate) ||
    displayState?.status === actionStatuses.DONE
  ">
  <ultra-id-card-actions-title
    [displayState]="displayState"
    [action]="action"
    ultraAutotestAttribute="card-action-container"
    [startDate]="startDate?.onSpecificDay" />

  <div class="info-wrap">
    <div class="info" [class.p-0]="displayState?.status === actionStatuses.ABLE || displayState?.isLocked">
      @if (startDate?.onSpecificDay) {
        <ultra-actions-period-text [date]="startDate.onSpecificDay" [displayState]="displayState" dateType="starting" />
      } @else {
        <div><!--empty column--></div>
      }

      <ultra-actions-gauge-state [displayState]="displayState" [availability]="availability" />

      @if (
        displayState.hasEndDate ||
        (!displayState.hasEndDate && displayState.status === actionStatuses.ABLE) ||
        (!displayState.hasEndDate && displayState.status === actionStatuses.PENDING)
      ) {
        <ultra-actions-period-text
          [date]="endDate?.onSpecificDay"
          [startDate]="startDate?.onSpecificDay"
          [displayState]="displayState"
          dateType="ending"
          [action]="action" />
      } @else {
        <div><!--empty column--></div>
      }
    </div>

    @if (
      revenueData &&
      displayState?.status !== actionStatuses.DONE &&
      (displayState?.status !== actionStatuses.UNABLE || displayState?.hasStartDate || displayState?.hasEndDate)
    ) {
      <ultra-tradability-resale [revenueData]="revenueData" />
    }
  </div>
</div>
