export interface IRequirement {
  os: string;
  processor: string;
  memory: string;
  graphics: string[] | string;
  storage: string;
  soundCard: string;
  minimumResolution: string;
}

export class Requirement implements IRequirement {
  os: string;
  processor: string;
  memory: string;
  graphics: string[] | string;
  storage: string;
  soundCard: string;
  minimumResolution: string;

  constructor(req: Partial<IRequirement>) {
    this.os = req.os || '';
    this.processor = req.processor || '';
    this.memory = req.memory || '';
    this.graphics = req.graphics || [];
    this.storage = req.storage || '';
    this.soundCard = req.soundCard || '';
    this.minimumResolution = req.minimumResolution || '';
  }
}
