<div
  class="item d-flex item-app cursor-pointer justify-content-center"
  [ngClass]="{
    active: app.active,
    disabled: app.disabled
  }"
  (click)="activate()"
>
  <div class="item__icon-container d-flex flex-column justify-content-center">
    <div class="icon" [ngbTooltip]="app.title" tooltipClass="item__tooltip" placement="bottom-right" container="body">
      <a
        class="btn position-relative"
        ultraAutotestAttribute="item-app-link"
        [ngStyle]="{
          'background-image': 'url(' + app.iconApp + ') '
        }"
      >
      </a>
    </div>
  </div>
</div>
