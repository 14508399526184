import { Observable } from 'rxjs';

import { StorageType, UltraosApiService } from '../ultraos/ultraos-api.service';

import { IStorageService } from './interfaces/storage.interface';

export class ClientStorageService implements IStorageService {
  constructor(private ultraosApiService: UltraosApiService) {}

  set(key: string, value: string, storageType: StorageType = 'persistent'): Observable<void> {
    return this.ultraosApiService.setStorageData(key, value, storageType);
  }
  get(key: string, storageType: StorageType = 'persistent'): Observable<string> {
    return this.ultraosApiService.getStorageData(key, storageType);
  }
  setObject<T>(key: string, value: T, storageType: StorageType = 'persistent'): Observable<void> {
    return this.ultraosApiService.setStorageData(key, value, storageType);
  }
  getObject<T>(key: string, storageType: StorageType = 'persistent'): Observable<T> {
    return this.ultraosApiService.getStorageData(key, storageType);
  }
  remove(key: string, storageType: StorageType = 'persistent'): Observable<void> {
    return this.ultraosApiService.removeStorageData(key, storageType);
  }
}
