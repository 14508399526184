import { PlatformModule } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';

import { CoreModule } from '@ultra/core';
import { UserState } from '@ultra/core/stores';
import { LicenseManageModule } from '@ultra/shared';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UniverseNavComponent } from './universe-nav/universe-nav.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    LicenseManageModule,
    UniverseNavComponent,
    PlatformModule,
    NgxsModule.forRoot([UserState], { compatibility: { strictContentSecurityPolicy: true } }),
    CoreModule.withUserStore(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
