import { OAuthModuleConfig } from 'angular-oauth2-oidc';

import { EnvironmentConfig } from '@ultra/environment';

const authConfigFactory = (envConfig: EnvironmentConfig): OAuthModuleConfig => {
  return {
    resourceServer: {
      allowedUrls: [envConfig.BASE_PATH_GRAPH, envConfig.OAUTH_URL],
      sendAccessToken: true,
    },
  };
};

export const AUTH_MODULE_CONFIG_PROVIDER = {
  provide: OAuthModuleConfig,
  useFactory: authConfigFactory,
  deps: [EnvironmentConfig],
};
