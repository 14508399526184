import { AppId, DimensionTitle, IDimension, IDimensionApp } from '@ultra/core/models';
import { IUserRoles, UserResourceAccess } from '@ultra/core/permissions';

export const isGameStoreApp = (app: AppId) => app === AppId.ULTRA_GAMES;
export const isMarketplaceApp = (app: AppId) => app === AppId.ULTRA_UNIQ_MARKETPLACE;
export const isWalletApp = (app: AppId) => app === AppId.ULTRA_WALLET;
export const isGDCApp = (app: AppId) => app === AppId.GAME_DEV_CENTER;

/**
 * Set a disabled object
 */
export const disableAppsByUserRoles = (roles: IUserRoles): Partial<Record<AppId, boolean>> => {
  return {
    [AppId.ULTRA_GAMES]: !(roles.gameStoreUser || roles.gdcAdmin),
    [AppId.ULTRA_UNIQ_MARKETPLACE]: !(roles.marketUser || roles.marketAdmin),
    [AppId.ULTRA_WALLET]: !(roles.walletUser || roles.walletAdmin),
    [AppId.MASTER_CENTER]: !roles.ultraAdmin,
    [AppId.GAME_DEV_CENTER]: !(roles.companyMember || roles.gdcAdmin || roles.ultraAdmin),
    [AppId.ULTRA_HELP]: !(roles.companyMember || roles.gdcAdmin || roles.ultraAdmin),
  };
};

export const disableAppsByResourceAccess = (resourceAccess: UserResourceAccess): Partial<Record<AppId, boolean>> => {
  return {
    [AppId.ULTRA_ARENA]: !(resourceAccess?.arena?.roles.length > 0),
  };
};

/**
 * Check if given dimension is available for the roles the user has.
 */
export const isDimensionAvailable = (dimension: IDimension, userRoles: IUserRoles): boolean => {
  switch (dimension.title) {
    case DimensionTitle.COMPANY: {
      return userRoles.companyMember || userRoles.ultraAdmin || userRoles.gdcAdmin;
    }
    case DimensionTitle.ULTRA: {
      return userRoles.ultraAdmin;
    }
    default: {
      return true;
    }
  }
};

export const filterDimensionsByUserRolesAndResourceAccess = (
  dimensions: IDimension[],
  roles: IUserRoles,
  resourceAccess: UserResourceAccess,
): IDimension[] => {
  const disabledApps = {
    ...disableAppsByUserRoles(roles),
    ...disableAppsByResourceAccess(resourceAccess),
  };

  return dimensions.reduce((acc, dimension) => {
    if (isDimensionAvailable(dimension, roles)) {
      dimension.apps = dimension.apps.map((app) => {
        const disabled = disabledApps[app.id];
        return { ...app, disabled, active: false };
      });
      acc.push(dimension);
    }
    return acc;
  }, []);
};

export const findAppInDimension = (dimension: IDimension, appId: string): IDimensionApp => {
  return dimension.apps.find((app: IDimensionApp) => app.id === appId);
};

export const trackedApps: AppId[] = [
  AppId.ULTRA_ARENA,
  AppId.ULTRA_GAMES,
  AppId.ULTRA_UNIQ_MARKETPLACE,
  AppId.ULTRA_WALLET,
];
