import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ultra-game-detail-tags-modal',
  templateUrl: './game-detail-tags-modal.component.html',
  styleUrls: ['./game-detail-tags-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailTagsModalComponent {
  activeModal = inject(NgbActiveModal);
  tags: string[] = [];
}
