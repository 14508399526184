<ul class="tab-header {{ tabHeaderClass }}">
  <ng-content select=".divider"></ng-content>
  @for (tab of tabs; track tab; let index = $index) {
    <li
      class="tab-nav position-relative"
      [class.flex-fill]="fullTabWith"
      [class.tab-nav--same-width]="sameTabWidth"
      [class.tab-nav--disabled]="tab.disabled"
      (click)="select(index)">
      <div
        class="tab-nav-content"
        [class.tab-nav-content--selected]="tab.id === selectedId"
        [class.tab-nav-content--min-size]="tabNavMinSize">
        @if (tab.templateLabel) {
          <ng-template [cdkPortalOutlet]="tab.templateLabel"></ng-template>
        }
        @if (!tab.templateLabel) {
          {{ tab.label }}
        }
      </div>
      @if (0 === index) {
        <span
          class="tab-nav-indicator d-block w-100 position-absolute bg-primary"
          [class.tab-nav-indicator--bottom]="tabIndicator === 'bellow'"
          [class.tab-nav-indicator--thick]="indicatorSize === 'thick'"
          [style.transform]="transformNav()"></span>
      }
    </li>
  }
</ul>
<div
  class="tab-body {{ fixedHeight ? 'tab-body--with-height' : 'tab-body--no-height' }}"
  [class.tab-body--no-padding]="!padContent">
  @for (tab of tabs; track tab; let index = $index) {
    @if (index === selectedIndex) {
      <div [@inOutAnimation]>
        <ng-template [cdkPortalOutlet]="tab.content"></ng-template>
      </div>
    }
  }
</div>
