<div class="w-100 uniq-wrap d-flex position-relative rounded">
  @if (showBackground) {
    <div class="backdrop-image" [ngStyle]="{ 'background-image': getBackgroundImage() }"></div>
  }
  <div class="rounded w-100">
    <ultra-id-card-content-header
      [idCardEntity]="idCardEntity"
      [config]="idCardContentHeaderConfig"
      (closed)="closeItem()"></ultra-id-card-content-header>
    <div class="divider d-flex-center">
      <div class="d-flex">
        <ultra-uniq-type-icon
          [type]="idCardEntity?.content?.contentType?.toLowerCase()"
          showOrnament="true"
          opacity="70"></ultra-uniq-type-icon>
      </div>
    </div>
    <ultra-tab-group
      tabHeaderClass="uniq-preview-body-tab-header"
      headerPosition="below"
      [padContent]="false"
      tabIndicator="bellow"
      indicatorSize="thick"
      (tabChange)="onTabChange($event)">
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span ultraAutotestAttribute="token-preview-overview-tab">Overview</span>
        </ng-template>
        <ng-template ultraTabContent>
          <ultra-id-card-overview [idCardEntity]="idCardEntity"></ultra-id-card-overview>
        </ng-template>
      </ultra-tab>
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span ultraAutotestAttribute="token-preview-preview-tab">Gallery</span>
        </ng-template>
        <ng-template ultraTabContent>
          <ultra-media-carousel
            [multiMedia]="idCardEntity?.medias?.imageGalleryList"
            theme="uniq-factory-detail"
            [expandable]="true"></ultra-media-carousel>
        </ng-template>
      </ultra-tab>
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span ultraAutotestAttribute="token-preview-properties-tab">Properties</span>
        </ng-template>
        <ng-template ultraTabContent>
          <ultra-id-card-properties
            [idCardEntity]="idCardEntity"
            [showTradability]="true"
            [showTransferability]="true"
            [showGeofencing]="false" />
        </ng-template>
      </ultra-tab>
    </ultra-tab-group>

    <div class="d-flex position-absolute preview-content-actions">
      <ultra-id-card-quantity-status
        class="me-2"
        [quantityAvailability]="idCardEntity?.quantity"
        (click)="handlePropertyQuickAccess(properties.QUANTITY)">
      </ultra-id-card-quantity-status>
      <ultra-actions-status
        class="me-2"
        [period]="idCardEntity?.tradingPeriod"
        [availability]="idCardEntity?.tradingPeriod?.resaleAvailability"
        (click)="handlePropertyQuickAccess(properties.TRADABILITY)"
        [action]="actions.TRADABILITY">
      </ultra-actions-status>
      <ultra-actions-status
        class="me-2"
        [period]="idCardEntity?.transferPeriod"
        [availability]="idCardEntity?.transferPeriod?.transferAvailability"
        (click)="handlePropertyQuickAccess(properties.TRANSFERABILITY)"
        [action]="actions.TRANSFERABILITY">
      </ultra-actions-status>
      @if (showPaymentButton) {
        @if (uniqFactory?.prices; as prices) {
          <span>
            @if (idCardEntity?.quantity?.quantityLeft !== '0') {
              <span
                class="price price--purple price__currency d-inline-block ms-1 rounded d-flex d-flex-center"
                [class.price__free]="prices?.[0]?.amount | isFree"
                ultraAutotestAttribute="uniq-overview-price-button"
                (click)="buy.emit(uniqFactory)">
                <span class="price__text-active">Buy Now</span>
                <ultra-uniq-price
                  type="uniqfactory"
                  [symbol]="prices[0]?.currency?.symbol"
                  [price]="prices[0]?.amount" />
              </span>
            } @else {
              <span
                class="price price--disabled d-inline-block ms-1 rounded d-flex d-flex-center"
                i18n
                (click)="$event.stopPropagation()"
                >Sold Out
              </span>
            }
          </span>
        }
      }
    </div>
  </div>
</div>
