import { gql } from 'apollo-angular';

import {
  UNIQ_FACTORY_METADATA_FIELDS,
  UNIQ_FACTORY_METADATA_FRAGMENTS,
  UNIQ_FACTORY_METADATA_MEDIAS,
} from '../../gql/shared/queries/uniq-metadata-shared.query';
import { ACCOUNT, CURRENCY, PURCHASE_UNIQ_FACTORY, REVENUE } from '../../gql/shared/queries/uniq-shared.query';

export const UNIQ_FACTORY = gql`
{
  id
  onChainId
  type
  name
  subName
  createdAt
  description
  medias ${UNIQ_FACTORY_METADATA_MEDIAS}
  creator ${ACCOUNT}
  geofencing
  status
  hidden
  prices {
    currency ${CURRENCY}
    amount
  }
  transferability {
    enabled
    transferStartDate {
      onSpecificDay
      afterNumberDays
    }
    transferEndDate {
      onSpecificDay
      afterNumberDays
    }
    transferPeriod {
      mintDate
      startDate
      endDate
      duration
      transferAvailability {
        transferDate
        isLocked
        duration
        durationRate
      }
    }
  }
  tradability {
    enabled
    minimumResellPrice {
     currency ${CURRENCY}
     amount
    }
    resellRevenueShare {
      percentage
      account ${ACCOUNT}
    }
    tradingStartDate {
      onSpecificDay
      afterNumberDays
    }
    tradingEndDate {
      onSpecificDay
      afterNumberDays
    }
    tradingPeriod {
      startDate
      endDate
      duration
      resaleAvailability {
        resaleDate
        isLocked
        duration
        durationRate
        revenue ${REVENUE}
      }
    }
  }
  quantity {
    maxMintableTokens
    quantityLeft
    existing
    minted
    reserved
    maxCirculatingSupply
  }
  metadata ${UNIQ_FACTORY_METADATA_FIELDS}
}
`;

export const UNIQ_FACTORY_WITH_REVENUE = gql`
{
  id
  onChainId
  type
  name
  subName
  description
  medias ${UNIQ_FACTORY_METADATA_MEDIAS}
  creator ${ACCOUNT}
  geofencing
  status
  prices {
    currency ${CURRENCY}
    amount
  }
  transferability {
    enabled
    transferStartDate {
      onSpecificDay
      afterNumberDays
    }
    transferEndDate {
      onSpecificDay
      afterNumberDays
    }
    transferPeriod {
      mintDate
      startDate
      endDate
      duration
      transferAvailability {
        transferDate
        isLocked
        duration
        durationRate
      }
    }
  }
  tradability {
    enabled
    minimumResellPrice {
     currency ${CURRENCY}
     amount
    }
    resellRevenueShare {
      percentage
      account ${ACCOUNT}
    }
    tradingStartDate {
      onSpecificDay
      afterNumberDays
    }
    tradingEndDate {
      onSpecificDay
      afterNumberDays
    }
    tradingPeriod {
      startDate
      endDate
      duration
      resaleAvailability {
        resaleDate
        isLocked
        duration
        durationRate
        revenue(price: $price) ${REVENUE}
      }
    }
  }
  quantity {
    maxMintableTokens
    quantityLeft
    existing
    minted
    reserved
    maxCirculatingSupply
  }
}
`;

export const UNIQ_FACTORY_FOR_SALE_UNIQ = gql`
{
  id
  onChainId
  type
  name
  subName
  createdAt
  description
  medias ${UNIQ_FACTORY_METADATA_MEDIAS}
  creator ${ACCOUNT}
  geofencing
  status
  transferability {
    enabled
    transferStartDate {
      onSpecificDay
      afterNumberDays
    }
    transferEndDate {
      onSpecificDay
      afterNumberDays
    }
    transferPeriod {
      mintDate
      startDate
      endDate
      duration
      transferAvailability {
        transferDate
        isLocked
        duration
        durationRate
      }
    }
  }
  tradability {
    enabled
    minimumResellPrice {
     currency ${CURRENCY}
     amount
    }
    resellRevenueShare {
      percentage
      account ${ACCOUNT}
    }
    tradingStartDate {
      onSpecificDay
      afterNumberDays
    }
    tradingEndDate {
      onSpecificDay
      afterNumberDays
    }
    tradingPeriod {
      startDate
      endDate
      duration
      resaleAvailability {
        resaleDate
        isLocked
        duration
        durationRate
      }
    }
  }
  quantity {
    maxMintableTokens
    quantityLeft
    existing
    minted
    reserved
    maxCirculatingSupply
  }
  metadata ${UNIQ_FACTORY_METADATA_FIELDS}
}
`;

export const UNIQ_FACTORY_WITH_FILTERED_PRICE = gql`
{
  id
  onChainId
  type
  name
  subName
  description
  medias ${UNIQ_FACTORY_METADATA_MEDIAS}
  creator ${ACCOUNT}
  geofencing
  status
  metadata ${UNIQ_FACTORY_METADATA_FIELDS}
  prices(currencyCodes: $currencyCodes) {
    currency ${CURRENCY}
    amount
  }
  transferability {
    enabled
    transferStartDate {
      onSpecificDay
      afterNumberDays
    }
    transferEndDate {
      onSpecificDay
      afterNumberDays
    }
    transferPeriod {
      mintDate
      startDate
      endDate
      duration
      transferAvailability {
        transferDate
        isLocked
        duration
        durationRate
      }
    }
  }
  tradability {
    enabled
    minimumResellPrice {
     currency ${CURRENCY}
     amount
    }
    resellRevenueShare {
      percentage
      account ${ACCOUNT}
    }
    tradingStartDate {
      onSpecificDay
      afterNumberDays
    }
    tradingEndDate {
      onSpecificDay
      afterNumberDays
    }
    tradingPeriod {
      startDate
      endDate
      duration
      resaleAvailability {
        resaleDate
        isLocked
        duration
        durationRate
      }
    }
  }
  quantity {
    maxMintableTokens
    quantityLeft
    existing
    minted
    reserved
    maxCirculatingSupply
  }
  hidden
}
`;

export const UNIQ_FACTORIES_QUERY = gql`
  query ($filter: UniqFactoryFilterInput, $pagination: PaginationInput) {
    uniqFactory {
      list(filter: $filter, pagination: $pagination) {
        data ${UNIQ_FACTORY}
        totalCount
        pagination {
          limit
          skip
        }
      }
    }
  }
  ${UNIQ_FACTORY_METADATA_FRAGMENTS}
`;

export const GET_UNIQ_FACTORY_QUERY = gql`
  query uniqFactory($id: EntityOnOrOffChainId!, $currencyCodes: [String!]) {
    uniqFactory {
      find(id: $id) ${PURCHASE_UNIQ_FACTORY}
    }
  }
  ${UNIQ_FACTORY_METADATA_FRAGMENTS}
`;

export const GET_FULL_UNIQ_FACTORY_QUERY = gql`
  query uniqFactory($id: EntityOnOrOffChainId!) {
    uniqFactory {
      find(id: $id) ${UNIQ_FACTORY}
    }
  }
  ${UNIQ_FACTORY_METADATA_FRAGMENTS}
`;

export const UNIQ_FACTORIES_RECOMMENDATION_QUERY = gql`
  query uniqFactory($name: StaticCollectionName, $currencyCodes: [String!]!) {
    uniqFactory {
      recommendation(name: $name) {
        uniqFactories ${UNIQ_FACTORY_WITH_FILTERED_PRICE}
        title
      }
    }
  }
  ${UNIQ_FACTORY_METADATA_FRAGMENTS}
`;

export const UNIQ_FACTORIES_STATIC_COLLECTION_QUERY = gql`
  query staticCollection($type: StaticCollectionType!, $name: StaticCollectionName!) {
    staticCollection {
      getByName(type: $type, name: $name) {
        _id
        _v
        name
        type
        title
        itemIds
      }
    }
  }
`;

export const UNIQ_FACTORY_WITH_REVENUE_QUERY = gql`
  query uniqFactory($id: EntityOnOrOffChainId!, $price: ResellPriceInput) {
    uniqFactory {
      find(id: $id) ${UNIQ_FACTORY_WITH_REVENUE}
    }
  }
`;

export const UNIQ_SALE_FEE_QUERY = gql`
  query uniqFactory($id: EntityOnOrOffChainId!, $price: ResellPriceInput) {
    uniqFactory {
      find(id: $id) {
        id
        tradability {
          tradingPeriod {
            resaleAvailability {
              revenue(price: $price) ${REVENUE}
            }
          }
        }
      }
    }
  }
`;
