import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, startWith } from 'rxjs';

import { AutotestAttributeDirective } from '../../../autotests/autotest-attribute.directive';
import { UltraValidators } from '../../../services';
import { FormErrorModule } from '../../form-error';
import { TagModel } from '../../tags-input/models/tag-model';
import { TagsInputModule } from '../../tags-input/tags-input.module';
import { UniqFactorySelectorService } from '../uniq-factory-selector.service';

/***
 * A component that presents to the user a tag input field to insert Uniq Factory onChainIds
 * and a search button.
 */
@UntilDestroy()
@Component({
  selector: 'ultra-uniq-factory-selector-form',
  templateUrl: './uniq-factory-selector-form.component.html',
  styleUrls: ['./uniq-factory-selector-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TagsInputModule, ReactiveFormsModule, FormErrorModule, AutotestAttributeDirective],
})
export class UniqFactorySelectorFormComponent {
  private _idsMaxCount = 18;
  @Input()
  get idsMaxCount(): number {
    return this._idsMaxCount;
  }
  set idsMaxCount(val: number) {
    this._idsMaxCount = val;
    if (this.form) {
      const idsControl = this.form.controls['ids'];
      idsControl.setValidators([UltraValidators.onChainId, UltraValidators.tagMaxLength(this.idsMaxCount)]);
      idsControl.updateValueAndValidity();
      this.cd.markForCheck();
    }
  }
  /**
   * Useful when user has no privileges to change results.
   * For instance non admin users.
   */
  @Input() readonlyMode = false;

  @Input() tagInputPlaceHolder = 'Enter Uniq Factory onChainIds here';

  @Output() search: EventEmitter<string[]> = new EventEmitter<string[]>();

  form: FormGroup = new FormGroup({
    ids: new FormControl<TagModel[]>([], [UltraValidators.onChainId, UltraValidators.tagMaxLength(this.idsMaxCount)]),
  });

  private uniqFactorySelectorService: UniqFactorySelectorService = inject(UniqFactorySelectorService);
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  /**
   * Loading when there are onChainIds that are loading in the notification array.
   */
  loading$ = this.uniqFactorySelectorService.loadingUniqFactoryIds$.pipe(
    startWith([]),
    map((ids) => ids.length > 0),
  );

  submit() {
    if (this.form.status === 'VALID') {
      const ids = this.selectorOnChainIds.map((item) => item.value);
      this.uniqFactorySelectorService.addUniqFactoryIdsToSearch(ids);
      this.form.get('ids').setValue([]);
      this.search.emit(ids);
    }
  }

  checkDuplicates(tag: TagModel) {
    const resultIds = this.uniqFactorySelectorService.resultUniqFactoryIds.value;
    const isDuplicated = resultIds.includes(tag.value);
    if (isDuplicated) {
      this.uniqFactorySelectorService.addDuplicateUniqFactoryId(tag.value);
      this.form.patchValue({
        ids: this.selectorOnChainIds.filter((item) => item.value !== tag.value),
      });
    }
  }

  get selectorOnChainIds() {
    return this.form.get('ids').value as TagModel[];
  }

  get searchDisabled(): boolean {
    return this.readonlyMode || this.form.invalid || this.form.pristine || this.selectorOnChainIds.length === 0;
  }
}
