import { Component, ContentChild, HostBinding, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

import { INotification, NotificationType } from './notification.interface';

@Component({
  selector: 'ultra-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationMessageComponent {
  @Input()
  get message(): string {
    return this._message;
  }

  set message(val: string) {
    this._message = val;
  }

  @Input()
  get type(): NotificationType {
    return this._type;
  }

  set type(val: NotificationType) {
    this._type = val;
    this.classes = `notification-${this._type}`;
  }

  get settings(): INotification {
    return {
      type: this._type,
      message: this._message,
    };
  }
  private _message: string;
  private _type: NotificationType;

  @HostBinding('class')
  classes;

  @ContentChild(TemplateRef)
  template;
}
