export enum RenewRoutineEventStatusCode {
  SUCCESS,
  UNKNOWN_ERROR,
  RENEW_THRESHOLD_ERROR,
}

export interface RenewRoutineEventResponse {
  response: string[];
  status: boolean;
  status_code: RenewRoutineEventStatusCode;
  status_message: string;
}
