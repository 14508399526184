import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { ApiGraphQLService } from '@ultra/core/graphql';
import { getGraphQLErrorMessage } from '@ultra/core/helpers';

import { GameFactoryGqlService } from '../../game-factory/graphql/game-factory-gql.service';
import { ApiStoreGqlConfig } from '../../graphql-client/api-store-gql.config';
import { CurrentUserGameAccount } from '../../models';
import { IGame } from '../../models/game/interfaces/game.interface';
import { IGameStoreStrategy } from '../../models/game/interfaces/game-store-strategy.interface';
import { IUserGamesWishlistId } from '../../models/game/interfaces/wishlist.interface';
import { Game } from '../../models/game/models/game.model';
import { GameDetail } from '../../models/game/models/game-detail.model';
import { GameHelper } from '../../services/helpers/game.helper';

@Injectable({
  providedIn: 'root',
})
export class GameGqlService implements IGameStoreStrategy {
  private gameFactoryGqlService = inject(GameFactoryGqlService);
  constructor(
    private apiService: ApiGraphQLService,
    private apollo: Apollo,
  ) {}

  getGameById(id: string): Observable<IGame> {
    return this.apiService.getQuery(ApiStoreGqlConfig.GAME_DETAIL, { id }, { cache: false }).pipe(
      map((result) => result.data.game),
      catchError((error) => of({ error: getGraphQLErrorMessage(error) })),
    );
  }

  getOwnedGameTokenFactories$(id: string): Observable<IGame> {
    return this.apiService.getQuery(ApiStoreGqlConfig.OWNED_GAME_TOKEN_FACTORIES, { id }, { cache: false }).pipe(
      map((result) => result.data.game),
      catchError((error) => of({ error: getGraphQLErrorMessage(error) })),
    );
  }

  getUserWishList$(): Observable<Game[]> {
    return this.apiService.getQuery(ApiStoreGqlConfig.WISHLIST_GAMES).pipe(
      map((result) => result.data.currentUserGameAccount),
      map((result) => {
        let games = result.gamesWishlist;
        games = games.map((game: Game) => {
          const wishGame = new Game(game);
          wishGame.favorite = true;
          return wishGame;
        });
        return games;
      }),
      take(1),
    );
  }

  addGameToWishList$(gameId: Game['id']): Observable<Game[]> {
    return this.apiService
      .createMutation(ApiStoreGqlConfig.ADD_GAME_TO_WISHLIST, { gameId })
      .pipe(
        switchMap((response) =>
          this.updateCacheAndReturnWishList$(response?.data?.userGameAccount?.addGameToWishList.gamesIdsWishlist || []),
        ),
      );
  }

  removeGameFromWishList$(gameId: Game['id']): Observable<Game[]> {
    return this.apiService
      .createMutation(ApiStoreGqlConfig.REMOVE_GAME_FROM_WISHLIST, { gameId })
      .pipe(
        switchMap((response) =>
          this.updateCacheAndReturnWishList$(
            response?.data?.userGameAccount?.removeGameFromWishList.gamesIdsWishlist || [],
            gameId,
          ),
        ),
      );
  }

  private updateCacheAndReturnWishList$(gamesIdsWishlist: string[], gameId?: string): Observable<Game[]> {
    const data = this.apollo.client.cache.readQuery<CurrentUserGameAccount>({
      query: ApiStoreGqlConfig.WISHLIST_GAMES,
    });
    const newData: CurrentUserGameAccount = {
      ...data,
      currentUserGameAccount: {
        ...data.currentUserGameAccount,
        gamesWishlist: [...data.currentUserGameAccount.gamesWishlist.filter((item) => item.id !== gameId)],
      },
    };
    const gamesIdsToFetch = gamesIdsWishlist.filter((item) =>
      newData.currentUserGameAccount.gamesWishlist.every((i) => i.id !== item),
    );
    return (gamesIdsToFetch.length ? this.getGamesByIds$(gamesIdsToFetch) : of([])).pipe(
      map((res: IGame[]) => {
        newData.currentUserGameAccount.gamesWishlist.push(...res);
        this.apollo.client.cache.writeQuery({
          query: ApiStoreGqlConfig.WISHLIST_GAMES,
          data: newData,
          broadcast: true,
        });

        return newData.currentUserGameAccount.gamesWishlist.map((game: Game) => {
          const wishGame = new Game(game);
          wishGame.favorite = true;
          return wishGame;
        });
      }),
    );
  }

  getUserWishlistGameIds$(): Observable<IUserGamesWishlistId[]> {
    return this.apiService
      .getQuery(ApiStoreGqlConfig.WISHLIST_GAMES_IDS, null, { cache: false })
      .pipe(map((response) => response?.data?.currentUserGameAccount?.gamesWishlist));
  }
  /**
   * Get the Game Factory associated with the `onChainId`
   *
   * If there is no result, throws an error that could be catch later.
   */
  getGameFactoryByOnChainId(onChainId: string) {
    return this.gameFactoryGqlService
      .publishedGameFactories(
        {
          onChainIds: [onChainId],
        },
        { skip: 0, limit: 1 },
      )
      .pipe(
        map((publishedGameFactory) =>
          publishedGameFactory.data?.length > 0
            ? publishedGameFactory
            : throwError(() => new Error(publishedGameFactory)),
        ),
      );
  }

  /**
   * Using the `onChainId` we track first the Game Factory associated with it
   * and then query the game that the Game Factory belongs to.
   */
  getGameByOnChainId$(onChainId: string): Observable<IGame> {
    return this.getGameFactoryByOnChainId(onChainId).pipe(
      map((publishedGameFactory) => publishedGameFactory.data[0].content?.gameContents[0]?.gameId),
      switchMap((gameId: string) => this.getGameById(gameId)),
    );
  }

  private getGamesByIds$(ids: string[]): Observable<GameDetail[]> {
    return this.apiService.getQuery<{ gamesByIds: { game: IGame }[] }>(ApiStoreGqlConfig.GAMES_BY_IDS, { ids }).pipe(
      map((response) => response?.data?.gamesByIds),
      map((result) =>
        result.filter((gameWrapper) => !!gameWrapper.game).map((gameWrapper) => new GameDetail(gameWrapper.game)),
      ),
    );
  }

  markFavoriteGames$(games: Game[]): Observable<Game[]> {
    return this.getUserWishList$().pipe(
      map((wishlist) =>
        games.map((game) => {
          game.favorite = GameHelper.checkGame(game.id, wishlist);
          return game;
        }),
      ),
    );
  }
}
