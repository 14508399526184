import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ultra-id-card-header',
  styleUrls: ['./id-card-header.component.scss'],
  templateUrl: './id-card-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardHeaderComponent {
  @Input() imageType: 'square' | 'rectangle' = 'rectangle';
  @Input() shouldValidateMetadata = false;
}
