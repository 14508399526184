import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RadioGroupComponent } from './radio-group.component';
import { RadioItemDirective } from './radio-item.directive';

const COMPONENTS = [RadioItemDirective, RadioGroupComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule],
  exports: [...COMPONENTS],
})
export class RadioModule {}
