<form [formGroup]="form" (submit)="submit()">
  <label class="form-label rarefied-text" i18n>UNIQ FACTORY onChain Id (<span>i.e. 1, 62, 375</span>)</label>
  <span class="label-help-text d-flex" #helpLabel i18n>
    <ng-content select="[help-label]" />
  </span>

  @if (!helpLabel.hasChildNodes()) {
    <span class="label-help-text d-flex" i18n>
      You can add multiple IDs at once and use a comma or space to separate them. Press the Enter key to insert your IDs
    </span>
  }
  <ultra-tag-input
    formControlName="ids"
    validationError="onChainIdError"
    [maxItems]="idsMaxCount"
    [inputDisabled]="readonlyMode"
    [controlErrorAnchor]="anchor"
    [primaryPlaceHolder]="tagInputPlaceHolder"
    (created)="checkDuplicates($event)">
    <ng-template ultraControlErrorAnchor #anchor="ultraControlErrorAnchor" />
  </ultra-tag-input>

  <div class="d-flex mt-3">
    <button
      [disabled]="searchDisabled || (loading$ | async)"
      class="btn btn-primary ms-auto"
      ultraAutotestAttribute="search-uniq-button">
      Search
    </button>
  </div>
</form>
