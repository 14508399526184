<div class="container">
  <ng-container
    *contentSkeleton="isRouteChange; repeat: 2; className: ['mt-2']; height: '240px'; theme: placeholderTheme.MD_TITLE">
    <div class="f-size-16 pt-2 pb-2 mb-1">
      <ng-container i18n>Details</ng-container>
    </div>

    <div class="game-details box--transparent-blur-border mb-2">
      <div class="game-details__item game-details__item--release-date">
        <div class="overline-medium text-white-alpha-5 mb-2">
          <ng-container i18n>Release date</ng-container>
        </div>

        @if (game.releaseDate?.year) {
          <div class="b1-regular" ultraAutotestAttribute="game-details-release-date">
            {{ game.getReleaseDateObject() | date: game.getReleaseDateFormat(DatePipeFormat.DayMonthYearFullText) }}
          </div>
        } @else {
          <span class="b2-regular">
            <ng-container i18n>Coming soon</ng-container>
          </span>
        }
      </div>

      <div class="game-details__item game-details__item--developer-and-publisher">
        <div class="overline-medium text-white-alpha-5 mb-2">
          <ng-container i18n>Developer & Publisher</ng-container>
        </div>

        @if (game.developerName?.length && game.editorName?.length) {
          <div class="b2-regular mb-2" ultraAutotestAttribute="game-details-developer">{{ game.developerName }}</div>
          <div class="b2-regular" ultraAutotestAttribute="game-details-editor">{{ game.editorName }}</div>
        } @else {
          <span class="b2-regular">
            <ng-container i18n>No data</ng-container>
          </span>
        }
      </div>

      <div class="game-details__item game-details__item--platform">
        <div class="overline-medium text-white-alpha-5 mb-2">
          <ng-container i18n>Platform</ng-container>
        </div>
        <i class="icon icon-16x icon-windows"></i>
      </div>

      <div class="game-details__item game-details__item--age-rating">
        <div class="overline-medium text-white-alpha-5 mb-2">
          <ng-container i18n>Age Rating</ng-container>
          @if (game.ageRating?.length) {
            &ndash;
            <button
              class="game-details__rating-details btn btn-bright-link overline-medium text-uppercase px-0"
              (click)="openRatingsModal()">
              Details
            </button>
          }
        </div>

        @if (game.ageRatingCategoryImages?.length) {
          <div class="game-details__rating-list d-flex flex-wrap">
            @for (img of game.ageRatingCategoryImages; track img) {
              <img [src]="img" class="game-details__rating-img" />
            }
          </div>
        } @else {
          <span class="b2-regular">
            <ng-container i18n>No data</ng-container>
          </span>
        }
      </div>

      <div class="game-details__item game-details__item--genres">
        <div class="overline-medium text-white-alpha-5 mb-2">
          <ng-container i18n>Genres</ng-container>
        </div>

        @if (game.categories.length) {
          <ul class="game-details__list d-flex flex-column align-items-start mb-0">
            @for (category of game.categories; track category) {
              <li
                class="game-details__tag game-details__tag--with-icon u-badge u-badge-ultra-purple-light b2-regular radius-4"
                ultraAutotestAttribute="game-details-categories-item">
                <i class="icon me-2 {{ category | genreIcon }}"></i>
                {{ category }}
              </li>
            }
          </ul>
        } @else {
          <span class="b2-regular">
            <ng-container i18n>No data</ng-container>
          </span>
        }
      </div>

      <div class="game-details__item game-details__item--playing-mode">
        <div class="overline-medium text-white-alpha-5 mb-2">
          <ng-container i18n>Playing Mode</ng-container>
        </div>

        @if (game.playingModes.length) {
          <ul class="game-details__list d-flex flex-column align-items-start mb-0">
            @for (mode of game.playingModes; track mode) {
              <li
                class="d-flex justify-content-start align-items-center"
                ultraAutotestAttribute="ultra-game-detail-playingModes-item">
                <i class="icon icon-16x bg-white-alpha-7 me-2 icon-{{ game.getModeIcon(mode) }}"></i>
                <div class="b2-regular text-capitalize">{{ mode }}</div>
              </li>
            }
          </ul>
        } @else {
          <span class="b2-regular">
            <ng-container i18n>No data</ng-container>
          </span>
        }
      </div>

      <div class="game-details__item game-details__item--tags">
        <div class="overline-medium text-white-alpha-5 mb-2">
          <ng-container i18n>Tags</ng-container>
        </div>

        @if (game.tags?.length) {
          <ul class="game-details__list d-flex flex-column align-items-start mb-0">
            @for (tag of game.tags | slice: 0 : (game.tags.length > 6 ? 5 : game.tags.length); track tag) {
              <li
                class="game-details__tag u-badge u-badge-ultra-purple-light b2-regular radius-4"
                ultraAutotestAttribute="game-details-tags-item">
                {{ tag }}
              </li>
            }
            @if (game.tags.length > 6) {
              <li>
                <button
                  type="button"
                  i18n="Show all tags"
                  class="game-details__tag u-badge u-badge-ultra-purple-light b2-regular radius-4"
                  (click)="openTagsModal()">
                  +{{ game.tags.length - 5 }}
                </button>
              </li>
            }
          </ul>
        } @else {
          <span class="b2-regular">
            <ng-container i18n>No data</ng-container>
          </span>
        }
      </div>
    </div>
  </ng-container>
</div>
