import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EnvironmentConfig } from '@ultra/environment';

import { ErrorHandlerApiService } from '../errors/services/error-handler-api.service';

enum contentType {
  formData = 'x-www-form-urlencoded',
  json = 'json',
}

@Injectable()
export class OAuthApiRestService {
  constructor(
    private http: HttpClient,
    private envConfig: EnvironmentConfig,
    private errorHandlerService: ErrorHandlerApiService,
  ) {}

  /**
   * POST: Create/Send data to the OAuth API
   * @param {string} path: api endpoint
   * @param params
   * @param contentTypeJson
   * @returns {Observable<any>}
   */
  create(path: string, params: any, contentTypeJson = true): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': `application/${contentTypeJson ? contentType.json : contentType.formData}`,
    });

    return this.http
      .post(this.envConfig.OAUTH_REST_API + path, params, { headers })
      .pipe(catchError((errorResponse: HttpErrorResponse) => this.errorHandlerService.handle(errorResponse.error)));
  }
}
