import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  QueryList,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { AutosizeDirective } from '../autosize/autosize.directive';
import { ULTRA_ERROR, UltraErrorDirective } from '../error/error.directive';
import { ErrorStateMatcher } from '../error-state-matcher/error-state-matcher';
import { FormFieldControl } from '../form-field-control';
import { getFormFieldDuplicatedHintError, getFormFieldMissingControlError } from '../helpers/form-field-errors';
import { ULTRA_HINT, UltraHintDirective } from '../hint/hint.directive';
import { LabelDirective } from '../label/label.directive';
import { ULTRA_PREFIX, UltraPrefixDirective } from '../prefix/prefix.directive';
import { ULTRA_SUFFIX, UltraSuffixDirective } from '../suffix/suffix.directive';

import { ultraFormFieldAnimations } from './form-field-animation';

let nextUniqueId = 0;

@UntilDestroy()
@Component({
  selector: 'ultra-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  exportAs: 'ultraFormField',
  animations: [ultraFormFieldAnimations.transitionMessages],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UltraFormFieldComponent implements AfterContentInit, AfterContentChecked, AfterViewInit {
  @Input()
  @HostBinding('attr.id')
  get id(): string {
    return this._id || this.uid;
  }

  set id(value: string) {
    this._id = value || this.uid;
  }

  protected _id: string;

  @Input()
  errorStateMatcher: ErrorStateMatcher;

  @Input()
  get showRequiredMarker(): boolean {
    return this._showRequiredMarker;
  }

  set showRequiredMarker(value: boolean) {
    this._showRequiredMarker = value;
  }

  private _showRequiredMarker: boolean;

  @Input()
  get hintLabel(): string {
    return this._hintLabel;
  }

  set hintLabel(value: string) {
    this._hintLabel = value;
    this.validateHints();
  }

  private _hintLabel = '';

  @ViewChild('connectionContainer', { static: true }) connectionContainerRef: ElementRef;

  @ContentChild(FormFieldControl) _controlNonStatic: FormFieldControl<any>;
  @ContentChild(FormFieldControl, { static: true }) _controlStatic: FormFieldControl<any>;

  get control() {
    return this._explicitFormFieldControl || this._controlNonStatic || this._controlStatic;
  }

  set control(value) {
    this._explicitFormFieldControl = value;
  }

  private _explicitFormFieldControl: FormFieldControl<any>;

  @ContentChildren(ULTRA_PREFIX, { descendants: true }) prefixChildren: QueryList<UltraPrefixDirective>;
  @ContentChildren(ULTRA_SUFFIX, { descendants: true }) suffixChildren: QueryList<UltraSuffixDirective>;
  @ContentChildren(ULTRA_ERROR, { descendants: true }) errorChildren: QueryList<UltraErrorDirective>;
  @ContentChildren(ULTRA_HINT, { descendants: true }) hintChildren: QueryList<UltraHintDirective>;

  @ContentChild(AutosizeDirective, { static: true }) textAreaAutosize: AutosizeDirective;
  @ContentChild(LabelDirective, { static: true }) label: LabelDirective;

  @ViewChild('suffixContainer', { static: false }) suffixContainer: ElementRef;

  public infixHeight$: Observable<string> = of('auto');
  public isScrollableTextArea$: Observable<boolean> = of(false);

  subscriptAnimationState = '';

  @HostBinding('class.focused')
  get focused(): boolean {
    return this.control.focused;
  }

  @HostBinding('class.ng-untouched')
  get untouched(): boolean {
    return this.shouldForward('untouched');
  }

  @HostBinding('class.pristine')
  get pristine(): boolean {
    return this.shouldForward('pristine');
  }

  @HostBinding('class.touched')
  get touched(): boolean {
    return this.shouldForward('touched');
  }

  @HostBinding('class.dirty')
  get dirty(): boolean {
    return this.shouldForward('dirty');
  }

  @HostBinding('class.valid')
  get valid(): boolean {
    return this.shouldForward('valid');
  }

  @HostBinding('class.invalid')
  get invalid(): boolean {
    return this.shouldForward('invalid');
  }

  @HostBinding('class.pending')
  get pending(): boolean {
    return this.shouldForward('pending');
  }

  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this.control.disabled;
  }

  @HostBinding('class.readonly')
  get readonly(): boolean {
    return this.control.readonly;
  }

  @HostBinding('class.error')
  get formFieldInvalid(): boolean {
    return this.errorState;
  }

  public errorState = false;
  protected uid = `ultra-form-field-${nextUniqueId++}`;

  constructor(
    public elementRef: ElementRef,
    @Optional() private parentForm: NgForm,
    @Optional() private parentFormGroup: FormGroupDirective,
    private defaultErrorStateMatcher: ErrorStateMatcher,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this.validateControlChild();

    if (this.control.controlType) {
      this.elementRef.nativeElement.classList.add(`form-field-type-${this.control.controlType}`);
    }
    this.control.stateChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
      this.updateErrorState();
    });

    if (this.control.ngControl?.valueChanges) {
      this.control.ngControl.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => this.changeDetectorRef.markForCheck());
    }

    this.hintChildren.changes.pipe(startWith(<string>null)).subscribe(() => {
      this.validateHints();
      this.changeDetectorRef.markForCheck();
    });

    if (this.textAreaAutosize) {
      this.infixHeight$ = this.textAreaAutosize.infixBlockHeight$;
      this.isScrollableTextArea$ = this.textAreaAutosize.updateIsScrollable.asObservable();
    }
  }

  ngAfterContentChecked() {
    this.validateControlChild();
  }

  ngAfterViewInit(): void {
    if (this.textAreaAutosize) {
      this.textAreaAutosize.updateElementStyles(
        'padding-right',
        `${this.suffixContainer?.nativeElement?.offsetWidth || 0}px`
      );
    }
    this.subscriptAnimationState = 'enter';
    this.changeDetectorRef.detectChanges();
  }

  getConnectedOverlayOrigin(): ElementRef {
    return this.connectionContainerRef || this.elementRef;
  }

  onContainerClickHandler($event: MouseEvent): void {
    this.control.onContainerClick($event);
  }

  getDisplayedMessages(): 'error' | 'hint' {
    return this.errorChildren?.length > 0 && this.errorState ? 'error' : 'hint';
  }

  private validateControlChild() {
    if (!this.control) {
      throw getFormFieldMissingControlError();
    }
  }

  private shouldForward(prop: keyof NgControl): boolean {
    const ngControl = this.control ? this.control.ngControl : null;
    return ngControl && ngControl[prop];
  }

  private validateHints() {
    if (this.hintChildren) {
      let startHint: UltraHintDirective;
      let endHint: UltraHintDirective;
      this.hintChildren.forEach((hint: UltraHintDirective) => {
        if (hint.align === 'start') {
          if (startHint || this.hintLabel) {
            throw getFormFieldDuplicatedHintError('start');
          }
          startHint = hint;
        } else if (hint.align === 'end') {
          if (endHint) {
            throw getFormFieldDuplicatedHintError('end');
          }
          endHint = hint;
        }
      });
    }
  }

  updateErrorState() {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.errorStateMatcher || this.defaultErrorStateMatcher;
    const control = this.control?.ngControl?.control ? (this.control.ngControl.control as UntypedFormControl) : null;
    const newState = matcher.isErrorState(control, parent);
    if (newState !== oldState) {
      this.errorState = newState;
      this.changeDetectorRef.detectChanges();
    }
  }
}
