<ng-container
  *contentSkeleton="isRouteChange; repeat: 2; className: ['mt-2']; height: '588px'; theme: placeholderTheme.MD_TITLE">
  <div class="pb-3 s1-bold">
    <ng-container i18n>Languages</ng-container>
  </div>
  <div class="d-flex text-white-alpha-7">
    <div class="col-3 p-0"></div>
    @for (support of supportTypes; track support) {
      <div class="col-3 p-0 text-capitalize b2-regular text-center text-white-alpha-7">
        <ng-container i18n>{{ support }}</ng-container>
      </div>
    }
  </div>
  <div class="pt-2">
    @for (lang of gameLanguage$ | async | slice: 0 : (isExpanded ? 100 : 5); track lang) {
      <div class="lang d-flex py-2" ultraAutotestAttribute="ultra-game-detail-languages-item">
        <div class="col-3 text-white-alpha-7 overline-medium p-0">{{ lang.languageName }}</div>
        @for (support of supportTypes; track support) {
          <div class="text-center col-3 p-0">
            <i
              class="icon {{
                game.languages[lang.languageKey]?.[support] ? 'icon-check-default' : 'icon-cross-16 bg-white-alpha-5'
              }}"></i>
          </div>
        }
      </div>
    }
  </div>
  @if (game.getLanguagesKeys().length > 5) {
    <div
      class="d-flex position-relative more justify-content-center align-items-center pt-3"
      [class.more--collapsed]="!isExpanded">
      <span class="divider"></span>
      <button
        class="text-uppercase overline-medium mx-2"
        ultraButton
        size="small"
        color="transparent-light"
        styled="transparent"
        (click)="toggleMore()">
        {{ isExpanded ? 'See less' : 'See more' }}
      </button>
      <span class="divider"></span>
    </div>
  }
</ng-container>
