import { Directive, HostBinding, InjectionToken, Input } from '@angular/core';

let nextUniqueId = 0;

export const ULTRA_HINT = new InjectionToken<UltraHintDirective>('UltraHintDirective');

@Directive({
  selector: 'ultra-hint',
  providers: [{ provide: ULTRA_HINT, useExisting: UltraHintDirective }],
})
export class UltraHintDirective {
  @Input() align: 'start' | 'end' = 'start';

  @Input()
  @HostBinding('attr.id')
  id = `ultra-hint-${nextUniqueId++}`;

  @HostBinding('class.ultra-form-field-hint-end')
  public get alignDir(): boolean {
    return this.align === 'end';
  }
}
