import { Injectable } from '@angular/core';
import Big from 'big.js';
import { Observable, timer, zip } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';

import { UserCurrency } from '@ultra/core/models';
import { UserFacadeService } from '@ultra/core/stores';

import { UserCurrencyConversionService } from '../../models/exchange/user-currency-conversion.service';
import { AuthenticatorGatewayService } from '../../modules/authenticator/authenticator.gateway.service';
import {
  BlockchainTransactionBuilder,
  EosioAction,
  KnownContract,
  TransferTokenParams,
} from '../../services/authenticator';
import { TransactionAppService } from '../../services/transaction';

import { IWalletBalance } from './interface/wallet-balance.interface';

export interface WalletBalanceParams {
  rate: string;
  uosAmount: number;
  userCurrency: UserCurrency;
}

@Injectable({
  providedIn: 'root',
})
export class WalletBalanceFacadeService {
  public walletId$: Observable<string>;
  public walletBalance$: Observable<IWalletBalance>;

  constructor(
    private authenticatorGatewayService: AuthenticatorGatewayService,
    private userFacadeService: UserFacadeService,
    private userCurrencyConversionService: UserCurrencyConversionService,
    private transactionService: TransactionAppService,
  ) {
    this.walletBalance$ = this.getWalletBalance();
    this.walletId$ = this.userFacadeService.user$.pipe(
      map((user) => user?.blockchainId),
      filter(Boolean),
      shareReplay(1),
    );
  }

  public createTokenTransfer(params: TransferTokenParams) {
    const transaction = new BlockchainTransactionBuilder()
      .action(EosioAction.TRANSFER)
      .contract(KnownContract.EOSIO_TOKEN)
      .authorizationForTransferAction(params.from)
      .dataForTransferAction(params.from, params.to, params.quantity, params.memo)
      .build();
    this.transactionService.openTransactionSigning(transaction);
  }

  private getWalletBalance(): Observable<IWalletBalance> {
    const userCurrency$ = this.userCurrencyConversionService.userCurrency$.pipe(take(1));
    const uosToFiatExchange$ = this.userCurrencyConversionService.uosToFiatExchange$.pipe(
      map((exchange) => exchange?.rate),
      take(1),
    );
    return timer(0, 7000).pipe(
      switchMap(() => zip(userCurrency$, uosToFiatExchange$, this.getWalletUosAmount())),
      map(([userCurrency, rate, uosAmount]) => this.transformToWalletBalance({ rate, uosAmount, userCurrency })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getWalletUosAmount(): Observable<number> {
    return this.authenticatorGatewayService.getWalletBalance().pipe(map(parseFloat));
  }

  private transformToWalletBalance({ rate, uosAmount, userCurrency }: WalletBalanceParams): IWalletBalance {
    const uos = uosAmount || 0;
    const fiat = rate ? +Big(uos).mul(rate) : null;
    const symbol = userCurrency.currencySymbol;
    const currency = userCurrency.countryCurrency;
    return {
      uos,
      fiat,
      symbol,
      currency,
    };
  }
}
