import { Inject, Injectable } from '@angular/core';

import { IEnvironment } from './interfaces';
import { APP_CONFIG } from './providers';

const enum NetworkProtocolType {
  HTTP,
  WEBSOCKET,
}

@Injectable()
export class EnvironmentConfig {
  BASE_PATH_API: string;
  BASE_PATH_GRAPH: string;
  BASE_PATH_GRAPH_SOCKET: string;
  AUTHENTICATOR_SOCKET_URL: string;
  OAUTH_REST_API: string;
  OAUTH_URL: string;

  ALGOLIA_APP_ID: string;
  ALGOLIA_PUB_KEY: string;

  get envName(): string {
    return this.environment.name;
  }

  constructor(@Inject(APP_CONFIG) private readonly environment: IEnvironment) {
    this.BASE_PATH_API = this.setNetworkProtocol(this.environment.basePathApi);
    this.BASE_PATH_GRAPH = this.setNetworkProtocol(this.environment.uriGraphQL);
    this.BASE_PATH_GRAPH_SOCKET = this.setNetworkProtocol(this.environment.uriGraphQL, NetworkProtocolType.WEBSOCKET);
    this.OAUTH_REST_API = this.setNetworkProtocol(this.environment.oauthIssuer);
    const oAuthUrlOrigin = environment.oauthIssuer ? new URL(environment.oauthIssuer).origin : undefined;
    this.OAUTH_URL = this.setNetworkProtocol(oAuthUrlOrigin);

    this.ALGOLIA_APP_ID = this.environment.algoliaAppId;
    this.ALGOLIA_PUB_KEY = this.environment.algoliaPubKey;
  }

  /**
   * Set a network protocol based in the current browser connection
   *
   * @private
   * @param {string} path
   * @param {NetworkProtocolType} type
   * @returns {string}
   * @memberof EnvironmentConfig
   */
  private setNetworkProtocol(path: string, type: NetworkProtocolType = NetworkProtocolType.HTTP): string {
    if (!path) return undefined;
    const endPoint: string = path.indexOf('http') > -1 ? path.split(/:/)[1] : path;
    let protocol: string;
    if (NetworkProtocolType.WEBSOCKET === type) {
      protocol = 'wss';
    } else {
      protocol = 'https';
    }
    return `${protocol}:${endPoint}`;
  }
}
