import { Injectable } from '@angular/core';

import { IUserStrategy } from '../interfaces/user-strategy.interface';

import { UserGqlService } from './user-gql.service';

@Injectable({
  providedIn: 'root',
})
export class UserFactoryService {
  private userStrategy: IUserStrategy;

  constructor(private apiService: UserGqlService) {
    this.userStrategy = this.apiService;
  }

  get strategy(): IUserStrategy {
    return this.userStrategy;
  }
}
