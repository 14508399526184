import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { catchError, EMPTY, filter, map, merge, Observable, of, switchMap, take, tap } from 'rxjs';

import { CompanyPermission } from '../../helpers/jwt-permissions.transformer';
import { IPersonalData, IUser, IUserStrategy, UserBlockchainStatusType, UserFactoryService } from '../../models';
import { AuthService } from '../../services/auth/auth.service';

import * as UserActions from './user.actions';
import { UserState } from './user.state';

@UntilDestroy()
@Injectable()
export class UserFacadeService {
  user$: Observable<IUser> = this.store.select(UserState.getUser);
  personalData$: Observable<IPersonalData> = this.store.select(UserState.getUserPersonalData);
  userCompanies$: Observable<CompanyPermission> = this.user$.pipe(
    filter((user) => !!user.permission),
    map((user) => user.permission.companies),
  );
  blockchainId$: Observable<string> = this.user$.pipe(map((user) => user.blockchainId));

  private userGqlService: IUserStrategy;

  constructor(
    private store: Store,
    private authService: AuthService,
    private userFactoryService: UserFactoryService,
  ) {
    this.userGqlService = this.userFactoryService.strategy;
    this.authService.accessToken$
      .pipe(
        switchMap((accessToken) => {
          if (accessToken) {
            return this.mergeCurrentUserWithTokenInfo();
          } else {
            this.setUserInitialState();
            return EMPTY;
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  mergeCurrentUserWithTokenInfo() {
    return this.getCurrentUser(false).pipe(
      catchError(() => of(null)),
      map((currentUser) => {
        const user = {
          ...currentUser,
          ...this.authService.getAuthTokenUser(),
        };
        this.setUser(user);
        return user;
      }),
    );
  }

  public getCurrentUser(dispatchAction = true): Observable<IUser> {
    return this.userGqlService.getCurrentUser().pipe(
      tap((user: IUser) => {
        if (dispatchAction) {
          this.setUser(user);
        }
      }),
    );
  }

  public setUser(user: IUser): void {
    this.store.dispatch(new UserActions.SetUser(user));
  }

  public setUserInitialState() {
    this.store.dispatch(new UserActions.SetUserInitialState());
  }

  public onAccountStatus(): Observable<Partial<IUser>> {
    return merge(this.getCurrentUser(false), this.userGqlService.accountStatus()).pipe(
      tap(({ blockchainId, blockchainStatus, deviceSyncStatus }) =>
        this.store.dispatch(
          new UserActions.UpdateBlockchainStatus({ blockchainId, blockchainStatus, deviceSyncStatus }),
        ),
      ),
    );
  }

  public onSuccessAccountCreation(): Observable<boolean> {
    return this.onSuccessEBA('blockchainStatus');
  }

  public onSuccessUpdateKeys(): Observable<boolean> {
    return this.onSuccessEBA('deviceSyncStatus');
  }

  private onSuccessEBA(field: keyof Pick<IUser, 'blockchainStatus' | 'deviceSyncStatus'>): Observable<boolean> {
    return this.onAccountStatus().pipe(
      map((data) => data[field]),
      map((status) => status === UserBlockchainStatusType.SUCCESS),
      filter(Boolean),
      take(1),
    );
  }
}
