<div class="checkbox transition-bg {{ cssClass }}" [class.disabled]="readonly" [class.active]="checked">
  <input
    class="checkbox__input"
    #input
    [id]="id"
    [attr.disabled]="readonly || null"
    [checked]="checked"
    (change)="onInputClick($event)"
    type="checkbox"
    [ultraAutotestAttribute]="dataId + '-input'" />

  <label
    class="checkbox__label d-inline-flex align-items-center"
    [ngClass]="[readonly ? 'disabled' : 'cursor-pointer', 'checkbox__label--' + size]"
    [ngbTooltip]="tooltip"
    [for]="id">
    <div class="check__wrapper" [class.check__wrapper--active]="checked || indeterminate">
      @if (!indeterminate) {
        <div class="check" [class.check--active]="checked"></div>
      }

      @if (indeterminate) {
        <i class="checkbox__icon icon icon-minus me-0" [class.check--active]="indeterminate"> </i>
      }
    </div>

    @if (cssClassIcon) {
      <i class="checkbox__icon icon icon-{{ cssClassIcon }}"> </i>
    }

    @if (label) {
      <span class="checkbox__label-name pe-2 text-capitalize f-size-12 ls-quoter">
        {{ label }}
      </span>
    }
  </label>
</div>
