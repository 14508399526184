import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GameDetail } from '../../../../models/game/models/game-detail.model';
import { ContentSkeletonTheme } from '../../../../modules/skeleton/content-skeleton/content-skeleton-theme.enum';

@Component({
  selector: 'ultra-game-detail-about',
  templateUrl: './game-detail-about.component.html',
  styleUrls: ['./game-detail-about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailAboutComponent {
  @Input()
  game: GameDetail;
  @Input() isRouteChange = false;
  readonly placeholderTheme = ContentSkeletonTheme;
}
