import { BuildType } from './build.helper';

export enum RepositoryType {
  COREG = 'COREG', // Core Game Files
  CORED = 'CORED', // Core Demo Files
  DLC = 'DLC', // DLC
  GLP = 'GLP', // Game Language Pack
  DLP = 'DLP', // Demo Language Pack
  DSERVER = 'DSERVER', // Dedicated server
}

export const repositoryLabels = {
  [RepositoryType.COREG]: 'Game: Core Game Files',
  [RepositoryType.CORED]: 'Demo: Core Game Files',
  [RepositoryType.DLC]: 'Game: DLC',
  [RepositoryType.GLP]: 'Game: Language Pack',
  [RepositoryType.DLP]: 'Demo: Language Pack',
  [RepositoryType.DSERVER]: 'Game: Dedicated Server',
};

export const repositoryIcons = {
  [RepositoryType.COREG]: 'gamepad',
  [RepositoryType.CORED]: 'demo',
  [RepositoryType.DLC]: 'dlc',
  [RepositoryType.GLP]: 'languages',
  [RepositoryType.DLP]: 'languages',
  [RepositoryType.DSERVER]: 'dserver',
};

export const gameCompatibleContent: { [key: string]: RepositoryType[] } = {
  [BuildType.Core]: [RepositoryType.DLC, RepositoryType.GLP, RepositoryType.DSERVER],
  [BuildType.Demo]: [RepositoryType.DLP],
};

export const gameTypeRepositories = {
  [BuildType.Core]: [...gameCompatibleContent[BuildType.Core], RepositoryType.COREG],
  [BuildType.Demo]: [...gameCompatibleContent[BuildType.Demo], RepositoryType.CORED],
};

export enum CompatibilityMatrixType {
  DLCREPOSITORIES = 'dlcRepositories', // DLC
  LANGUAGEREPOSITORIES = 'languageRepositories', // Game Language Pack
  DSREPOSITORIES = 'dsRepository', // Dedicated Server
  DEMOLANGUAGEREPOSITORIES = 'demoLanguagesRepositories', // Dedicated server
}
