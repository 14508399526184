<span ultraAutotestAttribute="uniq-price-content">
  @if (price() | isFree) {
    Free
  } @else {
    {{ symbol() }}
    @if (type() === 'uniq') {
      {{ price() | uniqPrice: minResellPrice() : minResellPriceCurrency() | async | number: '1.2-2' }}
    } @else {
      {{ price() | number: '1.2-2' }}
    }
  }
</span>
