import { Job, JobStatus, Queue } from '../interfaces/download-manager.interface';

export const jobMock: Job = {
  jobId: 'job-id',
  jobIndex: 0,
  queueId: 'queue-id',
  status: JobStatus.ACTIVE,
  payload: {
    repositoryId: 'repo-id',
    branch: 'branch',
    parentRepositoryId: null,
    action: 'install',
    downloadPath: 'C:\\user\\directory',
  },
  data: {
    progress: 1,
    downloadSpeed: 100,
  },
};

export const queueMock: Queue = {
  queueId: 'queue-id',
  status: 'active',
  length: 1,
  jobs: [jobMock],
};
