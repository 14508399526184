import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  Input,
  Optional,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../../autotests/autotest-attribute.directive';
import { UniqFactoryRouteService } from '../../../uniq-factory/services/uniq-factory-route.service';
import { CopyToClipboardComponent } from '../../copy-to-clipboard';
import { IdCardEntity } from '../adapters';
import { IdCardSerialNumberComponent } from '../id-card-serial-number/id-card-serial-number.component';
import { ID_CARD_CONFIG, IdCardConfig } from '../providers';
/**
 * A component that displays the identity information of a Uniq
 *
 * Shows the following information
 * - `Uniq ID`: Blockchain ID of the Uniq.
 * - `Factory ID`: Blockchain ID of the Factory that the Uniq belongs to. On click opens the Uniq Factory Page.
 * - `Creator`: Each creator has a wallet ID and this lets you know who created this Uniq.
 * - `Serial Number`: Lets user know which Uniq is within a collection of Uniqs. Example: 99/1000
 *
 * For each of the above information, a copy to clipboard button is available to copy the information to the clipboard.
 */
@Component({
  selector: 'ultra-id-card-identity',
  templateUrl: './id-card-identity.component.html',
  styleUrls: ['./id-card-identity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CopyToClipboardComponent,
    NgbTooltipModule,
    IdCardSerialNumberComponent,
    DatePipe,
    AutotestAttributeDirective,
  ],
})
export class IdCardIdentityComponent implements AfterViewInit {
  @Input() idCardEntity: IdCardEntity;

  isUniqDetailsAvailable = false;

  private uniqFactoryRouteService = inject(UniqFactoryRouteService);

  constructor(
    private router: Router,
    @Optional() @Inject(ID_CARD_CONFIG) private idCardConfig: IdCardConfig,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.isUniqDetailsAvailable = this.contextualProductDetails('uniq');
    this.cd.detectChanges();
  }

  navigateToUniqFactoryPage() {
    this.uniqFactoryRouteService.openUniqFactoryPage(this.idCardEntity.factoryBlockchainId);
  }

  contextualProductDetails(hideForType: string): boolean {
    const currentUrlWithoutParams = this.router.url.split('?')[0];
    if (['product', 'sell', 'my-inventory'].some((urlPart) => currentUrlWithoutParams.includes(urlPart))) {
      return this.idCardEntity?.type !== hideForType;
    }
    return this.idCardConfig?.isAllowProductDetails;
  }
}
