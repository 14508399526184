import { ValidatorFn } from '@angular/forms';

import { CountryCode } from '@ultra/core/helpers';
import { AddressFieldName } from '@ultra/core/models';

import { UltraValidators } from '../../../../services/validators/validators.service';

type FieldValidators = { default: ValidatorFn[] } & { [country in CountryCode]?: ValidatorFn[] };

export const addressFormValidators: { [field in AddressFieldName]?: FieldValidators } = {
  streetName: {
    default: [
      UltraValidators.minLength(1),
      UltraValidators.maxLength(255),
      UltraValidators.alphaNumericSpacesDashesApostrophesSlashes,
      UltraValidators.whitespaceValidator,
    ],
  },
  streetName2: {
    default: [
      UltraValidators.minLength(1),
      UltraValidators.maxLength(255),
      UltraValidators.alphaNumericSpacesDashesApostrophesSlashes,
      UltraValidators.whitespaceValidator,
    ],
  },
  zipCode: {
    default: [
      UltraValidators.maxLength(10),
      UltraValidators.alphaNumericSpacesDashes,
      UltraValidators.whitespaceValidator,
    ],
    US: [UltraValidators.numeric, UltraValidators.minLength(5), UltraValidators.maxLength(5)],
  },
  city: {
    default: [
      UltraValidators.minLength(1),
      UltraValidators.maxLength(50),
      UltraValidators.alphaSpacesDashesApostrophes,
      UltraValidators.whitespaceValidator,
    ],
  },
  state: {
    default: [
      UltraValidators.minLength(1),
      UltraValidators.maxLength(50),
      UltraValidators.alphaSpacesDashesApostrophes,
      UltraValidators.whitespaceValidator,
    ],
  },
  country: {
    default: [UltraValidators.minLength(1), UltraValidators.maxLength(50)],
  },
};
