import { IUniq } from '../models/uniq/interfaces/uniq.interface';
import { IdCardEntity } from '../modules/id-card-content/adapters/id-card.adapter';
import { IdCardActionsDisplayHelper } from '../modules/id-card-content/id-card-actions/helpers/id-card-actions-display.helper';
import { ActionStatus } from '../modules/id-card-content/id-card-actions/id-card-actions.model';
import { getMetadataInfo } from '../services/uniq/uniq-metadata-transform-helper';
import { IUniqFactory } from '../uniq-factory/uniq-factory.interface';

export interface IUniqEventData {
  id: string;
  factoryId: string;
  uniqType: string;
  name: string;
  subName: string;
  listingPrice: string;
  effectivePrice?: number;
  minimumResellPrice: string;
  minimumResellCurrency: string;
  transferable: ActionStatus;
  tradable: ActionStatus;
}

export function getUniqFactoryEventData(uniqFactory: IUniqFactory) {
  return {
    id: uniqFactory.id,
    name: uniqFactory.name,
    subName: uniqFactory.subName,
    price: uniqFactory.prices?.[0]?.amount,
    currency: uniqFactory.prices?.[0]?.currency.code,
  };
}

export function getUniqEventData(uniq: IUniq, effectivePrice?: number): IUniqEventData {
  const uniqMeta = getMetadataInfo(uniq);
  const transferPeriod = uniq.transferPeriod || uniq.transferability?.transferPeriod;
  const transferStatus = IdCardActionsDisplayHelper.getActionStatus(
    transferPeriod,
    transferPeriod?.transferAvailability
  );
  const tradingPeriod = uniq.tradingPeriod || uniq.tradability?.tradingPeriod;
  const tradabilityStatus = IdCardActionsDisplayHelper.getActionStatus(
    tradingPeriod,
    tradingPeriod?.resaleAvailability
  );
  return {
    id: uniq.id,
    factoryId: uniq.factory?.onChainId,
    uniqType: uniq.factory?.type,
    name: uniqMeta.name,
    subName: uniqMeta.subName,
    listingPrice: uniq.saleInfo?.price?.amount,
    effectivePrice,
    minimumResellPrice: uniq.factory?.tradability?.minimumResellPrice?.amount,
    minimumResellCurrency: uniq.factory?.tradability?.minimumResellPrice?.currency?.code,
    transferable: transferStatus,
    tradable: tradabilityStatus,
  };
}

export function getUniqEventDataFromIdCard(idCardEntity: IdCardEntity, effectivePrice?: number): IUniqEventData {
  const transferPeriod = idCardEntity.transferPeriod || idCardEntity.transferability?.transferPeriod;
  const transferStatus = IdCardActionsDisplayHelper.getActionStatus(
    transferPeriod,
    transferPeriod?.transferAvailability
  );
  const tradingPeriod = idCardEntity.tradingPeriod || idCardEntity.tradability?.tradingPeriod;
  const tradabilityStatus = IdCardActionsDisplayHelper.getActionStatus(
    tradingPeriod,
    tradingPeriod?.resaleAvailability
  );
  return {
    id: idCardEntity.id,
    factoryId: idCardEntity.factoryBlockchainId,
    uniqType: idCardEntity.content.contentType,
    name: idCardEntity.info.title,
    subName: idCardEntity.info.subTitle,
    listingPrice: idCardEntity.prices[0].amount,
    effectivePrice,
    minimumResellPrice: idCardEntity.tradability.minimumResellPrice,
    minimumResellCurrency: idCardEntity.tradability.minimumResellPriceCurrency?.code,
    transferable: transferStatus,
    tradable: tradabilityStatus,
  };
}
