import { DatePipe } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  ActionDisplayState,
  ActionStatus,
  IdCardAction,
} from '../../../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { IdCardActionsDisplayHelper } from '../../helpers/id-card-actions-display.helper';

@Component({
  selector: 'ultra-actions-period-text',
  templateUrl: './actions-period-text.component.html',
  styleUrls: ['./actions-period-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe],
  standalone: true,
})
export class ActionsPeriodTextComponent implements AfterContentInit {
  @Input() displayState: ActionDisplayState;
  @Input() date: string; // ISOString
  @Input() startDate: string; // ISOString
  @Input() dateType: 'starting' | 'ending';
  @Input() action: IdCardAction;

  actionVerb: string;
  readonly actionStatuses = ActionStatus;
  readonly actions = IdCardAction;

  ngAfterContentInit() {
    this.actionVerb = IdCardActionsDisplayHelper.getActionVerb(this.action);
  }
}
