import { ACTIVATE_USER_DEVICE, ADD_USER_DEVICE, EDIT_USER } from '../graphql-client/mutations/user.mutation';
import {
  CURRENT_USER_INFO_QUERY,
  CURRENT_USER_PROFILE_SETTINGS_QUERY,
  CURRENT_USER_QUERY,
  USER_QUERY,
  USERS_QUERY,
} from '../graphql-client/queries/user.query';
import { ACCOUNT_STATUS_SUBSCRIPTION } from '../graphql-client/subscriptions/user.subscription';

export class ApiGql {
  // ********** Queries **********
  static readonly CURRENT_USER = CURRENT_USER_QUERY;
  static readonly CURRENT_USER_PROFILE_SETTINGS = CURRENT_USER_PROFILE_SETTINGS_QUERY;
  static readonly CURRENT_USER_INFO = CURRENT_USER_INFO_QUERY;
  static readonly USERS = USERS_QUERY;
  static readonly USER = USER_QUERY;
  static readonly USER_ACCOUNT_STATUS = ACCOUNT_STATUS_SUBSCRIPTION;

  static readonly EDIT_USER = EDIT_USER;
  static readonly ADD_USER_DEVICE = ADD_USER_DEVICE;
  static readonly ACTIVATE_USER_DEVICE = ACTIVATE_USER_DEVICE;
}
