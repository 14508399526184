import { Game } from '../../models/game.model';
import { GameDetail } from '../../models/game-detail.model';
import { gamePlaceholderB64 } from '../../../multimedia/b64/game-placeholder.helper';
import { mockPrice } from './game-detail.mock';

export const gameMock = new Game({
  id: '5bb6505ddf97da0008fecb26',
  publisher: { id: '1' },
  liveGame: {
    title: 'The Witcher® 3: Wild Hunt',
    description: `Description Text`,
    descriptionShort: `Description Short Text`,
    categories: ['Action', 'RPG'],
    editorName: 'Guerrilla Games',
    developerName: 'cd project red',
    releaseDate: {
      year: 2020,
      month: 7,
      day: 27,
    },
    lastUpdate: {
      date: '2018-09-28T00:00:00.000Z',
      version: '1.0.1a',
    },
  },
  tokenFactories: [
    {
      id: '62154d8d4f3b22a5285f9ee7',
      blockchainId: '1645563277707',
      prices: [
        {
          currency: 'USD',
          amount: 100,
          symbol: '$',
        },
      ],
      livePrices: [
        {
          currency: 'USD',
          amount: 100,
          symbol: '$',
          originalAmount: 111,
          discount: 1000,
        },
      ],
      publishOrder: 0,
      description:
        'Final Fantasy VIII is a role-playing video game developed and published by Square for the PlayStation console. ',
      name: 'FF8Factory',
      image: {
        name: 'hero.jpg',
        fileExtension: 'jpeg',
        base: {
          width: 1920,
          height: 1080,
          url: 'https://ultraos-uploads-dev.s3.eu-west-1.amazonaws.com/621399c8f68df41ffcc07baf/62153e4239b0ff95a02a9f29-1645559362953/original-images/hero.jpg',
          size: 1296272,
          uploadDate: '2022-02-22T20:55:18.428Z',
        },
        images: [
          {
            width: 120,
            height: 160,
            url: 'https://dev.img.ultra.io/120x160/621399c8f68df41ffcc07baf/62153e4239b0ff95a02a9f29-1645559362953/original-images/hero.jpg',
            size: null,
            uploadDate: null,
          },
        ],
      },
      multimedia: {
        backGroundImage: {
          name: 'teahub.io-ff8-wallpaper-1745451.jpg',
          fileExtension: 'jpeg',
          base: {
            width: 1920,
            height: 1080,
            url: 'https://ultraos-uploads-dev.s3.eu-west-1.amazonaws.com/621399c8f68df41ffcc07baf/62153e4239b0ff95a02a9f29-1645559362953/original-images/teahub.io-ff8-wallpaper-1745451.jpg',
            size: 648182,
            uploadDate: '2022-02-22T20:55:22.368Z',
          },
          images: [
            {
              width: 1920,
              height: 1080,
              url: 'https://dev.img.ultra.io/1920x1080/621399c8f68df41ffcc07baf/62153e4239b0ff95a02a9f29-1645559362953/original-images/teahub.io-ff8-wallpaper-1745451.jpg',
              size: null,
              uploadDate: null,
            },
          ],
        },
      },
      contentType: {
        id: '5eb58a492dce1cd42f84ce9d',
        code: 'game',
        icon: 'icon-gamepad-light',
        name: 'Game',
      },
    },
  ],
});

export const gameInfoPreview = new GameDetail({
  id: '12345',
  publisher: {
    id: '111',
  },
  liveGame: {
    title: 'Game Title',
    multimedia: {
      largeHeroImage: {
        base: {
          url: gamePlaceholderB64,
          height: 800,
          width: 1430,
        },
        name: 'background image',
        fileExtension: 'jpg',
        images: [
          {
            url: gamePlaceholderB64,
            height: 800,
            width: 1430,
          },
          {
            url: gamePlaceholderB64,
            height: 800,
            width: 1430,
          },
        ],
      },
      imageGalleryList: [
        {
          base: {
            url: gamePlaceholderB64,
            height: 800,
            width: 1430,
          },
          name: 'imageGalleryList image 1',
          fileExtension: 'jpg',
          images: [
            {
              url: gamePlaceholderB64,
              height: 800,
              width: 1430,
            },
            {
              url: gamePlaceholderB64,
              height: 800,
              width: 1430,
            },
          ],
        },
        {
          base: {
            url: gamePlaceholderB64,
            height: 800,
            width: 1430,
          },
          name: 'imageGalleryList image 2',
          fileExtension: 'jpg',
          images: [
            {
              url: gamePlaceholderB64,
              height: 800,
              width: 1430,
            },
            {
              url: gamePlaceholderB64,
              height: 800,
              width: 1430,
            },
          ],
        },
      ],
    },
  },
});

export const gameForCarousel = new Game({
  id: '5bb6505ddf97da0008fecb26',
  buyableTokenFactory: { id: '1234', prices: mockPrice },
  liveGame: {
    title: 'The Witcher® 3: Wild Hunt',
    categories: ['Action', 'RPG'],
    multimedia: {
      imageGalleryList: [],
    },
  },
});
