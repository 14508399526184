<ul>
  @for (uniq of uniqs; track trackBy(i, uniq); let i = $index) {
    <li class="uniq-list__item">
      <ultra-uniq-preview
        (click)="openUniqDialog(uniq)"
        [showTradability]="showTradability"
        [showTransferability]="showTransferability"
        [showAvailability]="showAvailability"
        [showStatusIcons]="showStatusIcons"
        [forSale]="forSale"
        [uniq]="uniq"
        [status]="uniq.status"
        [isUniqOwned]="userBlockChainId === uniq?.owner?.blockchainId"
        [shouldValidateMetadata]="shouldValidateMetadata"
        (buy)="onBuy(uniq)"
        [ultraDomEventsBusClick]="{
          id: uniq.onChainId,
          positionOnPage: i,
          type: SearchInsightsObjectTypes.UNIQ,
          eventType: SearchInsightsClickEventTypes.CLICK_AFTER_SEARCH
        }" />
    </li>
  }
</ul>
