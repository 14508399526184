import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IdCardContentModule, IdCardEntity, IdCardPropertyTabs } from '../../../id-card-content';
import { ActionsStatusComponent } from '../../../id-card-content/id-card-actions/components/actions-status/actions-status.component';
import { isPropertyLocked } from '../../../id-card-content/id-card-actions/helpers/id-card-actions-display.helper';
import { IdCardAction } from '../../../id-card-content/id-card-actions/id-card-actions.model';

/**
 * A dumb component that shows the Uniq properties in the Token Preview header.
 */
@Component({
  selector: 'ultra-preview-token-header-properties',
  templateUrl: 'preview-token-header-properties.component.html',
  standalone: true,
  imports: [ActionsStatusComponent, IdCardContentModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewTokenHeaderPropertiesComponent {
  private _idCardEntity: Partial<IdCardEntity>;
  @Input() set idCardEntity(idCardEntity: Partial<IdCardEntity>) {
    this._idCardEntity = idCardEntity;
    this.showTradabality =
      idCardEntity.tradability.enabled &&
      idCardEntity.tradingPeriod.resaleAvailability &&
      !isPropertyLocked(idCardEntity.tradingPeriod?.resaleAvailability);
    this.showTransferability =
      idCardEntity.transferability.enabled &&
      idCardEntity.transferPeriod?.transferAvailability &&
      !isPropertyLocked(idCardEntity.transferPeriod?.transferAvailability);
  }
  get idCardEntity(): Partial<IdCardEntity> {
    return this._idCardEntity;
  }

  showTradabality = false;
  showTransferability = false;
  /**
   * Emits when an icon is clicked to show the corresponding property
   * in the Token Preview body.
   */
  @Output() propertyClick: EventEmitter<IdCardPropertyTabs> = new EventEmitter<IdCardPropertyTabs>();

  readonly actions = IdCardAction;
  readonly properties = IdCardPropertyTabs;

  onPropertyClick(event: Event, property: IdCardPropertyTabs) {
    event.stopPropagation();
    event.preventDefault();
    this.propertyClick.emit(property);
  }
}
