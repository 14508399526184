import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoundProgressModule } from 'angular-svg-round-progressbar';

import { CircleProgressComponent } from './circle-progress.component';

@NgModule({
  imports: [CommonModule, RoundProgressModule],
  declarations: [CircleProgressComponent],
  exports: [CircleProgressComponent],
})
export class CircleProgressModule {}
