import Big from 'big.js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { ExchangeRate } from '../../../models/exchange';
import { UserCurrencyConversionService } from '../../../models/exchange/user-currency-conversion.service';
import { ITokenPrice } from '../../../models/token-factory';
import { ExchangeRateCalculator } from '../../../utils/exchange-rate';
import { ConversationRateType } from '../../game/helpers/helper.enums';
import { IBasketService } from '../interfaces/basket.service.interface';
import { IBasketItem } from '../interfaces/basket-item.interface';

export abstract class BasketService implements IBasketService {
  readonly items$: Observable<IBasketItem[]>;
  readonly itemsCount$: Observable<number>;
  readonly hasItems$: Observable<boolean>;
  readonly totalFiat$: Observable<ITokenPrice>;
  readonly totalUOS$: Observable<number>;
  readonly onClearItems$: Observable<void>;
  readonly loading$: Observable<boolean>;
  readonly conversionAvailable$: Observable<boolean>;

  protected _loading$ = new BehaviorSubject<boolean>(true);

  /*** private properties ***/
  private _items$ = new BehaviorSubject<IBasketItem[]>([]);
  private _clearItems$ = new Subject<void>();
  private _conversionAvailable$ = new Subject<boolean>();

  constructor(protected userCurrencyConversionService: UserCurrencyConversionService) {
    this.items$ = this._items$.asObservable();
    this.itemsCount$ = this.items$.pipe(map((items) => items.length));
    this.hasItems$ = this.itemsCount$.pipe(map((count) => count > 0));
    this.totalFiat$ = this.totalFiat();
    this.totalUOS$ = this.totalUOS();
    this.onClearItems$ = this._clearItems$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.conversionAvailable$ = this._conversionAvailable$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));
  }

  addItem(item: IBasketItem): Observable<void> {
    if (this.itemExist(item)) {
      return of(undefined);
    }

    return this.setItems([...this._items$.value, item]);
  }

  removeItem(tokenId: string): Observable<void> {
    return this.setItems(this._items$.value.filter((item) => item.id !== tokenId));
  }

  clearItems(emitEvent = true): Observable<void> {
    return this.setItems([]).pipe(
      tap(() => {
        if (emitEvent) {
          this._clearItems$.next();
        }
      })
    );
  }

  protected setItems(items: IBasketItem[]): Observable<void> {
    return of(undefined).pipe(
      tap(() => {
        this._items$.next(items);
      })
    );
  }

  protected abstract loadItems(): void;

  /*** private methods ***/

  private itemExist(item: IBasketItem): boolean {
    return this._items$.value.some(({ id }) => id === item.id);
  }

  // toDo: provide refactoring to get rid of if(!!items.length) condition
  protected totalFiat(): Observable<ITokenPrice> {
    return this.items$.pipe(
      filter((items) => !!items.length),
      map((items: IBasketItem[]) => {
        const totalAmount = items.reduce((sum, item) => sum.plus(item.price.amount), Big(0));
        const prices: ITokenPrice = {
          amount: +totalAmount,
          currency: items[0].price.currency,
          symbol: items[0].price.symbol,
        };
        return prices;
      })
    );
  }

  private totalUOS(): Observable<number> {
    return this.totalFiat$.pipe(
      switchMap((price) =>
        this.userCurrencyConversionService.fiatToUosExchange$.pipe(
          tap((rate: ExchangeRate) => {
            this._conversionAvailable$.next(!!rate);
          }),
          map(
            (rate: ExchangeRate) =>
              rate && ExchangeRateCalculator.convertPrice(price.amount, ConversationRateType.fromFiatToUos, rate)
          )
        )
      ),
      shareReplay(1)
    );
  }
}
