import { gql } from 'apollo-angular';

import { UNIQ_FACTORY_STATIC_COLLECTION_RESPONSE } from '../../gql/shared/queries/uniq-shared.query';

export const CREATE_STATIC_COLLECTION_MUTATION = gql`
  mutation staticCollection($_id: EntityId, $body: StaticCollectionBody!) {
    staticCollection {
      create(_id: $_id, body: $body) ${UNIQ_FACTORY_STATIC_COLLECTION_RESPONSE}
    }
  }
`;

export const UPDATE_STATIC_COLLECTION_MUTATION = gql`
  mutation staticCollection($_id: EntityId! $_v: EntityVersion! $body: StaticCollectionBody!) {
    staticCollection {
      update(_id: $_id, _v: $_v body: $body) ${UNIQ_FACTORY_STATIC_COLLECTION_RESPONSE}
    }
  }
`;
