import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
/**
 * Shows an uniq icon based on its type
 *
 * If showOrnament is true it also shows a background icon to decorate
 * the type icon.
 *
 * @usage
 * `
 * <ultra-uniq-type-icon showOrnament="true"
 *  type="collectible"
 *  size="sm">
 * </ultra-uniq-type-icon>
 * `
 */
@Component({
  selector: 'ultra-uniq-type-icon',
  templateUrl: './uniq-type-icon.component.html',
  styleUrls: ['./uniq-type-icon.component.scss'],
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqTypeIconComponent {
  /**
   * Set this property to true when you want to
   * show a box as background for the icon.
   */
  @Input() showOrnament = false;

  /**
   * The icon type will map to the icon name in the font
   *
   * Important: Remove `not-uniq` type when all games are tokenized
   */
  @Input() type: 'game' | 'collectible' | 'dlc' | 'not-uniq' = 'game';

  /**
   * Icon size
   * Except for ornament which has a fixed size and cannot be configured.
   */
  @Input() size: 'sm' | 'lg' | '24' | '32' | '16' = 'sm';

  /**
   * Opacity value to be applied to the icon.
   * Similar as tailwind do, the mapping values are:
   * 50 -> `opacity: 0.5`
   * 70 -> `opacity: 0.7`
   * 100 -> `opacity: 1` (default)
   */
  @Input() opacity: '50' | '70' | '100' = '100';

  get isTypeGame() {
    return this.type === 'game';
  }

  get isTypeCollectible() {
    return this.type === 'collectible';
  }

  get isTypeDLC() {
    return this.type === 'dlc';
  }

  /**
   *  Important: Remove this getter when all games are tokenized
   */
  get isNotUniq() {
    return this.type === 'not-uniq';
  }
}
