import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  NgZone,
  ViewEncapsulation,
} from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

import { DIALOG_DATA, DialogRef } from './../../dialog';
import { DetailDialog } from './../detail-dialog.base';

@Component({
  selector: 'ultra-uniq-detail-dialog',
  templateUrl: './uniq-detail-dialog.component.html',
  styleUrls: ['./uniq-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqDetailDialogComponent extends DetailDialog<UniqDetailDialogComponent> {
  isOwned: boolean;
  private cdr = inject(ChangeDetectorRef);
  private ngZone = inject(NgZone);

  constructor(
    @Inject(DIALOG_DATA) data: any,
    dialogRef: DialogRef<UniqDetailDialogComponent>,
    ngbTooltipConfig: NgbTooltipConfig
  ) {
    super(data, dialogRef, ngbTooltipConfig);
    this.isOwned = this.data.userBlockChainId === this.data.idCardEntity?.owner?.blockchainId;
    setTimeout(() => {
      this.ngZone.run(() => {
        this.cdr.detectChanges();
      });
    });
  }
}
