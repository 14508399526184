import { computed, inject, Injectable, Signal } from '@angular/core';

import { ISelectItem } from '../../modules/form';
import { GeofencingService } from '../../stores';

@Injectable({ providedIn: 'root' })
export class LanguagesService {
  readonly languages = inject(GeofencingService).languages;
  formattedLanguageOptions: Signal<ISelectItem[]> = computed(() =>
    this.languages().map((element) => ({
      lbl: element.label,
      value: element.code,
      dataId: `language-code-${element.code}`,
    })),
  );
}
