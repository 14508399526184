export interface SearchInsightsEventData {
  eventType: SearchInsightsEventType;
  type: SearchInsightsObjectType;
  id?: string;
  positionOnPage?: number;
  value?: any;
}

export enum SearchInsightsObjectType {
  UNIQ = 'uniq',
  UNIQ_FACTORY = 'uniqFactory',
  FILTER_UNIQ_TYPE = 'type',
  FILTER_SOLDOUT = 'launchpad_hidden',
  SEARCH_INPUT = 'query',
}

export enum SearchInsightsEventType {
  CLICK = 'click',
  CLICK_AFTER_SEARCH = 'clickAfterSearch',
  CONVERSION = 'convert',
  CONVERSION_AFTER_SEARCH = 'convertAfterSearch',
  FILTER_CLICK = 'filterClick',
  FILTER_VIEW = 'filterView',
  FILTER_CONVERSION = 'filterConversion',
}

export enum SearchInsightsEventNames {
  UNIQ_CLICKED = 'Uniq Clicked',
  UNIQ_FACTORY_CLICKED = 'Uniq Factory Clicked',
  UNIQ_TYPE_FILTER_CHANGED = 'Uniq Type Filter Changed',
  SEARCH_INPUT_CHANGED = 'Search Input Changed',
  SOLDOUT_CHANGED = 'Sold Out Changed',
}
