import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  numberAttribute,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ControlErrorDirective, ErrorsMap } from '../../../form-error';

@Component({
  selector: 'ultra-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  readonly id = `${Math.random()}`.replace('.', '');
  strLen: number;
  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() placeholder: string;
  @Input({ transform: booleanAttribute }) hideLength: boolean;
  @Input() cssClass: string;
  @Input() label: string;
  @Input() cssCountClass: string;
  @Input() customErrors: ErrorsMap;
  @Input() readonly: boolean;
  @Input() control: UntypedFormControl = new UntypedFormControl('');
  @Input({ transform: numberAttribute }) maxLength = 100;
  @Input() height: boolean;
  @Input() dataId: string;
  @Output() blurEvent: EventEmitter<Event> = new EventEmitter<Event>();

  @ViewChild('textarea', { read: ControlErrorDirective, static: true }) controlError: ControlErrorDirective;

  ngOnInit() {
    this.strLen = this.maxLength;
    this.charsLeft();
  }

  onKeyUp(): boolean {
    const textLen = this.control.value ? this.control.value.length : 0;

    if (textLen !== 0 && textLen <= this.maxLength) {
      this.charsLeft();
    } else {
      return false;
    }
  }

  /**
   * charsLeft
   * @desc check the amount of text entered
   */
  charsLeft(event?: Event): void {
    if (event) {
      this.blurEvent.emit(event);
    }

    return this.control.value ? this.control.value.length : 0;
  }
}
