import { Injectable } from '@angular/core';

import { GameDetail } from '../../../models/game/models/game-detail.model';
import { IImageData, IImageDetail } from '../../../models/multimedia/interfaces';

@Injectable()
export class GameDetailBackgroundService {
  private game?: GameDetail;
  private images: IImageDetail[];
  private imageIndex;

  getImage(game: GameDetail): string {
    if (!this.game || this.game.id !== game.id) {
      this.init(game);
    }

    return this.images[this.getImageIndex()].url;
  }

  private init(game: GameDetail) {
    this.game = game;
    this.images = this.getImages(game);
    this.imageIndex = -1;
  }

  private getImages(game): IImageDetail[] {
    return game.multimedia.imageGalleryList.flatMap((image: IImageData) => image.images);
  }

  private getImageIndex(): number {
    if (++this.imageIndex === this.images.length) {
      this.imageIndex = 0;
    }

    return this.imageIndex;
  }
}
