<nav aria-label="breadcrumb" class="position-relative" ultraAutotestAttribute="breadcrumbs">
  <ul class="crumbs d-flex align-items-center" [ngClass]="customClass">
    @for (item of breadCrumbs; track item; let last = $last) {
      <li class="crumbs-item text-capitalize" aria-current="page" [ultraAutotestAttribute]="item?.dataId">
        @if (item.link) {
          <a
            [ngbTooltip]="item.title"
            [disableTooltip]="item.title?.length <= ellipsisLength"
            [tooltipClass]="tooltipClass"
            [routerLink]="[item.link]"
            [queryParams]="item.queryParams">
            {{ item.title | ellipsis: ellipsisLength }}</a
          >
        } @else {
          <span
            [ngbTooltip]="item.title"
            [disableTooltip]="item.title?.length <= ellipsisLength"
            [tooltipClass]="tooltipClass">
            {{ item?.title | ellipsis: ellipsisLength }}
          </span>
        }
      </li>
      @if (!last && item.link) {
        <li class="crumbs-separator px-1">
          <i class="icon icon-chevron-sm-right bg-white-alpha-7"></i>
        </li>
      }
    }
  </ul>
</nav>
