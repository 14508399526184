import { ContentSkeletonTheme } from '../content-skeleton-theme.enum';

const PARAGRAPH_LINES = 8;

/**
 * Depending on the theme class, we could modify what classes to return in order to help us create themes. Or just return the theme class.
 */
export const handleThemeClass = (index: number, themeClass: ContentSkeletonTheme): string[] | null => {
  if (themeClass) {
    // If we use two elements, we will apply MD_TITLE and XS_TITLE styles only to the first one.
    if ([ContentSkeletonTheme.MD_TITLE, ContentSkeletonTheme.XS_TITLE].includes(themeClass) && index % 2 !== 0) {
      return null;
    }

    // Adding class to Paragraph theme in order to apply styles only to last line
    if ([ContentSkeletonTheme.PARAGRAPH].includes(themeClass) && (index + 1) % PARAGRAPH_LINES === 0) {
      return [themeClass, 'last-paragraph-line'];
    }

    return [themeClass];
  }
};

export const repeatElementByTemplate = (themeClass: string, elementNumber: number) => {
  if (themeClass === ContentSkeletonTheme.PARAGRAPH) {
    return PARAGRAPH_LINES * elementNumber;
  }

  return elementNumber;
};
