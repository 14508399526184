import { InjectionToken, ValueProvider } from '@angular/core';

export interface IdCardConfig {
  isAllowProductDetails?: boolean;
}

export const ID_CARD_CONFIG = new InjectionToken<IdCardConfig>('id-card config');

export const ID_CARD_CONFIG_DEFAULT: IdCardConfig = {
  isAllowProductDetails: true,
};

export const ID_CARD_CONFIG_DEFAULT_PROVIDER: ValueProvider = {
  provide: ID_CARD_CONFIG,
  useValue: ID_CARD_CONFIG_DEFAULT,
};
