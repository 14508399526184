import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2,
  TemplateRef,
} from '@angular/core';

import { TagModel } from '../models/tag-model';

@Component({
  selector: 'ultra-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input()
  public model: TagModel;

  // Todo tag refactoring
  @Input()
  public removable = true;
  // Todo tag refactoring
  @Input()
  public editable = true;

  @Input()
  public template: TemplateRef<any>;

  @Input()
  public displayBy: string;

  @Input()
  public identifyBy: string;

  @Input()
  public index: number;

  @Input()
  public disabled = false;
  // Todo tag refactoring
  @Input()
  public canAddTag: (tag: TagModel) => boolean;

  @Output()
  public removed: EventEmitter<TagModel> = new EventEmitter<TagModel>();

  @Output()
  public blurred: EventEmitter<TagModel> = new EventEmitter<TagModel>();
  // Todo tag refactoring
  @Output()
  public keyDown: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public tagEdited: EventEmitter<TagModel> = new EventEmitter<TagModel>();

  @HostBinding('class.invalid')
  get inValid() {
    return this.model.invalid;
  }

  public get readonly(): boolean {
    return typeof this.model !== 'string' && this.model.readonly === true;
  }

  public editing = false;

  constructor(public element: ElementRef, public renderer: Renderer2, private cdRef: ChangeDetectorRef) {}

  public getDisplayValue(): string {
    return typeof this.model === 'string' ? this.model : this.model[this.displayBy];
  }

  public isDeleteIconVisible(): boolean {
    return !this.readonly && !this.disabled && this.removable && !this.editing;
  }

  public remove($event: MouseEvent) {
    $event.stopPropagation();
    this.removed.emit(this.model);
  }

  public activateEditMode(): void {
    if (this.editable && !this.editing) {
      const classList = this.element.nativeElement.classList;
      classList.add('tag-edit-mode');
      this.editing = true;
    }
  }

  public blink(): void {
    const classList = this.element.nativeElement.classList;
    classList.add('blink');
    setTimeout(() => classList.remove('blink'), 250);
  }
}
