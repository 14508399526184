<ultra-slider
  class="carousel media-carousel"
  [theme]="theme"
  [innerPagination]="innerPagination"
  [goToSlideIndex]="slideIndex"
  paginationType="bullets"
  [updateLoopContent]="updateLoopContent"
  (slideChange)="pauseActiveVideo()">
  @for (media of multiMedia; track media) {
    <ultraSliderContent>
      <ng-template ultraSwiperTemplate>
        @if (media.videoId) {
          @if (media.provider === 'youtube') {
            <div #videoItem class="media-carousel-item">
              @if (slideReSize$ | async; as resize) {
                <youtube-player
                  class="media-carousel-video"
                  [id]="'video-player-' + media.videoId"
                  [videoId]="media.videoId"
                  [height]="resize.height"
                  [width]="resize.width"
                  [playerVars]="playerConfig"
                  (ready)="updateSliderVideoMedia($event.target)" />
              }
            </div>
          }
        } @else {
          @if (media.contentType === 'VIDEO') {
            <div class="media-carousel-item">
              <div class="w-100 h-100 media-carousel-video">
                <ultra-media-video-player [src]="media.uri" [play]="false" />
              </div>
            </div>
          } @else {
            <div class="media-carousel-item">
              <div
                ultraAutotestAttribute="media-carousel-image-container"
                class="media-carousel-image"
                [ngStyle]="{ 'background-image': 'url(' + (media?.url || media?.uri | resizeImage: 720 : 400) + ')' }"
                (click)="expandable && openMediaExpandedDialog(media)"></div>
            </div>
          }
        }
      </ng-template>
    </ultraSliderContent>
  }
</ultra-slider>
