export interface IAgeRating {
  id?: string;
  country: string;
  name: string;
  categories: ICategory[];
  descriptors: IDescriptor[];
  // rating: IRating;
}

export interface IDescriptor {
  descriptorId: string;
  name: string;
  displayName?: string;
  image: string;
  active?: boolean;
  cssClass?: string;
  disabled?: boolean;
  dataId?: string;
}

export interface ICategory {
  categoryId?: string;
  name: string;
  image: string;
  rounded?: boolean;
}

export interface IAgeRatingData {
  ageRatingId: string;
  country: string;
  name: string;
  category: ICategory;
  descriptors?: IDescriptor[];
  descriptorsDescription?: string;
}

export enum RatingSystem {
  PEGI = 'PEGI',
  ESRB = 'ESRB',
  CERO = 'CERO',
}

export const RatingSystemCategoriesDescription = {
  [RatingSystem.PEGI]: {
    'rp-1':
      'The content of games with a PEGI 3 rating is considered suitable for all age groups. The game should not contain any sounds or pictures that are likely to frighten young children. A very mild form of violence (in a comical context or a childlike setting) is acceptable. No bad language should be heard.',
    'rp-2':
      'Game content with scenes or sounds that can possibly be frightening to younger children should fall in this category. Very mild forms of violence (implied, non-detailed, or non-realistic violence) are acceptable for a game with a PEGI 7 rating.',
    'rp-3':
      'Video games that show violence of a slightly more graphic nature towards fantasy characters or non-realistic violence towards human-like characters would fall in this age category. Sexual innuendo or sexual posturing can be present, while any bad language in this category must be mild. Gambling as it is normally carried out in real life in casinos or gambling halls can also be present (e.g. card games that in real life would be played for money).',
    'rp-4':
      'This rating is applied once the depiction of violence (or sexual activity) reaches a stage that looks the same as would be expected in real life. The use of bad language in games with a PEGI 16 rating can be more extreme, while games of chance, and the use of tobacco, alcohol or illegal drugs can also be present.',
    'rp-5':
      'The 18 rating, which indicates content suitable only for adults, is applied when the level of violence reaches a stage where it becomes a depiction of gross violence, apparently motiveless killing, or violence towards defenceless characters. The glamorisation of the use of illegal drugs and explicit sexual activity should also fall into this age category.',
  },
  [RatingSystem.ESRB]: {
    'rp-1':
      'Content is generally suitable for ages 3 and older. Contains no material that parents would find inappropriate.',
    'rp-2':
      'Content is generally suitable for all ages. May contain minimal cartoon, fantasy or mild violence and/or infrequent use of mild language.',
    'rp-3':
      'Content is generally suitable for ages 10 and up. May contain more cartoon, fantasy or mild violence, mild language and/or minimal suggestive themes.',
    'rp-4':
      'Content is generally suitable for ages 13 and up. May contain violence, suggestive themes, crude humor, minimal blood, simulated gambling and/or infrequent use of strong language.',
    'rp-5':
      'Content is generally suitable for ages 17 and up. May contain intense violence, blood and gore, sexual content and/or strong language.',
    'rp-6':
      'Content suitable only for adults ages 18 and up. May include prolonged scenes of intense violence, graphic sexual content and/or gambling with real currency.',
  },
  [RatingSystem.CERO]: {
    'rp-1':
      'Expressions and content subjected to age-specific limitation are not included in the game, thereby being suitable for all ages. All games that used to be rated All go into this category.',
    'rp-2':
      'Expression and content suitable only to 12-year-olds and above are included in the game. All games that used to be rated 12 go into this category.',
    'rp-3':
      'Expression and content suitable only to 15-year-olds and above are included in the game. All games that used to be rated 15 go into this category.',
    'rp-4':
      'Contains adult material. Anyone under 17 cannot buy video games with this rating without parental consent. Expression and content suitable only to 17-year-olds and above are included in the game. Some games that used to be rated 18 go into this category.',
    'rp-5':
      'Contains strong adult material. It is illegal for anyone under 18 to buy video games with this rating. Expression and content suitable only to 18-year-olds and above are included in the game. Some games that used to be rated 18 go into this category.',
  },
};
