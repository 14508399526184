import { Game } from '../../../models/game/models/game.model';

export const cartGameMock = new Game({
  id: '5bb6505ddf97da0008fecb26',
  publisher: { id: '1' },
  liveGame: {
    title: 'The Witcher® 3: Wild Hunt',
    description: `Description Text`,
    descriptionShort: `Description Short Text`,
    categories: ['Action', 'RPG'],
    editorName: 'Guerrilla Games',
    developerName: 'cd project red',
    releaseDate: {
      year: 2020,
      month: 7,
      day: 27,
    },
    lastUpdate: {
      date: '2018-09-28T00:00:00.000Z',
      version: '1.0.1a',
    },
  },
  tokenFactories: [
    {
      id: 'id',
      blockchainId: 'blockchainId',
      name: 'Standard edition',
      image: {
        images: [
          {
            url: '',
          },
        ],
      },
      prices: [
        {
          amount: 99,
          currency: 'USD',
          symbol: '$',
        },
      ],
      contentType: {
        id: '',
        code: 'game',
        name: 'Game',
      },
    },
  ],
});
