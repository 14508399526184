@if (label) {
  <label class="form-label d-block rarefied-text mb-2" [for]="id">{{ label }}</label>
}
<div class="textarea-content position-relative {{ cssClass }}" [class.disabled]="readonly" [class.h-100]="height">
  <textarea
    class="textarea textarea-{{ size }} d-block"
    [id]="id"
    [class.h-100]="height"
    [class.input--invalid]="control.invalid && control.touched"
    [formControl]="control"
    [maxLength]="maxLength"
    [attr.disabled]="readonly"
    [placeholder]="placeholder || ''"
    [ultraAutotestAttribute]="dataId"
    (click)="$event.stopPropagation()"
    (keyup)="onKeyUp()"
    (blur)="charsLeft($event)"
    [customControlErrors]="customErrors"></textarea>

  @if (!hideLength) {
    <span class="maxlen f-size-10 ls-one-and-half text-white-alpha-25 {{ cssCountClass }}">
      {{ (control.value && control.value.length) || 0 }} / {{ maxLength }}
    </span>
  }
</div>
