import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConfigFieldsType, IConfigField } from '../../../../interfaces/requirements.interface';
import { GameDetail } from '../../../../models/game/models/game-detail.model';
import { SystemRequirement } from '../../../../models/game/models/system-requirement.model';
import { ContentSkeletonTheme } from '../../../../modules/skeleton/content-skeleton/content-skeleton-theme.enum';

const measuresUnitsRegex = /bytes|kb|mb|gb|tb/g;

@Component({
  selector: 'ultra-game-detail-requirements',
  templateUrl: './game-detail-requirements.component.html',
  styleUrls: ['./game-detail-requirements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailRequirementsComponent {
  @Input()
  game: GameDetail;

  @Input()
  isRouteChange = false;

  readonly requirementsLevels: [string, string] = ['minimum', 'recommended'];

  readonly placeholderTheme = ContentSkeletonTheme;

  requirementsFields: IConfigField[] = [
    { name: ConfigFieldsType.os, id: 'os' },
    { name: ConfigFieldsType.processor, id: 'processor' },
    { name: ConfigFieldsType.memory, id: 'memory' },
    { name: ConfigFieldsType.graphics, id: 'graphics' },
    { name: ConfigFieldsType.storage, id: 'storage', suffix: 'available space' },
    { name: ConfigFieldsType.soundCard, id: 'soundCard' },
    { name: ConfigFieldsType.minimumResolution, id: 'minimumResolution' },
  ];

  hasRequirementValues(): SystemRequirement {
    return this.game.systemsRequirements[0];
  }

  getRequirementValue(level, field): string {
    const value = this.game.systemsRequirements[0] ? this.game.systemsRequirements[0][level][field] : '';
    if (field === 'memory' || field === 'storage') {
      return value.toLowerCase().replace(measuresUnitsRegex, (substring) => {
        if (substring === 'bytes') {
          return substring[0].toUpperCase() + substring.substring(1);
        }
        return substring.toUpperCase();
      });
    }
    return value;
  }
}
