import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'ultra-media-video-player',
  templateUrl: './media-video-player.component.html',
  styleUrls: ['./media-video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaVideoPlayerComponent {
  @Input() src: string;
  @Input() posterSrc: string;
  @Input() play = true;
  @Input() set shouldStopPlayer(value: boolean) {
    setTimeout(() => {
      if (this.video) {
        if (value) {
          this.video.nativeElement.pause();
          this.video.nativeElement.currentTime = 0;
        } else {
          this.video.nativeElement.play();
        }
      }
    }); // UI is not rendered when @Input() executed - logic to be applied in next event loop
  }
  @ViewChild('video') video: ElementRef;

  playVideo() {
    this.play = true;
    this.video.nativeElement.play();
  }
}
