import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CustomScrollStrategyOptionsService } from '../../services/overlay-scroll-strategy/custom-scroll-strategy-options.service';

import { OverlayEvent } from './overlay-event';
import { OverlayEventBusService } from './overlay-event-bus.service';

let nextUniqueId = 0;

export type ScrollStrategyName = 'noop' | 'close' | 'reposition' | 'block' | 'customBlock';

@Directive({
  selector: '[ultraOverlayHost]',
  exportAs: 'overlayHost',
})
export class OverlayHostDirective implements AfterViewInit, OnDestroy {
  @Input()
  @HostBinding('attr.id')
  public get id(): string {
    return this._uid;
  }

  public set id(value: string) {
    this._uid = value || this._uid;
  }

  @Input()
  public positions: Partial<ConnectedPosition>;

  @Input()
  public template: TemplateRef<any>;

  @Input()
  public context: any;

  @Input()
  backdropClass: string | string[] = null;

  @Input()
  panelClass?: string | string[] = null;

  @Input()
  scrollStrategy: ScrollStrategyName = 'reposition';

  public get isOverlayOpen(): boolean {
    if (!this.overlayRef) {
      return false;
    }
    return this.overlayRef.hasAttached();
  }

  private _uid = `tooltip-host-${nextUniqueId++}`;
  private overlayRef: OverlayRef;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private eventBus: OverlayEventBusService,
    private customScrollStrategy: CustomScrollStrategyOptionsService
  ) {
    // Following line of code is not actually not self-assign, it's calling setter with getter value.
    // This is done in order to guarantee that id is set (given or default one uid)
    this.id = this.id; // eslint-disable-line no-self-assign
  }

  ngAfterViewInit(): void {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        ...this.positions,
      },
    ]);
    const scrollStrategy = this.setScrollPosition(this.scrollStrategy);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: this.backdropClass,
      panelClass: this.panelClass,
    });

    this.eventBus
      .pipe(
        filter((event: OverlayEvent) => event.target === this.id),
        takeUntil(this.destroy$)
      )
      .subscribe((event: OverlayEvent) => {
        if (event.action === 'open') {
          this.overlayRef.attach(
            new TemplatePortal(this.template, this.viewContainerRef, {
              ...this.context,
              overlayRef: this.overlayRef,
            })
          );
          this.overlayRef.updatePosition();
        } else {
          this.overlayRef.detach();
        }
      });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.overlayRef.detach();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setScrollPosition(strategy: ScrollStrategyName) {
    switch (strategy) {
      case 'customBlock':
        return this.customScrollStrategy.customBlock();
      case 'block':
        return this.overlay.scrollStrategies.block();
      case 'close':
        return this.overlay.scrollStrategies.close();
      case 'noop':
        return this.overlay.scrollStrategies.noop();
      case 'reposition':
        return this.overlay.scrollStrategies.reposition();
    }
  }
}
