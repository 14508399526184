export enum EntityStatus {
  PUBLISHED = 'PUBLISHED',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  NOT_PUBLISHED = 'NOT_PUBLISHED',
  DRAFT = 'DRAFT',
  READY_TO_PUBLISH = 'READY TO PUBLISH',
  PLANNED = 'PLANNED',
  PENDING = 'PENDING',
  UNPUBLISHED = 'UNPUBLISHED',
  EXPIRED = 'EXPIRED',
}

export const EntityStatusMap = {
  [EntityStatus.PUBLISHED]: 'PUBLISHED',
  [EntityStatus.DISABLED]: 'DISABLED',
  [EntityStatus.ENABLED]: 'PUBLISHING_ALLOWED',
  [EntityStatus.NOT_PUBLISHED]: 'DRAFT',
  [EntityStatus.READY_TO_PUBLISH]: 'READY_TO_PUBLISH',
  [EntityStatus.PENDING]: 'PENDING',
  [EntityStatus.PLANNED]: 'PLANNED',
  [EntityStatus.UNPUBLISHED]: 'UNPUBLISHED',
  [EntityStatus.EXPIRED]: 'EXPIRED',
};
