import { Injectable } from '@angular/core';
import Big from 'big.js';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { MainStorageService, StorageKey } from '@ultra/core/services';
import { UserFacadeService } from '@ultra/core/stores';

import { ITokenPrice } from '../../../models';
import { UserCurrencyConversionService } from '../../../models/exchange/user-currency-conversion.service';
import { Game } from '../../../models/game/models/game.model';
import { GameGqlService } from '../../../models/game/services/game-gql.service';
import { IStorageBasketItem } from '../interfaces/basket-item.interface';
import { TokenFactoryItem } from '../models/token-factory-item.model';

import { BasketService } from './basket.service';

@Injectable({
  providedIn: 'root',
})
export class TokenFactoryBasketService extends BasketService {
  // todo: get basket from URL instead of storage
  private userId;
  constructor(
    private mainStorageService: MainStorageService,
    private gameService: GameGqlService,
    userCurrencyConversionService: UserCurrencyConversionService,
    private userFacadeService: UserFacadeService,
  ) {
    super(userCurrencyConversionService);
    this.loadItems();
  }

  setItems(items: TokenFactoryItem[], persist = true): Observable<void> {
    return super.setItems(items).pipe(
      switchMap(() =>
        persist
          ? this.mainStorageService.setObject<IStorageBasketItem[]>(
              `${StorageKey.SHOPPING_CART}-${this.userId}`,
              items.map((item) => item.toStorage()),
            )
          : of(undefined),
      ),
    );
  }

  totalFiat(): Observable<ITokenPrice> {
    return this.items$.pipe(
      filter((items) => !!items.length),
      map((items: TokenFactoryItem[]) => {
        let amount = Big(0);
        let originalAmount = Big(0);
        items.forEach((item) => {
          amount = amount.plus(item.price.amount);
          originalAmount = originalAmount.plus(item.price.originalAmount);
        });
        const prices: ITokenPrice = {
          amount: +amount,
          originalAmount: +originalAmount,
          currency: items[0].price.currency,
          symbol: items[0].price.symbol,
        };
        return prices;
      }),
    );
  }

  /*** private methods ***/

  protected loadItems(): void {
    this.userFacadeService.user$
      .pipe(
        filter(({ id }) => Boolean(id)),
        take(1),
        tap(({ id }) => (this.userId = id)),
        switchMap((user) =>
          this.mainStorageService.getObject<IStorageBasketItem[]>(`${StorageKey.SHOPPING_CART}-${user.id}`),
        ),
        tap(() => this._loading$.next(true)),
        filter((items) => items && items.length > 0),
        switchMap((items) => {
          const gameIds = items.map((item) => item.gameId);

          return this.gameService.getCartGamesById(gameIds).pipe(map((games) => this.gamesToBasketItems(games, items)));
        }),
        switchMap((basketItems) => this.setItems(basketItems, false)),
        finalize(() => this._loading$.next(false)),
      )
      .subscribe();
  }

  private gamesToBasketItems(games: Game[], storageItems: IStorageBasketItem[]): TokenFactoryItem[] {
    const gamesMap = new Map(games.map<[string, Game]>((game) => [game.id, game]));

    return storageItems.map((cartItem) => {
      const game = gamesMap.get(cartItem.gameId);
      const tokenFactory = game.getTokenFactoryById(cartItem.tokenId);

      return new TokenFactoryItem(game, tokenFactory);
    });
  }
}
