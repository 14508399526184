<div class="content-background">
  <div class="pt-2 pb-2 ps-1 ms-1 pe-1 block-border">
    <div class="medium-block"></div>
  </div>

  <div class="pt-2 pb-2 ps-1 ms-1 pe-1 block-border">
    <div class="medium-block"></div>
  </div>

  <div class="pt-2 pb-2 ps-1 ms-1 pe-1">
    <div class="medium-block"></div>
  </div>
</div>
