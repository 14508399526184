import { MultipleQueriesQuery } from '@algolia/client-search';
import { Injectable } from '@angular/core';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { defer } from 'rxjs';

import { EnvironmentConfig } from '@ultra/environment';

export interface AlgoliaQuery<T = string> {
  indexName: string; // enum
  attributesToRetrieve?: T[]; // key of Game | key of TokenFactory
  filters?: string;
  search?: string;
  offset?: number;
  length?: number;
  facets?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AlgoliaSearchService {
  private client: SearchClient;

  constructor(private envConfig: EnvironmentConfig) {
    this.client = algoliasearch(this.envConfig.ALGOLIA_APP_ID, this.envConfig.ALGOLIA_PUB_KEY);
  }

  /**
   * Search for a single index
   * @param query
   */
  search<T>(query: AlgoliaQuery) {
    return this.multipleQueries<T>([query]);
  }

  multipleQueries<T>(queries: AlgoliaQuery[]) {
    const algoliaQueries: MultipleQueriesQuery[] = queries.map((query) => ({
      indexName: query.indexName,
      params: {
        attributesToRetrieve: query.attributesToRetrieve ?? ['*'],
        filters: query.filters ?? '',
        query: query.search ?? '',
        offset: query.offset ?? 0,
        length: query.length ?? 15,
        facets: query.facets ?? [],
      },
    }));
    return defer(() => this.client.multipleQueries<T>(algoliaQueries));
  }
}
