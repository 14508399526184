import { GameDetail } from '../../game/models/game-detail.model';
import { ITokenFactory } from '../../token-factory/interfaces/token-factory.interface';
import { IUserGame } from '../interfaces/user-game.interface';

export class UserGame implements IUserGame {
  game: GameDetail;
  installationDirectory?: boolean;
  state?: string;
  autoUpdate?: boolean;
  tokenFactories?: ITokenFactory[];

  constructor(data: IUserGame) {
    Object.assign(this, data);
    this.game = new GameDetail(data.game);
    this.tokenFactories = data.tokenFactories || [];
  }
}
