import { IImageData } from './image-data.interface';

export enum VideoProvider {
  Youtube = 'youtube',
}

export interface IVideo {
  videoId: string;
  provider: VideoProvider;
  duration: number;
  title?: string;
  image: IImageData;
  company?: string;
  invalid?: boolean;
  displayOrder?: number;
}
