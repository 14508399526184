import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ultra-background-skeleton',
  templateUrl: './background-skeleton.component.html',
  styleUrls: ['./background-skeleton.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BackgroundSkeletonComponent {
  @Input() delay = 0;
}
