<ultra-game-detail-background-container [isRouteChange]="isRouteChange" [game]="game">
  <div class="container mb-3">
    <h5 class="pt-3 s1-bold">
      <ng-container *contentSkeleton="isRouteChange; className: ['mb-3']; theme: placeholderTheme.MD_TITLE" i18n
        >System Requirements</ng-container
      >
    </h5>
    <dl class="row">
      @for (level of requirementsLevels; track level) {
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <ng-container
            *contentSkeleton="
              isRouteChange;
              className: ['mb-2'];
              repeat: 2;
              height: '588px';
              theme: placeholderTheme.XS_TITLE
            ">
            <div class="py-3 b1 text-capitalize">
              <ng-container i18n>{{ level }}</ng-container>
            </div>
            <div class="box--transparent-blur-border">
              @if (hasRequirementValues()) {
                @for (field of requirementsFields; track field) {
                  <dt class="overline-medium text-white-alpha-5">
                    <ng-container i18n>{{ field.name }}</ng-container>
                  </dt>
                  <dd
                    class="b2-regular"
                    [ultraAutotestAttribute]="'game-details-requirements-' + level + '-' + field.id">
                    {{ getRequirementValue(level, field.id) }} {{ field.suffix }}
                  </dd>
                }
              } @else {
                <span class="b2-regular">
                  <ng-container i18n>No data</ng-container>
                </span>
              }
            </div>
          </ng-container>
        </div>
      }
    </dl>
  </div>
</ultra-game-detail-background-container>
