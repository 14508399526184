<div
  [ngStyle]="{ visibility: idCardEntity?.serialNumber ? 'visible' : 'hidden' }"
  class="product-availability align-items-center mt-1 text-white-alpha-5">
  @if (isUniqDetailsAvailable) {
    <a
      class="product-availability__serial-number overline-medium d-inline-block"
      ultraAutotestAttribute="product-availability-serial-number-link"
      [routerLink]="['/product', 'uniq', idCardEntity?.blockchainId]"
      target="_blank"
      >#{{ idCardEntity?.serialNumber }}</a
    >
  } @else {
    <span class="overline-medium text-white-alpha-5">#{{ idCardEntity?.serialNumber }}</span>
  }

  @if (idCardEntity?.quantity?.maxMintableTokens) {
    <span>
      <span class="f-size-16 ms-1">/</span>
      <span class="product-availability__max-cap overline-medium d-inline-block text-white-alpha-5">{{
        idCardEntity.quantity.maxMintableTokens
      }}</span>
    </span>
  }
</div>
