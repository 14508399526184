import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  catchError,
  defer,
  distinctUntilChanged,
  filter,
  iif,
  map,
  merge,
  Observable,
  of,
  retry,
  share,
  shareReplay,
  switchMap,
  timer,
} from 'rxjs';

import { JwtPermissionsTransformer } from '../../helpers/jwt-permissions.transformer';
import { IAuthTokenUser } from '../../models/user/interfaces/user.interface';
import { WINDOW } from '../../providers/window.provider';
import { ClientFeatureService } from '../client-feature.service';
import { SessionStorageKeys } from '../helpers';
import { UltraosApiService } from '../ultraos';

import { AuthConfigService } from './auth-config';
import { AuthEventsService } from './auth-events.service';

@UntilDestroy()
@Injectable()
export class AuthService {
  private window = inject(WINDOW);
  protected router = inject(Router);
  protected oAuthService = inject(OAuthService);
  protected authConfig = inject(AuthConfigService);
  protected authEventsService = inject(AuthEventsService);
  private ultraOsApiService = inject(UltraosApiService);
  protected clientFeatureService = inject(ClientFeatureService);
  private jwtHelper = new JwtHelperService();

  private isSessionLoadingDoneSubject = new BehaviorSubject<true>(null);
  readonly isSessionLoadingDone$: Observable<true> = this.isSessionLoadingDoneSubject.pipe(filter((v) => v === true));

  private authenticationChange$: Observable<boolean> = merge(
    this.isSessionLoadingDone$,
    this.authEventsService.accessTokenReceived$,
    this.authEventsService.accessTokenExpired$,
    this.authEventsService.logout$,
  ).pipe(map(() => this.isAuthenticatedInternal()));
  /**
   * Emits boolean values indicating the authentication state of the user.
   * This depends on the value of `isSessionLoadingDone$`, so there is no need to manually check the loading state.
   */
  readonly isAuthenticated$: Observable<boolean> = this.authenticationChange$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  /**
   * Emits the current access_token. Updates each time the access_token is updated, expired or removed.
   */
  readonly accessToken$: Observable<string> = this.authenticationChange$.pipe(
    map((authenticated) => (authenticated ? this.getAccessToken() : undefined)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private refreshAccessToken$ = defer(async () => {
    await this.oAuthService.refreshToken();
  }).pipe(
    catchError(() => this.silentLogin()),
    share(), // Prevents multiple refresh requests in parallel
  );

  initialize(): void {
    this.configure()
      .then(() => this.tryLoadSession())
      .finally(() => {
        // Notify session loading process is complete
        this.isSessionLoadingDoneSubject.next(true);
      });
  }

  async configure(): Promise<void> {
    const authConfig = this.authConfig.get();
    this.oAuthService.configure(authConfig);
    await this.oAuthService.loadDiscoveryDocument();
    if (authConfig.automaticSilentRefresh) {
      this.oAuthService.setupAutomaticSilentRefresh();
    }
    if (authConfig.automaticLogout) {
      this.setupAutomaticLogout();
    }
    this.startAccessTokenSyncOnClient();
  }

  /**
   * Tries to get a session from:
   *  1. Url query params (Code flow callback)
   *  2. Existing access_token in the storage
   *  3. Session cookie (Silent login)
   * @private
   */
  private async tryLoadSession(): Promise<void> {
    if (this.shouldHandleCallback()) {
      await this.handleRedirectCallback().catch(() => null);
    }

    const isAuthenticated = this.isAuthenticatedInternal();

    if (!isAuthenticated) {
      // if not authenticated, try to load the session from the cookies using an iframe
      await this.silentLogin().catch(() => null);
    }
  }

  /**
   * Shows the app login page to the user
   * @param {string} routerRedirectUrl - Angular route to be restored after login
   */
  showLoginPage(routerRedirectUrl?: string): void {
    if (this.clientFeatureService.isInClient) {
      this.ultraOsApiService.displayDefaultPage();
    } else {
      this.loginWithRedirect(routerRedirectUrl);
    }
  }

  /**
   * Redirects to the authorization endpoint to start login.
   * @param {string} routerRedirectUrl - Angular route to be restored after login
   */
  loginWithRedirect(routerRedirectUrl?: string): void {
    this.oAuthService.initCodeFlow(routerRedirectUrl || this.router.url);
  }

  /**
   * After the browser redirects back from the authentication server
   * call `handleRedirectCallback` to handle success and error responses.
   * @return {string} Angular route to be restored after login
   */
  private async handleRedirectCallback(): Promise<void> {
    await this.oAuthService.tryLoginCodeFlow();
    await this.navigateToRouterUrlFromState();
  }

  /**
   * Retrieve from state the Angular route to be restored after login and navigates to it.
   * @private
   */
  private navigateToRouterUrlFromState(): Promise<boolean> {
    if (this.oAuthService.state && this.oAuthService.state !== 'undefined' && this.oAuthService.state !== 'null') {
      const routerUrl = decodeURIComponent(this.oAuthService.state);
      if (typeof routerUrl === 'string' && routerUrl.startsWith('/')) {
        return this.router.navigateByUrl(routerUrl);
      }
    }
  }

  /**
   * Get the access token from the storage
   */
  getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  getAuthorizationHeader(): string {
    return `Bearer ${this.getAccessToken()}`;
  }

  /**
   * Whether there is a not expired access token in the storage
   * Note: Can give wrong values if called before session loading is complete
   */
  isAuthenticated(): boolean {
    if (this.isSessionLoadingDoneSubject.value !== true) {
      console.warn('AuthService.isAuthenticated() called before initialization finished');
    }
    return this.isAuthenticatedInternal();
  }

  private isAuthenticatedInternal(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  /**
   * Renew the access token using a refresh_token or session cookie
   */
  refreshAccessToken(): Observable<void> {
    return this.refreshAccessToken$;
  }

  /**
   * Get an access token silently using the code flow in a hidden iframe
   * If there is a valid session cookie the flow will be completed successfully
   */
  protected async silentLogin(): Promise<void> {
    await this.oAuthService.silentRefresh();
  }

  logout(customParameters?: boolean | object): void {
    this.oAuthService.logOut(customParameters);
  }

  /**
   * Workaround for GP-14137
   * Once the user account is irreversible the backend expects the users to provide a token
   * containing the blockchain id. This function refreshes the user's token and checks for
   * the existence of the blockchain id.
   * @returns {Observable<boolean>} true if the new token contains a blockchain id
   */
  waitForBlockchainIdInToken(): Observable<boolean> {
    return of(true).pipe(
      map(() => {
        const decodedToken = this.decodeToken(this.getIdToken());
        if (!decodedToken.blockchain_id) {
          throw new Error('BlockchainId is missing');
        }
        return true;
      }),
      retry({
        count: 5,
        delay: (_error) => timer(5000).pipe(switchMap(() => this.refreshAccessToken())),
      }),
    );
  }

  getAuthTokenUser(): IAuthTokenUser {
    const decodedIdToken = this.decodeToken(this.getIdToken());
    const decodedAccessToken = this.decodeToken(this.getAccessToken());

    return {
      permission: decodedIdToken && JwtPermissionsTransformer.transform(decodedIdToken.permission),
      resourceAccess: decodedAccessToken.resource_access,
      referralLink: decodedIdToken?.referral.link,
      authData: {
        username: decodedIdToken?.preferred_username,
        lastname: decodedIdToken?.family_name,
        firstname: decodedIdToken?.given_name,
      },
    };
  }

  /**
   * Get the id_token from the storage
   */
  private getIdToken(): string {
    return this.oAuthService.getIdToken();
  }

  private decodeToken(token: string): any {
    return this.jwtHelper.decodeToken(token);
  }

  private shouldHandleCallback(): boolean {
    const searchParams = new URLSearchParams(this.window.location.search);
    return (
      (searchParams.has('code') || searchParams.has('error')) &&
      searchParams.has('state') &&
      !this.authConfig.get().skipRedirectCallback
    );
  }

  /**
   * Automatically logout the app when access_token expires or session ends on auth server
   * @private
   */
  private setupAutomaticLogout() {
    this.authEventsService.accessTokenExpired$.pipe(untilDestroyed(this)).subscribe(() => {
      // Try to do a silent login as last resource, if it fails logout.
      this.silentLogin().catch(() => {
        this.logout(true);
        this.showLoginPage();
      });
    });
    this.authEventsService.sessionTerminated$.pipe(untilDestroyed(this)).subscribe(() => {
      this.showLoginPage();
    });
  }

  /**
   * Automatically updates access_token on client session storage
   * @private
   */
  private startAccessTokenSyncOnClient(): void {
    if (this.clientFeatureService.isInClient) {
      this.accessToken$
        .pipe(
          switchMap((accessToken) =>
            iif(
              () => !!accessToken,
              this.ultraOsApiService.setStorageData(SessionStorageKeys.AccessToken, accessToken, 'session'),
              this.ultraOsApiService.removeStorageData(SessionStorageKeys.AccessToken, 'session'),
            ),
          ),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }
}
