@if (!displayState?.isLocked) {
  <div
    class="status-wrap status-wrap--{{ size }} status-wrap--{{ status }}"
    [class.status-wrap--max-content]="showExplicit"
    [class.px-2]="showExplicit"
    [ngbTooltip]="statusTooltip | titlecase"
    container="body"
    placement="top"
    tooltipClass="tooltip-no-arrow z-infinite tooltip--{{ status }}"
    ultraAutotestAttribute="token-actions-status">
    <i class="icon {{ icon }}"></i>
    @if (showExplicit) {
      <span class="status-wrap__explicit px-2 b2-regular" i18n>{{
        customText ?? statusText[displayState?.status]
      }}</span>
    }
  </div>
} @else {
  <ultra-actions-locked-status
    [class.me-2]="size === 'sm'"
    [ngbTooltip]="statusTooltip"
    container="body"
    placement="top"
    tooltipClass="tooltip-no-arrow z-infinite tooltip--{{ status }}"
    [action]="action"
    [period]="period"
    [availability]="availability"
    class="align-locked-status"
    [size]="size" />
}
