<div class="form-field-wrapper">
  <div class="form-field-flex" #connectionContainer (click)="onContainerClickHandler($event)">
    @if (label) {
      <label class="form-label form-field-label mb-2" [attr.for]="control.id" [attr.aria-owns]="control.id">
        <ng-content select="ultra-label"></ng-content>
        @if (showRequiredMarker && control.required && !control.disabled) {
          <span class="ultra-form-field-required-marker" aria-hidden="true">&#32;* </span>
        }
      </label>
    }

    <div class="form-field-input-container" #inputContainer>
      @if (prefixChildren.length) {
        <div class="form-field-prefix">
          <ng-content select="[ultraPrefix]"></ng-content>
        </div>
      }

      <div class="form-field-infix" [style.height]="infixHeight$ | async">
        <ng-content></ng-content>
      </div>

      @if (suffixChildren.length) {
        <div
          class="form-field-suffix"
          #suffixContainer
          [ngClass]="{
            'suffix-absolute-position': this.textAreaAutosize,
            'suffix-absolute-position-scroll': this.isScrollableTextArea$ | async
          }">
          <ng-content select="[ultraSuffix]"></ng-content>
        </div>
      }
    </div>
  </div>
  @if (hintChildren.length || hintLabel || errorChildren.length) {
    <div class="ultra-form-field-subscript-wrapper">
      @switch (getDisplayedMessages()) {
        @case ('error') {
          <div [@transitionMessages]="subscriptAnimationState">
            <ng-content select="ultra-error"></ng-content>
          </div>
        }
        @case ('hint') {
          <div class="form-field-hint-wrapper" [@transitionMessages]="subscriptAnimationState">
            @if (hintLabel) {
              <div class="ultra-hint">{{ hintLabel }}</div>
            }
            <ng-content select="ultra-hint:not([align='end'])"></ng-content>
            <div class="form-field-hint-spacer"></div>
            <ng-content select="ultra-hint[align='end']"></ng-content>
          </div>
        }
      }
    </div>
  }
</div>
