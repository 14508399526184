import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonDirective } from './button/button.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { ContentCopyDirective } from './content-copy/content-copy.directive';
import { DisableControlDirective } from './disable-control/disable-control.directive';
import { HoverDirective } from './hover/hover.directive';
import { NotifyingTooltipDirective } from './notifying-tooltip/notifying-tooltip.directive';
import { ScreenSizeChangeDirective } from './screen-size-change/screen-size-change.directive';
import { TrapScrollDirective } from './trap-scroll/trap-scroll.directive';

@NgModule({
  imports: [CommonModule],
  exports: [
    DisableControlDirective,
    ContentCopyDirective,
    NotifyingTooltipDirective,
    ClickOutsideDirective,
    ScreenSizeChangeDirective,
    TrapScrollDirective,
    ButtonDirective,
    HoverDirective,
  ],
  declarations: [
    DisableControlDirective,
    ContentCopyDirective,
    NotifyingTooltipDirective,
    ClickOutsideDirective,
    ScreenSizeChangeDirective,
    TrapScrollDirective,
    ButtonDirective,
    HoverDirective,
  ],
})
export class DirectivesModule {}
