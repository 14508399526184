<div
  class="lazy-image__wrapper"
  [class.video-preview]="videoPreview"
  [class.full-width]="fullWidth"
  [ngClass]="styleClass"
>
  <img
    class="lazy-image lazy-image-bg {{ imgClassName }}"
    [ultraAutotestAttribute]="dataId"
    [class.no-alt-icon]="!imageAlt"
    [class.fading]="fading"
    [loading]="loading"
    [src]="defaultImage"
    [srcset]="srcset"
    (load)="onImgLoaded()"
    (error)="onImgError($event)"
    [alt]="imageAlt"
    [style.background-image]="showPlaceHolder ? 'url(' + placeholder + ')' : 'none'"
  />
  <ng-content></ng-content>
</div>
