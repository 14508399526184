import { NgModule } from '@angular/core';

import { LicenseManageService } from './license-manage.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [LicenseManageService],
})
export class LicenseManageModule {}
