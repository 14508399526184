import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ultra-game-discount',
  templateUrl: './game-discount.component.html',
  styleUrls: ['./game-discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDiscountComponent {
  @Input()
  discountBasis = 0; // from 1_000 to 9_000

  getDiscountPercentage() {
    return this.discountBasis / 100;
  }
}
