<div class="content-overview content-overview--advanced">
  <div class="content-item content-item--left d-flex flex-column">
    <div class="d-flex flex-column pb-3">
      <span class="f-size-12 text-white-alpha-5">{{ gameName }}</span>
      <span
        class="content-overview__title text-white text-capitalize"
        ultraAutotestAttribute="card-overview-title-content"
        >{{ idCardEntity?.info?.title }}</span
      >
      @if (idCardEntity?.info?.subTitle) {
        <span class="content-overview__sub-title" ultraAutotestAttribute="card-overview-subtitle-content">{{
          idCardEntity.info.subTitle
        }}</span>
      }

      @if (idCardEntity?.type === 'uniqfactory') {
        <div class="overline-medium">
          @if (isFactoryDetailsAvailable) {
            <a class="external-reference" target="_blank" [routerLink]="['/product', 'uniqfactory', idCardEntityId]">
              <span i18n class="text-white-alpha-5 text-uppercase">Factory N°:&nbsp;</span>
              <span>
                {{ idCardEntity?.type === 'uniq' ? idCardEntity?.factoryBlockchainId : idCardEntity?.blockchainId }}
              </span>
              <i class="external-reference__icon icon-external-link" ultraAutotestAttribute="external-link-content"></i>
            </a>
          } @else {
            <span i18n class="text-white-alpha-5 text-uppercase">Factory N°:&nbsp;</span>
            <span class="text-white-alpha-5">{{ idCardEntityId }}</span>
          }
        </div>
      }

      @if (idCardEntity?.type === 'uniq') {
        <div class="overline-medium">
          <ultra-id-card-serial-number
            [isUniqDetailsAvailable]="isUniqDetailsAvailable"
            [idCardEntity]="idCardEntity"></ultra-id-card-serial-number>
          <div class="product-creator d-flex align-items-center b2-regular mt-2">
            <span class="f-size-14 text-white-alpha-5 me-1">By</span>
            <span class="f-size-14 text-white-alpha-7 content-ellipsis">
              {{ idCardEntity?.creator?.name }}
            </span>
            @if (idCardEntity?.creator?.verified) {
              <div class="product-creator__check ms-1">
                <i class="icon icon-check-circle-outline bg-white-alpha-7"></i>
              </div>
            }
          </div>
          @if (idCardEntity?.owner?.blockchainId) {
            <div class="product-owner d-flex align-items-center b2-regular mt-1">
              <span class="f-size-14 text-nowrap text-white-alpha-5 me-1">Owned by</span>
              <span
                class="f-size-14 text-white-alpha-7 content-ellipsis me-1"
                ultraAutotestAttribute="product-owner-content">
                {{ idCardEntity.owner.blockchainId }}
              </span>
              <span class="product-owner__avatar d-flex align-self-center rounded-circle">
                <i class="product-owner__icon icon icon-user-sm text-white-alpha-7"></i>
              </span>
            </div>
          }
        </div>
      }
    </div>

    @if (shouldValidateMetadata) {
      <ultra-metadata-information-manager [metadataStatus]="idCardEntity?.metadata?.status" />
    } @else {
      @if (idCardEntity?.info?.description) {
        <div
          class="token-description f-size-16 text-white-alpha-5"
          ultraAutotestAttribute="card-overview-description-content"
          ultraScroll
          useBottomGradient="true"
          useTopGradient="true">
          {{ idCardEntity.info.description }}
        </div>
      } @else {
        <ultra-id-card-overview-placeholder></ultra-id-card-overview-placeholder>
      }
    }
  </div>

  <div class="content-item content-item--right d-flex-center">
    @if (idCardEntity?.type === 'uniq' || idCardEntity?.type === 'uniqfactory') {
      @if (shouldValidateMetadata) {
        <ultra-metadata-image-manager class="product-image-wrap" [size]="32" />
      } @else {
        @if (idCardEntity?.medias?.image?.contentType === 'IMAGE') {
          <ultra-lazy-image
            [defaultImage]="idCardEntity?.medias?.image?.uri"
            [imageAlt]="idCardEntity?.info?.title"
            [dataId]="'card-overview-image-content'"
            [showPlaceHolder]="false"
            class="product-image-wrap"
            styleClass="product-image">
          </ultra-lazy-image>
        }
        @if (idCardEntity?.medias?.image?.contentType === 'VIDEO') {
          <div class="w-100 h-100">
            <ultra-media-video-player
              [src]="idCardEntity.medias.image.uri"
              [posterSrc]="idCardEntity.medias.square?.uri"></ultra-media-video-player>
          </div>
        }
      }
    }
  </div>
</div>
