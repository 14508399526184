import { ViewportRuler } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { CustomBlockStrategy } from './custom-block-strategy';

@Injectable({ providedIn: 'root' })
export class CustomScrollStrategyOptionsService {
  constructor(private _viewportRuler: ViewportRuler, @Inject(DOCUMENT) private document: any) {}

  customBlock() {
    return new CustomBlockStrategy(this._viewportRuler, this.document);
  }
}
