import { Pipe, PipeTransform } from '@angular/core';

export function toSlug(str: string): string {
  const replacement = '_';
  const transformed = str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .trim()
    .replace(/\s+/g, replacement);
  return transformed || replacement;
}

@Pipe({
  name: 'slug',
  standalone: true,
})
export class SlugPipe implements PipeTransform {
  transform(value: string): string {
    return toSlug(value);
  }
}
