import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from '@ultra/core/auth';
import { ClientVersionGuard } from '@ultra/shared';

import { UniverseNavComponent } from './universe-nav/universe-nav.component';

const routes: Routes = [{ path: '', component: UniverseNavComponent, canActivate: [authGuard, ClientVersionGuard] }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
