import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IAgeRatingData, RatingSystemCategoriesDescription } from '../../../../../models/game/data/age-ratings.data';

@Component({
  selector: 'ultra-game-detail-ratings-modal',
  templateUrl: './game-detail-ratings-modal.component.html',
  styleUrls: ['./game-detail-ratings-modal.component.scss'],
})
export class GameDetailRatingsModalComponent {
  activeModal = inject(NgbActiveModal);
  activeTab = 0;
  ageRating: IAgeRatingData[];
  categoriesDescription = { ...RatingSystemCategoriesDescription };

  tabChanged(index: number): void {
    this.activeTab = index;
  }
}
