import { Directive, Input, OnDestroy, Optional } from '@angular/core';
import { Subject } from 'rxjs';

import { OverlayEventBusService } from '../overlay-event-bus.service';
import { OverlayHostDirective } from '../overlay-host.directive';

@Directive()
export abstract class BaseListenerDirective implements OnDestroy {
  @Input()
  public get hostId(): string {
    return this._uid;
  }

  public set hostId(value: string) {
    this._uid = value;
  }

  private _uid: string;

  @Input()
  protected destroy$: Subject<void> = new Subject<void>();

  constructor(protected eventBus: OverlayEventBusService, @Optional() protected host: OverlayHostDirective) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
