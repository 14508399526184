/******** Pagination ********/
export const PAGINATION_GQL = `
  pagination {
    limit
    skip
  }
  totalCount
`;

/******** User personalData ********/
export const USER_PERSONAL_DATA_GQL = `
{
  firstname
  lastname
  email
  addresses {
    type
    detail {
     streetName
     streetName2
     state
     zipCode
     country
     isPrimary
     }
  }
}`;
