import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ROUND_PROGRESS_DEFAULTS, RoundProgressModule } from 'angular-svg-round-progressbar';

import { AutotestAttributeDirective } from '../../autotests';
import { ScrollDirective } from '../../directives/scroll/scroll.directive';
import { ActionsStatusComponent } from '../../modules/id-card-content/id-card-actions/components/actions-status/actions-status.component';
import { IdCardActionsTitleComponent } from '../../modules/id-card-content/id-card-actions/components/actions-title/id-card-actions-title.component';
import { IdCardActionsComponent } from '../../modules/id-card-content/id-card-actions/id-card-actions.component';
import { SharedLanguagePipeModule } from '../../pipes/languages/languages.pipe.module';
import { SharedPipesModule } from '../../pipes/pipes.module';
import { UniqTypeIconComponent } from '../../uniq/uniq-type-icon';
import { CircleProgressModule } from '../circle-progress/circle-progress.module';
import { CopyToClipboardComponent } from '../copy-to-clipboard';
import { CurrencyInputModule } from '../currency-input/currency-input.module';
import { InputComponent } from '../form/components/input';
import { FormModule } from '../form/form.module';
import { UltraFormsModule } from '../forms/forms.module';
import { LazyImageModule } from '../lazy-image/lazy-image.module';
import { MediaCarouselModule } from '../media-carousel/media-carousel.module';
import { MediaVideoPlayerModule } from '../media-video-player';
import { MetadataImageManagerComponent } from '../metadata-status-manager/metadata-image-manager/metadata-image-manager.component';
import { MetadataInformationManagerComponent } from '../metadata-status-manager/metadata-information-manager/metadata-information-manager.component';
import { NotificationMessageModule } from '../notification_message';
import { UltraOverlayModule } from '../overlay/overlay.module';
import { TableModule } from '../table/table.module';
import { TabsModule } from '../tabs/tabs.module';
import { VersionCurrencyModule } from '../version-currency/version-currency.module';
import { VerticalProgressSticksModule } from '../vertical-progress-sticks/vertical-progress-sticks.module';

import { IdCardAttributesComponent } from './id-card-attributes/id-card-attributes.component';
import { IdCardContentComponent } from './id-card-content/id-card-content.component';
import { IdCardContentHeaderComponent } from './id-card-content-header/id-card-content-header.component';
import { IdCardContentPlaceholderComponent } from './id-card-content-placeholder/id-card-content-placeholder.component';
import { IdCardDataComponent } from './id-card-data/id-card-data.component';
import { IdCardGeofencingComponent } from './id-card-geofencing/id-card-geofencing.component';
import { IdCardHeaderComponent } from './id-card-header/id-card-header.component';
import { IdCardIdentityComponent } from './id-card-identity';
import { IdCardMarketplaceComponent } from './id-card-marketplace/id-card-marketplace.component';
import { IdCardOverviewComponent } from './id-card-overview/id-card-overview.component';
import { IdCardOverviewPlaceholderComponent } from './id-card-overview-placeholder/id-card-overview-placeholder.component';
import { IdCardPropertiesComponent } from './id-card-properties/id-card-properties.component';
import { IdCardQuantityStatusComponent } from './id-card-quantity';
import { IdCardQuantityComponent } from './id-card-quantity/id-card-quantity.component';
import { IdCardQuantitySupplyComponent } from './id-card-quantity/id-card-quantity-supply/id-card-quantity-supply.component';
import { IdCardSellComponent } from './id-card-sell/id-card-sell.component';
import { IdCardSerialNumberComponent } from './id-card-serial-number/id-card-serial-number.component';
import { IdCardTransferComponent } from './id-card-transfer/id-card-transfer.component';

export const PROGRESS_DEFAULTS_PROVIDER = {
  provide: ROUND_PROGRESS_DEFAULTS,
  useValue: {
    color: '#C5ABFF',
    background: 'rgba(255, 255, 255, 0.1)',
    radius: 8,
    stroke: 2,
    clockwise: true,
    responsive: true,
    animation: 'easeOutCubic',
    duration: 500,
  },
};

@NgModule({
  imports: [
    CommonModule,
    LazyImageModule,
    VersionCurrencyModule,
    TableModule,
    SharedLanguagePipeModule,
    SharedPipesModule,
    TabsModule,
    UltraOverlayModule,
    FormModule,
    NgbTooltipModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    AutotestAttributeDirective,
    CircleProgressModule,
    VerticalProgressSticksModule,
    UltraFormsModule,
    CurrencyInputModule,
    ActionsStatusComponent,
    IdCardQuantityStatusComponent,
    NotificationMessageModule,
    RouterModule,
    MediaCarouselModule,
    CopyToClipboardComponent,
    MediaVideoPlayerModule,
    UniqTypeIconComponent,
    RoundProgressModule,
    MetadataImageManagerComponent,
    MetadataInformationManagerComponent,
    IdCardActionsComponent,
    IdCardActionsTitleComponent,
    InputComponent,
    ScrollDirective,
    IdCardIdentityComponent,
    IdCardSerialNumberComponent,
  ],
  providers: [PROGRESS_DEFAULTS_PROVIDER],
  declarations: [
    IdCardAttributesComponent,
    IdCardContentComponent,
    IdCardContentPlaceholderComponent,
    IdCardGeofencingComponent,
    IdCardOverviewComponent,
    IdCardOverviewPlaceholderComponent,
    IdCardPropertiesComponent,
    IdCardContentHeaderComponent,
    IdCardHeaderComponent,
    IdCardQuantityComponent,
    IdCardDataComponent,
    IdCardMarketplaceComponent,
    IdCardQuantitySupplyComponent,
    IdCardSellComponent,
    IdCardTransferComponent,
  ],
  exports: [
    IdCardAttributesComponent,
    IdCardContentComponent,
    IdCardContentPlaceholderComponent,
    IdCardGeofencingComponent,
    IdCardOverviewComponent,
    IdCardOverviewPlaceholderComponent,
    IdCardPropertiesComponent,
    IdCardContentHeaderComponent,
    IdCardHeaderComponent,
    IdCardSerialNumberComponent,
    IdCardIdentityComponent,
    IdCardDataComponent,
    IdCardMarketplaceComponent,
    IdCardQuantitySupplyComponent,
  ],
})
export class IdCardContentModule {}
