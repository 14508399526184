<span class="rounded" style="width: 95%"></span>
<span class="rounded" style="width: 97%"></span>
<span class="rounded" style="width: 80%"></span>
<span class="rounded" style="width: 100%"></span>
<span class="rounded" style="width: 95%"></span>
<span class="rounded mb-3" style="width: 60%"></span>
<span class="rounded" style="width: 92%"></span>
<span class="rounded" style="width: 94%"></span>
<span class="rounded" style="width: 96%"></span>
<span class="rounded" style="width: 85%"></span>
