import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genreIcon',
})
export class GenreIconPipe implements PipeTransform {
  transform(value: string): string {
    return `icon-${value.toLowerCase()}`;
  }
}
