import { DecimalPipe } from '@angular/common';

import { ITokenPrice } from '../models/token-factory/interfaces/token-price.interface';

export class DisplayPrices {
  constructor(protected decimalPipe: DecimalPipe) {}

  getPrice(price: ITokenPrice): string {
    if (!price) {
      return null;
    }
    const amount = this.decimalPipe.transform(price.amount, '1.2-2');
    return `${price.symbol}${amount}`;
  }
}
