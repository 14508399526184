export interface IErrorTypes {
  [key: string]: string;
}

export const inputErrors: IErrorTypes = {
  required: 'This field cannot be empty',
  minlength: 'Need more characters',
  maxlength: 'Need less characters',
  email: 'Enter a valid email address',
  startsWithLetters: 'Should start with a letter',
  lettersOrNumbers: 'Should only contain letters or numbers',
  patternPassword: 'Password is not valid',
  countryExist: 'Choose country from list',
  passwordMatch: 'The two passwords you entered do not match',
  emailMatch: 'The two email addresses you entered do not match',
  patternSecurityPassword: 'The password needs to follow the security requirements',
  oneDecimal: 'At least 1 number',
  oneCapital: 'At least 1 capital letter',
  oneSpecial: 'At least 1 special character',
  minPasswordLength: 'Minimum 8 characters',
  uniqueGameName: 'Game with this name already exists!',
  youtubeVideoLink: 'Invalid Youtube video',
  videoLinkAlreadyExist: 'You already added this video',
  videoLinkMaxCount: 'You already added the maximum number of videos (10)',
  videoNotExist: 'Sorry, We did not find this video on Youtube.',
  invalidWhitespace: 'The field cannot start or end with space. Only one space is allowed between the characters.',
  invalidNumber: 'Enter a valid phone number',
  invalidLink: 'Invalid URL',
  twoDecimalAllowed: 'Only 2 decimals allowed',
  noDecimal: 'Only integers are available',
  zipCode: 'Zip code is not valid',
  alphaOnly: 'Field is not valid. You should use letters only',
  alphaWithSpacesAndDashes: 'Field is not valid. You should use letters, spaces, dashes only',
  alphaWithSpacesAndSlashes: 'Field is not valid. You should use letters, spaces, slashes only',
  invalidGame: 'Invalid game name',
  alphaSpacesDashesApostrophes: 'Field contains invalid symbols, please use only valid ones',
  alphaNumericSpacesDashesApostrophesSlashes: 'Field contains invalid symbols, please use only valid ones',
  alphaNumericSpacesDashes: 'Field contains invalid symbols, please use only valid ones',
  numeric: 'Must be numeric',
  walletBalance: 'Amount over Wallet balance',
  min: 'Amount entered too low',
  nameOnCard: 'Field contains invalid symbols, please use only valid ones',
  noWhiteSpaceOnly: 'This field cannot be empty',
  ethereumAddress: 'Invalid Ethereum address',
  invalidUsername: 'Invalid user name. Please use only letters and numbers',
};
