import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { merge, NEVER, Observable, retry } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ApiGraphQLService, ApolloClientName } from '@ultra/core/graphql';

import { ApiSharedGqlConfig } from '../../graphql-client';

import { ExchangeRate, ExchangeRateParams } from './interfaces/exchange-rate.interface';

@Injectable({
  providedIn: 'root',
})
export class ExchangeGqlService {
  isPlatformBrowser = isPlatformBrowser(this.platformID);

  constructor(private apiService: ApiGraphQLService, @Inject(PLATFORM_ID) private platformID: any) {}

  public getExchangeRate(exchangeRateParams: ExchangeRateParams): Observable<ExchangeRate> {
    let subscription: Observable<any> = NEVER;
    const options = { apolloClient: ApolloClientName.EXCHANGE };
    const query = this.apiService.getQuery(ApiSharedGqlConfig.EXCHANGE_RATE, exchangeRateParams, options).pipe(
      retry({ delay: 1000, count: 10, resetOnSuccess: true }),
      map((response) => response?.data.getExchangeRate)
    );
    if (this.isPlatformBrowser) {
      subscription = this.apiService
        .getSubscription(ApiSharedGqlConfig.EXCHANGE_RATE_SUB, exchangeRateParams, options)
        .pipe(
          retry({ delay: 1000, count: 10, resetOnSuccess: true }),
          map((response) => response?.data?.updateExchangeRate)
        );
    }
    return merge(query, subscription).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  getExchangeMargin() {
    return this.apiService
      .getQuery(ApiSharedGqlConfig.EXCHANGE_MARGIN, {}, { apolloClient: ApolloClientName.REFERENTIAL })
      .pipe(map((response) => response?.data?.configurations?.exchangeMargin));
  }
}
