import {
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, ControlContainer, NgControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, fromEvent, map, merge, NEVER, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, startWith, switchMap } from 'rxjs/operators';

import { ControlErrorAnchorDirective } from './control-error-anchor.directive';
import { DefaultControlErrorComponent } from './default-control-error/default-control-error.component';
import { FormActionDirective } from './form-action.directive';
import { FORM_ERRORS, FormErrorsConfig, FormErrorsConfigProvider } from './providers';
import { ControlErrorComponentInterface } from './types/control-error-component.interface';
import { ErrorTemplate } from './types/error-template.type';
import { ErrorsMap } from './types/errors-map.type';

@UntilDestroy()
@Directive({
  selector:
    '[formControlName]:not([ultraControlErrorsIgnore]), [formControl]:not([ultraControlErrorsIgnore]), [formGroup]:not([ultraControlErrorsIgnore]), [formGroupName]:not([ultraControlErrorsIgnore]), [formArrayName]:not([ultraControlErrorsIgnore]), [ngModel]:not([ultraControlErrorsIgnore])',
  exportAs: 'ultraControlError',
})
export class ControlErrorDirective implements OnInit, OnDestroy {
  @Input() customControlErrors: ErrorsMap = {};
  @Input() controlErrorsClass: string | undefined;
  @Input() controlErrorsTpl: ErrorTemplate | undefined;
  @Input() controlErrorsOnAsync = true;
  @Input() controlErrorsOnBlur = true;
  @Input() controlErrorAnchor: ControlErrorAnchorDirective;

  private ref: ComponentRef<ControlErrorComponentInterface>;
  private anchor: ViewContainerRef;
  private submit$: Observable<Event>;
  private reset$: Observable<Event>;
  private control: AbstractControl;
  private showError$ = new Subject<void>();
  private mergedConfig: FormErrorsConfig = {};
  private customAnchorDestroyFn: () => void;

  constructor(
    private host: ElementRef,
    private vcr: ViewContainerRef,
    @Inject(FormErrorsConfigProvider) private config: FormErrorsConfig,
    @Inject(FORM_ERRORS) private globalErrors,
    @Optional() private controlErrorAnchorParent: ControlErrorAnchorDirective,
    @Optional() private form: FormActionDirective,
    @Optional() @Self() private ngControl: NgControl,
    @Optional() @Self() private controlContainer: ControlContainer,
  ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
    this.reset$ = this.form ? this.form.reset$ : EMPTY;
    this.mergedConfig = this.buildConfig();
  }

  ngOnInit(): void {
    this.anchor = this.resolveAnchor();
    this.control = (this.controlContainer || this.ngControl).control;
    const hasAsyncValidator = !!this.control.asyncValidator;
    const statusChanges$ = this.control.statusChanges.pipe(distinctUntilChanged());
    const valueChanges$ = this.control.valueChanges;
    const controlChanges$ = merge(statusChanges$, valueChanges$);
    let changesOnAsync$: Observable<any> = EMPTY;
    let changesOnBlur$: Observable<any> = EMPTY;

    if (this.controlErrorsOnAsync && hasAsyncValidator) {
      changesOnAsync$ = statusChanges$.pipe(filter(() => this.control.touched));
    }

    if (this.controlErrorsOnBlur && this.isInput) {
      const blur$ = fromEvent(this.host.nativeElement, 'focusout');
      changesOnBlur$ = blur$.pipe(switchMap(() => valueChanges$.pipe(startWith(true))));
    }

    const submit$ = merge(this.submit$.pipe(map(() => true)), this.reset$.pipe(map(() => false)));

    const changesOnSubmit$ = submit$.pipe(
      switchMap((submit) => (submit ? controlChanges$.pipe(startWith(true)) : NEVER)),
    );

    this.reset$.pipe(untilDestroyed(this)).subscribe(() => this.clearRefs());

    merge(changesOnAsync$, changesOnBlur$, changesOnSubmit$, this.showError$)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.valueChanges());
  }

  ngOnDestroy(): void {
    this.clearRefs();
  }

  showError(): void {
    this.showError$.next();
  }

  hideError(): void {
    this.setError(null);
  }

  private buildConfig(): FormErrorsConfig {
    return {
      ...{
        blurPredicate(element): boolean {
          return element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA';
        },
        controlErrorComponent: DefaultControlErrorComponent,
      },
      ...this.config,
    };
  }

  private resolveAnchor(): ViewContainerRef {
    if (this.controlErrorAnchor) {
      return this.controlErrorAnchor.vcr;
    }

    if (this.controlErrorAnchorParent) {
      return this.controlErrorAnchorParent.vcr;
    }
    return this.vcr;
  }

  private get isInput(): boolean {
    return this.mergedConfig.blurPredicate(this.host.nativeElement);
  }

  private clearRefs(): void {
    if (this.customAnchorDestroyFn) {
      this.customAnchorDestroyFn();
      this.customAnchorDestroyFn = null;
    }
    if (this.ref) {
      this.ref.destroy();
    }
    this.ref = null;
  }

  private valueChanges(): void {
    const controlErrors = this.control.errors;

    if (controlErrors) {
      const [firstKey] = Object.keys(controlErrors);
      const getError = (this.customControlErrors && this.customControlErrors[firstKey]) || this.globalErrors[firstKey];
      if (!getError) {
        return;
      }

      const text = typeof getError === 'function' ? getError(controlErrors[firstKey]) : getError;
      if (this.isInput) {
        this.host.nativeElement.parentElement.classList.add('control-has-error');
      }
      this.setError(text, controlErrors);
    } else if (this.ref) {
      if (this.isInput) {
        this.host.nativeElement.parentElement.classList.remove('control-has-error');
      }
      this.setError(null);
    }
  }

  private setError(text: string, error?: ValidationErrors): void {
    if (!this.ref) {
      this.ref = this.anchor.createComponent<ControlErrorComponentInterface>(this.mergedConfig.controlErrorComponent);
    }
    const instance = this.ref.instance;

    if (this.controlErrorsTpl) {
      instance.createTemplate(this.controlErrorsTpl, error, text);
    } else {
      instance.text = text;
    }

    if (this.controlErrorsClass) {
      instance.customClass = this.controlErrorsClass;
    }

    if (!this.controlErrorAnchor && this.mergedConfig.controlErrorComponentAnchorFn) {
      this.customAnchorDestroyFn = this.mergedConfig.controlErrorComponentAnchorFn(
        this.host.nativeElement as HTMLElement,
        (this.ref.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement,
      );
    }
  }
}
