import { Component, HostListener, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Observable } from 'rxjs';

import { WINDOW } from '@ultra/core/providers';

import { IImageDetail, IVideo } from '../../../models/multimedia/interfaces';
import { IMedia } from '../../../models/uniq/interfaces/uniq.interface';
import { DIALOG_DATA } from '../../dialog/providers/dialog-injectors';
import { DialogRef } from '../../dialog/providers/dialog-ref';

import { MediaCarouselHelper } from './media.helper';
/**
 * A component to expand an image in the carousels.
 */
@UntilDestroy()
@Component({
  selector: 'ultra-media-carousel-expanded',
  templateUrl: './media-carousel-expanded.component.html',
  styleUrls: ['./media-carousel-expanded.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaCarouselExpandedComponent implements OnInit, OnDestroy {
  private data = inject(DIALOG_DATA);
  private window: Window = inject(WINDOW);
  private dialogRef: DialogRef<MediaCarouselExpandedComponent> = inject(DialogRef);

  selectedMedia: IVideo | IMedia | IImageDetail = this.data.selectedMedia;
  iMediaItems: IMedia[] = MediaCarouselHelper.getIMediaItems(this.data.medias);
  iVideoItems: IVideo[] = MediaCarouselHelper.getIVideoItems(this.data.medias);
  medias: (IVideo | IMedia | IImageDetail)[] = this.data.medias;
  mediaWidth: number = this.window.innerWidth * 0.7;
  mediaHeight: number = this.mediaWidth / 1.7777;

  windowReSize$: Observable<Event>;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowRight':
        this.changeMedia(1);
        break;
      case 'ArrowLeft':
        this.changeMedia(-1);
        break;
      case 'Escape':
        this.close();
        break;
    }
  }

  ngOnInit() {
    fromEvent(this.window, 'resize')
      .pipe(untilDestroyed(this))
      .subscribe((event: Event) => {
        this.mediaWidth = event.target['innerWidth'] * 0.7;
        this.mediaHeight = this.mediaWidth / 1.7777;
      });
  }

  ngOnDestroy(): void {
    const currentIndex = this.getIndexForCarousel();
    this.dialogRef.close(currentIndex);
  }

  changeMedia(value: number): void {
    const index: number = this.medias.indexOf(this.selectedMedia) + value;
    const indexMedia: IVideo | IMedia | IImageDetail =
      this.medias[this.medias[index] ? index : value === -1 ? this.medias.length - 1 : 0];

    this.selectMedia(indexMedia);
  }

  selectMedia(media: IVideo | IMedia | IImageDetail): void {
    this.selectedMedia = media;
  }

  close(): void {
    this.dialogRef.close();
  }

  getImageMediaUrl(): string {
    if ('url' in this.selectedMedia) {
      return this.selectedMedia?.url;
    } else if ('uri' in this.selectedMedia) {
      return this.selectedMedia?.uri;
    }
  }

  private getIndexForCarousel(): number {
    return this.data.medias.indexOf(this.selectedMedia);
  }

  isImageContentType() {
    return 'contentType' in this.selectedMedia ? this.selectedMedia.contentType !== 'VIDEO' : true;
  }
}
