import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { IUniq } from '../../../models/uniq';

import { TabGroupComponent } from './../../tabs/tab-group/tab-group.component';
import { IdCardPanelDispatcherService, IdCardPropertyTabs } from './../id-card-panel-dispatcher';

@UntilDestroy()
@Component({
  selector: 'ultra-id-card-marketplace',
  templateUrl: './id-card-marketplace.component.html',
  styleUrls: ['./id-card-marketplace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardMarketplaceComponent implements AfterViewInit {
  @Input()
  minResellPrice: number;
  @Input()
  minResellPriceCurrency: string;
  @Input()
  uniq: IUniq;

  @ViewChild(TabGroupComponent, { static: true })
  public tabGroup: TabGroupComponent;

  constructor(@Optional() private panelDispatcher: IdCardPanelDispatcherService, private cd: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    if (!this.panelDispatcher) {
      return;
    }
    this.panelDispatcher
      .pipe(
        filter((data) => data === IdCardPropertyTabs.MARKETPLACE),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.tabGroup.select(0);
        this.cd.markForCheck();
      });
  }
}
