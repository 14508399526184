import { Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { ID_CARD_CONFIG, IdCardConfig } from '../../../modules';
import { IdCardEntity } from '../adapters/id-card.adapter';

@Component({
  selector: 'ultra-id-card-overview',
  templateUrl: './id-card-overview.component.html',
  styleUrls: ['./id-card-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdCardOverviewComponent implements OnInit {
  @Input() idCardEntity: IdCardEntity;

  @Input() gameName: string;

  private _shouldValidateMetadata = false;
  @Input() set shouldValidateMetadata(value: boolean) {
    this._shouldValidateMetadata = value && this.idCardEntity?.metadata?.status !== 'VALID';
  }
  get shouldValidateMetadata(): boolean {
    return this._shouldValidateMetadata;
  }

  isFactoryDetailsAvailable: boolean;
  isUniqDetailsAvailable: boolean;

  constructor(@Optional() @Inject(ID_CARD_CONFIG) private idCardConfig: IdCardConfig, private router: Router) {}

  ngOnInit() {
    this.isFactoryDetailsAvailable = this.contextualProductDetails('uniqfactory');
    this.isUniqDetailsAvailable = this.contextualProductDetails('uniq');
  }

  contextualProductDetails(hideForType: string): boolean {
    const currentUrlWithoutParams = this.router.url.split('?')[0];
    if (['product', 'sell', 'my-inventory'].some((urlPart) => currentUrlWithoutParams.includes(urlPart))) {
      return this.idCardEntity?.type !== hideForType;
    }
    return this.idCardConfig?.isAllowProductDetails;
  }

  get idCardEntityId(): string {
    return this.idCardEntity?.type === 'uniq'
      ? this.idCardEntity?.factoryBlockchainId
      : this.idCardEntity?.blockchainId;
  }
}
