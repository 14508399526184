export enum FeatureFlag {
  PUBLIC_GAME_STORE = 'publicGameStore',
  GAME_RATING_AWARDS = 'gameRatingAwards',
  TOKEN_FACTORY_BLOCKCHAIN_LINK = 'linkGameWithBlockchainId',
  SHOPPING_CART = 'shoppingCart',
  ALLOW_ACCESS_WITHOUT_CLIENT = 'allowAccessWithoutClient',
  LOAD_SIMPLEX_TRACKING_SCRIPT = 'loadSimplexTrackingScript',
  LOAD_SENTRY = 'loadSentry',
  MOCK_ULTRA_OS = 'mockUltraOs',
  ADVANCED_UNIQ_GROUP_LAYOUT = 'advancedUniqGroupLayout',
  UNIQ_PROPERTIES = 'uniqProperties',
  ENABLE_PROMOTER_FEE = 'enablePromoterFee',
  ALLOW_UNRESTRICTED_TRANSFER = 'allowUnrestrictedTransfer',
  REPORT_UNIQ = 'reportUniq',
  SWAP_UOS_TO_ETH = 'swapUOSToETH',
  DISABLE_UNIQ_CAPTCHA = 'disableUniqCaptcha',
}
