import { DatePipe, NgStyle } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  ActionDisplayState,
  ActionStatus,
  IdCardAction,
} from '../../../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { IdCardActionsDisplayHelper } from '../../helpers/id-card-actions-display.helper';

@Component({
  selector: 'ultra-id-card-actions-title',
  templateUrl: './id-card-actions-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle],
  providers: [DatePipe],
  standalone: true,
})
export class IdCardActionsTitleComponent implements AfterContentInit {
  @Input() displayState: ActionDisplayState;
  @Input() action: IdCardAction;
  @Input() startDate: string;

  title: string;

  constructor(private datePipe: DatePipe) {}

  ngAfterContentInit() {
    this.title = this.getActionTitle();
  }

  private getActionTitle(): string {
    const actionName = this.getActionName();

    if (this.displayState.isLocked) {
      return `${this.getLockupDurationFormattedTitle(
        IdCardActionsDisplayHelper.getFormattedDuration(
          this.displayState.duration.locked,
          this.displayState.lockedDurationUnit,
        ),
      )} this Uniq can be ${actionName} ${this.getDurationFormattedTitle(
        IdCardActionsDisplayHelper.getFormattedDuration(
          this.displayState.duration.available,
          this.displayState.availableDurationUnit,
        ),
      )}`;
    } else {
      if (this.displayState?.status === ActionStatus.ABLE) {
        return this.startDate
          ? `Since ${this.datePipe.transform(this.startDate, `longDate`)} this Uniq can be ${actionName} forever`
          : `This Uniq can be ${actionName} forever`;
      }
      if (this.displayState?.status === ActionStatus.UNABLE) {
        return `This Uniq cannot be ${actionName}`;
      }
      if (this.displayState?.status === ActionStatus.DONE) {
        return `This Uniq cannot be ${actionName} anymore`;
      }
      if (this.displayState?.status === ActionStatus.TILL) {
        return `This Uniq can be ${actionName} ${this.getDurationFormattedTitle(
          IdCardActionsDisplayHelper.getFormattedDuration(
            this.displayState.duration.available,
            this.displayState.availableDurationUnit,
          ),
        )}`;
      }
    }
  }

  private getLockupDurationFormattedTitle(formattedLockedDuration: string): string {
    return formattedLockedDuration ? `After ${formattedLockedDuration},` : `In less than a minute,`;
  }

  private getDurationFormattedTitle(formattedDuration: string): string {
    if (!this.displayState.hasEndDate) {
      return 'forever';
    }

    return formattedDuration ? `for the next ${formattedDuration}` : `for less than a minute`;
  }

  private getActionName(): string {
    if (this.action === IdCardAction.TRADABILITY) {
      return `traded`;
    }

    if (this.action === IdCardAction.TRANSFERABILITY) {
      return `transferred`;
    }

    return '';
  }
}
