import { Directive, HostListener } from '@angular/core';

import { BaseListenerDirective } from './base-listener';

@Directive({
  selector: '[ultraClickHostListener]',
  providers: [{ provide: BaseListenerDirective, useExisting: ClickListenerDirective }],
  exportAs: 'clickListener',
})
export class ClickListenerDirective extends BaseListenerDirective {
  @HostListener('click')
  public onClick() {
    const target = this.host ? this.host.id : this.hostId;
    this.eventBus.next({ target, action: 'open' });
  }
}
