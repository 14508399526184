<ultra-id-card-header
  imageType="square"
  [shouldValidateMetadata]="shouldValidateMetadata || isRestricted"
  ultraAutotestAttribute="id-card-header-container">
  <div
    class="token-type-icon preview-type-marker position-relative d-flex align-items-center justify-content-center flex-shrink-0">
    <ultra-uniq-type-icon [type]="uniqPreviewEntity?.type.toLowerCase()" showOrnament="true" opacity="70" />
  </div>
  @if (shouldValidateMetadata) {
    <ultra-metadata-image-manager class="id-card-image" />
  } @else {
    <ultra-lazy-image
      class="id-card-image"
      [defaultImage]="uniqPreviewEntity?.medias?.square?.uri | imgSourceReplace: 'listPreview'"
      styleClass="rounded d-flex position-relative w-100 h-100" />
  }

  <div
    class="factory-name fw-bold f-size-16 content-ellipsis text-capitalize text-white"
    [class.uniq-sync-reduced-text]="shouldValidateMetadata"
    ultraAutotestAttribute="uniq-factory-name-content">
    {{ uniqPreviewEntity?.info.title }}
  </div>
  @if (uniqPreviewEntity?.info?.subTitle) {
    <div
      class="factory-subname fw-bold f-size-16 content-ellipsis text-capitalize text-white-alpha-5 mt-1"
      [class.uniq-sync-reduced-text]="shouldValidateMetadata"
      ultraAutotestAttribute="uniq-factory-subname-content">
      {{ uniqPreviewEntity?.info?.subTitle }}
    </div>
  }
  <div class="d-flex align-items-center justify-content-start mt-1 text-white-alpha-5 caption-regular f-size-12">
    <div class="text-capitalize">{{ uniqPreviewEntity?.type.toLowerCase() }}</div>
    <div class="px-1">•</div>
    <div class="content-ellipsis">
      <span>by </span>
      <span>{{ uniqPreviewEntity?.creator?.name }}</span>
    </div>
    @if (uniqPreviewEntity?.creator?.verified) {
      <div class="check-wrap ms-1">
        <i class="icon icon-check-circle-outline-12 bg-white-alpha-5"></i>
      </div>
    }
  </div>
  @if (showPaymentButton || showStatusIcons) {
    <div class="d-flex align-items-center id-card-actions">
      @if (showStatusIcons) {
        @if (!forSale && showAvailability) {
          <ultra-id-card-quantity-status
            class="me-2"
            [quantityAvailability]="uniqPreviewEntity?.quantity"
            (click)="handlePropertyQuickAccess(properties.QUANTITY)" />
        }
        @if (showTradability) {
          <ultra-actions-status
            class="me-2"
            [period]="uniqPreviewEntity?.tradingPeriod"
            [action]="actions.TRADABILITY"
            [availability]="uniqPreviewEntity?.tradingPeriod?.resaleAvailability"
            (click)="handlePropertyQuickAccess(properties.TRADABILITY)" />
        }
        @if (showTransferability) {
          <ultra-actions-status
            class="me-2"
            [period]="uniqPreviewEntity?.transferPeriod"
            [action]="actions.TRANSFERABILITY"
            [availability]="uniqPreviewEntity?.transferPeriod?.transferAvailability"
            (click)="handlePropertyQuickAccess(properties.TRANSFERABILITY)" />
        }
      }
      @if (showPaymentButton && !isRestricted) {
        @if (!forSale && !isUniqOwned) {
          <span>
            @if (!(uniqPreviewEntity?.quantity?.quantityLeft === '0' && type === 'uniqfactory')) {
              @if (uniqPreviewEntity?.prices?.length) {
                <button
                  class="price price--purple price__currency d-inline-block ms-1 rounded d-flex d-flex-center"
                  [class.price__free]="uniqPreviewEntity?.prices?.[0].amount | isFree"
                  [class.price--disabled]="!isBuyAvailable"
                  [disabled]="!isBuyAvailable"
                  ultraAutotestAttribute="uniq-overview-price-button"
                  (click)="onBuyClick($event)">
                  <span class="price__text-active">Buy Now</span>
                  <ultra-uniq-price
                    [type]="type"
                    [price]="uniqPreviewEntity.prices[0].amount"
                    [symbol]="uniqPreviewEntity.prices[0].currency?.symbol"
                    [minResellPrice]="uniqPreviewEntity.tradability?.minimumResellPrice?.amount"
                    [minResellPriceCurrency]="uniqPreviewEntity.tradability?.minimumResellPrice?.currency.code" />
                </button>
              }
            } @else {
              <span
                ultraAutotestAttribute="uniq-sold-out-button"
                class="price price--disabled d-inline-block ms-1 rounded d-flex d-flex-center"
                i18n
                (click)="$event.stopPropagation()"
                >Sold Out
              </span>
            }
          </span>
        } @else {
          @if (isDisabled) {
            <button class="btn btn-primary btn-loading ms-1 rounded d-inline-flex d-flex-center min-view-110">
              <span class="loader" ultraAutotestAttribute="button-placeholder"></span>
            </button>
          } @else {
            @if (isSetForSale()) {
              <ultra-withdraw (clicked)="onWithdraw()">
                <span
                  >{{ uniqPreviewEntity?.prices[0]?.currency?.symbol }}&nbsp;{{
                    uniqPreviewEntity?.prices[0]?.amount | number: '1.2-2'
                  }}</span
                >
              </ultra-withdraw>
            } @else {
              <button
                i18n
                class="btn btn-primary ms-1 rounded d-inline-flex d-flex-center min-view-110"
                ultraAutotestAttribute="sell-button"
                [disabled]="!isSellAvailable()"
                (click)="onSell()">
                Sell<i class="icon icon-arrow-sm ms-2"></i>
              </button>
            }
          }
        }
      }
    </div>
  }
  @if (shouldValidateMetadata || isRestricted) {
    <ultra-metadata-information-manager
      class="metadata-status"
      [metadataStatus]="uniqPreviewEntity?.info?.status"
      [showInfoSeparation]="showStatusIcons"
      [shortMessage]="true"
      [size]="16" />
  }
</ultra-id-card-header>
