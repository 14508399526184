import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

import { ContentSkeletonComponent } from '../content-skeleton.component';
import { ContentSkeletonTheme } from '../content-skeleton-theme.enum';

import { handleThemeClass, repeatElementByTemplate } from './content-skeleton-theme-class.helper';

@Directive({ selector: '[contentSkeleton]', standalone: true })
export class ContentSkeletonDirective implements OnChanges {
  /**
   * If true, host element will be visible.
   */
  @Input('contentSkeleton') isLoading = false;
  /**
   * Number of time we repeat the host element.
   */
  @Input('contentSkeletonRepeat') repeat = 1;

  @Input('contentSkeletonWidth') width = '100%';
  @Input('contentSkeletonHeight') height = '100%';
  @Input('contentSkeletonMaxWidth') maxWidth = '100%';
  /**
   * List of extra classes that can be added to host element in some specific cases, better to create themes instead of adding in several places.
   */
  @Input('contentSkeletonClassName') className: string[] | null = null;
  /**
   * An already created theme with styles specific for certain usage.
   */
  @Input('contentSkeletonTheme') theme: ContentSkeletonTheme = null;
  /**
   * If true, background skeleton animation will be added.
   */
  @Input('contentSkeletonIsAnimated') isAnimated = false;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.viewContainer.clear();

      if (changes.isLoading.currentValue) {
        Array.from({ length: repeatElementByTemplate(this.theme, this.repeat) }).forEach((_repeat, i) => {
          const ref = this.viewContainer.createComponent(ContentSkeletonComponent);

          this.assignComponentValues(ref.instance, i);
        });
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }

  private assignComponentValues(componentInstance: ContentSkeletonComponent, index: number): void {
    Object.assign(componentInstance, {
      width: this.width,
      height: this.height,
      maxWidth: this.maxWidth,
      className: this.className,
      isAnimated: this.isAnimated,
      theme: handleThemeClass(index, this.theme),
    });
  }
}
