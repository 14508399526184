<form
  class="search d-flex align-items-center position-relative"
  [class.search--animate]="pending"
  (submit)="submit(search)"
  [class.search--opened]="isSearchOpen"
  (clickOutside)="close($event)"
  [disabled]="!isSearchOpen"
  ultraClickOutside
>
  <label [for]="id" class="form-label search__trigger d-flex align-items-center mb-0">
    <span class="search__icon">
      <i class="icon icon-search"></i>
    </span>
  </label>

  <input
    class="search__input border-0 bg-transparent text-truncate text-white f-size-14 ls-quoter pe-3"
    [id]="id"
    name="search"
    autocomplete="off"
    placeholder="Search in Ultra Games..."
    i18n-placeholder
    (focus)="open()"
    [(ngModel)]="search"
    (ngModelChange)="changed($event)"
    ultraAutotestAttribute="navbar-search-input"
  />

  <i
    class="icon icon-cross-sm search__close cursor-pointer bg-white position-absolute"
    (click)="close()"
    ultraAutotestAttribute="navbar-search-close"
  ></i>
</form>
