import { UserGame } from '../../../user/models/user-game.model';
import { Game } from '../../models/game.model';

export const userGameMock: UserGame = new UserGame({
  game: new Game({
    id: '5bca49266f8ccb0007fd24d7',
    liveGame: {
      title: 'The Witcher® 3: Wild Hunt',
    },
  }),
  state: 'installed',
  autoUpdate: true,
});
