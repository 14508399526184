import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IdCardAction } from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';
import { ExpansionPanelComponent } from '../../accordion/expansion-panel/expansion-panel.component';
import { IdCardContentHeaderConfig } from '../../id-card-content';
import { IdCardAdapter, IdCardEntity } from '../../id-card-content/adapters/id-card.adapter';
import { UniqFactoryIdCardAdapter } from '../../id-card-content/adapters/uniq-factory-id-card.adapter';
import { IdCardPanelTransformerHelper } from '../../id-card-content/id-card-panel-dispatcher/helpers/id-card-panel-transformer.helper';
import {
  IdCardPanelDispatcherService,
  IdCardPropertyTabs,
} from '../../id-card-content/id-card-panel-dispatcher/providers/id-card-panel-dispatcher.service';
import { TabGroupComponent } from '../../tabs/tab-group/tab-group.component';
import { UniqBodyDatasource } from '../../uniq-preview/providers/uniq-body.datasource';

const animationTime = '0.3s ease';

@UntilDestroy()
@Component({
  selector: 'ultra-uniq-factory-detail',
  templateUrl: './uniq-factory-detail.component.html',
  styleUrls: ['./uniq-factory-detail.component.scss'],
  animations: [
    trigger('fadeGradient', [
      state('open', style({ background: 'linear-gradient(to left, rgba(60, 56, 70, 0), 15%, rgb(60, 56, 70))' })),
      state('close', style({ background: 'linear-gradient(to left, rgba(60, 56, 70, 1), 15%, rgb(60, 56, 70))' })),
      transition('close => open', animate(animationTime)),
      transition('open => close', animate(animationTime)),
    ]),
    trigger('animOpacity', [
      state('open', style({ opacity: 1 })),
      state('close', style({ opacity: 0 })),
      transition('close => open', animate(animationTime)),
      transition('open => close', animate(animationTime)),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqFactoryDetailComponent implements OnInit, AfterViewInit {
  @Input() uniqFactory: IUniqFactory;
  @Input() expansionPanel: ExpansionPanelComponent;
  @Input() set fetchOnOpen(value: boolean) {
    if (value && this.uniqBodyDatasource) {
      this.uniqBodyDatasource.getData(this.uniqFactory.onChainId).subscribe((uniqFactory) => {
        this.idCardEntity = this.idCardAdapter.transform(uniqFactory);
        this.cd.detectChanges();
      });
    }
  }
  @Input() showPaymentButton = true;
  @Input() isProductPage = false;

  @Output() buy = new EventEmitter<IUniqFactory>();
  @Output() closed = new EventEmitter<any>();

  @ViewChild(TabGroupComponent, { static: true }) tabGroup: TabGroupComponent;

  idCardEntity: IdCardEntity;
  properties = IdCardPropertyTabs;
  idCardContentHeaderConfig: Partial<IdCardContentHeaderConfig>;
  showBackground = true;

  private idCardAdapter: IdCardAdapter<IUniqFactory>;
  readonly actions = IdCardAction;

  constructor(
    @Optional() private panelDispatcher: IdCardPanelDispatcherService,
    @Optional() private uniqBodyDatasource: UniqBodyDatasource<IUniqFactory>,
    private cd: ChangeDetectorRef,
  ) {
    this.idCardAdapter = new UniqFactoryIdCardAdapter();
  }

  ngOnInit() {
    this.idCardEntity = this.idCardAdapter.transform(this.uniqFactory);

    this.idCardContentHeaderConfig = {
      hideCloseIcon: this.isProductPage,
      hideCopyToClipboard: false,
      hideAdvancedDetails: true,
    };
  }

  ngAfterViewInit() {
    this.panelDispatcher?.pipe(untilDestroyed(this)).subscribe((dispatchedInfo) => {
      const { tabNumber } = IdCardPanelTransformerHelper.transform(dispatchedInfo, 2);
      this.tabGroup.select(tabNumber);
    });
  }

  closeItem() {
    this.expansionPanel ? this.expansionPanel.close() : this.closed.emit();
  }

  onTabChange({ index }) {
    this.toggleTabBackground(index);
    this.toggleTabHeaderDetails(index);
  }

  getBackgroundImage() {
    if (!this.idCardEntity?.medias?.backGroundImage?.uri) {
      return '';
    }
    return this.showBackground ? `url(${this.idCardEntity.medias.backGroundImage.uri})` : '';
  }

  handlePropertyQuickAccess(property: IdCardPropertyTabs): void {
    this.panelDispatcher?.next(property);
  }

  private toggleTabBackground(tabIndex: number): void {
    this.showBackground = tabIndex === 0;
  }

  private toggleTabHeaderDetails(tabIndex: number) {
    this.idCardContentHeaderConfig = {
      ...this.idCardContentHeaderConfig,
      hideAdvancedDetails: tabIndex === 0,
    };
  }
}
