import { AppId } from '@ultra/core/models';

export enum DeepLinkKind {
  APPLICATION = 'apps',
  AUTHENTICATION = 'auth',
}

export interface AppDeepLinkTarget {
  appId: AppId;
  path: string;
}
