import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

/**
 * TODO Remove this service once Recommended and Suggested games have dedicated queries
 * Temporary solution will fetch a static JSON hosted by Ultra listing game ids:
 * {
 *  "carousel": ["5f7c788706798a4cf9f691c0", "5f7f2ac870897573aabd7428"],
 *  "suggested": ["5f8fe0d909863a33d8e92d7c", "5f96d23308c9900bc162d5d6"]
 * }
 */

@Injectable({
  providedIn: 'root',
})
export class GameStoreHttpService {
  storeHomeConfig$: Observable<any>;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private readonly environment: IEnvironment) {
    this.storeHomeConfig$ = this.http.get(`${this.environment.storeHomeConfigUrl}`).pipe(shareReplay());
  }

  getRecommendedGameIds(): Observable<string[]> {
    return this.storeHomeConfig$.pipe(map((storeHomeConfig) => storeHomeConfig?.carousel));
  }

  getSuggestedGameIds(): Observable<string[]> {
    return this.storeHomeConfig$.pipe(map((storeHomeConfig) => storeHomeConfig?.suggested));
  }
}
