<div class="tag-wrapper">
  <div
    class="d-flex justify-content-center align-items-center tag"
    [attr.contenteditable]="editing"
    [attr.title]="getDisplayValue()">
    {{ getDisplayValue() }}
  </div>

  @if (isDeleteIconVisible()) {
    <div class="tag-remove">
      <i class="icon icon-cross-sm cursor-pointer" (click)="remove($event)"></i>
    </div>
  }
</div>
