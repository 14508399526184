import { ILicense, IUserGameBranch, LicenseStatus, RepositoryType } from '../..';
/**
 * Get the license property of the game and all its DLCs
 */
export const getLicenseProperty = <T extends ILicense, K extends keyof T>(game: IUserGameBranch, prop: K): T[K][] => {
  const gameLicenseProp = (<T>game.buildContentNodes[0].content.tokens[0].license)[prop];
  const dlcLicenseProps = game.componentContentNodes.reduce((licenses, item) => {
    if (item.content.repositoryType === RepositoryType.DLC) {
      licenses.push((<T>item.content.tokens[0].license)[prop]);
    }
    return licenses;
  }, []);

  return [gameLicenseProp, ...dlcLicenseProps];
};

export const getTicketIdByRepositoryId = (id: string, game: IUserGameBranch): string | null => {
  const { repositoryId, tokens } = game.buildContentNodes[0].content;
  if (repositoryId === id) {
    return tokens[0].license.ticketId;
  }

  for (const content of game.buildContentNodes[0].compatibleContents) {
    if (
      content.repositoryType === RepositoryType.DLC &&
      content.tokens[0].license.status === LicenseStatus.SUCCESS &&
      content.repositoryId === id
    ) {
      return content.tokens[0].license.ticketId;
    }
  }
  return null;
};

export const existsLicenseWithStatus = (licenses: LicenseStatus[], status: LicenseStatus): boolean => {
  return licenses.some((license) => license === status);
};

export const getTicketIdsToDeactivate = (idsToDeactivate: string[], idsToActivate: string[]): string[] => {
  const idsMap: { [key: string]: boolean } = idsToActivate.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
  return [...idsToDeactivate].filter((id) => !idsMap[id]);
};

/**
 * Given a game, returns true if the game
 * or any of its DLCs is in the CREATING status
 */
export const isLicenseCreating = (game: IUserGameBranch): boolean => {
  const licenseStatuses = getLicenseProperty(game, 'status');
  return existsLicenseWithStatus(licenseStatuses, LicenseStatus.CREATING);
};

/**
 * Given a game, returns true if the game
 * or any of its DLCs is in the ERROR status
 */
export const isLicenseErrored = (game: IUserGameBranch): boolean => {
  const licenseStatuses = getLicenseProperty(game, 'status');
  return existsLicenseWithStatus(licenseStatuses, LicenseStatus.ERROR);
};

export const gameHasLicense = (game: IUserGameBranch): boolean => {
  const licenseStatuses = getLicenseProperty(game, 'status');
  return existsLicenseWithStatus(licenseStatuses, LicenseStatus.SUCCESS);
};

/**
 * We should only activate licenses if there is no license in CREATING status
 * and at least we have one license with status SUCCESS
 */
export const licensesCanBeActivated = (game: IUserGameBranch): boolean => {
  const licenseStatuses = getLicenseProperty(game, 'status');
  const canBeActivated =
    !existsLicenseWithStatus(licenseStatuses, LicenseStatus.CREATING) &&
    existsLicenseWithStatus(licenseStatuses, LicenseStatus.SUCCESS);
  return canBeActivated;
};

export const gameTicketsIds = (game: IUserGameBranch): string[] =>
  getLicenseProperty(game, 'ticketId').filter((ticketId) => !!ticketId);
