<div class="preview position-relative" ultraAutotestAttribute="id-card-header">
  <div class="preview__inner d-flex position-relative align-items-center justify-content-between overflow-hidden">
    <div class="d-flex align-items-center w-100 content-wrapper">
      <ng-content select=".token-type-icon"></ng-content>
      <div class="rounded overflow-hidden me-2 ms-1 img-placeholder-{{ imageType }}">
        <ng-content select=".id-card-image"></ng-content>
      </div>
      <div ultraAutotestAttribute="uniq-preview-content" class="d-flex flex-column w-100 content">
        <ng-content></ng-content>
      </div>
    </div>
    @if (shouldValidateMetadata) {
      <div class="ms-auto">
        <ng-content select=".metadata-status"></ng-content>
      </div>
    }
    <ng-content select=".id-card-actions"></ng-content>
  </div>
</div>
