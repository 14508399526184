import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutotestAttributeDirective } from '../../autotests';

import { TagComponent } from './tag/tag.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { TagsInputComponent } from './tags-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    FormsModule,
    AutotestAttributeDirective,
  ],
  declarations: [
    TagsInputComponent, // Todo Delete it
    TagComponent,
    TagInputComponent,
  ],
  exports: [
    TagsInputComponent, // Todo Delete it
    TagComponent,
    TagInputComponent,
  ],
})
export class TagsInputModule {}
