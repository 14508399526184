@if (
  displayState?.status === actionStatuses.ABLE ||
  displayState?.status === actionStatuses.TILL ||
  isTransferableUnrestricted
) {
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="id-card-transfer-section-notification">
      @if (isOnSale) {
        <ultra-notification-message type="error">
          <ng-template>
            <div class="message d-flex align-items-center" i18n>
              Your Uniq is currently on Sale on the Marketplace.<br />
              You need to withdraw it from the Marketplace to Transfer it.
            </div>
          </ng-template>
        </ultra-notification-message>
      }
      @if (isTransferableUnrestricted) {
        <ultra-notification-message type="warning">
          <ng-template>
            <div class="message d-flex align-items-center" i18n>
              <i class="icon icon-warning me-1"></i>This uniq can only be transferred to unrestricted addresses
            </div>
          </ng-template>
        </ultra-notification-message>
      }
    </div>
    <div class="id-card-transfer-section-field">
      <div class="b2-bold py-1" i18n>Recipient Wallet Account</div>
      <ultra-input
        placeholder="ie: aa1cd2be3gk4"
        [control]="recipientControl"
        [dataId]="'card-recipient-field'"></ultra-input>
    </div>
    <div class="id-card-transfer-section-field flex-grow-1">
      <div class="b2-bold py-1" i18n>Memo</div>
      <ultra-textarea
        placeholder="ie: the Uniq I promised you"
        cssCountClass="token-count"
        [maxLength]="256"
        [height]="true"
        [control]="memoControl"
        [dataId]="'ultra-textarea-field'"></ultra-textarea>
    </div>
    <div class="id-card-transfer-section-button">
      <button
        class="btn col-12 btn-primary"
        [disabled]="isOnSale || form.invalid"
        i18n
        ultraAutotestAttribute="card-transfer-uniq-button">
        Transfer Uniq
      </button>
    </div>
  </form>
} @else {
  <div class="text-center text-white-alpha-3">
    <p>
      <ultra-id-card-actions-title [displayState]="displayState" action="transferability" />
    </p>
    <p>
      <button class="btn btn-light text-transform-none" (click)="handleTransferabilityQuickAccess()" i18n>
        View Transferability details
      </button>
    </p>
    @if (allowUnrestrictedTransfer) {
      <p i18n class="b2-regular text-white-alpha-5">
        Uniq Creators can whitelist wallet addresses<br />that can bypass transferability usually for management
        purposes.<br />
        If you’ve been asked to send your Uniq to such address
        <button
          type="button"
          class="btn btn-transparent-purple p-0 text-transform-none fw-400 bg-transparent"
          (click)="handleTransferToUnrestrictedAddress()">
          click here
        </button>
      </p>
    }
  </div>
}
