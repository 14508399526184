import { Component, Input, ViewEncapsulation } from '@angular/core';

import { IdCardEntity } from '../adapters/id-card.adapter';

@Component({
  selector: 'ultra-id-card-attributes',
  templateUrl: './id-card-attributes.component.html',
  styleUrls: ['./id-card-attributes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdCardAttributesComponent {
  @Input() idCardEntity: IdCardEntity;
}
