import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { startWith, take } from 'rxjs/operators';

import { MixpanelEvent, MixpanelService } from '@ultra/core/services';

import { getUniqEventData } from '../../../helpers/mixpanel-uniq-data.helper';
import { IGeofencingData } from '../../../models/geofencing/interfaces/geofencing-data.interface';
import { IUniq, UniqStatus } from '../../../models/uniq/interfaces/uniq.interface';
import { IdCardAction } from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { UniqPriceService } from '../../../uniq/uniq-price.service';
import { MetadataStatus } from '../../../uniq-factory/uniq-factory-metadata.interface';
import { IdCardContentHeaderConfig } from '../../id-card-content';
import { IdCardAdapter, IdCardEntity } from '../../id-card-content/adapters/id-card.adapter';
import { UniqIdCardAdapter } from '../../id-card-content/adapters/uniq-id-card.adapter';
import { IdCardPanelTransformerHelper } from '../../id-card-content/id-card-panel-dispatcher/helpers/id-card-panel-transformer.helper';
import {
  IdCardPanelDispatcherService,
  IdCardPropertyTabs,
} from '../../id-card-content/id-card-panel-dispatcher/providers/id-card-panel-dispatcher.service';
import { TabGroupComponent } from '../../tabs/tab-group/tab-group.component';
import { UniqBodyDatasource } from '../../uniq-preview/providers/uniq-body.datasource';

@UntilDestroy()
@Component({
  selector: 'ultra-uniq-detail',
  styleUrls: ['./uniq-detail.component.scss'],
  templateUrl: './uniq-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqDetailComponent implements AfterViewInit, OnInit {
  @Input() set uniq(uniq: IUniq) {
    this.idCardEntity = this.idCardAdapter.transform(uniq);
    this._uniq = uniq;
  }
  get uniq(): IUniq {
    return this._uniq;
  }
  private _uniq: IUniq;
  @Input() geofencingData: IGeofencingData;
  @Input() isContentVisible = true;
  @Input() forSale = false;
  @Input() showPaymentButton = true;
  @Input() isProductPage = false;
  @Input() isUniqOwned = false;
  @Input() set fetchOnOpen(value: boolean) {
    if (value && this.uniqBodyDatasource) {
      this.uniqBodyDatasource.getData(this.uniq.onChainId).subscribe((uniq) => {
        this.idCardEntity = this.idCardAdapter.transform(uniq);
        // Temporary quick fix, children of ultra-id-card-marketplace: ultra-id-card-sell and ultra-id-card-transfer
        // shouldn't use uniq, all tabs content should relly on idCardEntity type only
        this.fetchedUniq = uniq;
        this.displayMarketplaceTab = true;
        this.cdr.detectChanges();
      });
    }
  }
  shouldDisableAdvancedDetailsContent = false;
  private _shouldValidateMetadata = false;
  @Input() set shouldValidateMetadata(value: boolean) {
    this._shouldValidateMetadata =
      value && this.uniq?.metadata && this.uniq?.metadata.status !== this.metadataStatusEnum.VALID;
    this.shouldDisableAdvancedDetailsContent =
      (this._shouldValidateMetadata && this.uniq?.metadata?.status === this.metadataStatusEnum.INVALID) ||
      this.uniq?.metadata?.restricted;
  }
  get shouldValidateMetadata(): boolean {
    return this._shouldValidateMetadata;
  }

  @Output() buy = new EventEmitter<string>();
  @Output() closed = new EventEmitter<any>();

  @ViewChild(TabGroupComponent, { static: false }) tabGroup: TabGroupComponent;

  get isRestricted(): boolean {
    return !!this.uniq?.metadata?.restricted;
  }

  idCardEntity: IdCardEntity;
  properties = IdCardPropertyTabs;
  showBackground = true;
  fetchedUniq: IUniq;
  displayMarketplaceTab = false;
  idCardContentHeaderConfig: Partial<IdCardContentHeaderConfig>;
  isOwnedUniqControlsVisible$ = new BehaviorSubject<boolean>(false);

  private idCardAdapter: IdCardAdapter<IUniq> = new UniqIdCardAdapter();

  readonly metadataStatusEnum = MetadataStatus;
  readonly actions = IdCardAction;

  private cdr = inject(ChangeDetectorRef);
  private mixpanelService = inject(MixpanelService);

  constructor(
    private uniqPriceService: UniqPriceService,
    @Optional() private panelDispatcher: IdCardPanelDispatcherService,
    @Optional() private uniqBodyDatasource: UniqBodyDatasource<IUniq>,
  ) {
    if (!this.uniqBodyDatasource) {
      this.displayMarketplaceTab = true;
    }
  }

  ngOnInit(): void {
    this.idCardContentHeaderConfig = {
      hideCloseIcon: this.isProductPage,
      hideCopyToClipboard: false,
      hideAdvancedDetails: true,
    };
  }

  ngAfterViewInit() {
    this.panelDispatcher?.pipe(untilDestroyed(this)).subscribe((dispatchedInfo) => {
      const { tabNumber } = IdCardPanelTransformerHelper.transform(dispatchedInfo);
      this.tabGroup.select(tabNumber);
      this.cdr.detectChanges();
    });

    this.tabGroup.tabChange.pipe(untilDestroyed(this), startWith('')).subscribe(() => {
      this.isOwnedUniqControlsVisible$.next(this.isSellButtonVisible());
      this.cdr.detectChanges();
    });
  }

  onTabChange({ index }) {
    this.toggleTabBackground(index);
    this.toggleTabHeaderDetails(index);
    this.cdr.detectChanges();
  }

  closeItem() {
    this.closed.emit();
    this.cdr.detectChanges();
  }

  getBackgroundImage() {
    if (!this.idCardEntity?.medias?.backGroundImage?.uri) {
      return '';
    }
    return this.showBackground ? `url(${this.idCardEntity.medias.backGroundImage.uri})` : '';
  }

  handlePropertyQuickAccess(property: IdCardPropertyTabs): void {
    if (!this.panelDispatcher) {
      return;
    }
    this.panelDispatcher.next(property);
  }

  onSell(event: Event): void {
    event.stopPropagation();
    this.handlePropertyQuickAccess(IdCardPropertyTabs.MARKETPLACE);
    this.mixpanelService.track(MixpanelEvent.UNIQ_SALE_INITIATE, getUniqEventData(this.uniq));
  }

  onWithdraw(event: Event): void {
    event.stopPropagation();
    this.handlePropertyQuickAccess(IdCardPropertyTabs.MARKETPLACE);
    this.uniqPriceService
      .getUniqPrice(
        this.uniq.saleInfo?.price?.amount,
        this.uniq.factory?.tradability?.minimumResellPrice?.amount,
        this.uniq.factory?.tradability?.minimumResellPrice?.currency?.code,
      )
      .pipe(take(1))
      .subscribe((uniqPrice) => {
        this.mixpanelService.track(MixpanelEvent.UNIQ_CANCEL_SALE_INITIATE, getUniqEventData(this.uniq, uniqPrice));
      });
  }

  showMarketPlaceContent(): boolean {
    return this.forSale || this.isUniqOwned;
  }

  isSetForSale(): boolean {
    return this.uniq.status === UniqStatus.ON_SALE;
  }

  get isBuyAvailable(): boolean {
    return (
      !this.idCardEntity.tradingPeriod ||
      !this.idCardEntity.tradingPeriod.endDate ||
      new Date(this.idCardEntity.tradingPeriod.endDate).getTime() > Date.now()
    );
  }

  isSellAvailable(): boolean {
    return (
      this.idCardEntity.tradingPeriod?.resaleAvailability &&
      !this.idCardEntity.tradingPeriod.resaleAvailability.isLocked
    );
  }

  onBuyClick(event: MouseEvent): void {
    event.stopPropagation();
    this.buy.emit(this.uniq.id);
  }

  private isSellButtonVisible(): boolean {
    const { tabNumber } = IdCardPanelTransformerHelper.transform(IdCardPropertyTabs.MARKETPLACE);
    return this.tabGroup.selectedIndex !== tabNumber && this.showMarketPlaceContent();
  }

  private toggleTabBackground(tabIndex: number): void {
    this.showBackground = tabIndex === 0;
  }

  private toggleTabHeaderDetails(tabIndex: number) {
    this.idCardContentHeaderConfig = {
      ...this.idCardContentHeaderConfig,
      hideAdvancedDetails: tabIndex === 0,
    };
  }
}
