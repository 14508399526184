import { inject, Injectable } from '@angular/core';

import { UltraosApiService } from './ultraos/ultraos-api.service';
/**
 * A wrapper service to centralize client specific features.
 */
@Injectable({
  providedIn: 'root',
})
export class ClientFeatureService {
  private ultraosApiService = inject(UltraosApiService);
  // Temporary variable that will be removed once the client is fully implemented.
  isElectronClient = this.ultraosApiService.isElectronClient;
  /**
   * True if Ultra is being executed in the client.
   */
  isInClient = this.ultraosApiService.clientLayoutAvailable();

  navigateToApp(url: string) {
    return this.ultraosApiService.openApp(url, true);
  }
}
