import { Component, Input } from '@angular/core';

@Component({
  selector: 'ultra-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent {
  @Input()
  public progress = 0;

  @Input()
  public status: string;

  @Input()
  public radius = 55;

  @Input()
  public progressColor = '#C5ABFF';

  @Input()
  public isLocked = false;

  public background = 'rgba(255, 255, 255, 0.1)';
}
