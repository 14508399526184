import { IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';
import { ITransferability } from '../..';
import { IRevenue, IUniqTradingPeriod } from '../../token-factory/interfaces/tradability.interface';

import { ICurrency } from './currency.interface';
import { IUniqActionsPeriod } from './uniq-actions-period.interface';
import { IUniqAvailability } from './uniq-availability.interface';
import { UniqMetadata } from './uniq-metadata.interface';

export type MediaContentType = 'IMAGE' | 'VIDEO';

export type AccountType = 'USER' | 'COMPANY';

export enum UniqStatus {
  ON_SALE = 'ON_SALE',
  READY_TO_SALE = 'READY_TO_SALE',
  PENDING_OWNED = 'PENDING_OWNED',
  OWNED = 'OWNED',
  PENDING_ON_SALE = 'PENDING_ON_SALE',
  PENDING_BURNED = 'PENDING_BURNED',
  BURNED = 'BURNED',
}

export interface IMedias {
  product: IMedia;
  square: IMedia;
  hero: IMedia;
  gallery: IMedia[];
}

export interface IMedia {
  uri: string;
  contentType?: MediaContentType;
}

export interface IMonetaryAmount {
  currency: ICurrency;
  amount: string;
}
export interface IAccount {
  blockchainId: string;
  eba: EBAccount;
  verified: boolean;
}

export interface ICreator {
  blockchainId: string;
  name: string;
  verified?: boolean;
  isThirdPartyCreator?: boolean;
}

interface EBAccount {
  name: string;
  accountId: string;
  type: AccountType;
}

export interface UniqRevenueShare {
  account: IAccount;
  percentage: number;
}

export interface ExchangeDate {
  onSpecificDay: string | null;
  afterNumberDays: number;
}

export interface UniqTradability {
  enabled: boolean;
  minimumResellPrice?: IMonetaryAmount;
  resellRevenueShare?: UniqRevenueShare[];
  tradingStartDate?: ExchangeDate;
  tradingEndDate?: ExchangeDate;
  tradingPeriod?: IUniqTradingPeriod;
}

export interface IUniqTransferPeriod extends IUniqActionsPeriod {
  transferAvailability: IUniqTransferAvailability;
}

export interface IUniqTransferAvailability extends IUniqAvailability {
  transferDate?: string;
}

export interface IQuantityAvailability {
  maxMintableTokens: string | null;
  quantityLeft: string | null;
  existing: string | null;
  minted: string | null;
  reserved?: string | null;
  maxCirculatingSupply?: string | null;
}

export interface BlockchainLineage {
  explorerUri: string;
}

export interface IUniq {
  id: string;
  blockId: string;
  onChainId: string;
  serialNumber: string;
  mintIndex: number;
  correlationId: string;
  owner: IAccount;
  status: UniqStatus;
  factory: IUniqFactory;
  mintDate: string;
  tradingPeriod?: IUniqTradingPeriod;
  transferPeriod?: IUniqTransferPeriod;
  saleInfo: SaleInfo;
  metadata?: UniqMetadata;
  blockchainLineage?: BlockchainLineage;
  tradability?: UniqTradability;
  transferability?: ITransferability;
}

interface SaleInfo {
  onSaleDate: string;
  price: IRevenue;
}

export interface IUniqStatusFilter {
  ids?: string[];
  factoryIds?: string[];
}
