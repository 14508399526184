<ultra-uniq-detail
  class="uniq-preview-body with-border"
  [forSale]="data?.forSale"
  [uniq]="data?.uniq"
  [fetchOnOpen]="true"
  [isUniqOwned]="isOwned"
  [shouldValidateMetadata]="data?.shouldValidateMetadata"
  (closed)="close()"
  (buy)="onBuy($event)"
/>
