import { formatNumber } from '@angular/common';

import { IQuantityAvailability } from '../../../../models/uniq/interfaces/uniq.interface';

export type IUniqQuantityStatus = 'long' | 'short' | 'very_short' | 'infinite' | 'not_available';

export interface IQuantityBars {
  lowerBarsToFill: number;
  middleBarsToFill: number;
  barsUnfilled: number;
}

export enum UniqQuantityStatusEnum {
  LONG = 'long',
  SHORT = 'short',
  VERY_SHORT = 'very_short',
  INFINITE = 'infinite',
  NOT_AVAILABLE = 'not_available',
}

export enum UniqQuantityStatusColorEnum {
  LONG = '#C5ABFF',
  SHORT = '#FAD198',
  VERY_SHORT = '#FB9393',
  INFINITE = '#8CE895',
  NOT_AVAILABLE = '#A8A7A9',
  OTHERS = '#464250',
  RESERVED = '#ffffff4d',
}

export enum UniqQuantityStatusTitleEnum {
  INFINITE = 'More of these Uniqs can be created',
  NOT_AVAILABLE = 'No more of this Uniq can be created',
  OTHERS = 'Maximum xxx of these Uniqs can be created',
}

export enum UniqQuantityStatusTextEnum {
  INFINITE = 'Available forever',
  NOT_AVAILABLE = 'Not Available',
  OTHERS = 'xxx left',
}

const UniqQuantityStatusRange = {
  short: {
    min: 11,
    max: 1000,
    percentage: 25,
  },
  very_short: {
    min: 1,
    max: 10,
    percentage: 5,
  },
};

export const tooltipColor = (quantityStatus: IUniqQuantityStatus): string => {
  switch (quantityStatus) {
    case 'infinite':
      return 'success';
    case 'long':
      return 'primary';
    case 'short':
      return 'warning';
    case 'very_short':
      return 'error';
    case 'not_available':
      return 'neutral';
  }
};

export class IdCardQuantityDisplayHelper {
  static getStatusColor(status: IUniqQuantityStatus): string {
    switch (status) {
      case UniqQuantityStatusEnum.INFINITE: {
        return UniqQuantityStatusColorEnum.INFINITE;
      }
      case UniqQuantityStatusEnum.VERY_SHORT: {
        return UniqQuantityStatusColorEnum.VERY_SHORT;
      }
      case UniqQuantityStatusEnum.SHORT: {
        return UniqQuantityStatusColorEnum.SHORT;
      }
      case UniqQuantityStatusEnum.LONG: {
        return UniqQuantityStatusColorEnum.LONG;
      }
      case UniqQuantityStatusEnum.NOT_AVAILABLE: {
        return UniqQuantityStatusColorEnum.NOT_AVAILABLE;
      }
    }
  }

  static getStatusText(status: IUniqQuantityStatus, quantityLeft: string): string {
    switch (status) {
      case UniqQuantityStatusEnum.INFINITE: {
        return UniqQuantityStatusTextEnum.INFINITE;
      }
      case UniqQuantityStatusEnum.NOT_AVAILABLE: {
        return UniqQuantityStatusTextEnum.NOT_AVAILABLE;
      }
      default: {
        return UniqQuantityStatusTextEnum.OTHERS.replace('xxx', formatNumber(Number(quantityLeft), 'en-US').toString());
      }
    }
  }

  static getQuantityStatus(quantityLeft: string, maxCirculatingSupply: string): IUniqQuantityStatus {
    if (maxCirculatingSupply === null) {
      return UniqQuantityStatusEnum.INFINITE;
    }
    if (Number(quantityLeft) === 0) {
      return UniqQuantityStatusEnum.NOT_AVAILABLE;
    }
    if (
      this.inRange(maxCirculatingSupply, UniqQuantityStatusRange.very_short.min, UniqQuantityStatusRange.very_short.max)
    ) {
      return UniqQuantityStatusEnum.VERY_SHORT;
    }
    if (this.inRange(maxCirculatingSupply, UniqQuantityStatusRange.short.min, UniqQuantityStatusRange.short.max)) {
      return this.getQuantityStatusByPercentage(
        UniqQuantityStatusEnum.SHORT,
        this.getQuantityLeftPercentage(quantityLeft, maxCirculatingSupply)
      );
    }
    if (Number(maxCirculatingSupply) > UniqQuantityStatusRange.short.max) {
      return this.getQuantityStatusByPercentage(
        UniqQuantityStatusEnum.LONG,
        this.getQuantityLeftPercentage(quantityLeft, maxCirculatingSupply)
      );
    }
  }

  static getQuantityTitle(status: IUniqQuantityStatus, maxCirculatingSupply: string): string {
    if (status === UniqQuantityStatusEnum.INFINITE) {
      return UniqQuantityStatusTitleEnum.INFINITE;
    }
    if (status === UniqQuantityStatusEnum.NOT_AVAILABLE) {
      return UniqQuantityStatusTitleEnum.NOT_AVAILABLE;
    }
    return UniqQuantityStatusTitleEnum.OTHERS.replace(
      'xxx',
      formatNumber(Number(maxCirculatingSupply), 'en-US').toString()
    );
  }

  static getBarRatiosToFill(quantityAvailability: IQuantityAvailability, totalBarsCount: number): IQuantityBars {
    if (quantityAvailability.maxCirculatingSupply === null) {
      return {
        lowerBarsToFill: totalBarsCount,
        middleBarsToFill: 0,
        barsUnfilled: 0,
      };
    }

    let mintedCount = quantityAvailability.minted
      ? this.roundOffCount(quantityAvailability.minted, quantityAvailability.maxCirculatingSupply)
      : 0;
    let reservedCount = quantityAvailability.reserved
      ? this.roundOffCount(quantityAvailability.reserved, quantityAvailability.maxCirculatingSupply)
      : 0;
    let availableCount = quantityAvailability.quantityLeft
      ? this.roundOffCount(quantityAvailability.quantityLeft, quantityAvailability.maxCirculatingSupply)
      : 0;
    const largest = Math.max(mintedCount, reservedCount, availableCount);
    mintedCount = largest === mintedCount ? totalBarsCount - (reservedCount + availableCount) : mintedCount;
    reservedCount = largest === reservedCount ? totalBarsCount - (mintedCount + availableCount) : reservedCount;
    availableCount = largest === availableCount ? totalBarsCount - (reservedCount + mintedCount) : availableCount;
    return {
      lowerBarsToFill: availableCount,
      middleBarsToFill: reservedCount,
      barsUnfilled: mintedCount,
    };
  }

  private static roundOffCount(remaining: string, total: string): number {
    const roundedRatio = Math.round(((Number(remaining) / this.notZero(Number(total))) * 100) / 5);
    return roundedRatio === 0 ? 1 : roundedRatio;
  }

  private static inRange(total: string, min: number, max: number): boolean {
    return Number(total) >= min && Number(total) <= max;
  }

  private static getQuantityStatusByPercentage(
    quantityStatus: IUniqQuantityStatus,
    quantityLeftPercentage: number
  ): IUniqQuantityStatus {
    if (
      quantityStatus === UniqQuantityStatusEnum.LONG &&
      quantityLeftPercentage < UniqQuantityStatusRange.very_short.percentage
    ) {
      return UniqQuantityStatusEnum.VERY_SHORT;
    }
    if (
      quantityStatus === UniqQuantityStatusEnum.LONG &&
      quantityLeftPercentage < UniqQuantityStatusRange.short.percentage
    ) {
      return UniqQuantityStatusEnum.SHORT;
    }
    if (
      quantityStatus === UniqQuantityStatusEnum.SHORT &&
      quantityLeftPercentage < UniqQuantityStatusRange.short.percentage
    ) {
      return UniqQuantityStatusEnum.VERY_SHORT;
    }
    return quantityStatus;
  }

  private static getQuantityLeftPercentage(remaining: string, total: string): number {
    return (Number(remaining) / this.notZero(Number(total))) * 100;
  }

  private static notZero(dividend: number) {
    if (!dividend) {
      throw new Error('The total number of mintable tokens is not valid');
    }
    return dividend;
  }
}
