import { IFactoryGroup } from '../../../groups/groups.model';
import { getEbaName, isVerifiedCreator } from '../../../helpers/uniq-eba-name.helper';
import {
  IMedia,
  IMonetaryAmount,
  IQuantityAvailability,
  IRevenue,
  ITransferability,
  IUniq,
  IUniqTradingPeriod,
  IUniqTransferPeriod,
  UniqMetadataContent,
  UniqTradability,
  UniqValidMetadata,
} from '../../../models/';
import { FactoryType, IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';
import {
  MetadataStatus,
  UniqFactoryMetadataContent,
  UniqFactoryValidMetadata,
} from '../../../uniq-factory/uniq-factory-metadata.interface';

export interface UniqPreviewEntity {
  id: string | number;
  name?: string;
  typeEntity?: 'uniqFactory' | 'groupFactory';
  blockchainId?: string;
  type?: FactoryType;
  info?: {
    title: string;
    subTitle?: string;
    description?: string;
    status: string;
    restricted: boolean;
  };
  medias?: {
    square?: IMedia;
  };
  creator?: {
    name: string;
    blockchainId: string;
    verified: boolean;
  };
  quantity?: IQuantityAvailability;
  tradability?: UniqTradability;
  prices?: IMonetaryAmount[] | IRevenue[];
  tradingPeriod?: IUniqTradingPeriod;
  transferPeriod?: IUniqTransferPeriod;
  transferability?: ITransferability;
  groupTotal?: number;
}

export class UniqPreviewAdapter {
  static transformUniq(uniq: IUniq): UniqPreviewEntity {
    const { id, saleInfo, transferability, tradability, tradingPeriod, transferPeriod } = uniq;

    return {
      ...this.uniqMetadataToInfoAndMedias(uniq),
      ...this.getUniqFactoryInfo(uniq.factory),
      id,
      prices: [saleInfo?.price],
      tradingPeriod: tradingPeriod || tradability?.tradingPeriod,
      transferPeriod: transferPeriod || transferability?.transferPeriod,
      transferability,
    };
  }

  static transformUniqFactory(uniqFactory: IUniqFactory): UniqPreviewEntity {
    const { id, prices, transferability, tradability } = uniqFactory;

    return {
      typeEntity: 'uniqFactory',
      ...this.uniqMetadataToInfoAndMedias(uniqFactory),
      ...this.getUniqFactoryInfo(uniqFactory),
      id,
      prices,
      tradingPeriod: tradability?.tradingPeriod,
      transferPeriod: transferability?.transferPeriod,
      transferability,
    };
  }

  static transformGroupFactory(group: IFactoryGroup): UniqPreviewEntity {
    const { id, prices } = group;
    return {
      typeEntity: 'groupFactory',
      ...this.uniqMetadataToInfoAndMedias(group),
      ...this.getGroupInfo(group),
      id,
      prices,
    };
  }

  private static uniqMetadataToInfoAndMedias(data: IUniq | IUniqFactory | IFactoryGroup): Partial<UniqPreviewEntity> {
    let content: UniqMetadataContent | UniqFactoryMetadataContent | IUniqFactory =
      (data as IUniq).factory || (data as IUniqFactory);

    if ((data as IUniq)?.metadata?.status === MetadataStatus.INVALID) {
      content = {
        name: data.onChainId,
        subName: 'Uniq ID',
      };
    } else if (data?.metadata?.status === MetadataStatus.VALID) {
      content = (data?.metadata as UniqValidMetadata)?.content;
    } else if ((data as IUniqFactory)?.metadata?.status === MetadataStatus.VALID) {
      content = (data?.metadata as UniqFactoryValidMetadata)?.content;
    }

    return {
      blockchainId: data.onChainId,
      info: {
        title: content.name === '???' ? `${data.onChainId}` : content?.name,
        subTitle: content.subName === '???' ? 'Uniq ID' : content?.subName,
        description: content.description,
        status: data.metadata?.status,
        restricted: data.metadata && 'restricted' in data.metadata ? data.metadata.restricted : false,
      },
      medias: {
        square: content.medias?.square,
      },
    };
  }

  private static getUniqFactoryInfo(uniqFactory: IUniqFactory): Partial<UniqPreviewEntity> {
    return {
      type: uniqFactory.type,
      creator: {
        blockchainId: uniqFactory.creator?.blockchainId,
        name: uniqFactory.creator?.eba?.name || getEbaName(uniqFactory.creator?.blockchainId),
        verified: uniqFactory.creator?.verified || isVerifiedCreator(uniqFactory.creator?.blockchainId),
      },
      tradability: uniqFactory.tradability,
      quantity: uniqFactory.quantity,
    };
  }
  private static getGroupInfo(group: IFactoryGroup): Partial<UniqPreviewEntity> {
    return {
      groupTotal: group.factories.length,
      creator: {
        blockchainId: group.manager?.blockchainId,
        name: group.manager?.eba?.name || getEbaName(group.manager?.blockchainId),
        verified: group.manager?.verified || isVerifiedCreator(group.manager?.blockchainId),
      },
    };
  }
}
