import { gql } from 'apollo-angular';

import { UNIQ_FACTORY, UNIQ_FACTORY_FOR_SALE_UNIQ } from '../../../uniq-factory/graphql/uniq-factory.query';
import {
  UNIQ_FACTORY_METADATA_FRAGMENTS,
  UNIQ_METADATA_FIELDS,
  UNIQ_METADATA_FRAGMENTS,
} from '../../shared/queries/uniq-metadata-shared.query';
import { ACCOUNT, PURCHASE_UNIQ_FACTORY, REVENUE } from '../../shared/queries/uniq-shared.query';

export const INVENTORY_LIST_QUERY = gql`
  query($pagination: PaginationInput) {
    uniq {
      inventory(pagination: $pagination) {
        data {
          id
          blockId
          onChainId
          serialNumber
          owner ${ACCOUNT}
          status
          factory ${UNIQ_FACTORY_FOR_SALE_UNIQ}
          mintDate
          tradingPeriod {
            startDate
            endDate
            duration
            resaleAvailability {
              resaleDate
              isLocked
              duration
              durationRate
              revenue ${REVENUE}
            }
          }
          transferPeriod {
            mintDate
            startDate
            endDate
            duration
            transferAvailability {
              transferDate
              isLocked
              duration
              durationRate
            }
          }
          saleInfo {
            onSaleDate
            price ${REVENUE}
          }
          metadata ${UNIQ_METADATA_FIELDS}
          blockchainLineage {
            explorerUri
          }
        }
        totalCount
        pagination {
          limit
          skip
        }
      }
    }
  }
  ${UNIQ_METADATA_FRAGMENTS}
  ${UNIQ_FACTORY_METADATA_FRAGMENTS}
`;

export const UNIQ_FOR_SALE_FEE_QUERY = gql`
  query($id: EntityOnChainId!, $price: ResellPriceInput) {
    uniq {
      find(id: $id) {
        id
        tradingPeriod {
            resaleAvailability {
            revenue(price: $price) ${REVENUE}
          }
        }
        transferPeriod {
            mintDate
            startDate
            endDate
            duration
            transferAvailability {
              transferDate
              isLocked
              duration
              durationRate
            }
          }
      }
    }
  }
`;

export const INVENTORY_LIST_FILTER_QUERY = gql`
  query ($filter: InventoryFilterInput) {
    uniq {
      inventory(filter: $filter) {
        data {
          factory {
            onChainId
          }
        }
      }
    }
  }
`;

export const GET_FULL_UNIQ_QUERY = gql`
  query uniq($id: EntityOnChainId!) {
    uniq {
      find(id: $id) {
        id
        serialNumber
        onChainId
        blockId
        correlationId
        owner ${ACCOUNT}
        status
        factory ${UNIQ_FACTORY}
        mintDate
        tradingPeriod {
          startDate
          endDate
          duration
          resaleAvailability {
            resaleDate
            isLocked
            duration
            durationRate
            revenue ${REVENUE}
          }
        }
        transferPeriod {
          mintDate
          startDate
          endDate
          duration
          transferAvailability {
            transferDate
            isLocked
            duration
            durationRate
          }
        }
        saleInfo {
          onSaleDate
          price ${REVENUE}
        }
        metadata ${UNIQ_METADATA_FIELDS}
        blockchainLineage {
          explorerUri
        }
      }
    }
  }
  ${UNIQ_METADATA_FRAGMENTS}
  ${UNIQ_FACTORY_METADATA_FRAGMENTS}
`;

export const GET_UNIQ_QUERY = gql`
  query uniq($id: EntityOnChainId!, $currencyCodes: [String!]) {
    uniq {
      find(id: $id) {
        id
        owner {
          blockchainId
        }
        factory ${PURCHASE_UNIQ_FACTORY}
        saleInfo {
          price {
            amount
            currency {
              code
              symbol
            }
          }
        }
        tradingPeriod {
          endDate
          resaleAvailability {
            isLocked
          }
        }
        transferPeriod {
          endDate
          transferAvailability {
            isLocked
          }
        }
        metadata ${UNIQ_METADATA_FIELDS}
      }
    }
  }
  ${UNIQ_METADATA_FRAGMENTS}
  ${UNIQ_FACTORY_METADATA_FRAGMENTS}
`;

export const GET_UNIQ_FOR_SALE_QUERY = gql`
  query($id: EntityOnChainId!, $price: ResellPriceInput) {
    uniq {
      find(id: $id) {
        id
        owner ${ACCOUNT}
        factory {
          name
          subName
          type
          medias {
            square {
              uri
            }
          }
          creator ${ACCOUNT}
        }
        metadata ${UNIQ_METADATA_FIELDS}
        tradingPeriod {
          mintDate
          startDate
          endDate
          duration
          resaleAvailability {
            revenue(price: $price) ${REVENUE}
          }
        }
      }
    }
  }
  ${UNIQ_METADATA_FRAGMENTS}
`;

export const REPORT_UNIQ_FACTORY = gql`
  mutation uniqFactoryReport(
    $uniqFactoryId: EntityOnOrOffChainId!
    $feedback: UniqFactoryFeedbackInput!
    $uniqFactoryMetadataId: EntityId!
  ) {
    uniqFactoryReport(
      uniqFactoryId: $uniqFactoryId
      feedback: $feedback
      uniqFactoryMetadataId: $uniqFactoryMetadataId
    ) {
      correlationId
    }
  }
`;
