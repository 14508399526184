import Big, { RoundingMode } from 'big.js';

import { ExchangeRate } from '../models/exchange/interfaces/exchange-rate.interface';
import { ConversationRateType } from '../modules/game/helpers/helper.enums';

export class ExchangeRateCalculator {
  /**
   * @param {number} amount
   * @param {ConversationRateType} priceType
   * @param {ExchangeRate} exchangeRate
   * @returns {number}
   * @memberof ExchangeGqlService
   */
  public static convertPrice(amount: number, priceType: ConversationRateType, exchangeRate: ExchangeRate): number {
    let price: Big;
    if (ConversationRateType.fromUosToFiat === priceType) {
      price = Big(amount).div(exchangeRate.rate);
    } else if (ConversationRateType.fromFiatToUos === priceType) {
      price = Big(amount).mul(exchangeRate.rate);
    } else {
      price = Big(amount);
    }
    return this.roundPrice(+price);
  }

  private static roundPrice(price: number): number {
    return +Big(price).round(2, RoundingMode.RoundUp);
  }
}
