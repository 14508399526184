import { Inject, Pipe, PipeTransform } from '@angular/core';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

const IMAGE_SIZE = /\/\d+x\d+\//;
/**
 * Resize images hosted in "img.ultra.io" by changing the size in the image URL.
 * Using an invalid size will result in the image not displaying.
 * Valid sizes:
 *    1920x1080
 *    1720x960
 *    720x400
 *    350x190
 *    260x150
 *    800x600
 *    464x180
 *    206x80
 *    60x60
 *    40x40
 *    30x30
 *    360x480
 *    120x160
 *    68x90
 * ex.
 *    imageUrl = https://img.ultra.io/1920x1080/632476122ed6e6d2a7a2a2de/6328ddde4a361e2040590768-1663622622408/original-images/1920x1080-2.png
 *    imageUrl | resizeImage:260:150
 *    // result: https://img.ultra.io/260x150/632476122ed6e6d2a7a2a2de/6328ddde4a361e2040590768-1663622622408/original-images/1920x1080-2.png;
 *
 */
@Pipe({ name: 'resizeImage' })
export class ImageResizePipe implements PipeTransform {
  constructor(@Inject(APP_CONFIG) private env: IEnvironment) {}

  transform(imgUrl: string, width: number, height: number): string {
    if (!this.isValidHost(imgUrl)) {
      return imgUrl;
    }
    return imgUrl.replace(IMAGE_SIZE, `/${width}x${height}/`);
  }

  private isValidHost(value: string): boolean {
    try {
      const imgUrl = new URL(value);
      const gameImagesUrl = new URL(this.env.gameImagesUrl);
      return imgUrl.host === gameImagesUrl.host;
    } catch {
      return false;
    }
  }
}
