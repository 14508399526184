import { TargetVersion } from '../game/enums/target-version.enum';
import { OrderDirection } from '../game/interfaces/order-direction.enum';

import { Pagination } from './pagination.interface';

export interface IPaginationFilters {
  page?: number;
  filter?: IFilters;
  pagination?: Pagination;
}

export interface IFilters {
  search?: string;
  orderBy?: string;
  orderDirection?: OrderDirection;
  tags?: string[];
  categories?: string[];
  features?: string[];
  playingModes?: string[];
  metascore?: IMetascore;
  publisherIds?: string[];
  targetVersion?: TargetVersion;
  withFiltersCount?: boolean;
  specialOffers?: boolean;
}

export interface IPublisherFilters extends IFilters {
  publisherId?: string;
}

export interface IMetascore {
  metascoreMin: number;
  metascoreMax: number;
}

export enum OrderBy {
  RELEVANCE = 'RELEVANCE',
  RELEASE_DATE = 'RELEASE_DATE',
  NAME = 'NAME',
}
