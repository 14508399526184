<div
  class="item d-flex cursor-pointer justify-content-center"
  [ngClass]="{
    active: dimension.active
  }"
  (click)="setDimension()"
>
  <div class="item__icon-container d-flex flex-column justify-content-center">
    <div class="icon">
      <a
        class="btn position-relative"
        ultraAutotestAttribute="item-dimension-link"
        [ngStyle]="{
          'background-image': 'url(' + dimension.icon + ') '
        }"
      >
      </a>
    </div>
  </div>
</div>
