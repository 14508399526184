import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { IUniqAvailability, IUniqTradingPeriod } from '../../../../../../models';
import { IdCardActionsDisplayHelper } from '../../../../../../modules/id-card-content/id-card-actions/helpers/id-card-actions-display.helper';
import {
  ActionDisplayState,
  IdCardAction,
} from '../../../../../../modules/id-card-content/id-card-actions/id-card-actions.model';

@Component({
  selector: 'ultra-actions-locked-status',
  templateUrl: './actions-locked-status.component.html',
  styleUrls: ['./actions-locked-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  standalone: true,
})
export class ActionsLockedStatusComponent implements OnChanges {
  @Input() period: IUniqTradingPeriod;
  @Input() availability: IUniqAvailability;
  @Input() action: IdCardAction;
  @Input() size: 'lg' | 'sm' | '' = 'sm';

  state: ActionDisplayState;
  lockedStatus: 'short' | 'long' | 'very long';

  readonly actions = IdCardAction;

  ngOnChanges(): void {
    this.state = IdCardActionsDisplayHelper.getState(this.period, this.availability);
    this.lockedStatus = this.getLockedStatus(this.state.duration.locked.day);
  }

  private getLockedStatus(duration: number): 'short' | 'long' | 'very long' {
    if (duration <= 15) {
      return 'short';
    }
    if (duration > 15 && duration <= 90) {
      return 'long';
    }
    if (duration > 90) {
      return 'very long';
    }
  }
}
