import { gql } from 'apollo-angular';

import { PAGINATION_GQL } from '@ultra/core/graphql';

import {
  COMPANY_DATA,
  DATA_FOR_CAROUSEL_GQL,
  GAME_INFO_GQL,
  GAME_VERSION_GQL,
  GDC_IMAGE_GQL,
  IMAGE_GQL,
  LANGUAGES_GQL,
  PRICES,
  RECOMMENDED_UNIQS,
  SYSTEM_REQUIREMENTS_GQL,
  TOKEN_FACTORY_CONTENT,
  TOKEN_FACTORY_PRICE,
} from '../../shared/queries/shared.query';

export const GAMES_BY_IDS_QUERY = gql`
query gamesByIds($ids: [OID!]!) {
  gamesByIds(ids: $ids) {
    game {
      id
      buyableTokenFactory {
        id
        prices ${TOKEN_FACTORY_PRICE}
      }
      tokenFactories {
        freeToPlay
      }
      liveGame {
        title
        description
        descriptionShort
        editorName
        multimedia {
          largeHeroImage {
            name
            images {
              width
              height
              url
            }
          }
          imageGalleryList {
            name
            images {
              width
              height
              url
            }
          }
          videosPreviews {
            videoId
            provider
            duration
            image {
              name
              images {
                width
                height
                url
              }
            }
          }
        }
        systemsRequirements {
          osName
        }
      }
    }
  }
}`;

export const WISHLIST_GAMES_QUERY = gql`
  query currentUserGameAccount {
    currentUserGameAccount {
      id,
      gamesWishlist {
        ${DATA_FOR_CAROUSEL_GQL}
      }
    }
  }
`;

export const WISHLIST_GAMES_QUERY_IDS = gql`
  query currentUserGameAccount {
    currentUserGameAccount {
      gamesWishlist {
        id
      }
    }
  }
`;

export const OWN_GAMES_IDS_QUERY = gql`
  query currentUserGameAccount {
    currentUserGameAccount {
      ownGames {
        game {
          id
        }
      }
    }
  }
`;

export const GAMES_BY_CATEGORY_QUERY = gql`
query gameByCategory($category: String!) {
  gameByCategory(category: $category) {
    ${GAME_INFO_GQL}
  }
}`;

export const FULL_GAMES_DATA_QUERY = gql`
query professionalGames($filter: ProfessionalFilterInput!, $pagination: PaginationInput) {
  professionalGames(filter: $filter, pagination: $pagination) {
    nodes {
      id
      blockchainId
      developmentGame {
        title
        releaseDate {
          year
          month
          day
        }
        developerName
        editorName
        lastUpdate {
          date
        }
        multimedia {
          imageGalleryList ${IMAGE_GQL}
          boxArtImage ${IMAGE_GQL}
        }
      }
    }
    ${PAGINATION_GQL}
  }
}`;

export const SIDENAV_GAMES_DATA = gql`
query professionalGames($filter: ProfessionalFilterInput!, $pagination: PaginationInput) {
  professionalGames(filter: $filter, pagination: $pagination) {
    nodes {
      id
      blockchainId
      storePageStatus
      developmentGame {
        title
        multimedia {
          boxArtImage ${IMAGE_GQL}
        }
      }
    }
    ${PAGINATION_GQL}
  }
}`;

export const FULL_GAME_QUERY = gql`
query professionalGame($id: ID!) {
  professionalGame(id: $id) {
    id
    storePageStatus
    lastStorePageStatusEditComment
    blockchainId,
    publisher ${COMPANY_DATA}
    developmentGame ${GAME_VERSION_GQL}
    liveGame ${GAME_VERSION_GQL}
  }
}`;

const GAME_DETAIL_GQL = `{
  title
  description
  descriptionShort
  ${LANGUAGES_GQL}
  categories
  developerName
  editorName
  releaseDate {
    year
    month
    day
  }
  ${SYSTEM_REQUIREMENTS_GQL}
  multimedia {
    largeHeroImage ${IMAGE_GQL}
    boxArtImage ${IMAGE_GQL}
    imageGalleryList  ${IMAGE_GQL}
    videosPreviews {
      videoId
      provider
      duration
      title
      image ${IMAGE_GQL}
    }
  }
  tags
  rating {
    metascore {
      value
      totalNumberOfRatings
    }
    metaCriticsUserScore {
      value
      totalNumberOfRatings
    }
    awards {
      name
      nomination
    }
  }
  links {
    name
    url
  }
  lastUpdate {
    date
    version
  }
  playingModes
  features
  ageRating {
    ageRatingId
    country
    name
    category {
      categoryId
      name
      image
    }
   }
}`;

export const GAME_DETAIL = gql`query professionalGame($id: ID!) {
  professionalGame(id: $id) {
    id
    publisher ${COMPANY_DATA}
    blockchainId
    developmentGame ${GAME_DETAIL_GQL}
  }
}`;

export const AGE_ARTINGS = gql`
  query ageRatings {
    ageRatings {
      nodes {
        id
        country
        name
        categories {
          categoryId
          name
          image
        }
        descriptors {
          descriptorId
          name
          image
        }
      }
    }
  }
`;

export const GAME_VERSIONS = gql`
query professionalGame($id: ID!) {
  professionalGame(id: $id) {
    id
    developmentGame ${GAME_VERSION_GQL}
    liveGame ${GAME_VERSION_GQL}
  }
}
`;

export const LIBRARY_ITEM_COUNT = gql`
  query ($gameFactoryId: ID!) {
    legacyGameMigration {
      libraryItemCount(gameFactoryId: $gameFactoryId) {
        legacy
        tokenized
      }
    }
  }
`;

export const GET_MASTERCENTER_GAME_BY_ID_QUERY = gql`
  query professionalGame($id: ID!) {
    professionalGame(id: $id) {
      id
      lastStorePageEditDate
      storePageStatus
      lastStorePageStatusEditDate
      lastStorePageStatusEditComment
      lastStorePageStatusChangeDate
      liveGame {
        title
        multimedia {
          primaryGameImage ${IMAGE_GQL}
          boxArtImage ${IMAGE_GQL}
        }
      }
      publisher {
        name
      }
    }
  }
`;

export const GET_GAME_MANAGEMENT_LIST_QUERY = gql`
  query professionalGames($filter: ProfessionalFilterInput!, $pagination: PaginationInput) {
    professionalGames(filter: $filter, pagination: $pagination) {
      nodes {
        id
        publisher ${COMPANY_DATA}
        storePageStatus,
        lastStorePageEditDate,
        lastStorePageStatusEditDate,
        liveGame {
          title
          multimedia {
            primaryGameImage ${IMAGE_GQL}
          }
        }
      }
      ${PAGINATION_GQL}
    }
  }
`;

export const GAME_DETAIL_QUERY = gql`
query game($id: OID!) {
  game(id: $id) {
    id
    publisher ${COMPANY_DATA}
    buyableTokenFactory {
      id
      prices ${TOKEN_FACTORY_PRICE}
    }
    tokenFactories {
      freeToPlay
    }
    ownedTokenFactories {
      id
    }
    blockchainId
    liveGame {
      title
      description
      descriptionShort
      ${LANGUAGES_GQL}
      categories
      developerName
      editorName
      releaseDate {
        year
        month
        day
      }
      ${SYSTEM_REQUIREMENTS_GQL}
      multimedia {
        largeHeroImage {
          name
          images {
            width
            height
            url
          }
        }
        boxArtImage {
          name
          images {
            width
            height
            url
          }
        }
        imageGalleryList {
          name
          images {
            width
            height
            url
          }
        }
        videosPreviews {
          videoId
          provider
          duration
          image {
            name
            base {
              width
              height
              url
            }
            images {
              width
              height
              url
            }
          }
        }
      }
      tags
      rating {
        metascore {
          value
          totalNumberOfRatings
        }
        metaCriticsUserScore {
          value
          totalNumberOfRatings
        }
        awards {
          name
          category
        }
      }
      links {
        name
        url
      }
      lastUpdate {
        date
        version
      }
      playingModes
      features
      ageRating {
        ageRatingId
        country
        name
        category {
          categoryId
          name
          image
        }
      }
      recommendedUniqs ${RECOMMENDED_UNIQS}
    }
  }
}`;

export const OWNED_GAME_TOKEN_FACTORIES_QUERY = gql`
  query game($id: OID!) {
    game(id: $id) {
      id
      ownedTokenFactories {
        id
      }
    }
  }
`;

export const CART_GAMES_QUERY = gql`
query gamesByIds($ids: [OID!]!) {
  gamesByIds(ids: $ids) {
    game {
      id
      publisher ${COMPANY_DATA}
      tokenFactories {
        id
        blockchainId
        prices ${PRICES}
        livePrices ${TOKEN_FACTORY_PRICE}
        freeToPlay
        publishOrder
        description
        name
        image ${GDC_IMAGE_GQL}
        multimedia {
          backGroundImage ${GDC_IMAGE_GQL}
        }
        contentType ${TOKEN_FACTORY_CONTENT}
      }
      liveGame {
        title
        multimedia {
          boxArtImage {
            name
            images {
              url
            }
          }
        }
      }
    }
  }
}`;
