import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IQuantityAvailability } from '../../../../models/uniq/interfaces/uniq.interface';

@Component({
  selector: 'ultra-id-card-quantity-supply',
  templateUrl: './id-card-quantity-supply.component.html',
  styleUrls: ['./id-card-quantity-supply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardQuantitySupplyComponent {
  @Input() quantityAvailability: IQuantityAvailability;

  @Input() quantityStatusColor: string;
}
