import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Paginatable, Pagination } from '../../models/base-interfaces';
import { IRevenue } from '../../models/token-factory';
import { IUniq } from '../../models/uniq';
import { OwnUniqState } from '../../stores/ownUniq';
import * as OwnUniqActions from '../../stores/ownUniq/ownUniq.actions';

import { UniqGqlService } from './uniq-gql.service';
import { transformNullUniqsMetadataToProcessing } from './uniq-metadata-transform-helper';

@Injectable({
  providedIn: 'root',
})
export class UniqForSaleFacadeService {
  public uniqList$: Observable<IUniq[]>;
  public totalCount$: Observable<number>;

  constructor(
    private uniqGqlService: UniqGqlService,
    private store: Store,
  ) {
    this.uniqList$ = this.store.select(OwnUniqState.getUniqList);
    this.totalCount$ = this.store.select(OwnUniqState.getTotalCount);
  }

  getList$(pagination: Pagination): Observable<Paginatable<IUniq[]>> {
    return this.uniqGqlService.getUniqInventoryList$(pagination).pipe(
      tap((paginatableUniqs) => {
        paginatableUniqs.data = transformNullUniqsMetadataToProcessing(paginatableUniqs.data);
        this.store.dispatch(new OwnUniqActions.SetOwnUniqs(paginatableUniqs));
      }),
    );
  }

  public getRevenue(id: string, price: any): Observable<IRevenue> {
    return this.uniqGqlService.getUniqForSaleFee(id, price).pipe(
      map((resp) => {
        return resp.tradability.tradingPeriod.resaleAvailability.revenue;
      }),
    );
  }
}
