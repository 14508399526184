@if (displayGaugeState) {
  <ultra-circle-progress
    [isLocked]="displayState?.isLocked"
    [progress]="availability?.durationRate || 0"
    [progressColor]="displayState?.color">
    <div class="d-flex flex-column">
      @if (displayState?.isLocked) {
        <span class="f-size-12 text-center text-white-alpha-5" i18n>Locked for</span>
        <span class="f-size-28 lh-28 text-center" [ngStyle]="{ color: displayState?.color }">{{
          displayHelper.getFormattedDurationAmount(displayState.duration.locked, displayState.lockedDurationUnit)
        }}</span>
        <span class="f-size-12 text-white-alpha-5 text-center" i18n>{{
          displayHelper.getFormattedDurationUnit(displayState.duration.locked, displayState.lockedDurationUnit)
        }}</span>
      } @else {
        <span class="f-size-28 lh-28 text-center" [ngStyle]="{ color: displayState?.color }">{{
          displayHelper.getFormattedDurationAmount(displayState.duration.available, displayState.availableDurationUnit)
        }}</span>
        <span class="f-size-12 text-white-alpha-5 text-center" i18n
          >{{
            displayHelper.getFormattedDurationUnit(displayState.duration.available, displayState.availableDurationUnit)
          }}
          left</span
        >
      }
    </div>
  </ultra-circle-progress>
} @else {
  <div
    ultraAutotestAttribute="actions-end-state"
    [ngClass]="{
      'actions-gauge-state-able': displayState.status === actionStatuses.ABLE,
      'actions-gauge-state-unable':
        displayState.status === actionStatuses.UNABLE ||
        displayState.status === actionStatuses.PENDING ||
        displayState.status === actionStatuses.DONE
    }"></div>
}
