import { gql } from 'apollo-angular';

export interface GameFactoryFilterInput {
  ids: string[];
  publisherId: string;
  onChainIds: string[];
}

export const GAME_PUBLISHED_GAME_FACTORIES = gql`
  query gamePublishedGameFactories($filter: GameFactoryFilterInput!, $pagination: PaginationInput) {
    gamePublishedGameFactories(filter: $filter, pagination: $pagination) {
      data {
        content {
          gameContents {
            gameId
          }
        }
        id
        onChainId
      }
      totalCount
    }
  }
`;
