import { Observable } from 'rxjs';

export interface IDownloadManager {
  start(queueId: string, queue: Queue): Observable<Job>;
  attachToQueue(queueId: string, queue: Queue): Observable<Job>;
  initQueue(queueId: string): Observable<Queue>;
  getQueue(queueId: string): Observable<Queue>;
  bulk(queueId: string, payload: DownloadPayload[]): void;
  add(queueId: string, payload: DownloadPayload): void;
}

export type DownloadPayloadAction = 'install' | 'uninstall' | 'repair' | 'update' | 'failed';

export enum JobStatus {
  IDLE = 'idle',
  ACTIVE = 'active',
  FINISHED = 'finished',
  FAILED = 'failed',
}

export interface DownloadPayload {
  repositoryId: string;
  branch: string;
  parentRepositoryId: string;
  action: DownloadPayloadAction;
  downloadPath: string;
}

export interface Job {
  jobId: string;
  jobIndex: number;
  queueId: string;
  status: JobStatus;
  payload: DownloadPayload;
  data: DownloadResponseData;
}

/**
 * Code list:
 * 961 - Timeout on I/O. On multiple downloads (+3) we usually get this error. We just ignore it.
 */
export interface DownloadResponseError {
  code: number;
  errorCount?: number;
}

export interface DownloadResponseData {
  progress: number;
  downloadSpeed: number;
  error?: DownloadResponseError;
}

export interface DownloadResponse {
  job: Job;
}

export interface Queue {
  queueId: string;
  status: 'idle' | 'active' | 'finished' | 'failed';
  length: number;
  jobs: Job[];
}
