export enum TokenFOrderStatus {
  CREATED = 'created',
  SUBMITTED = 'submitted',
  DELIVERY_COMPLETED = 'delivery_completed',
  PAYMENT_INCONSISTENT = 'payment_inconsistent',
  CANCELED_BY_SYSTEM = 'canceled_by_system',
  CANCELED_BY_USER = 'canceled_by_user',
  DELIVERY_FAILED = 'delivery_failed',
}

export interface ITokenFOrder {
  id: string;
  status: TokenFOrderStatus;
}
