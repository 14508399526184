import { gql } from 'apollo-angular';

export const EXCHANGE_RATE_GQL = `
  {
    currencyFrom,
    currencyTo,
    rate
  }
`;

export const EXCHANGE_RATE_QUERY = gql`
  query getExchangeRate($to: String!, $from: String!) {
    getExchangeRate(to: $to, from: $from) ${EXCHANGE_RATE_GQL}
  }
`;
