import { CurrencyType, UserCurrency } from '@ultra/core/models';

export class CurrencyHelper {
  // Todo: remove when https://ultraio.atlassian.net/browse/GP-10004 is done on the back-end
  static readonly supportedFiatCurrencies: CurrencyType[] = [
    CurrencyType.USD,
    CurrencyType.EUR,
    CurrencyType.GBP,
    CurrencyType.CAD,
    CurrencyType.AUD,
  ];
  static readonly fallbackCurrency: UserCurrency = { countryCurrency: CurrencyType.USD, currencySymbol: '$' };

  static getUserCurrency(userCurrency: UserCurrency): UserCurrency {
    return CurrencyHelper.supportedFiatCurrencies.includes(userCurrency.countryCurrency)
      ? userCurrency
      : CurrencyHelper.fallbackCurrency;
  }
}
