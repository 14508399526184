import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../..';
import { VideoDirective } from '../video/video.directive';

import { MediaVideoPlayerComponent } from './media-video-player.component';

@NgModule({
  imports: [CommonModule, VideoDirective, AutotestAttributeDirective],
  declarations: [MediaVideoPlayerComponent],
  exports: [MediaVideoPlayerComponent],
})
export class MediaVideoPlayerModule {}
