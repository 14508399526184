import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { RegExpService } from './reg-exp.service';

export class UltraValidators extends Validators {
  constructor() {
    super();
  }

  static checkOneSpecialSymbol(control: AbstractControl): { [key: string]: boolean } | null {
    if (null !== control.value && control.value.length > 0) {
      return !RegExpService.oneSpecial.test(control.value) ? { oneSpecial: true } : null;
    }

    return null;
  }

  static checkOneDecimalSymbol(control: AbstractControl): { [key: string]: boolean } | null {
    if (null !== control.value && control.value.length > 0) {
      return !RegExpService.oneDecimal.test(control.value) ? { oneDecimal: true } : null;
    }

    return null;
  }

  static checkOneCapitalSymbol(control: AbstractControl): { [key: string]: boolean } | null {
    if (null !== control.value && control.value.length > 0) {
      return !RegExpService.oneCapital.test(control.value) ? { oneCapital: true } : null;
    }

    return null;
  }

  static checkOneLowerCaseLetter(control: AbstractControl): { [key: string]: boolean } | null {
    if (null !== control.value && control.value.length > 0) {
      return !RegExpService.oneLowerCaseLetter.test(control.value) ? { oneLowerCaseLetter: true } : null;
    }

    return null;
  }

  static checkRepeatedCharacter(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value?.length > 0) {
      return RegExpService.repeatedCharacter.test(control.value) ? { repeatedCharacter: true } : null;
    }

    return null;
  }

  /**
   * Check if there is a sequence like "123", "789", "abc" or "XYZ"
   * @param control
   */
  static checkSequentialCharacter(control: AbstractControl): { [key: string]: boolean } | null {
    const dic = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const chars: string[] = control.value.split('');
    const charSequence = [];
    for (const char of chars) {
      charSequence.push(char);
      if (charSequence.length >= 3) {
        if (dic.includes(charSequence.join(''))) {
          return { sequentialCharacter: true };
        } else {
          charSequence.splice(0, 1);
        }
      }
    }

    return null;
  }

  static checkPasswordLength(control: AbstractControl): { [key: string]: boolean } | null {
    const length = control.value?.length;
    if (length < 8 || length > 64) {
      return { invalidPasswordLength: true };
    }

    return null;
  }

  static checkPassword(control: AbstractControl): { [key: string]: boolean } | null {
    return UltraValidators.checkingPassword(control, 'patternPassword');
  }

  static checkSecurityPassword(control: AbstractControl): { [key: string]: boolean } | null {
    return UltraValidators.checkingPassword(control, 'patternSecurityPassword');
  }

  static passwordMatch(control: AbstractControl): null {
    const password: string = control.get('password').value;
    const confirm: string = control.get('passwordConfirm').value;

    if (null !== confirm && null !== password) {
      password !== confirm
        ? control.get('passwordConfirm').setErrors({ passwordMatch: true })
        : control.get('passwordConfirm').setErrors(null);
    }

    return null;
  }

  /**
   * Test the value using a regular expression that matches valid e-mail addresses.
   *
   * It is based on the Angular Validators.email.
   *
   * The main differences from the Angular version are:
   *  - The domain part requires a dot.
   *  - There must be at least 2 characters after the dot.
   *
   * ex.
   *  Valid email: test@mail.co
   *  Invalid email: test@mail
   */
  static email(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    if (value !== null && value.length > 0) {
      return RegExpService.email.test(value) ? null : { email: true };
    }

    return null;
  }

  static emailMatch(control: AbstractControl): null {
    const email: string = control.get('email').value;
    const confirm: string = control.get('emailConfirm').value;

    if (null !== confirm && null !== email) {
      email !== confirm
        ? control.get('emailConfirm').setErrors({ emailMatch: true })
        : control.get('emailConfirm').setErrors(null);
    }

    return null;
  }

  static minArrLength(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof UntypedFormArray)) {
        return;
      }

      return control.length < min ? { minArrLength: true } : null;
    };
  }

  static minCheckboxArrLength(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof UntypedFormArray)) {
        return;
      }

      return control.controls.filter((con) => con.value).length < min ? { minArrLength: true } : null;
    };
  }

  static filesRepositoryRequired(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    let error = { required: true };

    Object.keys(value).forEach((key) => {
      if (value[key].active && value[key].version) {
        error = null;
      }
    });

    return error;
  }

  static uniqueGameName(list: string[] = []): ValidatorFn | any {
    return (control: AbstractControl) => {
      const value = control.value ? control.value.trim().toLowerCase() : null;
      const notUniqueGameName = list.map((l) => l.trim().toLowerCase()).includes(value);
      return notUniqueGameName ? { uniqueGameName: true } : null;
    };
  }

  static tagMaxLength(length: number): ValidatorFn | any {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value && value.length > length) {
        return { tagMaxLength: true };
      }
      return null;
    };
  }

  static registrationPhoneValidation(control: AbstractControl): { [key: string]: boolean } | null {
    const { mobile, countryDialCode } = control.value;
    return mobile && countryDialCode && (mobile.startsWith(countryDialCode) || mobile.startsWith('+'))
      ? { countryCodeInNumber: true }
      : null;
  }

  static youtubeVideoLink(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (null !== value && value.length > 0) {
      return !RegExpService.youtubeLink.test(value) ? { youtubeVideoLink: true } : null;
    }
    return null;
  }

  static tokenContentGrantendValid(control: AbstractControl) {
    const values = Object.values(control.value);
    if (values.length) {
      const contentValid = values.find((item) => item !== null);
      return contentValid ? null : { contentInvalid: true };
    }
    return { contentInvalid: true };
  }

  static whitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (null !== value && 0 < value.length) {
      const isValid = RegExpService.whiteSpaces.test(value);
      return !isValid ? { invalidWhitespace: true } : null;
    }
    return null;
  }

  static companyPhoneValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (null !== control.value && 0 < control.value.length) {
      const isValid = RegExpService.companyPhone.test(control.value);
      return !isValid ? { invalidNumber: true } : null;
    }
    return null;
  }

  static urlLinkValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (null !== value && 0 < value.length) {
      const isValid = RegExpService.url.test(value);
      return !isValid ? { invalidLink: true } : null;
    }
    return null;
  }

  static imageFormValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && value.invalid) {
      return { invalidFile: true };
    }
    return null;
  }

  static numberWithTwoMaxDecimals(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/^\d*\.?\d{1,2}$/.test(value)) {
      return { twoDecimalAllowed: true };
    }
    return null;
  }

  static numberWithoutDecimal(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/^[\d]*$/.test(value)) {
      return { noDecimal: true };
    }
    return null;
  }

  static zipCode(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.zipCode.test(value)) {
      return { zipCode: true };
    }
    return null;
  }

  static alphaOnly(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.alphaOnly.test(value)) {
      return { alphaOnly: true };
    }
    return null;
  }

  static alphaWithSpacesAndDashes(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.alphaWithSpacesAndDashes.test(value)) {
      return { alphaWithSpacesAndDashes: true };
    }
    return null;
  }

  static alphaSpacesDashesApostrophes(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.alphaSpacesDashesApostrophes.test(value)) {
      return { alphaSpacesDashesApostrophes: true };
    }
    return null;
  }

  static alphaNumericSpacesDashesApostrophesSlashes(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.alphaNumericSpacesDashesApostrophesSlashes.test(value)) {
      return { alphaNumericSpacesDashesApostrophesSlashes: true };
    }
    return null;
  }

  static alphaNumericSpacesDashes(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.alphaNumericSpacesDashes.test(value)) {
      return { alphaNumericSpacesDashes: true };
    }
    return null;
  }

  static alphaWithSpacesAndSlashes(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.alphaWithSpacesAndSlashes.test(value)) {
      return { alphaWithSpacesAndDashes: true };
    }
    return null;
  }

  static startsWithLetters(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    if (value !== null && value.length > 0) {
      return RegExpService.startsWithLetters.test(value) ? null : { startsWithLetters: true };
    }

    return null;
  }

  static lettersOrNumbers(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    if (value !== null && value.length > 0) {
      return RegExpService.lettersOrNumbers.test(value) ? null : { lettersOrNumbers: true };
    }

    return null;
  }

  static numeric(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    if (value == null) {
      return null;
    }

    return RegExpService.numeric.test(value) ? null : { numeric: true };
  }

  static checkNameOnCreditCard(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    if (value !== null && value.length > 0) {
      return !RegExpService.alphaSpacesDashesApostrophesUnderscores.test(value) ||
        !RegExpService.whiteSpaces.test(value)
        ? { nameOnCard: true }
        : null;
    }
    return null;
  }

  private static checkingPassword(control: AbstractControl, errorsName: string): { [key: string]: boolean } | null {
    if (null !== control.value && control.value.length > 0) {
      return !RegExpService.password.test(control.value) ? { [errorsName]: true } : null;
    }

    return null;
  }

  static AsyncWalletAmountValidator(walletBalance: Observable<number>) {
    return (input: UntypedFormControl) => {
      return walletBalance.pipe(
        filter(() => !!input.value),
        take(1),
        map((amount) => {
          return amount < parseFloat(input.value) ? { walletBalance: true } : null;
        })
      );
    };
  }

  static noWhiteSpaceOnly(control: UntypedFormControl) {
    return (control.value || '').trim().length === 0 ? { noWhiteSpaceOnly: true } : null;
  }

  /**
   * Validates that the entered value is an Ethereum address
   * - Starts with the characters 0x
   * - After this, contains 40 hexadecimal characters (a-f, A-F, and 0-9)
   * @param control
   */
  static ethereumAddress(control: UntypedFormControl) {
    const { value } = control;
    if (value !== null && value.length > 0) {
      return RegExpService.ethereumAddress.test(value) ? null : { ethereumAddress: true };
    }
    return null;
  }

  static mongoId(control: UntypedFormControl) {
    const { value } = control;
    if (value !== null && Array.isArray(value) && value.length > 0) {
      const invalidItems = value.filter((item) => {
        item = typeof item === 'string' ? item : item.value;
        return !RegExpService.mongoIdPattern.test(item);
      });
      return invalidItems.length ? { mongoIdError: invalidItems } : null;
    }
    if (value !== null && value.length > 0) {
      return RegExpService.mongoIdPattern.test(value) ? null : { mongoIdError: true };
    }
    return null;
  }

  static onChainId(control: UntypedFormControl) {
    const { value } = control;
    if (value !== null && Array.isArray(value) && value.length > 0) {
      const invalidItems = value.filter((item) => {
        item = typeof item === ('string' || 'number') ? item : item.value;
        return !RegExpService.positiveInteger.test(item) || item > Number.MAX_SAFE_INTEGER;
      });
      return invalidItems.length ? { onChainIdError: invalidItems } : null;
    }
    if (value !== null && value.length > 0) {
      return RegExpService.positiveInteger.test(value) && value < Number.MAX_SAFE_INTEGER
        ? null
        : { onChainIdError: true };
    }
    return null;
  }

  static usernameAlphaNumeric(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !RegExpService.usernameAlphaNumeric.test(value)) {
      return { invalidUsername: true };
    }
    return null;
  }
}
