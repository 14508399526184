import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedPipesModule } from '../../pipes/pipes.module';

/**
 * A component to copy the text given as input to the clipboard
 * and show a tooltip text after the copy.
 *
 * @usage
 * `
 * <ultra-copy-to-clipboard
 *    [text]="https://www.ultra.io"
 *    copyTooltipText="Copy Ultra URL"
 *    i18n-copyTooltipText
 *    copiedTooltipText="URL Copied"
 *    i18n-copiedTooltipText>
 * </ultra-copy-to-clipboard>
 * `
 */
@Component({
  selector: 'ultra-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  imports: [CommonModule, NgbTooltipModule, ClipboardModule, SharedPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CopyToClipboardComponent implements OnInit {
  @ViewChild('copyButton') copyButton: ElementRef;
  @ViewChild('buttonTooltip') tooltip: NgbTooltip;

  /**
   * Text to be copied to the clipboard.
   */
  @Input() text: string;
  /**
   * Label to be shown next to the icon.
   */
  @Input() label: string;
  /**
   * Show label next to the icon.
   */
  @Input() showLabel = true;
  /**
   * Label to be shown next to the icon upon hover.
   */
  @Input() hoverLabel: string;
  /**
   * Show label next to the icon upon hover.
   */
  @Input() showLabelOnHover = false;
  /**
   * Length of the label before showing ellipsis.
   */
  @Input() visibleLength: number;
  /**
   * Show tooltip upon hover.
   */
  @Input() showTooltip = true;
  /**
   * Show the initial tooltip text upon hover before text is copied.
   */
  @Input() showInitialTooltip = true;
  /**
   * Text to be shown on hover.
   */
  @Input() copyTooltipText: string;
  /**
   * Text to be shown after text is copied.
   */
  @Input() copiedTooltipText: string;
  /**
   * `transparent-light` will show a background color.
   */
  @Input() tooltipVariant: 'default' | 'success' = 'default';
  /**
   * `transparent-light` will show a background color.
   */
  @Input() variant: 'transparent' | 'transparent-large' | 'transparent-light' | 'transparent-light-static' | 'simple' =
    'transparent-light';
  /**
   * Icon to be shown.
   */
  @Input() mainIconType = 'icon-copy';
  /**
   * Icon to be shown on hover.
   * Can be different from the main icon.
   */
  @Input() hoverIconType = 'icon-copy';

  /**
   * Placement specifies where the tooltip should be
   * positioned in the order of preference.
   * Accepts an array of strings or a string with space separated values.
   *
   * @see https://ng-bootstrap.github.io/#/guides/positioning#api
   */
  @Input() tooltipPlacement = 'auto';
  @Input() buttonClassNames = '';
  @Input() wrapperClassNames = '';

  public tooltipResetTimerId: ReturnType<typeof setTimeout>;
  public tooltipText: string;
  public isHover = false;
  public isCopied = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.tooltipText = this.copyTooltipText;
  }

  changeHoverState(hover: boolean) {
    this.isHover = hover;

    this.tooltipText = this.copyTooltipText;
    this.isCopied = false;

    if (this.tooltipResetTimerId) {
      clearTimeout(this.tooltipResetTimerId);
    }
  }

  onCopySuccess() {
    this.tooltipText = this.copiedTooltipText;
    this.isCopied = true;
    this.cdr.detectChanges();

    this.tooltipResetTimerId = setTimeout(() => {
      this.tooltipText = this.copyTooltipText;
      this.isCopied = false;
      this.cdr.detectChanges();
    }, 2000);
  }

  get buttonClass(): string {
    switch (this.variant) {
      case 'transparent':
        return 'btn-transparent';
      case 'transparent-large':
        return 'btn-transparent btn-transparent--large';
      case 'transparent-light':
        return 'btn-transparent-light';
      case 'transparent-light-static':
        return 'btn-square btn-transparent-light btn-transparent-light--static';
      case 'simple':
        return 'btn-simple';
      default:
        return '';
    }
  }

  get iconClass(): object {
    return {
      [this.hoverIconType]: this.isHover || this.hoverIconType === this.mainIconType,
      [this.mainIconType]: !this.isHover || this.hoverIconType === this.mainIconType,
    };
  }

  get tooltipClass(): string {
    const tooltipClasses = ['copy-tooltip'];

    switch (this.tooltipVariant) {
      case 'success':
        tooltipClasses.push('tooltip--success');
        break;
      default:
        break;
    }

    if (!this.showInitialTooltip && !this.isCopied) {
      tooltipClasses.push('tooltip--hidden');
    }

    return tooltipClasses.join(' ');
  }
}
