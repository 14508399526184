import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[ultraClickOutside]',
})
export class ClickOutsideDirective {
  private _disabled = false;

  @Input('disabled')
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
  }

  @Output()
  clickOutside: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside && !this._disabled) {
      this.clickOutside.emit(true);
    }
  }
}
