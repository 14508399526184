import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MediaCarouselModule } from '../../../modules/media-carousel/media-carousel.module';
import { SliderComponent, SliderContentDirective, SwiperTemplateDirective } from '../../../modules/slider';
import { SliderTheme } from '../../slider/slider.interface';
import { BackgroundSkeletonComponent } from '../background-skeleton/background-skeleton.component';

@Component({
  selector: 'ultra-gallery-skeleton',
  templateUrl: './gallery-skeleton.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MediaCarouselModule,
    SliderComponent,
    SliderContentDirective,
    SwiperTemplateDirective,
    BackgroundSkeletonComponent,
  ],
})
export class GallerySkeletonComponent {
  @Input() size: 'xs' | 'md' | 'xl' = 'xl';
  @Input() theme: SliderTheme;
  @Input() animatedSkeleton = true;
}
