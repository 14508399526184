import { Component, Input, OnChanges, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

import { FeatureFlag, FeatureFlagService } from '@ultra/core/lib/services/feature-flag';
import { UserFacadeService } from '@ultra/core/stores';

import { IUniq, UniqStatus } from '../../../models/uniq';
import {
  ActionDisplayState,
  ActionStatus,
} from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { BlockchainTransactionBuilder, EosioUniqAction, KnownContract } from '../../../services/authenticator';
import { TransactionAppService } from '../../../services/transaction';
import { UltraValidators } from '../../../services/validators';
import { IdCardActionsDisplayHelper } from '../id-card-actions/helpers/id-card-actions-display.helper';
import {
  IdCardPanelDispatcherService,
  IdCardPropertyTabs,
} from '../id-card-panel-dispatcher/providers/id-card-panel-dispatcher.service';

@Component({
  selector: 'ultra-id-card-transfer',
  templateUrl: './id-card-transfer.component.html',
  styleUrls: ['./id-card-transfer.component.scss'],
})
export class IdCardTransferComponent implements OnChanges {
  @Input()
  uniq: IUniq;

  isOnSale: boolean;
  displayState: ActionDisplayState;
  isTransferableUnrestricted: boolean;

  form: UntypedFormGroup;
  recipientControl: UntypedFormControl;
  memoControl: UntypedFormControl;

  allowUnrestrictedTransfer: boolean;

  readonly actionStatuses = ActionStatus;

  constructor(
    private featureFlagService: FeatureFlagService,
    private userFacadeService: UserFacadeService,
    private transactionAppService: TransactionAppService,
    @Optional() private panelDispatcher: IdCardPanelDispatcherService,
  ) {
    this.allowUnrestrictedTransfer = this.featureFlagService.isEnabled(FeatureFlag.ALLOW_UNRESTRICTED_TRANSFER);
    this.createForm();
  }

  ngOnChanges(): void {
    this.isOnSale = UniqStatus.ON_SALE === this.uniq.status;
    this.displayState = IdCardActionsDisplayHelper.getState(
      this.uniq?.transferPeriod,
      this.uniq?.transferPeriod?.transferAvailability,
    );
    if (this.isOnSale) {
      this.form.disable();
    }
  }

  submitForm() {
    const { recipient, memo } = this.form.value;
    this.userFacadeService.blockchainId$.pipe(take(1)).subscribe((blockchainId) => {
      const transaction = this.buildBlockchainTransaction(blockchainId, recipient, this.uniq.onChainId, memo);
      this.transactionAppService.openTransactionSigning(transaction, { uniqId: this.uniq.id });
    });
  }

  handleTransferabilityQuickAccess(): void {
    if (!this.panelDispatcher) {
      return;
    }
    this.panelDispatcher.next(IdCardPropertyTabs.TRANSFERABILITY);
  }

  handleTransferToUnrestrictedAddress(): void {
    this.isTransferableUnrestricted = true;
  }

  private buildBlockchainTransaction(blockchainId: string, recipient: string, uniqOnChainId: string, memo: string) {
    return new BlockchainTransactionBuilder()
      .contract(KnownContract.EOSIO_UNIQ)
      .action(EosioUniqAction.TRANSFER)
      .authorizationForTransferAction(blockchainId)
      .dataForTransferUniq(blockchainId, recipient, [uniqOnChainId], memo)
      .build();
  }

  private createForm(): void {
    this.recipientControl = new UntypedFormControl(null, [Validators.required, UltraValidators.maxLength(20)]);
    this.memoControl = new UntypedFormControl(null, [UltraValidators.maxLength(256)]);
    this.form = new UntypedFormGroup({
      recipient: this.recipientControl,
      memo: this.memoControl,
    });
  }
}
