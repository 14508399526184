import {
  CdkCell,
  CdkCellDef,
  CdkColumnDef,
  CdkFooterCell,
  CdkFooterCellDef,
  CdkHeaderCell,
  CdkHeaderCellDef,
} from '@angular/cdk/table';
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[ultraCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: UltraCellDefDirective }],
})
export class UltraCellDefDirective extends CdkCellDef {}

@Directive({
  selector: '[ultraHeaderCellDef]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: UltraHeaderCellDefDirective }],
})
export class UltraHeaderCellDefDirective extends CdkHeaderCellDef {}

@Directive({
  selector: '[ultraFooterCellDef]',
  providers: [{ provide: CdkFooterCellDef, useExisting: UltraFooterCellDefDirective }],
})
export class UltraFooterCellDefDirective extends CdkFooterCellDef {}

@Directive({
  selector: '[ultraColumnDef]',
  providers: [
    { provide: CdkColumnDef, useExisting: UltraColumnDefDirective },
    { provide: 'MAT_SORT_HEADER_COLUMN_DEF', useExisting: UltraColumnDefDirective },
  ],
})
export class UltraColumnDefDirective extends CdkColumnDef {
  /** Unique name for this column. */
  @Input('ultraColumnDef')
  get name(): string {
    return this._name;
  }
  set name(name: string) {
    this._setNameInput(name);
  }

  /**
   * Add "mat-column-" prefix in addition to "cdk-column-" prefix.
   * In the future, this will only add "mat-column-" and columnCssClassName
   * will change from type string[] to string.
   * @docs-private
   */
  protected _updateColumnCssClassName() {
    super._updateColumnCssClassName();
    this._columnCssClassName?.push(`ultra-column-${this.cssClassFriendlyName}`);
  }
}

@Directive({
  selector: 'ultra-header-cell, th[ultra-header-cell]',
})
export class UltraHeaderCellDirective extends CdkHeaderCell {
  @HostBinding('class.ultra-header-cell') headerCell = true;
  @HostBinding('role.columnheader') columnheader = true;
}

@Directive({
  selector: 'ultra-footer-cell, td[ultra-footer-cell]',
})
export class UltraFooterCellDirective extends CdkFooterCell {
  @HostBinding('class.ultra-footer-cell') footerCell = true;
  @HostBinding('role.gridcell') gridcell = true;
}

@Directive({
  selector: 'ultra-cell, td[ultra-cell]',
})
export class UltraCellDirective extends CdkCell {
  @HostBinding('class.ultra-cell') cell = true;
  @HostBinding('role.gridcell') gridcell = true;
}
