import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';

import { AutotestAttributeDirective } from '../../../../autotests/autotest-attribute.directive';

let nextUniqueId = 0;

@Component({
  selector: 'ultra-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgbTooltipModule, AutotestAttributeDirective],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id = `ultra-checkbox-${nextUniqueId++}`;
  @Input() label: string;
  @Input() size: 'sm' | 'm' | 'lg' = 'lg';
  @Input() cssClassIcon: string;
  @Input() cssClass = '';
  @Input() readonly: boolean;
  @Input() dataId: string;
  @Input() tooltip: string;
  @ViewChild('input', { static: true })
  _inputElement: ElementRef<HTMLInputElement>;

  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  indeterminateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChange: any = noop;
  public onTouched: any = noop;

  private _checked = false;
  private _disabled = false;
  private _indeterminate = false;

  constructor(
    @Optional()
    @Self()
    private ngControl: NgControl,
    private cd: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get checked() {
    return this._checked;
  }

  set checked(value: boolean) {
    if (value !== this.checked) {
      this._checked = value;
      this.cd.markForCheck();
    }
  }

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(value: any) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this.disabled) {
      this._disabled = newValue;
      this.cd.markForCheck();
    }
  }

  @Input()
  get indeterminate() {
    return this._indeterminate;
  }

  set indeterminate(value: boolean) {
    const changed = value !== this._indeterminate;
    this._indeterminate = coerceBooleanProperty(value);
    if (changed) {
      this.indeterminateChange.emit(value);
      this.cd.markForCheck();
    }
  }

  public onInputClick(event) {
    event.stopPropagation();

    if (!this.disabled) {
      if (this.indeterminate) {
        this._indeterminate = false;
        this.indeterminateChange.emit(this._indeterminate);
      }
      this.toggle();
      this.emitChangeEvent();
    }
  }

  public toggle(): void {
    this.checked = !this.checked;
  }

  public writeValue(value: any): void {
    this.checked = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private emitChangeEvent() {
    this.onChange(this.checked);
    this.valueChange.emit(this.checked);
  }
}
