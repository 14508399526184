import { IImageData } from '../../../models/multimedia/interfaces';
import { ITokenFactory } from '../../../models/token-factory/interfaces/token-factory.interface';
import { IMedia } from '../../../models/uniq/interfaces/uniq.interface';

import { IdCardAdapter, IdCardEntity } from './id-card.adapter';

export class TokenFactoryIdCardAdapter extends IdCardAdapter<ITokenFactory> {
  transform(data: ITokenFactory): IdCardEntity {
    return {
      type: 'tokenfactory',
      blockchainId: data.blockchainId,
      info: {
        title: data.name,
        description: data.description,
      },
      content: {
        contentType: data.contentType.name,
        contentTypeCode: data.contentType.code,
        languages: data.languages,
        tokenContent: data.content,
      },
      medias: {
        image: this.imageDataToMedia(data.image),
        backGroundImage: this.imageDataToMedia(data.multimedia?.backGroundImage),
        imageGalleryList: data?.multimedia?.imageGalleryList?.length
          ? data.multimedia.imageGalleryList.map((item) => this.imageDataToMedia(item))
          : [],
      },
      creator: null,
      status: data.status,
      tradability: null,
      geofencing: data.geofencing,
      tradingPeriod: null,
      isTokenized: false,
      hidden: false,
    };
  }

  private imageDataToMedia(data: IImageData): IMedia {
    return {
      uri: data?.images[0]?.url,
      contentType: 'IMAGE',
    };
  }
}
