import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { IUserRoles, UserRoleName } from '../permission/interfaces/user-roles.interface';
import { PermissionService } from '../permission/permission.service';

@Injectable()
export class RolesGroupGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private permissionService: PermissionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.permissionService.userRoles$.pipe(
      filter((permissions: IUserRoles) => !!permissions),
      take(1),
      map((permissions: IUserRoles) => {
        if (this.isGroupAllowed(route.data?.rolesGroup, permissions)) {
          return true;
        }

        this.router.navigate(['/403']);

        return false;
      }),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.permissionService.userRoles$.pipe(
      filter((permissions: IUserRoles) => !!permissions),
      take(1),
      map((permissions: IUserRoles) => {
        if (this.isGroupAllowed(route.data?.rolesGroup, permissions)) {
          return true;
        }

        this.router.navigate(['/403']);

        return false;
      }),
    );
  }

  private isGroupAllowed(rolesGroup: UserRoleName[], permissions: IUserRoles): boolean {
    return rolesGroup?.some((role: UserRoleName) => permissions[role] === true);
  }
}
