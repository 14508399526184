<div class="position-relative d-flex justify-content-center align-content-center" [class.mt-4]="isLocked">
  <div class="inner-content">
    <ng-content></ng-content>
  </div>
  @if (isLocked) {
    <div class="lock-wrap">
      <i class="icon icon-lock" [style.background]="progressColor"></i>
    </div>
  }
  <round-progress
    [ngClass]="{ 'locked-progress': isLocked }"
    [radius]="radius"
    [current]="progress"
    [max]="100"
    [stroke]="5"
    [rounded]="true"
    [background]="background"
    [color]="progressColor"
    [clockwise]="false"
    [responsive]="false"></round-progress>
</div>
