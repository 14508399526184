import { IScore } from '../interfaces/rating.interface';

export class Score {
  private totalNumberOfRatings: number;

  value: number;

  constructor(score: IScore) {
    Object.assign(this, score);
  }

  get formattedTotalNumberOfRatings(): string {
    const initialValue = this.totalNumberOfRatings;
    const thousands = this.floorNumber(initialValue / 1000);
    const rest = initialValue % 1000;
    const hundreds = this.floorNumber(rest / 100);

    if (thousands >= 1) {
      return hundreds >= 1 ? `${thousands}.${hundreds}K` : `${thousands}K`;
    }

    return initialValue.toString();
  }

  get decimalValue(): number {
    const value = this.value;
    const metascriticAccuracy = 10;
    const integerPart = this.floorNumber(value);
    const decimalPart = value - integerPart;

    return this.roundNumberTo(decimalPart, metascriticAccuracy) * metascriticAccuracy;
  }

  get integerValue(): number {
    return this.floorNumber(this.value);
  }

  private floorNumber(value: number): number {
    return Math.floor(value);
  }

  private roundNumberTo(value: number, accuracy: number): number {
    return Math.round(accuracy * value) / accuracy;
  }
}
