import { CurrencyType } from '@ultra/core/models';

import { TokenFOrderStatus } from '../../token-factory/interfaces/order.interface';
import { IOrder, IOrderItems, IOrderPrice, OrderErrorType } from '../interfaces/order.interface';

export class Order implements IOrder {
  id: string;
  hash: string;
  status: TokenFOrderStatus;
  storeCurrency: CurrencyType;
  orderCurrency: CurrencyType;
  storeTotalPricing: IOrderPrice;
  orderTotalPricing: IOrderPrice;
  items?: IOrderItems[];
  error?: OrderErrorType;
  storeCurrencySymbol?: string;
  expirationDate?: Date;

  constructor(data: IOrder) {
    this.id = data.id;
    this.hash = data.hash;
    this.status = data.status;
    this.storeCurrency = data.storeCurrency;
    this.orderCurrency = data.orderCurrency;
    this.storeTotalPricing = data.storeTotalPricing;
    this.orderTotalPricing = data.orderTotalPricing;
    this.items = data.items;
    this.error = data.error;
    this.storeCurrencySymbol = data.storeCurrencySymbol;
    this.expirationDate = new Date(data.expirationDate);
  }
}
