import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { ErrorPage } from '../../interfaces';
import { errorDataByCode } from '../../mocks';

@Component({
  selector: 'ultra-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsComponent {
  errorData: ErrorPage;
  private data: Params;

  constructor(private activatedRoute: ActivatedRoute) {
    this.data = this.activatedRoute.snapshot.data;

    this.setErrorData();
  }

  private setErrorData(): void {
    if (Object.keys(this.data).length) {
      this.errorData = errorDataByCode[this.data.status];
      this.setStatusCodeToErrorData(this.data.status);
    } else {
      const status = this.activatedRoute.snapshot.queryParams.status;
      // set data any server error (5XX)
      if (/^5[0-9]{2}$/.test(status)) {
        this.errorData = errorDataByCode.server_error;
      } else {
        this.errorData = errorDataByCode[status];
      }

      this.setStatusCodeToErrorData(status);
    }
  }

  private setStatusCodeToErrorData(code: string): void {
    if (this.errorData) {
      this.errorData.code = code;
    }
  }
}
