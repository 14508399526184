import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WINDOW } from '../../providers/window.provider';
import { StorageType, UltraosApiService } from '../ultraos/ultraos-api.service';

import { BrowserStorageService } from './browser-storage.service';
import { ClientStorageService } from './client-storage.service';
import { IStorageService } from './interfaces/storage.interface';

const mainStorageServiceFactory = (ultraosApiService: UltraosApiService, window: Window) => {
  return ultraosApiService.globalStoreAccessible()
    ? new ClientStorageService(ultraosApiService)
    : new BrowserStorageService(window.localStorage);
};

@Injectable({
  providedIn: 'root',
  useFactory: mainStorageServiceFactory,
  deps: [UltraosApiService, WINDOW],
})
export abstract class MainStorageService implements IStorageService {
  abstract set(key: string, value: string, type?: StorageType): Observable<void>;
  abstract get(key: string, type?: StorageType): Observable<string>;
  abstract setObject<T>(key: string, value: T, type?: StorageType): Observable<void>;
  abstract getObject<T>(key: string, type?: StorageType): Observable<T>;
  abstract remove(key: string, type?: StorageType): Observable<void>;
}
