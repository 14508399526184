<button
  i18n
  class="btn withdraw ms-1 rounded d-inline-flex d-flex-center text-capitalize"
  ultraAutotestAttribute="withdraw-button"
  (click)="clicked.emit()"
>
  <div class="withdraw-price">
    <ng-content></ng-content>
  </div>
  <div class="withdraw-message">
    Withdraw
    <i class="icon icon-arrow-sm ms-2"></i>
  </div>
</button>
