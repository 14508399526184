export enum KnownContract {
  EOSIO = 'eosio',
  EOSIO_TOKEN = 'eosio.token',
  EOSIO_UNIQ = 'eosio.nft.ft',
}

// actions for contract eosio.token
export enum EosioAction {
  TRANSFER = 'transfer',
  NEW_ACCOUNT = 'newnonebact',
}

// actions for contract eosio.nft.ft
export enum EosioUniqAction {
  BUY = 'buy',
  RESELL = 'resell',
  CANCEL_RESELL = 'cancelresell',
  TRANSFER = 'transfer',
}
