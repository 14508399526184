import { IReleaseDate } from '../game/interfaces/release-date.interface';

import { DatePipeFormat } from './date-types.enum';

/**
 * @param {IReleaseDate} date - date, which should be formatted
 * @return {string} - concatenated string with the existing year, month, day
 */
export function getConcatenatedReleaseDate(date: IReleaseDate): string {
  return date ? [date.year, date.month, date.day].filter(Boolean).join('-') : '';
}

/**
 * @param {IReleaseDate} releaseDate - date, which should be formatted
 * @param {DatePipeFormat} type - date format to display full date
 * @return {DatePipeFormat} - Check the presence of (year, month, day) in concatenated release date and return suitable DatePipeFormat
 */
export function getDatePipeType(releaseDate: IReleaseDate, type: DatePipeFormat): DatePipeFormat {
  const concatenatedReleaseDate = getConcatenatedReleaseDate(releaseDate).split('-');
  if (concatenatedReleaseDate.length === 1) {
    return DatePipeFormat.Year;
  } else if (concatenatedReleaseDate.length === 2) {
    return DatePipeFormat.MonthYear;
  }
  return type;
}
