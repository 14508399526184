import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ResponsiveContentDirective } from './directives/responsive-content.directive';
import { ResponsiveLayoutDirective } from './directives/responsive-layout.directive';

@NgModule({
  imports: [CommonModule, ResponsiveLayoutDirective, ResponsiveContentDirective],
  exports: [ResponsiveLayoutDirective, ResponsiveContentDirective],
})
export class CardAnimationModule {}
