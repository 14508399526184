export enum OSType {
  Windows = 'windows',
  MacOS = 'macos',
  Ubuntu = 'ubuntu',
}

export interface ISystemTab {
  osId: OSType;
  index: number;
}

export interface ISystem {
  name: string;
  id: OSType;
  dataId: string;
  disabled?: boolean;
}
