<div class="d-flex">
  @switch (metadataStatus) {
    @case (metadataStatusEnum.PROCESSING) {
      @if (shortMessage) {
        <ultra-info-message-detail [extraClassName]="shortMessageClassName" [size]="size">
          <div class="{{ messageWrapperClassName }}">
            Synchronization is in progress.
            <a
              href="mailto:support@ultra.io"
              (click)="$event.stopPropagation()"
              class="text-decoration-underline text-ultra-purple-light"
              [class.d-block]="contactLinkOnNewLine">
              <b>Contact support</b>
            </a>
          </div>
        </ultra-info-message-detail>
      } @else {
        <ultra-info-message-detail extraClassName="gap-16" [size]="size">
          <b>Synchronization is in progress.</b>
          <br />
          We&rsquo;re still working on&nbsp;this content, it&nbsp;should be&nbsp;visible soon. If&nbsp;the problem
          persists, please
          <a
            href="mailto:support@ultra.io"
            (click)="$event.stopPropagation()"
            class="text-decoration-underline text-ultra-purple-light">
            <b>contact the support</b>
          </a>
        </ultra-info-message-detail>
      }
    }
    @case (metadataStatusEnum.INVALID) {
      @if (shortMessage) {
        <ultra-info-message-detail [extraClassName]="shortMessageClassName" [size]="size" mode="error">
          <div class="{{ messageWrapperClassName }}">
            Synchronization problem.
            <a
              href="mailto:support@ultra.io"
              (click)="$event.stopPropagation()"
              class="text-decoration-underline text-orange-light"
              [class.d-block]="contactLinkOnNewLine">
              <b>Contact support</b>
            </a>
          </div>
        </ultra-info-message-detail>
      } @else {
        <ultra-info-message-detail extraClassName="gap-16" mode="error" [size]="size">
          <b>Synchronization problem.</b>
          <br />
          Looks like we&nbsp;have some problems trying to&nbsp;display this content. If&nbsp;the problem persists,
          please
          <a
            href="mailto:support@ultra.io"
            (click)="$event.stopPropagation()"
            class="text-decoration-underline text-orange-light">
            <b>contact the support</b>
          </a>
        </ultra-info-message-detail>
      }
    }
    @default {
      @if (shortMessage) {
        <ultra-info-message-detail [extraClassName]="shortMessageClassName" [size]="size" mode="restricted">
          <div class="{{ messageWrapperClassName }}">Restricted content</div>
        </ultra-info-message-detail>
      } @else {
        <ultra-info-message-detail extraClassName="gap-16" [size]="size" mode="restricted">
          <b>Restricted content.</b>
          <br />
          Looks like this content is violating our terms and conditions.
        </ultra-info-message-detail>
      }
    }
  }
  @if (showInfoSeparation) {
    <div class="metadata-information-manager__border"></div>
  }
</div>
