import { NgClass, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../../../../autotests';
import { ExchangeDate, IUniqActionsPeriod, IUniqAvailability } from '../../../../../models';
import { ActionsLockedStatusComponent } from '../../../../../modules/id-card-content/id-card-actions/components/actions-status/actions-locked-status/actions-locked-status.component';
import { IdCardActionsDisplayHelper } from '../../../../../modules/id-card-content/id-card-actions/helpers/id-card-actions-display.helper';
import {
  ActionDisplayState,
  ActionStatus,
  IdCardAction,
} from '../../../../../modules/id-card-content/id-card-actions/id-card-actions.model';

type ActionStatusText = {
  [key in ActionStatus]?: string;
};

@Component({
  selector: 'ultra-actions-status',
  templateUrl: './actions-status.component.html',
  styleUrls: ['./actions-status.component.scss'],
  imports: [NgClass, TitleCasePipe, ActionsLockedStatusComponent, AutotestAttributeDirective, NgbTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ActionsStatusComponent implements OnChanges {
  @Input() period: IUniqActionsPeriod;
  @Input() availability: IUniqAvailability;
  @Input() action: IdCardAction;
  @Input() startDate: ExchangeDate;
  @Input() endDate: ExchangeDate;
  @Input() size: 'lg' | 'sm' | '' = 'sm';
  /**
   * Show the text associated with the status.
   */
  @Input() showExplicit = false;
  @Input() showTooltip = false;
  /**
   * If provided, will override the default text for the status.
   *
   * Note: Added to show `resalable` instead of `tradable` text in game store.
   * Maybe should be removed in the future if we keep one of the two for all the cases.
   */
  @Input() customText: string;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  get status(): 'success' | 'warning' | 'error' | 'neutral' | 'primary' | '' {
    const status =
      this.displayState?.status === this.actions.TILL ? this.displayState?.durationStatus : this.displayState?.status;
    switch (status) {
      case ActionStatus.ABLE:
        return 'success';
      case ActionStatus.UNABLE:
        return 'neutral';
      case 'long':
        return 'primary';
      case 'regular':
        return 'warning';
      case 'short':
        return 'error';
      default:
        return '';
    }
  }

  statusTooltip = '';
  displayState: ActionDisplayState;
  icon: string;
  statusText: ActionStatusText;
  readonly actions = ActionStatus;
  readonly actionStatuses = ActionStatus;

  @HostListener('click')
  onClick(): void {
    this.clicked.next();
  }

  ngOnChanges(): void {
    this.displayState = IdCardActionsDisplayHelper.getState(
      this.period,
      this.availability,
      this.startDate,
      this.endDate,
    );
    this.icon =
      this.displayState?.status === this.actionStatuses.UNABLE
        ? `icon-not-${this.action}-${this.size}`
        : `icon-${this.action}-${this.size}`;

    const verb: string = IdCardActionsDisplayHelper.getActionVerb(this.action);
    this.statusText = {
      able: `${verb}`,
      unable: `Not ${verb}`,
      till: `${verb}`,
      pending: `${this.action === IdCardAction.TRANSFERABILITY ? 'Transfer' : 'Trade'} Locked`,
      done: `Not ${verb}`,
    };

    if (this.showTooltip) {
      this.statusTooltip = this.customText ?? this.statusText[this.displayState?.status];
    }
  }
}
