import { CurrencyType } from '@ultra/core/models';
import { TokenFOrderStatus } from '../../token-factory/interfaces/order.interface';
import { Order } from '../models/order.model';

export const orderMock = new Order({
  id: 'id',
  hash: 'hash',
  status: TokenFOrderStatus.CREATED,
  storeCurrency: CurrencyType.USD,
  orderCurrency: CurrencyType.USD,
  storeCurrencySymbol: '$',
  expirationDate: new Date('2021-01-27T07:50:06.832+00:00'),
  storeTotalPricing: {
    grossPrice: 10,
    netPrice: 12,
    taxes: 2,
  },
  orderTotalPricing: {
    grossPrice: 10,
    netPrice: 12,
    taxes: 2,
  },
  items: [
    {
      id: 'item-id',
      product: {
        id: 'prod-id',
        name: 'name',
      },
    },
  ],
  error: null,
});
