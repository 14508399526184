import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { AuthService } from '@ultra/core/auth';
import { getGraphQLErrorCode, getGraphQLErrorMessage } from '@ultra/core/helpers';
import { MixpanelEvent, MixpanelService } from '@ultra/core/services';

import { GameService } from '../../game/services/game.service';
import { Game, IUserGamesWishlistId } from '../../models/game';
import { ToastService } from '../../modules';

@Injectable({ providedIn: 'root' })
export class GameWishListService {
  private wishlist: Game[] = [];
  private globalChanges: Subject<Game[]> = new Subject();
  private partialChanges: Subject<{ id: string; include: boolean }> = new Subject();

  favoritePendingStore$: BehaviorSubject<Record<string, boolean>> = new BehaviorSubject({});
  private authService = inject(AuthService);
  private toastService = inject(ToastService);

  constructor(
    private gameService: GameService,
    private mixpanelService: MixpanelService,
  ) {}

  /**
   * used in wishlist carousel
   * @returns {Observable<Game[]>}
   */
  listenWishlistGlobalChanges$(): Observable<Game[]> {
    return this.globalChanges.asObservable();
  }

  /**
   * used in carousel items and preview section on detail page
   * @returns {Observable<{id: string; include: boolean}>}
   */
  listenWishlistPartialChanges$(): Observable<{ id: string; include: boolean }> {
    return this.partialChanges.asObservable();
  }

  getUserWishList$(force = false): Observable<Game[]> {
    if (this.wishlist && force) {
      return of(this.wishlist);
    }
    return this.gameService.strategy.getUserWishList$().pipe(tap((data: Game[]) => (this.wishlist = data)));
  }

  updateUserWishlist$(game: Game, include = false): Observable<Game[]> {
    if (!this.authService.isAuthenticated()) {
      this.authService.showLoginPage();
      return of(null);
    }
    this.favoritePendingStore$.next({
      ...this.favoritePendingStore$.value,
      [game.id]: true,
    });
    this.trackGameInWishlist(game, include);
    return (
      include
        ? this.gameService.strategy.addGameToWishList$(game.id)
        : this.gameService.strategy.removeGameFromWishList$(game.id)
    ).pipe(
      tap((wishlist: Game[]) => {
        this.wishlist = wishlist;
        this.globalChanges.next(this.wishlist);
        this.partialChanges.next({
          id: game.id,
          include,
        });
      }),
      catchError((e) => {
        this.toastService.danger({
          text:
            getGraphQLErrorCode(e) === 0
              ? 'Unable to establish a connection. Please ensure you are connected to the internet.'
              : getGraphQLErrorMessage(e),
          classname: 'bottom-0 start-50 toast toast-container translate-middle position-fixed',
        });
        this.wishlist = [...this.wishlist];
        this.globalChanges.next(this.wishlist);
        return of(this.wishlist);
      }),
      finalize(() => {
        this.favoritePendingStore$.next({
          ...this.favoritePendingStore$.value,
          [game.id]: false,
        });
      }),
    );
  }

  getUserWishlistIds(): Observable<IUserGamesWishlistId[]> {
    return this.gameService.strategy.getUserWishlistGameIds$();
  }

  private trackGameInWishlist(game: Game, inWishlist: boolean) {
    // track game added or removed from wishlist
    const tokenPrice = game.getTokenPrices();
    const wishlistEvent = {
      gameTitle: game.title,
      gameId: game.id,
      publisher: game.editorName,
      companyId: game.publisher?.id,
      currency: tokenPrice?.currency,
      price: tokenPrice?.amount,
      genres: game.categories,
      inWishlist,
    };
    this.mixpanelService.track(MixpanelEvent.WISHLIST_GAME, wishlistEvent);
  }
}
