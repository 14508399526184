import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter, map, merge, Subject } from 'rxjs';

import { WINDOW } from '../../providers/window.provider';

@UntilDestroy()
@Injectable()
export class AuthEventsService {
  private window = inject(WINDOW);
  private oAuthService = inject(OAuthService);
  private accessTokenExpiredInterval: number;
  private accessTokenExpiredSubject = new Subject<void>();
  private accessTokenReceivedSubject = new Subject<void>();

  /**
   * Emits each time a new access_token is received. Can be after a sign-in, token refresh or silent refresh.
   */
  readonly accessTokenReceived$ = merge(
    this.accessTokenReceivedSubject,
    this.oAuthService.events.pipe(filter((event) => event.type === 'token_received')),
  );
  /**
   * Emits some time before the access_token expires. The time is configured in AuthConfig.timeoutFactor
   */
  readonly accessTokenExpiring$ = this.oAuthService.events.pipe(filter((event) => event.type === 'token_expires'));
  /**
   * Emits after the access_token has expired.
   */
  readonly accessTokenExpired$ = this.accessTokenExpiredSubject;
  readonly sessionTerminated$ = this.oAuthService.events.pipe(filter((event) => event.type === 'session_terminated'));
  readonly logout$ = this.oAuthService.events.pipe(filter((event) => event.type === 'logout'));

  private accessTokenChange$ = merge(this.accessTokenReceived$, this.logout$).pipe(
    map(() => this.oAuthService.getAccessToken()),
  );

  constructor() {
    this.setupAccessTokenExpiredTimer();
  }

  dispatchTokenReceived(): void {
    this.accessTokenReceivedSubject.next();
  }

  private setupAccessTokenExpiredTimer() {
    this.startAccessTokenExpiredTimer();
    // Update expired timer each time access_token changes
    this.accessTokenChange$.pipe(untilDestroyed(this)).subscribe(() => {
      this.startAccessTokenExpiredTimer();
    });
  }

  private startAccessTokenExpiredTimer() {
    this.window.clearInterval(this.accessTokenExpiredInterval);
    const accessToken = this.oAuthService.getAccessToken();
    const expiresAt = this.oAuthService.getAccessTokenExpiration();

    // only start timer if there's an access token and it has an expiration
    if (!accessToken || expiresAt == null) {
      return;
    }

    const callback = (): void => {
      if (expiresAt <= Date.now()) {
        this.window.clearInterval(this.accessTokenExpiredInterval);
        this.accessTokenExpiredSubject.next();
      }
    };
    const durationMs = expiresAt - Date.now();
    // we're using a short timer and then checking the expiration in the
    // callback to handle scenarios where the browser device sleeps, and then
    // the timers end up getting delayed.
    const timerDuration = Math.max(Math.min(durationMs, 5000), 1000);
    this.accessTokenExpiredInterval = this.window.setInterval(callback, timerDuration);
  }
}
