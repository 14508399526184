import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VerticalProgressSticksComponent } from './vertical-progress-sticks.component';

@NgModule({
  declarations: [VerticalProgressSticksComponent],
  imports: [CommonModule],
  exports: [VerticalProgressSticksComponent],
})
export class VerticalProgressSticksModule {}
