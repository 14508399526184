import { gql } from 'apollo-angular';

import { UNIQ_FACTORY_METADATA_FIELDS } from './uniq-metadata-shared.query';

export const ACCOUNT = gql`
  {
    blockchainId
    eba {
      name
      accountId
      type
    }
  }
`;

export const CURRENCY = gql`
  {
    code
    symbol
  }
`;

export const UNIQ_REVENUE_SHARE = gql`
{
  account ${ACCOUNT}
  percentage
}
`;

export const SHARED_REVENUE = gql`
  {
    ratio
    amount
  }
`;

export const REVENUE = gql`
 {
    currency ${CURRENCY}
    amount
    platform ${SHARED_REVENUE}
    creators ${SHARED_REVENUE}
    promoter ${SHARED_REVENUE}
    owner ${SHARED_REVENUE}
 }
`;

export const PURCHASE_UNIQ_FACTORY = gql`{
  id
  onChainId
  type
  name
  subName
  creator {
    blockchainId
    eba {
      name
    }
  }
  medias {
    square {
      uri
    }
  }
  prices(currencyCodes: $currencyCodes) {
    currency ${CURRENCY}
    amount
  }
  quantity {
    quantityLeft
  }
  hidden
  tradability {
    minimumResellPrice {
      currency ${CURRENCY}
      amount
    }
  }
  metadata ${UNIQ_FACTORY_METADATA_FIELDS}
}`;

export const UNIQ_FACTORY_STATIC_COLLECTION_RESPONSE = gql`
  {
    _id
    _v
    name
    type
    title
    itemIds
  }
`;
