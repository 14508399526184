@if (uniq || idCardEntity) {
  <div class="w-100 uniq-wrap position-relative">
    @if (showBackground) {
      <div class="backdrop-image" [ngStyle]="{ 'background-image': getBackgroundImage() }"></div>
    }
    <ultra-id-card-content-header
      [idCardEntity]="idCardEntity"
      [config]="idCardContentHeaderConfig"
      (closed)="closeItem()" />
    <ultra-tab-group
      headerPosition="below"
      tabIndicator="bellow"
      indicatorSize="thick"
      tabHeaderClass="uniq-preview-body-tab-header"
      [padContent]="false"
      (tabChange)="onTabChange($event)">
      <div class="divider d-flex-center">
        <div class="d-flex">
          <ultra-uniq-type-icon
            [type]="idCardEntity?.content?.contentType?.toLowerCase()"
            opacity="70"
            showOrnament="true" />
        </div>
      </div>
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span ultraAutotestAttribute="token-preview-overview-tab">Overview</span>
        </ng-template>
        <ng-template ultraTabContent>
          @if (isContentVisible) {
            <ultra-id-card-overview [idCardEntity]="idCardEntity" [shouldValidateMetadata]="shouldValidateMetadata" />
          }
        </ng-template>
      </ultra-tab>
      <ultra-tab [disabled]="shouldDisableAdvancedDetailsContent">
        <ng-template ultraTabLabel>
          <ng-container>
            <span
              ultraAutotestAttribute="token-preview-attributes-tab"
              [class.tab-disabled]="shouldDisableAdvancedDetailsContent"
              >Attributes</span
            >
          </ng-container>
        </ng-template>
        <ng-template ultraTabContent>
          @if (shouldValidateMetadata) {
            <ultra-sync-attributes-skeleton class="bg-attribute-skeleton" />
          } @else {
            <ultra-id-card-attributes [idCardEntity]="idCardEntity" />
          }
        </ng-template>
      </ultra-tab>
      <ultra-tab [disabled]="shouldDisableAdvancedDetailsContent">
        <ng-template ultraTabLabel>
          <span
            ultraAutotestAttribute="token-preview-preview-tab"
            [class.tab-disabled]="shouldDisableAdvancedDetailsContent"
            >Gallery</span
          >
        </ng-template>
        <ng-template ultraTabContent>
          @if (shouldValidateMetadata) {
            <ultra-gallery-skeleton [animatedSkeleton]="false" theme="uniq-detail" />
          } @else {
            <ultra-media-carousel
              [multiMedia]="idCardEntity?.medias?.imageGalleryList"
              theme="uniq-detail"
              [expandable]="true" />
          }
        </ng-template>
      </ultra-tab>
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span ultraAutotestAttribute="token-preview-properties-tab">Properties</span>
        </ng-template>
        <ng-template ultraTabContent>
          <ultra-id-card-properties
            [activeTab]="properties.IDENTITY"
            [idCardEntity]="idCardEntity"
            [geofencingData]="geofencingData"
            [showTradability]="true"
            [showTransferability]="true"
            [showGeofencing]="false" />
        </ng-template>
      </ultra-tab>
      @if (showMarketPlaceContent()) {
        <ultra-tab [disabled]="isRestricted">
          <ng-template ultraTabLabel>
            <span ultraAutotestAttribute="token-preview-properties-tab">Trade</span>
          </ng-template>
          <ng-template ultraTabContent>
            @if (displayMarketplaceTab) {
              <ultra-id-card-marketplace
                [minResellPrice]="idCardEntity.tradability.minimumResellPrice"
                [minResellPriceCurrency]="idCardEntity.tradability.minimumResellPriceCurrency?.code"
                [uniq]="fetchedUniq || uniq" />
            }
          </ng-template>
        </ultra-tab>
      }
    </ultra-tab-group>

    <div class="d-flex position-absolute preview-content-actions">
      @if (shouldValidateMetadata || isRestricted) {
        <div class="container-border">
          @switch (idCardEntity?.metadata?.status) {
            @case (metadataStatusEnum.PROCESSING) {
              <div
                class="container-tooltip"
                placement="top"
                ngbTooltip="Synchronization is in progress"
                tooltipClass="transparent-purple-tooltip">
                <i class="icon icon-hourglass-16 bg-ultra-purple-light"></i>
              </div>
            }
            @case (metadataStatusEnum.INVALID) {
              <div
                class="container-tooltip container-tooltip--orange"
                placement="top"
                ngbTooltip="Error, please come back later"
                tooltipClass="transparent-orange-tooltip">
                <i class="bg-orange-light icon-alert-triangle-16"></i>
              </div>
            }
            @default {
              <div
                class="container-tooltip container-tooltip--red"
                placement="top"
                ngbTooltip="Restricted content"
                tooltipClass="transparent-red-tooltip">
                <i class="bg-red-light icon-block-16"></i>
              </div>
            }
          }
        </div>
      }
      @if (!forSale) {
        <ultra-id-card-quantity-status
          class="me-2"
          [quantityAvailability]="idCardEntity?.quantity"
          (click)="handlePropertyQuickAccess(properties.QUANTITY)" />
      }
      <ultra-actions-status
        class="me-2"
        [period]="idCardEntity?.tradingPeriod"
        [availability]="idCardEntity?.tradingPeriod?.resaleAvailability"
        (click)="handlePropertyQuickAccess(properties.TRADABILITY)"
        [action]="actions.TRADABILITY" />
      <ultra-actions-status
        class="me-2"
        [period]="idCardEntity?.transferPeriod"
        [action]="actions.TRANSFERABILITY"
        [availability]="idCardEntity?.transferPeriod?.transferAvailability"
        (click)="handlePropertyQuickAccess(properties.TRANSFERABILITY)" />
      @if (showPaymentButton && !isRestricted) {
        @if (!isUniqOwned) {
          <span>
            @if (isSetForSale()) {
              <button
                ultraAutotestAttribute="uniq-overview-price-button"
                class="price price--purple price__currency d-inline-block ms-1 rounded d-flex d-flex-center"
                [class.price--disabled]="!isBuyAvailable"
                [disabled]="!isBuyAvailable"
                (click)="onBuyClick($event)">
                <span class="price__text-active">Buy Now</span>
                @if (idCardEntity?.prices; as prices) {
                  <ultra-uniq-price
                    [type]="idCardEntity.type"
                    [price]="prices[0]?.amount"
                    [symbol]="prices[0]?.currency?.symbol"
                    [minResellPrice]="idCardEntity.tradability?.minimumResellPrice"
                    [minResellPriceCurrency]="idCardEntity.tradability?.minimumResellPriceCurrency?.code" />
                }
              </button>
            }
          </span>
        } @else {
          @if (isOwnedUniqControlsVisible$ | async) {
            @if (isSetForSale()) {
              <button
                class="btn btn-danger ms-1 rounded d-inline-flex d-flex-center min-view-105"
                (click)="onWithdraw($event)">
                Withdraw<i class="icon icon-arrow-sm ms-2"></i>
              </button>
            } @else {
              <button
                class="btn btn-primary ms-1 rounded d-inline-flex d-flex-center min-view-105"
                [disabled]="!isSellAvailable()"
                (click)="onSell($event)"
                ultraAutotestAttribute="sell-button">
                Sell<i class="icon icon-arrow-sm ms-2"></i>
              </button>
            }
          }
        }
      }
    </div>
  </div>
}
