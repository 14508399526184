import { Paginatable } from '../../models/base-interfaces/pagination.interface';
import { IUniq } from '../../models/uniq/interfaces/uniq.interface';

export class SetOwnUniqs {
  public static type = '[OwnUniq] Set Own Uniqs';

  constructor(public payload: Paginatable<IUniq[]>) {}
}

export class UpdateUniqStatusSaleInfo {
  public static type = '[OwnUniq] Update Uniq Status Sale Info';

  constructor(public payload: IUniq) {}
}
