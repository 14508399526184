import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
  standalone: true,
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, length = 200): string {
    return value && value.length > length ? value.substr(0, length).concat('...') : value;
  }
}
