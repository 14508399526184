import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IDimension } from '@ultra/core/models';
import { AutotestAttributeDirective } from '@ultra/shared';

@Component({
  selector: 'ultra-dimension',
  styleUrls: ['./dimension.component.scss'],
  templateUrl: './dimension.component.html',
  standalone: true,
  imports: [NgClass, NgStyle, AutotestAttributeDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimensionComponent {
  @Input() dimension: IDimension;

  @Output() activateDimension: EventEmitter<IDimension> = new EventEmitter<IDimension>();

  setDimension(): void {
    this.activateDimension.emit(this.dimension);
  }
}
