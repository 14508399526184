import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'version',
})
export class VersionPipe implements PipeTransform {
  transform(value: string, property: string): string {
    const versionMapper = {
      master: {
        icon: 'icon-play-xs',
        bgColor: 'bg-success',
        textColor: 'text-success',
      },
      beta: {
        icon: 'icon-beta-xs',
        bgColor: 'bg-warning',
        textColor: 'text-warning',
      },
    };

    if (!value || !versionMapper[value]) {
      return value;
    }

    return versionMapper[value][property];
  }
}
