export class RegExpService {
  static password = /(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\])(?=.*\d)(?!.*[\n])(?=.*[a-z])(?=.*[A-Z])^.{8,}$/;
  static oneDecimal = /\d/;
  static oneSpecial = /[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]/;
  static oneCapital = /[A-Z]/;
  static oneLowerCaseLetter = /[a-z]/;
  static url = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
  static acceptedImageTypes = /(jpe?g|png|gif)/;
  static acceptedFileTypes = /(ztmp|zip)/;
  static youtubeLink =
    /^(https:\/\/www\.|https:\/\/|www\.)?youtube\.com\/watch\?v=[A-Za-z0-9.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]+$/;
  static companyPhone = /^\+?[0-9]{3,15}$/;
  static whiteSpaces = /^\S+(\s\S+)*$/;
  static email =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))$/;
  static urlWithHttp = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9_.\-~#]+([a-zA-Z0-9]|#)\.[^\s]{2,}/;
  static zipCode = /^[a-zA-Z0-9-]+$/;
  static alphaOnly = /^[a-zA-Z]+$/;
  static alphaWithSpacesAndDashes = /^(?!(\s|-))([a-zA-Z-]\s?)+$/;
  static alphaWithSpacesAndSlashes = /^(?!(\s|\/))([a-zA-Z0-9/]\s?)+$/;
  static alphaSpacesDashesApostrophes = /^[a-z\-'’\s]+$/i;
  static alphaSpacesDashesApostrophesUnderscores = /^[a-z\-_'’\s]+$/i;
  static alphaNumericSpacesDashesApostrophesSlashes = /^[a-z\d\-'’/\s]+$/i;
  static alphaNumericSpacesDashes = /^[a-z\d\-\s]+$/i;
  static lettersOrNumbers = /^[0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+$/;
  static startsWithLetters = /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF].*/;
  static numeric = /^\d*$/;
  static ethereumAddress = /^0x[a-fA-F0-9]{40}$/;
  static nonZeroNumbersAndFloats = /^\s*(?=.*[1-9])\d*(?:\.\d{1,8})?\s*$/;
  static mongoIdPattern = /^[0-9a-f]{24}$/;
  static uuidv4 = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  static positiveInteger = /^[0-9]+$/;
  static repeatedCharacter = /([a-zA-Z0-9])\1{2,}/;
  static usernameAlphaNumeric = /^[a-zA-Z0-9]+$/;
}
