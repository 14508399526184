import cloneDeep from 'lodash-es/cloneDeep';

export function ImmutableSelector(): (
  target: object,
  key: string,
  descriptor: PropertyDescriptor
) => PropertyDescriptor {
  return function (_target: object, _key: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    const method = descriptor.value;

    descriptor.value = function (state: any, ...args: any[]) {
      return method.apply(this, [cloneDeep(state), ...args]);
    };

    return descriptor;
  };
}
