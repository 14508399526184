import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GameDetail } from '../../../../models/game/models/game-detail.model';
import { ContentSkeletonTheme } from '../../../../modules/skeleton/content-skeleton/content-skeleton-theme.enum';

@Component({
  selector: 'ultra-game-detail-features',
  templateUrl: './game-detail-features.component.html',
  styleUrls: ['./game-detail-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailFeaturesComponent {
  @Input()
  game: GameDetail;

  @Input()
  isRouteChange = false;

  readonly placeholderTheme = ContentSkeletonTheme;
}
