<div class="scroll-container" ultraScroll>
  <div class="token-content d-flex flex-column p-3 mb-2">
    <div class="token-type d-inline-flex align-self-start rounded align-items-center me-2">
      <i class="icon me-2 bg-white-alpha-7 icon-{{ iconType }}"></i>
      <span class="f-size-12 text-white-alpha-7">{{ idCardEntity?.content?.contentType }}</span>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-auto">
      @if (idCardEntity?.content?.tokenContent) {
        <div class="d-flex ms-auto">
          <div class="d-flex align-items-center me-2">
            <div class="branch rounded text-uppercase" [class.game-active]="getBranch('master')">
              <i class="icon me-1 {{ 'master' | version: 'icon' }}"></i>
              <ng-container i18n>Game</ng-container>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="branch rounded text-uppercase" [class.beta-active]="getBranch('beta')">
              <i class="icon me-1 {{ 'beta' | version: 'icon' }}"></i>
              <ng-container i18n>Beta</ng-container>
            </div>
          </div>
        </div>
      }
    </div>
  </div>

  @if (idCardEntity?.content?.languages) {
    <ultra-table [dataSource]="dataSource">
      <ng-container ultraColumnDef="language">
        <ultra-header-cell *ultraHeaderCellDef class="language-cell">
          <div class="language-header rounded p-2">
            <i class="icon icon-lp"></i>
            <span i18n class="d-inline-block f-size-12 ms-2">Languages</span>
          </div>
        </ultra-header-cell>
        <ultra-cell *ultraCellDef="let row">
          {{ row?.language | langFormatter: languages() }}
        </ultra-cell>
      </ng-container>
      <ng-container ultraColumnDef="audio">
        <ultra-header-cell *ultraHeaderCellDef>
          <span class="text-white-alpha-7 text-center w-100 text-uppercase" i18n>Audio</span>
        </ultra-header-cell>
        <ultra-cell *ultraCellDef="let row">
          @if (row?.audio) {
            <i class="icon icon-check w-100 text-center"></i>
          }
        </ultra-cell>
      </ng-container>
      <ng-container ultraColumnDef="subtitles">
        <ultra-header-cell *ultraHeaderCellDef>
          <span class="text-white-alpha-7 text-center w-100 text-uppercase" i18n>Subtitles</span>
        </ultra-header-cell>
        <ultra-cell *ultraCellDef="let row">
          @if (row?.subtitles) {
            <i class="icon icon-check w-100 text-center"></i>
          }
        </ultra-cell>
      </ng-container>
      <ng-container ultraColumnDef="interfaces">
        <ultra-header-cell *ultraHeaderCellDef>
          <span class="text-white-alpha-7 text-center w-100 text-uppercase">Interfaces</span>
        </ultra-header-cell>
        <ultra-cell *ultraCellDef="let row">
          @if (row?.interfaces) {
            <i class="icon icon-check w-100 text-center"></i>
          }
        </ultra-cell>
      </ng-container>
      <ultra-header-row *ultraHeaderRowDef="columns"></ultra-header-row>
      <ultra-row
        ultraAutotestAttribute="token-languages-item"
        *ultraRowDef="let row; index as i; columns: columns"></ultra-row>
    </ultra-table>
  } @else {
    <ultra-id-card-content-placeholder></ultra-id-card-content-placeholder>
  }
</div>
