import { CommonModule } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../../autotests';
import { ExchangeDate, IUniqActionsPeriod, IUniqAvailability } from '../../../models';
import { ActionsGaugeStateComponent } from '../../../modules/id-card-content/id-card-actions/components/actions-gauge-state/actions-gauge-state.component';
import { ActionsPeriodTextComponent } from '../../../modules/id-card-content/id-card-actions/components/actions-period-text/actions-period-text.component';
import { IdCardActionsTitleComponent } from '../../../modules/id-card-content/id-card-actions/components/actions-title/id-card-actions-title.component';
import {
  ActionDisplayState,
  ActionStatus,
  IdCardAction,
} from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';

import {
  ITradabilityRevenue,
  TradabilityResaleComponent,
} from './components/tradability-resale/tradability-resale.component';
import { IdCardActionsDisplayHelper } from './helpers/id-card-actions-display.helper';

@Component({
  selector: 'ultra-id-card-actions',
  templateUrl: './id-card-actions.component.html',
  styleUrls: ['./id-card-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ActionsGaugeStateComponent,
    IdCardActionsTitleComponent,
    ActionsPeriodTextComponent,
    TradabilityResaleComponent,
    NgbTooltipModule,
    AutotestAttributeDirective,
  ],
  standalone: true,
})
export class IdCardActionsComponent implements AfterContentInit {
  @Input() actionsTooltipInfo: string;
  @Input() period: IUniqActionsPeriod | null;
  @Input() startDate: ExchangeDate;
  @Input() endDate: ExchangeDate;
  @Input() availability: IUniqAvailability;
  @Input() action: IdCardAction | null;
  @Input() revenueData: ITradabilityRevenue;

  displayState: ActionDisplayState;

  readonly actions = IdCardAction;
  readonly actionStatuses = ActionStatus;

  ngAfterContentInit() {
    this.setDisplayState();
  }

  private setDisplayState(): void {
    this.displayState = IdCardActionsDisplayHelper.getState(
      this.period,
      this.availability,
      this.startDate,
      this.endDate,
    );
  }
}
