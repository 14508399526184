<div class="d-flex header align-items-center" [style.width.px]="grid?.maxWidthPad">
  <div class="ms-0">
    <span class="fw-bold f-size-16" ultraAutotestAttribute="card-carousel-title-content">{{ title }}</span>
  </div>
  <div class="pagination ms-auto">
    <button
      class="pagination-btn bg-white-alpha-1"
      [class.disabled]="paginationDisabledState('left', pagination)"
      (click)="paginate('left')">
      <i class="icon icon-chevron-16"></i>
    </button>
    <button
      class="pagination-btn bg-white-alpha-1"
      [class.disabled]="paginationDisabledState('right', pagination)"
      (click)="paginate('right')"
      ultraAutotestAttribute="card-carousel-right-button">
      <i class="icon icon-chevron-16 rotate-180"></i>
    </button>
  </div>
</div>
<div class="list-holder position-relative" [style.width.px]="grid?.maxWidth">
  <div
    class="list d-flex align-items-center justify-content-center position-relative"
    [style.min-height.px]="forceAutoHeight ? 0 : grid.minHeight"
    [@animSliderPosition]="{ value: sliderPosition, params: grid.positions }">
    @if (dataSource.data$ | async; as data) {
      @for (item of data; track item; let i = $index) {
        <div
          class="list-item-holders h-100 d-flex align-items-center flex-grow-1"
          ultraAutotestAttribute="card-carousel-list-item-holders">
          <div class="list-item d-flex justify-content-center align-items-center w-100">
            @if (item && item.id) {
              <ng-container
                *ngTemplateOutlet="
                  template;
                  context: {
                    $implicit: item
                  }
                " />
            } @else {
              <div class="placeholder d-flex position-relative w-100 h-100">
                <div class="placeholder-item d-flex w-100 bg-white-alpha-1"></div>
              </div>
            }
          </div>
        </div>
      }
    }
  </div>
</div>
