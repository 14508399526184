import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Big, { RoundingMode } from 'big.js';
import merge from 'lodash-es/merge';
import { BehaviorSubject, firstValueFrom, Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { FeatureFlag, FeatureFlagService } from '@ultra/core/lib/services/feature-flag';
import { CurrencySymbol, CurrencyType } from '@ultra/core/models';
import { MixpanelEvent, MixpanelService } from '@ultra/core/services';
import { UserFacadeService } from '@ultra/core/stores';

import { getUniqEventData } from '../../../helpers/mixpanel-uniq-data.helper';
import { IRevenue, ISharedRevenue } from '../../../models/token-factory/interfaces/tradability.interface';
import { ICurrency } from '../../../models/uniq/interfaces/currency.interface';
import { IUniq, UniqStatus } from '../../../models/uniq/interfaces/uniq.interface';
import {
  ActionDisplayState,
  ActionStatus,
  IdCardAction,
} from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { BlockchainTransactionBuilder } from '../../../services/authenticator/builder/blockchain-transaction.builder';
import { EosioUniqAction, KnownContract } from '../../../services/authenticator/enums/known-contracts.enum';
import { ITransactionAction } from '../../../services/authenticator/interfaces/authenticator-api-input.interface';
import { CancelResellUniqParams } from '../../../services/authenticator/interfaces/cancel-resell-uniq-params.interface';
import { ResellUniqParams } from '../../../services/authenticator/interfaces/resell-uniq-params.interface';
import { TransactionPayload } from '../../../services/authenticator/interfaces/transaction-payload.unterface';
import { TransactionAppService } from '../../../services/transaction/transaction-app.service';
import { UniqForSaleFacadeService } from '../../../services/uniq/uniq-for-sale-facade.service';
import { RegExpService } from '../../../services/validators/reg-exp.service';
import { UniqPriceService } from '../../../uniq/uniq-price.service';
import { UNIQ_FEE_CONFIG } from '../../../uniq/uniq-transaction-config';
import { ISelectItem } from '../../form/interfaces/form-elements.interface';
import { IdCardActionsDisplayHelper } from '../id-card-actions/helpers/id-card-actions-display.helper';
import {
  IdCardPanelDispatcherService,
  IdCardPropertyTabs,
} from '../id-card-panel-dispatcher/providers/id-card-panel-dispatcher.service';

const UOS_CURRENCY: ICurrency = {
  code: CurrencyType.UOS,
  symbol: CurrencySymbol.UOS,
};

const CURRENCY_OPTIONS: ISelectItem[] = [
  {
    lbl: CurrencyType.UOS,
    value: CurrencyType.UOS,
  },
];

const DEFAULT_REVENUE_AMOUNT: IRevenue = {
  amount: '0',
  creators: { amount: '0' },
  owner: { amount: '0' },
  platform: { amount: '0' },
  promoter: { amount: '0' },
};

const MIN_RESELL_VALUE = 0.01;

@UntilDestroy()
@Component({
  selector: 'ultra-id-card-sell',
  templateUrl: './id-card-sell.component.html',
  styleUrls: ['./id-card-sell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IdCardSellComponent implements OnInit, AfterContentInit {
  form: UntypedFormGroup;
  currencyOptions: ISelectItem[];
  owner: ISharedRevenue;
  platform: ISharedRevenue;
  creators: ISharedRevenue;
  promoter: ISharedRevenue;
  currency: ICurrency = UOS_CURRENCY;

  isTradable: boolean;

  private setForSaleDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isSetForSaleDisabled$ = this.setForSaleDisabled.asObservable();

  @Input()
  minResellPrice: number;
  @Input()
  minResellPriceCurrency: string;
  @Input()
  uniq: IUniq;

  isEdit = true;
  hasFiatMinPrice: boolean;
  minResellPriceUos$: Observable<number>;
  refreshProgress$: Observable<number>;
  displayState: ActionDisplayState;
  showPromoterFee: boolean;

  readonly actions = IdCardAction;
  readonly actionStatuses = ActionStatus;

  readonly promoterFeeDefaultValue = UNIQ_FEE_CONFIG.promoterFeePercentage;

  get totalAmount(): string {
    const symbol = this.uniq.saleInfo.price.currency.symbol;
    const amount = this.uniq.saleInfo.price.amount;
    return `${symbol} ${amount}`;
  }

  set revenue(value: IRevenue) {
    this._revenue = value;
    this.updateFee(value);
  }

  get revenue(): IRevenue {
    return this._revenue;
  }

  private _revenue: IRevenue;

  private userBlockChainId$: Observable<string>;

  constructor(
    private uniqPriceService: UniqPriceService,
    private uniqForSaleFacadeService: UniqForSaleFacadeService,
    private userFacadeService: UserFacadeService,
    private transactionService: TransactionAppService,
    private ref: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
    private mixpanelService: MixpanelService,
    @Optional() private panelDispatcher: IdCardPanelDispatcherService,
  ) {
    this.userBlockChainId$ = this.userFacadeService.user$.pipe(map((user) => user?.blockchainId));
  }

  ngOnInit(): void {
    this.isTradable = this.isSellAvailable();
    this.isEdit = this.uniq.status !== UniqStatus.ON_SALE;
    this.showPromoterFee = this.featureFlagService.isEnabled(FeatureFlag.ENABLE_PROMOTER_FEE);

    this.initRevenue();

    if (this.isEdit && this.isTradable) {
      this.initCurrencyOptions();
      this.createForm();
      this.initForm();
      this.listenAmountChanges();
      this.minResellPriceUos$ = this.getMinResellPriceUos();
      this.refreshProgress$ = this.minResellPriceUos$.pipe(switchMap(() => timer(0, 1000)));
    }
  }

  ngAfterContentInit() {
    this.displayState = IdCardActionsDisplayHelper.getState(
      this.uniq?.tradingPeriod,
      this.uniq?.tradingPeriod?.resaleAvailability,
    );
  }

  public setUniqForSale(): void {
    this.userBlockChainId$
      .pipe(
        untilDestroyed(this),
        map((blockchainId: string) => this.createTransactionForSale(blockchainId)),
        tap((transaction: ITransactionAction) => this.setTransactionByType(transaction, EosioUniqAction.RESELL)),
      )
      .subscribe();
  }

  public withdrawUniq(): void {
    this.userBlockChainId$
      .pipe(
        untilDestroyed(this),
        map((blockchainId: string) => this.createTransactionForWithdraw(blockchainId)),
        tap((transaction: ITransactionAction) => this.setTransactionByType(transaction, EosioUniqAction.CANCEL_RESELL)),
      )
      .subscribe();
  }

  public handleTradabilityQuickAccess(): void {
    if (!this.panelDispatcher) {
      return;
    }
    this.panelDispatcher.next(IdCardPropertyTabs.TRADABILITY);
  }

  private setTransactionByType(transaction: ITransactionAction, type: EosioUniqAction) {
    const transactionPayload = this.processTransactionPayload(type);
    this.trackDetails(type).then(() => {
      this.transactionService.openTransactionSigning(transaction, transactionPayload);
    });
  }

  private processTransactionPayload(action: EosioUniqAction): TransactionPayload {
    const amount =
      action === EosioUniqAction.RESELL
        ? Number(this.form.get('amount').value)
        : Number(this.uniq.saleInfo.price.amount);
    return {
      action,
      id: this.uniq.id,
      price: {
        amount,
        currency: this.currency.code,
      },
    };
  }

  private async trackDetails(type: EosioUniqAction): Promise<void> {
    let mixpanelEvent: MixpanelEvent;
    let listingPrice: string;

    if (type === EosioUniqAction.RESELL) {
      mixpanelEvent = MixpanelEvent.UNIQ_SALE_DETAILS;
      listingPrice = this.form.get('amount').value;
    } else if (type === EosioUniqAction.CANCEL_RESELL) {
      mixpanelEvent = MixpanelEvent.UNIQ_CANCEL_SALE_DETAILS;
      listingPrice = this.uniq.saleInfo.price.amount;
    } else {
      return;
    }

    let uosRate: string;
    if (this.hasFiatMinPrice) {
      uosRate = await firstValueFrom(
        this.uniqPriceService.getExchangeRate(this.minResellPriceCurrency as CurrencyType),
      ).catch(() => undefined);
    }
    await this.mixpanelService.waitForTrack(mixpanelEvent, {
      ...getUniqEventData(this.uniq),
      listingPrice,
      protocolFee: this.platform,
      marketplaceFee: this.promoter,
      royalties: this.creators,
      resellerEarnings: this.owner.amount,
      uosRate,
    });
  }

  private isSellAvailable(): boolean {
    return this.uniq.tradingPeriod?.resaleAvailability && !this.uniq.tradingPeriod.resaleAvailability.isLocked;
  }

  private createTransactionForWithdraw(blockchainId: string): ITransactionAction {
    const cancelResellParams: CancelResellUniqParams = {
      token_id: Number(this.uniq.onChainId),
      memo: this.uniq.id,
      uniq: this.uniq,
    };
    return new BlockchainTransactionBuilder()
      .action(EosioUniqAction.CANCEL_RESELL)
      .contract(KnownContract.EOSIO_UNIQ)
      .authorizationForTransferAction(blockchainId)
      .dataForCancelResellAction(cancelResellParams)
      .build();
  }

  private createTransactionForSale(blockchainId: string): ITransactionAction {
    const uniq: IUniq = { ...this.uniq };
    uniq.tradingPeriod.resaleAvailability.revenue = this.revenue;
    const resellParams: ResellUniqParams = {
      seller: blockchainId,
      token_id: Number(this.uniq.onChainId),
      price: +this.form.get('amount').value,
      memo: this.uniq.id,
      uniq,
      promoter_basis_point: this.showPromoterFee ? this.promoter.ratio * 100 : 250,
    };
    return new BlockchainTransactionBuilder()
      .action(EosioUniqAction.RESELL)
      .contract(KnownContract.EOSIO_UNIQ)
      .authorizationForTransferAction(blockchainId)
      .dataForResellAction(resellParams)
      .build();
  }

  private initRevenue(): void {
    this.revenue =
      this.uniq.status === UniqStatus.ON_SALE
        ? this.uniq.saleInfo.price
        : merge(this.uniq.tradingPeriod?.resaleAvailability?.revenue, DEFAULT_REVENUE_AMOUNT);
    this.hasFiatMinPrice = this.minResellPriceCurrency && this.minResellPriceCurrency !== CurrencyType.UOS;
  }

  private initCurrencyOptions(): void {
    this.currencyOptions = [...CURRENCY_OPTIONS];
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      currency: new UntypedFormControl(null),
      amount: new UntypedFormControl(null),
    });
  }

  private initForm(): void {
    const currency = this.currencyOptions[0].value;
    const valueToPatch = { currency };
    this.form.patchValue(valueToPatch);
  }

  private updateFee(value: IRevenue): void {
    this.platform = value.platform;
    this.creators = value.creators;
    this.owner = value.owner;
    this.promoter = value.promoter;
  }

  private listenAmountChanges(): void {
    this.form
      .get('amount')
      .valueChanges.pipe(
        debounceTime(500),
        untilDestroyed(this),
        map((amount) => (RegExpService.nonZeroNumbersAndFloats.test(amount) ? amount : null)),
        switchMap((amount) => {
          if (!amount) {
            return of(null);
          }
          if (this.hasFiatMinPrice) {
            return of(this.calcRevenue(+amount));
          } else {
            return this.getRevenue(+amount);
          }
        }),
      )
      .subscribe((revenue) => {
        if (!revenue) {
          merge(this.revenue, DEFAULT_REVENUE_AMOUNT);
        } else {
          this.revenue = revenue;
        }
        this.setForSaleDisabled.next(!revenue);
        this.ref.markForCheck();
      });
  }

  private getRevenue(amount: number): Observable<IRevenue> {
    const price: { amount: number; currency: string; promoterRatio?: number } = {
      amount,
      currency: this.currency.code,
    };

    if (this.showPromoterFee) {
      // Wrong name `promoterRatio` property in backend. Ratio should be `promoterPercentage`.
      price.promoterRatio = UNIQ_FEE_CONFIG.promoterFeePercentage;
    }

    return this.uniqForSaleFacadeService.getRevenue(this.uniq.factory.id, price).pipe(
      catchError(() => {
        return of(null);
      }),
    );
  }

  private calcRevenue(amount: number): IRevenue {
    const revenue = this.uniq.tradingPeriod.resaleAvailability?.revenue;
    if (!revenue || !amount) {
      return null;
    }
    const amountBig = Big(amount);

    let ownerAmount = amountBig.mul(revenue.owner.ratio).div(100);
    const promoterAmount = amountBig.mul(this.promoterFeeDefaultValue).div(100);

    if (this.showPromoterFee) {
      ownerAmount = ownerAmount.minus(promoterAmount);
    }
    return merge({}, revenue, {
      amount,
      creators: { amount: amountBig.mul(revenue.creators.ratio).div(100).toString() },
      owner: { amount: ownerAmount.toString() },
      platform: { amount: amountBig.mul(revenue.platform.ratio).div(100).toString() },
      promoter: { amount: promoterAmount.toString(), ratio: this.promoterFeeDefaultValue },
    });
  }

  private getMinResellPriceUos(): Observable<number> {
    let minPrice$: Observable<number>;
    if (this.hasFiatMinPrice) {
      minPrice$ = this.uniqPriceService
        .getExchangeRate(this.minResellPriceCurrency as CurrencyType)
        .pipe(map((rate) => +Big(this.minResellPrice).mul(rate).round(2, RoundingMode.RoundUp)));
    } else {
      minPrice$ = of(this.minResellPrice);
    }
    return minPrice$.pipe(map((minPrice) => Math.max(minPrice, MIN_RESELL_VALUE)));
  }
}
