import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { escapeSpecialCharacters, removeUnnecessaryWhitespaces } from '../../../../helpers';

@UntilDestroy()
@Component({
  selector: 'ultra-navbar-search-form',
  templateUrl: './navbar-search-form.component.html',
  styleUrls: ['./navbar-search-form.component.scss'],
})
export class NavbarSearchFormComponent implements OnDestroy {
  private searchChanged: Subject<string> = new Subject<string>();

  id: number;
  search: string;
  isSearchOpen: boolean;

  @Input() pending = false;

  @Output() changeSearchFocusTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchTrigger: EventEmitter<string> = new EventEmitter<string>();
  @Output() resetTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitTrigger: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.id = Math.round(Math.random() * +new Date());

    this.searchChanged.pipe(debounceTime(300), untilDestroyed(this)).subscribe((search: string) => {
      if (search.trim().length > 1) {
        this.searchTrigger.emit(search);
      } else {
        this.resetTrigger.emit(true);
      }
    });
  }

  ngOnDestroy() {
    this.close();
  }

  open(): void {
    this.isSearchOpen = true;
    this.changeSearchFocusTrigger.emit(true);
  }

  changed(text: string): void {
    const textWithoutUnnecessaryWhitespaces = removeUnnecessaryWhitespaces(text);
    const textWithEscapedSpecialSymbols = escapeSpecialCharacters(textWithoutUnnecessaryWhitespaces, ['(', ')', '[']);
    this.searchChanged.next(textWithEscapedSpecialSymbols);
  }

  /**
   * Can be called after click on close button or after emitting event from directive
   * If clicked outside would be false
   * If close button pressed would be undefined
   * @param event
   */
  close(event?: boolean): void {
    if (event === false && event !== undefined) {
      return;
    }

    setTimeout(() => {
      this.reset();
      this.isSearchOpen = false;
      this.changeSearchFocusTrigger.emit(false);
    }, 300);
  }

  reset(): void {
    this.search = '';
    this.resetTrigger.emit(true);
  }

  submit(value: string): void {
    this.close();
    this.submitTrigger.emit(value);
  }
}
