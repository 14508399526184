import { BranchType, IGameVersion, ITokenFactory, RepositoryType } from '../..';

export type OsType = 'win' | 'linux' | 'mac' | 'cross';

export interface IUserGameBranch {
  game: IUserGameDetail;
  branchType: BranchType;
  // Content of the core game
  buildContentNodes: IUserGameVersionedContentNode[];
  // Content of the DLCs
  componentContentNodes: IUserGameContentNode[];
  isInstalled?: boolean;
  state?: string;
}

export interface IUserGameDetail {
  id: string;
  publisher: { id: string; name: string };
  liveGame: IGameVersion;
  lastFileManagementPublishDate?: string;
}

export interface IUserGameVersionedContentNode {
  content: IUserGameVersionedContent;
  compatibleContents: IUserGameVersionedContent[];
}

export interface IUserGameContentNode {
  content: IUserGameContent;
  compatibleContents: IUserGameContent[];
}

export interface IUserGameContent {
  repositoryId: string;
  repositoryType: RepositoryType;
  osType: OsType;
  tokens: IUserGameToken[];
}

export interface IUserGameVersionedContent {
  repositoryId: string;
  repositoryType: RepositoryType;
  repositoryVersion: number;
  osType: OsType;
  tokens: IUserGameToken[];
}

export interface IUserGameToken {
  tokenFactory: ITokenFactory;
  quantity: number;
  orders: IOrderStatus[];
  license: ILicense;
}

export interface IOrderStatus {
  id: string;
  status: string;
}

export interface ILicense {
  status: LicenseStatus;
  ticketId: string;
}

export enum LicenseStatus {
  SUCCESS = 'SUCCESS',
  NO_LICENSE = 'NO_LICENSE',
  ERROR = 'ERROR',
  CREATING = 'CREATING',
}

export interface IUserLibraryGames {
  data: IUserGameBranch[];
  meta: IUserLibraryMeta;
}

export interface IUserLibraryMeta {
  date: string;
}

export type ClientGameId = `${string}${BranchType}`;

export interface IClientInstalledGameInfo {
  gameId: ClientGameId;
  gameTitle?: string;
}
