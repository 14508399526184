import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { catchError, EMPTY, filter, switchMap, tap } from 'rxjs';

import { UserFacadeService } from '../../stores/user/user-facade.service';
import { AuthService } from '../auth/auth.service';

import { MixpanelService } from './mixpanel.service';

function mixpanelLogin() {
  const authService = inject(AuthService);
  const mixpanelService = inject(MixpanelService);
  const userFacadeService = inject(UserFacadeService);

  return () => {
    if (mixpanelService.DISABLED) return;

    authService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() =>
          userFacadeService.getCurrentUser(false).pipe(
            tap((user) => mixpanelService.login(user.id, user.username, user.personalData.email, user.blockchainId)),
            catchError(() => EMPTY),
          ),
        ),
      )
      .subscribe();
  };
}

export const MIXPANEL_LOGIN_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: mixpanelLogin,
  multi: true,
};
