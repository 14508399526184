import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Toast } from './toast.model';

// todo GP-20524 finalize
@Injectable({ providedIn: 'root' })
export class ToastService {
  private readonly defaultVisibilityDuration = 5000;
  toasts$ = new BehaviorSubject<Toast[]>([]);

  // todo GP-20524 add more types
  addToast(toast: Toast, type?: 'danger' | 'primary' | 'warning'): void {
    this.toasts$.next([
      ...this.toasts$.value,
      {
        ...toast,
        delay: toast.delay || this.defaultVisibilityDuration,
        classname: (type ? `bg-${type} ` : '') + toast.classname,
      },
    ]);
  }

  removeToast(toast: Toast): void {
    this.toasts$.next([...this.toasts$.value.filter((t) => t !== toast)]);
  }

  danger(toast: Toast): void {
    this.addToast(toast, 'danger');
  }

  primary(toast: Toast): void {
    this.addToast(toast, 'primary');
  }

  warning(toast: Toast): void {
    this.addToast(toast, 'warning');
  }
}
