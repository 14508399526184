import { gql } from 'apollo-angular';

import { GAME_VERSION_GQL_WITHOUT_BUILD, IMAGE_DETAIL_GQL } from '../../shared/queries/shared.query';

export const USER_GAME_CONTENT = `
{
  repositoryId
  repositoryType
  osType
  tokens {
    quantity
    tokenFactory {
         id
         blockchainId
         name
         description
         factoryType
         image {
          name
          fileExtension
          base ${IMAGE_DETAIL_GQL}
          images ${IMAGE_DETAIL_GQL}
         }
         content {
           gameContents {
               gameId
               builds {
                 buildId
                 branches
               }
               components {
                 repositoryId
                 branches
               }
           }
         }
         contentType {
            id
            code
            index
            name
            icon
            shortDescription
            description
         }
    }
    orders {
      id
      status
    },
    license {
      status,
      ticketId
    }
  }
}
`;

export const USER_GAME_CONTENT_WITHOUT_LICENSE = `
{
  repositoryId
  repositoryType
  osType
  tokens {
    quantity
    tokenFactory {
      id
      blockchainId
      name
      description
      factoryType
      image {
        name
        fileExtension
        base ${IMAGE_DETAIL_GQL}
        images ${IMAGE_DETAIL_GQL}
      }
      content {
        gameContents {
          gameId
          builds {
            buildId
            branches
          }
          components {
            repositoryId
            branches
          }
        }
      }
      contentType {
        id
        code
        index
        name
        icon
        shortDescription
        description
      }
    }
    orders {
      id
      status
    }
  }
}
`;

export const USER_GAME_VERSIONED_CONTENT = `
{
  repositoryId
  repositoryType
  repositoryVersion
  osType
  tokens {
    quantity
    tokenFactory {
         id
         blockchainId
         onChainId
         name
         description
         factoryType
         image {
          name
          fileExtension
          base ${IMAGE_DETAIL_GQL}
          images ${IMAGE_DETAIL_GQL}
         }
         content {
           gameContents {
               gameId
               builds {
                 buildId
                 branches
               }
               components {
                 repositoryId
                 branches
               }
           }
         }
         contentType {
            id
            code
            index
            name
            icon
            shortDescription
            description
         }
    }
    orders {
      id
      status
    }
    license {
      status,
      ticketId
    }
  }
}
`;

export const USER_GAME_VERSIONED_CONTENT_WITHOUT_LICENSE = `
{
  repositoryId
  repositoryType
  repositoryVersion
  osType
  tokens {
    quantity
    tokenFactory {
      id
      blockchainId
      name
      description
      factoryType
      image {
        name
        fileExtension
        base ${IMAGE_DETAIL_GQL}
        images ${IMAGE_DETAIL_GQL}
      }
      content {
        gameContents {
          gameId
          builds {
            buildId
            branches
          }
          components {
            repositoryId
            branches
          }
        }
      }
      contentType {
        id
        code
        index
        name
        icon
        shortDescription
        description
      }
    }
    orders {
      id
      status
    }
  }
}
`;

export const USER_GAME_QUERY = gql`
 query userGame {
  userGame {
    list {
      game {
        id
        publisher {
          id,
          name
        }
        liveGame ${GAME_VERSION_GQL_WITHOUT_BUILD}
        lastFileManagementPublishDate
      }
      branchType
      buildContentNodes {
        content ${USER_GAME_VERSIONED_CONTENT}
        compatibleContents ${USER_GAME_VERSIONED_CONTENT}
      }
      componentContentNodes {
        content ${USER_GAME_CONTENT}
        compatibleContents ${USER_GAME_CONTENT}
      }
    }
  }
 }
`;
