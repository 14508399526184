export const TIMEZONE_DATA = {
  zoneToCountry: {
    'Europe/Andorra': 'AD',
    'Europe/Vienna': 'AT',
    'Australia/Lord_Howe': 'AU',
    'Antarctica/Macquarie': 'AU',
    'Australia/Hobart': 'AU',
    'Australia/Melbourne': 'AU',
    'Australia/Sydney': 'AU',
    'Australia/Broken_Hill': 'AU',
    'Australia/Brisbane': 'AU',
    'Australia/Lindeman': 'AU',
    'Australia/Adelaide': 'AU',
    'Australia/Darwin': 'AU',
    'Australia/Perth': 'AU',
    'Australia/Eucla': 'AU',
    'Europe/Brussels': 'BE',
    'Europe/Sofia': 'BG',
    'America/St_Johns': 'CA',
    'America/Halifax': 'CA',
    'America/Glace_Bay': 'CA',
    'America/Moncton': 'CA',
    'America/Goose_Bay': 'CA',
    'America/Toronto': 'CA',
    'America/Iqaluit': 'CA',
    'America/Winnipeg': 'CA',
    'America/Resolute': 'CA',
    'America/Rankin_Inlet': 'CA',
    'America/Regina': 'CA',
    'America/Swift_Current': 'CA',
    'America/Edmonton': 'CA',
    'America/Cambridge_Bay': 'CA',
    'America/Inuvik': 'CA',
    'America/Dawson_Creek': 'CA',
    'America/Fort_Nelson': 'CA',
    'America/Whitehorse': 'CA',
    'America/Dawson': 'CA',
    'America/Vancouver': 'CA',
    'Europe/Zurich': 'CH',
    'Asia/Nicosia': 'CY',
    'Asia/Famagusta': 'CY',
    'Europe/Prague': 'CZ',
    'Europe/Berlin': 'DE',
    'Europe/Tallinn': 'EE',
    'Europe/Madrid': 'ES',
    'Africa/Ceuta': 'ES',
    'Atlantic/Canary': 'ES',
    'Europe/Helsinki': 'FI',
    'Atlantic/Faroe': 'FO',
    'Europe/Paris': 'FR',
    'Europe/London': 'GB',
    'America/Cayenne': 'GF',
    'Europe/Gibraltar': 'GI',
    'America/Nuuk': 'GL',
    'America/Danmarkshavn': 'GL',
    'America/Scoresbysund': 'GL',
    'America/Thule': 'GL',
    'Europe/Athens': 'GR',
    'Europe/Budapest': 'HU',
    'Europe/Dublin': 'IE',
    'Indian/Chagos': 'IO',
    'Europe/Rome': 'IT',
    'Europe/Vilnius': 'LT',
    'Europe/Riga': 'LV',
    'America/Martinique': 'MQ',
    'Europe/Malta': 'MT',
    'Pacific/Noumea': 'NC',
    'Pacific/Norfolk': 'NF',
    'Pacific/Tahiti': 'PF',
    'Pacific/Marquesas': 'PF',
    'Pacific/Gambier': 'PF',
    'Europe/Warsaw': 'PL',
    'America/Miquelon': 'PM',
    'Europe/Lisbon': 'PT',
    'Atlantic/Madeira': 'PT',
    'Atlantic/Azores': 'PT',
    'Europe/Bucharest': 'RO',
    'Europe/Mariehamn': 'AX',
    'Europe/Sarajevo': 'BA',
    'America/St_Barthelemy': 'BL',
    'America/Blanc-Sablon': 'CA',
    'America/Atikokan': 'CA',
    'America/Creston': 'CA',
    'Indian/Cocos': 'CC',
    'Indian/Christmas': 'CX',
    'Europe/Busingen': 'DE',
    'Europe/Copenhagen': 'DK',
    'Europe/Guernsey': 'GG',
    'America/Guadeloupe': 'GP',
    'Europe/Zagreb': 'HR',
    'Europe/Isle_of_Man': 'IM',
    'Europe/Jersey': 'JE',
    'Europe/Vaduz': 'LI',
    'Europe/Luxembourg': 'LU',
    'Europe/Monaco': 'MC',
    'Europe/Podgorica': 'ME',
    'America/Marigot': 'MF',
    'Europe/Amsterdam': 'NL',
    'Europe/Oslo': 'NO',
    'Indian/Reunion': 'RE',
    'Europe/Stockholm': 'SE',
    'Atlantic/St_Helena': 'SH',
    'Europe/Ljubljana': 'SI',
    'Arctic/Longyearbyen': 'SJ',
    'Europe/Bratislava': 'SK',
    'Europe/San_Marino': 'SM',
    'America/Lower_Princes': 'SX',
    'Europe/Vatican': 'VA',
    'Pacific/Wallis': 'WF',
    'Indian/Mayotte': 'YT',
  },
  countryToCurrency: {
    AD: 'EUR',
    AU: 'AUD',
    AT: 'EUR',
    BE: 'EUR',
    BA: 'EUR',
    IO: 'GBP',
    BG: 'EUR',
    CA: 'CAD',
    CX: 'AUD',
    CC: 'AUD',
    HR: 'EUR',
    CY: 'EUR',
    CZ: 'EUR',
    DK: 'EUR',
    EE: 'EUR',
    FO: 'EUR',
    FI: 'EUR',
    FR: 'EUR',
    GF: 'EUR',
    PF: 'EUR',
    DE: 'EUR',
    GI: 'GBP',
    GR: 'EUR',
    GL: 'EUR',
    GP: 'EUR',
    GG: 'GBP',
    HU: 'EUR',
    IE: 'EUR',
    IM: 'GBP',
    IT: 'EUR',
    JE: 'GBP',
    XK: 'EUR',
    LV: 'EUR',
    LI: 'EUR',
    LT: 'EUR',
    LU: 'EUR',
    MT: 'EUR',
    MQ: 'EUR',
    YT: 'EUR',
    MC: 'EUR',
    ME: 'EUR',
    NL: 'EUR',
    NC: 'EUR',
    NF: 'AUD',
    NO: 'EUR',
    PL: 'EUR',
    PT: 'EUR',
    RE: 'EUR',
    RO: 'EUR',
    BL: 'EUR',
    SH: 'GBP',
    MF: 'EUR',
    PM: 'EUR',
    SM: 'EUR',
    SX: 'EUR',
    SK: 'EUR',
    SI: 'EUR',
    ES: 'EUR',
    SJ: 'EUR',
    SE: 'EUR',
    CH: 'EUR',
    GB: 'GBP',
    VA: 'EUR',
    WF: 'EUR',
    AX: 'EUR',
  },
};
