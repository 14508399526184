import { IFilterItem } from '../../models/base-interfaces/store-filters.interface';
import { IOperationSystem } from '../../models/game/interfaces/operation-system.interface';
import { ISystemRequirement } from '../../models/game/interfaces/system-requirement.interface';

export class GameHelper {
  /**
   * @param {number} count - count games
   * @param {number} biggest - the large number in filters list
   * @return {number} - width of progress bar
   */
  static calculateWidth(count: number, biggest: number): number {
    return (count / biggest) * 100;
  }

  /**
   * сheck if game exist in WishList or OwnGames
   * @param {string} gameId
   * @param {any[]} list
   * @returns {boolean}
   */
  static checkGame(gameId: string, list: any[]): boolean {
    const index = list.findIndex((item) => item.id === gameId);
    return index !== -1;
  }

  /**
   * Get class for icon
   * @param {string} name
   * @param {any[]} types (for example: playingModes, features)
   * @returns {string}
   */
  static getCssClassIcon(name: string, types: any[]): string {
    const type = types.find((item) => item.name.toLowerCase().indexOf(name.toLowerCase()) !== -1);
    return type ? type.cssClass : '';
  }

  /**
   * Need for adding or deleting filters id from selected list
   * was created because components with filters have a lot of same logic
   * @param {string[]} selectedValueList - list of names select filters
   * @param {string} filterValue - filter name, which was clicked
   * @return {string[]} - new selected list
   */
  static selectedFilterList(selectedValueList: string[], filterValue: string): string[] {
    const selectedFiltersValues = [...selectedValueList];
    const index = selectedFiltersValues.findIndex((val) => val === filterValue);

    if (index === -1) {
      selectedFiltersValues.push(filterValue);
    } else {
      selectedFiltersValues.splice(index, 1);
    }

    return selectedFiltersValues;
  }

  static initFilterItem(name: string, gamesTotal: number, types: any[]): IFilterItem {
    return {
      name,
      gamesTotal,
      cssClass: GameHelper.getCssClassIcon(name, types),
    };
  }

  /**
   * @param {any[]} data
   * @returns {any[]}
   */
  static sortByProperty(data: any[]): any[] {
    return data.sort((prevValue, nextValue) => (prevValue.number < nextValue.number ? 1 : -1));
  }

  /**
   * need for check game compatibility
   * checking compares OS and bit
   * @param {SystemRequirement[]} systemRequirements
   * @param {IOperationSystem} userOS - name and bit property
   * @return {boolean} - is settings exist and bit right
   */
  static checkCompatibility(systemRequirements: ISystemRequirement[], userOS: IOperationSystem): boolean {
    let isBitCorrect = true;
    const system = systemRequirements.find((systemRequirement: ISystemRequirement) => {
      return systemRequirement.osName.toLowerCase().indexOf(userOS.name.toLowerCase()) > -1;
    });

    if (system && system.minimum) {
      if (system.minimum.os.indexOf('-bit only') > -1) {
        const bit: string = system.minimum.os.match(/(64|32)/g)[0];

        isBitCorrect = userOS.bit.length > 0 ? userOS.bit === bit : true;
      }

      return !!system && isBitCorrect;
    }
    return false;
  }
}
