import { ISystemRequirement } from '../interfaces/system-requirement.interface';

import { Requirement } from './requirement.model';

export class SystemRequirement implements ISystemRequirement {
  osName: string;
  minimum: Requirement;
  recommended: Requirement;

  constructor(data: ISystemRequirement) {
    this.osName = data.osName;
    this.minimum = new Requirement(data.minimum || {});
    this.recommended = new Requirement(data.recommended || {});
  }
}
