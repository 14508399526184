import { gql } from 'apollo-angular';

import { PAGINATION_GQL, USER_PERSONAL_DATA_GQL } from '../shared/shared.query';

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      id
      blockchainId
      blockchainStatus
      deviceSyncStatus
      username
      roles
      professionalData {
        companyId
      }
      personalData {
        email
        countryCurrency
        currencySymbol
        countryCode
      }
    }
  }
`;

export const CURRENT_USER_PROFILE_SETTINGS_QUERY = gql`
  query currentUser {
    currentUser {
      id
      username
      personalData {
        firstname
        lastname
        email
        countryCode
        addresses {
          detail {
            streetName
            streetName2
            state
            zipCode
            country
            city
          }
        }
        billingInformation {
          id
          address {
            streetName
            streetName2
            state
            zipCode
            country
            city
          }
          paymentInformation {
            paymentMethod
          }
          isPrimary
        }
      }
    }
  }
`;

export const CURRENT_USER_INFO_QUERY = gql`
  query currentUser {
    currentUser {
      id
      username
    }
  }
`;

export const USERS_QUERY = gql`
  query users {
    users {
      nodes {
        id
        username
        status
        roles
        personalData {
          firstname
          lastname
          email
        }
      }
      ${PAGINATION_GQL}
    }
  }`;

export const USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $ID) {
      id
      username
      status
      roles
      personalData ${USER_PERSONAL_DATA_GQL}
    }
  }`;

export const GET_USERS_QUERY = gql`
  query users {
    users {
      nodes {
        id
        username
        personalData {
          email
        }
      }
    }
  }
`;

export const GET_ALL_USERS_QUERY = gql`
  query users($pagination: PaginationInput, $orderBy: OrderByInput) {
    users(pagination: $pagination, orderBy: $orderBy) {
      data {
        id
        username
        createdAt
        personalData {
          firstname
          lastname
        }
      }
    }
  }
`;

export const GET_NUMBER_OF_USERS_QUERY = gql`
  query users {
    users {
      totalCount
    }
  }
`;

export const GET_USER_BY_ID_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      username
    }
  }
`;
