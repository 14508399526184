import { CountryCode } from '../../../helpers/country-code.enum';
import { IBillingAddresses } from '../interfaces/user.interface';

export const unitedStatesAddressMock: IBillingAddresses = {
  firstname: 'Jhon',
  lastname: 'Doe',
  countryCode: CountryCode.UNITED_STATES,
  billingInformation: [
    {
      id: 'id',
      address: {
        streetName: 'Main St',
        streetName2: null,
        state: 'Texas',
        zipCode: '78539',
        country: 'United States',
        city: 'Alamo',
      },
      paymentInformation: {
        paymentMethod: '1',
      },
      isPrimary: true,
    },
  ],
};

export const franceAddressMock: IBillingAddresses = {
  firstname: 'Jhon',
  lastname: 'Doe',
  countryCode: 'FR' as CountryCode,
  billingInformation: [
    {
      id: 'id',
      address: {
        streetName: 'Rue de Rivoli',
        streetName2: null,
        state: 'Paris',
        zipCode: '75001',
        country: 'France',
        city: 'Paris',
      },
      paymentInformation: {
        paymentMethod: '1',
      },
      isPrimary: true,
    },
  ],
};
