<div class="d-flex">
  <div class="resale-section me-auto">
    <span class="resale-section__title" i18n>Creator Earnings</span>
    @if (revenueStatus) {
      <span class="resale-section__body">{{ getRevenueShareDisplay() }}</span>
    } @else {
      <span class="resale-section__body">0 %</span>
    }
  </div>
  <div class="resale-section ms-auto">
    <span class="resale-section__title" i18n>Minimum resale price</span>
    <span class="resale-section__body"
      >{{ revenueData.minResellPriceCurrency?.symbol }} {{ revenueData.minResellPrice || 0 | number: '1.0-2' }}</span
    >
  </div>
</div>
