import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';

import { IVideo } from '../../../../../models/multimedia/interfaces';
import { MediaCarouselYoutubeService } from '../../../services/media-carousel-youtube.service';

@Component({
  selector: 'ultra-youtube-expanded-videos',
  templateUrl: './expanded-youtube-videos.component.html',
  styleUrls: ['./expanded-youtube-videos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MediaCarouselYoutubeService],
})
export class ExpandedYoutubeVideosComponent implements OnChanges, OnDestroy {
  @Input() videos: IVideo[];
  @Input() videoWidth: number;
  @Input() videoHeight: number;
  @Input() selectedMedia: IVideo;

  public playerConfig;

  constructor(private mediaYoutubeService: MediaCarouselYoutubeService) {
    this.playerConfig = this.mediaYoutubeService.playerConfig;
  }

  public addPlayerToActiveList(video: YouTubePlayer) {
    this.mediaYoutubeService.addPlayerToActiveList(video);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedMedia?.previousValue?.videoId !== changes?.selectedMedia?.currentValue?.videoId) {
      this.mediaYoutubeService.pauseActiveVideoPlayers();
    }
  }

  public ngOnDestroy(): void {
    this.mediaYoutubeService.destroyYoutubeVideoPlayers();
  }
}
