import { NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AutotestAttributeDirective } from '../..';
import {
  SearchInsightsEventType,
  SearchInsightsObjectType,
} from '../../algolia-search-insights/algolia-search-insights.interface';
import { DomEventsBusClickDirective } from '../../dom-events-bus/dom-events-bus-click.directive';
import { ResponsiveContentDirective } from '../card-animation/directives/responsive-content.directive';

@UntilDestroy()
@Component({
  selector: 'ultra-card-grid',
  templateUrl: 'card-grid.component.html',
  styleUrls: ['./card-grid.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutotestAttributeDirective, DomEventsBusClickDirective, NgTemplateOutlet],
})
export class CardGridComponent implements AfterContentInit {
  readonly SearchInsightsClickEventTypes = SearchInsightsEventType;
  readonly SearchInsightsObjectTypes = SearchInsightsObjectType;

  @Input() uniqs = [];
  @Input() containerFullWidth = false;
  @Input() isTotalResults = true;
  @Input() placeholderMode = false;
  @Input() placeholderRowMode = false;
  @Input({ transform: booleanAttribute }) hiddenPlaceholder = false;

  @Output() elementClick = new EventEmitter<{ data: any; index: number }>();

  @ContentChild(ResponsiveContentDirective, { static: true }) gridContent: ResponsiveContentDirective;

  template: TemplateRef<any>;

  ngAfterContentInit(): void {
    this.template = this.placeholderMode ? null : this.gridContent.template;
  }

  trackBy(_, data) {
    return data && data.id;
  }
}
