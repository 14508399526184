import { IUniq, UniqMetadata, UniqValidMetadata } from '../..';
import { MetadataStatus, UniqFactoryValidMetadata } from '../../uniq-factory/uniq-factory-metadata.interface';

export const transformNullUniqsMetadataToProcessing = (uniqs: IUniq[]): IUniq[] => {
  return uniqs.map((uniq) => ({
    ...uniq,
    metadata: validateMetadata(uniq?.metadata),
  }));
};

export const transformNullUniqMetadataToProcessing = (uniq: IUniq): IUniq => {
  return {
    ...uniq,
    metadata: validateMetadata(uniq?.metadata),
  };
};

const validateMetadata = (metadata: UniqMetadata | null): UniqMetadata => {
  return metadata === null ? { id: metadata.id, status: MetadataStatus.PROCESSING, source: null } : metadata;
};

export const getMetadataInfo = (
  uniq: Partial<IUniq>,
): { name: string; subName: string; status: string; image: string; restricted: boolean } => {
  if (uniq.metadata?.status !== MetadataStatus.INVALID) {
    const { name, subName, medias } = (uniq.metadata as UniqValidMetadata)?.content ||
      (uniq.factory?.metadata as UniqFactoryValidMetadata)?.content || {
        name: uniq.factory?.name,
        subName: uniq.factory?.subName,
        image: uniq.factory?.medias?.square?.uri,
      };
    return {
      name: name === '???' ? uniq.id : name,
      subName: subName === '???' ? 'Uniq ID' : subName,
      status: uniq.metadata?.status ?? uniq.factory?.metadata?.status,
      restricted: uniq.metadata?.restricted ?? uniq.factory?.metadata?.restricted,
      image: medias?.square?.uri,
    };
  } else {
    return {
      name: uniq.id,
      subName: 'Uniq ID',
      status: uniq.metadata?.status ?? uniq.factory?.metadata?.status,
      restricted: uniq.metadata?.restricted ?? uniq.factory?.metadata?.restricted,
      image: uniq.factory?.medias?.square?.uri,
    };
  }
};
