import { environment } from './environment';
import { IEnvironment } from './interfaces';

/**
 * Allows changes of ENV in running Docker image,
 * so, first Angular will checked environment.json, if it's empty - will return default ENV from configurations
 * @param url - route for the file with the env variables
 * @return {Promise<any>} - resolve IEnvironment
 */
export class EnvironmentLoaderHelper {
  private static instance: EnvironmentLoaderHelper;

  private configURL = './assets/environment.json';
  private _appConfig: IEnvironment;

  static getInstance() {
    if (!EnvironmentLoaderHelper.instance) {
      EnvironmentLoaderHelper.instance = new EnvironmentLoaderHelper();
    }
    return EnvironmentLoaderHelper.instance;
  }

  static appConfigFactory() {
    return EnvironmentLoaderHelper.getInstance().appConfig;
  }

  get appConfig(): IEnvironment {
    return this._appConfig;
  }

  set appConfig(value: IEnvironment) {
    if (this._appConfig) {
      throw new Error('AppConfig data already defined');
    }
    this._appConfig = value;
  }

  loadConfig() {
    return fetch(this.configURL)
      .then((response) => response.json())
      .then((response) => {
        this._appConfig = { ...environment, ...response };
        return this._appConfig;
      });
  }
}
