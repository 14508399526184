import { Injectable } from '@angular/core';
import { applyChange, observableDiff } from 'deep-diff';

@Injectable()
export class ManageFormDiffService {
  // Todo refactoring
  private _diff = {};

  get diff() {
    return this._diff;
  }

  set diff(value) {
    this._diff = value;
  }

  get formChanged(): boolean {
    return !!Object.keys(this.diff).length;
  }

  public compareChanges(value, initValue) {
    let diff = {};
    const keys = Object.keys(value);
    const isError = keys.find((key) => key === 'errors');
    if (isError) {
      return (diff = { ...value });
    }
    if (!initValue) {
      diff = { ...value };
    } else {
      observableDiff(initValue, value, (d) => applyChange(diff, value, d));
    }
    return diff;
  }
}
