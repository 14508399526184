import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult, OperationVariables } from '@apollo/client';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, MutationResult, TypedDocumentNode } from 'apollo-angular';
import { Observable } from 'rxjs';

import { AuthEventsService } from '../../services/auth/auth-events.service';
import { ApolloClientName } from '../enums';

interface IGetQueryOptions {
  cache?: boolean;
  apolloClient?: string;
}

interface ICreateMutationOptions {
  update?: any;
  apolloClient?: string;
}

interface ICreateSubscriptionOptions {
  cache?: boolean;
  apolloClient?: string;
}

@UntilDestroy()
@Injectable()
export class ApiGraphQLService {
  constructor(
    private apollo: Apollo,
    private authEventsService: AuthEventsService,
  ) {
    this.authEventsService.logout$.pipe(untilDestroyed(this)).subscribe(() => {
      this.clearStore();
    });
  }

  getQuery<TData = any, TVariables = OperationVariables>(
    queryData: TypedDocumentNode,
    variables?: TVariables,
    options?: IGetQueryOptions,
  ): Observable<ApolloQueryResult<TData>> {
    const defaultOptions: IGetQueryOptions = {
      cache: true,
      apolloClient: 'default',
    };
    const { cache, apolloClient } = { ...defaultOptions, ...options };
    return this.apollo.use(apolloClient).query<TData, TVariables>({
      query: queryData,
      variables,
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  createMutation<TData = any, TVariables = OperationVariables>(
    queryData: TypedDocumentNode,
    variables: TVariables,
    options?: ICreateMutationOptions,
  ): Observable<MutationResult<TData>> {
    const defaultOptions: ICreateMutationOptions = { apolloClient: 'default' };
    const { update, apolloClient } = { ...defaultOptions, ...options };
    return this.apollo.use(apolloClient).mutate<TData, TVariables>({
      mutation: queryData,
      variables,
      update,
    });
  }

  getSubscription<TData = any, TVariables = OperationVariables>(
    queryData: TypedDocumentNode,
    variables?: TVariables,
    options?: ICreateSubscriptionOptions,
  ): Observable<FetchResult<TData>> {
    const defaultOptions: ICreateSubscriptionOptions = {
      cache: false,
      apolloClient: 'default',
    };
    const { cache, apolloClient } = { ...defaultOptions, ...options };
    return this.apollo.use(apolloClient).subscribe<TData, TVariables>({
      query: queryData,
      variables,
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    });
  }

  clearStore(): Promise<any> {
    const clearStorePromises = Object.values(ApolloClientName).map((clientName) => {
      const apolloClient = this.apollo.use(clientName).client;
      apolloClient.stop();
      return apolloClient.clearStore();
    });

    const defaultClient = this.apollo.client;
    defaultClient.stop();
    clearStorePromises.push(defaultClient.clearStore());

    return Promise.all(clearStorePromises);
  }
}
