import { gql } from 'apollo-angular';

export const CURRENCIES_BY_TOKEN_FACTORY_ID_QUERY = gql`
  query findCurrenciesByTokenFactoryId($id: ID!, $freeToPlay: Boolean) {
    findCurrenciesByTokenFactoryId(id: $id, freeToPlay: $freeToPlay) {
      currencyId
      currency
      amount
      name
      symbol
      isRequired
    }
  }
`;
