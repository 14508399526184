import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[ultraSelectOption]',
})
export class SelectOptionDirective implements AfterViewInit {
  @Input('ultraSelectOption')
  optionValue: string;
  private _offsetTop: number;
  private _scrollHeight: number;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this._offsetTop = this.el.nativeElement.offsetTop;
    this._scrollHeight = this.el.nativeElement.scrollHeight;
  }

  get offsetTop(): number {
    return this._offsetTop;
  }

  get scrollHeight(): number {
    return this._scrollHeight;
  }
}
