import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

export class IdCardContentDatasource implements DataSource<any> {
  private data$ = new BehaviorSubject<any>([]);

  constructor(data) {
    this.data$.next(data);
  }

  connect(_collectionViewer: CollectionViewer): Observable<any[] | ReadonlyArray<any>> {
    return this.data$.asObservable();
  }

  disconnect(_collectionViewer: CollectionViewer): void {
    this.data$.complete();
  }
}
