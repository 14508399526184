<div class="errors">
  @if (errorData) {
    <div class="errors__container">
      <div class="errors__logo">
        <i class="icon icon-ultra-logo-32"></i>
      </div>
      @if (errorData.title) {
        <div class="errors__title" i18n>{{ errorData.title }}</div>
      }
      @if (errorData.suggestions && errorData.suggestions.length !== 0) {
        <div class="errors__suggestions">
          @for (suggestion of errorData.suggestions; track suggestion) {
            <ng-container i18n>{{ suggestion.title }}</ng-container>
            <br />
          }
        </div>
      }
      @if (errorData.actions && errorData.actions.length !== 0) {
        <div class="errors__actions-wrap">
          @for (action of errorData.actions; track action) {
            @if (action.type === 'link') {
              <a
                class="errors__action btn btn-primary errors__action--{{ action.cssClass }}"
                [routerLink]="action.href">
                <ng-container i18n>{{ action.title }}</ng-container>
              </a>
            }
            @if (action.type === 'externalLink') {
              <a class="errors__action btn btn-primary errors__action--{{ action.cssClass }}" [href]="action.href">
                <ng-container i18n>{{ action.title }}</ng-container>
              </a>
            }
            @if (action.type === 'button') {
              <button class="errors__action errors__action--side btn btn-primary" type="button">
                <ng-container i18n>{{ action.title }}</ng-container>
              </button>
            }
          }
        </div>
      }
    </div>
  }
</div>
