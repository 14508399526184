@for (video of videos; track video) {
  @if (video.provider === 'youtube') {
    <youtube-player
      class="video-player"
      [class.active]="selectedMedia !== video"
      [id]="'video-player-' + video.videoId"
      [videoId]="video.videoId"
      [playerVars]="playerConfig"
      [height]="videoHeight"
      [width]="videoWidth"
      (ready)="addPlayerToActiveList($event.target)" />
  }
}
