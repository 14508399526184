import { IdCardPanelDispatch, IdCardPropertyTabs } from '../providers/id-card-panel-dispatcher.service';

export class IdCardPanelTransformerHelper {
  public static transform(state: IdCardPropertyTabs, propertyTabIndex = 3): IdCardPanelDispatch {
    if (!state) {
      return { tabNumber: null, propertyName: null };
    }
    const uniqStates: { [key: string]: IdCardPanelDispatch } = {
      overview: { tabNumber: 0 },
      attributes: { tabNumber: 1, propertyName: 'attributes' },
      medias: { tabNumber: 2, propertyName: 'medias' },
      tradability: { tabNumber: propertyTabIndex, propertyName: 'tradability' },
      transferability: { tabNumber: propertyTabIndex, propertyName: 'transferability' },
      quantity: { tabNumber: propertyTabIndex, propertyName: 'quantity' },
      marketplace: { tabNumber: 4 },
    };

    if (propertyTabIndex !== 3) {
      const { attributes, ...factoryStates } = uniqStates;
      return factoryStates[state];
    }

    return uniqStates[state];
  }
}
