import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { IUserRoles } from '../permission/interfaces/user-roles.interface';
import { PermissionService } from '../permission/permission.service';

@Injectable()
export class UltraAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionService: PermissionService,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.permissionService.userRoles$.pipe(
      filter((permissions) => !!permissions),
      take(1),
      map((permissions: IUserRoles) => {
        if (permissions.ultraAdmin) {
          return true;
        }
        this.router.navigate(['/403']);
        return false;
      }),
    );
  }
}
