import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { EnvironmentLoaderHelper } from '@ultra/environment';

import { AppModule } from './app/app.module';

EnvironmentLoaderHelper.getInstance()
  .loadConfig()
  .then(() => platformBrowserDynamic().bootstrapModule(AppModule))
  .catch((err) => console.error(err));
