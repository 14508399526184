import {
  carouselSingleSlideConfig,
  carouselSlideShowConfig,
  gameDetailConfig,
  relatedGamesConfig,
  sliderConfig,
  slideShowConfig,
  suggestedGamestConfig,
  tokenPreviewConfig,
  uniqueDetailConfig,
  uniqueFactoryDetailConfig,
  wishlistConfig,
} from './slider.config';
import { SliderTheme } from './slider.interface';

export const getConfigByTheme = (theme: SliderTheme) => {
  let config;
  switch (theme) {
    case 'carousel': {
      config = sliderConfig;
      break;
    }
    case 'slideshow': {
      config = slideShowConfig;
      break;
    }
    case 'game-detail': {
      config = gameDetailConfig;
      break;
    }
    case 'uniq-factory-detail': {
      config = uniqueFactoryDetailConfig;
      break;
    }
    case 'wishlist': {
      config = wishlistConfig;
      break;
    }
    case 'related-games': {
      config = relatedGamesConfig;
      break;
    }
    case 'suggested-games': {
      config = suggestedGamestConfig;
      break;
    }
    case 'token-preview': {
      config = tokenPreviewConfig;
      break;
    }
    case 'uniq-detail': {
      config = uniqueDetailConfig;
      break;
    }
    case 'carousel-single-slide': {
      config = carouselSingleSlideConfig;
      break;
    }
    case 'carousel-slideshow': {
      config = carouselSlideShowConfig;
      break;
    }
  }
  return config;
};
