export enum BtnSize {
  lg = 'lg',
  sm = 'sm',
}

export enum PriceType {
  uos = 'uos',
  usd = 'usd',
  eur = 'eur',
  gbp = 'gbp',
  cad = 'cad',
  aud = 'aud',
}

export enum ConversationRateType {
  fromFiatToUos = 'fromFiatToUos',
  fromUosToFiat = 'fromUosToFiat',
}

export enum PriceTheme {
  light = 'light',
  dark = 'dark',
}
