import { UserCurrency } from '@ultra/core/models';

import { getEbaName } from '../../helpers/uniq-eba-name.helper';
import { ITransferability } from '../../models/token-factory/interfaces/transferability.interface';
import { ExchangeDate, UniqTradability } from '../../models/uniq/interfaces/uniq.interface';
import { IUniqFactory } from '../uniq-factory.interface';

export interface ExchangeOptions {
  readonly enabled?: boolean;
  readonly exchangeStartDate?: ExchangeDate;
  readonly exchangeEndDate?: ExchangeDate;
}

export interface ExchangePeriod {
  readonly mintDate: Date;
  readonly startDate: Date;
  /**
   * undefined means infinite
   */
  readonly endDate?: Date | null;
  /**
   * undefined if infinite
   */
  readonly duration?: number | null;
}

export interface ExchangeAvailability {
  readonly isLocked: boolean;
  /**
   * number of millis before availability if locked or else end of availability, undefined if always available
   */
  readonly duration?: number | null;
  /**
   * rate before availability if locked or else end of availability, undefined if always available
   */
  readonly durationRate?: number | null;
}

const defaultPriceToDisplay = { code: 'USD', symbol: '$' };

function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export class AlgoliaUniqFactoryAdapter {
  static transform(data, userCurrency: UserCurrency): Partial<IUniqFactory> {
    let priceToDisplay = defaultPriceToDisplay;
    const isCurrencyAllowed = data.price_currencies.includes(userCurrency.countryCurrency);
    if (isCurrencyAllowed) {
      priceToDisplay = { code: userCurrency.countryCurrency, symbol: userCurrency.currencySymbol };
    }
    const priceField = `price_${priceToDisplay.code}`;

    return {
      id: data.objectID,
      onChainId: data.on_chain_id,
      name: data.name,
      subName: data.subName,
      description: data.description,
      type: data.type,
      creator: {
        blockchainId: data.creator_blockchain_id,
        eba: {
          name: getEbaName(data.creator_blockchain_id),
          accountId: null,
          type: null,
        },
        verified: null,
      },
      createdAt: data.created_at,
      hidden: data.launchpad_hidden,
      medias: {
        product: {
          uri: data.product_uri,
          contentType: data.product_media_type,
        },
        square: { uri: data.image },
        gallery: [],
        hero: null,
      },
      prices: [
        {
          currency: {
            symbol: priceToDisplay.symbol,
            code: priceToDisplay.code,
          },
          amount: data[priceField],
        },
      ],
      quantity: {
        quantityLeft: data.quantity_left === -1 && data.max_mintable_tokens === -1 ? null : `${data.quantity_left}`,
        maxMintableTokens:
          data.max_mintable_tokens === -1 && data.quantity_left === -1 ? null : `${data.max_mintable_tokens}`,
        // Currently we don't have data representing maxCirculatingSupply in Algolia, so this is added as a temporary solution acknowledging the lack of precision.
        maxCirculatingSupply:
          data.max_mintable_tokens === -1 && data.quantity_left === -1 ? null : `${data.max_mintable_tokens}`,
        existing: null,
        minted: null,
      },
      tradability: AlgoliaUniqFactoryAdapter.transformTradability(data),
      transferability: AlgoliaUniqFactoryAdapter.transformTransferability(data),
    };
  }

  static transformTradability(data): UniqTradability | undefined {
    const enabled = data?.trading_window_end !== 0;
    const exchangeOptions: ExchangeOptions = {
      enabled,
      exchangeStartDate: AlgoliaUniqFactoryAdapter.formatExchangeDateOptions(data?.trading_window_start),
      exchangeEndDate: AlgoliaUniqFactoryAdapter.formatExchangeDateOptions(data?.trading_window_end),
    };

    const exchangePeriod = AlgoliaUniqFactoryAdapter.getExchangePeriod(exchangeOptions);
    const exchangeAbility = AlgoliaUniqFactoryAdapter.getExchangeAvailability(exchangePeriod);

    return {
      enabled,
      tradingPeriod: exchangePeriod
        ? {
            mintDate: exchangePeriod.mintDate?.toISOString(),
            startDate: exchangePeriod.startDate?.toISOString(),
            endDate: exchangePeriod.endDate?.toISOString(),
            duration: exchangePeriod.duration?.toString(),
            resaleAvailability: exchangeAbility
              ? {
                  isLocked: exchangeAbility.isLocked,
                  duration: `${exchangeAbility.duration}`,
                  durationRate: exchangeAbility.durationRate,
                  resaleDate: new Date().toISOString(),
                }
              : null,
          }
        : null,
    };
  }

  static transformTransferability(data): ITransferability | undefined {
    const enabled = data?.transfer_window_end !== 0;
    const exchangeOptions: ExchangeOptions = {
      enabled,
      exchangeStartDate: AlgoliaUniqFactoryAdapter.formatExchangeDateOptions(data?.transfer_window_start),
      exchangeEndDate: AlgoliaUniqFactoryAdapter.formatExchangeDateOptions(data?.transfer_window_end),
    };

    const exchangePeriod = AlgoliaUniqFactoryAdapter.getExchangePeriod(exchangeOptions);
    const exchangeAbility = AlgoliaUniqFactoryAdapter.getExchangeAvailability(exchangePeriod);

    return {
      enabled,
      transferStartDate: exchangeOptions.exchangeStartDate,
      transferEndDate: exchangeOptions.exchangeEndDate,
      transferPeriod: exchangePeriod
        ? {
            mintDate: exchangePeriod.mintDate?.toISOString(),
            startDate: exchangePeriod.startDate?.toISOString(),
            endDate: exchangePeriod.endDate?.toISOString(),
            duration: exchangePeriod.duration?.toString(),
            transferAvailability: exchangeAbility
              ? {
                  isLocked: exchangeAbility.isLocked,
                  duration: `${exchangeAbility.duration}`,
                  durationRate: exchangeAbility.durationRate,
                  transferDate: new Date().toISOString(),
                }
              : null,
          }
        : null,
    };
  }

  private static formatExchangeDateOptions(milliseconds: number): ExchangeDate | null {
    if (!milliseconds) {
      return null;
    }

    return {
      afterNumberDays: null,
      onSpecificDay: new Date(milliseconds).toISOString(),
    };
  }

  private static getExchangePeriod(exchange: ExchangeOptions): ExchangePeriod | undefined {
    if (!exchange?.enabled) {
      return;
    }

    // assuming that mintDate (purchaseDate) is today
    const mintDate = new Date();

    let startDate = mintDate;
    let endDate: Date | undefined;
    let duration: number | undefined;

    if (isDefined(exchange?.exchangeStartDate?.onSpecificDay)) {
      startDate = new Date(exchange?.exchangeStartDate?.onSpecificDay);
    }

    if (isDefined(exchange?.exchangeEndDate?.onSpecificDay)) {
      endDate = new Date(exchange?.exchangeEndDate.onSpecificDay);
      duration = endDate.getTime() - startDate.getTime();
    }

    if (duration !== undefined && duration <= 0) {
      // Trading period duration must be greater than 0
      return;
    }

    return {
      mintDate,
      startDate,
      endDate,
      duration,
    };
  }

  private static getExchangeAvailability(period: ExchangePeriod): ExchangeAvailability | undefined {
    if (!period) {
      return;
    }

    const transferTime = new Date().getTime();
    const startTime = period.startDate.getTime();
    const endTime = period.endDate ? period.endDate.getTime() : undefined;
    if (isDefined(endTime) && endTime <= transferTime) {
      // Cannot resell out of trading period
      return;
    }

    const isLocked: boolean = transferTime < startTime;
    let duration: number | undefined;
    let durationRate: number | undefined;

    if (isLocked) {
      duration = startTime - transferTime;
      durationRate = Math.floor((duration / (startTime - transferTime)) * 100);
    } else if (isDefined(endTime)) {
      duration = endTime - transferTime;
      durationRate = Math.floor((duration / (endTime - startTime)) * 100);
    }

    return {
      isLocked,
      duration,
      durationRate,
    };
  }
}
