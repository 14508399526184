import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControlStatus,
  NgControl,
  UntypedFormControl,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ControlErrorDirective } from '../../../modules/form-error/control-error.directive';
import { UltraValidators } from '../../../services/validators/validators.service';
import { TagModel } from '../models/tag-model';
import { TagComponent } from '../tag/tag.component';

export function isObject(obj: any): boolean {
  return obj === Object(obj);
}

@UntilDestroy()
@Component({
  selector: 'ultra-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TagInputComponent implements ControlValueAccessor, OnInit {
  @Input() inputClass: string;
  @Input() inputDisabled = false;
  @Input() inputId = 'tag-input-id';
  @Input() displayBy = 'display';
  @Input() identifyBy = 'value';
  @Input() trimTags = true;
  @Input() primaryPlaceHolder = 'Enter Uniq Factory onChainIds here';
  @Input() secondaryPlaceholder = '+ ID';
  @Input() allowDupes = false;
  @Input() blinkIfDupe = true;
  @Input() maxItems = 18;
  @Input() validationError = 'mongoIdError';
  @Input() divider = ',';

  @Output() removed = new EventEmitter<TagModel>();
  @Output() created = new EventEmitter<TagModel>();

  @ViewChild('input', { read: ElementRef })
  public inputElement: ElementRef;

  @ViewChildren(TagComponent)
  public tagElements: QueryList<TagComponent>;

  public tagControl = new UntypedFormControl({ value: '', disabled: this.inputDisabled }, UltraValidators.required);
  public isInvalid = false;
  public isFocused = false;

  private _tags: TagModel[] = [];
  private _onTouchedCallback: () => void;
  private _onChangeCallback: (items: TagModel[]) => void;
  public displayTags: TagModel[] = [];

  public get tags(): TagModel[] {
    return this._tags;
  }

  public set tags(val: TagModel[]) {
    this._tags = val;
    this._onChangeCallback(this._tags);
  }

  public get maxItemsReached(): boolean {
    return this.maxItems !== undefined && this.tags.length >= this.maxItems;
  }

  public get leftCount(): number {
    const leftCount = this.maxItems - this.tags.length;
    return leftCount >= 0 ? leftCount : 0;
  }

  public onTouched() {
    this._onTouchedCallback();
  }

  public writeValue(items: any[]) {
    this._tags = items || [];
  }

  public registerOnChange(fn: any) {
    this._onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this._onTouchedCallback = fn;
  }

  get placeHolder(): string {
    return this.tags.length === 0 ? this.primaryPlaceHolder : this.secondaryPlaceholder;
  }

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    @Self() @Optional() private error: ControlErrorDirective,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    this.ngControl && (this.ngControl.valueAccessor = this);
  }

  ngOnInit(): void {
    this.listenStatusChanges();
  }

  @HostListener('document:click', ['$event.target'])
  onclick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (clickedInside && !this.isFocused) {
      this.isFocused = true;
      this.focus();
    } else if (clickedInside && this.isFocused) {
      this.focus();
    } else if (!clickedInside && this.isFocused) {
      this.isFocused = false;
      this.blur();
    }
  }

  public remove(model: TagModel, index: number) {
    this.tags = this.tags.filter((item, position) => position !== index);
    this.removed.emit(model);
    this.cdr.detectChanges();
    this.focus();
    this.isFocused = true;
  }

  public create($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    const text = $event.target.value;
    if (!text) {
      return;
    }
    const tags: TagModel[] = this.createTagModel(text);
    tags.forEach((tag) => {
      if (this.isDuplicateValid(tag)) {
        this.appendTag(tag);
      }
    });
    this.resetTagControl();
  }

  private isDuplicateValid(tag: TagModel): boolean {
    const dupe = this.findDuplicate(tag);
    if (!this.allowDupes && dupe && this.blinkIfDupe) {
      const tagElement = this.tagElements.find((item) => {
        return this.getItemValue(item.model) === this.getItemValue(dupe);
      });

      if (tagElement) {
        tagElement.blink();
      }
    }
    return !dupe || this.allowDupes;
  }

  private findDuplicate(tag: TagModel): TagModel | undefined {
    const id = tag[this.identifyBy];
    return this.tags.find((item) => this.getItemValue(item) === id);
  }

  private createTagModel(value: string): TagModel[] {
    const tags = value.split(this.divider).join('$').split(' ').join('$').split('$');
    return tags
      .filter((tag) => tag.trim().length)
      .map((tag) => ({
        [this.displayBy]: this.trimTags ? tag.trim() : tag,
        [this.identifyBy]: this.trimTags ? tag.trim() : tag,
      }));
  }

  private appendTag(tag: TagModel): void {
    this.tags = [...this.tags, tag];
    this.created.emit(tag);
  }

  private getItemValue(item: TagModel): string {
    const property = this.identifyBy;
    return isObject(item) ? item[property] : item;
  }

  private resetTagControl(): void {
    this.tagControl.setValue('', { emitEvent: true });
    this.focus();
  }

  private focus(): void {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  private blur(): void {
    this.onTouched();
    if (this.inputElement) {
      this.inputElement.nativeElement.blur();
    }
  }

  private listenStatusChanges(): void {
    this.control.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe((status: FormControlStatus) => this.updateTagsInvalidState(status));
  }

  private updateTagsInvalidState(status: FormControlStatus): void {
    if (status === 'INVALID') {
      if (this.control.hasError(this.validationError)) {
        const invalidTags = this.control.getError(this.validationError);
        this.displayTags = this.tags.map((tag) => (invalidTags.includes(tag) ? { ...tag, invalid: true } : tag));
      } else {
        this.displayTags = [...this.tags];
      }
      this.error.showError();
      this.inputDisabled = true;
      return;
    }
    this.displayTags = [...this.tags];
    this.inputDisabled = false;
    this.error.hideError();
  }
}
