import { FactorySansProvider, InjectionToken, Type, ValueSansProvider } from '@angular/core';

import { DEFAULT_CONTROL_ERRORS } from './default-control-errors';
import { ControlErrorComponentInterface } from './types/control-error-component.interface';
import { ErrorsMap } from './types/errors-map.type';

export interface ErrorsUseValue extends ValueSansProvider {
  useValue: ErrorsMap;
}

export interface ErrorsUseFactory extends FactorySansProvider {
  useFactory: (...args: any[]) => ErrorsMap;
}

export type ErrorsProvider = ErrorsUseValue | ErrorsUseFactory;

export interface FormErrorsConfig {
  errors?: ErrorsProvider;
  blurPredicate?: (element: Element) => boolean;
  controlErrorComponent?: Type<ControlErrorComponentInterface>;
  controlErrorComponentAnchorFn?: (hostElement: Element, errorElement: Element) => () => void;
}

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'platform',
  factory: () => {
    return DEFAULT_CONTROL_ERRORS;
  },
});

export const FormErrorsConfigProvider = new InjectionToken<FormErrorsConfig>('FormErrorsConfigProvider');
