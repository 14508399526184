import { ChangeDetectorRef, Directive, HostListener, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RadioGroupComponent } from './radio-group.component';

@Directive({
  selector: '[ultraRadioItem]',
  exportAs: 'ultraRadioItem',
})
export class RadioItemDirective implements OnInit, OnDestroy {
  @Input()
  public value: any;
  @Input()
  public checked = false;
  @Input()
  public preventUnselect = false;

  private until$: Subject<void> = new Subject<void>();
  private _disabled = false;

  @Input()
  public get disabled() {
    if (!this.radio) {
      return false;
    }
    return this.radio.disabled || this._disabled;
  }

  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  constructor(@Optional() private radio: RadioGroupComponent, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (!this.radio) {
      return;
    }
    this.radio.registerRadio(this);
    this.radio.radioChanges$.pipe(takeUntil(this.until$)).subscribe((val) => {
      this.checked = val === this.value;
      this.changeDetector.markForCheck();
    });
  }

  @HostListener('click')
  public onClick() {
    let value = this.value;
    if (!this.disabled) {
      if (!this.preventUnselect && this.radio.value === value) {
        value = null;
      }
      this.radio.value = value;
      this.changeDetector.markForCheck();
    }
  }

  public ngOnDestroy(): void {
    this.until$.next();
    this.until$.complete();
  }
}
