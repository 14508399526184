export interface IDownloadRepository {
  repositoryId: string;
  branch: string;
  parentRepositoryId: string;
  action: string;
  accessToken: string;
}

export enum RepositoryStates {
  INSTALLED = 'installed',
  DOWNLOADING = 'downloading',
  UPDATING = 'updating',
  UNINSTALLING = 'uninstalling',
  REPAIRING = 'repairing',
  NONE = 'none',
  UPDATABLE = 'updatable',
  FAILED = 'failed',
  NO_BUILD = 'no_core_builds',
}

export interface IRepositoriesVersion {
  [key: string]: number;
}

export interface IRepositoriesToUpdate {
  [key: string]: boolean;
}
