import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[ultraCurrencyInput]',
})
export class CurrencyInputDirective {
  @Input()
  min = 0.01;

  @Input()
  max = 9007199254740991;

  private invalidChars = ['e', 'E', '+', '-'];
  private specialKeys: string[] = [
    'Backspace',
    'Ctrl',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  public keyDownHandler(e: KeyboardEvent): void {
    if (this.invalidChars.includes(e.key)) {
      e.preventDefault();
      return;
    }
    if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
      return;
    }

    if (this.specialKeys.indexOf(e.key) !== -1) {
      return;
    }

    const currentValue: string = this.elementRef.nativeElement.value.includes('.')
      ? this.elementRef.nativeElement.value
      : Number(this.elementRef.nativeElement.value).toString();
    const regex = new RegExp(/^\d*(\.\d{0,8})?$/g);
    const position = this.elementRef.nativeElement.selectionStart;
    const next: string =
      position !== 0
        ? [currentValue.slice(0, position), e.key === 'Decimal' ? '.' : e.key, currentValue.slice(position)].join('')
        : e.key.toString();
    if (next && !regex.test(next)) {
      e.preventDefault();
      return;
    }
    this.checkMinLengthConditions(next, e);
  }

  @HostListener('paste', ['$event'])
  pasteHandler(e: ClipboardEvent) {
    const value = e.clipboardData.getData('Text');
    const pattern = new RegExp(/^\d*(\.\d{0,8})?$/g);
    if (!pattern.test(value)) {
      e.preventDefault();
    }
    this.checkMinLengthConditions(value, e);
  }

  private checkMinLengthConditions(value: string, e: ClipboardEvent | KeyboardEvent): void {
    const minLength = `${this.min}`.split('.')[1]?.length;
    const valueLength = value.split('.')[1]?.length;
    if (Number(value) > this.max || (Number(value) < this.min && minLength < valueLength)) {
      e.preventDefault();
      return;
    }
  }
}
