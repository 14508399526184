export enum TokenStatus {
  NOT_PUBLISHED = 'NOT_PUBLISHED',
  NOT_ACCESSIBLE = 'NOT_ACCESSIBLE',
  PUBLISHED = 'PUBLISHED',
}

export enum TokenFactoryContentType {
  ALL = 'All',
  GAME = 'game',
  DEMO = 'demo',
  DLC = 'DLC',
  LANGUAGE_PACK = 'Language Pack',
  DEDICATED_SERVER = 'Dedicated Server',
}
