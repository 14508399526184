import { Hit, MultipleQueriesResponse, SearchResponse } from '@algolia/client-search';

import { UserCurrency } from '@ultra/core/models';

import { ITokenFactoriesByGame, ITokenFactory, ITokenPrice } from '../../models';

export function algoliaSingleResponseAdapter(
  response: MultipleQueriesResponse<AlgoliaGameFactoryModel>,
  userCurrency: UserCurrency,
): ITokenFactory {
  return algoliaResponseAdapter(response.results[0]['hits'], userCurrency)[0];
}

export function algoliaResponseAdapter(
  hits: Hit<AlgoliaGameFactoryModel>[],
  userCurrency: UserCurrency,
): ITokenFactory[] {
  return hits
    .filter((hit) => hit.freeToPlay || hit.prices.some((price) => price.currency === userCurrency.countryCurrency))
    .map((hit) => ({
      ...hit,
      livePrices: adaptPrices(hit.prices, userCurrency),
      exclusiveAccess: hit.exclusiveAccess && {
        ...hit.exclusiveAccess,
        requiredAmount: hit.exclusiveAccess.requiredAmount || null,
      },
    }));
}

export function algoliaResponseArrayAdapter(
  response: MultipleQueriesResponse<AlgoliaGameFactoryModel>,
  userCurrency: UserCurrency,
): ITokenFactoriesByGame {
  const results = response.results[0] as SearchResponse<AlgoliaGameFactoryModel>;
  const nodes = algoliaResponseAdapter(results.hits, userCurrency);
  return {
    nodes,
    totalCount: response.results[0]['nbHits'],
    pagination: {
      skip: results.offset,
      limit: results.length,
    },
  };
}

export function adaptPrices(prices: AlgoliaGameFactoryModel['prices'], userCurrency: UserCurrency): ITokenPrice[] {
  const price = prices.find((price) => price.currency === userCurrency.countryCurrency);

  if (!price) {
    return [];
  }

  return [
    {
      ...price,
      discount: price.discountBasisPoint,
      discountEndDate: price.discountEndDate && new Date(price.discountEndDate).toISOString(),
    },
  ];
}

export interface AlgoliaGameFactoryModel {
  gameId: string;
  id: string;
  onChainId: number;
  name: string;
  description: string;
  contentType: {
    id: string;
    code: string;
    icon: string;
    name: string;
  };
  image: {
    images: Array<{
      width: number;
      height: number;
      url: string;
    }>;
  };
  languages: {
    audio: Array<string>;
    subtitles: Array<string>;
    interfaces: Array<string>;
  };
  multimedia: {
    backGroundImage: {
      images: Array<{
        width: number;
        height: number;
        url: string;
      }>;
    };
    imageGalleryList: Array<{
      images: Array<{
        width: number;
        height: number;
        url: string;
      }>;
    }>;
  };
  freeToPlay: boolean;
  prices: Array<{
    currency: string;
    symbol: string;
    originalAmount: number;
    amount: number;
    discountBasisPoint: number;
    discountEndDate: string;
  }>;
  objectID: string;
  exclusiveAccess: {
    uniqFactoryKeyIds: string[];
    requiredAmount: number | null;
  };
}
