import { DecimalPipe } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ICurrency, IResellRevenueShare } from '../../../../../models';

export type IRevenueStatus = 'fixedAmount' | 'percentage';

export interface ITradabilityRevenue {
  revenue: IResellRevenueShare;
  minResellPrice: string;
  minResellPriceCurrency: ICurrency;
}

@Component({
  selector: 'ultra-tradability-resale',
  templateUrl: './tradability-resale.component.html',
  styleUrls: ['./tradability-resale.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DecimalPipe],
  standalone: true,
})
export class TradabilityResaleComponent implements AfterContentInit {
  @Input()
  revenueData: ITradabilityRevenue | null;

  revenueStatus: IRevenueStatus | null;

  ngAfterContentInit() {
    this.revenueStatus = this.getRevenueStatus();
  }

  getRevenueShareDisplay(): string {
    return this.revenueStatus === 'fixedAmount'
      ? `$ ${this.revenueData.revenue[this.revenueStatus]}`
      : `${this.revenueData.revenue[this.revenueStatus]} %`;
  }

  private getRevenueStatus(): IRevenueStatus | null {
    if (!this.revenueData.revenue) {
      return null;
    }

    return this.revenueData.revenue.fixedAmount ? 'fixedAmount' : 'percentage';
  }
}
