import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { GameDetail } from '../../../../models/game/models/game-detail.model';
import { GameDetailBackgroundService } from '../../services/game-detail-background.service';

@Component({
  selector: 'ultra-game-detail-background-container',
  templateUrl: './game-detail-background-container.component.html',
  styleUrls: ['game-detail-background-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailBackgroundContainerComponent {
  private backgroundService = inject(GameDetailBackgroundService);
  backgroundImage: string;

  @Input()
  isRouteChange = false;

  @Input()
  set game(game: GameDetail) {
    this.backgroundImage = this.backgroundService.getImage(game);
  }
}
