import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { AutotestAttributeDirective } from '../../../autotests/autotest-attribute.directive';
import { IdCardEntity } from '../adapters';

@Component({
  selector: 'ultra-id-card-serial-number',
  templateUrl: './id-card-serial-number.component.html',
  styleUrls: ['./id-card-serial-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, RouterLink, AutotestAttributeDirective],
})
export class IdCardSerialNumberComponent {
  @Input() idCardEntity: IdCardEntity;
  @Input() isUniqDetailsAvailable: boolean;
}
