import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NgbDateNativeAdapter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../autotests';
import { ScrollDirective } from '../../directives/scroll/scroll.directive';
import { SharedPipesModule } from '../../pipes/pipes.module';
import { FormErrorModule } from '../form-error/form-error.module';

import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { InputComponent } from './components/input/input.component';
import { InputErrorsComponent } from './components/input-errors/input-errors.component';
import { PrefixInputComponent } from './components/prefix-input/prefix-input.component';
import { RadioComponent } from './components/radio/radio.component';
import { RadioModule } from './components/radio2/radio.module';
import { SelectComponent } from './components/select/select.component';
import { SelectOptionDirective } from './components/select/select-option.directive';
import { SwitchComponent } from './components/switch/switch.component';
import { TelInputComponent } from './components/tel-input/tel-input.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { DigitOnlyDirective } from './directives/digits-only/digits-only.directive';
import { EmptyToNullDirective } from './directives/empty-to-null/empty-to-null.directive';

@NgModule({
  imports: [
    CommonModule,
    NgbDatepickerModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    NgbDropdownModule,
    NgbTooltipModule,
    AutotestAttributeDirective,
    SharedPipesModule,
    RadioModule,
    FormErrorModule.forRoot(),
    InputComponent,
    DigitOnlyDirective,
    ScrollDirective,
    CheckboxComponent,
  ],
  declarations: [
    SwitchComponent,
    RadioComponent,
    TextareaComponent,
    SelectComponent,
    DatepickerComponent,
    InputErrorsComponent,
    PrefixInputComponent,
    SelectOptionDirective,
    TelInputComponent,
    EmptyToNullDirective,
  ],
  exports: [
    SwitchComponent,
    RadioComponent,
    TextareaComponent,
    SelectComponent,
    DatepickerComponent,
    InputErrorsComponent,
    PrefixInputComponent,
    CheckboxComponent,
    TelInputComponent,
    EmptyToNullDirective,
    RadioModule,
    FormErrorModule,
  ],
  providers: [NgbDateNativeAdapter],
})
export class FormModule {}
