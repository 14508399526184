import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';

import { LazyImageComponent } from './components/lazy-image/lazy-image.component';
@NgModule({
  imports: [CommonModule, AutotestAttributeDirective],
  exports: [LazyImageComponent],
  declarations: [LazyImageComponent],
})
export class LazyImageModule {}
