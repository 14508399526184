import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  UltraCellDefDirective,
  UltraCellDirective,
  UltraColumnDefDirective,
  UltraFooterCellDefDirective,
  UltraFooterCellDirective,
  UltraHeaderCellDefDirective,
  UltraHeaderCellDirective,
} from './cell';
import {
  UltraFooterRowComponent,
  UltraFooterRowDefDirective,
  UltraHeaderRowComponent,
  UltraHeaderRowDefDirective,
  UltraRowComponent,
  UltraRowDefDirective,
} from './row';
import { UltraTableComponent } from './table';

const EXPORTED_DECLARATIONS = [
  // Table
  UltraTableComponent,
  // Template defs
  UltraHeaderRowDefDirective,
  UltraRowDefDirective,
  UltraColumnDefDirective,
  UltraHeaderCellDefDirective,
  UltraCellDefDirective,
  UltraFooterCellDefDirective,
  UltraFooterRowDefDirective,
  // Cell directives
  UltraHeaderCellDirective,
  UltraCellDirective,
  UltraFooterCellDirective,
  // Row directives
  UltraHeaderRowComponent,
  UltraRowComponent,
  UltraFooterRowComponent,
];

@NgModule({
  declarations: [...EXPORTED_DECLARATIONS],
  exports: [...EXPORTED_DECLARATIONS],
  imports: [CommonModule, CdkTableModule],
})
export class TableModule {}
