import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { Dialog, IImageDetail } from '../..';
import { IMedia } from '../../models/uniq/interfaces/uniq.interface';

import { IVideo } from './../../models/multimedia/interfaces/video.interface';
import { MediaCarouselExpandedComponent } from './media-carousel-expanded/media-carousel-expanded.component';
import { MediaCarouselYoutubeService } from './services/media-carousel-youtube.service';

@UntilDestroy()
@Component({
  selector: 'ultra-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MediaCarouselYoutubeService],
})
export class MediaCarouselComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('videoItem') videoItemElement: ElementRef;

  @Input() multiMedia: (IVideo | IMedia | IImageDetail)[];
  @Input() expandable = false;
  @Input() innerPagination = true;
  @Input() theme = 'card-carousel';

  playerConfig;
  updateLoopContent = false;
  slideReSize$: Observable<{ width: number; height: number }>;
  /**
   * Media carousel expanded slide index.
   */
  slideIndex: number | null = 0;

  constructor(
    private dialog: Dialog,
    private mediaCarouselYoutube: MediaCarouselYoutubeService,
    private cd: ChangeDetectorRef,
  ) {
    this.playerConfig = this.mediaCarouselYoutube.playerConfig;
  }

  public ngOnInit(): void {
    if (this.multiMedia?.some((media) => Object.prototype.hasOwnProperty.call(media, 'videoId'))) {
      this.mediaCarouselYoutube.initYoutubeIFrameApi();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.multiMedia) {
      this.cd.markForCheck();
      if (this.videoItemElement) {
        this.slideReSize$ = this.mediaCarouselYoutube
          .getSliderVideoPlayerSize$(this.videoItemElement)
          .pipe(untilDestroyed(this));
      }
    }
  }

  public ngOnDestroy(): void {
    this.mediaCarouselYoutube.destroyYoutubeVideoPlayers();
  }

  public openMediaExpandedDialog(selectedMedia: IVideo | IMedia | IImageDetail): void {
    this.slideIndex = null;

    const dialog = this.dialog.open(MediaCarouselExpandedComponent, {
      data: { selectedMedia, medias: this.multiMedia },
      disableClose: false,
      backdropClass: 'ultra-blur-backdrop',
    });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((slideIndex: number) => {
        this.slideIndex = slideIndex;
        this.cd.detectChanges();
      });
  }

  public updateSliderVideoMedia(video: YouTubePlayer): void {
    this.mediaCarouselYoutube.addPlayerToActiveList(video);
    this.updateLoopContent = true;
  }

  public pauseActiveVideo(): void {
    this.mediaCarouselYoutube.pauseActiveVideoPlayers();
  }
}
