<div class="data-header d-flex w-100 justify-content-between">
  <span class="text-uppercase overline-medium text-white-alpha-7" i18n>data</span>
</div>
<div class="data-content d-flex flex-column w-100">
  <div class="box-grid d-flex flex-wrap w-100 flex-row">
    <div class="me-2 box d-flex overflow-hidden flex-column align-items-stretch overflow-hidden rounded">
      <div class="box-content d-flex align-items-center overflow-hidden w-100 h-100 justify-content-center flex-column">
        <span class="title overline-medium mb-2" i18n ultraAutotestAttribute="card-data-onchain-content"
          >onchain data</span
        >
        <button class="btn col-12 btn-lg" (click)="openLastTransactionInBlockExplorer()">
          <span class="b1-bold d-flex justify-content-center" i18n
            >Open <i class="ms-1 icon icon-link-external" ultraAutotestAttribute="card-data-open-icon-content"></i
          ></span>
        </button>
      </div>
    </div>
    <div class="ms-2 box d-flex overflow-hidden flex-column align-items-stretch overflow-hidden rounded">
      <div class="box-content d-flex align-items-center overflow-hidden w-100 h-100 justify-content-center flex-column">
        <span class="title overline-medium mb-2" i18n>offchain metadata</span>
        <button (click)="downloadOffchainMetadata()" class="btn col-12 btn-lg">
          <span class="b1-bold d-flex justify-content-center" i18n
            >Download <i class="ms-1 icon icon-download-sm" ultraAutotestAttribute="card-data-download-icon-content"></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</div>
