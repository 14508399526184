import { creditCards } from './credit-cards.mock';
import { cash } from './cash.mock';
import { IUser } from '../interfaces/user.interface';
import { CountryCode } from '../../../helpers/country-code.enum';
import { CurrencyType } from '../../exchange/enums/currency-type';

export const userMock: IUser = {
  id: '12345',
  blockchainId: 'aa1bb2cc3dd4',
  username: 'Miranda',
  avatar: {
    name: `avatar A`,
    images: {
      s: {
        url: './assets/images/tmp/avatars/mens/3.jpg',
        width: '128',
        height: '128',
      },
    },
  },
  creditCards: creditCards,
  cash: cash,
  personalData: {
    firstname: 'Miranda',
    lastname: 'Doe',
    email: 'test@ultra.io',
    countryCode: CountryCode.UNITED_STATES,
    countryCurrency: CurrencyType.USD,
    currencySymbol: '$',
    addresses: [
      {
        type: '',
        detail: {
          streetName: null,
          streetName2: null,
          state: null,
          zipCode: null,
          country: 'United States',
          city: null,
        },
      },
    ],
  },
  professionalData: [{ companyId: '12345' }],
};
