export enum ImageSizes {
  xs,
  s,
  m,
  l,
  none,
}
export type ImgSize = keyof typeof ImageSizes;

export const imgBySizeDictionary: { [key in ImgSize]: string } = {
  ['l']: 'assets/images/img-placeholder-l.png',
  ['m']: 'assets/images/img-placeholder-m.png',
  ['s']: 'assets/images/img-placeholder-s.png',
  ['xs']: 'assets/images/img-placeholder-xs.png',
  ['none']: 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
};

export const getImgBySize = (width: number, height: number): string => {
  if (width >= 1920 && height >= 1080) {
    return `${imgBySizeDictionary.l}`;
  }

  if (width >= 720 && height >= 400) {
    return `${imgBySizeDictionary.m}`;
  }

  if (width >= 464 && height >= 180) {
    return `${imgBySizeDictionary.s}`;
  }
  return `${imgBySizeDictionary.xs}`;
};

export const isValidImageSize = (size: ImgSize): boolean => size && Object.values(ImageSizes).includes(size);
