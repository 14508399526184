import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(@Inject(APP_CONFIG) private environment: IEnvironment) {}

  isEnabled(feature: string | string[]): boolean {
    const flags = typeof feature === 'string' ? [feature] : feature;
    return flags.every((flag) => this.environment.featureFlags?.[flag] === true);
  }

  isDisabled(feature: string): boolean {
    return this.environment.featureFlags?.[feature] !== true;
  }
}
