export interface IErrorResponse {
  statusCode: number;
  error: string;
  errorMessage?: string;
  message: IMsgFErrorResponse | IMsgTErrorResponse[] | IMsgFErrorResponse[] | string;
}

export interface IMsgTErrorResponse {
  children: any[];
  constraints: { [key: string]: string };
  property: string;
  target: any;
  value: string;
}

export interface IMsgFErrorResponse {
  message?: string;
  code?: number;
  error?: string;
  statusCode?: number;
}

export interface IFormattedErrorResponse {
  [key: string]: string[];
}

export enum ErrorResponse {
  'Unauthorized' = 'Your email or password is incorrect',
  'EmailNotWhitelisted' = 'The platform is currently opened to a limited number of selected users',
}

export interface IHttpStatusCode {
  statusCode: number;
  message: string;
}
