import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
} from '@angular/core';

import { ISelectedTab } from '../interfaces/selected-tab.interface';
import { TabComponent } from '../tab/tab.component';

export type UltraTabHeaderPosition = 'above' | 'below';

@Component({
  selector: 'ultra-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TabGroupComponent implements AfterViewInit {
  selectedId: string;

  @Input() selectedIndex = 0;

  @Input() tabIndicator: 'above' | 'bellow' = 'above';

  @Input() indicatorSize: 'thin' | 'thick' = 'thin';
  @Input() tabNavMinSize = true;

  @Input() headerPosition: UltraTabHeaderPosition = 'above';
  @HostBinding('class.tab-group-inverted-header')
  get isHeaderAbove() {
    return this.headerPosition === 'below';
  }

  @Input() fixedHeight = true;

  @Input() fullTabWith = false;
  @Input() sameTabWidth = false;
  @Input() tabHeaderClass = '';

  @Input() padContent = true;

  @Input() set changeTab(index: number | null) {
    const idx = index ?? -1;
    if (idx >= 0) {
      this.select(idx);
    }
  }

  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  @Output() tabChange = new EventEmitter<ISelectedTab>();
  /**
   * Created as a workaround to just emit the tab change
   * when the tab is selected and not AfterviewInit
   * Since there are other components using this.
   *
   * TODO:
   * Change the way it works to just emit the tab change.
   */
  @Output() tabSelected = new EventEmitter<ISelectedTab>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const selectedTab = this.tabs.toArray()[this.selectedIndex];
    this.selectedId = selectedTab ? selectedTab.id : this.tabs.length ? this.tabs.first.id : null;
    this.tabChange.emit({ id: this.selectedId, index: this.selectedIndex });
    this.cdr.detectChanges();
  }

  select(index: number) {
    const selectedTab = this.tabs.toArray()[index];
    if (selectedTab && !selectedTab.disabled && this.selectedId !== selectedTab.id) {
      this.selectedId = selectedTab.id;
      this.selectedIndex = index;
      this.tabChange.emit({ id: this.selectedId, index: this.selectedIndex });
      this.tabSelected.emit({ id: this.selectedId, index: this.selectedIndex });
      this.cdr.detectChanges();
    }
  }

  transformNav(): string {
    return `translateX(${100 * this.selectedIndex}%)`;
  }
}
