import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageInterface } from '../../../../interfaces';
import { GameDetail } from '../../../../models/game/models/game-detail.model';
import { ContentSkeletonTheme } from '../../../../modules/skeleton/content-skeleton/content-skeleton-theme.enum';
import { LanguageFormatterPipe } from '../../../../pipes';
import { LanguagesService } from '../../../../services/languages/languages.service';

interface GameLanguage {
  languageName: string;
  languageKey: string;
}

@Component({
  selector: 'ultra-game-detail-languages',
  templateUrl: './game-detail-languages.component.html',
  styleUrls: ['./game-detail-languages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailLanguagesComponent {
  private languagePipe = inject(LanguageFormatterPipe);
  @Input() game: GameDetail;
  @Input() isRouteChange = false;

  isExpanded = false;
  languagesService = inject(LanguagesService);

  gameLanguage$: Observable<GameLanguage[]> = of(this.languagesService.languages()).pipe(
    map((languages: LanguageInterface[]) =>
      this.game
        .getLanguagesKeys()
        .map((languageKey: string) => ({
          languageName: this.languagePipe.transform(languageKey, languages),
          languageKey,
        }))
        .sort((a, b) => a.languageName.localeCompare(b.languageName)),
    ),
  );

  readonly supportTypes: [string, string, string] = ['interfaces', 'audio', 'subtitles'];
  readonly placeholderTheme = ContentSkeletonTheme;

  toggleMore(): void {
    this.isExpanded = !this.isExpanded;
  }
}
