@if (showTradabality) {
  <ultra-actions-status
    class="me-2"
    [period]="idCardEntity.tradingPeriod"
    customText="Resalable"
    [action]="actions.TRADABILITY"
    [availability]="idCardEntity.tradingPeriod?.resaleAvailability"
    [showTooltip]="true"
    (click)="onPropertyClick($event, properties.TRADABILITY)">
  </ultra-actions-status>
}
@if (showTransferability) {
  <ultra-actions-status
    class="me-2"
    [period]="idCardEntity.transferPeriod"
    [action]="actions.TRANSFERABILITY"
    [availability]="idCardEntity.transferPeriod?.transferAvailability"
    [showTooltip]="true"
    (click)="onPropertyClick($event, properties.TRANSFERABILITY)">
  </ultra-actions-status>
}
