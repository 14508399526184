import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../autotests';
import { ActionsStatusComponent } from '../../modules/id-card-content/id-card-actions/components/actions-status/actions-status.component';
import { SharedPipesModule } from '../../pipes';
import { isFreePipe } from '../../uniq/is-free.pipe';
import { UniqPriceComponent } from '../../uniq/uniq-price/uniq-price.component';
import { UniqTypeIconComponent } from '../../uniq/uniq-type-icon';
import { IdCardQuantityStatusComponent } from '../id-card-content';
import { IdCardContentModule } from '../id-card-content/id-card-content.module';
import { LazyImageModule } from '../lazy-image/lazy-image.module';
import { MediaCarouselModule } from '../media-carousel/media-carousel.module';
import { MetadataImageManagerComponent } from '../metadata-status-manager/metadata-image-manager/metadata-image-manager.component';
import { MetadataInformationManagerComponent } from '../metadata-status-manager/metadata-information-manager/metadata-information-manager.component';
import { GallerySkeletonComponent } from '../skeleton/gallery-skeleton/gallery-skeleton.component';
import { TabsModule } from '../tabs/tabs.module';
import { WithdrawModule } from '../withdraw/withdraw.module';

import { DialogModule } from './../dialog';
import { SyncAttributesSkeletonComponent } from './uniq-detail/sync-attributes-skeleton/sync-attributes-skeleton.component';
import { UniqDetailComponent } from './uniq-detail/uniq-detail.component';
import { UniqDetailDialogComponent } from './uniq-detail-dialog/uniq-detail-dialog.component';
import { UniqFactoryDetailComponent } from './uniq-factory-detail/uniq-factory-detail.component';
import { UniqFactoryDetailDialogComponent } from './uniq-factory-detail-dialog/uniq-factory-detail-dialog.component';
import { UniqPreviewComponent } from './uniq-preview/uniq-preview.component';

@NgModule({
  imports: [
    CommonModule,
    IdCardContentModule,
    LazyImageModule,
    TabsModule,
    AutotestAttributeDirective,
    WithdrawModule,
    SharedPipesModule,
    MediaCarouselModule,
    DialogModule,
    UniqTypeIconComponent,
    UniqPriceComponent,
    MetadataImageManagerComponent,
    MetadataInformationManagerComponent,
    GallerySkeletonComponent,
    SyncAttributesSkeletonComponent,
    ActionsStatusComponent,
    IdCardQuantityStatusComponent,
    isFreePipe,
    NgbTooltip,
    NgbTooltipModule,
  ],
  exports: [
    UniqPreviewComponent,
    UniqDetailComponent,
    UniqDetailDialogComponent,
    UniqFactoryDetailComponent,
    UniqFactoryDetailDialogComponent,
  ],
  declarations: [
    UniqPreviewComponent,
    UniqDetailComponent,
    UniqDetailDialogComponent,
    UniqFactoryDetailComponent,
    UniqFactoryDetailDialogComponent,
  ],
})
export class UniqPreviewModule {}
