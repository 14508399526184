import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AuthService } from '@ultra/core/auth';
import { UserFacadeService } from '@ultra/core/lib/stores';
import { CurrencyType, IPersonalData, UserCurrency } from '@ultra/core/models';

import { TIMEZONE_DATA } from './timezone-to-currency';

const currencyToSymbol = {
  EUR: '€',
  AUD: 'A$',
  GBP: '£',
  CAD: 'CDN$',
  USD: '$',
} as const;

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  private authService = inject(AuthService);
  private userFacadeService = inject(UserFacadeService);

  userCurrency$: Observable<UserCurrency> = this.userFacadeService.personalData$.pipe(
    filter((personalData: IPersonalData | undefined) => (this.authService.isAuthenticated() ? !!personalData : true)),
    map((personalData: IPersonalData | undefined) => {
      const timeZoneCurrencyData = this.timeZoneToCurrency();
      return {
        countryCurrency: personalData?.countryCurrency || timeZoneCurrencyData.countryCurrency,
        currencySymbol: personalData?.currencySymbol || timeZoneCurrencyData.currencySymbol,
      };
    }),
    take(1),
  );

  private timeZoneToCurrency(): UserCurrency {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { zoneToCountry, countryToCurrency } = TIMEZONE_DATA;
    const countryCode = zoneToCountry[timeZone];
    const currencyCode = countryToCurrency[countryCode];
    const countryCurrency = currencyCode || CurrencyType.USD;
    return {
      countryCurrency,
      currencySymbol: currencyToSymbol[countryCurrency],
    };
  }
}
