import { ApiGql } from '@ultra/core/config';

import { CREATE_STATIC_COLLECTION_MUTATION, UPDATE_STATIC_COLLECTION_MUTATION } from './uniq-factory.mutations';
import {
  GET_FULL_UNIQ_FACTORY_QUERY,
  UNIQ_FACTORIES_QUERY,
  UNIQ_FACTORIES_RECOMMENDATION_QUERY,
  UNIQ_FACTORIES_STATIC_COLLECTION_QUERY,
} from './uniq-factory.query';

export class UniqFactoryGqlConfig extends ApiGql {
  // ********** Queries **********
  static readonly UNIQ_FACTORIES = UNIQ_FACTORIES_QUERY;
  static readonly UNIQ_FACTORIES_STATIC_COLLECTION = UNIQ_FACTORIES_STATIC_COLLECTION_QUERY;
  static readonly UNIQ_FACTORIES_RECOMMENDATION = UNIQ_FACTORIES_RECOMMENDATION_QUERY;

  static readonly UNIQ_FACTORY_BY_ID = GET_FULL_UNIQ_FACTORY_QUERY;

  // ********** Mutations **********
  static readonly CREATE_STATIC_COLLECTION = CREATE_STATIC_COLLECTION_MUTATION;
  static readonly UPDATE_STATIC_COLLECTION = UPDATE_STATIC_COLLECTION_MUTATION;
}
