import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloError } from '@apollo/client/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
  timeout,
  timer,
  withLatestFrom,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { getGraphQLErrorMessage } from '@ultra/core/helpers';
import { DEVICE_TYPE } from '@ultra/core/lib/providers/device-type.provider';
import { AuthEventsService } from '@ultra/core/lib/services/auth/auth-events.service';
import { INewUserDeviceData, IUser, UserBlockchainStatusType, UserFactoryService } from '@ultra/core/models';
import { MainStorageService, StorageKey, UltraosApiService } from '@ultra/core/services';
import { UserFacadeService } from '@ultra/core/stores';

import {
  AccountCreationError,
  AccountCreationErrorCode,
  AuthenticatorErrorCode,
  AuthenticatorFlowStatus,
  BlockchainTransactionBuilder,
  DeviceStatus,
  EosioAction,
  IGetAccountsResponse,
  ISignTransactionResponse,
  ITransactionAction,
  KnownContract,
} from '../../services/authenticator';
import { BlockchainService } from '../../services/blockchain';

import { AuthenticatorService } from './authenticator.service';
import { AuthenticatorError } from './models/authenticator-error';
import { SymmetricKey } from './models/symmetric-key';

const ACCOUNT_CREATION_TIMEOUT = 2 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class AuthenticatorGatewayService {
  private deviceType = inject(DEVICE_TYPE);
  private _loading$ = new BehaviorSubject<boolean>(false);
  private _connectionStatus$ = new BehaviorSubject<AuthenticatorFlowStatus>(null);

  loading$ = this._loading$.asObservable();
  connectionStatus$ = this._connectionStatus$.asObservable();
  walletUnavailable$ = this.getWalletUnavailable();

  constructor(
    private router: Router,
    private blockchainService: BlockchainService,
    private ultraosApiService: UltraosApiService,
    private userService: UserFactoryService,
    private storageService: MainStorageService,
    private authenticatorService: AuthenticatorService,
    private userFacadeService: UserFacadeService,
    private authEventsService: AuthEventsService,
  ) {
    this.authEventsService.logout$.pipe(switchMap(() => this.signOut())).subscribe();
  }

  public authenticateUser(): Observable<boolean> {
    this._loading$.next(true);
    return this.signIn().pipe(finalize(() => this._loading$.next(false)));
  }

  public transferToken(
    uniqueId: string,
    blockchainFrom: string,
    blockchainTo: string,
    quantity: number,
    memo: string,
  ): Observable<ISignTransactionResponse> {
    const transactionAction = this.buildTransferTransaction(blockchainFrom, blockchainTo, quantity, memo);
    return this.signTransaction(transactionAction, uniqueId);
  }

  public buildTransferTransaction(blockchainFrom: string, blockchainTo: string, quantity: number, memo: string) {
    return new BlockchainTransactionBuilder()
      .action(EosioAction.TRANSFER)
      .contract(KnownContract.EOSIO_TOKEN)
      .authorizationForTransferAction(blockchainFrom)
      .dataForTransferAction(blockchainFrom, blockchainTo, quantity, memo)
      .build();
  }

  public signTransaction(transaction: ITransactionAction, id: string = uuidv4()): Observable<ISignTransactionResponse> {
    return this.signIn().pipe(switchMap(() => this.authenticatorService.signTransaction(id, [transaction])));
  }

  public abortTransaction(receipt: string): Observable<boolean> {
    return this.authenticatorService.abortTransaction(receipt);
  }

  public getAccounts(): Observable<IGetAccountsResponse> {
    return this.signIn().pipe(switchMap(() => this.authenticatorService.getAccounts()));
  }

  public getWalletBalance(): Observable<string> {
    return this.userFacadeService.blockchainId$.pipe(
      filter((blockchainId) => !!blockchainId),
      take(1),
      switchMap((blockchainAccount: string) => this.blockchainService.getAccountBalance(blockchainAccount)),
    );
  }

  public signIn(): Observable<boolean> {
    return this.userFacadeService.getCurrentUser().pipe(
      switchMap((user) =>
        this.authenticatorService.create(user.id).pipe(
          switchMap(() => this.ultraosApiService.getDeviceId()),
          switchMap((deviceId: string) =>
            this.getUserHalfSymkey(user.id).pipe(
              switchMap((userHalfSymkey) => {
                if (userHalfSymkey) {
                  return this.activateDevice(deviceId, userHalfSymkey);
                } else {
                  return this.registerDevice(deviceId);
                }
              }),
              withLatestFrom(this.userFacadeService.blockchainId$),
              switchMap(([symmetricKey, blockchainId]: [SymmetricKey, string]) =>
                this.signIntoAuthenticator(symmetricKey, deviceId, blockchainId, true),
              ),
            ),
          ),
        ),
      ),
      catchError((error) => {
        console.error(`signIn - ${getGraphQLErrorMessage(error)}`, error);
        return throwError(error);
      }),
    );
  }

  public signOut(): Observable<boolean> {
    return this.authenticatorService.lock().pipe(
      tap((locked) => {
        if (locked) {
          this._connectionStatus$.next(null);
        }
      }),
      catchError((error) => {
        console.error(`signOut - ${error.message}`);
        return of(null);
      }),
    );
  }

  private activateDevice(deviceId: string, userHalfSymkey: string): Observable<SymmetricKey> {
    return this.userService.strategy.activateUserDevice(deviceId).pipe(
      map(({ ultraHalfVaultKey }) => new SymmetricKey(userHalfSymkey, ultraHalfVaultKey)),
      catchError((error) => {
        if (
          error instanceof ApolloError &&
          (error.graphQLErrors[0]?.message as any).statusCode === DeviceStatus.UNKNOWN
        ) {
          return this.registerDevice(deviceId);
        }
        throw error;
      }),
    );
  }

  private registerDevice(deviceId: string): Observable<SymmetricKey> {
    const userHalfSymkey = this.generateUserHalfSymkey();

    return this.authenticatorService.clearWallet().pipe(
      switchMap(() => this.authenticatorService.createWallet()),
      switchMap(({ publicKey }) => {
        const userBlockchainData: INewUserDeviceData = {
          publicOwnerKey: publicKey,
          publicActiveKey: publicKey,
          deviceType: this.deviceType,
          deviceId,
        };
        return this.userFacadeService.user$.pipe(
          take(1),
          switchMap((user) => this.waitForBlockchainAccountCreation(user)),
          switchMap(() => this.userService.strategy.addNewUserDevice(userBlockchainData)),
          switchMap((user: IUser) => {
            const { ultraHalfVaultKey } = user.devices.find((device) => device.deviceId === deviceId);
            const symmetricKey = new SymmetricKey(userHalfSymkey, ultraHalfVaultKey);
            return this.waitForBlockchainAccountCreation(user, true).pipe(
              switchMap(({ blockchainId }) =>
                this.storeUserHalfSymkey(user.id, userHalfSymkey).pipe(
                  switchMap(() => this.authenticatorService.unlock(symmetricKey.fullKey)),
                  switchMap(() => this.authenticatorService.linkAccount(publicKey, blockchainId)),
                  map(() => symmetricKey),
                ),
              ),
            );
          }),
        );
      }),
    );
  }

  private generateUserHalfSymkey(): string {
    return uuidv4();
  }

  private storeUserHalfSymkey(userId: string, userHalfSymkey: string): Observable<void> {
    return this.storageService.set(StorageKey.USER_SYMKEY + userId, userHalfSymkey);
  }

  private getUserHalfSymkey(userId: string): Observable<string> {
    return this.storageService.get(StorageKey.USER_SYMKEY + userId);
  }

  private checkUserKeys(blockchainId: string): Observable<boolean> {
    return this.authenticatorService.hasKeys(blockchainId).pipe(
      tap(
        (hasKeys) => {
          if (!hasKeys) {
            throw new AuthenticatorError(AuthenticatorErrorCode.KEYS_NOT_FOUND);
          }
          this._connectionStatus$.next(AuthenticatorFlowStatus.ACCOUNT_KEYS_VALIDATED);
        },
        () => {
          this._connectionStatus$.next(AuthenticatorFlowStatus.ACCOUNT_KEYS_ERROR);
        },
      ),
    );
  }

  private getWalletUnavailable(): Observable<boolean> {
    return this.loading$.pipe(
      filter((loading) => false === loading),
      switchMap(() => combineLatest([this.connectionStatus$, this.userFacadeService.blockchainId$])),

      map(([status, blockchainId]) => {
        const errorList = [
          AuthenticatorFlowStatus.BLOCKCHAIN_ACCOUNT_ERROR,
          AuthenticatorFlowStatus.ACCOUNT_KEYS_ERROR,
          AuthenticatorFlowStatus.USER_AUTHENTICATION_ERROR,
        ];
        return errorList.includes(status) || !blockchainId;
      }),
    );
  }

  private signIntoAuthenticator(
    symmetricKey: SymmetricKey,
    deviceId: string,
    blockchainId: string,
    renewKeysOnError = false,
  ): Observable<boolean> {
    return this.authenticatorService.unlock(symmetricKey.fullKey).pipe(
      switchMap(() => this.checkUserKeys(blockchainId)),
      tap(() => this._connectionStatus$.next(AuthenticatorFlowStatus.USER_AUTHENTICATED)),
      catchError((error) => {
        if (renewKeysOnError && error instanceof AuthenticatorError) {
          return timer(2000).pipe(
            switchMap(() => this.registerDevice(deviceId)),
            switchMap((symKey) => this.signIntoAuthenticator(symKey, deviceId, blockchainId)),
          );
        } else {
          this._connectionStatus$.next(AuthenticatorFlowStatus.USER_AUTHENTICATION_ERROR);
          this.router.navigate(['/create-blockchain-account/error']);
          return throwError(error);
        }
      }),
    );
  }

  private waitForBlockchainAccountCreation(user: IUser, throwOnFailure = false): Observable<Partial<IUser>> {
    // if blockchain account creation process already finished, skip waiting
    if (this.isBlockchainAccountCreationFinished(user, throwOnFailure)) {
      return of(user);
    }
    // blockchain account creation is in progress, subscribe to
    // userAccountStatus and wait for updates
    return this.userFacadeService.onAccountStatus().pipe(
      filter((user) => this.isBlockchainAccountCreationFinished(user, throwOnFailure)),
      take(1),
      // Throw a timeout error if the account is not created within 2 minutes
      timeout({
        each: ACCOUNT_CREATION_TIMEOUT,
        with: () =>
          throwError(
            () =>
              new AccountCreationError(
                'Blockchain account was not created within 2 minutes',
                AccountCreationErrorCode.REQUEST_TIMEOUT,
              ),
          ),
      }),
    );
  }

  private isBlockchainAccountCreationFinished(
    { blockchainId, blockchainStatus }: Partial<IUser>,
    throwOnFailure: boolean,
  ): boolean {
    if (blockchainStatus === UserBlockchainStatusType.FAILURE && throwOnFailure) {
      throw new AccountCreationError('Blockchain account creation failed', AccountCreationErrorCode.FAILURE);
    }
    return !!blockchainId || blockchainStatus === UserBlockchainStatusType.FAILURE;
  }
}
