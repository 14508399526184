import { GameDetail } from '../../models/game-detail.model';
import { VideoProvider } from '../../../multimedia/interfaces/video.interface';
import { gamePlaceholderB64 } from '../../../multimedia/b64/game-placeholder.helper';
import { ITokenPrice } from '../../../token-factory/interfaces/token-price.interface';

export const mockPrice: ITokenPrice = {
  amount: 10,
  currency: 'GBP',
  symbol: '£',
  originalAmount: 11,
  discount: 1000,
  discountEndDate: '2060-02-08T10:28:05.595Z',
};

export const mockFreePrices: ITokenPrice = {
  amount: 0,
  currency: 'GBP',
  symbol: '£',
};

export const gameDetailMock = new GameDetail({
  id: '5bb6505ddf97da0008fecb26',
  blockchainId: '1538674781283',
  publisher: { id: '12344' },
  buyableTokenFactory: { id: '1234', prices: mockPrice },
  tokenFactories: [
    {
      id: 'id',
      blockchainId: 'blockchainId',
      contentType: {
        id: '',
        code: 'game',
        name: 'Game',
      },
      prices: [mockPrice],
    },
  ],
  liveGame: {
    title: 'The Witcher® 3: Wild Hunt',
    description: `The Witcher is a story-driven, next-generation open world role-playing game, set in a visually stunning fantasy universe, full of meaningful choices and impactful consequences. In The Witcher, you play as Geralt of Rivia, a monster hunter tasked with finding a child from an ancient prophecy.`,
    descriptionShort: `In the open world of Wild Hunt, you chart your own path to adventure.`,
    languages: {
      audio: ['English', 'French', 'German', 'Spanish', 'Korean'],
      subtitles: ['English', 'French', 'German', 'Spanish', 'Korean'],
      interfaces: ['English', 'French', 'German', 'Spanish', 'Korean'],
    },
    categories: ['Action', 'RPG'],
    developerName: 'cd project red',
    editorName: 'Guerrilla Games',
    releaseDate: null,
    systemsRequirements: [
      {
        osName: 'windows',
        minimum: {
          os: 'Windows XP / 8 / 8.1 / 10 (64-bit only)',
          processor: 'Intel CPU Core i3-2500K 3.3GHz / AMD CPU Phenom II X4 940',
          memory: '2 GB RAM',
          graphics: ['Nvidia GPU GeForce GTX 660 / AMD GPU Radeon HD 7870'],
          storage: '35 GB available space',
          soundCard: 'DX10 compatible',
          minimumResolution: '1280 × 720',
        },
        recommended: {
          os: 'Windows 8.1 / 10 (64-bit only)',
          processor: 'Intel CPU Core i5-2500K 3.3GHz / AMD CPU Phenom II X4 940',
          memory: '8 GB RAM',
          graphics: ['Nvidia GPU GeForce GTX 1080 / AMD GPU Radeon HD 7870'],
          storage: '35 GB available space',
          soundCard: 'DX11 compatible',
          minimumResolution: '1920 × 1080',
        },
      },
    ],
    multimedia: {
      largeHeroImage: {
        base: {
          url: gamePlaceholderB64,
          height: 800,
          width: 1430,
        },
        name: 'gal image 0',
        fileExtension: 'jpg',
        images: [
          {
            url: gamePlaceholderB64,
            height: 400,
            width: 720,
          },
          {
            url: gamePlaceholderB64,
            height: 400,
            width: 720,
          },
          {
            url: gamePlaceholderB64,
            height: 400,
            width: 720,
          },
        ],
      },
      imageGalleryList: [
        {
          base: {
            url: gamePlaceholderB64,
            height: 800,
            width: 1430,
          },
          name: 'gal image 0',
          fileExtension: 'jpg',
          images: [
            {
              url: gamePlaceholderB64,
              height: 400,
              width: 720,
            },
            {
              url: gamePlaceholderB64,
              height: 400,
              width: 720,
            },
            {
              url: gamePlaceholderB64,
              height: 400,
              width: 720,
            },
          ],
        },
      ],
      videosPreviews: [
        {
          videoId: 'XHrskkHf958',
          provider: VideoProvider.Youtube,
          duration: 6.35,
          image: {
            name: 'video image 3',
            base: {
              height: 120,
              width: 120,
              url: gamePlaceholderB64,
            },
            images: [
              {
                url: gamePlaceholderB64,
                height: 400,
                width: 720,
              },
            ],
          },
        },
      ],
    },
    tags: ['Adventure', 'Heroic-Fantasy', '+12'],
    rating: {
      metascore: {
        value: 92,
        totalNumberOfRatings: 79,
      },
      metaCriticsUserScore: {
        value: 9.2,
        totalNumberOfRatings: 11900,
      },
      awards: [
        {
          name: 'IGN’s Best of 2015',
          category: 'Game of the Year (Editor’s choice)',
        },
      ],
    },
    links: [
      {
        name: 'Developer website',
        url: 'https://google.com/',
      },
    ],
    lastUpdate: {
      date: '2018-09-28T00:00:00.000Z',
      version: '1.0.1a',
    },
    playingModes: ['Single player', 'Online Multiplayer'],
    features: ['Controller support', 'VR Support', 'Leaderboard'],
    ageRating: [],
  },
  developmentGame: {
    title: 'The Witcher® 3: Wild Hunt',
    description: `The Witcher is a story-driven, next-generation open world role-playing game, set in a visually stunning fantasy universe, full of meaningful choices and impactful consequences. In The Witcher, you play as Geralt of Rivia, a monster hunter tasked with finding a child from an ancient prophecy.`,
    descriptionShort: `In the open world of Wild Hunt, you chart your own path to adventure.`,
    languages: {
      audio: ['English', 'French', 'German', 'Spanish', 'Korean'],
      subtitles: ['English', 'French', 'German', 'Spanish', 'Korean'],
      interfaces: ['English', 'French', 'German', 'Spanish', 'Korean'],
    },
    categories: ['Action', 'RPG'],
    developerName: 'cd project red',
    editorName: 'Guerrilla Games',
    releaseDate: {
      year: 2020,
      month: 7,
      day: 27,
    },
    systemsRequirements: [
      {
        osName: 'windows',
        minimum: {
          os: 'Windows XP / 8 / 8.1 / 10 (64-bit only)',
          processor: 'Intel CPU Core i3-2500K 3.3GHz / AMD CPU Phenom II X4 940',
          memory: '2 GB RAM',
          graphics: ['Nvidia GPU GeForce GTX 660 / AMD GPU Radeon HD 7870'],
          storage: '35 GB available space',
          soundCard: 'DX10 compatible',
          minimumResolution: '1280 × 720',
        },
        recommended: {
          os: 'Windows 8.1 / 10 (64-bit only)',
          processor: 'Intel CPU Core i5-2500K 3.3GHz / AMD CPU Phenom II X4 940',
          memory: '8 GB RAM',
          graphics: ['Nvidia GPU GeForce GTX 1080 / AMD GPU Radeon HD 7870'],
          storage: '35 GB available space',
          soundCard: 'DX11 compatible',
          minimumResolution: '1920 × 1080',
        },
      },
    ],
    multimedia: {
      largeHeroImage: {
        base: {
          url: gamePlaceholderB64,
          height: 800,
          width: 1430,
        },
        name: 'gal image 0',
        fileExtension: 'jpg',
        images: [
          {
            url: gamePlaceholderB64,
            height: 400,
            width: 720,
          },
          {
            url: gamePlaceholderB64,
            height: 400,
            width: 720,
          },
          {
            url: gamePlaceholderB64,
            height: 400,
            width: 720,
          },
        ],
      },
      imageGalleryList: [
        {
          base: {
            url: gamePlaceholderB64,
            height: 800,
            width: 1430,
          },
          name: 'gal image 0',
          fileExtension: 'jpg',
          images: [
            {
              url: gamePlaceholderB64,
              height: 400,
              width: 720,
            },
            {
              url: gamePlaceholderB64,
              height: 400,
              width: 720,
            },
            {
              url: gamePlaceholderB64,
              height: 400,
              width: 720,
            },
          ],
        },
      ],
      videosPreviews: [
        {
          videoId: 'XHrskkHf958',
          provider: VideoProvider.Youtube,
          duration: 6.35,
          image: {
            name: 'video image 3',
            base: {
              height: 120,
              width: 120,
              url: gamePlaceholderB64,
            },
            images: [
              {
                url: gamePlaceholderB64,
                height: 400,
                width: 720,
              },
            ],
          },
        },
      ],
    },
    tags: ['Adventure', 'Heroic-Fantasy', '+12'],
    rating: {
      metascore: {
        value: 92,
        totalNumberOfRatings: 79,
      },
      metaCriticsUserScore: {
        value: 9.2,
        totalNumberOfRatings: 11900,
      },
      awards: [
        {
          image: {
            base: {
              url: gamePlaceholderB64,
              height: 800,
              width: 1430,
            },
            name: 'gal image 0',
            fileExtension: 'jpg',
            images: [
              {
                url: gamePlaceholderB64,
                height: 48,
                width: 48,
              },
            ],
          },
          name: 'IGN’s Best of 2015',
          category: 'Game of the Year (Editor’s choice)',
        },
      ],
    },
    links: [
      {
        name: 'Developer website',
        url: 'https://google.com/',
      },
    ],
    lastUpdate: {
      date: '2018-09-28T00:00:00.000Z',
      version: '1.0.1a',
    },
    playingModes: ['Single player', 'Online Multiplayer'],
    features: ['Controller support', 'VR Support', 'Leaderboard'],
    ageRating: [],
  },
});
