import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';

let nextUniqueId = 0;

@Component({
  selector: 'ultra-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RadioGroupComponent implements ControlValueAccessor, DoCheck, OnDestroy {
  public readonly stateChange$: Subject<void> = new Subject<void>();
  public radioChanges$: ReplaySubject<any> = new ReplaySubject(1);
  public focused = false;

  @HostBinding('class.radio-error')
  public hasError = false;

  private _id: string;
  private _uid = `ultra-radio-group-${nextUniqueId++}`;
  private _disabled = false;
  private _required = false;
  private _placeholder = '';
  private _readonly = false;
  private _value = null;
  private _radios = [];

  private onTouched: any;
  private onChange: any;

  @HostBinding('attr.id')
  @Input()
  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value || this._uid;
  }

  @Input()
  public get required(): boolean {
    return this._required;
  }

  public set required(value: boolean) {
    this._required = !!value;
  }

  @Input()
  public get value() {
    return this._value;
  }

  public set value(val) {
    if (!this.disabled) {
      this._value = val;
      this.radioChanges$.next(this._value);
      if (this.onChange) {
        this.onChange(this.value);
      }
    }
  }

  @HostBinding('class.disabled')
  @Input()
  public get disabled() {
    return this._disabled;
  }

  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  constructor(
    protected elem: ElementRef,
    @Optional()
    @Self()
    protected ngControl: NgControl,
    @Optional() protected _parentForm: NgForm,
    @Optional() protected _parentFormGroup: FormGroupDirective,
    private changeDetector: ChangeDetectorRef
  ) {
    // Following line of code is not actually not self-assign, it's calling setter with getter value.
    // This is done in order to guarantee that id is set (given or default one uid)
    this.id = this.id; // eslint-disable-line no-self-assign
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostListener('blur', ['false'])
  @HostListener('focus', ['true'])
  public focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && !this._readonly) {
      this.focused = isFocused;
      this.stateChange$.next();
    }
  }

  public registerRadio(radio) {
    this._radios.push(radio);
  }

  public registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public writeValue(value) {
    this.value = value;
    this.changeDetector.markForCheck();
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  public get element() {
    return this.elem.nativeElement;
  }

  public get control() {
    return this.ngControl;
  }

  public get parent() {
    return this._parentFormGroup || this._parentForm;
  }

  public focus(): void {
    this._radios[0].onClick();
  }

  public ngDoCheck() {
    this.stateChange$.next();
  }

  public ngOnDestroy() {
    this.stateChange$.complete();
  }
}
