@if (displayState.status === actionStatuses.TILL || displayState.status === actionStatuses.ABLE || !isEdit) {
  <div class="id-card-sell-price-form">
    <div class="d-flex-center">
      @if (isEdit) {
        <div i18n class="me-1 b2-bold">
          Selling price
          @if (hasFiatMinPrice) {
            <div class="caption-regular text-white-alpha-5">Minimum resell price:</div>
            <div class="caption-regular text-ultra-purple-light d-flex">
              $ {{ minResellPrice }} (≈{{ minResellPriceUos$ | async | ultraCurrency }}
              <span class="refresh-timer mx-1"
                ><round-progress [current]="refreshProgress$ | async" [max]="60"></round-progress></span
              >)
            </div>
          }
        </div>
      } @else {
        <div i18n class="me-1 b2-bold">Total selling price</div>
      }
      @if (!hasFiatMinPrice) {
        <i class="icon icon-info" [ngbTooltip]="totalPriceTooltip" tooltipClass="marketplace-tooltip"></i>
      }
      <ng-template #totalPriceTooltip>
        <ul>
          <li>Royalties are set to ({{ creators.ratio }}%) and go to the creators of the Uniq</li>
          <li>Protocol fees represent blockchain fees and taxes ({{ platform?.ratio }}%)</li>
        </ul>
      </ng-template>
    </div>
    @if (isEdit) {
      <form [formGroup]="form" class="d-flex-center">
        <ultra-select
          class="me-2"
          disabled="true"
          [options]="currencyOptions"
          [control]="form.get('currency')"></ultra-select>
        <ultra-form-field class="price-form-field">
          <i
            class="icon icon-info"
            ultraPrefix
            [ngbTooltip]="hasFiatMinPrice ? priceTooltipFiat : priceTooltipUos"
            tooltipClass="marketplace-tooltip price-tooltip"></i>
          <ng-template #priceTooltipFiat>
            <p i18n>
              You can set any price in UOS, independent of the minimum resell price set by the creator. The Uniq
              Marketplace will display whichever is higher.
            </p>
          </ng-template>
          <ng-template #priceTooltipUos>
            <p i18n>
              The creator of this Uniq set a minimum resell price of {{ minResellPriceUos$ | async | ultraCurrency }}
            </p>
          </ng-template>
          <input
            type="text"
            ultraInput
            ultraCurrencyInput
            placeholder="min: {{ minResellPriceUos$ | async | ultraCurrency }}"
            [formControl]="form.get('amount')"
            ultraAutotestAttribute="min-resell-price-field" />
        </ultra-form-field>
      </form>
    } @else {
      <span class="f-size-16">{{ totalAmount }}</span>
    }
  </div>
  <div class="id-card-sell-fee">
    <div class="fee-type" [class.fee-reduced-row]="showPromoterFee">
      <div i18n class="fee-name">Protocol fee</div>
      <div class="fee-results">
        <div class="fee-percentage">{{ platform?.ratio }} %</div>
        <div class="fee-amount">
          <span class="me-1">{{ currency.symbol }}</span>
          @if (platform?.amount) {
            <span ultraAutotestAttribute="fee-amount-content" class="d-inline-block amount">{{ platform.amount }}</span>
          } @else {
            <span class="d-inline-block">--</span>
          }
        </div>
      </div>
    </div>
    @if (showPromoterFee) {
      <div class="fee-type fee-reduced-row">
        <div i18n class="fee-name">Marketplace fee</div>
        <div class="fee-results">
          <div class="fee-percentage">{{ promoter?.ratio ? promoter.ratio : promoterFeeDefaultValue }} %</div>
          <div class="fee-amount">
            <span class="me-1">{{ currency.symbol }}</span>
            @if (promoter?.amount) {
              <span ultraAutotestAttribute="fee-amount-content" class="d-inline-block amount">{{
                promoter.amount
              }}</span>
            } @else {
              <span class="d-inline-block">--</span>
            }
          </div>
        </div>
      </div>
    }
    <div class="fee-type" [class.fee-reduced-row]="showPromoterFee">
      <div i18n class="fee-name">Royalties</div>
      <div class="fee-results">
        <div class="fee-percentage">{{ creators.ratio }} %</div>
        <div class="fee-amount">
          <span class="me-1">{{ currency.symbol }}</span>
          @if (creators.amount) {
            <span ultraAutotestAttribute="fee-amount-content" class="d-inline-block amount">{{ creators.amount }}</span>
          } @else {
            <span class="d-inline-block">--</span>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="id-card-sell-total">
    <div class="b2-bold" i18n>You’ll earn</div>
    <div class="total-amount">
      <span class="me-1">{{ currency.symbol }}</span>
      <span class="d-inline-block content-ellipsis" ultraAutotestAttribute="card-sell-total-amount-content">
        @if (owner.amount) {
          {{ owner.amount }}
        } @else {
          <span class="d-inline-block">--</span>
        }
      </span>
    </div>
  </div>
  <div class="id-card-sell-action">
    @if (isEdit) {
      <button
        i18n
        class="btn btn-primary w-100"
        [disabled]="isSetForSaleDisabled$ | async"
        (click)="setUniqForSale()"
        ultraAutotestAttribute="set-for-sale-button">
        Set for Sale
      </button>
    } @else {
      <button i18n class="btn btn-danger w-100" (click)="withdrawUniq()" ultraAutotestAttribute="widthdraw-button">
        Withdraw from Marketplace
      </button>
    }
  </div>
} @else {
  <div
    class="nontradable-container d-flex flex-column justify-content-center align-items-center text-center text-white-alpha-3">
    <p>
      <ultra-id-card-actions-title [displayState]="displayState" action="tradability" />
    </p>
    <p>
      <button class="btn btn-light text-transform-none" (click)="handleTradabilityQuickAccess()" i18n>
        View Tradability details
      </button>
    </p>
  </div>
}
