import { SlugPipe } from '../../../pipes';
import { DatePipeFormat } from '../../date/date-types.enum';
import { getDatePipeType } from '../../date/format-date.helper';
import { IImageDetail, IMultimedia } from '../../multimedia/interfaces';
import { ITokenFactoriesByType, ITokenFactory } from '../../token-factory/interfaces/token-factory.interface';
import { ITokenPrice } from '../../token-factory/interfaces/token-price.interface';
import { IGame } from '../interfaces/game.interface';
import { ILastUpdate } from '../interfaces/game-version.interface';
import { IReleaseDate } from '../interfaces/release-date.interface';

import { DefaultGame } from './default-game.model';
import { GameVersionModel } from './game-version.model';

type ImageRules = { [key in string]: string };

export const defaultBgResponsiveListRules: ImageRules = {
  l: '1200w',
  m: '768w',
};

export class Game extends DefaultGame {
  slug?: string;
  favorite?: boolean;
  fakeCloneGame: boolean;
  gameVersion: GameVersionModel;
  status?: string;
  tokenFactoriesByType?: ITokenFactoriesByType;

  get title(): string {
    return this.gameVersion.title;
  }

  set title(title: string) {
    this.gameVersion.title = title;
  }

  get tokenFactory(): ITokenFactory {
    return this.tokenFactories.length ? this.tokenFactories[0] : null;
  }

  set tokenFactory(tokenFactory: ITokenFactory) {
    if (tokenFactory) {
      this.tokenFactories[0] = tokenFactory;
    }
  }

  get description(): string {
    return this.gameVersion.description;
  }

  get descriptionShort(): string {
    return this.gameVersion.descriptionShort;
  }

  get editorName(): string {
    return this.gameVersion.editorName;
  }

  get developerName(): string {
    return this.gameVersion.developerName;
  }

  get categories(): string[] {
    return this.gameVersion.categories;
  }

  get releaseDate(): IReleaseDate {
    return this.gameVersion.releaseDate;
  }

  get multimedia(): IMultimedia {
    return this.gameVersion.multimedia as IMultimedia;
  }

  set multimedia(val: IMultimedia) {
    this.gameVersion.multimedia = val;
  }

  get lastUpdate(): ILastUpdate {
    return this.gameVersion.lastUpdate;
  }

  get freeToPlay(): boolean {
    return !this.buyableTokenFactory && this.tokenFactories[0]?.freeToPlay;
  }

  // custom for frontend
  show_gallery?: boolean;

  constructor(game: IGame, develop?: boolean, fakeCloneGame = false) {
    super(game);
    this.fakeCloneGame = fakeCloneGame;
    this.gameVersion = develop ? <GameVersionModel>this.developmentGame : <GameVersionModel>this.liveGame;
    this.initSlug();
  }

  getUrl(): string {
    return `/games/${this.slug}/${this.id}/`;
  }

  getPreview(width = 0, height = 0): string {
    return this.hasPreviewImgs() ? this.getImgBySize(this.multimedia.imageGalleryList[0].images, width, height) : '';
  }

  getBoxArtImage(width = 0, height = 0): string {
    return this.getImage('boxArtImage', width, height);
  }

  getBgImg(width = 0, height = 0): string {
    return this.getImage('largeHeroImage', width, height);
  }

  getImage(imageType: string, width = 0, height = 0): string {
    return this.multimedia && this.multimedia[imageType]
      ? this.getImgBySize(this.multimedia[imageType].images, width, height)
      : '';
  }

  // Todo ask product team
  getBgResponsiveList(rules: ImageRules = defaultBgResponsiveListRules): string {
    return this.multimedia && this.multimedia.largeHeroImage
      ? this.getImgResponsiveList(this.multimedia.largeHeroImage.images, rules)
      : '';
  }

  getImgBySize(images: IImageDetail[] = [], width = 0, height = 0): string {
    if (images.length) {
      const foundImg: IImageDetail | undefined = images.find(
        (image) => image.width === width && image.height === height
      );
      return foundImg?.url || images[0].url;
    }
    return '';
  }

  getImgResponsiveList(images: IImageDetail[], rules: ImageRules): string {
    let url = '';

    for (const rule in rules) {
      if (Object.prototype.hasOwnProperty.call(rules, rule)) {
        url += `${this.getImgBySize(images)} ${rules[rule]}, `;
      }
    }

    return url;
  }

  hasPreviewImgs(): boolean {
    return this.multimedia?.imageGalleryList && this.multimedia.imageGalleryList.length !== 0;
  }

  isPublished(): boolean {
    return this.storePageStatus === 'PUBLISHED';
  }

  getBoxArtImageName(): string {
    return this.multimedia && this.multimedia.boxArtImage ? this.multimedia.boxArtImage.name : 'cover image';
  }

  unavailableGameMedia(): boolean {
    return (
      this.multimedia && this.multimedia.imageGalleryList.length === 0 && this.multimedia.videosPreviews.length === 0
    );
  }

  getReleaseDateObject(): Date {
    return this.releaseDate
      ? new Date(
          this.releaseDate.year,
          this.releaseDate.month ? this.releaseDate.month - 1 : 1,
          this.releaseDate.day || 1
        )
      : null;
  }

  getReleaseDateFormat(defaultType: DatePipeFormat): DatePipeFormat {
    return getDatePipeType(this.releaseDate, defaultType);
  }

  getTokenFactoryById(id: string): ITokenFactory {
    return this.tokenFactories.find((token: ITokenFactory) => token.id === id);
  }

  getTokenPrices(): ITokenPrice {
    return this.buyableTokenFactory?.prices && this.buyableTokenFactory.prices[0];
  }

  isTokenFactoryAlreadyInTheUserLibrary(id: string): boolean {
    return (
      this.ownedTokenFactories &&
      this.ownedTokenFactories.length > 0 &&
      this.ownedTokenFactories.some((ownedTokenFactory) => ownedTokenFactory.id === id)
    );
  }

  isGameBuyable(): boolean {
    return this.buyableTokenFactory !== null;
  }

  private initSlug() {
    if (this.gameVersion && this.gameVersion.title) {
      this.slug = new SlugPipe().transform(this.gameVersion.title);
    }
  }
}
