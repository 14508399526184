<div
  class="status-wrap status-text__{{ quantityStatus }} {{ showStatus ? 'showStatus px-2 ' : '' }} {{
    showLarge ? 'status-wrap-lg' : 'status-wrap-sm'
  }}"
  ultraAutotestAttribute="token-quantity-status"
  [ngbTooltip]="tooltipText"
  container="body"
  placement="top"
  tooltipClass="tooltip-no-arrow z-infinite tooltip--{{ tooltipColor }}">
  <i
    class="icon {{
      quantityStatus === uniqQuantityStatusEnum.NOT_AVAILABLE ? 'icon-shopping-trolley-disabled' : 'icon-uniq-quantity'
    }} {{ showLarge ? 'icon-24x' : 'icon-16x' }}">
  </i>
  @if (showStatus) {
    <span class="px-2" [style.color]="quantityStatusColor" i18n>{{ statusText }}</span>
  }
</div>
