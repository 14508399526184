import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AutotestAttributeDirective } from '../../autotests';

import { ControlErrorDirective } from './control-error.directive';
import { ControlErrorAnchorDirective } from './control-error-anchor.directive';
import { DefaultControlErrorComponent } from './default-control-error/default-control-error.component';
import { FormActionDirective } from './form-action.directive';
import { FORM_ERRORS, FormErrorsConfig, FormErrorsConfigProvider } from './providers';

@NgModule({
  imports: [CommonModule, AutotestAttributeDirective],
  declarations: [DefaultControlErrorComponent, ControlErrorAnchorDirective, FormActionDirective, ControlErrorDirective],
  exports: [DefaultControlErrorComponent, ControlErrorAnchorDirective, FormActionDirective, ControlErrorDirective],
})
export class FormErrorModule {
  static forRoot(config: FormErrorsConfig = {}): ModuleWithProviders<FormErrorModule> {
    return {
      ngModule: FormErrorModule,
      providers: [
        {
          provide: FormErrorsConfigProvider,
          useValue: config,
        },
        {
          provide: FORM_ERRORS,
          ...config.errors,
        },
      ],
    };
  }
}
