import {
  DateTimeDuration,
  DateTimeHelper,
  DateTimeUnit,
} from '../../../../../../../shared/src/lib/services/helpers/datetime.helper';
import { ExchangeDate, IUniqActionsPeriod, IUniqAvailability } from '../../../../models/uniq/interfaces';
import {
  ActionDisplayState,
  ActionDuration,
  ActionDurationStatus,
  ActionStatus,
  IdCardAction,
} from '../id-card-actions.model';

export const isPropertyLocked = (availability: IUniqAvailability | null) => !!availability?.isLocked;

export class IdCardActionsDisplayHelper {
  static getState(
    period: IUniqActionsPeriod | null,
    availability: IUniqAvailability | null,
    startDate?: ExchangeDate,
    endDate?: ExchangeDate,
  ): ActionDisplayState {
    const isLocked = !!availability?.isLocked;
    const actionDuration = IdCardActionsDisplayHelper.getActionDuration(period, availability);
    const actionsDurationUnit = IdCardActionsDisplayHelper.getActionDurationUnit(actionDuration.available);
    const lockedDurationUnit = IdCardActionsDisplayHelper.getActionDurationUnit(actionDuration.locked);
    const status = IdCardActionsDisplayHelper.getActionStatus(period, availability, endDate);
    const durationStatus = IdCardActionsDisplayHelper.getActionDurationStatus(period, availability);
    const color = isLocked
      ? IdCardActionsDisplayHelper.getLockedStatusColor()
      : IdCardActionsDisplayHelper.getActionStatusColor(status, durationStatus);

    return {
      status,
      duration: actionDuration,
      durationStatus,
      isLocked,
      lockedDurationUnit,
      availableDurationUnit: actionsDurationUnit,
      color,
      hasStartDate: !!(period?.startDate || startDate?.onSpecificDay),
      hasEndDate: !!(period?.endDate || endDate?.onSpecificDay),
    };
  }

  static getActionStatus(
    period: IUniqActionsPeriod | null,
    availability: IUniqAvailability | null,
    endDate?: ExchangeDate,
  ): ActionStatus {
    if (!period || !availability) {
      if (
        (period?.endDate || endDate?.onSpecificDay) &&
        new Date(period?.endDate || endDate.onSpecificDay).getTime() < Date.now()
      ) {
        return ActionStatus.DONE;
      }

      return ActionStatus.UNABLE;
    }

    if (availability?.isLocked) {
      return ActionStatus.PENDING;
    }

    if (period?.endDate) {
      return ActionStatus.TILL;
    }

    if (!period?.endDate) {
      return ActionStatus.ABLE;
    }
  }

  static getActionStatusColor(status: ActionStatus, durationStatus: ActionDurationStatus): string {
    switch (status) {
      case ActionStatus.TILL: {
        if (durationStatus === 'short') {
          return '#FB9393';
        }
        if (durationStatus === 'long') {
          return '#C5ABFF';
        }

        return '#FAD198';
      }
      case ActionStatus.ABLE: {
        return '#8CE895';
      }

      default:
        return 'rgba(255,255,255,0.5)';
    }
  }

  static getLockedStatusColor(): string {
    return '#C5ABFF';
  }

  static getActionDurationUnit(duration: DateTimeDuration): DateTimeUnit {
    if (duration.day > 0) {
      return DateTimeUnit.DAY;
    }

    if (duration.hour > 0) {
      return DateTimeUnit.HOUR;
    }

    return DateTimeUnit.MINUTE;
  }

  static getActionVerb(action: IdCardAction): string {
    switch (action) {
      case IdCardAction.TRADABILITY:
        return 'Tradable';
      case IdCardAction.TRANSFERABILITY:
        return 'Transferable';
      default:
        return '';
    }
  }

  static getFormattedDurationUnit(duration: DateTimeDuration, unit: DateTimeUnit): string {
    return `${unit}${duration[unit] == 1 ? '' : 's'}`;
  }

  static getFormattedDurationAmount(duration: DateTimeDuration, unit: DateTimeUnit): string {
    const amount = duration[unit];

    if (unit !== DateTimeUnit.MINUTE && unit !== DateTimeUnit.HOUR) {
      return `${amount}`;
    }

    if (unit === DateTimeUnit.HOUR) {
      const minutes = duration.minute - DateTimeHelper.hoursToMinutes(duration.hour);
      return `${amount < 10 ? '0' : ''}${amount}:${minutes < 10 ? '0' : ''}${minutes}`;
    }

    return `00:${amount < 10 ? '0' : ''}${amount}`;
  }

  static getFormattedDuration(duration: DateTimeDuration, unit: DateTimeUnit): string {
    return duration[unit] !== 0
      ? `${duration[unit]} ${IdCardActionsDisplayHelper.getFormattedDurationUnit(duration, unit)}`
      : '';
  }

  private static getActionDurationStatus(
    period: IUniqActionsPeriod | null,
    availability: IUniqAvailability | null,
  ): ActionDurationStatus {
    const actionDuration = IdCardActionsDisplayHelper.getActionDuration(period, availability);
    const durationUnit = IdCardActionsDisplayHelper.getActionDurationUnit(actionDuration.available);

    switch (durationUnit) {
      case DateTimeUnit.DAY:
        if (actionDuration.available.day > 90) {
          return 'long';
        }
        break;
      case DateTimeUnit.HOUR:
      case DateTimeUnit.MINUTE:
        return 'short';
    }
    return 'regular';
  }

  private static getActionDuration(
    period: IUniqActionsPeriod | null,
    availability: IUniqAvailability | null,
  ): ActionDuration {
    const isLocked = availability?.isLocked;

    return {
      available: DateTimeHelper.msToDatetimeDuration(
        isLocked ? Number(period?.duration) : Number(availability?.duration),
      ),
      locked: DateTimeHelper.msToDatetimeDuration(Number(availability?.duration)),
    };
  }
}
