import { VideoProvider } from '../../../multimedia/interfaces/video.interface';
import { Game } from '../../models/game.model';

export const testGame: Game = new Game({
  id: '123456767898',
  liveGame: {
    title: 'Dead Cells',
    description: 'Description test',
    descriptionShort: 'In the open world of Wild Hunt, you chart your own path to adventure.',
    categories: ['Action', 'RPG'],
    editorName: 'Guerrilla Games',
    releaseDate: {
      year: 2020,
      month: 7,
      day: 27,
    },
    multimedia: {
      largeHeroImage: {
        name: 'background image',
        fileExtension: 'jpg',
        base: {
          url: '/assets/images/tmp/games/05_Dead-Cells/01_BG.jpg',
          height: 800,
          width: 1430,
        },
        images: [
          {
            url: '/assets/images/tmp/games/05_Dead-Cells/01_BG.jpg',
            height: 800,
            width: 1430,
          },
          {
            url: '/assets/images/tmp/games/05_Dead-Cells/01_BG.jpg',
            height: 800,
            width: 1430,
          },
          {
            url: '/assets/images/tmp/games/05_Dead-Cells/01_BG.jpg',
            height: 800,
            width: 1430,
          },
        ],
      },
      boxArtImage: {
        name: 'cover image',
        fileExtension: 'jpg',
        base: {
          url: '/assets/images/tmp/games/05_Dead-Cells/05_Cover.jpg',
          height: 240,
          width: 180,
        },
        images: [
          {
            url: '/assets/images/tmp/games/05_Dead-Cells/05_Cover.jpg',
            height: 240,
            width: 180,
          },
          {
            url: '/assets/images/tmp/games/05_Dead-Cells/05_Cover.jpg',
            height: 240,
            width: 180,
          },
          {
            url: '/assets/images/tmp/games/05_Dead-Cells/05_Cover.jpg',
            height: 240,
            width: 180,
          },
        ],
      },
      imageGalleryList: [
        {
          name: 'gal image 0',
          fileExtension: 'jpg',
          base: {
            url: '/assets/images/tmp/games/05_Dead-Cells/02_MainCapsule.jpg',
          },
          images: [
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/02_MainCapsule.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/02_MainCapsule.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/02_MainCapsule.jpg',
            },
          ],
        },
        {
          name: 'gal image 1',
          fileExtension: 'jpg',
          base: {
            url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery1.jpg',
          },
          images: [
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery1.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery1.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery1.jpg',
            },
          ],
        },
        {
          name: 'gal image 2',
          fileExtension: 'jpg',
          base: {
            url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery2.jpg',
          },
          images: [
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery2.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery2.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery2.jpg',
            },
          ],
        },
        {
          name: 'gal image 3',
          fileExtension: 'jpg',
          base: {
            url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery3.jpg',
          },
          images: [
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery3.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery3.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery3.jpg',
            },
          ],
        },
        {
          name: 'gal image 4',
          fileExtension: 'jpg',
          base: {
            url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery4.jpg',
          },
          images: [
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery4.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery4.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery4.jpg',
            },
          ],
        },
        {
          name: 'gal image 5',
          fileExtension: 'jpg',
          base: {
            url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery5.jpg',
          },
          images: [
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery5.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery5.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery5.jpg',
            },
          ],
        },
        {
          name: 'gal image 6',
          fileExtension: 'jpg',
          base: {
            url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery6.jpg',
          },
          images: [
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery6.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery6.jpg',
            },
            {
              url: '/assets/images/tmp/games/05_Dead-Cells/03_Gallery6.jpg',
            },
          ],
        },
      ],
      videosPreviews: [
        {
          videoId: 'KV6fBYuuPMg',
          provider: VideoProvider.Youtube,
          duration: 6.35,
          image: {
            name: 'video image 3',
            images: [
              {
                url: '/assets/images/tmp/games/05_Dead-Cells/04_Trailer.jpg',
              },
            ],
          },
        },
      ],
    },
    // status: GameStatus.published,
    lastUpdate: {
      date: '2019-01-09T00:00:00.000Z',
      version: '1.0.1a',
    },
  },
});
