enum tagsToDelete {
  Adyen = 'adyen',
  PaymentFrame = 'payment-frame',
}

enum fileType {
  Js = 'js',
  Css = 'css',
}

const fileTypes = {
  [fileType.Js]: [tagsToDelete.Adyen, tagsToDelete.PaymentFrame],
  [fileType.Css]: [tagsToDelete.Adyen],
};

export function removeTagsByAttribute(type: fileType, tags: string[]): void {
  const targetElement = type === fileType.Js ? 'script' : type === fileType.Css ? 'link' : null;
  const targetAttr = type === fileType.Js ? 'src' : type === fileType.Css ? 'href' : null;
  if (!targetElement || !targetAttr) {
    return;
  }
  tags
    .map((item) => Array.from(document.querySelectorAll(`${targetElement}[${targetAttr}*="${item}"]`)))
    .reduce((a, b) => a.concat(b), [])
    .forEach((item) => item.parentNode.removeChild(item));
}

export function removePaymentScripts() {
  Object.keys(fileTypes).forEach((type) => removeTagsByAttribute(type as fileType, fileTypes[type]));
}

export function isScriptExist(targetAttr: string, value: string, targetElement = 'script'): boolean {
  return !!document.querySelector(`${targetElement}[${targetAttr}*="${value}"]`);
}
