import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGraphQLService, ApolloClientName } from '@ultra/core/graphql';

import { ApiSharedGqlConfig } from '../../graphql-client';
import { setUniqOwner, setUniqOwnerPaginatable } from '../../helpers';
import { IUniq, IUniqStatusFilter, Paginatable, Pagination } from '../../models';
import { IUniqFactory } from '../../uniq-factory/uniq-factory.interface';

import { ResaleParams } from './interfaces/resale-params.interface';

@Injectable({
  providedIn: 'root',
})
export class UniqGqlService {
  constructor(private apiService: ApiGraphQLService) {}

  getUniqById(id: string) {
    return this.apiService
      .getQuery(ApiSharedGqlConfig.UNIQ_BY_ID, { id }, { apolloClient: 'tokenfactories', cache: false })
      .pipe(map((result) => result.data.uniq.find));
  }

  getBasicUniqInfoById(id: string) {
    return this.apiService
      .getQuery(ApiSharedGqlConfig.GET_UNIQ_QUERY, { id }, { apolloClient: 'tokenfactories', cache: false })
      .pipe(map((result) => result.data.uniq.find));
  }

  getUniqInventoryList$(pagination: Pagination): Observable<Paginatable<IUniq[]>> {
    return this.apiService
      .getQuery<{
        uniq: { inventory: { data: IUniq[] } };
      }>(ApiSharedGqlConfig.INVENTORY_LIST, { pagination }, { apolloClient: 'tokenfactories', cache: false })
      .pipe(
        map((result) => result.data.uniq.inventory),
        map(setUniqOwnerPaginatable),
      );
  }

  getUniqForSaleFee(id: string, price: any): Observable<Partial<IUniqFactory>> {
    return this.apiService
      .getQuery(ApiSharedGqlConfig.UNIQ_SALE_FEE, { id, price }, { apolloClient: 'tokenfactories', cache: false })
      .pipe(
        map((result) => {
          return result.data.uniqFactory.find;
        }),
      );
  }

  getUniqForSale(resaleParams: ResaleParams): Observable<Partial<IUniq>> {
    return this.apiService
      .getQuery(
        ApiSharedGqlConfig.GET_UNIQ_FOR_SALE,
        { ...resaleParams },
        { apolloClient: ApolloClientName.TOKEN_FACTORIES, cache: false },
      )
      .pipe(
        map((result) => result.data.uniq.find),
        map(setUniqOwner),
      );
  }

  /**
   * Gets the list of owned Uniqs Ids filtered by the Uniq Factory Ids from which they were minted.
   *
   * @returns list of owned Uniqs filtered by factory ids from which they were minted.
   * @returns empty array in case it the user doesn't own any Uniqs minted from specified factory id.
   * Returns Uniq[] only for those owned.
   */
  getUniqInventoryListByFactoryIds$(filter: Partial<IUniqStatusFilter>): Observable<{ data: IUniq[] }> {
    return this.apiService
      .getQuery<{
        uniq: { inventory: { data: IUniq[] } };
      }>(
        ApiSharedGqlConfig.INVENTORY_LIST_FILTER,
        { filter, blockStep: 'IRREVERSIBLE' },
        { apolloClient: 'tokenfactories', cache: false },
      )
      .pipe(map((result) => result.data.uniq.inventory));
  }
}
