export const errorDataByCode = {
  '401': {
    code: '401',
    title: 'Oops, looks like something went wrong.',
    suggestions: [
      {
        title:
          'You’re not authorized to access the application network. Check your settings and try to reload this page. If the problem persists, contact us and we’ll get right on it!',
      },
    ],
    actions: [
      {
        type: 'externalLink',
        cssClass: 'main',
        href: '',
        title: 'Reload',
      },
      {
        type: 'externalLink',
        cssClass: 'side',
        href: 'mailto:support@ultra.io',
        title: 'Contact Us',
      },
    ],
  },
  '403': {
    code: '403',
    title: 'You are not allowed to access this application.',
  },
  forbidden: {
    code: '403',
    title: 'This area is forbidden.',
    suggestions: [
      {
        title: 'You may want to head back to the homepage.',
      },
      {
        title: 'If you think this is an error, report a problem.',
      },
    ],
    actions: [
      {
        type: 'link',
        cssClass: 'main',
        href: '/',
        title: 'Go to homepage',
      },
      {
        type: 'externalLink',
        cssClass: 'side',
        href: 'mailto:support@ultra.io',
        title: 'Report a problem',
      },
    ],
  },
  '404': {
    code: '404',
    title: 'Oops, the page you’re looking for does not exist.',
    suggestions: [
      {
        title: 'You may want to head back to the homepage.',
      },
      {
        title: 'If you think something is broken, report a problem.',
      },
    ],
    actions: [
      {
        type: 'link',
        cssClass: 'main',
        href: '/',
        title: 'Go to homepage',
      },
      {
        type: 'externalLink',
        cssClass: 'side',
        href: 'mailto:support@ultra.io',
        title: 'Report a problem',
      },
    ],
  },
  '451': {
    code: '451',
    title: 'Oops, this page has been temporarily removed by Ultra.',
    suggestions: [
      {
        title: 'You may want to head back to the homepage.',
      },
      {
        title: 'If you think something is broken, report a problem.',
      },
    ],
    actions: [
      {
        type: 'link',
        cssClass: 'main',
        href: '/',
        title: 'Go to homepage',
      },
      {
        type: 'externalLink',
        cssClass: 'side',
        href: 'mailto:support@ultra.io',
        title: 'Report a problem',
      },
    ],
  },
  server_error: {
    code: '503',
    title: 'Looks like we’re having some server issues.',
    suggestions: [
      {
        title: 'Go back to the previous page and try again.',
      },
    ],
    actions: [
      {
        type: 'link',
        cssClass: 'main',
        href: '/',
        title: 'Back',
      },
      {
        type: 'externalLink',
        cssClass: 'side',
        href: 'mailto:support@ultra.io',
        title: 'Report a problem',
      },
    ],
  },
  maintenance: {
    code: 'Maintenance',
    title: 'We’re undergoing a bit of scheduled maintenance.',
    suggestions: [
      {
        title: 'Sorry for the inconvenience.',
      },
      {
        title: 'We’ll be back up and running as fast as possible.',
      },
    ],
  },
  'ultraos-error': {
    code: 'ultraos-error',
    title: 'Oops, looks like something went wrong.',
    suggestions: [
      {
        title: 'Would you mind restarting Ultra OS?',
      },
    ],
  },
};
