import { Pipe, PipeTransform } from '@angular/core';

import { LanguageInterface } from '../../interfaces/languages/languages.interface';

@Pipe({
  name: 'langFormatter',
})
export class LanguageFormatterPipe implements PipeTransform {
  transform(code: string, languages: LanguageInterface[]): string {
    if (languages) {
      const language = languages.find((x) => x.code.toLowerCase() === code.toLowerCase());
      return language ? language.label : code;
    }
  }
}
