export enum UniqsSearchIndex {
  UNIQ_CREATED_DESC = 'uniqs_updated_at_desc',
  UNIQ_SELLING_PRICE_UOS_DESC = 'uniqs_selling_price_UOS_desc',
  UNIQ_SELLING_PRICE_UOS_ASC = 'uniqs_selling_price_UOS_asc',
}

export enum UniqFactoriesSearchIndex {
  UNIQ_FACTORIES_PRICE_USD_DESC = 'uniq_factories_price_USD_desc',
  UNIQ_FACTORIES_PRICE_USD_ASC = 'uniq_factories_price_USD_asc',
  UNIQ_FACTORIES_CREATED_DESC = 'uniq_factories_created_at_desc',
}
