import { Options } from '@popperjs/core/lib/types';

export class BootstrapHelper {
  static getTooltipPopperOptions(options: Partial<Options>): Partial<Options> {
    return {
      ...options,
      strategy: 'fixed',
    };
  }
}
