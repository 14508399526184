<div class="s1-bold pb-3">
  <ng-container *contentSkeleton="isRouteChange; theme: placeholderTheme.MD_TITLE" i18n>Features</ng-container>
</div>

@if (!isRouteChange) {
  <ul>
    @for (feature of game?.features | slice: 0 : 15; track feature) {
      <li
        class="d-flex text-white-alpha-7 caption-regular mb-3 align-items-center text-capitalize"
        ultraAutotestAttribute="game-details-features-item">
        <i
          class="icon opacity me-2 icon-{{ game.getFeatureIcon(feature) }}"
          [ultraAutotestAttribute]="'game-details-features-item--' + feature"></i>
        {{ feature }}
      </li>
    }
  </ul>
} @else {
  <div class="col-10">
    <div *contentSkeleton="true; repeat: 5; className: ['mb-1', 'rounded-5']; height: '32px'"></div>
  </div>
}
