/******** Type Image ********/
export const IMAGE_DETAIL_GQL = `
{
  width
  height
  url
  size
  uploadDate
}
`;

export const IMAGE_GQL = `
{
  name
  images {
    width
    height
    url
  }
}`;

export const GDC_IMAGE_GQL = `
{
  name
  fileExtension
  base ${IMAGE_DETAIL_GQL}
  images ${IMAGE_DETAIL_GQL}
}
`;

export const GDC_IMAGE_WITH_ORDER_GQL = `
{
  name
  fileExtension
  displayOrder
  base ${IMAGE_DETAIL_GQL}
  images ${IMAGE_DETAIL_GQL}
}
`;

/******** Systems Requirements ********/
export const REQUIREMENT_GQL = `
{
  os
  processor
  memory
  graphics
  storage
  soundCard
  minimumResolution
}`;

export const SYSTEM_REQUIREMENTS_GQL = `
  systemsRequirements {
    osName
    minimum ${REQUIREMENT_GQL}
    recommended ${REQUIREMENT_GQL}
   }
`;

/******** Repository ********/
export const REPOSITORY_DATA = `
{
  id
  gameId
  publisherId
  name
  ssnRepositoryName
  osPlatform
  repositoryType
  language
  isProtected
  repositoryVersions {
    id
    version
    userId
    comment
    ssnReleaseId
    size
    filesCount
    uploadDate
    status
  }
  build {
    id
    name
  }
}`;

/******** Builds ********/
export const BUILDS_GQL = `
builds {
  id
  name
  osPlatform
  buildType
  repository ${REPOSITORY_DATA}
  assemblies {
    id
    repositoryVersion
    version
    compatibilityMatrix {
      dlcRepositories {
        repositoryId
        version
      }
      languageRepositories {
        repositoryId
        version
      }
      dsRepository {
        repositoryId
        version
      }
      demoLanguagesRepositories {
        repositoryId
        version
      }
    }
    comment
    size
    branches
    executableFile32
    executableFile64
    argumentExecutableFile32
    argumentExecutableFile64
  }
}
`;

/******** Age rating********/
export const AGE_RATING = `
 ageRating {
    ageRatingId
    country
    name
    category {
      categoryId
      name
      image
    }
    descriptors {
      descriptorId
      name
      image
    }
    descriptorsDescription
  }
`;

/******** Rating ********/
export const RATING_GQL = `
  rating {
    metascore {
    value
    totalNumberOfRatings
    }
    metaCriticsUserScore {
    value
    totalNumberOfRatings
    }
    awards {
      name
      image ${GDC_IMAGE_GQL}
      category
      link
    }
}`;

/******** Languages ********/
export const LANGUAGES_GQL = `
  languages {
    audio
    subtitles
    interfaces
}`;

/******** Game Main Info ********/
export const GAME_INFO_GQL = `
  id
  title
`;

/******** Token Factory Content Type ********/
export const TOKEN_FACTORY_CONTENT = `{
  id
  code
  icon
  name
}`;

export const RECOMMENDED_UNIQS = `{
  title
  uniqFactories {
    ... on GameRecommendedUniqsUniqFactoriesGroup {
      type
      uniqFactoryGroupId
    }
    ... on GameRecommendedUniqsUniqFactoriesList  {
      type
      uniqFactoryOnChainIds
    }
  }
}`;

export const PRICES = `
  {
    currency
    amount
    symbol
  }
`;

export const TOKEN_FACTORY_PRICE = `
  {
    currency
    amount
    symbol
    discount
    originalAmount
    discountEndDate
  }
`;

/******** Game Data for Carousel ********/
export const DATA_FOR_CAROUSEL_GQL = `
   id
   buyableTokenFactory {
    id
    prices ${TOKEN_FACTORY_PRICE}
   }
   tokenFactories {
    freeToPlay
   }
   liveGame {
    title
    editorName
    multimedia {
      imageGalleryList {
        name
        images {
           url
        }
      }
    }
   }
`;

export const COMPANY = `{
  id
}`;

export const COMPANY_DATA = `{
  id
  name
}`;

/******** Game Version without Build ********/
export const GAME_VERSION_GQL_WITHOUT_BUILD = `
{
      title
      multimedia {
        largeHeroImage ${GDC_IMAGE_GQL}
        boxArtImage ${GDC_IMAGE_GQL}
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
        primaryGameImage ${GDC_IMAGE_GQL}
        secondaryGameImage ${GDC_IMAGE_GQL}
        gameIcon ${GDC_IMAGE_GQL}
        videosPreviews {
          videoId
          provider
          duration
          title
          displayOrder
          image {
            name
            fileExtension
            base ${IMAGE_DETAIL_GQL}
          }
        }
      }
      categories
      developerName
      editorName
      releaseDate {
        year
        month
        day
      }
      tags
      description
      descriptionShort
      playingModes
      features
      links {
       name
       url
      }
      ${RATING_GQL}
      ${LANGUAGES_GQL}
      ${SYSTEM_REQUIREMENTS_GQL}
      ${AGE_RATING}
}`;

/******** Game Version ********/
export const GAME_VERSION_GQL = `
{
      title
      multimedia {
        largeHeroImage ${GDC_IMAGE_GQL}
        boxArtImage ${GDC_IMAGE_GQL}
        imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
        primaryGameImage ${GDC_IMAGE_GQL}
        secondaryGameImage ${GDC_IMAGE_GQL}
        gameIcon ${GDC_IMAGE_GQL}
        videosPreviews {
          videoId
          provider
          duration
          title
          displayOrder
          image {
            name
            fileExtension
            base ${IMAGE_DETAIL_GQL}
          }
        }
      }
      categories
      developerName
      editorName
      releaseDate {
        year
        month
        day
      }
      tags
      description
      descriptionShort
      playingModes
      features
      links {
       name
       url
      }
      ${RATING_GQL}
      ${LANGUAGES_GQL}
      ${SYSTEM_REQUIREMENTS_GQL}
      ${BUILDS_GQL}
      ${AGE_RATING}
      recommendedUniqs ${RECOMMENDED_UNIQS}
    }`;

export const FILTER_LIST = `
  {
      categories {
        category
        totalGames
      }
      tags {
        tag
        totalGames
      }
      features {
        feature
        totalGames
      }
      playingModes {
        playingMode
        totalGames
      }
      metascores {
        metascore
        totalGames
      }
 }
`;
