import { Directive, HostBinding, InjectionToken, Input } from '@angular/core';

export const ULTRA_ERROR = new InjectionToken<UltraErrorDirective>('UltraError');

@Directive({
  selector: 'ultra-error',
  providers: [{ provide: ULTRA_ERROR, useExisting: UltraErrorDirective }],
})
export class UltraErrorDirective {
  private nextUniqueId = 0;
  @Input()
  @HostBinding('attr.id')
  id = this.getUniqId();

  private getUniqId(): string {
    this.nextUniqueId++;
    return `ultra-error-${this.nextUniqueId}`;
  }
}
