import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import {
  SearchInsightsEventType,
  SearchInsightsObjectType,
} from '../../../../algolia-search-insights/algolia-search-insights.interface';
import { IUniq } from '../../../../models/uniq/interfaces/uniq.interface';
import { Dialog } from '../../../dialog';
import { IdCardAdapter, IdCardEntity, UniqIdCardAdapter } from '../../../id-card-content';
import { UniqDetailDialogComponent } from '../../../uniq-preview/uniq-detail-dialog/uniq-detail-dialog.component';
import { UniqFactoryDetailDialogComponent } from '../../../uniq-preview/uniq-factory-detail-dialog/uniq-factory-detail-dialog.component';

@UntilDestroy()
@Component({
  selector: 'ultra-uniq-list',
  templateUrl: './uniq-list.component.html',
  styleUrls: ['./uniq-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqListComponent {
  readonly SearchInsightsClickEventTypes = SearchInsightsEventType;
  readonly SearchInsightsObjectTypes = SearchInsightsObjectType;

  @Input() uniqs: IUniq[] = [];
  @Input() forSale: boolean;
  @Input() fetchOnOpen = false;
  @Input() userBlockChainId: string;
  @Input() showTradability = true;
  @Input() showTransferability = true;
  @Input() showAvailability = true;
  @Input() showStatusIcons = true;
  @Input() shouldValidateMetadata = false;

  @Output() buy = new EventEmitter<IUniq>();

  trackBy(_: number, item: IUniq): string {
    return item.id;
  }

  onBuy(uniq: IUniq) {
    this.buy.emit(uniq);
  }

  private viewContainerRef = inject(ViewContainerRef);
  private dialog = inject(Dialog);

  openUniqDialog(uniq): void {
    const { component, config } = this.getDetailsConfig(uniq);
    this.dialog
      .openFromComponent(component, config)
      .componentInstance.buy.pipe(take(1), untilDestroyed(this))
      .subscribe(() => this.onBuy(uniq));
  }
  private idCardAdapter: IdCardAdapter<IUniq> = new UniqIdCardAdapter();
  private getDetailsConfig(uniq) {
    const defaultConfig = {
      viewContainerRef: this.viewContainerRef,
      backdropClass: 'uniq-backdrop',
      disableClose: true,
      width: 'calc(100vw - 40px)',
      maxWidth: 1047,
    };
    const idCardEntity: IdCardEntity = this.idCardAdapter.transform(uniq);
    switch (idCardEntity.type) {
      case 'uniq':
        return {
          component: UniqDetailDialogComponent,
          config: {
            ...defaultConfig,
            data: {
              forSale: this.forSale,
              idCardEntity: idCardEntity,
              uniq: uniq,
              userBlockChainId: this.userBlockChainId,
              shouldValidateMetadata: this.shouldValidateMetadata,
            },
            panelClass: 'uniq-detail-overlay',
          },
        };
      case 'uniqfactory':
        return {
          component: UniqFactoryDetailDialogComponent,
          config: {
            ...defaultConfig,
            data: {
              uniq: uniq,
            },
            panelClass: 'uniq-factory-detail-overlay',
          },
        };
      default:
        return null;
    }
  }
}
