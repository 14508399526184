/* eslint no-console: 0 */
import { Inject, Injectable } from '@angular/core';
import flatten from 'lodash-es/flatten';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';

import { RenewRoutineEventStatusCode } from '@ultra/core/models';
import { RepositoryStates, UltraosApiService } from '@ultra/core/services';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { IUserGameBranch, RepositoryType } from '../..';
import { UserLibraryGqlService } from '../user-library/user-library-gql.service';

import { getTicketIdByRepositoryId } from './license-manage.helper';

export interface IRepositoryInstalledStatusById {
  [repositoryId: string]: RepositoryStates;
}

/**
 * A service to handle game license.
 */
@Injectable({ providedIn: 'root' })
export class LicenseManageService {
  /**
   * ONLY FOR QA TEAM.
   * We don't have another way to debug which tickets id we send to the client.
   */
  private isAllowedToDisplayLogs = false;

  constructor(
    private ultraosApiService: UltraosApiService,
    private userlibraryGqlService: UserLibraryGqlService,
    @Inject(APP_CONFIG) private readonly environment: IEnvironment,
  ) {
    this.isAllowedToDisplayLogs = ['dev', 'qa', 'localhost'].includes(this.environment.name);
    this.setLicenceCentralGateway();
  }

  /**
   * Check for all the ticket ids of the game and dlcs, the one that are activated.
   * @returns those that are not activated or empty array if all are activated.
   */
  getNotActivatedTicketsIds(ticketIds: string[]): Observable<string[]> {
    return this.ultraosApiService.checkLicenseActivation(ticketIds).pipe(
      map(({ response }) => response),
      tap((ticketIds) => {
        if (this.isAllowedToDisplayLogs) {
          console.warn('Not activated ticketIds ', ticketIds);
        }
      }),
      /*
       * If `checkLicenseActivation` fails, we return the ticketIds as it is.
       * It will mainly fail if the user has not containers created so for that
       * reason we return the ticketIds to activate them.
       * `activateLicense` will create the container in that case.
       */
      catchError(() => of(ticketIds)),
    );
  }

  activateLicenses(ticketIds: string[]): Observable<void> {
    if (this.isAllowedToDisplayLogs) {
      console.warn('activating ', ticketIds);
    }
    return this.ultraosApiService.activateLicense(ticketIds).pipe(mergeMap(() => this.renewLicenses()));
  }

  /**
   * Deactivate the given ticket ids.
   * This will send deactivate a license to Wibu.
   */
  deactivateLicense(ticketIdsToDeactivate: string[]): Observable<void> {
    if (this.isAllowedToDisplayLogs) {
      console.warn('deactivating ', ticketIdsToDeactivate);
    }
    return this.ultraosApiService.deactivateLicense(ticketIdsToDeactivate).pipe(switchMap(() => this.renewLicenses()));
  }

  renewLicenses(): Observable<void> {
    return this.userlibraryGqlService.getUserLibraryGames().pipe(
      filter(({ data }) => !!data.length),
      switchMap(({ data }) => this.getInstalledGames(data)),
      switchMap((games) => (games.length > 0 ? this.getTicketIdsOfInstalledRepositories(games) : of([]))),
      switchMap((ticketIds: string[]) => this.setupRenewRoutine(ticketIds)),
    );
  }

  /**
   * Given an array of games returns the ones that are installed.
   */
  getInstalledGames(games: IUserGameBranch[]): Observable<IUserGameBranch[]> {
    return from(games).pipe(
      concatMap((game) =>
        this.isGameInstalled(game).pipe(
          filter((isInstalled) => Boolean(isInstalled)),
          map(() => game),
        ),
      ),
      toArray(),
    );
  }

  getTicketIdsOfInstalledRepositories(games: IUserGameBranch[]): Observable<string[]> {
    const ticketIds = games.reduce(
      (acc, game) => {
        const dlcTickets$ = this.getTicketIdOfInstalledDlcPerGame(game);
        acc.dlc.push(dlcTickets$);
        acc.game.push(of(this.getTicketIdOfInstalledGame(game)));
        return acc;
      },
      { game: [], dlc: [] },
    );
    const tickets$: Observable<string>[] = [...ticketIds.dlc, ...ticketIds.game];
    return zip(...tickets$).pipe(
      map((foundedTicketIds) => flatten(foundedTicketIds).filter((ticket) => Boolean(ticket))),
    );
  }

  private isGameInstalled(game: IUserGameBranch): Observable<boolean> {
    if (!game.buildContentNodes[0]) {
      return of(false);
    }
    return this.ultraosApiService
      .isRepositoryInstalled(game.buildContentNodes[0].content.repositoryId, game.branchType.toLowerCase(), null)
      .pipe(map((result) => Object.values(result)[0] === RepositoryStates.INSTALLED));
  }

  setupRenewRoutine(ticketIds: string[]): Observable<void> {
    if (this.isAllowedToDisplayLogs) {
      console.warn('ticketIds to renew - ', ticketIds);
      console.warn('time delta in hours setup renew dev - ', this.environment.licenseRenewTime);
    }
    return this.ultraosApiService.setupRenewRoutine(ticketIds, '', +this.environment.licenseRenewTime);
  }

  listenToRenewRoutineEvents(): Observable<void> {
    return this.ultraosApiService.subscribeToRenewRoutineEvents().pipe(
      filter(({ status, status_code }) => !status && status_code !== RenewRoutineEventStatusCode.SUCCESS),
      tap(({ response, status_code, status_message }) => {
        if (this.isAllowedToDisplayLogs) {
          console.warn(`ids: ${response}; status code: ${status_code}; status message: ${status_message}`);
        }
      }),
      switchMap(({ response, status }) => {
        if (!status) {
          return this.renewLicenses();
        }
        return this.setupRenewRoutine(response);
      }),
    );
  }

  unsubscribeFromRenewRoutineEvents() {
    this.ultraosApiService.unsubscribeFromRenewRoutineEvents();
  }

  private getTicketIdOfInstalledDlcPerGame(game: IUserGameBranch): Observable<string[]> {
    return this.getDlcRepositoryInstalledStatusById(game).pipe(
      map((result: { [key: string]: string }[]) => {
        return result.reduce((acc, item) => {
          const [repositoryId, installedStatus] = Object.entries(item)[0];
          const ticketId = getTicketIdByRepositoryId(repositoryId, game);
          if (installedStatus === RepositoryStates.INSTALLED && ticketId) {
            acc.push(ticketId);
          }
          return acc;
        }, []);
      }),
    );
  }

  private getDlcRepositoryInstalledStatusById(game: IUserGameBranch): Observable<IRepositoryInstalledStatusById[]> {
    const dlcInstallStatusMap = game.buildContentNodes[0].compatibleContents.reduce((acc, item) => {
      if (item.repositoryType === RepositoryType.DLC) {
        const parentRepositoryId = game.buildContentNodes[0].content.repositoryId;
        const branchType = game.branchType.toLowerCase();
        acc.push(
          this.ultraosApiService
            .isRepositoryInstalled(item.repositoryId, branchType, parentRepositoryId)
            .pipe(map((value) => ({ [item.repositoryId]: Object.values(value)[0] as RepositoryStates }))),
        );
      }
      return acc;
    }, []);
    return dlcInstallStatusMap.length ? zip<IRepositoryInstalledStatusById[]>(...dlcInstallStatusMap) : of([]);
  }

  private getTicketIdOfInstalledGame(installedGame: IUserGameBranch): string {
    return installedGame.buildContentNodes[0].content.tokens[0].license.ticketId;
  }

  private setLicenceCentralGateway(): void {
    this.ultraosApiService.setLicenceCentralGateway(this.environment.licenceCentralGateway);
  }
}
