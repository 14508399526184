import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IStorageService } from './interfaces/storage.interface';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService implements IStorageService {
  constructor(protected storage: Storage) {}

  set(key: string, value: string): Observable<void> {
    this.storage.setItem(key, value);
    return of(undefined);
  }
  get(key: string): Observable<string> {
    return of(this.storage.getItem(key) || '');
  }
  setObject<T>(key: string, value: T): Observable<void> {
    this.set(key, JSON.stringify(value));
    return of(undefined);
  }
  getObject<T>(key: string): Observable<T | null> {
    return this.get(key).pipe(
      map((data) => {
        return data ? JSON.parse(data) : null;
      }),
    );
  }
  remove(key: string): Observable<void> {
    this.storage.removeItem(key);
    return of(undefined);
  }
}
