import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class CustomOAuthService extends OAuthService {
  protected override setupSilentRefreshEventListener(): void {
    this.removeSilentRefreshEventListener();

    this.silentRefreshPostMessageEventListener = (e: MessageEvent) => {
      const message = this.processMessageEventMessage(e);
      // Filter unwanted messages that might be coming from other origins or extensions
      if (e.origin !== location.origin || !message) {
        return;
      }
      this.tryLogin({
        customHashFragment: message,
        preventClearHashAfterLogin: true,
        customRedirectUri: this.silentRefreshRedirectUri || this.redirectUri,
      }).catch((err) => this.debug('tryLogin during silent refresh failed', err));
    };

    window.addEventListener('message', this.silentRefreshPostMessageEventListener);
  }
}
