<div class="supply d-flex">
  <div class="supply-block mb-2">
    <span class="text-white-alpha-5 f-size-12 me-2" i18n>Max supply</span>
    <span class="text-white f-size-16 fw-bold">
      {{ quantityAvailability?.maxCirculatingSupply | number: '' : 'en-US' }}
    </span>
  </div>
  <div class="supply-block mb-2">
    <div class="d-flex align-items-center me-2">
      @if (quantityAvailability?.quantityLeft > 0) {
        <span class="supply-block-bar supply-block-bar__empty me-2"></span>
      }
      <span class="text-white-alpha-5 f-size-12" i18n>Minted</span>
    </div>
    <div class="text-white-alpha-5 f-size-16 fw-bold">
      {{ quantityAvailability?.minted | number: '' : 'en-US' }}
    </div>
  </div>
  @if (quantityAvailability?.reserved || quantityAvailability?.reserved === 0) {
    <div class="supply-block mb-2">
      <div class="d-flex align-items-center me-2" i18n>
        @if (quantityAvailability?.quantityLeft > 0) {
          <span class="supply-block-bar supply-block-bar__filled me-2"></span>
        }
        <span class="text-white-alpha-5 f-size-12">Reserved</span>
      </div>
      <div class="text-white-alpha-5 f-size-16 fw-bold">
        {{ quantityAvailability?.reserved | number: '' : 'en-US' }}
      </div>
    </div>
  }
  <div class="supply-block">
    <div class="d-flex align-items-center me-2">
      @if (quantityAvailability?.quantityLeft > 0) {
        <span class="supply-block-bar me-2" [style.background]="quantityStatusColor"></span>
      }
      <span class="text-white-alpha-5 f-size-12" i18n>Available</span>
    </div>
    <div
      class="f-size-16 fw-bold"
      [style.color]="quantityStatusColor"
      ultraAutotestAttribute="available-uniq-quantity-content">
      {{ quantityAvailability?.quantityLeft | number: '' : 'en-US' }}
    </div>
  </div>
</div>
