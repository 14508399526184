const msToDaysDivider = 86400000;
const msToHoursDivider = 3600000;
const msToMinutesDivider = 60000;
const minutesToHoursDivider = 60;

export enum DateTimeUnit {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
}

export type DateTimeDuration = {
  [key in DateTimeUnit]?: number;
};

export class DateTimeHelper {
  static msToDatetimeDuration(durationMs = 0): DateTimeDuration {
    return {
      day: DateTimeHelper.msToDays(durationMs),
      hour: DateTimeHelper.msToHours(durationMs),
      minute: DateTimeHelper.msToMinutes(durationMs),
    };
  }

  static msToDays(durationMs: number): number {
    return Math.floor(durationMs / msToDaysDivider);
  }

  static msToHours(durationMs: number): number {
    return Math.floor(durationMs / msToHoursDivider);
  }

  static msToMinutes(durationMs: number): number {
    return Math.floor(durationMs / msToMinutesDivider);
  }

  static hoursToMinutes(durationMinutes): number {
    return durationMinutes * minutesToHoursDivider;
  }
}
