import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';

import { AuthService } from './auth.service';
import { AuthConfigService } from './auth-config';
import { AuthEventsService } from './auth-events.service';
import { AUTH_MODULE_CONFIG_PROVIDER } from './auth-module-config';
import { CustomOAuthService } from './custom-oauth-service';

@NgModule({
  imports: [OAuthModule.forRoot()],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AUTH_MODULE_CONFIG_PROVIDER,
        AuthService,
        AuthEventsService,
        AuthConfigService,
        {
          provide: OAuthService,
          useClass: CustomOAuthService,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (authService: AuthService) => () => authService.initialize(),
          deps: [AuthService],
          multi: true,
        },
      ],
    };
  }
}
