import { animate, animateChild, group, query, sequence, state, style, transition } from '@angular/animations';

const animationTiming = '0.3s ease';
const shortAnimationTiming = '.2s linear';

const veryShortAnimationTiming = '.01s linear';
const onDefault = { transform: 'translateX(0%)', opacity: '1' };
const onRight = { transform: 'translateX(100%)', opacity: '1' };
const onLeft = { transform: 'translateX(-100%)', opacity: '1' };
const hiddenRight = { transform: 'translateX(100%)', opacity: '0' };
const hiddenLeft = { transform: 'translateX(-100%)', opacity: '0' };

export const itemSlideAnimation = [
  state('p-null', style({})),
  state('p-0', style({ transform: 'translateX({{0}}px)' }), { params: { 0: '0' } }),
  state('p-1', style({ transform: 'translateX({{1}}px)' }), { params: { 1: '0' } }),
  state('p-2', style({ transform: 'translateX({{2}}px)' }), { params: { 2: '0' } }),
  state('p-3', style({ transform: 'translateX({{3}}px)' }), { params: { 3: '0' } }),
  state('p-4', style({ transform: 'translateX({{4}}px)' }), { params: { 4: '0' } }),
  state('p-5', style({ transform: 'translateX({{5}}px)' }), { params: { 5: '0' } }),
  state('p-6', style({ transform: 'translateX({{6}}px)' }), { params: { 6: '0' } }),
  state('p-7', style({ transform: 'translateX({{7}}px)' }), { params: { 7: '0' } }),
  state('p-8', style({ transform: 'translateX({{8}}px)' }), { params: { 8: '0' } }),
  state('p-right', style({})),
  state('p-left', style({})),
  transition('p-right => p-null', [
    style(onDefault),
    sequence([
      animate(shortAnimationTiming, style(onLeft)),
      animate(veryShortAnimationTiming, style(hiddenLeft)),
      animate(veryShortAnimationTiming, style(hiddenRight)),
      animate(shortAnimationTiming, style(onDefault)),
    ]),
  ]),
  transition('p-left => p-null', [
    style(onDefault),
    sequence([
      animate(shortAnimationTiming, style(onRight)),
      animate(veryShortAnimationTiming, style(hiddenRight)),
      animate(veryShortAnimationTiming, style(hiddenLeft)),
      animate(shortAnimationTiming, style(onDefault)),
    ]),
  ]),
  transition('* => *', [group([query('@*', animateChild(), { optional: true }), animate(animationTiming)])]),
];
