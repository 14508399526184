import { CountryCode } from '../../../helpers/country-code.enum';
import { IUser } from '../interfaces/user.interface';
import { cash } from './cash.mock';
import { creditCards } from './credit-cards.mock';

export const userMikeA = {
  id: 'user-mike-A',
  avatar: {
    id: null,
    name: `avatar mike A`,
    thumbs: {
      s: { url: './assets/images/tmp/avatars/mens/3.jpg', width: 128, height: 128 },
    },
  },
  creditCards: creditCards,
  cash: cash,
};

export const userMikeB = {
  id: 'user-mike-A',
  avatar: {
    id: null,
    name: `avatar mike A`,
    thumbs: {
      s: {
        url: './assets/images/tmp/avatars/neutrals/24025-profileavatar.png',
        width: 128,
        height: 128,
      },
    },
  },
  creditCards: creditCards,
  cash: cash,
};

export const userWithPersonalData: IUser = {
  id: 'user-id',
  personalData: {
    firstname: 'Jhon',
    lastname: 'Doe',
    email: 'jhon.doe@mail.com',
    countryCode: CountryCode.UNITED_STATES,
    addresses: [
      {
        type: 'primary',
        detail: {
          streetName: 'Main St',
          state: 'TX',
          zipCode: '78539',
          country: 'US',
          city: 'Alamo',
        },
      },
    ],
    billingInformation: [
      {
        id: 'xxxxxxxxxxx',
        address: {
          streetName: 'Main St',
          state: 'TX',
          zipCode: '78539',
          country: 'US',
          city: 'Alamo',
        },
        paymentInformation: {
          paymentMethod: 'BLOCKCHAIN',
        },
        isPrimary: true,
      },
    ],
  },
};
