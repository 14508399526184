export enum UserRole {
  USER = '001U',
  USER_BLOCKCHAIN = '100B',
  USER_COMPANY = '200C',
  OWNER_COMPANY = '300C',
  ADMIN_COMPANY = '100C',
  ADMIN_ULTRA = '999P',
  USER_WALLET = '001W',
  ADMIN_WALLET = '999W',
  USER_MARKETPLACE = '001M',
  ADMIN_MARKETPLACE = '999M',
  USER_GAMESTORE = '001G',
  ADMIN_GDC = '999G',
}

export enum UserRoleMap {
  user = '001U',
  companyMember = '200C',
  walletUser = '001W',
  ultraAdmin = '999P',
  walletAdmin = '999W',
  marketUser = '001M',
  marketAdmin = '999M',
  gameStoreUser = '001G',
  gdcAdmin = '999G',
}
