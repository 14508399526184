@if (!template) {
  <div class="d-flex align-items-center w-100">
    <i class="icon icon-main icon-triangle-alert me-2"></i>
    <i class="icon icon-main icon-check-default me-2"></i>
    <i class="icon icon-main icon-info me-2"></i>
    <span class="message d-flex align-items-center">{{ message }}</span>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="template; context: settings"></ng-container>
}
