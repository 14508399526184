import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IImageDetail, IVideo } from '../../../models/multimedia/interfaces';
import { IMedia } from '../../../models/uniq/interfaces';

@Injectable()
export class MediaCarouselService {
  private lastExpandedMedia$ = new Subject<IVideo | IMedia | IImageDetail>();

  public getLastExpandedMedia(): Observable<IVideo | IMedia | IImageDetail> {
    return this.lastExpandedMedia$.asObservable();
  }

  public setLastExpandedMedia(media: IVideo | IMedia | IImageDetail): void {
    this.lastExpandedMedia$.next(media);
  }
}
