import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  Optional,
  Output,
  Renderer2,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { auditTime } from 'rxjs/operators';

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

@UntilDestroy()
@Directive({
  selector: 'textarea[autosize]',
  exportAs: 'textAreaAutoSize',
})
export class AutosizeDirective implements DoCheck, AfterViewInit {
  @Input('autosize')
  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    value = coerceBooleanProperty(value);
    if (this._enabled !== value) {
      this._enabled = value;
      this._enabled ? this.resizeToFitContent(true) : this.reset();
    }
  }
  private _enabled = true;

  @Input('minRows')
  get minRows(): number {
    return this._minRows;
  }
  set minRows(value: number) {
    this._minRows = value;
    this.setMinHeight();
  }
  private _minRows: number;

  @Input('maxRows')
  get maxRows(): number {
    return this._maxRows;
  }
  set maxRows(value: number) {
    this._maxRows = value;
    this.setMaxHeight();
  }
  private _maxRows: number;

  private textareaElement: HTMLTextAreaElement;
  protected document: Document;
  private cachedLineHeight: number;
  private readonly measuringClass: string;
  private previousMinRows = -1;
  private previousValue?: string;
  private initialHeight: string | undefined;

  private maxHeight: string;
  private minHeight: string;
  private isScrollable = false;

  @Output()
  maxHeightChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  updateIsScrollable: EventEmitter<boolean> = new EventEmitter<boolean>();

  private setInfixBlockHeight$: BehaviorSubject<string> = new BehaviorSubject<string>('auto');
  public infixBlockHeight$: Observable<string> = this.setInfixBlockHeight$.asObservable();

  @HostListener('input')
  noopInputHandler() {
    // no-op handler that ensures we're running change detection on input events.
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private renderer: Renderer2,
    @Optional() @Inject(DOCUMENT) document?: any
  ) {
    this.document = document;
    this.textareaElement = this.elementRef.nativeElement as HTMLTextAreaElement;
    this.measuringClass = 'textarea-autosize-measuring';
    this.elementRef.nativeElement.setAttribute('rows', '1');
  }

  ngDoCheck() {
    this.resizeToFitContent();
  }

  ngAfterViewInit() {
    this.initialHeight = this.textareaElement.style.height;
    this.resizeToFitContent();
    this.maxHeightChanged.emit(this.maxHeight);
    this.setInfixBlockHeight$.next(this.maxHeight || 'auto');
    this.updateIsScrollable.emit(this.isScrollable);
    this.ngZone.runOutsideAngular(() => {
      const window = this.getWindow();
      fromEvent(window, 'resize')
        .pipe(auditTime(16), untilDestroyed(this))
        .subscribe(() => this.resizeToFitContent(true));
    });
  }

  public updateElementStyles(property: string, value: string): void {
    this.renderer.setStyle(this.elementRef.nativeElement, property, value);
  }

  public resizeToFitContent(force = false) {
    if (!this._enabled) {
      return;
    }

    this._cacheTextareaLineHeight();

    if (!this.cachedLineHeight) {
      return;
    }

    const textarea = this.elementRef.nativeElement as HTMLTextAreaElement;
    const value = textarea.value;

    if (!force && this._minRows === this.previousMinRows && value === this.previousValue) {
      return;
    }

    const placeholderText = textarea.placeholder;

    textarea.classList.add(this.measuringClass);
    textarea.placeholder = '';

    const height = textarea.scrollHeight - 4;

    textarea.style.height = `${height}px`;
    textarea.classList.remove(this.measuringClass);
    textarea.placeholder = placeholderText;
    this.isScrollable = height > parseInt(this.maxHeight, 10);
    this.updateIsScrollable.emit(this.isScrollable);
    this.ngZone.runOutsideAngular(() => {
      if (typeof requestAnimationFrame !== 'undefined') {
        requestAnimationFrame(() => this.scrollToCaretPosition(textarea));
      } else {
        setTimeout(() => this.scrollToCaretPosition(textarea));
      }
    });

    this.previousValue = value;
    this.previousMinRows = this._minRows;
  }

  public reset() {
    if (this.initialHeight !== undefined) {
      this.textareaElement.style.height = this.initialHeight;
    }
  }

  private _cacheTextareaLineHeight(): void {
    if (this.cachedLineHeight) {
      return;
    }

    const textareaClone = this.textareaElement.cloneNode(false) as HTMLTextAreaElement;
    textareaClone.rows = 1;

    textareaClone.style.position = 'absolute';
    textareaClone.style.visibility = 'hidden';
    textareaClone.style.border = 'none';
    textareaClone.style.padding = '0';
    textareaClone.style.height = '';
    textareaClone.style.minHeight = '';
    textareaClone.style.maxHeight = '';
    textareaClone.style.overflow = 'hidden';

    this.textareaElement.parentNode.appendChild(textareaClone);
    this.cachedLineHeight = textareaClone.clientHeight;
    this.textareaElement.parentNode.removeChild(textareaClone);

    this.setMinHeight();
    this.setMaxHeight();
  }

  private setMinHeight(): void {
    this.minHeight = this.minRows && this.cachedLineHeight ? `${this.minRows * this.cachedLineHeight}px` : null;

    if (this.minHeight) {
      this.textareaElement.style.minHeight = this.minHeight;
    }
  }

  private setMaxHeight(): void {
    this.maxHeight = this.maxRows && this.cachedLineHeight ? `${this.maxRows * this.cachedLineHeight}px` : null;

    if (this.maxHeight) {
      this.textareaElement.style.maxHeight = this.maxHeight;
    }

    this.maxHeightChanged.emit(this.maxHeight);
    this.setInfixBlockHeight$.next(this.maxHeight || 'auto');
  }

  private scrollToCaretPosition(textarea: HTMLTextAreaElement) {
    const { selectionStart, selectionEnd } = textarea;
    const document = this.getDocument();

    if (document.activeElement === textarea) {
      textarea.setSelectionRange(selectionStart, selectionEnd);
    }
  }

  private getDocument(): Document {
    return this.document || document;
  }

  private getWindow(): Window {
    const doc = this.getDocument();
    return doc.defaultView || window;
  }
}
