import { ApolloError } from '@apollo/client/core';

export function getGraphQLErrorMessage(error: any, propName: 'message' | 'error' = 'message'): string {
  let { message } = error;

  if (error instanceof ApolloError) {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors && graphQLErrors[0]) {
      message = (graphQLErrors[0].message as any)[propName];
    } else if (networkError) {
      message = networkError.message;
    }
  }
  return message;
}

export function getGraphQLErrorCode(error: any): number {
  let statusCode = 0;
  if (error instanceof ApolloError) {
    const { graphQLErrors } = error;

    if (graphQLErrors && graphQLErrors[0]) {
      statusCode = (graphQLErrors[0].message as any).statusCode ?? 0;
    }
  }
  return statusCode;
}
