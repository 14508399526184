<div class="modal-header d-flex justify-content-between align-items-center">
  <h5 class="modal-title f-size-16 mb-0" i18n>All Tags</h5>

  <button type="button" class="btn-close" (click)="activeModal.dismiss()">
    <i class="icon icon-cross-sm"></i>
  </button>
</div>

<div class="modal-body pe-0">
  <div ultraScroll class="scroll-container">
    <div class="limited-content">
      @if (tags?.length) {
        <ul class="game-details-tags pe-1">
          @for (tag of tags; track tag) {
            <li class="me-2 mb-2">
              <span class="tag u-badge u-badge-ultra-purple-light b2-regular radius-4">{{ tag }}</span>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</div>
