<ultra-game-detail-background-container [isRouteChange]="isRouteChange" [game]="game">
  <div class="container">
    <div class="row py-4" [class.justify-content-center]="!game.links.length">
      @if (!game) {
        <ultra-loading></ultra-loading>
      }

      <div class="d-flex flex-column mb-4 {{ game.links.length ? 'col-sm-12 col-md-9 col-lg-8' : 'col-8' }}">
        <h5 class="py-2 s1-bold">
          <ng-container *contentSkeleton="isRouteChange; theme: placeholderTheme.MD_TITLE" i18n>About</ng-container>
        </h5>
        <div class="game-description d-flex flex-column" [class.game-description-no-links]="!game.links.length">
          <span
            class="mb-5 h5-regular white-space-break"
            ultraAutotestAttribute="game-details-description-short"
            *contentSkeleton="isRouteChange; className: ['mb-5', 'rounded-5']; width: '256px'; height: '32px'"
            >{{ game.descriptionShort }}</span
          >
          <span
            class="b1-regular text-white-alpha-7 white-space-break"
            ultraAutotestAttribute="game-details-description"
            *contentSkeleton="isRouteChange; repeat: 2; theme: placeholderTheme.PARAGRAPH"
            >{{ game.description }}</span
          >
        </div>
      </div>

      @if (game.links.length) {
        <div class="col-sm-12 col-md-3 col-lg-4 d-flex flex-column">
          <h5
            class="py-2 s1-bold"
            *contentSkeleton="isRouteChange; className: ['mb-3', 'rounded-5']; width: '128px'; height: '24px'"
            ultraAutotestAttribute="game-details-external-links-title">
            <ng-container i18n>External Links</ng-container>
          </h5>
          <ng-container *contentSkeleton="isRouteChange; repeat: 3; className: ['mb-1', 'rounded-5']; height: '48px'">
            @for (link of game.links; track link) {
              <a [href]="link.url" class="mb-3" target="_blank">
                <span
                  class="caption-bold text-white d-block"
                  ultraAutotestAttribute="game-details-external-links-link-name"
                  >{{ link.name }}</span
                >
                <span
                  class="caption-regular text-white-alpha-5 word-break"
                  ultraAutotestAttribute="game-details-external-links-link-url"
                  >{{ link.url }}</span
                >
              </a>
            }
          </ng-container>
        </div>
      }
    </div>
  </div>
</ultra-game-detail-background-container>
