import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { ControlErrorComponentInterface } from '../types/control-error-component.interface';
import { ErrorTemplate } from '../types/error-template.type';

@Component({
  selector: 'ultra-default-control-error',
  templateUrl: './default-control-error.component.html',
  styleUrls: ['./default-control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultControlErrorComponent implements ControlErrorComponentInterface {
  public errorText: string | null = null;
  public errorTemplate: ErrorTemplate | undefined;
  public errorContext: { $implicit: ValidationErrors; text: string };
  public hideError = true;

  constructor(private changeDetectorRef: ChangeDetectorRef, private host: ElementRef<HTMLElement>) {}

  set customClass(className: string) {
    this.host.nativeElement.classList.add(className);
  }

  set text(value: string | null) {
    if (value !== this.errorText) {
      this.errorText = value;
      this.hideError = !value;
      this.changeDetectorRef.markForCheck();
    }
  }

  createTemplate(tpl: ErrorTemplate, error: ValidationErrors, text: string): void {
    this.errorTemplate = tpl;
    this.errorContext = { $implicit: error, text };
    this.changeDetectorRef.markForCheck();
  }
}
