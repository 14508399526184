import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { AutotestAttributeDirective } from '../..';
import { DialogModule } from '../dialog/dialog.module';
import { ImageResizeModule } from '../image-resize/image-resize.module';
import { MediaVideoPlayerModule } from '../media-video-player';
import { SliderComponent, SliderContentDirective, SwiperTemplateDirective } from '../slider';

import { MediaCarouselComponent } from './media-carousel.component';
import { ExpandedMediaVideosComponent } from './media-carousel-expanded/expanded-videos/media-video/expanded-media-videos.component';
import { ExpandedYoutubeVideosComponent } from './media-carousel-expanded/expanded-videos/youtube/expanded-youtube-videos.component';
import { MediaCarouselExpandedComponent } from './media-carousel-expanded/media-carousel-expanded.component';
import { MediaCarouselService } from './services/media-carousel.service';

@NgModule({
  imports: [
    CommonModule,
    SliderComponent,
    SliderContentDirective,
    SwiperTemplateDirective,
    DialogModule,
    YouTubePlayerModule,
    MediaVideoPlayerModule,
    ImageResizeModule,
    AutotestAttributeDirective,
  ],
  declarations: [
    MediaCarouselComponent,
    MediaCarouselExpandedComponent,
    ExpandedYoutubeVideosComponent,
    ExpandedMediaVideosComponent,
  ],
  exports: [MediaCarouselComponent, MediaCarouselExpandedComponent],
  providers: [MediaCarouselService],
})
export class MediaCarouselModule {}
