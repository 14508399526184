import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../autotests';
import { DirectivesModule } from '../../directives';

import { IconConfig, TooltipConfig } from './toggle-button.interface';

const defaultIconConfig: IconConfig = {
  activeIcon: 'icon-star-filled-16',
  inactiveIcon: 'icon-star-16',
};

const defaultTooltipConfig: TooltipConfig = {
  disableTooltip: false,
  activeTooltipText: 'Remove from wishlist',
  inactiveTooltipText: 'Add to wishlist',
  placement: ['auto'],
};

/**
 * A component that shows a icon button with a tooltip when user hover over it
 * and emits an event when clicked.
 *
 * This icon can only have two states: active or inactive.
 *
 * This component can be configured by `iconConfig` and `tooltipConfig` inputs.
 *
 * @example
 * <ultra-toggle-button [isSelected]="true" (toggleValue)="onToggleValue($event)"></ultra-favorite>
 */
@Component({
  selector: 'ultra-toggle-button',
  styleUrls: ['./toggle-button.component.scss'],
  templateUrl: './toggle-button.component.html',
  imports: [DirectivesModule, NgbTooltipModule, AutotestAttributeDirective],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent implements OnInit {
  tooltipText = defaultTooltipConfig.inactiveTooltipText;
  _isSelected = false;
  @Input() set isSelected(isSelected: boolean) {
    this._isSelected = isSelected;
    this.updateTooltipText();
  }
  get isSelected(): boolean {
    return this._isSelected;
  }
  @Input() pending = false;
  @Input() iconConfig: IconConfig = defaultIconConfig;
  @Input() tooltipConfig: TooltipConfig = defaultTooltipConfig;
  @Output() toggleValue = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.updateTooltipText();
  }

  toggleSelected(): void {
    if (this.pending) return;
    this.toggleValue.emit(!this.isSelected);
  }

  private updateTooltipText(): void {
    this.tooltipText = this.isSelected ? this.tooltipConfig.activeTooltipText : this.tooltipConfig.inactiveTooltipText;
  }
}
