import { IImageDetail, IVideo } from '../../../models/multimedia/interfaces';
import { IMedia } from '../../../models/uniq/interfaces/uniq.interface';

export function ToTypeFilter<T>(value?: unknown): value is T {
  return true;
}

export class MediaCarouselHelper {
  static getIMediaItems(multiMedia: (IVideo | IMedia | IImageDetail)[]): IMedia[] {
    return multiMedia
      .filter((media) => 'contentType' in media && media.contentType === 'VIDEO')
      .filter(ToTypeFilter<IMedia>);
  }

  static getIVideoItems(multiMedia: (IVideo | IMedia | IImageDetail)[]): IVideo[] {
    return multiMedia.filter((media) => 'videoId' in media).filter(ToTypeFilter<IVideo>);
  }
}
