import { GameHelper } from '../../../services/helpers/game.helper';
import { IAgeRatingData, RatingSystem } from '../data/age-ratings.data';
import { IGame, IGameDetail, ILink, IRecommendedUniqs } from '../interfaces';
import { ILanguage, ILanguageModel } from '../interfaces/language.interface';
import { IRating } from '../interfaces/rating.interface';
import { featuresMock } from '../mocks/filters/features.mock';
import { playingModesMock } from '../mocks/filters/modes.mock';

import { Game } from './game.model';
import { Rating } from './rating.model';
import { SystemRequirement } from './system-requirement.model';

export class GameDetail extends Game implements IGameDetail {
  languages?: ILanguage | ILanguageModel;
  initLanguage?: ILanguage = { audio: [], subtitles: [], interfaces: [] };
  systemsRequirements?: SystemRequirement[];
  rating?: Rating;
  gameInLibrary?: boolean;
  ageRatingSorted: IAgeRatingData[] = [];
  ageRatingCategoryImages: string[] = [];
  error?: string;
  recommendedUniqs?: IRecommendedUniqs;

  get tags(): string[] {
    return this.gameVersion.tags;
  }

  get features(): string[] {
    return this.gameVersion.features;
  }

  get playingModes(): string[] {
    return this.gameVersion.playingModes;
  }

  get links(): ILink[] {
    return this.gameVersion.links;
  }

  get ageRating(): IAgeRatingData[] {
    return this.gameVersion.ageRating;
  }

  constructor(game: IGame, develop?: boolean) {
    super(game, develop);
    this.initRating();
    this.initSystemsRequirements();
    this.initLanguages();
    this.initAgeRating();
    this.initAgeRatingImages();
    this.recommendedUniqs = this.gameVersion.recommendedUniqs;
  }

  getModeIcon(mode: string): string {
    return GameHelper.getCssClassIcon(mode, playingModesMock);
  }

  getFeatureIcon(mode: string): string {
    return GameHelper.getCssClassIcon(mode, featuresMock);
  }

  getLanguagesKeys(): string[] {
    return this.languages ? Object.keys(this.languages) : [];
  }

  updateRating(rating: IRating) {
    this.gameVersion.rating = rating;
    this.initRating();
  }

  private modifyFormatLanguages(data: ILanguage): ILanguageModel | null {
    let languages = {};

    // key is a property of language: audio/subtitles/interfaces
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && Array.isArray(data[key]) && data[key].length > 0) {
        data[key].forEach((lang) => {
          lang = lang.toLowerCase();

          if (Object.prototype.hasOwnProperty.call(languages, lang)) {
            // adding property to language
            languages[lang] = Object.assign(languages[lang], {
              [key]: true,
            });
          } else {
            // adding new language item
            languages = Object.assign(languages, { [lang]: { [key]: true } });
          }
        });
      }
    }
    return Object.keys(languages).length > 0 ? languages : null;
  }

  private initRating() {
    if (this.gameVersion && this.gameVersion.rating) {
      this.rating = new Rating(this.gameVersion.rating);
    }
  }

  private initSystemsRequirements() {
    if (this.gameVersion && this.gameVersion.systemsRequirements) {
      this.systemsRequirements = this.gameVersion.systemsRequirements.map((req) => new SystemRequirement(req));
    }
  }

  private initLanguages() {
    if (this.gameVersion && this.gameVersion.languages) {
      this.initLanguage = this.gameVersion.languages;
      this.languages = this.modifyFormatLanguages(this.gameVersion.languages);
    }
  }

  private initAgeRatingImages() {
    if (this.ageRatingSorted.length) {
      this.ageRatingCategoryImages = this.ageRatingSorted.map((rating: IAgeRatingData) => rating.category.image);
    }
  }

  private initAgeRating(): void {
    if (this.gameVersion?.ageRating) {
      const ratingSystemNames = Object.keys(RatingSystem);
      this.ageRatingSorted = this.ageRating
        .slice() // ageRating array is frozen
        .sort((ratingA, ratingB) => ratingSystemNames.indexOf(ratingA.name) - ratingSystemNames.indexOf(ratingB.name));
    }
  }
}
