import { ILanguage } from '../../../models/game/interfaces/language.interface';
import { ITokenFContent } from '../../../models/token-factory/interfaces/content.interface';
import {
  IRevenue,
  ITradability,
  IUniqTradingPeriod,
} from '../../../models/token-factory/interfaces/tradability.interface';
import { ITransferability } from '../../../models/token-factory/interfaces/transferability.interface';
import {
  IAccount,
  ICreator,
  IMedia,
  IMonetaryAmount,
  IQuantityAvailability,
  IUniqTransferPeriod,
  UniqRevenueShare,
} from '../../../models/uniq/interfaces/uniq.interface';
import { IUniqFactoryBaseMetadata } from '../../../uniq-factory/uniq-factory-metadata.interface';

import { BlockchainLineage } from './../../../models/uniq/interfaces/uniq.interface';
import { UniqMetadata } from './../../../models/uniq/interfaces/uniq-metadata.interface';

export interface IdCardEntity {
  type: 'tokenfactory' | 'uniq' | 'uniqfactory';
  blockchainId: string;
  id?: string;
  serialNumber?: string;
  info?: {
    title: string;
    description?: string;
    subTitle?: string;
    kind?: string;
  };
  content?: {
    contentType: string;
    contentTypeCode: string;
    languages?: ILanguage;
    tokenContent?: ITokenFContent;
  };
  medias?: {
    square?: IMedia;
    image?: IMedia;
    backGroundImage?: IMedia;
    imageGalleryList?: IMedia[];
  };
  creator: ICreator;
  status: string;
  tradability?: ITradability;
  transferability?: ITransferability;
  geofencing?: string[];
  tradingPeriod: IUniqTradingPeriod;
  transferPeriod?: IUniqTransferPeriod;
  quantity?: IQuantityAvailability;
  prices?: IMonetaryAmount[];
  revenue?: IRevenue;
  owner?: IAccount;
  factoryBlockchainId?: string;
  mintDate?: string;
  blockId?: string;
  metadata?: UniqMetadata;
  blockchainLineage?: BlockchainLineage;
  createdAt?: string;
  defaultMetadata?: IUniqFactoryBaseMetadata;
  isTokenized?: boolean;
  onChainId?: string;
  hidden: boolean;
}

export abstract class IdCardAdapter<T> {
  protected static reduceRevenueShareAmount(revenue: UniqRevenueShare[]): number {
    return revenue.reduce((acc, item) => {
      return acc + item.percentage;
    }, 0);
  }

  abstract transform(data: T): IdCardEntity;
}
