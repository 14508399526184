import { Directive, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { WINDOW } from '@ultra/core/providers';

export enum ScreenSize {
  XS = 576,
  SM = 768,
  MD = 992,
  LG = 1200,
  XL = Infinity,
}

export interface ScreenSizeChange {
  currentSize: ScreenSize;
  previousSize?: ScreenSize;
}

@Directive({
  selector: '[ultraScreenSizeChange]',
})
export class ScreenSizeChangeDirective implements OnInit, OnDestroy {
  @Output()
  screenSizeChange = new EventEmitter<ScreenSizeChange>();

  currentSize: ScreenSize;

  private resize$ = new BehaviorSubject(this.window.innerWidth);
  private destroy$ = new Subject<void>();

  constructor(@Inject(WINDOW) private window: Window) {}

  ngOnInit() {
    this.resize$.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe((screenSize: number) => {
      const previousSize = this.currentSize;
      if (screenSize < ScreenSize.XS) {
        this.currentSize = ScreenSize.XS;
      } else if (screenSize < ScreenSize.SM) {
        this.currentSize = ScreenSize.SM;
      } else if (screenSize < ScreenSize.MD) {
        this.currentSize = ScreenSize.MD;
      } else if (screenSize < ScreenSize.LG) {
        this.currentSize = ScreenSize.LG;
      } else {
        this.currentSize = ScreenSize.XL;
      }

      if (this.currentSize !== previousSize) {
        this.screenSizeChange.next({
          currentSize: this.currentSize,
          previousSize,
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(event) {
    this.resize$.next(event.target.innerWidth);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
