import {
  CDK_ROW_TEMPLATE,
  CdkFooterRow,
  CdkFooterRowDef,
  CdkHeaderRow,
  CdkHeaderRowDef,
  CdkRow,
  CdkRowDef,
} from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, Directive, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: '[ultraHeaderRowDef]',
  providers: [{ provide: CdkHeaderRowDef, useExisting: UltraHeaderRowDefDirective }],
})
export class UltraHeaderRowDefDirective extends CdkHeaderRowDef {
  @Input() set ultraHeaderRowDef(columns) {
    this.columns = columns;
  }
}

@Directive({
  selector: '[ultraFooterRowDef]',
  providers: [{ provide: CdkFooterRowDef, useExisting: UltraFooterRowDefDirective }],
})
export class UltraFooterRowDefDirective extends CdkFooterRowDef {
  @Input() set ultraFooterRowDef(columns) {
    this.columns = columns;
  }
}

@Directive({
  selector: '[ultraRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: UltraRowDefDirective }],
})
export class UltraRowDefDirective<T> extends CdkRowDef<T> {
  @Input() set ultraRowDefColumns(columns) {
    this.columns = columns;
  }
  @Input() set ultraRowDefWhen(when) {
    this.when = when;
  }
}

@Component({
  selector: 'ultra-header-row, tr[ultra-header-row]',
  template: CDK_ROW_TEMPLATE,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'ultraHeaderRow',
  providers: [{ provide: CdkHeaderRow, useExisting: UltraHeaderRowComponent }],
})
export class UltraHeaderRowComponent extends CdkHeaderRow {
  @HostBinding('class.ultra-header-row') ultraHeaderRrow = true;
  @HostBinding('role.row') row = true;
}

@Component({
  selector: 'ultra-footer-row, tr[ultra-footer-row]',
  template: CDK_ROW_TEMPLATE,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'ultraFooterRow',
  providers: [{ provide: CdkFooterRow, useExisting: UltraFooterRowComponent }],
})
export class UltraFooterRowComponent extends CdkFooterRow {
  @HostBinding('class.ultra-footer-row') ultraFooterRow = true;
  @HostBinding('role.row') row = true;
}

@Component({
  selector: 'ultra-row, tr[ultra-row]',
  template: CDK_ROW_TEMPLATE,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'ultraRow',
  providers: [{ provide: CdkRow, useExisting: UltraRowComponent }],
})
export class UltraRowComponent extends CdkRow {
  @Input() dataId: string;
  @HostBinding('class.ultra-row') ultraRow = true;
  @HostBinding('role.row') row = true;
  @HostBinding('attr.data-id')
  get _dataid() {
    return this.dataId;
  }
}
