import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

import { VideoService } from './video.service';

@UntilDestroy()
@Directive({
  selector: 'video[ultraVideo]',
  standalone: true,
})
export class VideoDirective implements OnInit, OnDestroy {
  @Output() videoPaused = new EventEmitter<HTMLVideoElement>();

  constructor(
    private videoService: VideoService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.videoService.pause$
      .pipe(
        filter((video) => video === this.elementRef.nativeElement),
        untilDestroyed(this),
      )
      .subscribe((video) => this.videoPaused.next(video));
    this.videoService.register(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.videoService.remove(this.elementRef.nativeElement);
  }
}
