<div [class.danger]="updateErrorState(danger)">
  @if (label) {
    <label class="form-label d-block rarefied-text mb-2" [for]="id">{{ label }}</label>
  }

  <div [class.disabled]="readonly" class="input-content input__wrapper position-relative">
    @if (iconSource) {
      <img [attr.src]="iconSource" alt="icon" class="input__icon" />
    }

    @if (icon) {
      <i class="icon input__icon icon-{{ icon }} {{ control.value ? 'bg-white' : 'bg-white-alpha-3' }}"> </i>
    }
    <input
      class="input w-100 input--{{ size }} {{ cssClass }} text-truncate"
      [class.icon]="icon"
      [class.input-icon]="iconSource"
      [class.input--invalid]="updateErrorState(control.invalid && control.touched)"
      #input
      [id]="id"
      [formControl]="control"
      [attr.type]="isPasswordVisible ? 'text' : type || 'text'"
      [attr.inputmode]="inputMode"
      [digitOnly]="allowDigitsOnly"
      [attr.maxLength]="maxLength"
      [step]="step"
      [attr.max]="max"
      [attr.disabled]="readonly || null"
      [placeholder]="placeholder || ''"
      [ultraAutotestAttribute]="dataId"
      (paste)="pasteHandler($event)"
      (keydown)="keyDownHandler($event)"
      [customControlErrors]="customErrors" />

    @if (canSeePassword) {
      <i class="input__eye icon icon-eye" [class.input__eye--active]="isEyeAvailable" (click)="showPassword()"></i>
    }

    @if (!hideLength) {
      <span class="maxlen f-size-10 ls-one-and-half text-white-alpha-25 {{ cssCountClass }}">
        {{ control.value.length }} / {{ maxLength }}
      </span>
    }
  </div>
</div>
