import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IBreadCrumb } from './breadcrumb.interface';

@Component({
  selector: 'ultra-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input()
  breadCrumbs: IBreadCrumb[];

  @Input()
  customClass: string | string[];

  @Input()
  ellipsisLength = 30;

  @Input()
  showToolTip = false;

  @Input()
  tooltipClass: string;

  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
}
