<div class="ultra-slider" [ngClass]="themeClass" ultraScreenSizeChange (screenSizeChange)="onScreenSizeChange($event)">
  <div class="position-relative">
    <swiper [config]="swiperConfig" (swiper)="onSwiper()" (activeIndexChange)="onIndexChange($event)">
      @for (slide of contents; track slide) {
        <ng-template swiperSlide>
          <ng-template [ngTemplateOutlet]="slide.template" [ngTemplateOutletContext]="{ $implicit: slide.template }" />
        </ng-template>
      }
    </swiper>
    @if (swiperReady && !skeletonMode) {
      <button
        type="button"
        class="ultra-slider-button ultra-slider-button-prev"
        [ultraAutotestAttribute]="theme + '-previous-slide-arrow'"
        [class.hidden]="hidePrevSlideButton"
        (click)="prevSlide()">
        <i class="icon icon-chevron"></i>
      </button>
      <button
        type="button"
        class="ultra-slider-button ultra-slider-button-next"
        [class.hidden]="hideNextSlideButton"
        [ultraAutotestAttribute]="theme + '-next-slide-arrow'"
        (click)="nextSlide()">
        <i class="icon icon-chevron reverse"></i>
      </button>
    }
    @if (innerPagination && showPagination && !skeletonMode) {
      <div
        #pagination
        class="ultra-slider-pagination swiper-pagination position-absolute inner-pagination"
        [ngClass]="[uuidClass, paginationType]"></div>
    }
  </div>
</div>
@if (!innerPagination && showPagination && !skeletonMode) {
  <div class="external-pagination-carousel">
    <div #pagination class="ultra-slider-pagination position-relative" [ngClass]="[uuidClass, paginationType]"></div>
  </div>
}
