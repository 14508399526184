// Probably temporary class with logic, in future when we start proper integration
// this class may be refactored or maybe this logic may be moved to another class
import cloneDeep from 'lodash-es/cloneDeep';

import { UserRole } from '../permission';

export type CompanyPermission = Record<string, UserRole[]>;

export interface JwtPermission {
  global?: UserRole[];
  companies?: CompanyPermission;
  applications?: any;
  dimensions?: any;
}

export interface JwtKeyCloakPermission {
  global?: UserRole[];
  companies?: CompanyPermission[];
  applications?: any;
  dimensions?: any;
}

export class JwtPermissionsTransformer {
  public static transform(permission: JwtPermission | JwtKeyCloakPermission): JwtPermission {
    permission = cloneDeep(permission);
    if (!Array.isArray(permission.companies)) {
      return permission as JwtPermission;
    }
    permission.companies = permission.companies.reduce((acc, item) => {
      const companyPermissions = Object.keys(item).reduce((accumulate, companyId) => {
        let permissionMap = { [companyId]: item[companyId] };
        if (acc[companyId]) {
          permissionMap = { [companyId]: [...item[companyId], ...acc[companyId]] };
        }
        return { ...accumulate, ...permissionMap };
      }, {});
      return { ...acc, ...companyPermissions };
    }, {});

    return permission as JwtPermission;
  }
}
