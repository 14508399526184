import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TabComponent } from './tab/tab.component';
import { TabContentDirective } from './tab-content.directive';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabLabelDirective } from './tab-label.directive';

@NgModule({
  imports: [CommonModule, PortalModule],
  declarations: [TabComponent, TabGroupComponent, TabLabelDirective, TabContentDirective],
  exports: [TabComponent, TabGroupComponent, TabLabelDirective, TabContentDirective],
})
export class TabsModule {}
