export const errorCodes: { [codeNumber: number]: string } = {
  0: 'common',
  1000: 'username',
  1010: 'email',
  2000: 'code',
  2010: 'code',
  2020: 'code',
  3000: 'common',
  4000: 'code',
  4010: 'code',
  401: 'unauthorized',
};
