import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogConfig } from './dialog-config';
import { DialogActionsDirective } from './directives/dialog-actions.directive';
import { DialogCloseDirective } from './directives/dialog-close.directive';
import { DialogContentDirective } from './directives/dialog-content.directive';
import { DialogHeaderDirective } from './directives/dialog-header.directive';
import { Dialog } from './providers/dialog.service';
import {
  DIALOG_CONFIG,
  DIALOG_CONTAINER,
  DIALOG_REF,
  DIALOG_SCROLL_STRATEGY_PROVIDER,
} from './providers/dialog-injectors';
import { DialogRef } from './providers/dialog-ref';

const DIRECTIVES = [DialogHeaderDirective, DialogContentDirective, DialogActionsDirective, DialogCloseDirective];

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule, A11yModule],
  declarations: [DialogContainerComponent, ...DIRECTIVES],
  exports: [PortalModule, DialogContainerComponent, ...DIRECTIVES],
  providers: [
    Dialog,
    DIALOG_SCROLL_STRATEGY_PROVIDER,
    { provide: DIALOG_REF, useValue: DialogRef },
    { provide: DIALOG_CONTAINER, useValue: DialogContainerComponent },
    { provide: DIALOG_CONFIG, useValue: DialogConfig },
  ],
})
export class DialogModule {}
