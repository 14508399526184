import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../../../autotests/autotest-attribute.directive';
import { IQuantityAvailability } from '../../../../models/uniq/interfaces/uniq.interface';
import {
  IdCardQuantityDisplayHelper,
  IUniqQuantityStatus,
  tooltipColor,
  UniqQuantityStatusEnum,
} from '../helpers/id-card-quantity-display.helper';

@Component({
  selector: 'ultra-id-card-quantity-status',
  templateUrl: './id-card-quantity-status.component.html',
  styleUrls: ['./id-card-quantity-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgbTooltipModule, AutotestAttributeDirective],
  standalone: true,
})
export class IdCardQuantityStatusComponent implements OnChanges {
  @Input() quantityAvailability: IQuantityAvailability;
  @Input() showStatus = false;
  @Input() showLarge = false;
  @Input() showTooltip = false;
  quantityStatus: IUniqQuantityStatus;
  uniqQuantityStatusEnum = UniqQuantityStatusEnum;
  quantityStatusColor: string;
  statusText: string;

  tooltipText: string;
  tooltipColor: string;
  ngOnChanges() {
    this.quantityStatus = this.quantityAvailability
      ? IdCardQuantityDisplayHelper.getQuantityStatus(
          this.quantityAvailability.quantityLeft,
          this.quantityAvailability.maxCirculatingSupply,
        )
      : UniqQuantityStatusEnum.NOT_AVAILABLE;
    this.quantityStatusColor = IdCardQuantityDisplayHelper.getStatusColor(this.quantityStatus);
    this.statusText = IdCardQuantityDisplayHelper.getStatusText(
      this.quantityStatus,
      this.quantityAvailability?.quantityLeft,
    );
    if (this.showTooltip) {
      this.tooltipText = this.quantityStatus === UniqQuantityStatusEnum.NOT_AVAILABLE ? 'Not Available' : 'Available';
      this.tooltipColor = tooltipColor(this.quantityStatus);
    }
  }
}
