import { inject, Injectable } from '@angular/core';

import { AuthService } from '@ultra/core/auth';

import { IGameStoreStrategy } from '../../models/game/interfaces/game-store-strategy.interface';
import { GameGqlService } from '../../services/game-gql/game-gql.service';
import { GameAlgoliaService } from '../algolia/game-algolia.service';

const gameServiceFactory = (authService: AuthService) => {
  const factoryService = new GameService();
  factoryService.strategy = authService.isAuthenticated() ? inject(GameGqlService) : inject(GameAlgoliaService);
  return factoryService;
};

/**
 * A factory provider
 *
 * For non-authenticated users we use Algolia, with limited data.
 * For authenticated users we use GraphQL.
 */
@Injectable({
  providedIn: 'root',
  useFactory: gameServiceFactory,
  deps: [AuthService],
})
export class GameService {
  strategy: IGameStoreStrategy;
}
