import { trigger } from '@angular/animations';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Subject, timer } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';

import { AutotestAttributeDirective } from '../..';
import { CardAnimationModule } from '../card-animation/card-animation.module';
import { ResponsiveContentDirective } from '../card-animation/directives/responsive-content.directive';

import { CarouselPaginate, PaginationDirection } from './card-carousel';
import { CardCarouselDatasource } from './card-carousel.datasource';
import { itemSlideAnimation } from './slide.animations';

@UntilDestroy()
@Component({
  selector: 'ultra-card-carousel',
  templateUrl: 'card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss'],
  animations: [trigger('animSliderPosition', itemSlideAnimation)],
  standalone: true,
  imports: [CardAnimationModule, AutotestAttributeDirective, NgTemplateOutlet, AsyncPipe],
})
export class CardCarouselComponent implements AfterContentInit, OnDestroy {
  @Input() dataSource: CardCarouselDatasource<any>;
  @Input() title: string;
  @Input() forceAutoHeight = false;
  @Input() grid;
  @Input() pagination: CarouselPaginate;

  @Output() elementClick = new EventEmitter<{ data: any; index: number }>();

  @ContentChild(ResponsiveContentDirective, { static: true }) carouselContent: ResponsiveContentDirective;

  template: TemplateRef<any>;
  sliderPosition = 'p-null';

  private paginate$ = new Subject<PaginationDirection>();

  ngAfterContentInit(): void {
    this.template = this.carouselContent.template;

    this.paginate$
      .asObservable()
      .pipe(
        tap((dir) => {
          this.sliderPosition = 'p-' + dir;
        }),
        exhaustMap((dir) =>
          forkJoin([
            timer(250).pipe(
              tap(() => {
                this.dataSource.paginate(dir);
              }),
            ),
            timer(100).pipe(
              tap(() => {
                this.sliderPosition = 'p-null';
              }),
            ),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.dataSource?.disconnect();
  }

  paginate(dir: PaginationDirection) {
    this.paginate$.next(dir);
  }

  paginationDisabledState(dir: PaginationDirection, pagination: CarouselPaginate) {
    return dir === 'left' ? pagination?.startIndex === 0 : pagination.page >= pagination.maxPages;
  }
}
