import { Injectable } from '@angular/core';
import Big from 'big.js';
import { catchError, combineLatest, map, merge, Observable, of, sampleTime, take } from 'rxjs';

import { CurrencyType } from '@ultra/core/models';

import { ExchangeGqlService, ExchangeRateParams } from '../models/exchange';

@Injectable({
  providedIn: 'root',
})
export class UniqPriceService {
  UPDATE_TIME_MS = 60_000;

  constructor(private exchangeService: ExchangeGqlService) {}

  getUniqPrice(resellPriceUos: string, minResellPrice: string, minResellPriceCurrency: string): Observable<number> {
    if (!minResellPrice || !minResellPriceCurrency) {
      return of(Number(resellPriceUos));
    }

    if (minResellPriceCurrency === CurrencyType.UOS) {
      return of(Math.max(Number(resellPriceUos), Number(minResellPrice)));
    }

    return combineLatest([
      this.getExchangeRate(minResellPriceCurrency as CurrencyType),
      this.exchangeService.getExchangeMargin(),
    ]).pipe(
      map(([exchangeRate, exchangeMargin]: [string, number]) => {
        const minResellPriceUos = Big(minResellPrice)
          .mul(exchangeRate)
          .mul(1 + exchangeMargin);
        return Math.max(Number(resellPriceUos), Number(minResellPriceUos));
      }),
      // Default to resell price if failed to get the exchange rate
      catchError(() => of(Number(resellPriceUos)))
    );
  }

  getExchangeRate(currency: CurrencyType): Observable<string> {
    const exchangeRateParams: ExchangeRateParams = { from: currency, to: CurrencyType.UOS };
    return merge(
      // Emit first value and then every minute
      this.exchangeService.getExchangeRate(exchangeRateParams).pipe(take(1)),
      this.exchangeService.getExchangeRate(exchangeRateParams).pipe(sampleTime(this.UPDATE_TIME_MS))
    ).pipe(map((exchangeRate) => exchangeRate.rate));
  }
}
