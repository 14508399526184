import { Hit, MultipleQueriesResponse, SearchResponse } from '@algolia/client-search';

import { UserCurrency } from '@ultra/core/models';

import { adaptPrices } from '../../game-factory/algolia/game-factory-algolia.adapter';
import { GameDetail, GameList, IBuyableTokenFactory, IFilters, ITokenFactory } from '../../models';

export interface AlgoliaGame {
  multimedia: {
    imageGalleryList: {
      images: {
        url: string;
        height: number;
        width: number;
      }[];
    }[];
  };
  hasFreeToPlay: boolean;
  title: string;
  buyableGameFactory: {
    id: string;
    onChainId: string;
    freeToPlay: boolean;
    prices: {
      amount: number;
      symbol: string;
      currency: string;
      originalAmount: number;
      discountBasisPoint: number;
    }[];
  };
  editorName: string;
  objectID: string;
  _highlightResult: {
    title: {
      value: string;
      matchLevel: string;
      matchedWords: [];
    };
  };
}

export function filtersAdapter(filter: IFilters) {
  const specialOffersFilter = filter?.specialOffers ? ['hasActiveDiscount:true'] : [];
  const toAlgoliaFilter = (filterValues, attributeName) =>
    filterValues ? filterValues.map((value) => `${attributeName}:'${value}'`) : [];
  const categoriesFilter = toAlgoliaFilter(filter?.categories, 'categories');
  const tagsFilter = toAlgoliaFilter(filter?.tags, '_tags');
  const featuresFilter = toAlgoliaFilter(filter?.features, 'features');
  const playingModesFilter = toAlgoliaFilter(filter?.playingModes, 'playingModes');

  return specialOffersFilter
    .concat(categoriesFilter)
    .concat(tagsFilter)
    .concat(featuresFilter)
    .concat(playingModesFilter)
    .join(' AND ');
}

export function gameListAdapter(
  algoliaResponse: MultipleQueriesResponse<AlgoliaGame>,
  userCurrency: UserCurrency,
): GameList {
  const result = algoliaResponse.results[0] as SearchResponse<AlgoliaGame>;
  const facets = result['facets'];

  return {
    nodes: gamesAdapter(algoliaResponse, userCurrency),
    totalCount: result['nbHits'],
    pagination: {
      skip: result['offset'],
      limit: result.length,
    },
    filtersCount: {
      categories: facets?.categories
        ? Object.entries(facets.categories).map(([category, totalGames]) => ({ category, totalGames }))
        : [],
      features: facets?.features
        ? Object.entries(facets.features).map(([feature, totalGames]) => ({ feature, totalGames }))
        : [],
      tags: facets?._tags ? Object.entries(facets._tags).map(([tag, totalGames]) => ({ tag, totalGames })) : [],
      playingModes: facets?.playingModes
        ? Object.entries(facets.playingModes).map(([playingMode, totalGames]) => ({
            playingMode,
            totalGames,
          }))
        : [],
      metascores: [],
    } as any,
  };
}

export function gamesAdapter(algoliaResponse: MultipleQueriesResponse<any>, userCurrency: UserCurrency): GameDetail[] {
  return algoliaResponse.results[0]['hits'].map(algoliaGameToGameDetail(userCurrency));
}

function algoliaGameToGameDetail(userCurrency: UserCurrency): (algoliaGame: Hit<any>) => GameDetail {
  return (algoliaGame: Hit<any>) => {
    const tokenFactories =
      !algoliaGame.buyableGameFactory && algoliaGame.hasFreeToPlay
        ? ([{ freeToPlay: true }] as ITokenFactory[])
        : undefined;
    const buyableTokenFactory = adaptTokenFactory(algoliaGame.buyableGameFactory, userCurrency);

    return new GameDetail({
      id: algoliaGame.objectID,
      publisher: {
        id: null,
        name: algoliaGame.publisherName,
      },
      buyableTokenFactory,
      liveGame: {
        title: algoliaGame.title,
        categories: algoliaGame.categories,
        description: algoliaGame.description,
        descriptionShort: algoliaGame.descriptionShort,
        developerName: algoliaGame.developerName,
        editorName: algoliaGame.editorName,
        playingModes: algoliaGame.playingModes,
        tags: algoliaGame._tags,
        features: algoliaGame.features,
        releaseDate: algoliaGame.releaseDate,
        multimedia: algoliaGame.multimedia,
        ageRating: algoliaGame.ageRating,
        links: algoliaGame.links,
        languages: algoliaGame.languages,
        systemsRequirements: algoliaGame.systemsRequirements,
        rating: algoliaGame.rating,
        recommendedUniqs: algoliaGame.recommendedUniqs,
      },
      tokenFactories,
    });
  };
}

function adaptTokenFactory(buyableGameFactory, userCurrency: UserCurrency): IBuyableTokenFactory | undefined {
  if (!buyableGameFactory || !buyableGameFactory.prices) {
    return;
  }

  return {
    ...buyableGameFactory,
    prices: adaptPrices(buyableGameFactory.prices, userCurrency),
  };
}
