import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { PaginationOptions } from 'swiper/types';

import { AutotestAttributeDirective } from '../../autotests';
import { DirectivesModule } from '../../directives/directives.module';
import { ScreenSize, ScreenSizeChange } from '../../directives/screen-size-change/screen-size-change.directive';

import { getConfigByTheme } from './slider.helper';
import { SliderTheme } from './slider.interface';
import { SliderContentDirective } from './slider-content.directive';
import { SwiperService } from './swiper.service';
/**
 *  A carousel powered by Swiper
 *
 *  This component provide a set of supported themes
 *  to allow consumer components to choose the type of slider
 *
 *  @link https://swiperjs.com/
 */
@UntilDestroy()
@Component({
  selector: 'ultra-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  /**
   * Every instance of the component gets its own,
   * private instance of the SwiperService.
   */
  providers: [SwiperService],
  /**
   * Needed to use swiper styles.
   */
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SwiperModule, DirectivesModule, AutotestAttributeDirective],
})
export class SliderComponent implements OnChanges, OnInit {
  /**
   * Stop autoloop on hover sliders
   */
  @Input() pauseOnHover = true;

  @Input() theme: SliderTheme = 'carousel';

  @Input() innerPagination = false;

  @Input() paginationType: 'lines' | 'bullets' = 'lines';

  @Input() skeletonMode = false;
  /**
   * Navigate to the selected index
   */
  @Input() set goToSlideIndex(slideIndex) {
    if (this.swiperReady && typeof slideIndex === 'number' && slideIndex >= 0) {
      this.goToSlide(slideIndex, 300, true);
    }
  }
  /**
   *  In order to show videos in both original and duplicated slides we want to destroy/create the loop
   *  just after video content is ready,
   *  @see more info: https://github.com/nolimits4web/swiper/issues/2629
   */
  @Input() set updateLoopContent(loopContent) {
    if (loopContent) {
      this.swiperService.loopDestroy();
      this.swiperService.loopCreate();
    }
  }

  @Output() slideChange = new EventEmitter<number>();

  @Output() isSwiperReady = new EventEmitter();

  @ContentChildren(SliderContentDirective, {
    descendants: true,
  })
  contents!: QueryList<SliderContentDirective>;

  /**
   * Configure slider through this property using swiper options available
   * @See https://swiperjs.com/swiper-api
   */
  swiperConfig: SwiperOptions;
  swiperReady = false;

  get hidePrevSlideButton() {
    return this.swiperService.isBeginning || (this.swiperService.hasOnePage && !this.swiperConfig?.loop);
  }

  get hideNextSlideButton() {
    return (this.swiperService.hasOnePage || this.swiperService.isEnd) && !this.swiperConfig?.loop;
  }

  get themeClass() {
    return `theme-${this.theme}`;
  }

  @ViewChild(SwiperComponent, { static: true })
  private swiper?: SwiperComponent;

  private resize$ = new Subject<void>();
  /**
   * We provide a unique class to be associated with
   * the custom pagination feature that Swiper offers.
   */
  uuidClass = `ultra-slider-${new Date().getMilliseconds()}`;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private swiperService: SwiperService,
  ) {}

  ngOnInit() {
    this.swiperConfig = getConfigByTheme(this.theme);
    if (this.swiperConfig.pagination) {
      (this.swiperConfig.pagination as PaginationOptions).el = `.${this.uuidClass}`;
    }
  }

  onSwiper() {
    this.swiperService.init(this.swiper, this.swiperConfig);
    this.swiperService.swiperRef.on('observerUpdate', this.onDomUpdate);
    this.resize$.pipe(debounceTime(300), untilDestroyed(this)).subscribe(() => {
      this.swiperService.update();
    });
    this.swiperReady = true;
    this.isSwiperReady.emit();
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.swiperReady) {
      if (changes['theme']) {
        const slideNextClass =
          changes['theme'].currentValue === 'carousel' ? 'ultra-slideshow-slide-next' : 'swiper-slide-next';
        this.swiperService.updateSlideNextClass(slideNextClass);
      }
    }
  }

  getStatusNavigation() {
    return { hideNext: this.hideNextSlideButton, hidePrev: this.hidePrevSlideButton };
  }

  showPagination() {
    return (this.swiperConfig.pagination as PaginationOptions)?.enabled;
  }

  nextSlide() {
    this.swiperService.nextSlide();
  }

  prevSlide() {
    this.swiperService.prevSlide();
  }

  goToSlide(index: number, transitionMs = 300, triggerEvents = true) {
    this.swiperService.goToSlide(index, transitionMs, triggerEvents);
  }

  /**
   * Emit to parent component the current index
   * of the slider every time it changes.
   */
  onIndexChange([swiper]) {
    this.slideChange.emit(swiper.activeIndex);
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.swiperReady && this.swiperConfig.autoplay && this.pauseOnHover) {
      this.swiperService.stopAutoplay();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.swiperReady && this.swiperConfig.autoplay && this.pauseOnHover) {
      this.swiperService.startAutoplay();
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resize$.next();
  }

  onScreenSizeChange({ currentSize, previousSize = ScreenSize.XL }: ScreenSizeChange) {
    if (!this.swiperConfig.pagination) {
      return;
    }
    if (currentSize <= ScreenSize.SM && previousSize > ScreenSize.SM) {
      this.swiperService.togglePaginationBullets(true);
    } else if (currentSize > ScreenSize.SM && previousSize <= ScreenSize.SM) {
      this.swiperService.togglePaginationBullets(false);
    }
  }

  /**
   * Event will be fired if observer is enabled and it detects DOM mutations
   * It will go to the first slide.
   */
  private onDomUpdate = () => {
    this.goToSlide(0, 1);
    this.changeDetector.detectChanges();
  };
}
