import { ContentChild, Directive, HostBinding, TemplateRef } from '@angular/core';

import { SwiperTemplateDirective } from './slider-template.directive';

@Directive({
  selector: 'ultraSliderContent',
  standalone: true,
})
export class SliderContentDirective {
  @ContentChild(SwiperTemplateDirective, { read: TemplateRef })
  template!: TemplateRef<HTMLElement>;

  @HostBinding('class.swiper-slide')
  swiperSlideClass = true;
}
