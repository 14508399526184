import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { FormFieldControl } from '../form-field-control';
import { getInputUnsupportedTypeError, INPUT_INVALID_TYPES } from '../helpers/input-invalid-type';

let nextUniqueId = 0;

@Directive({
  selector: 'input[ultraInput], textarea[ultraInput]',
  exportAs: 'ultraInput',
  providers: [{ provide: FormFieldControl, useExisting: UltraInputDirective }],
})
export class UltraInputDirective implements FormFieldControl<any>, DoCheck, OnChanges, OnDestroy {
  protected previousNativeValue: any;
  readonly isTextarea: boolean;
  protected uid = `ultra-input-${nextUniqueId++}`;

  focused = false;

  readonly stateChanges: Subject<void> = new Subject<void>();

  controlType = 'ultra-input';

  @Input()
  @HostBinding('disabled')
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  protected _disabled = false;

  @Input()
  @HostBinding('attr.id')
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this.uid;
  }

  protected _id: string;

  @Input()
  @HostBinding('attr.placeholder')
  placeholder: string;

  @Input()
  @HostBinding('required')
  @HostBinding('attr.aria-required')
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = value;
  }

  protected _required = false;

  /** Input type of the element. */
  @Input()
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
    this.validateType();
    if (!this.isTextarea) {
      (this.elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }

  protected _type = 'text';

  @Input()
  get value(): string {
    return this._inputValueAccessor.value;
  }

  set value(value: string) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  @Input()
  @HostBinding('attr.readonly')
  get readonly(): boolean {
    return this._readonly || null;
  }

  set readonly(value: boolean) {
    this._readonly = value;
  }

  private _readonly = false;

  private _inputValueAccessor: { value: any };

  constructor(
    protected elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Following line of code is not actually not self-assign, it's calling setter with getter value.
    // This is done in order to guarantee that id is set (given or default one uid)
    this.id = this.id; // eslint-disable-line no-self-assign

    const element = this.elementRef.nativeElement;
    this._inputValueAccessor = element;
    const nodeName = element.nodeName.toLowerCase();
    this.isTextarea = nodeName === 'textarea';
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    this.stateChanges.next();
    this.dirtyCheckNativeValue();
  }

  protected validateType(): void {
    if (INPUT_INVALID_TYPES.indexOf(this._type) > -1) {
      throw getInputUnsupportedTypeError(this._type);
    }
  }

  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  @HostListener('focus', ['true'])
  @HostListener('blur', ['false'])
  focusChanged(isFocused: boolean): void {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  protected dirtyCheckNativeValue(): void {
    const newValue = this.elementRef.nativeElement.value;

    if (this.previousNativeValue !== newValue) {
      this.previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  onContainerClick(): void {
    if (!this.focused) {
      this.focus();
    }
  }
}
