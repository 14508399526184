import { IAward, IRating } from '../interfaces/rating.interface';

import { Score } from './score.model';

export class Rating {
  metascore: Score;
  metaCriticsUserScore: Score;
  awards: IAward[];

  constructor(data: IRating) {
    this.awards = (data.awards && [...data.awards]) || [];
    this.metascore = data.metascore && new Score(data.metascore);
    this.metaCriticsUserScore = data.metaCriticsUserScore && new Score(data.metaCriticsUserScore);
  }
}
