import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  model,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ultra-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent implements AfterViewInit {
  private searchChanged: Subject<string> = new Subject<string>();
  debounceTime = input<number>(300);
  expandable = input<boolean, boolean>(false, { transform: booleanAttribute });
  lettersToSearch = input<number>(2);
  maxLength = input<number | null>(null);
  searchInput = model<string>('');
  showClearButton = input<boolean, boolean>(false, { transform: booleanAttribute });

  @Input()
  placeholder = 'Search...';
  @Input()
  additionalClass: string[] | string = [];
  @Input()
  disabled = false;
  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  set resetInput(resetInput: boolean) {
    this.searchInput.set(resetInput ? '' : this.searchInput());
  }

  @Input()
  emitFocusEvent = false;
  @Input()
  dataId = '';

  ngAfterViewInit(): void {
    this.startListenSearchField();
    if (this.searchInput()?.length) {
      this.searchChanged.next(this.searchInput());
    }
  }

  changed(text: string): void {
    this.searchChanged.next(text);
  }

  resetFilter() {
    this.searchInput.set('');
    this.changed('');
  }

  private startListenSearchField(): void {
    let flagSearch = true;
    let flagReset = true;

    this.searchChanged.pipe(debounceTime(this.debounceTime()), untilDestroyed(this)).subscribe((value) => {
      if (value.trim().length >= this.lettersToSearch()) {
        flagSearch = false;
        flagReset = true;
        this.search.emit(value);
      } else if (!flagSearch && flagReset) {
        flagReset = false;
        this.search.emit(null);
      }
    });
  }

  onFocus() {
    if (this.emitFocusEvent && this.searchInput() === '') {
      this.search.emit(this.searchInput());
    }
  }
}
