@if (dateType === 'starting') {
  <div class="me-2" [class.mt-3]="displayState.isLocked">
    @if (displayState.hasStartDate || (displayState.isLocked && displayState.hasStartDate)) {
      <div class="text-end text-white-alpha-5 f-size-12">
        @if (displayState.isLocked || displayState.status === actionStatuses.PENDING) {
          <ng-container i18n>Starting</ng-container>
        } @else {
          <ng-container i18n>Started</ng-container>
        }
      </div>
      <div class="f-size-16 b1-bold text-end">{{ date | date }}</div>
    }
  </div>
} @else if (dateType === 'ending') {
  <div class="ms-2" [class.mt-3]="displayState.isLocked">
    @if (displayState.hasEndDate) {
      <div class="text-white-alpha-5 f-size-12">
        @if (displayState.status !== actionStatuses.UNABLE && displayState.status !== actionStatuses.DONE) {
          <ng-container i18n>Until</ng-container>
        } @else {
          <ng-container i18n>Ended</ng-container>
        }
      </div>
      <div class="f-size-16 b1-bold">{{ date | date }}</div>
    } @else if (displayState.status === actionStatuses.ABLE && startDate) {
      <span class="text-white-alpha-5 f-size-12" i18n>Until</span>
      <div class="f-size-16 b1-bold"><i class="icon icon-32x icon-infinity-bold"></i></div>
    }
    @if (
      (!displayState.hasEndDate && displayState.status === actionStatuses.ABLE && displayState.isLocked) ||
      (!displayState.hasEndDate && displayState.status === actionStatuses.PENDING)
    ) {
      <div class="text-white-alpha-5 f-size-12">Will be {{ actionVerb }}</div>
      <div class="f-size-16">Forever</div>
    }
  </div>
}
