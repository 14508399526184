import { Location } from '@angular/common';
import { inject, Injectable, Optional } from '@angular/core';
import { AuthConfig as oidcConfig } from 'angular-oauth2-oidc';

import { APP_CONFIG } from '@ultra/environment';

import { WINDOW } from '../../providers/window.provider';

export class AuthConfig extends oidcConfig {
  automaticSilentRefresh?: boolean;
  automaticLogout?: boolean;
  skipRedirectCallback?: boolean;
}

@Injectable()
export class AuthConfigService {
  private readonly config?: AuthConfig;

  constructor(@Optional() config?: AuthConfig) {
    this.config = createAuthConfig(config);
  }

  get() {
    return this.config;
  }
}

export const createAuthConfig = (config?: AuthConfig): AuthConfig => {
  const environment = inject(APP_CONFIG);
  const location = inject(Location);
  const window = inject(WINDOW);
  const origin = window.location.origin;
  const rootPath = location.prepareExternalUrl('/');
  return {
    scope: 'openid',
    responseType: 'code',
    issuer: environment.oauthIssuer,
    clientId: environment.client_id,
    redirectUri: `${origin}${rootPath}`,
    postLogoutRedirectUri: `${origin}${rootPath}`,
    silentRefreshRedirectUri: `${origin}${rootPath}assets/silent-refresh/silent-refresh.html`,
    silentRefreshTimeout: 1000 * 10, // 10 sec
    showDebugInformation: false,
    sessionChecksEnabled: true,
    automaticSilentRefresh: true,
    automaticLogout: false,
    ...config,
  };
};
