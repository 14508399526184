import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { VersionCurrencyPipe } from './version-currency.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [VersionCurrencyPipe],
  exports: [VersionCurrencyPipe],
  providers: [CurrencyPipe],
})
export class VersionCurrencyModule {}
