<div class="quantity-header">
  <span class="text-uppercase overline-medium text-white-alpha-7" i18n>Availability</span>
  <i
    container="ultra-id-card-quantity"
    ngbTooltip="The Availability of a Uniq tells you if it’s available for purchase on the Uniq Store or Launchpad as a first-hand purchase.
        It may have a start date and/or an end date.
        It may also be limited in quantity."
    tooltipClass="z-infinite"
    class="d-inline-block bg-white-alpha-7 icon-info"
    ultraAutotestAttribute="availability-tooltip-info-content"></i>
</div>
<div class="quantity-content" ultraAutotestAttribute="card-quantity-content">
  <div class="text-center f-size-16" [style.color]="quantityStatusColor" ultraAutotestAttribute="quantity-title" i18n>
    {{ quantityTitle }}
  </div>
  <div class="info-wrap">
    <div class="info">
      @if (quantityStatus === uniqQuantityStatusEnum.INFINITE) {
        <div class="me-3">
          <div class="text-end text-white-alpha-5 f-size-12" i18n>No Quantity restriction</div>
        </div>
        <div class="info-gauge d-inline-flex justify-content-between">
          <div class="d-flex align-items-center">
            <ultra-vertical-progress-sticks
              [barOrIconPrimaryColor]="quantityStatusColor"
              [barAltColor]="uniqQuantityStatusColorEnum.OTHERS"
              [displayIcon]="true">
            </ultra-vertical-progress-sticks>
          </div>
          <div><hr class="vertical" /></div>
          <div class="d-flex align-items-center info-gauge-fill">
            <i class="icon icon-48x icon-infinity" [ngStyle]="{ background: quantityStatusColor }"></i>
          </div>
        </div>
      } @else {
        <ultra-id-card-quantity-supply
          [quantityStatusColor]="quantityStatusColor"
          [quantityAvailability]="quantityAvailability">
        </ultra-id-card-quantity-supply>
        @if (quantityStatus === uniqQuantityStatusEnum.NOT_AVAILABLE) {
          <div class="info-empty-block d-flex justify-content-center align-items-center">
            <i class="icon icon-112x icon-uniq-no-quantity" [ngStyle]="{ background: quantityStatusColor }"></i>
          </div>
        } @else {
          <div class="info-gauge d-inline-flex justify-content-between">
            <div>
              <ultra-vertical-progress-sticks
                [barsToFill]="barsToFill"
                [barOrIconPrimaryColor]="quantityStatusColor"
                [barSecondaryColor]="uniqQuantityStatusColorEnum.RESERVED">
              </ultra-vertical-progress-sticks>
            </div>
            <div class="mx-3"><hr class="vertical" /></div>
            <div class="d-flex align-items-center info-gauge-fill">
              <i class="icon icon-48x icon-infinity icon-green"></i>
            </div>
          </div>
        }
      }
      <div class="text-start text-white-alpha-5 f-size-12 ms-3" i18n>No Time restriction</div>
    </div>
  </div>
</div>
