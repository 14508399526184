import { IBuildBranch } from '../interfaces';
import { BranchType } from '../helpers';

export const branches: IBuildBranch[] = [
  {
    name: BranchType.BETA,
    index: 0,
    cssClass: 'icon-beta-sm',
    color: 'rgba(255,234,167,1)',
    bgColor: 'rgba(255,234,167,.1)',
  },
  {
    name: BranchType.MASTER,
    index: 1,
    cssClass: 'icon-play-sm',
    color: 'rgba(140,232,149,1)',
    bgColor: 'rgba(140,232,149,.1)',
  },
];

export const colors = [
  '#FFEAA7',
  '#FDCB6E',
  '#55EFC4',
  '#0ECC9E',
  '#74B9FF',
  '#0984E3',
  '#A29BFE',
  '#6C5CE7',
  '#FB9393',
  '#D63031',
  '#FAB1A0',
  '#E17055',
  '#81ECEC',
  '#00CEC9',
  '#FD79A8',
  '#E84393',
];
