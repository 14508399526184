import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';

import { AutotestAttributeDirective } from '../../autotests';
import { DomEventsBusClickDirective } from '../../dom-events-bus/dom-events-bus-click.directive';
import { AccordionModule } from '../accordion/accordion.module';
import { IdCardContentModule } from '../id-card-content/id-card-content.module';
import { UniqPreviewModule } from '../uniq-preview/uniq-preview.module';

import { UniqListComponent } from './components/uniq-list/uniq-list.component';

@NgModule({
  imports: [
    CommonModule,
    UniqPreviewModule,
    AccordionModule,
    IdCardContentModule,
    AutotestAttributeDirective,
    DomEventsBusClickDirective,
    RouterLink,
  ],
  declarations: [UniqListComponent],
  exports: [UniqListComponent],
})
export class UniqListModule {}
