import { getEbaName, isVerifiedCreator } from '../../../helpers';
import { ITradability, IUniq, UniqMetadataContent, UniqTradability, UniqValidMetadata } from '../../../models';
import { IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';
import {
  MetadataStatus,
  UniqFactoryMetadataContent,
  UniqFactoryValidMetadata,
} from '../../../uniq-factory/uniq-factory-metadata.interface';

import { IdCardAdapter, IdCardEntity } from './id-card.adapter';

export class UniqIdCardAdapter extends IdCardAdapter<IUniq> {
  transform(data: IUniq): IdCardEntity {
    return {
      ...this.uniqMetadataToInfoAndMedias(data),
      type: 'uniq',
      id: data.id,
      blockchainId: data?.onChainId,
      blockId: data?.blockId,
      serialNumber: data?.serialNumber,
      factoryBlockchainId: data?.factory?.onChainId,
      content: {
        contentType: data?.factory?.type,
        contentTypeCode: 'nft',
      },
      creator: {
        blockchainId: data?.factory?.creator?.blockchainId,
        name: data?.factory?.creator?.eba?.name || getEbaName(data?.factory?.creator?.blockchainId),
        verified: !!data?.factory?.creator?.verified || isVerifiedCreator(data?.factory?.creator?.blockchainId),
      },
      tradability: this.uniqTradabilityToTradability(data?.factory?.tradability),
      status: data.status,
      geofencing: data?.factory?.geofencing,
      tradingPeriod: data.tradingPeriod || data.tradability?.tradingPeriod,
      transferPeriod: data.transferPeriod || data.transferability?.transferPeriod,
      transferability: data?.factory?.transferability,
      quantity: {
        ...data?.factory?.quantity,
        quantityLeft:
          data?.factory?.quantity?.quantityLeft && Number(data.factory.quantity.quantityLeft) < 0
            ? '0'
            : data?.factory?.quantity?.quantityLeft,
        maxMintableTokens:
          data?.factory?.quantity?.maxMintableTokens && Number(data.factory.quantity.maxMintableTokens) < 0
            ? '0'
            : data?.factory?.quantity?.maxMintableTokens,
        maxCirculatingSupply:
          data?.factory?.quantity?.maxCirculatingSupply && Number(data.factory.quantity.maxCirculatingSupply) < 0
            ? '0'
            : data?.factory?.quantity?.maxCirculatingSupply,
      },
      prices: [{ amount: data?.saleInfo?.price?.amount, currency: data?.saleInfo?.price?.currency }],
      revenue: data?.tradingPeriod?.resaleAvailability?.revenue,
      owner: data?.owner,
      mintDate: data?.mintDate,
      metadata: data?.metadata,
      blockchainLineage: data?.blockchainLineage,
      defaultMetadata: data?.factory?.defaultUniqMetadata,
      createdAt: data?.factory?.createdAt,
      isTokenized: true,
      hidden: false,
    };
  }

  private uniqMetadataToInfoAndMedias(data: IUniq): Partial<IdCardEntity> {
    let content: UniqMetadataContent | UniqFactoryMetadataContent | IUniqFactory = data?.factory;

    if (data?.metadata?.status === MetadataStatus.INVALID) {
      content = {
        name: data.id,
        subName: 'Uniq ID',
      };
    } else if (data?.metadata?.status === MetadataStatus.VALID) {
      content = (data?.metadata as UniqValidMetadata)?.content;
    } else if (data?.factory?.metadata?.status === MetadataStatus.VALID) {
      content = (data?.factory?.metadata as UniqFactoryValidMetadata)?.content;
    }

    return {
      info: {
        title: content?.name === '???' ? data.id : content?.name,
        subTitle: content?.subName === '???' ? 'Uniq ID' : content?.subName,
        description: content?.description,
        kind: 'Uniq',
      },
      medias: {
        image: content?.medias?.product,
        square: content?.medias?.square,
        backGroundImage: content?.medias?.hero,
        imageGalleryList: content?.medias?.gallery || [],
      },
    };
  }

  private uniqTradabilityToTradability(data: UniqTradability): ITradability {
    return {
      ...data,
      minimumResellPrice: data?.minimumResellPrice.amount,
      minimumResellPriceCurrency: data?.minimumResellPrice.currency,
      resellRevenueShare: {
        percentage:
          data?.resellRevenueShare?.length && UniqIdCardAdapter.reduceRevenueShareAmount(data?.resellRevenueShare),
        fixedAmount: null,
      },
    };
  }
}
