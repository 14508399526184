<div class="container">
  @for (e of [].constructor(totalBarsCount); track e; let i = $index) {
    <div
      class="bar"
      [style.background-color]="getBarColorToFill(i)"
      [class.bar__empty]="
        !barAltColor && i < totalBarsCount - (barsToFill.lowerBarsToFill + barsToFill.middleBarsToFill)
      "></div>
  }
  @if (displayIcon) {
    <i class="icon icon-48x icon-infinity" [ngStyle]="{ background: barOrIconPrimaryColor }"></i>
  }
</div>
