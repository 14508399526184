import { MetadataStatus } from '@ultra/shared/uniq-factory/uniq-factory-metadata.interface';
import { IUniq, UniqStatus } from '../interfaces/uniq.interface';

export const uniqMock: IUniq = {
  id: '6087ac0fa19bd6a28515108c',
  onChainId: '1611747485604',
  blockId: '222',
  serialNumber: '1',
  correlationId: '333',
  owner: {
    blockchainId: '1611747484444',
    eba: {
      name: 'ultra',
      accountId: '111',
      type: 'USER',
    },
    verified: false,
  },
  mintIndex: 1,
  status: UniqStatus.ON_SALE,
  metadata: {
    id: 'hash',
    status: MetadataStatus.VALID,
    content: { name: 'Metadata Name', subName: 'Metadata Subname' },
    source: null,
  },

  factory: {
    id: '6087ac0fa19bd6a285154444',
    onChainId: '4444747485604',
    type: 'COLLECTIBLE',
    name: 'Mario',
    subName: 'Mario deluxe',
    description: 'Mario is the best game',
    medias: {
      product: {
        uri: 'https://dev.img.ultra.io/1920x1080/601a7bec80157b3720371f1a/60353fa068621a91d7e55ad3-1614102432815/original-images/ss_c9347dd0c6523f26bc850fb635cfb3c4c889a864.jpg',
        contentType: 'IMAGE',
      },
      square: {
        uri: 'https://dev.img.ultra.io/120x160/601a7bec80157b3720371f1a/60353fa068621a91d7e55ad3-1614102432815/original-images/ss_8cfd1150dee3ece84d5d81b41e1be7cd29851fd3.jpg',
        contentType: 'IMAGE',
      },
      hero: {
        uri: 'https://dev.img.ultra.io/1920x1080/601a7bec80157b3720371f1a/60353fa068621a91d7e55ad3-1614102432815/original-images/ss_c9347dd0c6523f26bc850fb635cfb3c4c889a864.jpg',
        contentType: 'IMAGE',
      },
      gallery: [],
    },
    creator: {
      blockchainId: '1611747484444',
      eba: {
        name: 'ultra',
        accountId: '111',
        type: 'USER',
      },
      verified: false,
    },
    geofencing: ['5e4bbfbe19856d0027e8fa2f', '5e4bbfbe19856d0027e8fa30', '5e4bbfbe19856d0027e8fa31'],
    tradability: {
      enabled: true,
      minimumResellPrice: null,
      resellRevenueShare: null,
      tradingEndDate: null,
      tradingStartDate: null,
      tradingPeriod: null,
    },
    prices: [{ amount: '99', currency: { symbol: '$', code: 'USD' } }],
  },
  mintDate: '2021-04-27T06:15:43.383Z',
  tradingPeriod: null,
  saleInfo: {
    onSaleDate: '2021-11-04T11:16:02.507Z',
    price: {
      amount: '2.1',
      currency: {
        symbol: 'UOS',
        code: 'U',
      },
      creators: { ratio: 70, amount: '31.5' },
      owner: { ratio: 27.5, amount: '12.375' },
      platform: { ratio: 2.5, amount: '1.125' },
      promoter: null,
    },
  },
};
