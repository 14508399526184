import { isPlatformBrowser } from '@angular/common';
import { InjectionToken, PLATFORM_ID } from '@angular/core';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { StorageMockImplementation } from '../services/storage/mocks/storage.mock';

export const WINDOW = new InjectionToken<Window>('Window', {
  providedIn: 'root',
  factory: () => window,
});

export const WINDOW_STORE_PROVIDER = {
  provide: WINDOW,
  useFactory: (env: IEnvironment, platformID: any) => {
    if (isPlatformBrowser(platformID)) {
      return window;
    } else {
      const locationURL = new URL(env.storeUrl);
      return {
        ultraos: undefined,
        location: {
          hash: locationURL.hash,
          host: locationURL.host,
          hostname: locationURL.hostname,
          href: locationURL.href,
          origin: locationURL.origin,
          pathname: locationURL.pathname,
          port: locationURL.port,
          protocol: locationURL.protocol,
          search: locationURL.search,
          assign: () => {
            /*noop*/
          },
          replace: () => {
            /*noop*/
          },
        },
        open: (..._: any[]) => {
          /*noop*/
        },
        scroll: (..._: any[]) => {
          /*noop*/
        },
        scrollTo: (..._: any[]) => {
          /*noop*/
        },
        innerWidth: 1280,
        pageYOffset: 0,
        localStorage: new StorageMockImplementation(),
        sessionStorage: new StorageMockImplementation(),
      };
    }
  },
  deps: [APP_CONFIG, PLATFORM_ID],
};
