export default {
  qa: {
    data: {
      countries: [
        {
          id: '5e4e8bcab00a6d0022ef2031',
          name: 'Afghanistan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'AF',
        },
        {
          id: '5e4e8bcab00a6d0022ef2065',
          name: 'Albania',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'AL',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fb4',
          name: 'Algeria',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'DZ',
        },
        {
          id: '616825d910e787e2fbe3f753',
          name: 'American Samoa',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'AS',
        },
        {
          id: '5e4e8bcab00a6d0022ef2066',
          name: 'Andorra',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'AD',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fb5',
          name: 'Angola',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'AO',
        },
        {
          id: '616825d910e7873c79e3f752',
          name: 'Anguilla',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'AI',
        },
        {
          id: '5e4e8bcab00a6d0022ef2046',
          name: 'Antarctica',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'AQ',
        },
        {
          id: '5e4e8bcab00a6d0022ef2002',
          name: 'Antigua and Barbuda',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'AG',
        },
        {
          id: '5e4e8bcab00a6d0022ef2059',
          name: 'Argentina',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'AR',
        },
        {
          id: '5e4e8bcab00a6d0022ef2067',
          name: 'Armenia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'AM',
        },
        {
          id: '616825d910e78733c5e3f754',
          name: 'Aruba',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'AW',
        },
        {
          id: '616825d910e787944be3f751',
          name: 'Ascension Island',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'AC',
        },
        {
          id: '5e4e8bcab00a6d0022ef204b',
          name: 'Australia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'AU',
        },
        {
          id: '5e4e8bcab00a6d0022ef2016',
          name: 'Austria',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'AT',
        },
        {
          id: '5e4e8bcab00a6d0022ef2068',
          name: 'Azerbaijan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'AZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef2043',
          name: 'Bahamas',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9c',
          alpha2Code: 'BS',
        },
        {
          id: '5e4e8bcab00a6d0022ef2032',
          name: 'Bahrain',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'BH',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fea',
          name: 'Bangladesh',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'BD',
        },
        {
          id: '5e4e8bcab00a6d0022ef2003',
          name: 'Barbados',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'BB',
        },
        {
          id: '5e4e8bcab00a6d0022ef2069',
          name: 'Belarus',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'BY',
        },
        {
          id: '5e4e8bcab00a6d0022ef2017',
          name: 'Belgium',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'BE',
        },
        {
          id: '5e4e8bcab00a6d0022ef2004',
          name: 'Belize',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'BZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fb6',
          name: 'Benin',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'BJ',
        },
        {
          id: '616825d910e787ae52e3f757',
          name: 'Bermuda',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'BM',
        },
        {
          id: '5e4e8bcab00a6d0022ef1feb',
          name: 'Bhutan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'BT',
        },
        {
          id: '5e4e8bcab00a6d0022ef205a',
          name: 'Bolivia (Plurinational State of)',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'BO',
        },
        {
          id: '616825d910e787b52ae3f758',
          name: 'Bonaire Sint Eustatius and Saba',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'BQ',
        },
        {
          id: '5e4e8bcab00a6d0022ef206a',
          name: 'Bosnia and Herzegovina',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'BA',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fb7',
          name: 'Botswana',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'BW',
        },
        {
          id: '5e4e8bcab00a6d0022ef205b',
          name: 'Brazil',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'BR',
        },
        {
          id: '616825d910e7876d20e3f77c',
          name: 'British Indian Ocean Territory',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'IO',
        },
        {
          id: '616825d910e7874227e3f778',
          name: 'British Virgin Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'VG',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fec',
          name: 'Brunei Darussalam',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'BN',
        },
        {
          id: '5e4e8bcab00a6d0022ef2018',
          name: 'Bulgaria',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'BG',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fb8',
          name: 'Burkina Faso',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'BF',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fb9',
          name: 'Burundi',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'BI',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fba',
          name: 'Cabo Verde',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'CV',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fed',
          name: 'Cambodia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'KH',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fbb',
          name: 'Cameroon',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'CM',
        },
        {
          id: '5e4e8bcab00a6d0022ef2044',
          name: 'Canada',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9c',
          alpha2Code: 'CA',
        },
        {
          id: '616825d910e787f187e3f768',
          name: 'Cayman Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'KY',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fbc',
          name: 'Central African Republic',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'CF',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fbd',
          name: 'Chad',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'TD',
        },
        {
          id: '5e4e8bcab00a6d0022ef205c',
          name: 'Chile',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'CL',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fee',
          name: 'China',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'CN',
        },
        {
          id: '616825d910e787bc65e3f75c',
          name: 'Christmas Island',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'CX',
        },
        {
          id: '616825d910e787a5b0e3f759',
          name: 'Cocos Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'CC',
        },
        {
          id: '5e4e8bcab00a6d0022ef205d',
          name: 'Colombia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'CO',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fbe',
          name: 'Comoros',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'KM',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fbf',
          name: 'Congo',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'CG',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc0',
          name: 'Congo (Democratic Republic of the)',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'CD',
        },
        {
          id: '616825d910e7871f5ce3f75a',
          name: 'Cook Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'CK',
        },
        {
          id: '5e4e8bcab00a6d0022ef2005',
          name: 'Costa Rica',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'CR',
        },
        {
          id: '5e4e8bcab00a6d0022ef2019',
          name: 'Croatia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'HR',
        },
        {
          id: '5e4e8bcab00a6d0022ef2006',
          name: 'Cuba',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'CU',
        },
        {
          id: '616825d910e787dfa5e3f75b',
          name: 'Curacao',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'CW',
        },
        {
          id: '5e4e8bcab00a6d0022ef201a',
          name: 'Cyprus',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'CY',
        },
        {
          id: '5e4e8bcab00a6d0022ef201b',
          name: 'Czech Republic',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'CZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef201c',
          name: 'Denmark',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'DK',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc2',
          name: 'Djibouti',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'DJ',
        },
        {
          id: '5e4e8bcab00a6d0022ef2007',
          name: 'Dominica',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'DM',
        },
        {
          id: '5e4e8bcab00a6d0022ef2008',
          name: 'Dominican Republic',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'DO',
        },
        {
          id: '5e4e8bcab00a6d0022ef205e',
          name: 'Ecuador',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'EC',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc3',
          name: 'Egypt',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'EG',
        },
        {
          id: '5e4e8bcab00a6d0022ef2009',
          name: 'El Salvador',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'SV',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc4',
          name: 'Equatorial Guinea',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'GQ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc5',
          name: 'Eritrea',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'ER',
        },
        {
          id: '5e4e8bcab00a6d0022ef201d',
          name: 'Estonia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'EE',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc6',
          name: 'Ethiopia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'ET',
        },
        {
          id: '616825d910e7872730e3f75d',
          name: 'Falkland Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'FK',
        },
        {
          id: '616825d910e7873be9e3f75e',
          name: 'Faroe Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'FO',
        },
        {
          id: '5e4e8bcab00a6d0022ef204c',
          name: 'Fiji',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'FJ',
        },
        {
          id: '5e4e8bcab00a6d0022ef201e',
          name: 'Finland',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'FI',
        },
        {
          id: '5e4e8bcab00a6d0022ef201f',
          name: 'France',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'FR',
        },
        {
          id: '616825d910e787bc37e3f75f',
          name: 'French Guiana',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'GF',
        },
        {
          id: '616825d910e7871ce4e3f771',
          name: 'French Polynesia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'PF',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc7',
          name: 'Gabon',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'GA',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc8',
          name: 'Gambia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'GM',
        },
        {
          id: '5e4e8bcab00a6d0022ef206b',
          name: 'Georgia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'GE',
        },
        {
          id: '5e4e8bcab00a6d0022ef2020',
          name: 'Germany',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'DE',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc9',
          name: 'Ghana',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'GH',
        },
        {
          id: '616825d910e78710e7e3f761',
          name: 'Gibraltar',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'GI',
        },
        {
          id: '5e4e8bcab00a6d0022ef2021',
          name: 'Greece',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'GR',
        },
        {
          id: '616825d910e7870d44e3f762',
          name: 'Greenland',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'GL',
        },
        {
          id: '5e4e8bcab00a6d0022ef200a',
          name: 'Grenada',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'GD',
        },
        {
          id: '616825d910e78711b3e3f763',
          name: 'Guadeloupe',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'GP',
        },
        {
          id: '616825d910e7876145e3f764',
          name: 'Guam',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'GU',
        },
        {
          id: '5e4e8bcab00a6d0022ef200b',
          name: 'Guatemala',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'GT',
        },
        {
          id: '616825d910e78708d9e3f760',
          name: 'Guernsey',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'GG',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fca',
          name: 'Guinea',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'GN',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fcb',
          name: 'Guinea-Bissau',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'GW',
        },
        {
          id: '5e4e8bcab00a6d0022ef205f',
          name: 'Guyana',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'GY',
        },
        {
          id: '5e4e8bcab00a6d0022ef200c',
          name: 'Haiti',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'HT',
        },
        {
          id: '5e4e8bcab00a6d0022ef200d',
          name: 'Honduras',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'HN',
        },
        {
          id: '616825d910e7876485e3f765',
          name: 'Hong Kong',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'HK',
        },
        {
          id: '5e4e8bcab00a6d0022ef2022',
          name: 'Hungary',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'HU',
        },
        {
          id: '5e4e8bcab00a6d0022ef206c',
          name: 'Iceland',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'IS',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fef',
          name: 'India',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'IN',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff0',
          name: 'Indonesia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'ID',
        },
        {
          id: '5e4e8bcab00a6d0022ef2033',
          name: 'Iran',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'IR',
        },
        {
          id: '5e4e8bcab00a6d0022ef2034',
          name: 'Iraq',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'IQ',
        },
        {
          id: '5e4e8bcab00a6d0022ef2023',
          name: 'Ireland',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'IE',
        },
        {
          id: '616825d910e7874035e3f766',
          name: 'Isle of Man',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'IM',
        },
        {
          id: '5e4e8bcab00a6d0022ef2035',
          name: 'Israel',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'IL',
        },
        {
          id: '5e4e8bcab00a6d0022ef2024',
          name: 'Italy',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'IT',
        },
        {
          id: '5e4e8bcab00a6d0022ef200e',
          name: 'Jamaica',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'JM',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff1',
          name: 'Japan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'JP',
        },
        {
          id: '616825d910e787135de3f767',
          name: 'Jersey',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'JE',
        },
        {
          id: '5e4e8bcab00a6d0022ef2036',
          name: 'Jordan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'JO',
        },
        {
          id: '5e4e8bcab00a6d0022ef206d',
          name: 'Kazakhstan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'KZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fcc',
          name: 'Kenya',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'KE',
        },
        {
          id: '5e4e8bcab00a6d0022ef204d',
          name: 'Kiribati',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'KI',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff2',
          name: 'Korea (Democratic People’s Republic of) north',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'KP',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff3',
          name: 'Korea (Republic of) south',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'KR',
        },
        {
          id: '616825d910e787342ee3f77a',
          name: 'Kosovo',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'XK',
        },
        {
          id: '5e4e8bcab00a6d0022ef2037',
          name: 'Kuwait',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'KW',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff4',
          name: 'Kyrgyzstan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'KG',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff5',
          name: 'Laos',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'LA',
        },
        {
          id: '5e4e8bcab00a6d0022ef2025',
          name: 'Latvia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'LV',
        },
        {
          id: '5e4e8bcab00a6d0022ef2038',
          name: 'Lebanon',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'LB',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fcd',
          name: 'Lesotho',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'LS',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fce',
          name: 'Liberia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'LR',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fcf',
          name: 'Libya',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'LY',
        },
        {
          id: '5e4e8bcab00a6d0022ef206e',
          name: 'Liechtenstein',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'LI',
        },
        {
          id: '5e4e8bcab00a6d0022ef2026',
          name: 'Lithuania',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'LT',
        },
        {
          id: '5e4e8bcab00a6d0022ef2027',
          name: 'Luxembourg',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'LU',
        },
        {
          id: '616825d910e787750ee3f76a',
          name: 'Macau',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'MO',
        },
        {
          id: '5e4e8bcab00a6d0022ef206f',
          name: 'Macedonia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'MK',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd0',
          name: 'Madagascar',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'MG',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd1',
          name: 'Malawi',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'MW',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff6',
          name: 'Malaysia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'MY',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff7',
          name: 'Maldives',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'MV',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd2',
          name: 'Mali',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'ML',
        },
        {
          id: '5e4e8bcab00a6d0022ef2028',
          name: 'Malta',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'MT',
        },
        {
          id: '5e4e8bcab00a6d0022ef204e',
          name: 'Marshall Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'MH',
        },
        {
          id: '616825d910e787ce10e3f76c',
          name: 'Martinique',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'MQ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd3',
          name: 'Mauritania',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'MR',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd4',
          name: 'Mauritius',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'MU',
        },
        {
          id: '616825d910e7870116e3f77b',
          name: 'Mayotte',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'YT',
        },
        {
          id: '5e4e8bcab00a6d0022ef200f',
          name: 'Mexico',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'MX',
        },
        {
          id: '5e4e8bcab00a6d0022ef204f',
          name: 'Micronesia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'FM',
        },
        {
          id: '5e4e8bcab00a6d0022ef2070',
          name: 'Moldova',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'MD',
        },
        {
          id: '5e4e8bcab00a6d0022ef2071',
          name: 'Monaco',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'MC',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff8',
          name: 'Mongolia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'MN',
        },
        {
          id: '5e4e8bcab00a6d0022ef2072',
          name: 'Montenegro',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'ME',
        },
        {
          id: '616825d910e787eebee3f76d',
          name: 'Montserrat',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'MS',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd5',
          name: 'Morocco',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'MA',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd6',
          name: 'Mozambique',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'MZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ff9',
          name: 'Myanmar',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'MM',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd7',
          name: 'Namibia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'NA',
        },
        {
          id: '5e4e8bcab00a6d0022ef2050',
          name: 'Nauru',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'NR',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ffa',
          name: 'Nepal',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'NP',
        },
        {
          id: '5e4e8bcab00a6d0022ef2029',
          name: 'Netherlands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'NL',
        },
        {
          id: '616825d910e78750e5e3f83d',
          name: 'Netherlands Antilles',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'AN',
        },
        {
          id: '616825d910e787b9d3e3f76e',
          name: 'New Caledonia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'NC',
        },
        {
          id: '5e4e8bcab00a6d0022ef2051',
          name: 'New Zealand',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'NZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef2010',
          name: 'Nicaragua',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'NI',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd8',
          name: 'Niger',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'NE',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fd9',
          name: 'Nigeria',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'NG',
        },
        {
          id: '616825d910e7870a20e3f770',
          name: 'Niue',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'NU',
        },
        {
          id: '616825d910e787c8bce3f76f',
          name: 'Norfolk Island',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'NF',
        },
        {
          id: '616825d910e7878dd9e3f76b',
          name: 'Northern Mariana Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'MP',
        },
        {
          id: '5e4e8bcab00a6d0022ef2073',
          name: 'Norway',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'NO',
        },
        {
          id: '5e4e8bcab00a6d0022ef2039',
          name: 'Oman',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'OM',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ffb',
          name: 'Pakistan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'PK',
        },
        {
          id: '5e4e8bcab00a6d0022ef2052',
          name: 'Palau',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'PW',
        },
        {
          id: '5e4e8bcab00a6d0022ef2049',
          name: 'Palestine',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'PS',
        },
        {
          id: '5e4e8bcab00a6d0022ef2011',
          name: 'Panama',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'PA',
        },
        {
          id: '5e4e8bcab00a6d0022ef2053',
          name: 'Papua New Guinea',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'PG',
        },
        {
          id: '5e4e8bcab00a6d0022ef2060',
          name: 'Paraguay',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'PY',
        },
        {
          id: '5e4e8bcab00a6d0022ef2061',
          name: 'Peru',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'PE',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ffc',
          name: 'Philippines',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'PH',
        },
        {
          id: '616825d910e7876b01e3f83e',
          name: 'Pitcairn',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'PN',
        },
        {
          id: '5e4e8bcab00a6d0022ef202a',
          name: 'Poland',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'PL',
        },
        {
          id: '5e4e8bcab00a6d0022ef202b',
          name: 'Portugal',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'PT',
        },
        {
          id: '616825d910e78700cee3f773',
          name: 'Puerto Rico',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'PR',
        },
        {
          id: '5e4e8bcab00a6d0022ef203a',
          name: 'Qatar',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'QA',
        },
        {
          id: '616825d910e787d103e3f774',
          name: 'Reunion',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'RE',
        },
        {
          id: '5e4e8bcab00a6d0022ef202c',
          name: 'Romania',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'RO',
        },
        {
          id: '5e4e8bcab00a6d0022ef2074',
          name: 'Russia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'RU',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fda',
          name: 'Rwanda',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'RW',
        },
        {
          id: '616825d910e7875028e3f756',
          name: 'Saint Barthelemy',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'BL',
        },
        {
          id: '616825d910e7879eb8e3f77d',
          name: 'Saint Helena',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'SH',
        },
        {
          id: '5e4e8bcab00a6d0022ef2012',
          name: 'Saint Kitts And Nevis',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'KN',
        },
        {
          id: '5e4e8bcab00a6d0022ef2013',
          name: 'Saint Lucia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'LC',
        },
        {
          id: '616825d910e7879349e3f769',
          name: 'Saint Martin',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'MF',
        },
        {
          id: '616825d910e7874dcce3f772',
          name: 'Saint Pierre and Miquelon',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'PM',
        },
        {
          id: '5e4e8bcab00a6d0022ef2014',
          name: 'Saint Vincent and The Grenadines',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'VC',
        },
        {
          id: '5e4e8bcab00a6d0022ef2054',
          name: 'Samoa',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'WS',
        },
        {
          id: '5e4e8bcab00a6d0022ef2075',
          name: 'San Marino',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'SM',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fdb',
          name: 'Sao Tome and Principe',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'ST',
        },
        {
          id: '5e4e8bcab00a6d0022ef203b',
          name: 'Saudi Arabia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'SA',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fdc',
          name: 'Senegal',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'SN',
        },
        {
          id: '5e4e8bcab00a6d0022ef2076',
          name: 'Serbia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'RS',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fdd',
          name: 'Seychelles',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'SC',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fde',
          name: 'Sierra Leone',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'SL',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ffd',
          name: 'Singapore',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'SG',
        },
        {
          id: '616825d910e7877598e3f776',
          name: 'Sint Maarten',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'SX',
        },
        {
          id: '5e4e8bcab00a6d0022ef202d',
          name: 'Slovakia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'SK',
        },
        {
          id: '5e4e8bcab00a6d0022ef202e',
          name: 'Slovenia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'SI',
        },
        {
          id: '5e4e8bcab00a6d0022ef2055',
          name: 'Solomon Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'SB',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fdf',
          name: 'Somalia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'SO',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe0',
          name: 'South Africa',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'ZA',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe1',
          name: 'South Sudan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'SS',
        },
        {
          id: '5e4e8bcab00a6d0022ef202f',
          name: 'Spain',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'ES',
        },
        {
          id: '5e4e8bcab00a6d0022ef1ffe',
          name: 'Sri Lanka',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'LK',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe2',
          name: 'Sudan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'SD',
        },
        {
          id: '5e4e8bcab00a6d0022ef2062',
          name: 'Suriname',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'SR',
        },
        {
          id: '616825d910e787534be3f775',
          name: 'Svalbard and Jan Mayen',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'SJ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe3',
          name: 'Swaziland',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'SZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef2030',
          name: 'Sweden',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'SE',
        },
        {
          id: '5e4e8bcab00a6d0022ef2077',
          name: 'Switzerland',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'CH',
        },
        {
          id: '5e4e8bcab00a6d0022ef203c',
          name: 'Syria',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'SY',
        },
        {
          id: '5e4e8bcab00a6d0022ef2048',
          name: 'Taiwan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'TW',
        },
        {
          id: '5e4e8bcab00a6d0022ef203d',
          name: 'Tajikistan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'TJ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe4',
          name: 'Tanzania',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'TZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fff',
          name: 'Thailand',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'TH',
        },
        {
          id: '5e4e8bcab00a6d0022ef2000',
          name: 'Timor-east',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'TL',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe5',
          name: 'Togo',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'TG',
        },
        {
          id: '616825d910e787666ce3f77e',
          name: 'Tokelau',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'TK',
        },
        {
          id: '5e4e8bcab00a6d0022ef2056',
          name: 'Tonga',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'TO',
        },
        {
          id: '5e4e8bcab00a6d0022ef2015',
          name: 'Trinidad and Tobago',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'TT',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe6',
          name: 'Tunisia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'TN',
        },
        {
          id: '5e4e8bcab00a6d0022ef203e',
          name: 'Turkey',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'TR',
        },
        {
          id: '5e4e8bcab00a6d0022ef203f',
          name: 'Turkmenistan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'TM',
        },
        {
          id: '616825d910e7874582e3f777',
          name: 'Turks',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'TC',
        },
        {
          id: '5e4e8bcab00a6d0022ef2057',
          name: 'Tuvalu',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'TV',
        },
        {
          id: '616825d910e78785f8e3f779',
          name: 'U.S. Virgin Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9d',
          alpha2Code: 'VI',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe7',
          name: 'Uganda',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'UG',
        },
        {
          id: '5e4e8bcab00a6d0022ef2078',
          name: 'Ukraine',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'UA',
        },
        {
          id: '5e4e8bcab00a6d0022ef2040',
          name: 'United Arab Emirates',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'AE',
        },
        {
          id: '5e4e8bcab00a6d0022ef2079',
          name: 'United Kingdom',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa1',
          alpha2Code: 'GB',
        },
        {
          id: '5e4e8bcab00a6d0022ef2045',
          name: 'United States',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9c',
          alpha2Code: 'US',
        },
        {
          id: '5e4e8bcab00a6d0022ef2063',
          name: 'Uruguay',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'UY',
        },
        {
          id: '5e4e8bcab00a6d0022ef2041',
          name: 'Uzbekistan',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'UZ',
        },
        {
          id: '5e4e8bcab00a6d0022ef2058',
          name: 'Vanuatu',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'VU',
        },
        {
          id: '5e4e8bcab00a6d0022ef204a',
          name: 'Vatican City',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'VA',
        },
        {
          id: '5e4e8bcab00a6d0022ef2064',
          name: 'Venezuela',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9e',
          alpha2Code: 'VE',
        },
        {
          id: '5e4e8bcab00a6d0022ef2001',
          name: 'Viet Nam',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa0',
          alpha2Code: 'VN',
        },
        {
          id: '616825d910e7874bf5e3f77f',
          name: 'Wallis and Futuna',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9f',
          alpha2Code: 'WF',
        },
        {
          id: '5e4e8bcab00a6d0022ef2047',
          name: 'Western Sahara',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa5',
          alpha2Code: 'EH',
        },
        {
          id: '5e4e8bcab00a6d0022ef2042',
          name: 'Yemen',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa3',
          alpha2Code: 'YE',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe8',
          name: 'Zambia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'ZM',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fe9',
          name: 'Zimbabwe',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'ZW',
        },
        {
          id: '5e4e8bcab00a6d0022ef1fc1',
          name: 'ivory coast',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa4',
          alpha2Code: 'CI',
        },
        {
          id: '616825d910e787bcd6e3f755',
          name: 'Åland Islands',
          parentRegionId: '5e4e8bc8b00a6d0022ef1fa2',
          alpha2Code: 'AX',
        },
      ],
      regions: [
        {
          id: '5e4e8bc8b00a6d0022ef1f99',
          name: 'APAC',
          parentRegionId: null,
        },
        {
          id: '5e4e8bc8b00a6d0022ef1f98',
          name: 'NCSA',
          parentRegionId: null,
        },
        {
          id: '5e4e8bc8b00a6d0022ef1f9a',
          name: 'EMEA',
          parentRegionId: null,
        },
        {
          id: '5e4e8bc8b00a6d0022ef1f9c',
          name: 'North America',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f98',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1f9e',
          name: 'South America',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f98',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1f9f',
          name: 'Pacific',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f99',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1f9d',
          name: 'Central America',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f98',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1fa1',
          name: 'Rest of Europe',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1fa0',
          name: 'Asia',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f99',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1fa3',
          name: 'Middle East',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1fa4',
          name: 'Africa',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1fa2',
          name: 'European Union',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
        },
        {
          id: '5e4e8bc8b00a6d0022ef1fa5',
          name: 'Other',
          parentRegionId: '5e4e8bc8b00a6d0022ef1f9a',
        },
      ],
    },
  },
  staging: {
    data: {
      countries: [
        {
          id: '5e5ce41841e1870029ed5e40',
          name: 'Afghanistan',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'AF',
        },
        {
          id: '5e5ce41841e1870029ed5e74',
          name: 'Albania',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'AL',
        },
        {
          id: '5e5ce41841e1870029ed5dc3',
          name: 'Algeria',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'DZ',
        },
        {
          id: '6166952f4f17de56efd41bc4',
          name: 'American Samoa',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'AS',
        },
        {
          id: '5e5ce41841e1870029ed5e75',
          name: 'Andorra',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'AD',
        },
        {
          id: '5e5ce41841e1870029ed5dc4',
          name: 'Angola',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'AO',
        },
        {
          id: '6166952f4f17ded727d41bc3',
          name: 'Anguilla',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'AI',
        },
        {
          id: '5e5ce41841e1870029ed5e55',
          name: 'Antarctica',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'AQ',
        },
        {
          id: '5e5ce41841e1870029ed5e11',
          name: 'Antigua and Barbuda',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'AG',
        },
        {
          id: '5e5ce41841e1870029ed5e68',
          name: 'Argentina',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'AR',
        },
        {
          id: '5e5ce41841e1870029ed5e76',
          name: 'Armenia',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'AM',
        },
        {
          id: '6166952f4f17dee6b1d41bc5',
          name: 'Aruba',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'AW',
        },
        {
          id: '6166952f4f17de6256d41bc2',
          name: 'Ascension Island',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'AC',
        },
        {
          id: '5e5ce41841e1870029ed5e5a',
          name: 'Australia',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'AU',
        },
        {
          id: '5e5ce41841e1870029ed5e25',
          name: 'Austria',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'AT',
        },
        {
          id: '5e5ce41841e1870029ed5e77',
          name: 'Azerbaijan',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'AZ',
        },
        {
          id: '5e5ce41841e1870029ed5e52',
          name: 'Bahamas',
          parentRegionId: '5e5ce41641e1870029ed5dab',
          alpha2Code: 'BS',
        },
        {
          id: '5e5ce41841e1870029ed5e41',
          name: 'Bahrain',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'BH',
        },
        {
          id: '5e5ce41841e1870029ed5df9',
          name: 'Bangladesh',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'BD',
        },
        {
          id: '5e5ce41841e1870029ed5e12',
          name: 'Barbados',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'BB',
        },
        {
          id: '5e5ce41841e1870029ed5e78',
          name: 'Belarus',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'BY',
        },
        {
          id: '5e5ce41841e1870029ed5e26',
          name: 'Belgium',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'BE',
        },
        {
          id: '5e5ce41841e1870029ed5e13',
          name: 'Belize',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'BZ',
        },
        {
          id: '5e5ce41841e1870029ed5dc5',
          name: 'Benin',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'BJ',
        },
        {
          id: '6166952f4f17de1fb9d41bc8',
          name: 'Bermuda',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'BM',
        },
        {
          id: '5e5ce41841e1870029ed5dfa',
          name: 'Bhutan',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'BT',
        },
        {
          id: '5e5ce41841e1870029ed5e69',
          name: 'Bolivia (Plurinational State of)',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'BO',
        },
        {
          id: '6166952f4f17de4363d41bc9',
          name: 'Bonaire Sint Eustatius and Saba',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'BQ',
        },
        {
          id: '5e5ce41841e1870029ed5e79',
          name: 'Bosnia and Herzegovina',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'BA',
        },
        {
          id: '5e5ce41841e1870029ed5dc6',
          name: 'Botswana',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'BW',
        },
        {
          id: '5e5ce41841e1870029ed5e6a',
          name: 'Brazil',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'BR',
        },
        {
          id: '6166952f4f17de57a0d41bed',
          name: 'British Indian Ocean Territory',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'IO',
        },
        {
          id: '6166952f4f17de6c66d41be9',
          name: 'British Virgin Islands',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'VG',
        },
        {
          id: '5e5ce41841e1870029ed5dfb',
          name: 'Brunei Darussalam',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'BN',
        },
        {
          id: '5e5ce41841e1870029ed5e27',
          name: 'Bulgaria',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'BG',
        },
        {
          id: '5e5ce41841e1870029ed5dc7',
          name: 'Burkina Faso',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'BF',
        },
        {
          id: '5e5ce41841e1870029ed5dc8',
          name: 'Burundi',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'BI',
        },
        {
          id: '5e5ce41841e1870029ed5dc9',
          name: 'Cabo Verde',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'CV',
        },
        {
          id: '5e5ce41841e1870029ed5dfc',
          name: 'Cambodia',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'KH',
        },
        {
          id: '5e5ce41841e1870029ed5dca',
          name: 'Cameroon',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'CM',
        },
        {
          id: '5e5ce41841e1870029ed5e53',
          name: 'Canada',
          parentRegionId: '5e5ce41641e1870029ed5dab',
          alpha2Code: 'CA',
        },
        {
          id: '6166952f4f17de3badd41bd9',
          name: 'Cayman Islands',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'KY',
        },
        {
          id: '5e5ce41841e1870029ed5dcb',
          name: 'Central African Republic',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'CF',
        },
        {
          id: '5e5ce41841e1870029ed5dcc',
          name: 'Chad',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'TD',
        },
        {
          id: '5e5ce41841e1870029ed5e6b',
          name: 'Chile',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'CL',
        },
        {
          id: '5e5ce41841e1870029ed5dfd',
          name: 'China',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'CN',
        },
        {
          id: '6166952f4f17de3cf9d41bcd',
          name: 'Christmas Island',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'CX',
        },
        {
          id: '6166952f4f17de88e9d41bca',
          name: 'Cocos Islands',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'CC',
        },
        {
          id: '5e5ce41841e1870029ed5e6c',
          name: 'Colombia',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'CO',
        },
        {
          id: '5e5ce41841e1870029ed5dcd',
          name: 'Comoros',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'KM',
        },
        {
          id: '5e5ce41841e1870029ed5dce',
          name: 'Congo',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'CG',
        },
        {
          id: '5e5ce41841e1870029ed5dcf',
          name: 'Congo (Democratic Republic of the)',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'CD',
        },
        {
          id: '6166952f4f17de1555d41bcb',
          name: 'Cook Islands',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'CK',
        },
        {
          id: '5e5ce41841e1870029ed5e14',
          name: 'Costa Rica',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'CR',
        },
        {
          id: '5e5ce41841e1870029ed5e28',
          name: 'Croatia',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'HR',
        },
        {
          id: '5e5ce41841e1870029ed5e15',
          name: 'Cuba',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'CU',
        },
        {
          id: '6166952f4f17de1285d41bcc',
          name: 'Curacao',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'CW',
        },
        {
          id: '5e5ce41841e1870029ed5e29',
          name: 'Cyprus',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'CY',
        },
        {
          id: '5e5ce41841e1870029ed5e2a',
          name: 'Czech Republic',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'CZ',
        },
        {
          id: '5e5ce41841e1870029ed5e2b',
          name: 'Denmark',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'DK',
        },
        {
          id: '5e5ce41841e1870029ed5dd1',
          name: 'Djibouti',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'DJ',
        },
        {
          id: '5e5ce41841e1870029ed5e16',
          name: 'Dominica',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'DM',
        },
        {
          id: '5e5ce41841e1870029ed5e17',
          name: 'Dominican Republic',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'DO',
        },
        {
          id: '5e5ce41841e1870029ed5e6d',
          name: 'Ecuador',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'EC',
        },
        {
          id: '5e5ce41841e1870029ed5dd2',
          name: 'Egypt',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'EG',
        },
        {
          id: '5e5ce41841e1870029ed5e18',
          name: 'El Salvador',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'SV',
        },
        {
          id: '5e5ce41841e1870029ed5dd3',
          name: 'Equatorial Guinea',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'GQ',
        },
        {
          id: '5e5ce41841e1870029ed5dd4',
          name: 'Eritrea',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'ER',
        },
        {
          id: '5e5ce41841e1870029ed5e2c',
          name: 'Estonia',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'EE',
        },
        {
          id: '5e5ce41841e1870029ed5dd5',
          name: 'Ethiopia',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'ET',
        },
        {
          id: '6166952f4f17de58bbd41bce',
          name: 'Falkland Islands',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'FK',
        },
        {
          id: '6166952f4f17de3f84d41bcf',
          name: 'Faroe Islands',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'FO',
        },
        {
          id: '5e5ce41841e1870029ed5e5b',
          name: 'Fiji',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'FJ',
        },
        {
          id: '5e5ce41841e1870029ed5e2d',
          name: 'Finland',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'FI',
        },
        {
          id: '5e5ce41841e1870029ed5e2e',
          name: 'France',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'FR',
        },
        {
          id: '6166952f4f17de88b0d41bd0',
          name: 'French Guiana',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'GF',
        },
        {
          id: '6166952f4f17de2b23d41be2',
          name: 'French Polynesia',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'PF',
        },
        {
          id: '5e5ce41841e1870029ed5dd6',
          name: 'Gabon',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'GA',
        },
        {
          id: '5e5ce41841e1870029ed5dd7',
          name: 'Gambia',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'GM',
        },
        {
          id: '5e5ce41841e1870029ed5e7a',
          name: 'Georgia',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'GE',
        },
        {
          id: '5e5ce41841e1870029ed5e2f',
          name: 'Germany',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'DE',
        },
        {
          id: '5e5ce41841e1870029ed5dd8',
          name: 'Ghana',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'GH',
        },
        {
          id: '6166952f4f17de370bd41bd2',
          name: 'Gibraltar',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'GI',
        },
        {
          id: '5e5ce41841e1870029ed5e30',
          name: 'Greece',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'GR',
        },
        {
          id: '6166952f4f17de45b7d41bd3',
          name: 'Greenland',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'GL',
        },
        {
          id: '5e5ce41841e1870029ed5e19',
          name: 'Grenada',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'GD',
        },
        {
          id: '6166952f4f17decb09d41bd4',
          name: 'Guadeloupe',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'GP',
        },
        {
          id: '6166952f4f17dee76cd41bd5',
          name: 'Guam',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'GU',
        },
        {
          id: '5e5ce41841e1870029ed5e1a',
          name: 'Guatemala',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'GT',
        },
        {
          id: '6166952f4f17dea1e8d41bd1',
          name: 'Guernsey',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'GG',
        },
        {
          id: '5e5ce41841e1870029ed5dd9',
          name: 'Guinea',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'GN',
        },
        {
          id: '5e5ce41841e1870029ed5dda',
          name: 'Guinea-Bissau',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'GW',
        },
        {
          id: '5e5ce41841e1870029ed5e6e',
          name: 'Guyana',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'GY',
        },
        {
          id: '5e5ce41841e1870029ed5e1b',
          name: 'Haiti',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'HT',
        },
        {
          id: '5e5ce41841e1870029ed5e1c',
          name: 'Honduras',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'HN',
        },
        {
          id: '6166952f4f17de1cbdd41bd6',
          name: 'Hong Kong',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'HK',
        },
        {
          id: '5e5ce41841e1870029ed5e31',
          name: 'Hungary',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'HU',
        },
        {
          id: '5e5ce41841e1870029ed5e7b',
          name: 'Iceland',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'IS',
        },
        {
          id: '5e5ce41841e1870029ed5dfe',
          name: 'India',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'IN',
        },
        {
          id: '5e5ce41841e1870029ed5dff',
          name: 'Indonesia',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'ID',
        },
        {
          id: '5e5ce41841e1870029ed5e42',
          name: 'Iran',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'IR',
        },
        {
          id: '5e5ce41841e1870029ed5e43',
          name: 'Iraq',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'IQ',
        },
        {
          id: '5e5ce41841e1870029ed5e32',
          name: 'Ireland',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'IE',
        },
        {
          id: '6166952f4f17de76a7d41bd7',
          name: 'Isle of Man',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'IM',
        },
        {
          id: '5e5ce41841e1870029ed5e44',
          name: 'Israel',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'IL',
        },
        {
          id: '5e5ce41841e1870029ed5e33',
          name: 'Italy',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'IT',
        },
        {
          id: '5e5ce41841e1870029ed5e1d',
          name: 'Jamaica',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'JM',
        },
        {
          id: '5e5ce41841e1870029ed5e00',
          name: 'Japan',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'JP',
        },
        {
          id: '6166952f4f17ded861d41bd8',
          name: 'Jersey',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'JE',
        },
        {
          id: '5e5ce41841e1870029ed5e45',
          name: 'Jordan',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'JO',
        },
        {
          id: '5e5ce41841e1870029ed5e7c',
          name: 'Kazakhstan',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'KZ',
        },
        {
          id: '5e5ce41841e1870029ed5ddb',
          name: 'Kenya',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'KE',
        },
        {
          id: '5e5ce41841e1870029ed5e5c',
          name: 'Kiribati',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'KI',
        },
        {
          id: '5e5ce41841e1870029ed5e01',
          name: 'Korea (Democratic People’s Republic of) north',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'KP',
        },
        {
          id: '5e5ce41841e1870029ed5e02',
          name: 'Korea (Republic of) south',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'KR',
        },
        {
          id: '6166952f4f17defeded41beb',
          name: 'Kosovo',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'XK',
        },
        {
          id: '5e5ce41841e1870029ed5e46',
          name: 'Kuwait',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'KW',
        },
        {
          id: '5e5ce41841e1870029ed5e03',
          name: 'Kyrgyzstan',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'KG',
        },
        {
          id: '5e5ce41841e1870029ed5e04',
          name: 'Laos',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'LA',
        },
        {
          id: '5e5ce41841e1870029ed5e34',
          name: 'Latvia',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'LV',
        },
        {
          id: '5e5ce41841e1870029ed5e47',
          name: 'Lebanon',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'LB',
        },
        {
          id: '5e5ce41841e1870029ed5ddc',
          name: 'Lesotho',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'LS',
        },
        {
          id: '5e5ce41841e1870029ed5ddd',
          name: 'Liberia',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'LR',
        },
        {
          id: '5e5ce41841e1870029ed5dde',
          name: 'Libya',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'LY',
        },
        {
          id: '5e5ce41841e1870029ed5e7d',
          name: 'Liechtenstein',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'LI',
        },
        {
          id: '5e5ce41841e1870029ed5e35',
          name: 'Lithuania',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'LT',
        },
        {
          id: '5e5ce41841e1870029ed5e36',
          name: 'Luxembourg',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'LU',
        },
        {
          id: '6166952f4f17de3cdad41bdb',
          name: 'Macau',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'MO',
        },
        {
          id: '5e5ce41841e1870029ed5e7e',
          name: 'Macedonia',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'MK',
        },
        {
          id: '5e5ce41841e1870029ed5ddf',
          name: 'Madagascar',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'MG',
        },
        {
          id: '5e5ce41841e1870029ed5de0',
          name: 'Malawi',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'MW',
        },
        {
          id: '5e5ce41841e1870029ed5e05',
          name: 'Malaysia',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'MY',
        },
        {
          id: '5e5ce41841e1870029ed5e06',
          name: 'Maldives',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'MV',
        },
        {
          id: '5e5ce41841e1870029ed5de1',
          name: 'Mali',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'ML',
        },
        {
          id: '5e5ce41841e1870029ed5e37',
          name: 'Malta',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'MT',
        },
        {
          id: '5e5ce41841e1870029ed5e5d',
          name: 'Marshall Islands',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'MH',
        },
        {
          id: '6166952f4f17def57ed41bdd',
          name: 'Martinique',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'MQ',
        },
        {
          id: '5e5ce41841e1870029ed5de2',
          name: 'Mauritania',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'MR',
        },
        {
          id: '5e5ce41841e1870029ed5de3',
          name: 'Mauritius',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'MU',
        },
        {
          id: '6166952f4f17dee598d41bec',
          name: 'Mayotte',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'YT',
        },
        {
          id: '5e5ce41841e1870029ed5e1e',
          name: 'Mexico',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'MX',
        },
        {
          id: '5e5ce41841e1870029ed5e5e',
          name: 'Micronesia',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'FM',
        },
        {
          id: '5e5ce41841e1870029ed5e7f',
          name: 'Moldova',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'MD',
        },
        {
          id: '5e5ce41841e1870029ed5e80',
          name: 'Monaco',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'MC',
        },
        {
          id: '5e5ce41841e1870029ed5e07',
          name: 'Mongolia',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'MN',
        },
        {
          id: '5e5ce41841e1870029ed5e81',
          name: 'Montenegro',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'ME',
        },
        {
          id: '6166952f4f17deb661d41bde',
          name: 'Montserrat',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'MS',
        },
        {
          id: '5e5ce41841e1870029ed5de4',
          name: 'Morocco',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'MA',
        },
        {
          id: '5e5ce41841e1870029ed5de5',
          name: 'Mozambique',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'MZ',
        },
        {
          id: '5e5ce41841e1870029ed5e08',
          name: 'Myanmar',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'MM',
        },
        {
          id: '5e5ce41841e1870029ed5de6',
          name: 'Namibia',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'NA',
        },
        {
          id: '5e5ce41841e1870029ed5e5f',
          name: 'Nauru',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'NR',
        },
        {
          id: '5e5ce41841e1870029ed5e09',
          name: 'Nepal',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'NP',
        },
        {
          id: '5e5ce41841e1870029ed5e38',
          name: 'Netherlands',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'NL',
        },
        {
          id: '6166952f4f17decc6cd41cae',
          name: 'Netherlands Antilles',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'AN',
        },
        {
          id: '6166952f4f17de4387d41bdf',
          name: 'New Caledonia',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'NC',
        },
        {
          id: '5e5ce41841e1870029ed5e60',
          name: 'New Zealand',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'NZ',
        },
        {
          id: '5e5ce41841e1870029ed5e1f',
          name: 'Nicaragua',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'NI',
        },
        {
          id: '5e5ce41841e1870029ed5de7',
          name: 'Niger',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'NE',
        },
        {
          id: '5e5ce41841e1870029ed5de8',
          name: 'Nigeria',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'NG',
        },
        {
          id: '6166952f4f17de677cd41be1',
          name: 'Niue',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'NU',
        },
        {
          id: '6166952f4f17def34ad41be0',
          name: 'Norfolk Island',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'NF',
        },
        {
          id: '6166952f4f17de9921d41bdc',
          name: 'Northern Mariana Islands',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'MP',
        },
        {
          id: '5e5ce41841e1870029ed5e82',
          name: 'Norway',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'NO',
        },
        {
          id: '5e5ce41841e1870029ed5e48',
          name: 'Oman',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'OM',
        },
        {
          id: '5e5ce41841e1870029ed5e0a',
          name: 'Pakistan',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'PK',
        },
        {
          id: '5e5ce41841e1870029ed5e61',
          name: 'Palau',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'PW',
        },
        {
          id: '5e5ce41841e1870029ed5e58',
          name: 'Palestine',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'PS',
        },
        {
          id: '5e5ce41841e1870029ed5e20',
          name: 'Panama',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'PA',
        },
        {
          id: '5e5ce41841e1870029ed5e62',
          name: 'Papua New Guinea',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'PG',
        },
        {
          id: '5e5ce41841e1870029ed5e6f',
          name: 'Paraguay',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'PY',
        },
        {
          id: '5e5ce41841e1870029ed5e70',
          name: 'Peru',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'PE',
        },
        {
          id: '5e5ce41841e1870029ed5e0b',
          name: 'Philippines',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'PH',
        },
        {
          id: '6166952f4f17de59f0d41caf',
          name: 'Pitcairn',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'PN',
        },
        {
          id: '5e5ce41841e1870029ed5e39',
          name: 'Poland',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'PL',
        },
        {
          id: '5e5ce41841e1870029ed5e3a',
          name: 'Portugal',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'PT',
        },
        {
          id: '6166952f4f17de14b7d41be4',
          name: 'Puerto Rico',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'PR',
        },
        {
          id: '5e5ce41841e1870029ed5e49',
          name: 'Qatar',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'QA',
        },
        {
          id: '6166952f4f17de7842d41be5',
          name: 'Reunion',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'RE',
        },
        {
          id: '5e5ce41841e1870029ed5e3b',
          name: 'Romania',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'RO',
        },
        {
          id: '5e5ce41841e1870029ed5e83',
          name: 'Russia',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'RU',
        },
        {
          id: '5e5ce41841e1870029ed5de9',
          name: 'Rwanda',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'RW',
        },
        {
          id: '6166952f4f17de55cbd41bc7',
          name: 'Saint Barthelemy',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'BL',
        },
        {
          id: '6166952f4f17de1cddd41bee',
          name: 'Saint Helena',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'SH',
        },
        {
          id: '5e5ce41841e1870029ed5e21',
          name: 'Saint Kitts And Nevis',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'KN',
        },
        {
          id: '5e5ce41841e1870029ed5e22',
          name: 'Saint Lucia',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'LC',
        },
        {
          id: '6166952f4f17de5ca5d41bda',
          name: 'Saint Martin',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'MF',
        },
        {
          id: '6166952f4f17de1a3dd41be3',
          name: 'Saint Pierre and Miquelon',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'PM',
        },
        {
          id: '5e5ce41841e1870029ed5e23',
          name: 'Saint Vincent and The Grenadines',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'VC',
        },
        {
          id: '5e5ce41841e1870029ed5e63',
          name: 'Samoa',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'WS',
        },
        {
          id: '5e5ce41841e1870029ed5e84',
          name: 'San Marino',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'SM',
        },
        {
          id: '5e5ce41841e1870029ed5dea',
          name: 'Sao Tome and Principe',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'ST',
        },
        {
          id: '5e5ce41841e1870029ed5e4a',
          name: 'Saudi Arabia',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'SA',
        },
        {
          id: '5e5ce41841e1870029ed5deb',
          name: 'Senegal',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'SN',
        },
        {
          id: '5e5ce41841e1870029ed5e85',
          name: 'Serbia',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'RS',
        },
        {
          id: '5e5ce41841e1870029ed5dec',
          name: 'Seychelles',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'SC',
        },
        {
          id: '5e5ce41841e1870029ed5ded',
          name: 'Sierra Leone',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'SL',
        },
        {
          id: '5e5ce41841e1870029ed5e0c',
          name: 'Singapore',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'SG',
        },
        {
          id: '6166952f4f17deee98d41be7',
          name: 'Sint Maarten',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'SX',
        },
        {
          id: '5e5ce41841e1870029ed5e3c',
          name: 'Slovakia',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'SK',
        },
        {
          id: '5e5ce41841e1870029ed5e3d',
          name: 'Slovenia',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'SI',
        },
        {
          id: '5e5ce41841e1870029ed5e64',
          name: 'Solomon Islands',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'SB',
        },
        {
          id: '5e5ce41841e1870029ed5dee',
          name: 'Somalia',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'SO',
        },
        {
          id: '5e5ce41841e1870029ed5def',
          name: 'South Africa',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'ZA',
        },
        {
          id: '5e5ce41841e1870029ed5df0',
          name: 'South Sudan',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'SS',
        },
        {
          id: '5e5ce41841e1870029ed5e3e',
          name: 'Spain',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'ES',
        },
        {
          id: '5e5ce41841e1870029ed5e0d',
          name: 'Sri Lanka',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'LK',
        },
        {
          id: '5e5ce41841e1870029ed5df1',
          name: 'Sudan',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'SD',
        },
        {
          id: '5e5ce41841e1870029ed5e71',
          name: 'Suriname',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'SR',
        },
        {
          id: '6166952f4f17de0cccd41be6',
          name: 'Svalbard and Jan Mayen',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'SJ',
        },
        {
          id: '5e5ce41841e1870029ed5df2',
          name: 'Swaziland',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'SZ',
        },
        {
          id: '5e5ce41841e1870029ed5e3f',
          name: 'Sweden',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'SE',
        },
        {
          id: '5e5ce41841e1870029ed5e86',
          name: 'Switzerland',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'CH',
        },
        {
          id: '5e5ce41841e1870029ed5e4b',
          name: 'Syria',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'SY',
        },
        {
          id: '5e5ce41841e1870029ed5e57',
          name: 'Taiwan',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'TW',
        },
        {
          id: '5e5ce41841e1870029ed5e4c',
          name: 'Tajikistan',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'TJ',
        },
        {
          id: '5e5ce41841e1870029ed5df3',
          name: 'Tanzania',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'TZ',
        },
        {
          id: '5e5ce41841e1870029ed5e0e',
          name: 'Thailand',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'TH',
        },
        {
          id: '5e5ce41841e1870029ed5e0f',
          name: 'Timor-east',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'TL',
        },
        {
          id: '5e5ce41841e1870029ed5df4',
          name: 'Togo',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'TG',
        },
        {
          id: '6166952f4f17de54cad41bef',
          name: 'Tokelau',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'TK',
        },
        {
          id: '5e5ce41841e1870029ed5e65',
          name: 'Tonga',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'TO',
        },
        {
          id: '5e5ce41841e1870029ed5e24',
          name: 'Trinidad and Tobago',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'TT',
        },
        {
          id: '5e5ce41841e1870029ed5df5',
          name: 'Tunisia',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'TN',
        },
        {
          id: '5e5ce41841e1870029ed5e4d',
          name: 'Turkey',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'TR',
        },
        {
          id: '5e5ce41841e1870029ed5e4e',
          name: 'Turkmenistan',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'TM',
        },
        {
          id: '6166952f4f17de3abdd41be8',
          name: 'Turks',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'TC',
        },
        {
          id: '5e5ce41841e1870029ed5e66',
          name: 'Tuvalu',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'TV',
        },
        {
          id: '6166952f4f17deec68d41bea',
          name: 'U.S. Virgin Islands',
          parentRegionId: '5e5ce41641e1870029ed5dac',
          alpha2Code: 'VI',
        },
        {
          id: '5e5ce41841e1870029ed5df6',
          name: 'Uganda',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'UG',
        },
        {
          id: '5e5ce41841e1870029ed5e87',
          name: 'Ukraine',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'UA',
        },
        {
          id: '5e5ce41841e1870029ed5e4f',
          name: 'United Arab Emirates',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'AE',
        },
        {
          id: '5e5ce41841e1870029ed5e88',
          name: 'United Kingdom',
          parentRegionId: '5e5ce41641e1870029ed5db0',
          alpha2Code: 'GB',
        },
        {
          id: '5e5ce41841e1870029ed5e54',
          name: 'United States',
          parentRegionId: '5e5ce41641e1870029ed5dab',
          alpha2Code: 'US',
        },
        {
          id: '5e5ce41841e1870029ed5e72',
          name: 'Uruguay',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'UY',
        },
        {
          id: '5e5ce41841e1870029ed5e50',
          name: 'Uzbekistan',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'UZ',
        },
        {
          id: '5e5ce41841e1870029ed5e67',
          name: 'Vanuatu',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'VU',
        },
        {
          id: '5e5ce41841e1870029ed5e59',
          name: 'Vatican City',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'VA',
        },
        {
          id: '5e5ce41841e1870029ed5e73',
          name: 'Venezuela',
          parentRegionId: '5e5ce41641e1870029ed5dad',
          alpha2Code: 'VE',
        },
        {
          id: '5e5ce41841e1870029ed5e10',
          name: 'Viet Nam',
          parentRegionId: '5e5ce41641e1870029ed5daf',
          alpha2Code: 'VN',
        },
        {
          id: '6166952f4f17de4c9ad41bf0',
          name: 'Wallis and Futuna',
          parentRegionId: '5e5ce41641e1870029ed5dae',
          alpha2Code: 'WF',
        },
        {
          id: '5e5ce41841e1870029ed5e56',
          name: 'Western Sahara',
          parentRegionId: '5e5ce41641e1870029ed5db4',
          alpha2Code: 'EH',
        },
        {
          id: '5e5ce41841e1870029ed5e51',
          name: 'Yemen',
          parentRegionId: '5e5ce41641e1870029ed5db2',
          alpha2Code: 'YE',
        },
        {
          id: '5e5ce41841e1870029ed5df7',
          name: 'Zambia',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'ZM',
        },
        {
          id: '5e5ce41841e1870029ed5df8',
          name: 'Zimbabwe',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'ZW',
        },
        {
          id: '5e5ce41841e1870029ed5dd0',
          name: 'ivory coast',
          parentRegionId: '5e5ce41641e1870029ed5db3',
          alpha2Code: 'CI',
        },
        {
          id: '6166952f4f17de403ed41bc6',
          name: 'Åland Islands',
          parentRegionId: '5e5ce41641e1870029ed5db1',
          alpha2Code: 'AX',
        },
      ],
      regions: [
        {
          id: '5e5ce41641e1870029ed5da7',
          name: 'NCSA',
          parentRegionId: null,
        },
        {
          id: '5e5ce41641e1870029ed5da8',
          name: 'APAC',
          parentRegionId: null,
        },
        {
          id: '5e5ce41641e1870029ed5da9',
          name: 'EMEA',
          parentRegionId: null,
        },
        {
          id: '5e5ce41641e1870029ed5dab',
          name: 'North America',
          parentRegionId: '5e5ce41641e1870029ed5da7',
        },
        {
          id: '5e5ce41641e1870029ed5dac',
          name: 'Central America',
          parentRegionId: '5e5ce41641e1870029ed5da7',
        },
        {
          id: '5e5ce41641e1870029ed5dae',
          name: 'Pacific',
          parentRegionId: '5e5ce41641e1870029ed5da8',
        },
        {
          id: '5e5ce41641e1870029ed5dad',
          name: 'South America',
          parentRegionId: '5e5ce41641e1870029ed5da7',
        },
        {
          id: '5e5ce41641e1870029ed5db1',
          name: 'European Union',
          parentRegionId: '5e5ce41641e1870029ed5da9',
        },
        {
          id: '5e5ce41641e1870029ed5daf',
          name: 'Asia',
          parentRegionId: '5e5ce41641e1870029ed5da8',
        },
        {
          id: '5e5ce41641e1870029ed5db0',
          name: 'Rest of Europe',
          parentRegionId: '5e5ce41641e1870029ed5da9',
        },
        {
          id: '5e5ce41641e1870029ed5db2',
          name: 'Middle East',
          parentRegionId: '5e5ce41641e1870029ed5da9',
        },
        {
          id: '5e5ce41641e1870029ed5db3',
          name: 'Africa',
          parentRegionId: '5e5ce41641e1870029ed5da9',
        },
        {
          id: '5e5ce41641e1870029ed5db4',
          name: 'Other',
          parentRegionId: '5e5ce41641e1870029ed5da9',
        },
      ],
    },
  },
  prod: {
    data: {
      countries: [
        {
          id: '5e5d07d2aee45f0028faa37a',
          name: 'Afghanistan',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'AF',
        },
        {
          id: '5e5d07d2aee45f0028faa3ae',
          name: 'Albania',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'AL',
        },
        {
          id: '5e5d07d2aee45f0028faa2fd',
          name: 'Algeria',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'DZ',
        },
        {
          id: '616fd6777571142cf9d8b9fc',
          name: 'American Samoa',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'AS',
        },
        {
          id: '5e5d07d2aee45f0028faa3af',
          name: 'Andorra',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'AD',
        },
        {
          id: '5e5d07d2aee45f0028faa2fe',
          name: 'Angola',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'AO',
        },
        {
          id: '616fd67775711466e6d8b9fb',
          name: 'Anguilla',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'AI',
        },
        {
          id: '5e5d07d2aee45f0028faa38f',
          name: 'Antarctica',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'AQ',
        },
        {
          id: '5e5d07d2aee45f0028faa34b',
          name: 'Antigua and Barbuda',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'AG',
        },
        {
          id: '5e5d07d2aee45f0028faa3a2',
          name: 'Argentina',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'AR',
        },
        {
          id: '5e5d07d2aee45f0028faa3b0',
          name: 'Armenia',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'AM',
        },
        {
          id: '616fd677757114125cd8b9fd',
          name: 'Aruba',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'AW',
        },
        {
          id: '616fd677757114477bd8b9fa',
          name: 'Ascension Island',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'AC',
        },
        {
          id: '5e5d07d2aee45f0028faa394',
          name: 'Australia',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'AU',
        },
        {
          id: '5e5d07d2aee45f0028faa35f',
          name: 'Austria',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'AT',
        },
        {
          id: '5e5d07d2aee45f0028faa3b1',
          name: 'Azerbaijan',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'AZ',
        },
        {
          id: '5e5d07d2aee45f0028faa38c',
          name: 'Bahamas',
          parentRegionId: '5e5d07d0aee45f0028faa2e5',
          alpha2Code: 'BS',
        },
        {
          id: '5e5d07d2aee45f0028faa37b',
          name: 'Bahrain',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'BH',
        },
        {
          id: '5e5d07d2aee45f0028faa333',
          name: 'Bangladesh',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'BD',
        },
        {
          id: '5e5d07d2aee45f0028faa34c',
          name: 'Barbados',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'BB',
        },
        {
          id: '5e5d07d2aee45f0028faa3b2',
          name: 'Belarus',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'BY',
        },
        {
          id: '5e5d07d2aee45f0028faa360',
          name: 'Belgium',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'BE',
        },
        {
          id: '5e5d07d2aee45f0028faa34d',
          name: 'Belize',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'BZ',
        },
        {
          id: '5e5d07d2aee45f0028faa2ff',
          name: 'Benin',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'BJ',
        },
        {
          id: '616fd6777571140126d8ba00',
          name: 'Bermuda',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'BM',
        },
        {
          id: '5e5d07d2aee45f0028faa334',
          name: 'Bhutan',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'BT',
        },
        {
          id: '5e5d07d2aee45f0028faa3a3',
          name: 'Bolivia (Plurinational State of)',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'BO',
        },
        {
          id: '616fd677757114a710d8ba01',
          name: 'Bonaire Sint Eustatius and Saba',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'BQ',
        },
        {
          id: '5e5d07d2aee45f0028faa3b3',
          name: 'Bosnia and Herzegovina',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'BA',
        },
        {
          id: '5e5d07d2aee45f0028faa300',
          name: 'Botswana',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'BW',
        },
        {
          id: '5e5d07d2aee45f0028faa3a4',
          name: 'Brazil',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'BR',
        },
        {
          id: '616fd6777571146c8cd8ba25',
          name: 'British Indian Ocean Territory',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'IO',
        },
        {
          id: '616fd67775711443b6d8ba21',
          name: 'British Virgin Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'VG',
        },
        {
          id: '5e5d07d2aee45f0028faa335',
          name: 'Brunei Darussalam',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'BN',
        },
        {
          id: '5e5d07d2aee45f0028faa361',
          name: 'Bulgaria',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'BG',
        },
        {
          id: '5e5d07d2aee45f0028faa301',
          name: 'Burkina Faso',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'BF',
        },
        {
          id: '5e5d07d2aee45f0028faa302',
          name: 'Burundi',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'BI',
        },
        {
          id: '5e5d07d2aee45f0028faa303',
          name: 'Cabo Verde',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'CV',
        },
        {
          id: '5e5d07d2aee45f0028faa336',
          name: 'Cambodia',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'KH',
        },
        {
          id: '5e5d07d2aee45f0028faa304',
          name: 'Cameroon',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'CM',
        },
        {
          id: '5e5d07d2aee45f0028faa38d',
          name: 'Canada',
          parentRegionId: '5e5d07d0aee45f0028faa2e5',
          alpha2Code: 'CA',
        },
        {
          id: '616fd67775711422bed8ba11',
          name: 'Cayman Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'KY',
        },
        {
          id: '5e5d07d2aee45f0028faa305',
          name: 'Central African Republic',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'CF',
        },
        {
          id: '5e5d07d2aee45f0028faa306',
          name: 'Chad',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'TD',
        },
        {
          id: '5e5d07d2aee45f0028faa3a5',
          name: 'Chile',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'CL',
        },
        {
          id: '5e5d07d2aee45f0028faa337',
          name: 'China',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'CN',
        },
        {
          id: '616fd677757114c3a0d8ba05',
          name: 'Christmas Island',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'CX',
        },
        {
          id: '616fd677757114f405d8ba02',
          name: 'Cocos Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'CC',
        },
        {
          id: '5e5d07d2aee45f0028faa3a6',
          name: 'Colombia',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'CO',
        },
        {
          id: '5e5d07d2aee45f0028faa307',
          name: 'Comoros',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'KM',
        },
        {
          id: '5e5d07d2aee45f0028faa308',
          name: 'Congo',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'CG',
        },
        {
          id: '5e5d07d2aee45f0028faa309',
          name: 'Congo (Democratic Republic of the)',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'CD',
        },
        {
          id: '616fd6777571144d50d8ba03',
          name: 'Cook Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'CK',
        },
        {
          id: '5e5d07d2aee45f0028faa34e',
          name: 'Costa Rica',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'CR',
        },
        {
          id: '5e5d07d2aee45f0028faa362',
          name: 'Croatia',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'HR',
        },
        {
          id: '5e5d07d2aee45f0028faa34f',
          name: 'Cuba',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'CU',
        },
        {
          id: '616fd677757114eddcd8ba04',
          name: 'Curacao',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'CW',
        },
        {
          id: '5e5d07d2aee45f0028faa363',
          name: 'Cyprus',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'CY',
        },
        {
          id: '5e5d07d2aee45f0028faa364',
          name: 'Czech Republic',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'CZ',
        },
        {
          id: '5e5d07d2aee45f0028faa365',
          name: 'Denmark',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'DK',
        },
        {
          id: '5e5d07d2aee45f0028faa30b',
          name: 'Djibouti',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'DJ',
        },
        {
          id: '5e5d07d2aee45f0028faa350',
          name: 'Dominica',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'DM',
        },
        {
          id: '5e5d07d2aee45f0028faa351',
          name: 'Dominican Republic',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'DO',
        },
        {
          id: '5e5d07d2aee45f0028faa3a7',
          name: 'Ecuador',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'EC',
        },
        {
          id: '5e5d07d2aee45f0028faa30c',
          name: 'Egypt',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'EG',
        },
        {
          id: '5e5d07d2aee45f0028faa352',
          name: 'El Salvador',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'SV',
        },
        {
          id: '5e5d07d2aee45f0028faa30d',
          name: 'Equatorial Guinea',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'GQ',
        },
        {
          id: '5e5d07d2aee45f0028faa30e',
          name: 'Eritrea',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'ER',
        },
        {
          id: '5e5d07d2aee45f0028faa366',
          name: 'Estonia',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'EE',
        },
        {
          id: '5e5d07d2aee45f0028faa30f',
          name: 'Ethiopia',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'ET',
        },
        {
          id: '616fd677757114dc14d8ba06',
          name: 'Falkland Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'FK',
        },
        {
          id: '616fd677757114426ad8ba07',
          name: 'Faroe Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'FO',
        },
        {
          id: '5e5d07d2aee45f0028faa395',
          name: 'Fiji',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'FJ',
        },
        {
          id: '5e5d07d2aee45f0028faa367',
          name: 'Finland',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'FI',
        },
        {
          id: '5e5d07d2aee45f0028faa368',
          name: 'France',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'FR',
        },
        {
          id: '616fd677757114373dd8ba08',
          name: 'French Guiana',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'GF',
        },
        {
          id: '616fd6777571145cb7d8ba1a',
          name: 'French Polynesia',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'PF',
        },
        {
          id: '5e5d07d2aee45f0028faa310',
          name: 'Gabon',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'GA',
        },
        {
          id: '5e5d07d2aee45f0028faa311',
          name: 'Gambia',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'GM',
        },
        {
          id: '5e5d07d2aee45f0028faa3b4',
          name: 'Georgia',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'GE',
        },
        {
          id: '5e5d07d2aee45f0028faa369',
          name: 'Germany',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'DE',
        },
        {
          id: '5e5d07d2aee45f0028faa312',
          name: 'Ghana',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'GH',
        },
        {
          id: '616fd6777571145642d8ba0a',
          name: 'Gibraltar',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'GI',
        },
        {
          id: '5e5d07d2aee45f0028faa36a',
          name: 'Greece',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'GR',
        },
        {
          id: '616fd6777571144c85d8ba0b',
          name: 'Greenland',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'GL',
        },
        {
          id: '5e5d07d2aee45f0028faa353',
          name: 'Grenada',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'GD',
        },
        {
          id: '616fd6777571147a4ed8ba0c',
          name: 'Guadeloupe',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'GP',
        },
        {
          id: '616fd6777571144b18d8ba0d',
          name: 'Guam',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'GU',
        },
        {
          id: '5e5d07d2aee45f0028faa354',
          name: 'Guatemala',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'GT',
        },
        {
          id: '616fd677757114a6aad8ba09',
          name: 'Guernsey',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'GG',
        },
        {
          id: '5e5d07d2aee45f0028faa313',
          name: 'Guinea',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'GN',
        },
        {
          id: '5e5d07d2aee45f0028faa314',
          name: 'Guinea-Bissau',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'GW',
        },
        {
          id: '5e5d07d2aee45f0028faa3a8',
          name: 'Guyana',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'GY',
        },
        {
          id: '5e5d07d2aee45f0028faa355',
          name: 'Haiti',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'HT',
        },
        {
          id: '5e5d07d2aee45f0028faa356',
          name: 'Honduras',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'HN',
        },
        {
          id: '616fd677757114f217d8ba0e',
          name: 'Hong Kong',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'HK',
        },
        {
          id: '5e5d07d2aee45f0028faa36b',
          name: 'Hungary',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'HU',
        },
        {
          id: '5e5d07d2aee45f0028faa3b5',
          name: 'Iceland',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'IS',
        },
        {
          id: '5e5d07d2aee45f0028faa338',
          name: 'India',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'IN',
        },
        {
          id: '5e5d07d2aee45f0028faa339',
          name: 'Indonesia',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'ID',
        },
        {
          id: '5e5d07d2aee45f0028faa37c',
          name: 'Iran',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'IR',
        },
        {
          id: '5e5d07d2aee45f0028faa37d',
          name: 'Iraq',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'IQ',
        },
        {
          id: '5e5d07d2aee45f0028faa36c',
          name: 'Ireland',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'IE',
        },
        {
          id: '616fd677757114c0fed8ba0f',
          name: 'Isle of Man',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'IM',
        },
        {
          id: '5e5d07d2aee45f0028faa37e',
          name: 'Israel',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'IL',
        },
        {
          id: '5e5d07d2aee45f0028faa36d',
          name: 'Italy',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'IT',
        },
        {
          id: '5e5d07d2aee45f0028faa357',
          name: 'Jamaica',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'JM',
        },
        {
          id: '5e5d07d2aee45f0028faa33a',
          name: 'Japan',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'JP',
        },
        {
          id: '616fd67775711408a7d8ba10',
          name: 'Jersey',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'JE',
        },
        {
          id: '5e5d07d2aee45f0028faa37f',
          name: 'Jordan',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'JO',
        },
        {
          id: '5e5d07d2aee45f0028faa3b6',
          name: 'Kazakhstan',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'KZ',
        },
        {
          id: '5e5d07d2aee45f0028faa315',
          name: 'Kenya',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'KE',
        },
        {
          id: '5e5d07d2aee45f0028faa396',
          name: 'Kiribati',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'KI',
        },
        {
          id: '5e5d07d2aee45f0028faa33b',
          name: 'Korea (Democratic People’s Republic of) north',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'KP',
        },
        {
          id: '5e5d07d2aee45f0028faa33c',
          name: 'Korea (Republic of) south',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'KR',
        },
        {
          id: '616fd6777571146e28d8ba23',
          name: 'Kosovo',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'XK',
        },
        {
          id: '5e5d07d2aee45f0028faa380',
          name: 'Kuwait',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'KW',
        },
        {
          id: '5e5d07d2aee45f0028faa33d',
          name: 'Kyrgyzstan',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'KG',
        },
        {
          id: '5e5d07d2aee45f0028faa33e',
          name: 'Laos',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'LA',
        },
        {
          id: '5e5d07d2aee45f0028faa36e',
          name: 'Latvia',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'LV',
        },
        {
          id: '5e5d07d2aee45f0028faa381',
          name: 'Lebanon',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'LB',
        },
        {
          id: '5e5d07d2aee45f0028faa316',
          name: 'Lesotho',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'LS',
        },
        {
          id: '5e5d07d2aee45f0028faa317',
          name: 'Liberia',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'LR',
        },
        {
          id: '5e5d07d2aee45f0028faa318',
          name: 'Libya',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'LY',
        },
        {
          id: '5e5d07d2aee45f0028faa3b7',
          name: 'Liechtenstein',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'LI',
        },
        {
          id: '5e5d07d2aee45f0028faa36f',
          name: 'Lithuania',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'LT',
        },
        {
          id: '5e5d07d2aee45f0028faa370',
          name: 'Luxembourg',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'LU',
        },
        {
          id: '616fd6777571141a3bd8ba13',
          name: 'Macau',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'MO',
        },
        {
          id: '5e5d07d2aee45f0028faa3b8',
          name: 'Macedonia',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'MK',
        },
        {
          id: '5e5d07d2aee45f0028faa319',
          name: 'Madagascar',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'MG',
        },
        {
          id: '5e5d07d2aee45f0028faa31a',
          name: 'Malawi',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'MW',
        },
        {
          id: '5e5d07d2aee45f0028faa33f',
          name: 'Malaysia',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'MY',
        },
        {
          id: '5e5d07d2aee45f0028faa340',
          name: 'Maldives',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'MV',
        },
        {
          id: '5e5d07d2aee45f0028faa31b',
          name: 'Mali',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'ML',
        },
        {
          id: '5e5d07d2aee45f0028faa371',
          name: 'Malta',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'MT',
        },
        {
          id: '5e5d07d2aee45f0028faa397',
          name: 'Marshall Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'MH',
        },
        {
          id: '616fd677757114b22bd8ba15',
          name: 'Martinique',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'MQ',
        },
        {
          id: '5e5d07d2aee45f0028faa31c',
          name: 'Mauritania',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'MR',
        },
        {
          id: '5e5d07d2aee45f0028faa31d',
          name: 'Mauritius',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'MU',
        },
        {
          id: '616fd67775711453b7d8ba24',
          name: 'Mayotte',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'YT',
        },
        {
          id: '5e5d07d2aee45f0028faa358',
          name: 'Mexico',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'MX',
        },
        {
          id: '5e5d07d2aee45f0028faa398',
          name: 'Micronesia',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'FM',
        },
        {
          id: '5e5d07d2aee45f0028faa3b9',
          name: 'Moldova',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'MD',
        },
        {
          id: '5e5d07d2aee45f0028faa3ba',
          name: 'Monaco',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'MC',
        },
        {
          id: '5e5d07d2aee45f0028faa341',
          name: 'Mongolia',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'MN',
        },
        {
          id: '5e5d07d2aee45f0028faa3bb',
          name: 'Montenegro',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'ME',
        },
        {
          id: '616fd6777571141bfad8ba16',
          name: 'Montserrat',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'MS',
        },
        {
          id: '5e5d07d2aee45f0028faa31e',
          name: 'Morocco',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'MA',
        },
        {
          id: '5e5d07d2aee45f0028faa31f',
          name: 'Mozambique',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'MZ',
        },
        {
          id: '5e5d07d2aee45f0028faa342',
          name: 'Myanmar',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'MM',
        },
        {
          id: '5e5d07d2aee45f0028faa320',
          name: 'Namibia',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'NA',
        },
        {
          id: '5e5d07d2aee45f0028faa399',
          name: 'Nauru',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'NR',
        },
        {
          id: '5e5d07d2aee45f0028faa343',
          name: 'Nepal',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'NP',
        },
        {
          id: '5e5d07d2aee45f0028faa372',
          name: 'Netherlands',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'NL',
        },
        {
          id: '616fd67775711482d3d8bae6',
          name: 'Netherlands Antilles',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'AN',
        },
        {
          id: '616fd6777571145d9bd8ba17',
          name: 'New Caledonia',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'NC',
        },
        {
          id: '5e5d07d2aee45f0028faa39a',
          name: 'New Zealand',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'NZ',
        },
        {
          id: '5e5d07d2aee45f0028faa359',
          name: 'Nicaragua',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'NI',
        },
        {
          id: '5e5d07d2aee45f0028faa321',
          name: 'Niger',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'NE',
        },
        {
          id: '5e5d07d2aee45f0028faa322',
          name: 'Nigeria',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'NG',
        },
        {
          id: '616fd677757114223dd8ba19',
          name: 'Niue',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'NU',
        },
        {
          id: '616fd67775711474d7d8ba18',
          name: 'Norfolk Island',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'NF',
        },
        {
          id: '616fd677757114deead8ba14',
          name: 'Northern Mariana Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'MP',
        },
        {
          id: '5e5d07d2aee45f0028faa3bc',
          name: 'Norway',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'NO',
        },
        {
          id: '5e5d07d2aee45f0028faa382',
          name: 'Oman',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'OM',
        },
        {
          id: '5e5d07d2aee45f0028faa344',
          name: 'Pakistan',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'PK',
        },
        {
          id: '5e5d07d2aee45f0028faa39b',
          name: 'Palau',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'PW',
        },
        {
          id: '5e5d07d2aee45f0028faa392',
          name: 'Palestine',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'PS',
        },
        {
          id: '5e5d07d2aee45f0028faa35a',
          name: 'Panama',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'PA',
        },
        {
          id: '5e5d07d2aee45f0028faa39c',
          name: 'Papua New Guinea',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'PG',
        },
        {
          id: '5e5d07d2aee45f0028faa3a9',
          name: 'Paraguay',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'PY',
        },
        {
          id: '5e5d07d2aee45f0028faa3aa',
          name: 'Peru',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'PE',
        },
        {
          id: '5e5d07d2aee45f0028faa345',
          name: 'Philippines',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'PH',
        },
        {
          id: '616fd6777571149fecd8bae7',
          name: 'Pitcairn',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'PN',
        },
        {
          id: '5e5d07d2aee45f0028faa373',
          name: 'Poland',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'PL',
        },
        {
          id: '5e5d07d2aee45f0028faa374',
          name: 'Portugal',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'PT',
        },
        {
          id: '616fd67775711412bfd8ba1c',
          name: 'Puerto Rico',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'PR',
        },
        {
          id: '5e5d07d2aee45f0028faa383',
          name: 'Qatar',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'QA',
        },
        {
          id: '616fd6777571140e02d8ba1d',
          name: 'Reunion',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'RE',
        },
        {
          id: '5e5d07d2aee45f0028faa375',
          name: 'Romania',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'RO',
        },
        {
          id: '5e5d07d2aee45f0028faa3bd',
          name: 'Russia',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'RU',
        },
        {
          id: '5e5d07d2aee45f0028faa323',
          name: 'Rwanda',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'RW',
        },
        {
          id: '616fd677757114da47d8b9ff',
          name: 'Saint Barthelemy',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'BL',
        },
        {
          id: '616fd677757114c1bad8ba26',
          name: 'Saint Helena',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'SH',
        },
        {
          id: '5e5d07d2aee45f0028faa35b',
          name: 'Saint Kitts And Nevis',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'KN',
        },
        {
          id: '5e5d07d2aee45f0028faa35c',
          name: 'Saint Lucia',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'LC',
        },
        {
          id: '616fd677757114ebdfd8ba12',
          name: 'Saint Martin',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'MF',
        },
        {
          id: '616fd6777571146de3d8ba1b',
          name: 'Saint Pierre and Miquelon',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'PM',
        },
        {
          id: '5e5d07d2aee45f0028faa35d',
          name: 'Saint Vincent and The Grenadines',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'VC',
        },
        {
          id: '5e5d07d2aee45f0028faa39d',
          name: 'Samoa',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'WS',
        },
        {
          id: '5e5d07d2aee45f0028faa3be',
          name: 'San Marino',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'SM',
        },
        {
          id: '5e5d07d2aee45f0028faa324',
          name: 'Sao Tome and Principe',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'ST',
        },
        {
          id: '5e5d07d2aee45f0028faa384',
          name: 'Saudi Arabia',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'SA',
        },
        {
          id: '5e5d07d2aee45f0028faa325',
          name: 'Senegal',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'SN',
        },
        {
          id: '5e5d07d2aee45f0028faa3bf',
          name: 'Serbia',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'RS',
        },
        {
          id: '5e5d07d2aee45f0028faa326',
          name: 'Seychelles',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'SC',
        },
        {
          id: '5e5d07d2aee45f0028faa327',
          name: 'Sierra Leone',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'SL',
        },
        {
          id: '5e5d07d2aee45f0028faa346',
          name: 'Singapore',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'SG',
        },
        {
          id: '616fd6777571144226d8ba1f',
          name: 'Sint Maarten',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'SX',
        },
        {
          id: '5e5d07d2aee45f0028faa376',
          name: 'Slovakia',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'SK',
        },
        {
          id: '5e5d07d2aee45f0028faa377',
          name: 'Slovenia',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'SI',
        },
        {
          id: '5e5d07d2aee45f0028faa39e',
          name: 'Solomon Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'SB',
        },
        {
          id: '5e5d07d2aee45f0028faa328',
          name: 'Somalia',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'SO',
        },
        {
          id: '5e5d07d2aee45f0028faa329',
          name: 'South Africa',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'ZA',
        },
        {
          id: '5e5d07d2aee45f0028faa32a',
          name: 'South Sudan',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'SS',
        },
        {
          id: '5e5d07d2aee45f0028faa378',
          name: 'Spain',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'ES',
        },
        {
          id: '5e5d07d2aee45f0028faa347',
          name: 'Sri Lanka',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'LK',
        },
        {
          id: '5e5d07d2aee45f0028faa32b',
          name: 'Sudan',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'SD',
        },
        {
          id: '5e5d07d2aee45f0028faa3ab',
          name: 'Suriname',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'SR',
        },
        {
          id: '616fd6777571140577d8ba1e',
          name: 'Svalbard and Jan Mayen',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'SJ',
        },
        {
          id: '5e5d07d2aee45f0028faa32c',
          name: 'Swaziland',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'SZ',
        },
        {
          id: '5e5d07d2aee45f0028faa379',
          name: 'Sweden',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'SE',
        },
        {
          id: '5e5d07d2aee45f0028faa3c0',
          name: 'Switzerland',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'CH',
        },
        {
          id: '5e5d07d2aee45f0028faa385',
          name: 'Syria',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'SY',
        },
        {
          id: '5e5d07d2aee45f0028faa391',
          name: 'Taiwan',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'TW',
        },
        {
          id: '5e5d07d2aee45f0028faa386',
          name: 'Tajikistan',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'TJ',
        },
        {
          id: '5e5d07d2aee45f0028faa32d',
          name: 'Tanzania',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'TZ',
        },
        {
          id: '5e5d07d2aee45f0028faa348',
          name: 'Thailand',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'TH',
        },
        {
          id: '5e5d07d2aee45f0028faa349',
          name: 'Timor-east',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'TL',
        },
        {
          id: '5e5d07d2aee45f0028faa32e',
          name: 'Togo',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'TG',
        },
        {
          id: '616fd67775711411dad8ba27',
          name: 'Tokelau',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'TK',
        },
        {
          id: '5e5d07d2aee45f0028faa39f',
          name: 'Tonga',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'TO',
        },
        {
          id: '5e5d07d2aee45f0028faa35e',
          name: 'Trinidad and Tobago',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'TT',
        },
        {
          id: '5e5d07d2aee45f0028faa32f',
          name: 'Tunisia',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'TN',
        },
        {
          id: '5e5d07d2aee45f0028faa387',
          name: 'Turkey',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'TR',
        },
        {
          id: '5e5d07d2aee45f0028faa388',
          name: 'Turkmenistan',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'TM',
        },
        {
          id: '616fd67775711400e3d8ba20',
          name: 'Turks',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'TC',
        },
        {
          id: '5e5d07d2aee45f0028faa3a0',
          name: 'Tuvalu',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'TV',
        },
        {
          id: '616fd677757114116dd8ba22',
          name: 'U.S. Virgin Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2e6',
          alpha2Code: 'VI',
        },
        {
          id: '5e5d07d2aee45f0028faa330',
          name: 'Uganda',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'UG',
        },
        {
          id: '5e5d07d2aee45f0028faa3c1',
          name: 'Ukraine',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'UA',
        },
        {
          id: '5e5d07d2aee45f0028faa389',
          name: 'United Arab Emirates',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'AE',
        },
        {
          id: '5e5d07d2aee45f0028faa3c2',
          name: 'United Kingdom',
          parentRegionId: '5e5d07d0aee45f0028faa2ea',
          alpha2Code: 'GB',
        },
        {
          id: '5e5d07d2aee45f0028faa38e',
          name: 'United States',
          parentRegionId: '5e5d07d0aee45f0028faa2e5',
          alpha2Code: 'US',
        },
        {
          id: '5e5d07d2aee45f0028faa3ac',
          name: 'Uruguay',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'UY',
        },
        {
          id: '5e5d07d2aee45f0028faa38a',
          name: 'Uzbekistan',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'UZ',
        },
        {
          id: '5e5d07d2aee45f0028faa3a1',
          name: 'Vanuatu',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'VU',
        },
        {
          id: '5e5d07d2aee45f0028faa393',
          name: 'Vatican City',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'VA',
        },
        {
          id: '5e5d07d2aee45f0028faa3ad',
          name: 'Venezuela',
          parentRegionId: '5e5d07d0aee45f0028faa2e7',
          alpha2Code: 'VE',
        },
        {
          id: '5e5d07d2aee45f0028faa34a',
          name: 'Viet Nam',
          parentRegionId: '5e5d07d0aee45f0028faa2e9',
          alpha2Code: 'VN',
        },
        {
          id: '616fd677757114369bd8ba28',
          name: 'Wallis and Futuna',
          parentRegionId: '5e5d07d0aee45f0028faa2e8',
          alpha2Code: 'WF',
        },
        {
          id: '5e5d07d2aee45f0028faa390',
          name: 'Western Sahara',
          parentRegionId: '5e5d07d0aee45f0028faa2ee',
          alpha2Code: 'EH',
        },
        {
          id: '5e5d07d2aee45f0028faa38b',
          name: 'Yemen',
          parentRegionId: '5e5d07d0aee45f0028faa2ec',
          alpha2Code: 'YE',
        },
        {
          id: '5e5d07d2aee45f0028faa331',
          name: 'Zambia',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'ZM',
        },
        {
          id: '5e5d07d2aee45f0028faa332',
          name: 'Zimbabwe',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'ZW',
        },
        {
          id: '5e5d07d2aee45f0028faa30a',
          name: 'ivory coast',
          parentRegionId: '5e5d07d0aee45f0028faa2ed',
          alpha2Code: 'CI',
        },
        {
          id: '616fd6777571144fe4d8b9fe',
          name: 'Åland Islands',
          parentRegionId: '5e5d07d0aee45f0028faa2eb',
          alpha2Code: 'AX',
        },
      ],
      regions: [
        {
          id: '5e5d07d0aee45f0028faa2e2',
          name: 'APAC',
          parentRegionId: null,
        },
        {
          id: '5e5d07d0aee45f0028faa2e1',
          name: 'NCSA',
          parentRegionId: null,
        },
        {
          id: '5e5d07d0aee45f0028faa2e3',
          name: 'EMEA',
          parentRegionId: null,
        },
        {
          id: '5e5d07d0aee45f0028faa2e7',
          name: 'South America',
          parentRegionId: '5e5d07d0aee45f0028faa2e1',
        },
        {
          id: '5e5d07d0aee45f0028faa2e5',
          name: 'North America',
          parentRegionId: '5e5d07d0aee45f0028faa2e1',
        },
        {
          id: '5e5d07d0aee45f0028faa2e8',
          name: 'Pacific',
          parentRegionId: '5e5d07d0aee45f0028faa2e2',
        },
        {
          id: '5e5d07d0aee45f0028faa2e6',
          name: 'Central America',
          parentRegionId: '5e5d07d0aee45f0028faa2e1',
        },
        {
          id: '5e5d07d0aee45f0028faa2eb',
          name: 'European Union',
          parentRegionId: '5e5d07d0aee45f0028faa2e3',
        },
        {
          id: '5e5d07d0aee45f0028faa2ec',
          name: 'Middle East',
          parentRegionId: '5e5d07d0aee45f0028faa2e3',
        },
        {
          id: '5e5d07d0aee45f0028faa2ea',
          name: 'Rest of Europe',
          parentRegionId: '5e5d07d0aee45f0028faa2e3',
        },
        {
          id: '5e5d07d0aee45f0028faa2ed',
          name: 'Africa',
          parentRegionId: '5e5d07d0aee45f0028faa2e3',
        },
        {
          id: '5e5d07d0aee45f0028faa2e9',
          name: 'Asia',
          parentRegionId: '5e5d07d0aee45f0028faa2e2',
        },
        {
          id: '5e5d07d0aee45f0028faa2ee',
          name: 'Other',
          parentRegionId: '5e5d07d0aee45f0028faa2e3',
        },
      ],
    },
  },
};
