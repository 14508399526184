import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

@Injectable()
export class DisabledRouteGuard implements CanActivate {
  constructor(@Inject(APP_CONFIG) private readonly environment: IEnvironment) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!route.data || !route.data.envs) {
      return true;
    }
    return !route.data.envs.includes(this.environment.name);
  }
}
