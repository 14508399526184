import { ICard } from '../interfaces/user.interface';

export const creditCards: ICard[] = [
  {
    iconSrc: '/assets/svg/mastercard.svg',
    name: 'MasterCard',
    number: '**08',
  },
  {
    iconSrc: '/assets/svg/mastercard.svg',
    name: 'MasterCard',
    number: '**04',
  },
  {
    iconSrc: '/assets/svg/mastercard.svg',
    name: 'MasterCard',
    number: '**05',
  },
  {
    iconSrc: '/assets/svg/mastercard.svg',
    name: 'MasterCard',
    number: '**06',
  },
];
