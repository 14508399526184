<div class="info-message-detail info-message-detail--{{ mode }} {{ extraClassName }}">
  @switch (mode) {
    @case ('error') {
      <i
        placement="top"
        container="body"
        ngbTooltip="Error, please come back later"
        [disableTooltip]="disableTooltip"
        tooltipClass="transparent-orange-tooltip"
        class="icon icon-alert-triangle-{{ size }} bg-orange-light"></i>
    }
    @case ('pending') {
      <i
        placement="top"
        container="body"
        ngbTooltip="Synchronization is in progress"
        tooltipClass="transparent-purple-tooltip"
        [disableTooltip]="disableTooltip"
        class="icon icon-hourglass-{{ size }} bg-ultra-purple-light"></i>
    }
    @case ('restricted') {
      <i
        placement="top"
        container="body"
        ngbTooltip="Restricted content"
        [disableTooltip]="disableTooltip"
        tooltipClass="transparent-red-tooltip"
        class="icon icon-block-{{ size }} bg-red-light"></i>
    }
  }
  <div (window:resize)="onResize()" #content class="info-message-detail__content">
    <ng-content />
  </div>
</div>
