import { Pipe, PipeTransform } from '@angular/core';

interface ImageSizeMap {
  cardPreview: string;
  listPreview: string;
}

const IMAGE_SIZE_MAP: ImageSizeMap = {
  cardPreview: '384',
  listPreview: '96',
};

const VALUE_TO_REPLACE = 'original';

@Pipe({
  name: 'imgSourceReplace',
})
export class ImgSourceReplacePipe implements PipeTransform {
  transform(value: any, size: keyof ImageSizeMap): any {
    if (!value || !IMAGE_SIZE_MAP[size]) {
      return value;
    }
    return value.replace(VALUE_TO_REPLACE, IMAGE_SIZE_MAP[size]);
  }
}
