import { ComponentType, Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

import { DialogContainerComponent } from '../components/dialog-container/dialog-container.component';
import { DialogConfig } from '../dialog-config';

import { DialogRef } from './dialog-ref';

/** Injection token for the Dialog's ScrollStrategy. */
export const DIALOG_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('DialogScrollStrategy');

/** Injection token for the Dialog's Data. */
export const DIALOG_DATA = new InjectionToken<any>('DialogData');

/** Injection token for the DialogRef constructor. */
export const DIALOG_REF = new InjectionToken<DialogRef<any>>('DialogRef');

/** Injection token for the DialogConfig. */
export const DIALOG_CONFIG = new InjectionToken<DialogConfig>('DialogConfig');

/** Injection token for the Dialog's DialogContainer component. */
export const DIALOG_CONTAINER = new InjectionToken<ComponentType<DialogContainerComponent>>('DialogContainer');

/** @docs-private */
export function DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

/** @docs-private */
export const DIALOG_SCROLL_STRATEGY_PROVIDER = {
  provide: DIALOG_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY,
};
