<div class="identity-header d-flex w-100 justify-content-between">
  <span class="text-uppercase overline-medium text-white-alpha-7" ultraAutotestAttribute="identity-title" i18n>
    identity</span
  >
</div>
<div class="identity-content d-flex flex-column w-100">
  <div class="box-grid d-flex flex-wrap w-100 flex-row">
    <div
      class="me-2 mb-2 box d-flex overflow-hidden flex-column align-items-stretch overflow-hidden rounded"
      (mouseleave)="copyUniqID.tooltip.close()">
      <div class="tools d-flex overline-medium align-items-center overflow-hidden justify-content-end">
        <ultra-copy-to-clipboard
          #copyUniqID
          [text]="idCardEntity.id"
          [showLabel]="false"
          [showTooltip]="true"
          [showInitialTooltip]="false"
          class="ms-1 me-1"
          variant="simple"
          tooltipPlacement="top right left"
          i18n-copyTooltipText
          copiedTooltipText="Uniq ID copied!"
          i18n-copiedTooltipText />

        <i
          container="body"
          [ngbTooltip]="uniqIDTooltipContent"
          tooltipClass="z-infinite"
          class="d-inline-block bg-white-alpha-7 icon-info"></i>
      </div>
      <div class="box-content d-flex align-items-center overflow-hidden w-100 h-100 justify-content-center flex-column">
        <span class="title overline-medium" ultraAutotestAttribute="uniq-id-title">uniq id</span>
        <span class="id text-align-center" ultraAutotestAttribute="uniq-id-value">{{ idCardEntity.id }}</span>
        <div class="date d-flex flex-column align-items-center">
          <span ultraAutotestAttribute="uniq-id-mint-title">Mint date</span>
          <span ultraAutotestAttribute="uniq-id-mint-value">{{ idCardEntity.mintDate | date: 'd/M/yy' }}</span>
        </div>
      </div>
    </div>
    <div
      class="ms-2 mb-2 box d-flex overflow-hidden flex-column align-items-stretch overflow-hidden rounded"
      (mouseleave)="copyFactoryID.tooltip.close()">
      <div class="tools d-flex overline-medium align-items-center overflow-hidden justify-content-end">
        <ultra-copy-to-clipboard
          #copyFactoryID
          [text]="idCardEntity?.factoryBlockchainId"
          [showLabel]="false"
          [showTooltip]="true"
          [showInitialTooltip]="false"
          class="ms-1 me-1"
          variant="simple"
          tooltipPlacement="top right left"
          i18n-copyTooltipText
          copiedTooltipText="Factory ID copied!"
          i18n-copiedTooltipText />
        <i
          container="body"
          [ngbTooltip]="factoryIDTooltipContent"
          tooltipClass="z-infinite"
          class="d-inline-block bg-white-alpha-7 icon-info"></i>
      </div>
      <div class="box-content d-flex align-items-center overflow-hidden w-100 h-100 justify-content-center flex-column">
        <span class="title overline-medium" ultraAutotestAttribute="factory-id-title">factory id</span>
        <!-- TODO: Replace this with a button -->
        <a
          class="id text-align-center"
          (click)="navigateToUniqFactoryPage()"
          target="_blank"
          tabindex="0"
          ultraAutotestAttribute="factory-id-value"
          >{{ idCardEntity?.factoryBlockchainId }}</a
        >
        <div class="date d-flex flex-column align-items-center">
          <span ultraAutotestAttribute="factory-id-creation-title">Creation date</span>
          <span ultraAutotestAttribute="factory-id-creation-value">{{ idCardEntity.createdAt | date: 'd/M/yy' }}</span>
        </div>
      </div>
    </div>
    <div
      class="mt-2 me-2 box d-flex overflow-hidden flex-column align-items-stretch overflow-hidden rounded"
      (mouseleave)="copySerial.tooltip.close()">
      <div class="tools d-flex overline-medium align-items-center overflow-hidden justify-content-end">
        <ultra-copy-to-clipboard
          #copySerial
          [text]="idCardEntity.serialNumber"
          [showLabel]="false"
          [showTooltip]="true"
          [showInitialTooltip]="false"
          class="ms-1 me-1"
          variant="simple"
          tooltipPlacement="top right left"
          i18n-copyTooltipText
          copiedTooltipText="Serial number copied!"
          i18n-copiedTooltipText />
        <i
          container="body"
          [ngbTooltip]="serialNumberTooltipContent"
          tooltipClass="z-infinite"
          class="d-inline-block bg-white-alpha-7 icon-info"></i>
      </div>
      <div class="box-content d-flex align-items-center overflow-hidden w-100 h-100 justify-content-center flex-column">
        <span class="title overline-medium" ultraAutotestAttribute="serial-number-title">serial number</span>
        <ultra-id-card-serial-number [isUniqDetailsAvailable]="isUniqDetailsAvailable" [idCardEntity]="idCardEntity" />
      </div>
    </div>
    <div
      class="ms-2 mt-2 box d-flex overflow-hidden flex-column align-items-stretch overflow-hidden rounded"
      (mouseleave)="copyOwner.tooltip.close()">
      <div class="tools d-flex overline-medium align-items-center overflow-hidden justify-content-end">
        <ultra-copy-to-clipboard
          #copyOwner
          [text]="idCardEntity.creator.blockchainId"
          [showLabel]="false"
          [showTooltip]="true"
          [showInitialTooltip]="false"
          class="ms-1 me-1"
          variant="simple"
          tooltipPlacement="top right left"
          i18n-copyTooltipText
          copiedTooltipText="Creator copied!"
          i18n-copiedTooltipText />
        <i
          container="body"
          [ngbTooltip]="creatorTooltipContent"
          tooltipClass="z-infinite"
          class="d-inline-block bg-white-alpha-7 icon-info"></i>
      </div>
      <div class="box-content d-flex align-items-center overflow-hidden w-100 h-100 justify-content-center flex-column">
        <span class="title overline-medium" ultraAutotestAttribute="creator-id-title">creator id</span>
        <span class="id d-flex flex-row flex-wrap justify-content-center" ultraAutotestAttribute="creator-id-value">
          {{ idCardEntity.creator?.blockchainId }}
          @if (idCardEntity?.creator?.verified) {
            <i class="icon icon-check-circle-outline bg-white-alpha-5 ms-1"></i>
          }
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #uniqIDTooltipContent
  >The <b>Uniq ID</b> lets you know which Uniq this is amongst all Uniqs created on Ultra</ng-template
>
<ng-template #factoryIDTooltipContent
  >The <b>factory ID</b> lets you know which factory this is amongst all factories created on Ultra</ng-template
>
<ng-template #serialNumberTooltipContent
  >The <b>serial number</b> lets you know which Uniq this is within a collection of Uniqs</ng-template
>
<ng-template #creatorTooltipContent
  >Each <b>creator</b> has a wallet ID and this lets you know who created this Uniq
</ng-template>
