import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AutotestAttributeDirective } from '../../autotests';
import { EncodeUriPipe } from '../../pipes/encode-uri/encode-uri.pipe';
import { SharedLanguagePipeModule } from '../../pipes/languages/languages.pipe.module';
import { SharedPipesModule } from '../../pipes/pipes.module';
import { UniqTypeIconComponent } from '../../uniq/uniq-type-icon';
import { CircleProgressModule } from '../circle-progress/circle-progress.module';
import { FormModule } from '../form/form.module';
import { GameModule } from '../game';
import { IdCardPanelDispatcherService } from '../id-card-content';
import { IdCardQuantityStatusComponent } from '../id-card-content';
import { IdCardContentModule } from '../id-card-content/id-card-content.module';
import { LazyImageModule } from '../lazy-image/lazy-image.module';
import { MediaCarouselModule } from '../media-carousel/media-carousel.module';
import { UltraOverlayModule } from '../overlay/overlay.module';
import { SliderComponent, SliderContentDirective, SwiperTemplateDirective } from '../slider';
import { TableModule } from '../table/table.module';
import { TabsModule } from '../tabs/tabs.module';
import { VersionCurrencyModule } from '../version-currency/version-currency.module';

import { PreviewInfoBoxComponent } from './preview-info-box/preview-info-box.component';
import { PreviewModeNotificationComponent } from './preview-mode-notification/preview-mode-notification.component';
import { PreviewPlaceholderListComponent } from './preview-placeholder-list/preview-placeholder-list.component';
import { PreviewTokenHeaderComponent } from './preview-token-header/preview-token-header.component';
import { PreviewTokenHeaderPropertiesComponent } from './preview-token-header/preview-token-header-properties/preview-token-header-properties.component';
import { TokenPreviewBodyComponent } from './token-preview-body/token-preview-body.component';

@NgModule({
  imports: [
    CommonModule,
    LazyImageModule,
    VersionCurrencyModule,
    SliderComponent,
    SliderContentDirective,
    SwiperTemplateDirective,
    TableModule,
    SharedLanguagePipeModule,
    SharedPipesModule,
    TabsModule,
    UltraOverlayModule,
    FormModule,
    NgbTooltipModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    AutotestAttributeDirective,
    CircleProgressModule,
    IdCardContentModule,
    IdCardQuantityStatusComponent,
    MediaCarouselModule,
    UniqTypeIconComponent,
    GameModule,
    PreviewTokenHeaderPropertiesComponent,
    RouterLink,
    EncodeUriPipe,
  ],
  declarations: [
    PreviewModeNotificationComponent,
    PreviewInfoBoxComponent,
    PreviewTokenHeaderComponent,
    PreviewPlaceholderListComponent,
    TokenPreviewBodyComponent,
  ],
  exports: [
    PreviewModeNotificationComponent,
    PreviewInfoBoxComponent,
    PreviewTokenHeaderComponent,
    PreviewPlaceholderListComponent,
    TokenPreviewBodyComponent,
  ],
  providers: [IdCardPanelDispatcherService],
})
export class TokenFactoryPreviewModule {}
