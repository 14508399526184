import { IBuild, IOsConfig } from '../interfaces/build.interface';

export enum BranchType {
  NONE = 'none',
  DEV = 'dev',
  BETA = 'beta',
  MASTER = 'master',
}

export enum BuildType {
  Core = 'CORE',
  Demo = 'DEMO',
}

export enum OsPlatformType {
  Windows = 'win',
  Linux = 'linux',
  MacOs = 'mac',
  CrossPlatform = 'cross',
}

export type BuildsByTypeDictionary = { [key in BuildType]: IBuild[] };

export const buildTypeDictionary: { [key in BuildType]: string } = {
  [BuildType.Core]: 'Game',
  [BuildType.Demo]: 'Demo',
};

export const OsPlatformTypeDictionary: { [key in OsPlatformType]: IOsConfig } = {
  [OsPlatformType.Windows]: {
    name: 'Windows',
    icon: 'windows',
  },
  [OsPlatformType.MacOs]: {
    name: 'Mac OS',
    icon: 'apple',
  },
  [OsPlatformType.Linux]: {
    name: 'Linux',
    icon: 'linux',
  },
  [OsPlatformType.CrossPlatform]: {
    name: 'Crossplatform',
    icon: 'star-filled',
  },
};
