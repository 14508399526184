import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

import { DIALOG_DATA, DialogRef } from './../../dialog';
import { DetailDialog } from './../detail-dialog.base';

@Component({
  selector: 'ultra-uniq-factory-detail-dialog',
  templateUrl: './uniq-factory-detail-dialog.component.html',
  styleUrls: ['./uniq-factory-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UniqFactoryDetailDialogComponent extends DetailDialog<UniqFactoryDetailDialogComponent> {
  constructor(
    @Inject(DIALOG_DATA) data: any,
    dialogRef: DialogRef<UniqFactoryDetailDialogComponent>,
    ngbTooltipConfig: NgbTooltipConfig
  ) {
    super(data, dialogRef, ngbTooltipConfig);
  }
}
