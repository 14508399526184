import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureFlag, FeatureFlagService } from '@ultra/core/lib/services/feature-flag';
import { WINDOW } from '@ultra/core/providers';
import { UltraosApiService } from '@ultra/core/services';

@Injectable({ providedIn: 'root' })
export class ClientVersionGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private ultraosApiService: UltraosApiService,
    @Inject(WINDOW) private window: Window,
    private featureFlagService: FeatureFlagService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.ultraosApiService.getUltraClientVersion().pipe(
      map((version: string) => {
        const isClientEnvironmentAllowed = this.featureFlagService.isEnabled(FeatureFlag.ALLOW_ACCESS_WITHOUT_CLIENT);
        const isAllowed = (version && !isClientEnvironmentAllowed) || isClientEnvironmentAllowed;
        if (isAllowed) {
          return true;
        }

        return this.router.createUrlTree(['403']);
      })
    );
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
