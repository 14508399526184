import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IDimensionApp } from '@ultra/core/models';
import { AutotestAttributeDirective } from '@ultra/shared';

@Component({
  selector: 'ultra-universe-nav-item',
  templateUrl: './universe-nav-item.component.html',
  styleUrls: ['./universe-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, NgbTooltipModule, AutotestAttributeDirective],
  encapsulation: ViewEncapsulation.None,
})
export class UniverseNavItemComponent {
  @Input() app: IDimensionApp;

  @Output() activeApp: EventEmitter<IDimensionApp> = new EventEmitter<IDimensionApp>();

  activate(): void {
    if (this.app?.disabled) {
      return;
    }
    this.activeApp.emit(this.app);
  }
}
