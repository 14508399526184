<div class="tab-marketplace-content">
  <ul class="marketplace-history">
    <li i18n><i></i>Price history</li>
    <li i18n><i></i>Trade history</li>
    <li i18n><i></i>Owners</li>
  </ul>
  <div class="marketplace-history-frame">
    <p class="b1-bold text-white-alpha-3" i18n>Uniq trading data coming soon!</p>
  </div>
</div>
<div class="tab-marketplace-content" ultraAutotestAttribute="tab-marketplace-container">
  <ultra-tab-group
    tabIndicator="bellow"
    indicatorSize="thick"
    tabHeaderClass="id-card-marketplace-tab-header"
    [sameTabWidth]="true"
    [tabNavMinSize]="false"
    [padContent]="false"
  >
    <ultra-tab>
      <span *ultraTabLabel class="tab-label" i18n>Sell on Marketplace</span>
      <ultra-id-card-sell
        *ultraTabContent
        [minResellPrice]="minResellPrice"
        [minResellPriceCurrency]="minResellPriceCurrency"
        [uniq]="uniq"
      ></ultra-id-card-sell>
    </ultra-tab>
    <ultra-tab>
      <span *ultraTabLabel class="tab-label" i18n ultraAutotestAttribute="card-transfer-tab-content"
        >Transfer Uniq</span
      >
      <ultra-id-card-transfer *ultraTabContent class="tab-content" [uniq]="uniq"></ultra-id-card-transfer>
    </ultra-tab>
  </ultra-tab-group>
</div>
