import { ScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';

export class CustomBlockStrategy implements ScrollStrategy {
  private _isEnabled = false;

  constructor(private _viewportRuler: ViewportRuler, private document: Document) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  attach() {}

  enable() {
    if (this._canBeEnabled()) {
      const html = this.document.documentElement;
      html.classList.add('custom-block-scroll');
      this._isEnabled = true;
    }
  }

  disable() {
    if (this._isEnabled) {
      const html = this.document.documentElement;
      this._isEnabled = false;
      html.classList.remove('custom-block-scroll');
    }
  }

  private _canBeEnabled(): boolean {
    const html = this.document.documentElement;

    if (html.classList.contains('custom-block-scroll') || this._isEnabled) {
      return false;
    }

    const body = this.document.body;
    const viewport = this._viewportRuler.getViewportSize();
    return body.scrollHeight > viewport.height || body.scrollWidth > viewport.width;
  }
}
