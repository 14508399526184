import { inject, Injectable, signal } from '@angular/core';

import { EnvironmentConfig } from '@ultra/environment';

import { LanguageInterface } from '../../../interfaces';
import { IGeofencingEntity } from '../../../models/geofencing';
import countries from '../static/countries.data';
import languages from '../static/languages.data';

@Injectable({ providedIn: 'root' })
export class GeofencingService {
  private envConfig = inject(EnvironmentConfig);

  private languagesSignal = signal<LanguageInterface[]>(languages.data.languages);
  readonly languages = this.languagesSignal.asReadonly();

  private countriesSignal = signal<IGeofencingEntity[]>(countries[this.envConfig.envName].data.countries);
  readonly countries = this.countriesSignal.asReadonly();

  private regionsSignal = signal<IGeofencingEntity[]>(countries[this.envConfig.envName].data.regions);
  readonly regions = this.regionsSignal.asReadonly();
}
