import { inject, Injectable } from '@angular/core';
import { map, Observable, ReplaySubject } from 'rxjs';

import { IdCardEntity, IStoreTokenFactory, TokenFactoryIdCardAdapter, UniqFactoryIdCardAdapter } from '../..';
import { UniqFactoryGqlService } from '../../uniq-factory/services/uniq-factory-gql.service';
import { IUniqFactory } from '../../uniq-factory/uniq-factory.interface';

@Injectable({ providedIn: 'root' })
export class TokenFactoryPreviewFacadeService {
  resalableTokenFactory = new ReplaySubject<IdCardEntity>(1);
  resalableTokenFactory$ = this.resalableTokenFactory.asObservable();
  private idCardAdapter = new TokenFactoryIdCardAdapter();
  private uniqIdCardAdapter = new UniqFactoryIdCardAdapter();
  private uniqFactoryGqlService = inject(UniqFactoryGqlService);

  getTokenFactoryEntity(tokenFactory: IStoreTokenFactory): IdCardEntity {
    return this.idCardAdapter.transform(tokenFactory);
  }

  getUniqFactoryEntity(tokenFactory: IStoreTokenFactory): Observable<IdCardEntity> {
    return this.uniqFactoryGqlService
      .getUniqFactoryById(tokenFactory.onChainId.toString())
      .pipe(map((uniqFactory: IUniqFactory) => this.uniqIdCardAdapter.transform(uniqFactory)));
  }
}
