import { DateTimeDuration, DateTimeUnit } from '../../../services/helpers/datetime.helper';

export interface ActionDisplayState {
  isLocked: boolean;
  hasStartDate: boolean;
  hasEndDate: boolean;
  status: ActionStatus;
  duration: ActionDuration;
  durationStatus?: ActionDurationStatus;
  color: string;
  availableDurationUnit: DateTimeUnit;
  lockedDurationUnit: DateTimeUnit;
}

export enum IdCardAction {
  TRADABILITY = 'tradability',
  TRANSFERABILITY = 'transferability',
}

export enum ActionStatus {
  ABLE = 'able',
  UNABLE = 'unable',
  PENDING = 'pending',
  DONE = 'done',
  TILL = 'till',
}

export interface ActionDuration {
  available: DateTimeDuration;
  locked: DateTimeDuration;
}

export type ActionDurationStatus = 'long' | 'regular' | 'short';
