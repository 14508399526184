export enum DeviceType {
  ULTRA_CLIENT = 'ULTRA_CLIENT',
  ULTRA_EXTENSION = 'ULTRA_EXTENSION',
}

export interface IDevice {
  deviceId: string;
  ultraHalfVaultKey: string;
}

export interface INewUserDeviceData {
  publicOwnerKey: string;
  publicActiveKey: string;
  deviceType: DeviceType;
  deviceId: string;
}
