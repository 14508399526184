import { SwiperOptions } from 'swiper';

export const sliderConfig: SwiperOptions = {
  slidesPerView: 1,
  loop: true,
  pagination: {
    enabled: true,
    clickable: true,
    dynamicBullets: false,
  },
  autoplay: {
    delay: 8000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  },
  navigation: false,
  centerInsufficientSlides: true,
  allowTouchMove: false,
  observer: true,
  slideNextClass: 'ultra-slideshow-slide-next',
};

export const wishlistConfig: SwiperOptions = {
  slidesPerView: 1,
  loop: true,
  pagination: {
    enabled: true,
    clickable: true,
    dynamicBullets: false,
  },
  autoplay: false,
  navigation: false,
  centerInsufficientSlides: true,
  allowTouchMove: true,
  observer: true,
  slideNextClass: 'ultra-slideshow-slide-next',
  breakpoints: {
    800: {
      allowTouchMove: false,
    },
  },
};

export const suggestedGamestConfig: SwiperOptions = {
  slidesPerView: 1,
  loop: false,
  pagination: false,
  autoplay: false,
  navigation: false,
  centerInsufficientSlides: true,
  allowTouchMove: true,
  observer: true,
  slideNextClass: 'ultra-slideshow-slide-next',
  breakpoints: {
    800: {
      allowTouchMove: false,
    },
  },
};

export const relatedGamesConfig: SwiperOptions = {
  slidesPerView: 1,
  loop: true,
  pagination: {
    enabled: true,
    clickable: true,
    dynamicBullets: false,
  },
  autoplay: false,
  navigation: false,
  centerInsufficientSlides: true,
  allowTouchMove: true,
  observer: true,
  slideNextClass: 'ultra-slideshow-slide-next',
  breakpoints: {
    800: {
      allowTouchMove: false,
    },
  },
};

export const slideShowConfig: SwiperOptions = {
  slidesPerView: 3.5,
  loopedSlides: 3,
  slidesPerGroup: 2,
  loop: false,
  autoplay: false,
  pagination: false,
  navigation: false,
  centerInsufficientSlides: true,
  allowTouchMove: false,
  observer: true,
  breakpoints: {
    800: {
      slidesPerView: 5.5,
      slidesPerGroup: 4,
    },
  },
};

export const carouselSlideShowConfig: SwiperOptions = {
  slidesPerView: 4,
  slidesPerGroup: 4,
  loop: false,
  autoplay: false,
  pagination: {
    enabled: false,
  },
  navigation: false,
  centerInsufficientSlides: true,
  centeredSlidesBounds: true,
  spaceBetween: 8,
  allowTouchMove: false,
  observer: true,
  breakpoints: {
    200: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1040: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
    2048: {
      slidesPerView: 6,
      slidesPerGroup: 6,
    },
  },
};

export const gameDetailConfig: SwiperOptions = {
  slidesPerView: 'auto',
  autoplay: false,
  allowTouchMove: false,
  loop: true,
  navigation: false,
  spaceBetween: 24,
  centeredSlides: true,
  pagination: {
    enabled: true,
    clickable: false,
    dynamicBullets: false,
  },
};

export const uniqueFactoryDetailConfig: SwiperOptions = {
  slidesPerView: 'auto',
  loop: true,
  autoplay: false,
  spaceBetween: 24,
  centeredSlides: true,
  pagination: {
    enabled: true,
    clickable: false,
    dynamicBullets: false,
  },
  breakpoints: {
    800: {
      allowTouchMove: false,
    },
  },
};

export const uniqueDetailConfig: SwiperOptions = {
  slidesPerView: 'auto',
  loop: true,
  autoplay: false,
  spaceBetween: 24,
  centeredSlides: true,
  pagination: {
    enabled: true,
    clickable: false,
    dynamicBullets: false,
  },
  breakpoints: {
    800: {
      allowTouchMove: false,
    },
  },
};

export const tokenPreviewConfig: SwiperOptions = {
  slidesPerView: 'auto',
  loop: true,
  autoplay: false,
  spaceBetween: 24,
  centeredSlides: true,
  pagination: {
    enabled: true,
    clickable: false,
    dynamicBullets: false,
  },
  breakpoints: {
    800: {
      allowTouchMove: false,
    },
  },
};

export const carouselSingleSlideConfig: SwiperOptions = {
  slidesPerView: 1,
  loop: true,
  pagination: {
    enabled: false,
    clickable: false,
    dynamicBullets: false,
  },
  autoplay: false,
  navigation: false,
  centerInsufficientSlides: true,
  allowTouchMove: false,
  observer: true,
  slideNextClass: 'ultra-slideshow-slide-next',
};
