import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorsComponent } from './components/errors-component/errors.component';

const routes: Routes = [
  { path: 'error', component: ErrorsComponent },
  { path: '401', component: ErrorsComponent, data: { status: 401 } },
  { path: '403', component: ErrorsComponent, data: { status: 403 } },
  { path: '451', component: ErrorsComponent, data: { status: 451 } },
  { path: 'forbidden', component: ErrorsComponent, data: { status: 'forbidden' } },
  { path: '**', component: ErrorsComponent, data: { status: 404 } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorsRoutingModule {}
