import { ElementRef, Inject, Injectable } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { WINDOW } from '@ultra/core/providers';

import { YoutubePlayerService } from '../../../modules/youtube-player/services/youtube-player';

@Injectable()
export class MediaCarouselYoutubeService {
  public playerConfig;
  private activeVideoPlayers: YouTubePlayer[] = [];

  constructor(@Inject(WINDOW) public window: Window, private youtubePlayerService: YoutubePlayerService) {
    this.playerConfig = this.youtubePlayerService.getMediaCarouselParams();
  }

  public initYoutubeIFrameApi(): void {
    this.youtubePlayerService.loadYoutubeApi();
  }

  public addPlayerToActiveList(video: YouTubePlayer): void {
    this.activeVideoPlayers.push(video);
  }

  public destroyYoutubeVideoPlayers() {
    if (this.activeVideoPlayers) {
      this.activeVideoPlayers.forEach((video) => video.ngOnDestroy);
    }
  }

  public pauseActiveVideoPlayers(): void {
    if (this.activeVideoPlayers) {
      this.activeVideoPlayers.forEach((video: YouTubePlayer) => {
        // If player state is 1, video is in playing state.
        if (video.getPlayerState() === 1) {
          video.pauseVideo();
        }
      });
    }
  }

  public getSliderVideoPlayerSize$(parentElement: ElementRef): Observable<{ width: number; height: number }> {
    return fromEvent(this.window, 'resize').pipe(
      map(() => {
        return {
          width: parentElement.nativeElement.offsetWidth,
          height: parentElement.nativeElement.offsetHeight,
        };
      }),
      startWith({
        width: parentElement.nativeElement.offsetWidth,
        height: parentElement.nativeElement.offsetHeight,
      })
    );
  }
}
