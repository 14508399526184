<div
  [ngbTooltip]="tooltip"
  [disableTooltip]="!showTooltip"
  [tooltipClass]="tooltipClass"
  [placement]="tooltipPlacement"
  triggers="hover"
  class="{{ wrapperClassNames }}"
  (mouseover)="changeHoverState(true)"
  (mouseleave)="changeHoverState(false)"
  [autoClose]="false"
  #buttonTooltip="ngbTooltip">
  <button
    #copyButton
    class="d-flex align-items-center justify-content-center btn col-12 text-transform-none w-100 h-100 {{
      buttonClass
    }} {{ buttonClassNames }}"
    type="button"
    [cdkCopyToClipboard]="text"
    (cdkCopyToClipboardCopied)="onCopySuccess()">
    @if (showLabel) {
      <span class="label content-ellipsis flex-grow-1 b2-regular me-1">
        {{ label || text | ellipsis: visibleLength }}
      </span>
    }

    <i class="icon" [ngClass]="iconClass"></i>

    @if (showLabelOnHover && isHover) {
      <span class="label content-ellipsis flex-grow-1 b2-regular mx-1">
        {{ hoverLabel || label || text | ellipsis: visibleLength }}
      </span>
    }
  </button>
</div>

<ng-template #tooltip>{{ tooltipText }}</ng-template>
