export const playingModesMock = [
  {
    name: 'Single player',
    cssClass: 'person',
  },
  {
    name: 'Multi-player',
    cssClass: 'user-group-lg',
  },
  {
    name: 'Online Multiplayer',
    cssClass: 'user-group-lg',
  },
  {
    name: 'Cross-Platform',
    cssClass: 'user-group-lg',
  },
  {
    name: 'Co-op',
    cssClass: 'person-couple',
  },
  {
    name: 'Online Co-op',
    cssClass: 'person-couple',
  },
];
