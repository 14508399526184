<ultra-slider class="carousel media-carousel" [theme]="theme" [skeletonMode]="true">
  @for (media of [1, 2, 3]; track media) {
    <ultraSliderContent>
      <div class="media-carousel-item">
        <div class="media-carousel-image">
          <ng-template ultraSwiperTemplate>
            @if (animatedSkeleton) {
              <ultra-background-skeleton [ngClass]="[size + '-background-skeleton', theme]" />
            } @else {
              <div class="bg-grey-light-1 h-100 d-flex align-items-center justify-content-center">
                <i class="icon icon-broken-image-32 bg-white-70"></i>
              </div>
            }
          </ng-template>
        </div>
      </div>
    </ultraSliderContent>
  }
</ultra-slider>
