<div class="content-wrap">
  <div class="content-item content-item--left d-flex-center">
    @if (idCardEntity?.type === 'uniq') {
      @if (idCardEntity?.medias?.image?.contentType === 'IMAGE') {
        <ultra-lazy-image
          [defaultImage]="idCardEntity?.medias?.image?.uri"
          [imageAlt]="idCardEntity?.info?.title"
          [showPlaceHolder]="false"
          class="content-image-wrap"
          styleClass="content-image">
        </ultra-lazy-image>
      }
      @if (idCardEntity?.medias?.image?.contentType === 'VIDEO') {
        <div class="w-100 h-100">
          <ultra-media-video-player
            [src]="idCardEntity.medias.image.uri"
            [posterSrc]="idCardEntity.medias.square?.uri"></ultra-media-video-player>
        </div>
      }
    }
  </div>

  <div class="content-item content-item--right d-flex flex-column" ultraScroll>
    <div class="scroll-container">
      <div class="attributes-table d-flex flex-column">
        <div class="attributes-table__header">
          <div class="attributes-table__caption overline-medium text-uppercase text-white-alpha-7 ls-one-and-half">
            Attributes
          </div>
        </div>

        <div class="attributes-table__body d-flex flex-column">
          @if (idCardEntity?.metadata?.content?.attributes; as attributes) {
            @for (attribute of attributes; track attribute) {
              <div class="attributes-table__row d-flex flex-row flex-wrap">
                @if (attribute.descriptor?.type) {
                  <div class="attributes-table__cell d-flex align-items-center">{{ attribute.descriptor.name }}</div>
                  <div class="attributes-table__cell d-flex align-items-center justify-content-end">
                    @if (attribute.descriptor.type !== 'boolean') {
                      {{ attribute.value }}
                    } @else {
                      {{ attribute.value ? 'Yes' : 'No' }}
                    }
                  </div>
                } @else {
                  <div class="attributes-table__cell d-flex align-items-center">{{ attribute.key }}</div>
                  <div class="attributes-table__cell d-flex align-items-center justify-content-end">
                    @if (attribute.value !== true) {
                      {{ attribute.value }}
                    } @else {
                      {{ attribute.value ? 'Yes' : 'No' }}
                    }
                  </div>
                }
              </div>
            }
          } @else {
            <div class="attributes-table__no-attributes d-flex align-items-center justify-content-center">
              This Uniq has no attributes
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
