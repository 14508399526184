import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

import { IUniqFactorySelectorNotification } from './uniq-factory-selector-notification/uniq-factory-selector-notification.component';
/**
 * A service to manage the state of the Uniq Factory Selector
 * It keeps track of the Uniq Factories that are being searched.
 *
 * It also keeps track of the Uniq Factories that were found in the search results so
 * we can later check if the search was successful or not.
 *
 */
@Injectable({ providedIn: 'root' })
export class UniqFactorySelectorService {
  /**
   * Current Uniq Factory ids in the result list
   */
  resultUniqFactoryIds = new BehaviorSubject<string[]>([]);
  resultUniqFactoryIds$ = this.resultUniqFactoryIds.asObservable();

  notifications = new BehaviorSubject<IUniqFactorySelectorNotification[]>([]);
  notifications$ = this.notifications.asObservable();

  /**
   * Duplicated Uniq Factory ids between selector and result list
   */
  duplicateUniqFactoryIds$ = this.notifications$.pipe(
    map((notifications) => notifications.filter((notification) => notification.status === 'duplicated')),
    map((notifications) => notifications.map((notification) => notification.id))
  );

  /**
   * Uniq Factory ids are sent to the search
   */
  loadingUniqFactoryIds$ = this.notifications$.pipe(
    map((notifications) => notifications.filter((notification) => notification.status === 'loading')),
    map((notifications) => notifications.map((notification) => notification.id))
  );

  /**
   * Uniq Factory ids that were not found in the search results
   */
  errorUniqFactoryIds$ = this.notifications$.pipe(
    map((notifications) => notifications.filter((notification) => notification.status === 'error')),
    map((notifications) => notifications.map((notification) => notification.id))
  );

  /**
   * Mantain the list of Uniq Factory ids to search
   * so we can later check if the search was successful or not
   */
  addUniqFactoryIdsToSearch(uniqFactoryOnChainIds: string[]): void {
    this.cleanNotifications();
    const loadingUniqFactories: IUniqFactorySelectorNotification[] = uniqFactoryOnChainIds.map((onChainId) => ({
      id: onChainId,
      status: 'loading',
    }));
    const newNotificationState: IUniqFactorySelectorNotification[] = [
      ...this.notifications.value,
      ...loadingUniqFactories,
    ];
    this.notifications.next(newNotificationState);
  }

  addResults(uniqFactoryOnChainIds: string[]): void {
    this.resultUniqFactoryIds.next(uniqFactoryOnChainIds);
    // Remove loading notification for uniq factories that were found
    uniqFactoryOnChainIds.map((onChainId) => this.removeNotification(onChainId));
    // Add error notification for Uniq Factories not found
    const notFoundIds: IUniqFactorySelectorNotification[] = this.notifications.value
      .filter(Boolean)
      .map((notification) => ({ status: 'error', id: notification.id }));
    this.notifications.next(notFoundIds);
  }

  /**
   * Remove notification item
   */
  removeNotification(uniqFactoryOnChainId: string): void {
    const updatedNotifications = this.notifications.value.filter(
      (notification) => notification.id !== uniqFactoryOnChainId
    );
    this.notifications.next(updatedNotifications);
  }

  addDuplicateUniqFactoryId(uniqFactoryOnChainId: string): void {
    const isAlreadyDuplicated = this.notifications.value.find(
      (notification) => notification.id === uniqFactoryOnChainId && notification.status === 'duplicated'
    );
    if (!isAlreadyDuplicated) {
      const newNotificationState: IUniqFactorySelectorNotification[] = [
        ...this.notifications.value,
        { id: uniqFactoryOnChainId, status: 'duplicated' },
      ];
      this.notifications.next(newNotificationState);
    }
  }

  private cleanNotifications(): void {
    this.notifications.next([]);
  }
}
