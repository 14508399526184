import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IDimension, IDimensionApp } from '@ultra/core/models';
import { LicenseManageService } from '@ultra/shared';
import { DeepLinkService } from '@ultra/shared/services/deeplink';

import { DimensionComponent } from './dimension/dimension.component';
import { UniverseNavService } from './universe-nav.service';
import { UniverseNavItemComponent } from './universe-nav-item/universe-nav-item.component';
/**
 * Universe Nav root component
 */
@UntilDestroy()
@Component({
  selector: 'ultra-universe-nav',
  templateUrl: './universe-nav.component.html',
  styleUrls: ['./universe-nav.component.scss'],
  standalone: true,
  imports: [AsyncPipe, DimensionComponent, UniverseNavItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniverseNavComponent implements AfterViewInit, OnDestroy {
  private universeNavService: UniverseNavService = inject(UniverseNavService);
  private deepLinkService: DeepLinkService = inject(DeepLinkService);
  private licenseManageService: LicenseManageService = inject(LicenseManageService);
  private domSanitizer: DomSanitizer = inject(DomSanitizer);
  dimensions$: Observable<IDimension[]> = this.universeNavService.dimensions$;
  apps$: Observable<IDimensionApp[]> = this.universeNavService.apps$;
  safeAuthDeeplinkUrl$: Observable<SafeResourceUrl> = this.deepLinkService.authenticationDeepLinkUrl$.pipe(
    map((url: string) => {
      // The authentication deeplink is displayed in an iframe
      // This is a trusted url verified in the deeplinkService
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }),
  );

  onSetActiveDimension(dimension: IDimension): void {
    this.universeNavService.launchDimension(dimension);
  }

  onSetActiveApp(app: IDimensionApp): void {
    this.universeNavService.launchApp(app);
  }

  ngAfterViewInit(): void {
    merge(
      this.licenseManageService.listenToRenewRoutineEvents().pipe(untilDestroyed(this)),
      this.licenseManageService.renewLicenses().pipe(take(1)),
    ).subscribe();
  }

  ngOnDestroy() {
    this.licenseManageService.unsubscribeFromRenewRoutineEvents();
  }
}
