import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';

import { TabContentDirective } from '../tab-content.directive';
import { TabLabelDirective } from '../tab-label.directive';

let nextId = 0;

@Component({
  selector: 'ultra-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  label = '';

  @Input()
  disabled = false;

  @Input()
  id = `ultra-tab-${nextId++}`;

  @ContentChild(TabLabelDirective)
  get templateLabel(): TabLabelDirective {
    return this._templateLabel;
  }
  set templateLabel(value: TabLabelDirective) {
    if (value) {
      this._templateLabel = value;
    }
  }
  private _templateLabel: TabLabelDirective;

  @ContentChild(TabContentDirective, { read: TemplateRef })
  explicitContent: TemplateRef<any>;

  @ViewChild(TemplateRef, { static: true })
  implicitContent: TemplateRef<any>;

  get content(): TemplatePortal | null {
    return this._contentPortal;
  }

  readonly _stateChanges = new Subject<void>();

  private _contentPortal: TemplatePortal | null = null;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      Object.prototype.hasOwnProperty.call(changes, 'label') ||
      Object.prototype.hasOwnProperty.call(changes, 'disabled')
    ) {
      this._stateChanges.next();
    }
  }

  ngAfterViewInit(): void {
    this._contentPortal = new TemplatePortal(this.explicitContent || this.implicitContent, this.viewContainerRef);
  }

  ngOnDestroy(): void {
    this._stateChanges.complete();
  }
}
