import { IMedias } from '../models';

export enum MetadataStatus {
  PROCESSING = 'PROCESSING',
  VALID = 'VALID',
  INVALID = 'INVALID',
  RESTRICTED = 'RESTRICTED',
}

export enum AttributeType {
  boolean = 'boolean',
  number = 'number',
  string = 'string',
  ISODateString = 'ISODateString',
}

export enum ResourceIntegrityType {
  SHA256 = 'SHA256',
}

export interface FailureContextEntry {
  key: string;
  value: string;
}

export interface Failure {
  code: string;
  context: FailureContextEntry[];
  defaultMessage: string;
}

export interface ResourceIntegrity {
  type: ResourceIntegrityType;
  hash: string;
}

export interface Resource {
  uri: string;
  contentType: string;
  integrity: ResourceIntegrity;
}

export interface MetadataResource {
  key: string;
  value: Resource;
}

export interface MetadataAttributeDescriptor {
  dynamic: boolean;
  type: AttributeType;
  name: string;
  description: string;
}

export interface MetadataAttribute {
  key: string;
  value: string | number | boolean;
  descriptor: MetadataAttributeDescriptor;
}

export interface UniqFactoryMetadataContent {
  name: string;
  subName?: string;
  description?: string;
  medias?: IMedias;
  properties?: Record<string, any>;
  attributes?: MetadataAttribute[];
  resources?: MetadataResource[];
}

export interface IUniqFactoryRestrictedMetadata {
  id: string;
  status: string;
  restricted: boolean;
}

export interface IUniqFactoryBaseMetadata {
  id: string;
  status: MetadataStatus;
  source: Resource;
  restricted?: boolean;
}

export interface UniqFactoryValidMetadata extends IUniqFactoryBaseMetadata {
  persistentSource: Resource;
  content: UniqFactoryMetadataContent;
}

export interface UniqFactoryInvalidMetadata extends IUniqFactoryBaseMetadata {
  validationFailures: Failure[];
}

export type UniqFactoryMetadata =
  | IUniqFactoryBaseMetadata
  | UniqFactoryValidMetadata
  | UniqFactoryInvalidMetadata
  | IUniqFactoryRestrictedMetadata;
