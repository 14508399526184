<div class="video-container ratio ratio-16x9 overflow-hidden">
  @if (posterSrc) {
    <img class="video-poster" [class.d-none]="play" [src]="posterSrc" />
  }
  <div class="overlay position-absolute w-100 h-100 justify-content-center align-items-center" [class.d-none]="play">
    <i class="icon icon-play-transparent cursor-pointer" (click)="playVideo()"></i>
  </div>
  <video
    [class.d-none]="!play"
    [autoplay]="play"
    [muted]="true"
    [src]="src"
    loop
    controls
    controlslist="nodownload noremoteplayback noplaybackrate"
    disablepictureinpicture
    disableremoteplayback
    ultraVideo
    #video
    ultraAutotestAttribute="media-video-player-content"></video>
</div>
