import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { IconSize } from '@ultraos/design-system';

@Component({
  selector: 'ultra-info-message-detail',
  templateUrl: './info-message-detail.component.html',
  styleUrls: ['./info-message-detail.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgbTooltip],
})
export class InfoMessageDetailComponent implements AfterViewInit {
  @Input() mode: 'error' | 'pending' | 'restricted' = 'pending';
  @Input() size: IconSize = 24;
  @Input() extraClassName = '';

  @ViewChild('content', { static: true }) contentElRef: ElementRef;

  private cdr = inject(ChangeDetectorRef);

  disableTooltip = true;

  ngAfterViewInit(): void {
    // the correct CSS to be applied in this event loop
    // onResize callback to be triggered in the next event loop
    // to ensure CSS applied properly
    setTimeout(() => this.onResize());
  }

  onResize(): void {
    this.disableTooltip = window.getComputedStyle(this.contentElRef.nativeElement).display !== 'none';
    this.cdr.detectChanges();
  }
}
