import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IMedia } from '../../../../../models/uniq/interfaces/uniq.interface';

@Component({
  selector: 'ultra-expanded-media-videos',
  templateUrl: './expanded-media-videos.component.html',
  styleUrls: ['./expanded-media-videos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedMediaVideosComponent {
  @Input() videos: IMedia[];
  @Input() videoWidth: number;
  @Input() videoHeight: number;
  @Input() selectedMedia: IMedia;
}
