import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ErrorsComponent } from './components/errors-component/errors.component';
import { ErrorsRoutingModule } from './errors-routing.module';
import { ErrorHandlerApiService } from './services/error-handler-api.service';

@NgModule({
  imports: [CommonModule, ErrorsRoutingModule],
  declarations: [ErrorsComponent],
  providers: [ErrorHandlerApiService],
  exports: [ErrorsComponent],
})
export class ErrorsModule {}
