export enum MixpanelEvent {
  START_SIGN_UP = 'StartSignUp',
  START_SIGN_IN = 'StartSignIn',
  FILTER_SELECTED = 'FilterSelected',
  DISCOUNT_CAROUSEL_CLICK = 'DiscountCarouselClick',
  POPULAR_GAMES_CLICK = 'PopularGamesClick',
  SUGGESTED_GAMES_CLICK = 'SuggestedGamesClick',
  WISHLIST_CLICK = 'WishListClick',
  VIEW_GAME_PAGE = 'ViewGamePage',
  VIEW_LIBRARY = 'ViewLibrary',
  GAME_LAUNCH = 'GameLaunch',
  GAME_INSTALL = 'GameInstall',
  GAME_UPDATE = 'GameUpdate',
  GAME_UNINSTALL = 'GameUninstall',
  GAME_PURCHASE_INITIATE = 'GamePurchaseInitiate',
  UNIQ_PURCHASE_INITIATE = 'UniqPurchaseInitiate',
  UNIQ_BUY_INITIATE = 'UniqBuyInitiate',
  SELECT_PAYMENT_METHOD = 'SelectPaymentMethod',
  BILLING_DETAILS = 'BillingDetails',
  CARD_DETAILS_INPUT = 'CardDetailsInput',
  CONFIRM_PURCHASE = 'ConfirmPurchase',
  PURCHASE_FAIL = 'PurchaseFail',
  PURCHASE_BACK = 'PurchaseBack',
  UNIQ_PURCHASED = 'UniqPurchase',
  GAME_PURCHASED = 'GamePurchase',
  GAMES_BOUGHT = 'GamesBought',
  REVIEW_UNIQ_BUY = 'ReviewUniqBuy',
  UNIQ_BUY_COMPLETE = 'UniqBuyComplete',
  UNIQ_BUY_FAIL = 'UniqBuyFail',
  PURCHASE_TO_INVENTORY = 'PurchaseToInventory',
  WISHLIST_GAME = 'WishlistGame',
  UNIQ_SALE_INITIATE = 'UniqSaleInitiate',
  UNIQ_SALE_DETAILS = 'UniqSaleDetails',
  REVIEW_UNIQ_SALE = 'ReviewUniqSale',
  UNIQ_SALE_COMPLETE = 'UniqSetOnSaleComplete',
  UNIQ_SALE_FAIL = 'UniqSetOnSaleFail',
  UNIQ_CANCEL_SALE_INITIATE = 'UniqCancelSaleInitiate',
  UNIQ_CANCEL_SALE_DETAILS = 'UniqCancelSaleDetails',
  REVIEW_UNIQ_CANCEL_SALE = 'ReviewUniqCancelSale',
  UNIQ_WITHDRAW_SALE_COMPLETE = 'UniqWithdrawSaleComplete',
  UNIQ_WITHDRAW_SALE_FAIL = 'UniqWithdrawSaleFail',
  VISIT_GAME_STORE = 'VisitGameStore',
  APP_OPENED = 'AppOpened',
}
