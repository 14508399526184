import { Decimal } from 'decimal.js';

import { IMonetaryAmount, IRevenue, UniqRevenueShare } from '../../../models';
import { IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';

export interface SharedRevenue {
  readonly amount: Decimal;
  readonly ratio: Decimal;
}

export interface Revenue {
  readonly platform: SharedRevenue;
  readonly creators: SharedRevenue;
  readonly owner: SharedRevenue;
}

const DECIMAL_PRECISION = 8;

export class InventoryPrice {
  private static DECIMAL_PLACES = 8;
  public static calculate(factory: IUniqFactory, price: IMonetaryAmount): IRevenue {
    return InventoryPrice.revenueOf(factory.tradability.resellRevenueShare, price);
  }

  private static ratioOf(ratio: Decimal): number {
    return Number(ratio.toFixed(InventoryPrice.DECIMAL_PLACES, Decimal.ROUND_DOWN));
  }

  private static sharedRevenueOf(sharedRevenue: SharedRevenue) {
    return {
      amount: sharedRevenue.amount.toString(),
      ratio: InventoryPrice.ratioOf(sharedRevenue.ratio),
    };
  }

  private static revenueOf(resellRevenueShare: UniqRevenueShare[], price: any): IRevenue {
    const revenue = InventoryRevenues.revenueOf(
      resellRevenueShare.map((share) => share.percentage),
      new Decimal(price.amount)
    );

    return {
      amount: price.amount,
      currency: price.currency,
      platform: InventoryPrice.sharedRevenueOf(revenue.platform),
      creators: InventoryPrice.sharedRevenueOf(revenue.creators),
      owner: InventoryPrice.sharedRevenueOf(revenue.owner),
    };
  }
}

export class InventoryRevenues {
  private static readonly PLATFORM_SHARE_RATIO = new Decimal('2.5');
  private static readonly ZERO = new Decimal(0);
  private static readonly HUNDRED = new Decimal(100);

  private static sharedRevenueOf(price: Decimal, ratio: Decimal): SharedRevenue {
    return {
      amount: new Decimal(ratio.div(InventoryRevenues.HUNDRED).mul(price).toDecimalPlaces(DECIMAL_PRECISION)),
      ratio,
    };
  }

  private static decimalOf(value: number | Decimal): Decimal {
    if (Decimal.isDecimal(value)) {
      return value as Decimal;
    }
    return new Decimal(value);
  }

  static revenueOf(shares: readonly (number | Decimal)[], price: Decimal): Revenue {
    const baseRevenue = {
      platform: InventoryRevenues.sharedRevenueOf(price, InventoryRevenues.PLATFORM_SHARE_RATIO),
      creators: InventoryRevenues.sharedRevenueOf(
        price,
        shares.map(InventoryRevenues.decimalOf).reduce((sum, share) => sum.add(share), InventoryRevenues.ZERO)
      ),
    };
    return {
      ...baseRevenue,
      owner: {
        amount: Decimal.max(
          price.sub(baseRevenue.platform.amount).sub(baseRevenue.creators.amount),
          InventoryRevenues.ZERO
        ),
        ratio: Decimal.max(
          InventoryRevenues.HUNDRED.sub(baseRevenue.platform.ratio).sub(baseRevenue.creators.ratio),
          InventoryRevenues.ZERO
        ),
      },
    };
  }
}
