import { isPlatformBrowser } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import mixpanel, { Dict } from 'mixpanel-browser';

import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { MixpanelProperty } from './mixpanel-property';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private platformId = inject(PLATFORM_ID);
  readonly DISABLED: boolean = true;

  constructor(@Inject(APP_CONFIG) private env: IEnvironment) {
    if (env.mixpanelApiKey && isPlatformBrowser(this.platformId)) {
      this.DISABLED = false;
      // init mixpanel
      mixpanel.init(env.mixpanelApiKey);
      mixpanel.register({ [MixpanelProperty.USER_AGENT]: navigator.userAgent });
    }
  }

  /**
   * Identify a user and save user's data
   * @param identifier
   * @param userName
   * @param userEmail
   * @param blockchainId
   */
  login(identifier: string, userName: string, userEmail: string, blockchainId: string) {
    if (this.DISABLED) return;
    mixpanel.identify(identifier);
    // Set properties on a user record
    mixpanel.people.set({
      $name: userName,
      $email: userEmail,
      blockchainId,
    });
  }

  /**
   * Track an event
   * @param event
   * @param properties
   */
  track(event: string, properties?: Dict) {
    if (this.DISABLED) return;
    mixpanel.track(event, properties);
  }

  /**
   * Track an event and waits for the event to be sent to mixpanel server.
   *
   * Useful when you want to make sure the event is sent before navigating
   * @param event
   * @param properties
   * @param timeout - Timeout in milliseconds for waiting mixpanel request
   * @returns - Promise that resolves when mixpanel event is sent or times out
   */
  waitForTrack(event: string, properties?: Dict, timeout = 300): Promise<boolean> {
    if (this.DISABLED) return Promise.resolve(false);
    return new Promise((resolve) => {
      setTimeout(() => resolve(false), timeout);
      mixpanel.track(event, properties, { send_immediately: true }, () => resolve(true));
    });
  }

  /**
   * Increment/decrement numeric people analytics properties
   * @param property
   * @param value i.e. 1, 299, -1
   */
  peoplePropIncrement(property: string, value = 1) {
    if (this.DISABLED) return;
    mixpanel.people.increment(property, value);
  }
}
