import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClickListenerDirective } from './listeners/click-listener.directive';
import { HoverListenerDirective } from './listeners/hover-listener.directive';
import { OverlayEventBusService } from './overlay-event-bus.service';
import { OverlayHostDirective } from './overlay-host.directive';

const listeners = [HoverListenerDirective, ClickListenerDirective];

@NgModule({
  declarations: [OverlayHostDirective, ...listeners],
  imports: [CommonModule, OverlayModule],
  providers: [OverlayEventBusService],
  exports: [OverlayHostDirective, ...listeners],
})
export class UltraOverlayModule {}
