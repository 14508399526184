import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { take, tap } from 'rxjs';

import { AuthService } from '../auth/auth.service';

export const authGuard: CanActivateFn | CanActivateChildFn = (_route, state) => {
  const authService = inject(AuthService);
  return authService.isAuthenticated$.pipe(
    take(1),
    tap((isAuthenticated) => {
      if (!isAuthenticated) {
        authService.showLoginPage(state.url);
      }
    }),
  );
};
