<div class="container-oversize px-0">
  @if (uniqs || placeholderMode) {
    <div
      class="grid mx-auto"
      [class.grid--full-width]="containerFullWidth"
      [class.grid--hidden-placeholder]="hiddenPlaceholder"
      [class.grid--placeholder-row-mode]="placeholderRowMode">
      @if (!placeholderMode) {
        @for (item of uniqs; track trackBy(i, item); let i = $index) {
          <div
            class="list-item-holders"
            ultraAutotestAttribute="card-list-item-container"
            [ultraDomEventsBusClick]="{
              id: item.id,
              positionOnPage: i,
              type: item?.factory ? SearchInsightsObjectTypes.UNIQ : SearchInsightsObjectTypes.UNIQ_FACTORY,
              eventType: SearchInsightsClickEventTypes.CLICK_AFTER_SEARCH
            }">
            <ng-container
              *ngTemplateOutlet="
                template;
                context: {
                  $implicit: item
                }
              " />
          </div>
        }
      }
      @if (!isTotalResults || placeholderMode) {
        <div
          class="ultra-card-placeholder radius-4"
          [class.ultra-card-placeholder--with-height]="placeholderMode"></div>
        <div class="ultra-card-placeholder radius-4"></div>
        <div class="ultra-card-placeholder radius-4"></div>
        <div class="ultra-card-placeholder radius-4"></div>
        <div class="ultra-card-placeholder radius-4"></div>
        <div class="ultra-card-placeholder radius-4"></div>
      }
    </div>
  }
  @if (placeholderMode) {
    <div class="grid__placeholder-message">
      <ng-content />
    </div>
  }
</div>
