import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { BootstrapHelper } from '../../services/helpers/bootstrap.helper';

import { DialogRef } from './../dialog/providers/dialog-ref';

export class DetailDialog<T> {
  buy: Subject<any> = new Subject();

  constructor(public data: any, protected dialogRef: DialogRef<T>, protected ngbTooltipConfig: NgbTooltipConfig) {
    // NgbBootstrap provides global config for tooltips
    // which is used below to override the default strategy for the Popper.js positioning
    // that helps to fix the issue of tooltips not visible when modal dialog is shown
    // See: https://github.com/ng-bootstrap/ng-bootstrap/issues/4345
    this.ngbTooltipConfig.popperOptions = BootstrapHelper.getTooltipPopperOptions;
    this.ngbTooltipConfig.container = 'body';
  }

  onBuy(event = null) {
    this.buy.next(event || this.data.uniq);
  }

  close() {
    this.dialogRef.close();
  }
}
