import { Directive, HostListener, Input, Optional } from '@angular/core';

import { DomEvent } from './dom-events-bus.intaface';
import { DomEventsBusService } from './dom-events-bus.service';

@Directive({
  selector: '[ultraDomEventsBusClick]',
  standalone: true,
})
export class DomEventsBusClickDirective {
  @Input('ultraDomEventsBusClick') data: any;

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    if (this.domEventsBus) {
      this.domEventsBus.next(new DomEvent<any>(this.data, event));
    }
  }

  constructor(@Optional() public domEventsBus: DomEventsBusService) {}
}
