import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';

import { ImmutableSelector } from '@ultra/core/helpers';

import { Paginatable } from '../../models/base-interfaces/pagination.interface';
import { IUniq } from '../../models/uniq/interfaces/uniq.interface';

import * as OwnUniqActions from './ownUniq.actions';

const INITIAL_STATE: Paginatable<IUniq[]> = {
  data: [],
  totalCount: 0,
  pagination: { limit: 0, skip: 0 },
};

@State<Paginatable<IUniq[]>>({
  name: 'ownUniq',
  defaults: INITIAL_STATE,
})
@Injectable()
export class OwnUniqState {
  @Selector()
  @ImmutableSelector()
  public static getUniqList(state: Paginatable<IUniq[]>): IUniq[] {
    return state.data;
  }

  @Selector()
  @ImmutableSelector()
  public static getTotalCount(state: Paginatable<IUniq[]>): number {
    return state.totalCount;
  }

  @Action(OwnUniqActions.SetOwnUniqs)
  public setUniqs({ setState }: StateContext<Paginatable<IUniq[]>>, { payload }: OwnUniqActions.SetOwnUniqs) {
    setState(payload);
  }

  @Action(OwnUniqActions.UpdateUniqStatusSaleInfo)
  public updateUniqStatusSaleInfo(
    { setState }: StateContext<Paginatable<IUniq[]>>,
    { payload }: OwnUniqActions.UpdateUniqStatusSaleInfo,
  ) {
    setState(
      patch({
        data: updateItem(
          (item: IUniq) => item.id === payload.id,
          patch({ status: payload.status, saleInfo: payload.saleInfo }),
        ),
      }),
    );
  }
}
