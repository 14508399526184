import { DecimalPipe } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  input,
  OnInit,
  Optional,
  output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AppId } from '@ultra/core/models';
import { ClientFeatureService } from '@ultra/core/services';

import { IStoreTokenFactory } from '../../../models/token-factory';
import { DeepLinkService } from '../../../services/deeplink';
import { UniqFactoryAlgoliaService } from '../../../uniq-factory/algolia/uniq-factory-algolia.service';
import { IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';
import { DisplayPrices } from '../../../utils';
import { BtnSize } from '../../game/helpers/helper.enums';
import { IdCardEntity, IdCardPropertyTabs, UniqFactoryIdCardAdapter } from '../../id-card-content';
import { isPropertyLocked } from '../../id-card-content/id-card-actions/helpers/id-card-actions-display.helper';
import { TokenFactoryPreviewFacadeService } from '../token-factory-preview-facade.service';

type PriceTheme = 'purple' | 'transparent';
/**
 * A component that shows a card with Game Factory data.
 */
@UntilDestroy()
@Component({
  selector: 'ultra-preview-token-header',
  templateUrl: './preview-token-header.component.html',
  styleUrls: ['./preview-token-header.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewTokenHeaderComponent extends DisplayPrices implements OnInit {
  companyName = input<string>();
  gameName = input<string>();
  btnSize = input<BtnSize>(BtnSize.lg);
  tokenFactory = input.required<IStoreTokenFactory>();
  priceTheme = input<PriceTheme>('purple');
  isTokenFactoryAlreadyInLibrary = input<boolean, boolean>(false, { transform: booleanAttribute });
  /*
   * Used as false in refund-item
   */
  showPrice = input(true);
  /**
   * True when the token factory is not available for purchase
   *
   * Used for Exclusive Access.
   */
  buyingOptionRestricted = input<boolean, boolean>(false, { transform: booleanAttribute });

  priceButtonClicked = output<Event>();
  propertyAccess = output<IdCardPropertyTabs>();

  uniqFactoryIdCardEntity: IdCardEntity;

  @HostListener('click')
  previewClick() {
    this.propertyAccess.emit(IdCardPropertyTabs.OVERVIEW);
  }

  private cdr = inject(ChangeDetectorRef);
  clientFeatureService = inject(ClientFeatureService);
  private deepLinkService = inject(DeepLinkService);
  private uniqFactoryAlgoliaService = inject(UniqFactoryAlgoliaService);
  private uniqIdCardAdapter = new UniqFactoryIdCardAdapter();

  constructor(
    protected decimalPipe: DecimalPipe,
    @Optional() private tokenFactoryPreviewFacadeService: TokenFactoryPreviewFacadeService,
  ) {
    super(decimalPipe);
  }

  ngOnInit() {
    if (this.tokenFactory().onChainId) {
      this.uniqFactoryAlgoliaService
        .uniqFactoriesByIds$([`${this.tokenFactory().onChainId}`])
        .pipe(
          take(1),
          map((uniqFactory: IUniqFactory[]) => uniqFactory[0]),
          // algolia will not send info about restricted/invalid uniq factories
          filter(Boolean),
          map((uniqFactory: IUniqFactory) => this.uniqIdCardAdapter.transform(uniqFactory)),
        )
        .subscribe((res) => {
          this.uniqFactoryIdCardEntity = res;
          if (res.tradability.enabled && !isPropertyLocked(res.tradingPeriod?.resaleAvailability)) {
            this.tokenFactoryPreviewFacadeService.resalableTokenFactory.next(res);
          }
          this.cdr.detectChanges();
        });
    }
  }

  yourLibraryDeeplink(e) {
    e.stopPropagation();
    this.deepLinkService
      .openDeepLink(AppId.ULTRA_GAMES, `/your-library?q=${encodeURIComponent(this.gameName())}`, true)
      .subscribe();
  }
}
