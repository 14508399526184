import { ISystem, OSType } from '../interfaces/system.interface';

export const listOS: ISystem[] = [
  { name: 'windows', id: OSType.Windows, dataId: 'sys-req-win-tab' },
  { name: 'mac os', id: OSType.MacOS, dataId: 'sys-req-mac-tab' },
  { name: 'linux', id: OSType.Ubuntu, dataId: 'sys-req-linux-tab' },
];

export const listOSDisabled: ISystem[] = [
  { name: 'windows', id: OSType.Windows, disabled: false, dataId: 'sys-req-win-tab' },
  { name: 'mac os', id: OSType.MacOS, disabled: true, dataId: 'sys-req-mac-tab' },
  { name: 'linux', id: OSType.Ubuntu, disabled: true, dataId: 'sys-req-linux-tab' },
];
