import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconSize } from '@ultraos/design-system';

import { MetadataStatus } from '../../../uniq-factory/uniq-factory-metadata.interface';
import { InfoMessageDetailComponent } from '../info-message-detail/info-message-detail.component';

@Component({
  selector: 'ultra-metadata-information-manager',
  templateUrl: './metadata-information-manager.component.html',
  styleUrls: ['./metadata-information-manager.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InfoMessageDetailComponent],
})
export class MetadataInformationManagerComponent {
  @Input() size: IconSize = 24;
  @Input() metadataStatus: Exclude<MetadataStatus, MetadataStatus.VALID>;
  @Input() showInfoSeparation = false;
  @Input() shortMessage = false;
  @Input() shortMessageClassName = 'gap-8';
  @Input() messageWrapperClassName = 'white-space-nowrap';
  @Input() contactLinkOnNewLine = false;
  readonly metadataStatusEnum = MetadataStatus;
}
