export enum RefundUserReason {
  WONT_START = 'WONT_START',
  MULTIPLAYER_WONT_WORK = 'MULTIPLAYER_WONT_WORK',
  LOW_FRAMERATE = 'LOW_FRAMERATE',
  CRASHES = 'CRASHES',
  NO_MINIMUM_REQUIREMENTS = 'NO_MINIMUM_REQUIREMENTS',
  ACCIDENTAL_PURCHASE = 'ACCIDENTAL_PURCHASE',
  GAME_DISLIKED = 'GAME_DISLIKED',
  MISMATCHING_DESCRIPTION = 'MISMATCHING_DESCRIPTION',
  GAME_TOO_DIFFICULT = 'GAME_TOO_DIFFICULT',
  DOUBLE_CHARGE = 'DOUBLE_CHARGE',
  GAME_ALREADY_OWNED = 'GAME_ALREADY_OWNED',
  OTHER = 'OTHER',
}

export const REFUND_REASONS_LIST: { value: RefundUserReason; lbl: string }[] = [
  { value: RefundUserReason.WONT_START, lbl: 'The game won’t start' },
  { value: RefundUserReason.MULTIPLAYER_WONT_WORK, lbl: 'The multiplayer doesn’t work' },
  { value: RefundUserReason.LOW_FRAMERATE, lbl: 'The game framerate is too low' },
  { value: RefundUserReason.CRASHES, lbl: 'The game frequently crashes' },
  { value: RefundUserReason.NO_MINIMUM_REQUIREMENTS, lbl: 'My computer doesn’t meet the system requirements' },
  { value: RefundUserReason.ACCIDENTAL_PURCHASE, lbl: 'I purchased this game by accident' },
  { value: RefundUserReason.GAME_DISLIKED, lbl: 'I don’t like this game' },
  {
    value: RefundUserReason.MISMATCHING_DESCRIPTION,
    lbl: 'The game didn’t match its description, videos, or screenshots',
  },
  { value: RefundUserReason.GAME_TOO_DIFFICULT, lbl: 'The game is too difficult' },
  { value: RefundUserReason.DOUBLE_CHARGE, lbl: 'I’ve been charged twice for this game' },
  { value: RefundUserReason.GAME_ALREADY_OWNED, lbl: 'I already got this game' },
  { value: RefundUserReason.OTHER, lbl: 'My issue isn’t listed' },
];
