import { Pipe, PipeTransform } from '@angular/core';

import { isFree } from '../utils/isFree';

@Pipe({
  name: 'isFree',
  standalone: true,
})
export class isFreePipe implements PipeTransform {
  transform(price: unknown): boolean {
    return isFree(price);
  }
}
