import { gql } from 'apollo-angular';

export const CURRENCY_LIST_QUERY = gql`
  query {
    currencies {
      id
      name
      code
      uiSymbol
    }
  }
`;
