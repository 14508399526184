import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';

import { BackgroundSkeletonComponent } from '../background-skeleton/background-skeleton.component';
import { GallerySkeletonComponent } from '../gallery-skeleton/gallery-skeleton.component';

import { ContentSkeletonTheme } from './content-skeleton-theme.enum';

@Component({
  selector: 'ultra-content-skeleton',
  templateUrl: './content-skeleton.component.html',
  standalone: true,
  styles: [
    `
      :host {
        width: var(--content-skeleton-width);
        height: var(--content-skeleton-height);
        max-width: var(--content-skeleton-maxWidth);
      }
    `,
  ],
  styleUrls: ['./content-skeleton.component.scss'],
  imports: [BackgroundSkeletonComponent, GallerySkeletonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSkeletonComponent implements OnInit {
  availableThemes = ContentSkeletonTheme;
  isAnimated: boolean;

  private width: string;
  private height: string;
  private maxWidth: string;
  private className: string[] | null;
  private theme: ContentSkeletonTheme[] | null;

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.setHostInitialStyles();
    this.addHostStyles();
  }

  isGallerySkeleton(): boolean {
    return this.theme?.includes(this.availableThemes.GALLERY);
  }

  private addHostStyles(): void {
    if (this.className) {
      this.host.nativeElement.classList.add(...this.className);
    }

    if (this.theme) {
      this.host.nativeElement.classList.add(...this.theme);
    }

    if (this.isAnimated) {
      this.host.nativeElement.classList.add('animated');
    }
  }

  private setHostInitialStyles(): void {
    this.host.nativeElement.style.setProperty('--content-skeleton-width', this.width);
    this.host.nativeElement.style.setProperty('--content-skeleton-height', this.height);
    this.host.nativeElement.style.setProperty('--content-skeleton-maxWidth', this.maxWidth);
  }
}
