import { getEbaName, isThirdPartyCreator, isVerifiedCreator } from '../../../helpers';
import { ITradability, UniqTradability } from '../../../models';
import { IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';
import {
  MetadataStatus,
  UniqFactoryMetadataContent,
  UniqFactoryValidMetadata,
} from '../../../uniq-factory/uniq-factory-metadata.interface';

import { IdCardAdapter, IdCardEntity } from './id-card.adapter';

export class UniqFactoryIdCardAdapter extends IdCardAdapter<IUniqFactory> {
  transform(data: IUniqFactory): IdCardEntity {
    return {
      ...this.uniqFactoryMetadataToInfoAndMedias(data),
      type: 'uniqfactory',
      id: data.id,
      blockchainId: data?.onChainId,
      content: {
        contentType: data?.type,
        contentTypeCode: 'nft',
      },
      creator: {
        blockchainId: data?.creator?.blockchainId,
        name: data?.creator?.eba?.name || getEbaName(data?.creator?.blockchainId),
        verified: !!data?.creator?.verified || isVerifiedCreator(data?.creator?.blockchainId),
        isThirdPartyCreator: isThirdPartyCreator(data?.creator?.blockchainId),
      },
      tradability: data?.tradability ? this.uniqTradabilityToTradability(data.tradability) : null,
      status: data.status,
      geofencing: data?.geofencing,
      tradingPeriod: data?.tradability?.tradingPeriod,
      transferability: data?.transferability,
      transferPeriod: data?.transferability?.transferPeriod,
      quantity: {
        ...data?.quantity,
        quantityLeft:
          data?.quantity?.quantityLeft && Number(data.quantity.quantityLeft) < 0 ? '0' : data?.quantity?.quantityLeft,
        maxMintableTokens:
          data?.quantity?.maxMintableTokens && Number(data?.quantity?.maxMintableTokens) < 0
            ? '0'
            : data?.quantity?.maxMintableTokens,
        maxCirculatingSupply:
          data?.quantity?.maxCirculatingSupply && Number(data?.quantity?.maxCirculatingSupply) < 0
            ? '0'
            : data?.quantity?.maxCirculatingSupply,
      },
      prices: data?.prices,
      metadata: data?.metadata,
      defaultMetadata: data?.defaultUniqMetadata,
      isTokenized: true,
      onChainId: data?.onChainId,
      hidden: data.hidden,
    };
  }

  private uniqFactoryMetadataToInfoAndMedias(data: IUniqFactory): Partial<IdCardEntity> {
    let content: UniqFactoryMetadataContent | IUniqFactory = data;

    if (data.metadata?.status === MetadataStatus.VALID) {
      content = (data?.metadata as UniqFactoryValidMetadata)?.content;
    }

    const imageGalleryList = [content.medias?.product, ...(content?.medias?.gallery || [])].filter(Boolean);

    return {
      info: {
        title: content?.name === '???' ? `${data.onChainId}` : content?.name,
        subTitle: content?.subName === '???' ? 'Uniq ID' : content?.subName,
        description: content?.description,
        kind: 'Product',
      },
      medias: {
        image: content.medias?.product,
        square: content.medias?.square,
        backGroundImage: content?.medias?.hero,
        imageGalleryList,
      },
    };
  }

  private uniqTradabilityToTradability(data: UniqTradability): ITradability {
    return {
      ...data,
      minimumResellPrice: data?.minimumResellPrice?.amount,
      minimumResellPriceCurrency: data?.minimumResellPrice?.currency,
      resellRevenueShare: {
        percentage:
          data?.resellRevenueShare?.length &&
          UniqFactoryIdCardAdapter.reduceRevenueShareAmount(data?.resellRevenueShare),
        fixedAmount: null,
      },
    };
  }
}
