<div class="media-expanded-header d-flex" (click)="close()">
  <button type="button" class="close-button">
    <i class="icon icon-cross-24" (click)="close()"></i>
  </button>
</div>
<div class="slide-media-wrapper">
  <div
    class="media-container d-flex position-relative"
    [style.width]="mediaWidth"
    [style.height]="selectedMedia.hasOwnProperty('videoId') ? mediaHeight + 'px' : 'auto'">
    @if (!(medias.length <= 1)) {
      <button type="button" class="previous-button" (click)="changeMedia(-1)">
        <i class="icon icon-chevron"></i>
      </button>
    }
    @if (iVideoItems.length) {
      <ultra-youtube-expanded-videos
        [style.height.px]="mediaHeight"
        [videoWidth]="mediaWidth"
        [videoHeight]="mediaHeight"
        [videos]="iVideoItems"
        [selectedMedia]="selectedMedia" />
    }
    @if (iMediaItems.length) {
      <ultra-expanded-media-videos
        [style.height.px]="mediaHeight"
        [videoWidth]="mediaWidth"
        [videoHeight]="mediaHeight"
        [videos]="iMediaItems"
        [selectedMedia]="selectedMedia" />
    }
    @if (!selectedMedia.hasOwnProperty('videoId')) {
      @if (isImageContentType()) {
        <img [style.width.px]="mediaWidth" [src]="getImageMediaUrl()" />
      }
    }
    @if (!(medias.length <= 1)) {
      <button type="button" class="next-button" (click)="changeMedia(1)">
        <i class="icon icon-chevron reverse"></i>
      </button>
    }
  </div>
</div>
<div class="media-expanded-pagination w-100 d-flex">
  <ng-container *ngTemplateOutlet="paginationSide" />
  <div class="pagination-bullet-wrapper d-flex">
    @for (media of medias; track media) {
      <div class="pagination-bullet" [class.active]="selectedMedia === media" (click)="selectMedia(media)"></div>
    }
  </div>
  <ng-container *ngTemplateOutlet="paginationSide" />
</div>

<ng-template #paginationSide><div class="pagination-side" (click)="close()"></div></ng-template>
