export default {
  data: {
    languages: [
      {
        label: 'English',
        code: 'en',
      },
      {
        label: 'Simplified Chinese',
        code: 'zh',
      },
      {
        label: 'Japanese',
        code: 'ja',
      },
      {
        label: 'Traditional Chinese',
        code: 'zh_tw',
      },
      {
        label: 'German',
        code: 'de',
      },
      {
        label: 'French',
        code: 'fr',
      },
      {
        label: 'Danish',
        code: 'da',
      },
      {
        label: 'Bulgarian',
        code: 'bg',
      },
      {
        label: 'Czech',
        code: 'cs',
      },
      {
        label: 'Dutch',
        code: 'nl',
      },
      {
        label: 'Italian',
        code: 'it',
      },
      {
        label: 'Hungarian',
        code: 'hu',
      },
      {
        label: 'Finnish',
        code: 'fi',
      },
      {
        label: 'Greek',
        code: 'el',
      },
      {
        label: 'Korean',
        code: 'ko',
      },
      {
        label: 'Norwegian',
        code: 'no',
      },
      {
        label: 'Polish',
        code: 'pl',
      },
      {
        label: 'Portuguese',
        code: 'pt',
      },
      {
        label: 'Portuguese - Brazil',
        code: 'pt_br',
      },
      {
        label: 'Spanish - Latin America',
        code: 'es',
      },
      {
        label: 'Romanian',
        code: 'ro',
      },
      {
        label: 'Swedish',
        code: 'sv',
      },
      {
        label: 'Spanish - Spain',
        code: 'es_419',
      },
      {
        label: 'Russian',
        code: 'ru',
      },
      {
        label: 'Turkish',
        code: 'tr',
      },
      {
        label: 'Ukrainian',
        code: 'uk',
      },
      {
        label: 'Thai',
        code: 'th',
      },
      {
        label: 'Vietnamese',
        code: 'vi',
      },
    ],
  },
};
