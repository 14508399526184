import { inject, Injectable } from '@angular/core';

import { AuthService } from '@ultra/core/auth';
import { WINDOW } from '@ultra/core/providers';
import { ClientFeatureService } from '@ultra/core/services';

@Injectable({
  providedIn: 'root',
})
export class ShareUrlService {
  private authService = inject(AuthService);
  private clientFeatureService = inject(ClientFeatureService);
  private window: Window = inject(WINDOW);

  getSharableUrl(urlString = this.window.location.href): string {
    const url = new URL(urlString);
    this.setSource(url);
    this.setMedium(url);
    return url.href;
  }

  private setSource(url: URL) {
    url.searchParams.set('utm_source', 'share');
  }

  private setMedium(url: URL) {
    let medium: string;
    if (this.clientFeatureService.isInClient) {
      medium = 'user_client';
    } else if (this.authService.isAuthenticated()) {
      medium = 'user_web';
    } else {
      medium = 'user_anonymous';
    }
    url.searchParams.set('utm_medium', medium);
  }
}
