import { NgClass, NgStyle } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IUniqAvailability } from '../../../../../models';
import { CircleProgressModule } from '../../../../../modules/circle-progress/circle-progress.module';
import {
  ActionDisplayState,
  ActionStatus,
} from '../../../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { IdCardActionsDisplayHelper } from '../../helpers/id-card-actions-display.helper';

@Component({
  selector: 'ultra-actions-gauge-state',
  templateUrl: './actions-gauge-state.component.html',
  styleUrls: ['./actions-gauge-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, NgClass, CircleProgressModule],
  standalone: true,
})
export class ActionsGaugeStateComponent implements AfterContentInit {
  @Input() displayState: ActionDisplayState;
  @Input() availability: IUniqAvailability;

  displayGaugeState: boolean;

  readonly actionStatuses = ActionStatus;
  readonly displayHelper = IdCardActionsDisplayHelper;

  ngAfterContentInit() {
    this.displayGaugeState =
      this.availability?.isLocked || [this.actionStatuses.TILL].includes(this.displayState.status);
  }
}
