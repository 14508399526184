<div class="container-fluid main-container container-layout">
  <div class="row cols">
    <div class="col-6 col-left d-flex flex-column border-end">
      @for (dimension of dimensions$ | async; track dimension) {
        <ultra-dimension [dimension]="dimension" (activateDimension)="onSetActiveDimension($event)"> </ultra-dimension>
      }
    </div>
    <div class="col-6 col-right d-flex flex-column">
      @for (app of apps$ | async; track app) {
        <ultra-universe-nav-item
          [app]="app"
          class="d-flex"
          (activeApp)="onSetActiveApp($event)"></ultra-universe-nav-item>
      }
    </div>
  </div>
  <!-- Hidden Deeplink Authentication frame -->
  @if (safeAuthDeeplinkUrl$ | async; as safeAuthDeeplinkUrl) {
    <iframe class="d-none" [src]="safeAuthDeeplinkUrl"></iframe>
  }
</div>
