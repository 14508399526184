import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { AppId } from '@ultra/core/models';

import { DeepLinkService } from '../../../services/deeplink';
import { IdCardEntity } from '../adapters/id-card.adapter';

export interface IdCardContentHeaderConfig {
  hideCopyToClipboard?: boolean;
  hideCloseIcon?: boolean;
  hideAdvancedDetails?: boolean;
  iconType?: string;
}
/**
 * A dumb component that shows the header part of a product card.
 * It can be configurable through the `config` property.
 */
@Component({
  selector: 'ultra-id-card-content-header',
  templateUrl: './id-card-content-header.component.html',
  styleUrls: ['./id-card-content-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardContentHeaderComponent {
  private deepLinkService = inject(DeepLinkService);
  productUrl: string;
  @Input() set idCardEntity(idCardEntity: IdCardEntity) {
    this._idCardEntity = idCardEntity;
    if (idCardEntity) {
      this.productUrl = this.deepLinkService.buildProxyDeeplinkURL(
        AppId.ULTRA_UNIQ_MARKETPLACE,
        `product/${idCardEntity.type}/${idCardEntity.blockchainId}`
      );
    }
  }
  get idCardEntity(): IdCardEntity {
    return this._idCardEntity;
  }
  private _idCardEntity: IdCardEntity;

  @Input() companyName: string;

  @Input() config: IdCardContentHeaderConfig = {
    hideCopyToClipboard: false,
    hideCloseIcon: false,
    hideAdvancedDetails: true,
    iconType: undefined,
  };

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  readonly ellipsisLimitChars = 32;
}
