import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LanguageFormatterPipe } from './languages.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LanguageFormatterPipe],
  exports: [LanguageFormatterPipe],
})
export class SharedLanguagePipeModule {}
