<div
  class="tag-input"
  [class.invalid]="isInvalid"
  [class.focused]="isFocused"
  ultraAutotestAttribute="tag-input-container">
  @for (tag of displayTags; track tag; let i = $index) {
    <ultra-tag
      [model]="tag"
      [displayBy]="displayBy"
      [identifyBy]="identifyBy"
      (removed)="remove($event, i)"></ultra-tag>
  }

  @if (!maxItemsReached) {
    <input
      #input
      type="text"
      class="tag-input-control"
      [formControl]="tagControl"
      [ngClass]="inputClass"
      [attr.id]="inputId"
      [attr.placeholder]="placeHolder"
      [attr.aria-label]="placeHolder"
      [attr.disabled]="inputDisabled || null"
      (keydown.enter)="create($event)" />
  }

  <span class="tags-left">
    {{ leftCount }}
    <ng-container i18n>Left</ng-container>
  </span>
</div>

<p class="error-message">
  <ng-content></ng-content>
</p>
