import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { AutotestAttributeDirective } from '../..';
import { isFreePipe } from '../is-free.pipe';
import { UniqPricePipe } from '../uniq-price.pipe';

@Component({
  selector: 'ultra-uniq-price',
  templateUrl: './uniq-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UniqPricePipe, AutotestAttributeDirective, isFreePipe, AsyncPipe, DecimalPipe],
})
export class UniqPriceComponent {
  type = input<'tokenfactory' | 'uniq' | 'uniqfactory'>();
  price = input<string>();
  symbol = input<string>();
  minResellPrice = input<string>();
  minResellPriceCurrency = input<string>();
}
