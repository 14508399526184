import { gql } from 'apollo-angular';

import {
  BUILDS_GQL,
  COMPANY,
  GAME_VERSION_GQL,
  GDC_IMAGE_GQL,
  GDC_IMAGE_WITH_ORDER_GQL,
  IMAGE_DETAIL_GQL,
  IMAGE_GQL,
} from '../../shared/queries/shared.query';

export const ADD_GAME_TO_WISHLIST = gql`
  mutation addGameToWishList($gameId: OID!) {
    userGameAccount {
      addGameToWishList(gameId: $gameId) {
        gamesIdsWishlist
      }
    }
  }
`;

export const REMOVE_GAME_FROM_WISHLIST = gql`
  mutation removeGameFromWishList($gameId: OID!) {
    userGameAccount {
      removeGameFromWishList(gameId: $gameId) {
        gamesIdsWishlist
      }
    }
  }
`;

export const CREATE_GAME = gql`
  mutation saveGame($game: GameToSave) {
    saveGame(game: $game) {
      id
      publisher ${COMPANY}
      developmentGame {
        title
      }
    }
  }
`;

export const UPDATE_GAME = gql`
  mutation patchGame($id: String!, $game: GameToPatch!) {
    patchGame(id: $id, game: $game) {
      id
      developmentGame {
        title
      }
    }
  }
`;

export const UPDATE_GAME_ASSETS = gql`
  mutation patchGame($id: String!, $game: GameToPatch!) {
    patchGame(id: $id, game: $game) {
      id
      developmentGame {
        multimedia {
          boxArtImage ${IMAGE_GQL}
          imageGalleryList ${GDC_IMAGE_GQL}
          largeHeroImage ${IMAGE_GQL}
          primaryGameImage ${IMAGE_GQL}
          secondaryGameImage ${IMAGE_GQL}
          gameIcon ${IMAGE_GQL}
      }
      }
    }
  }
`;

export const UPDATE_GAME_ABOUT = gql`
    mutation patchGame($id: String!, $game: GameToPatch!) {
        patchGame(id: $id, game: $game) {
            developmentGame {
                description
                descriptionShort
                multimedia {
                    imageGalleryList ${GDC_IMAGE_WITH_ORDER_GQL}
                     videosPreviews {
                      videoId
                      provider
                      duration
                      title
                      displayOrder
                      image {
                        name
                        fileExtension
                        base ${IMAGE_DETAIL_GQL}
                      }
                    }
                }
            }
        }
    }
`;

export const UPDATE_GAME_MAIN_INFO = gql`
  mutation patchGame($id: String!, $game: GameToPatch!) {
    patchGame(id: $id, game: $game) {
      id
      developmentGame {
        title
        releaseDate {
          year
          month
          day
        }
        editorName
        developerName
        categories
        tags
      }
    }
  }
`;

export const UPDATE_GAME_RATINGS_AWARDS = gql`
  mutation patchGame($id: String!, $game: GameToPatch!) {
    patchGame(id: $id, game: $game) {
      id
      developmentGame {
        rating {
          metascore {
            value
            totalNumberOfRatings
          }
          metaCriticsUserScore {
            value
            totalNumberOfRatings
          }
          awards {
            image ${GDC_IMAGE_GQL}
            name
            category
            link
          }
        }
      }
    }
  }
`;

export const UPDATE_GAME_DETAIL = gql`
  mutation patchGame($id: String!, $game: GameToPatch!) {
    patchGame(id: $id, game: $game) {
      id
      developmentGame {
        languages {
          audio
          subtitles
          interfaces
        }
        links {
          name
          url
        }
        features
        playingModes
        ageRating {
          ageRatingId
          country
          name
          category {
            categoryId
            name
            image
          }
          descriptors {
            descriptorId
            name
            image
          }
          descriptorsDescription
        }
        systemsRequirements {
          osName
          minimum {
            os
            processor
            memory
            graphics
            storage
            soundCard
            minimumResolution
          }
          recommended {
            os
            processor
            memory
            graphics
            storage
            soundCard
            minimumResolution
          }
        }
      }
    }
  }
`;

export const PUBLISH_GAME_STORE = gql`
  mutation publishGameStore($id: String!) {
    publishGameStore(id: $id) {
      id
      storePageStatus
      liveGame ${GAME_VERSION_GQL}
    }
  }
`;

export const PUBLISH_GAME_FILE_MANAGEMENT = gql`
  mutation publishGameFileManagement($id: String!) {
    publishGameFileManagement(id: $id) {
      id
      liveGame ${GAME_VERSION_GQL}
    }
  }
`;

export const SAVE_BUILD = gql`
  mutation addBuild($id: String!, $build: BuildDataToSave!) {
    addBuild(id: $id, build: $build) {
      id
      developmentGame {
        ${BUILDS_GQL}
      }
      liveGame {
        ${BUILDS_GQL}
      }
    }
  }
`;

export const SAVE_ASSEMBLY_BUILD = gql`
  mutation addAssembly($gameId: String!, $buildId: String!, $assembly: AssemblyToSave!) {
    addAssembly(gameId: $gameId, buildId: $buildId, assembly: $assembly) {
      id
      developmentGame {
        ${BUILDS_GQL}
      }
      liveGame {
        ${BUILDS_GQL}
      }
    }
  }
`;

export const PATCH_BUILD = gql`
  mutation patchBuild($gameId: String!, $builds: [BuildToPatch!]!) {
    patchBuild(gameId: $gameId, builds: $builds) {
      id
      developmentGame {
        ${BUILDS_GQL}
      }
    }
  }
`;

export const PATCH_ASSEMBLY_BUILD = gql`
  mutation patchAssembly($gameId: String!, $buildId: String!, $assemblies: [AssemblyToPatch!]!) {
    patchAssembly(gameId: $gameId, buildId: $buildId, assemblies: $assemblies) {
      id
      developmentGame {
        ${BUILDS_GQL}
      }
    }
  }
`;

export const DISABLE_GAME_FROM_STORE = gql`
  mutation disableStorePage($gameId: ID!, $comment: String!) {
    disableStorePage(gameId: $gameId, comment: $comment) {
      id
      lastStorePageEditDate
      storePageStatus
      lastStorePageStatusEditDate
      lastStorePageStatusEditComment
      lastStorePageStatusChangeDate
    }
  }
`;

export const ENABLE_GAME_IN_STORE = gql`
  mutation enableStorePage($gameId: ID!) {
    enableStorePage(gameId: $gameId) {
      id
      lastStorePageEditDate
      storePageStatus
      lastStorePageStatusEditDate
      lastStorePageStatusEditComment
      lastStorePageStatusChangeDate
    }
  }
`;

export const SET_RECOMMENDED_UNIQS = gql`
  mutation gamePatch($id: GameId!, $game: GamePatchInput!) {
    gamePatch(id: $id, game: $game)
  }
`;
