import { RefundOrigin } from '../enums/refund-origin.enum';
import { RefundUserReason } from '../enums/refund-reasons.helpers';
import { RefundStatus } from '../enums/request-status.enum';
import { IRefund } from '../interfaces/refund.interface';

export const refundMock: IRefund = {
  id: '5ff49f387516ba3eb2949a32',
  refundNumber: 'O7LAQUONU1UHD249',
  itemOrderPricing: {
    grossPrice: {
      amount: 8.47,
    },
  },
  itemStorePricing: {
    grossPrice: {
      amount: 8.47,
    },
  },
  order: {
    currency: 'UOS',
  },
  status: RefundStatus.REQUESTED,
  exchangeRate: 100,
  reason: RefundUserReason.MULTIPLAYER_WONT_WORK,
  createdAt: new Date('2021-01-05T17:17:44.321Z'),
  updatedAt: new Date('2021-01-05T17:17:44.321Z'),
  userId: '5ff387661e2b83d25949199a',
  origin: RefundOrigin.MASTER_CENTER,
  userFeedback: '',
  refundPayment: {
    amount: {
      amount: 100,
    },
    refundedBy: 'Joe',
    refundAskedAt: new Date('2021-01-05T17:17:44.321Z'),
    refundProcessedAt: new Date('2021-01-05T17:17:44.321Z'),
  },
  productRemovalStatus: {
    deletedBy: 'Joe',
    deletedAt: new Date('2021-01-05T17:17:44.321Z'),
    isDeleted: true,
  },
};
