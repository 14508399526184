import { IMultimedia } from '../../multimedia';
import { IAgeRatingData } from '../data';
import {
  IBuild,
  IGameVersion,
  ILanguage,
  ILastUpdate,
  ILink,
  IRating,
  IRecommendedUniqs,
  IReleaseDate,
  ISystemRequirement,
} from '../interfaces';

export class GameVersionModel implements IGameVersion {
  title: string;
  description?: string;
  descriptionShort?: string;
  languages?: ILanguage;
  categories?: string[];
  developerName?: string;
  editorName?: string;
  releaseDate?: IReleaseDate;
  systemsRequirements?: ISystemRequirement[];
  multimedia?: IMultimedia;
  tags?: string[];
  rating?: IRating;
  links?: ILink[];
  lastUpdate?: ILastUpdate;
  playingModes?: string[];
  features?: string[];
  versionNumber?: string;
  ageRating?: IAgeRatingData[];
  builds: IBuild[];
  recommendedUniqs?: IRecommendedUniqs;

  constructor(gameVersion: IGameVersion = {}) {
    this.title = gameVersion.title || null;
    this.description = gameVersion.description || null;
    this.descriptionShort = gameVersion.descriptionShort || null;
    this.categories = gameVersion.categories || [];
    this.developerName = gameVersion.developerName || null;
    this.editorName = gameVersion.editorName || null;
    this.releaseDate = gameVersion.releaseDate || null;
    this.systemsRequirements = gameVersion.systemsRequirements || [];
    this.tags = gameVersion.tags || [];
    this.links = gameVersion.links || [];
    this.lastUpdate = gameVersion.lastUpdate || null;
    this.playingModes = gameVersion.playingModes || [];
    this.features = gameVersion.features || [];
    this.versionNumber = gameVersion.versionNumber || null;
    this.ageRating = gameVersion.ageRating || [];
    this.builds = gameVersion.builds || [];
    this.recommendedUniqs = gameVersion.recommendedUniqs;

    this.initRating(gameVersion.rating);
    this.initMultimedia(gameVersion.multimedia);
    this.initLanguages(gameVersion.languages);
  }

  private initRating(rating: IRating) {
    this.rating = {
      metascore: (rating && rating.metascore) || null,
      metaCriticsUserScore: (rating && rating.metaCriticsUserScore) || null,
      awards: (rating && rating.awards) || [],
    };
  }

  private initMultimedia(multimedia: IMultimedia) {
    this.multimedia = {
      boxArtImage: (multimedia && multimedia.boxArtImage) || null,
      largeHeroImage: (multimedia && multimedia.largeHeroImage) || null,
      primaryGameImage: (multimedia && multimedia.primaryGameImage) || null,
      secondaryGameImage: (multimedia && multimedia.secondaryGameImage) || null,
      gameIcon: (multimedia && multimedia.gameIcon) || null,
      imageGalleryList: (multimedia && multimedia.imageGalleryList) || [],
      image: (multimedia && multimedia.image) || null,
      videosPreviews: (multimedia && multimedia.videosPreviews) || [],
    };
  }

  private initLanguages(languages: ILanguage) {
    this.languages = {
      audio: (languages && languages.audio) || [],
      subtitles: (languages && languages.subtitles) || [],
      interfaces: (languages && languages.interfaces) || [],
    };
  }
}
