import { Inject, Injectable } from '@angular/core';
import { fromEvent, Subject, switchMap, take, tap, timeout, timer } from 'rxjs';

import { AppId } from '@ultra/core/models';
import { WINDOW } from '@ultra/core/providers';
import { UltraosApiService } from '@ultra/core/services';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { AppDeepLinkTarget, DeepLinkKind } from './deeplink.interface';

@Injectable({
  providedIn: 'root',
})
export class DeepLinkService {
  static readonly allowedApps = [
    AppId.ULTRA_UNIQ_MARKETPLACE,
    AppId.ULTRA_WALLET,
    AppId.ULTRA_GAMES,
    AppId.ULTRA_ARENA,
  ];
  private authenticationDeepLinkUrlSubject$ = new Subject<string>();
  private applicationDeepLinkSubject$ = new Subject<AppDeepLinkTarget>();
  authenticationDeepLinkUrl$ = this.authenticationDeepLinkUrlSubject$.asObservable();
  applicationDeepLink$ = this.applicationDeepLinkSubject$.asObservable();
  constructor(
    private ultraosApiService: UltraosApiService,
    @Inject(APP_CONFIG) private readonly environment: IEnvironment,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    if (ultraosApiService.clientLayoutAvailable()) {
      /**
       * onCommandLineAddListener is not ready on the Client on page load
       * This timer is a workaround to delay the subscription
       */
      timer(1000)
        .pipe(
          switchMap(() =>
            this.ultraosApiService
              .onCommandLineAddListener()
              .pipe(tap((cmdValue) => this.processDeeplinkData(cmdValue))),
          ),
        )
        .subscribe();
    }
  }

  processDeeplinkData(deeplinkUrl: string): void {
    const urlRegExp = new RegExp(
      `${this.environment.clientDeeplinkProtocol}(?<kind>${DeepLinkKind.APPLICATION}|${DeepLinkKind.AUTHENTICATION})(?<content>.*)`,
      'i',
    );
    const deepLinkMatches = urlRegExp.exec(deeplinkUrl);
    if (deepLinkMatches) {
      switch (deepLinkMatches.groups.kind as DeepLinkKind) {
        case DeepLinkKind.APPLICATION: {
          const appRegExp = new RegExp(`/(?<appId>[^/]+)/?(?<path>.*)`, 'i');
          const appMatches = appRegExp.exec(deepLinkMatches.groups.content);
          const appId = appMatches.groups.appId as AppId;
          if (appMatches && this.isAppDeeplinkAllowed(appId)) {
            const appDeepLink: AppDeepLinkTarget = {
              appId,
              path: appMatches.groups.path,
            };
            this.applicationDeepLinkSubject$.next(appDeepLink);
          }
          break;
        }
        case DeepLinkKind.AUTHENTICATION: {
          const authRegExp = new RegExp(`\\?url=${this.environment.oauthIssuer}(?<path>.*)`, 'i');
          const authMatches = authRegExp.exec(deepLinkMatches.groups.content);
          if (authMatches) {
            this.authenticationDeepLinkUrlSubject$.next(`${this.environment.oauthIssuer}${authMatches.groups.path}`);
          }
          break;
        }
        default:
          console.error(`Unsupported deeplink: ${deeplinkUrl}`);
          break;
      }
    }
  }

  /**
   * Builds a sharable URL to an application resource
   * The generated link allows to trigger a deeplink through the ultra.io website (websiteRedirectUrl)
   * @param {AppId} appId
   * @param {string} targetPath
   * @returns {string} sharable URL
   */
  buildProxyDeeplinkURL(appId: AppId, targetPath: string): string {
    const url = new URL(`${this.environment.linkUrl}/${DeepLinkKind.APPLICATION}/${appId}/${targetPath}`);
    return url.href;
  }

  /**
   * Builds a deeplink that can opens the desktop client
   *   <schema>://apps/<app>/<path>
   *   example: ultra://apps/games/store/games/mars4/63c8f35ff837abdb845dfee4/
   * @param appId
   * @param targetPath
   */
  buildDeeplinkURL(appId: AppId, targetPath: string): string {
    if (targetPath?.[0] === '/') {
      targetPath = targetPath.slice(1);
    }
    const url = new URL(`${this.environment.clientDeeplinkProtocol}${DeepLinkKind.APPLICATION}/${appId}/${targetPath}`);
    return url.href;
  }

  /**
   * Opens the client using a deeplink. if the client is not open within 1 second throws an error.
   * Relies on 'blur' event to determine if the client opens
   * @param appId
   * @param targetPath
   * @param showDownloadPageOnError
   */
  openDeepLink(appId: AppId, targetPath: string, showDownloadPageOnError = false) {
    const url = this.buildDeeplinkURL(appId, targetPath);
    this.window.location.assign(url);

    return fromEvent(this.window, 'blur').pipe(
      timeout(1000),
      tap({
        error: () => {
          if (showDownloadPageOnError) {
            this.window.location.assign(`${this.environment.websiteRedirectUrl}/download`);
          }
        },
      }),
      take(1),
    );
  }

  private isAppDeeplinkAllowed(appId: AppId): boolean {
    return DeepLinkService.allowedApps.includes(appId);
  }
}
