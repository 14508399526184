import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppId } from '@ultra/core/models';
import { WINDOW } from '@ultra/core/providers';
import { ClientFeatureService } from '@ultra/core/services';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { DeepLinkService } from '../deeplink';

export const APPS_PATH = 'apps';
/***
 * A service to handle routing between apps.
 * It handles both client and web routing
 *
 * -------------------------------
 * | In browser navigation        |
 * | In client navigation         |
 * | Browser -> Client (deeplink) |
 * -------------------------------
 *
 * Custom services should extend this service with custom routes.
 *
 * @example
 * ```
 * export const UNIQ_FACTORY_PAGE_PATH = `/product/uniqfactory`;
 *
 * @Injectable({ providedIn: 'root' })
 * export class UniqFactoryRouteService extends RouteService {
 *   constructor() {
 *     super();
 *   }
 *
 *   openUniqFactoryPage(uniqFactoryId: string, deeplink = false) {
 *     const uniqFactoryPagePath = `${UNIQ_FACTORY_PAGE_PATH}/${uniqFactoryId}`;
 *     this.goToPage(AppId.ULTRA_UNIQ_MARKETPLACE, uniqFactoryPagePath, deeplink);
 *   }
 * }
 * ```
 */
export class RouteService {
  protected environment: IEnvironment = inject(APP_CONFIG);
  protected window: Window = inject(WINDOW);
  protected router: Router = inject(Router);
  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  protected appId = AppId.ULTRA_GAMES;
  protected deepLinkService = inject(DeepLinkService);
  private clientFeatureService = inject(ClientFeatureService);

  protected isInClient = this.clientFeatureService.isInClient;

  /**
   * Use this method from the services that extends the `RouteService`
   * IMPORTANT: Do not use it directly from this service since it will always use
   * the default value (GAME STORE).
   * Each service should define the AppId where it belongs to.
   */
  navigateNotFoundPage() {
    return this.goToApp(this.appId, '404');
  }

  protected getAppRootPathByAppId(appId: AppId): string {
    switch (appId) {
      case AppId.ULTRA_UNIQ_MARKETPLACE:
        return this.environment.marketplaceUrl;
      case AppId.ULTRA_WALLET:
        return this.environment.walletUrl;
      case AppId.ULTRA_GAMES:
        return this.environment.storeUrl;
    }
  }

  protected getAppIdByPath(path: string): AppId {
    if (path.includes(this.environment.marketplaceUrl)) {
      return AppId.ULTRA_UNIQ_MARKETPLACE;
    } else if (path.includes(this.environment.walletUrl)) {
      return AppId.ULTRA_WALLET;
    } else if (path.includes(this.environment.storeUrl)) {
      return AppId.ULTRA_GAMES;
    }
  }

  protected goToApp(appId = this.appId, subPath: string, deeplink = false) {
    let url = `${this.getAppRootPathByAppId(appId)}/${subPath}`;
    if (this.isInClient) {
      if (this.clientFeatureService.isElectronClient) {
        return this.clientFeatureService.navigateToApp(url);
      } else {
        url = `${this.environment.clientDeeplinkProtocol}${APPS_PATH}/${appId}/${subPath}`;
      }
    } else if (deeplink) {
      url = this.deepLinkService.buildProxyDeeplinkURL(appId, subPath);
    }
    this.navigate(url);
  }

  /**
   * Checks if the current URL is from the same app.
   *
   * Useful to decide if the navigation should be done using the Angular router or
   * using the `goToApp`.
   */
  protected isSameApp(): boolean {
    return this.appId === this.getAppIdByPath(this.window.location.href);
  }

  protected navigate(url: string) {
    this.window.location.assign(url);
  }
}
