export enum ApolloClientName {
  TOKEN_FACTORIES = 'tokenfactories',
  COMPANIES = 'companies',
  USERS = 'users',
  REFERENTIAL = 'referential',
  PERMISSION = 'permission',
  ORDER = 'order',
  EXCHANGE = 'exchange',
  PAYMENT = 'payment',
}
