import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { OverlayEvent } from './overlay-event';

@Injectable()
export class OverlayEventBusService extends Subject<OverlayEvent> {
  constructor() {
    super();
  }
}
