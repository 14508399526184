import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import merge from 'lodash-es/merge';
import { Subject } from 'rxjs';
import SwiperCore, { Autoplay, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { PaginationOptions } from 'swiper/types';
/**
 * Install Swiper modules to use
 */
SwiperCore.use([Navigation, Pagination, Autoplay]);
/**
 * A service to keep track the state of the slider
 * It encapsulates the Swiper API
 */
@UntilDestroy()
@Injectable()
export class SwiperService {
  private _swiper: SwiperComponent;
  private _swiperRef: SwiperCore;
  private _swiperConfig: SwiperOptions;

  public s_slideChangeTransitionEnd$: Subject<void> = new Subject();

  public init(swiper: SwiperComponent, swiperConfig: SwiperOptions) {
    this.swiper = swiper;
    this.swiperRef = this.swiper.swiperRef;
    this.updateSwiperConfig(swiperConfig);

    this.swiper.s_slideChangeTransitionEnd.pipe(untilDestroyed(this)).subscribe(() => {
      /*
      When "loop" is enabled some slides are cloned, those slides are not attached to angular
      so we want to move away of cloned slides as soon as transition ends
	  */
      if (this.swiperRef.params.loop) {
        this.goToSlide(this.swiperRef.realIndex, 1, false);
      }
    });
  }

  public updateSwiperConfig(config) {
    this.swiperConfig = merge({}, this.swiperConfig, config);
    this.update();
  }

  private set swiper(swiper: SwiperComponent) {
    this._swiper = swiper;
  }

  public get swiper(): SwiperComponent {
    return this._swiper;
  }

  public set swiperConfig(swiperConfig: SwiperOptions) {
    this._swiperConfig = swiperConfig;
  }

  public get swiperConfig(): SwiperOptions {
    return this._swiperConfig;
  }

  public set swiperRef(swiperRef: SwiperCore) {
    this._swiperRef = swiperRef;
  }

  public get swiperRef() {
    return this._swiperRef;
  }

  public get isPaginationAvailable() {
    return typeof this.swiperConfig.pagination === 'object';
  }

  public setPaginationElement(element) {
    const swiperConfig = this.swiperConfig;
    swiperConfig.pagination = {
      el: element,
      dynamicBullets: false,
    };
    this.swiper.updateParameter('pagination', swiperConfig.pagination);
    this.swiper.swiperRef.update();
  }

  public togglePaginationBullets(paginationBullets) {
    const swiperConfig = this.swiperConfig;
    (swiperConfig.pagination as PaginationOptions).dynamicBullets = paginationBullets;
    this.updateSwiperConfig(swiperConfig);
  }

  public updateSlideNextClass(className) {
    const swiperConfig = this.swiperConfig;
    swiperConfig.slideNextClass = className;
    this.updateSwiperConfig(swiperConfig);
  }

  public update() {
    this.swiperRef.update();
  }

  public startAutoplay() {
    this.swiperRef.autoplay.start();
  }

  public stopAutoplay() {
    this.swiperRef.autoplay.stop();
  }

  public nextSlide() {
    this.swiperRef.slideNext();
  }

  public prevSlide() {
    this.swiperRef.slidePrev();
  }

  public goToSlide(index: number, transitionMs = 300, triggerEvents = true) {
    this.swiperRef.slideToLoop(index, transitionMs, triggerEvents);
  }

  /**
   * Dom7 array-like collection of slides HTML elements.
   */
  public get slidesLength() {
    return this.swiperRef.slides?.length;
  }
  /**
   * If you use `slidesPerView:'auto'`with loop mode you should tell to Swiper
   * how many slides it should loop (duplicate) using this parameter.
   */
  public get loopedSlides() {
    return this.swiperRef.loopedSlides || 0;
  }

  public get hasOnePage() {
    return this.slidesLength - this.loopedSlides * 2 <= Number(this.swiperRef.params?.slidesPerView);
  }
  /**
   * `true` if slider on most "left"/"top" position
   */
  public get isBeginning() {
    return this.swiperRef.isBeginning;
  }
  /**
   * `true` if slider on most "right"/"bottom" position
   */
  public get isEnd() {
    return this.swiperRef.isEnd;
  }

  public loopDestroy() {
    this.swiperRef.loopDestroy();
  }

  public loopCreate() {
    this.swiperRef.loopCreate();
  }
}
