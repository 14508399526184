import { Directive, HostBinding, Input } from '@angular/core';

export type IButtonColor =
  | 'transparent-light'
  | 'ultra-purple'
  | 'transparent-ultra-purple'
  | 'transparent-dark'
  | 'transparent-grey'
  | 'red';
export type IButtonStyled = 'fill' | 'transparent' | 'stroke';

@Directive({
  selector: '[ultraButton]',
})
export class ButtonDirective {
  @Input() color: IButtonColor = 'ultra-purple';
  @Input() styled: IButtonStyled = 'fill';
  @Input() shaped = 'default';
  @Input() size = 'standard';
  @Input() disabled = false;

  @HostBinding('class') get Classes() {
    return [
      // 'btn', // to test // remove after rework
      'ultra-btn',
      'button-color--' + this.color,
      'button-styled--' + this.styled,
      'button-shaped--' + this.shaped,
      'button-size--' + this.size,
      this.disabled == true ? 'disabled' : null,
    ];
  }
}
