import { OsPlatformType } from '../helpers/build.helper';
import { RepositoryType } from '../helpers/repository.helper';
import { IRepository } from '../interfaces/repository.interface';

export const repositoryMock: IRepository = {
  id: 'id',
  gameId: 'gameId',
  publisherId: 'publisherId',
  ssnRepositoryName: '123',
  osPlatform: OsPlatformType.Windows,
  repositoryType: RepositoryType.COREG,
  repositoryVersions: [
    {
      id: 'id',
      userId: 'userId',
      version: 1,
      ssnReleaseId: 'ssnId',
      isProtected: false,
    },
  ],
  name: 'core game repository',
};
