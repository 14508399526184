import { gql } from 'apollo-angular';

export const EDIT_USER = gql`
  mutation user($userId: String!, $user: UserInput!) {
    user {
      edit(userId: $userId, user: $user) {
        username
        personalData {
          firstname
          lastname
          email
          countryCode
          addresses {
            detail {
              streetName
              streetName2
              state
              zipCode
              country
              city
            }
          }
          billingInformation {
            id
            address {
              streetName
              streetName2
              state
              zipCode
              city
            }
            paymentInformation {
              paymentMethod
            }
            isPrimary
          }
        }
      }
    }
  }
`;

export const ADD_USER_DEVICE = gql`
  mutation addNewUserDevice($newUserDevice: NewUserDeviceInput!) {
    addNewUserDevice(newUserDevice: $newUserDevice) {
      id
      username
      blockchainId
      blockchainStatus
      status
      roles
      devices {
        deviceId
        ultraHalfVaultKey
      }
    }
  }
`;

export const ACTIVATE_USER_DEVICE = gql`
  mutation activateUserDevice($deviceId: String!) {
    activateUserDevice(deviceId: $deviceId) {
      deviceId
      ultraHalfVaultKey
    }
  }
`;
