import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { take, throttleTime } from 'rxjs/operators';

import { MixpanelEvent, MixpanelService } from '@ultra/core/services';

import { getUniqEventData } from '../../../helpers/mixpanel-uniq-data.helper';
import { IUniq, UniqStatus } from '../../../models/uniq/interfaces/uniq.interface';
import { IdCardAction } from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { UniqPriceService } from '../../../uniq/uniq-price.service';
import { IUniqFactory } from '../../../uniq-factory/uniq-factory.interface';
import { MetadataStatus } from '../../../uniq-factory/uniq-factory-metadata.interface';
import {
  IdCardPanelDispatcherService,
  IdCardPropertyTabs,
} from '../../id-card-content/id-card-panel-dispatcher/providers/id-card-panel-dispatcher.service';

import { UniqPreviewAdapter, UniqPreviewEntity } from './uniq-preview.adapter';

@UntilDestroy()
@Component({
  selector: 'ultra-uniq-preview',
  styleUrls: ['./uniq-preview.component.scss'],
  templateUrl: './uniq-preview.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqPreviewComponent implements OnInit, OnChanges {
  private _uniq: IUniq;
  private _uniqFactory: IUniqFactory;
  @Input() set uniqFactory(value: IUniqFactory) {
    this._uniqFactory = value;
    this.uniqPreviewEntity = UniqPreviewAdapter.transformUniqFactory(value);
  }
  @Input() forSale = false;
  @Input() isUniqOwned = false;
  @Input() status: UniqStatus;
  @Input() type: 'uniq' | 'uniqfactory' = 'uniq';
  @Input() showTradability = true;
  @Input() showTransferability = true;
  @Input() showAvailability = true;
  @Input() showStatusIcons = true;
  @Input() showPaymentButton = true;
  @Input() set uniq(value: IUniq) {
    this._uniq = value;
    this._uniqFactory = value.factory;
    this.uniqPreviewEntity = UniqPreviewAdapter.transformUniq(value);
  }

  private _shouldValidateMetadata = false;
  @Input() set shouldValidateMetadata(value: boolean) {
    this._shouldValidateMetadata =
      value &&
      this.uniqPreviewEntity.info?.status &&
      this.uniqPreviewEntity.info?.status !== this.metadataStatusEnum.VALID;
  }
  get shouldValidateMetadata(): boolean {
    return this._shouldValidateMetadata;
  }

  get isRestricted(): boolean {
    return !!this.uniqPreviewEntity?.info?.restricted;
  }

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.status === UniqStatus.PENDING_OWNED || this.status === UniqStatus.PENDING_ON_SALE;
  }

  @Output() buy = new EventEmitter<IUniqFactory>();

  properties = IdCardPropertyTabs;

  uniqPreviewEntity: UniqPreviewEntity;

  private headerClick: Subject<IdCardPropertyTabs> = new Subject();

  readonly metadataStatusEnum = MetadataStatus;

  readonly actions = IdCardAction;

  @HostListener('click', ['$event'])
  public previewClick(event: Event) {
    if (this.isDisabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (!this.panelDispatcher) {
      return;
    }
    this.headerClick.next(IdCardPropertyTabs.OVERVIEW);
  }

  constructor(
    @Optional() private panelDispatcher: IdCardPanelDispatcherService,
    private changeDetectorRef: ChangeDetectorRef,
    private mixpanelService: MixpanelService,
    private uniqPriceService: UniqPriceService,
  ) {
    this.headerClick
      .asObservable()
      .pipe(throttleTime(100), untilDestroyed(this))
      .subscribe((event) => {
        this.panelDispatcher.next(event);
      });
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges() {
    if (this.uniqFactory?.quantity?.quantityLeft && Number(this.uniqFactory.quantity.quantityLeft) < 0) {
      this.uniqFactory.quantity.quantityLeft = '0';
    }
  }

  handlePropertyQuickAccess(property: IdCardPropertyTabs): void {
    if (!this.panelDispatcher) {
      return;
    }
    this.headerClick.next(property);
  }

  isSellAvailable() {
    return (
      this.uniqPreviewEntity?.tradingPeriod &&
      this.uniqPreviewEntity?.tradingPeriod?.resaleAvailability &&
      !this.uniqPreviewEntity.tradingPeriod.resaleAvailability?.isLocked
    );
  }

  isSetForSale(): boolean {
    return this.status === UniqStatus.ON_SALE;
  }

  handleMarketPlaceTabAccess() {
    this.handlePropertyQuickAccess(this.properties.MARKETPLACE);
  }

  onSell(): void {
    this.handleMarketPlaceTabAccess();
    this.mixpanelService.track(MixpanelEvent.UNIQ_SALE_INITIATE, getUniqEventData(this._uniq));
  }

  onWithdraw(): void {
    this.handleMarketPlaceTabAccess();
    this.uniqPriceService
      .getUniqPrice(
        this._uniq.saleInfo?.price?.amount,
        this._uniq.factory?.tradability?.minimumResellPrice?.amount,
        this._uniq.factory?.tradability?.minimumResellPrice?.currency?.code,
      )
      .pipe(take(1))
      .subscribe((uniqPrice) => {
        this.mixpanelService.track(MixpanelEvent.UNIQ_CANCEL_SALE_INITIATE, getUniqEventData(this._uniq, uniqPrice));
      });
  }

  onBuyClick(event: MouseEvent): void {
    event.stopPropagation();
    this.buy.emit(this._uniqFactory);
  }

  get isBuyAvailable(): boolean {
    return (
      !this.uniqPreviewEntity.tradingPeriod ||
      !this.uniqPreviewEntity.tradingPeriod.endDate ||
      new Date(this.uniqPreviewEntity.tradingPeriod.endDate).getTime() > Date.now()
    );
  }
}
