<div class="select position-relative">
  @if (label) {
    <div class="select__lbl text-uppercase text-white-alpha-5 ls-one-and-half f-size-10 mb-2 d-flex">
      {{ label }}
      @if (labelIconClass) {
        <i class="icon ms-auto" [ngClass]="labelIconClass" [ngbTooltip]="labelTooltip"></i>
      }
      <ng-template #labelTooltip>
        {{ labelTooltipText }}
      </ng-template>
    </div>
  }

  <div
    class="dropdown-dark dropdown-select"
    ngbDropdown
    [ultraAutotestAttribute]="dataId"
    [placement]="placement"
    (openChange)="scrollTo()">
    <div
      class="dropdown-select__btn select-{{ size }} rounded ls-quoter d-flex justify-content-between align-items-center"
      [class.disabled]="disabled"
      [class.dropdown-select__btn--collapsible]="responsiveCollapse"
      ultraAutotestAttribute="dropdown-toggle-button"
      ngbDropdownToggle>
      @if (prefixIcon) {
        <i class="icon {{ prefixIcon }}"></i>
      }
      <span class="ms-1">{{ (selected && selected.lbl) || placeholder }}</span>
      <i class="icon icon-chevron-sm ms-auto"></i>
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <div ultraScroll class="scroll-container" (scrollYReachEnd)="onScrollEnd()">
        @for (option of options; track option) {
          <div
            class="dropdown-item dropdown-select__item w-100"
            [class.dropdown-select__item-disabled]="
              option?.disable || (disableSelectedOption() && option.value === selected?.value)
            "
            (click)="markAsSelected(option)"
            [ultraSelectOption]="option.value"
            [ultraAutotestAttribute]="option.dataId">
            {{ option.lbl }}
          </div>
        }
      </div>
    </div>
  </div>

  @if (disabled) {
    <div class="backdrop position-absolute w-100 h-100"></div>
  }
</div>
