import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { UniqPriceService } from './uniq-price.service';

@Pipe({
  name: 'uniqPrice',
  standalone: true,
})
export class UniqPricePipe implements PipeTransform {
  constructor(private uniqPriceService: UniqPriceService) {}
  transform(resellPrice: string, minResellPrice: string, minResellPriceCurrency: string): Observable<number> {
    return this.uniqPriceService.getUniqPrice(resellPrice, minResellPrice, minResellPriceCurrency);
  }
}
