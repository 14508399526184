import { gql } from 'apollo-angular';

export const UNIQ_FACTORY_METADATA_VALIDATION_FAILURES = gql`
  {
    code
    context {
      key
      value
    }
    defaultMessage
  }
`;

export const UNIQ_FACTORY_METADATA_RESOURCE = gql`
  {
    contentType
    uri
    integrity {
      type
      hash
    }
  }
`;

export const UNIQ_FACTORY_METADATA_DYNAMIC_RESOURCE = gql`
  {
    contentType
    uris
  }
`;

export const UNIQ_FACTORY_METADATA_MEDIA = gql`
  {
    uri
    contentType
  }
`;

export const UNIQ_FACTORY_METADATA_MEDIAS = gql`
  {
    product ${UNIQ_FACTORY_METADATA_MEDIA}
    square ${UNIQ_FACTORY_METADATA_MEDIA}
    hero ${UNIQ_FACTORY_METADATA_MEDIA}
    gallery ${UNIQ_FACTORY_METADATA_MEDIA}
  }
`;

export const UNIQ_FACTORY_METADATA_CONTENT_FIELDS = gql`
  {
    name
    subName
    description
    medias ${UNIQ_FACTORY_METADATA_MEDIAS}
    properties
  }
`;

export const UNIQ_FACTORY_METADATA_FIELDS = gql`
  {
    ... on UniqFactoryProcessingMetadata {
      ...uniqFactoryProcessingMetadataFields
    }
    ... on UniqFactoryValidMetadata {
      ...uniqFactoryValidMetadataFields
    }
    ... on UniqFactoryInvalidMetadata {
      ...uniqFactoryInvalidMetadataFields
    }
    ... on UniqFactoryRestrictedMetadata {
      ...uniqFactoryRestrictedMetadataFields
    }
  }
`;

export const UNIQ_METADATA_CONTENT_FIELDS = gql`
  {
    name
    subName
    description
    medias ${UNIQ_FACTORY_METADATA_MEDIAS}
    properties
    attributes {
      key
      value
      descriptor {
        dynamic
        type
        name
        description
      }
    }
    dynamicAttributes ${UNIQ_FACTORY_METADATA_DYNAMIC_RESOURCE}
    resources {
      key
      value ${UNIQ_FACTORY_METADATA_RESOURCE}
    }
    dynamicResources {
      key
      value ${UNIQ_FACTORY_METADATA_DYNAMIC_RESOURCE}
    }
  }
`;

export const UNIQ_METADATA_FIELDS = gql`
  {
    ... on UniqProcessingMetadata {
      ...uniqProcessingMetadataFields
    }
    ... on UniqValidMetadata {
      ...uniqValidMetadataFields
    }
    ... on UniqInvalidMetadata {
      ...uniqInvalidMetadataFields
    }
    ... on UniqRestrictedMetadata {
      ...uniqRestrictedMetadataFields
    }
  }
`;

export const UNIQ_FACTORY_METADATA_FRAGMENTS = gql`
  fragment uniqFactoryProcessingMetadataFields on UniqFactoryProcessingMetadata {
    id
    restricted
    status
    source ${UNIQ_FACTORY_METADATA_RESOURCE}
  }

  fragment uniqFactoryValidMetadataFields on UniqFactoryValidMetadata {
    id
    restricted
    status
    source ${UNIQ_FACTORY_METADATA_RESOURCE}
    persistentSource ${UNIQ_FACTORY_METADATA_RESOURCE}
    content ${UNIQ_FACTORY_METADATA_CONTENT_FIELDS}
  }

  fragment uniqFactoryInvalidMetadataFields on UniqFactoryInvalidMetadata {
    id
    restricted
    status
    source ${UNIQ_FACTORY_METADATA_RESOURCE}
    validationFailures ${UNIQ_FACTORY_METADATA_VALIDATION_FAILURES}
  }

  fragment uniqFactoryRestrictedMetadataFields on UniqFactoryRestrictedMetadata {
    id
    status
    restricted
  }
`;

export const UNIQ_METADATA_FRAGMENTS = gql`
  fragment uniqProcessingMetadataFields on UniqProcessingMetadata {
    restricted
    status
    source ${UNIQ_FACTORY_METADATA_RESOURCE}
  }

  fragment uniqValidMetadataFields on UniqValidMetadata {
    restricted
    status
    source ${UNIQ_FACTORY_METADATA_RESOURCE}
    persistentSource ${UNIQ_FACTORY_METADATA_RESOURCE}
    content ${UNIQ_METADATA_CONTENT_FIELDS}
  }

  fragment uniqInvalidMetadataFields on UniqInvalidMetadata {
    restricted
    status
    source ${UNIQ_FACTORY_METADATA_RESOURCE}
    validationFailures ${UNIQ_FACTORY_METADATA_VALIDATION_FAILURES}
  }
  fragment uniqRestrictedMetadataFields on UniqRestrictedMetadata {
    status
    restricted
  }
`;
