import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IQuantityBars } from '../../modules/id-card-content/id-card-quantity/helpers';

@Component({
  selector: 'ultra-vertical-progress-sticks',
  templateUrl: './vertical-progress-sticks.component.html',
  styleUrls: ['./vertical-progress-sticks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalProgressSticksComponent {
  readonly totalBarsCount: number = 20;

  @Input() barsToFill: IQuantityBars;

  @Input() barOrIconPrimaryColor: string;

  @Input() barSecondaryColor: string;

  @Input() displayIcon = false;

  @Input() barAltColor = '';

  getBarColorToFill(index: number): string {
    if (this.barAltColor) {
      return this.barAltColor;
    }
    if (index < this.barsToFill.barsUnfilled) {
      return '';
    }
    if (index < this.barsToFill.barsUnfilled + this.barsToFill.middleBarsToFill) {
      return this.barSecondaryColor;
    }
    if (index < Object.values(this.barsToFill).reduce((initial, next) => initial + next)) {
      return this.barOrIconPrimaryColor;
    }
  }
}
