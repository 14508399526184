import { formatCurrency } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ultraCurrency',
})
export class UltraCurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: number | string, digitsInfo = '1.2-8'): string | null {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    if (value == null || Number.isNaN(value)) {
      return null;
    }
    return formatCurrency(num, this.locale, 'ᕫ ', null, digitsInfo);
  }
}
