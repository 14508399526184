<div
  class="w-100 h-100"
  [ngbTooltip]="tooltip"
  [disableTooltip]="pending || tooltipConfig.disableTooltip"
  tooltipClass="copy-tooltip"
  [autoClose]="false"
  [placement]="tooltipConfig.placement"
  [animation]="true"
  triggers="hover"
  ultraAutotestAttribute="toggle-button"
>
  <button
    (click)="toggleSelected()"
    [disabled]="pending"
    class="btn btn-transparent col-12 p-1 text-transform-none w-100 h-100"
    type="button"
  >
    <i
      class="icon {{ isSelected ? iconConfig.activeIcon : iconConfig.inactiveIcon }}"
      [ultraAutotestAttribute]="isSelected ? 'toggle-true' : 'toggle-false'"
    ></i>
  </button>
</div>
<ng-template #tooltip>{{ tooltipText }}</ng-template>
