import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';

import { APP_CONFIG_PROVIDER, EnvironmentConfig } from '@ultra/environment';

import { ErrorsModule } from './errors/errors.module';
import { GraphQLClientModule } from './graphql-client/graphql-client.module';
import { RolesGroupGuard } from './guards';
import { DisabledRouteGuard } from './guards/disabled-route.guard';
import { UltraAdminGuard } from './guards/ultra-admin.guard';
import { DeviceType } from './models/user/interfaces/device-data.interface';
import { DEVICE_TYPE } from './providers/device-type.provider';
import { AuthModule } from './services/auth/auth.module';
import { ClientFeatureService } from './services/client-feature.service';
import { GraphQlNetworkErrorInterceptor } from './services/graphql-network-error-interceptor';
import { InternalServerErrorInterceptorService } from './services/internal-server-error.interceptor';
import { MIXPANEL_LOGIN_PROVIDER } from './services/mixpanel/mixpanel-login';
import { OAuthApiRestService } from './services/oauth-api-rest.service';
import { RequestIdInterceptor } from './services/request-id.interceptor';
import { UserFacadeService } from './stores/user/user-facade.service';

const PROVIDERS = [
  APP_CONFIG_PROVIDER,
  MIXPANEL_LOGIN_PROVIDER,
  {
    provide: DEVICE_TYPE,
    useFactory: () => (inject(ClientFeatureService).isInClient ? DeviceType.ULTRA_CLIENT : undefined),
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestIdInterceptor,
    multi: true,
  },
  EnvironmentConfig,
  OAuthApiRestService,
  UltraAdminGuard,
  DisabledRouteGuard,
  RolesGroupGuard,
];

@NgModule({
  imports: [
    GraphQLClientModule,
    ErrorsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    NgbModalModule,
    FormsModule,
    AuthModule.forRoot(),
  ],
  declarations: [],
  exports: [],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([GraphQlNetworkErrorInterceptor, InternalServerErrorInterceptorService]),
    ),
    ...PROVIDERS,
  ],
})
export class CoreModule {
  public static withUserStore(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...PROVIDERS, UserFacadeService],
    };
  }
}
