import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGraphQLService } from '@ultra/core/graphql';
import { CurrencyType } from '@ultra/core/models';

import { setUniqFactoryCreator, setUniqFactoryCreatorPaginatable } from '../../helpers';
import {
  Paginatable,
  Pagination,
  StaticCollection,
  StaticCollectionBody,
  StaticCollectionName,
  StaticCollectionType,
} from '../../models';
import { UniqFactoriesRecommendation } from '../components/uniq-factories-recommendation/uniq-factories-recommendation.interface';
import { UniqFactoryGqlConfig } from '../graphql/uniq-factory-gql.config';
import { IUniqFactory, UniqFactoryFilterInput } from '../uniq-factory.interface';

@Injectable({
  providedIn: 'root',
})
export class UniqFactoryGqlService {
  constructor(private apiService: ApiGraphQLService) {}

  getUniqFactories(filter: UniqFactoryFilterInput, pagination: Pagination): Observable<Paginatable<IUniqFactory[]>> {
    return this.apiService
      .getQuery(
        UniqFactoryGqlConfig.UNIQ_FACTORIES,
        { filter, pagination },
        { apolloClient: 'tokenfactories', cache: false },
      )
      .pipe(
        map((result) => result.data.uniqFactory.list),
        map(setUniqFactoryCreatorPaginatable),
      );
  }

  getUniqFactoryById(id: string): Observable<IUniqFactory> {
    return this.apiService
      .getQuery(UniqFactoryGqlConfig.UNIQ_FACTORY_BY_ID, { id }, { apolloClient: 'tokenfactories', cache: false })
      .pipe(
        map((result) => result.data.uniqFactory.find),
        map(setUniqFactoryCreator),
      );
  }

  getUniqFactoryRecommendation(
    name: StaticCollectionName,
    currencyCodes = [CurrencyType.USD],
  ): Observable<UniqFactoriesRecommendation> {
    return this.apiService
      .getQuery(
        UniqFactoryGqlConfig.UNIQ_FACTORIES_RECOMMENDATION,
        { name, currencyCodes },
        { apolloClient: 'tokenfactories', cache: false },
      )
      .pipe(
        map((result) => result.data?.uniqFactory?.recommendation),
        map((recommendation: UniqFactoriesRecommendation) => ({
          ...recommendation,
          uniqFactories: recommendation.uniqFactories.map(setUniqFactoryCreator),
        })),
      );
  }

  getStaticCollectionByName(type: StaticCollectionType, name: StaticCollectionName): Observable<StaticCollection> {
    return this.apiService
      .getQuery(
        UniqFactoryGqlConfig.UNIQ_FACTORIES_STATIC_COLLECTION,
        { type, name },
        { apolloClient: 'tokenfactories', cache: false },
      )
      .pipe(map((result) => result?.data?.staticCollection?.getByName));
  }

  createStaticCollection(body: StaticCollectionBody, _id?: string): Observable<StaticCollection> {
    return this.apiService
      .createMutation(UniqFactoryGqlConfig.CREATE_STATIC_COLLECTION, { _id, body }, { apolloClient: 'tokenfactories' })
      .pipe(map((result) => result?.data?.staticCollection?.create));
  }

  updateStaticCollection(_id: string, _v: number, body: StaticCollectionBody): Observable<StaticCollection> {
    return this.apiService
      .createMutation(
        UniqFactoryGqlConfig.UPDATE_STATIC_COLLECTION,
        { _id, _v, body },
        { apolloClient: 'tokenfactories' },
      )
      .pipe(map((result) => result?.data?.staticCollection?.update));
  }
}
