import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WINDOW } from '@ultra/core/providers';

export interface PlayerVars {
  videoId?: string;
  playerVars: {
    enablejsapi?: string;
    controls?: string;
    disablekb?: string;
    fs?: string;
    iv_load_policy?: string;
    loop?: string;
    modestbranding?: string;
    autoplay?: string;
    rel?: string;
    showinfo?: string;
    mute?: string;
    origin?: string;
  };
}

let youtubeApiAdded = false;

@Injectable({
  providedIn: 'root',
})
export class YoutubePlayerService {
  private domain: string = this.window.location.host;
  private defaultParams: PlayerVars = {
    playerVars: {
      enablejsapi: '1',
      controls: '0',
      disablekb: '1',
      fs: '0',
      iv_load_policy: '3',
      loop: '1',
      modestbranding: '1',
      autoplay: '1',
      rel: '0',
      showinfo: '0',
      origin: this.domain,
    },
  };

  private mediaCarouselParams = {
    enablejsapi: '1',
    disablekb: '1',
    iv_load_policy: '3',
    loop: '1',
    autoplay: '0',
    rel: '0',
    origin: this.domain,
    modestbranding: '1',
  };

  private autoplayParams: PlayerVars = {
    playerVars: {
      controls: '1',
      fs: '1',
      loop: '0',
      showinfo: '0',
      mute: '1',
      origin: this.domain,
    },
  };

  constructor(@Inject(WINDOW) private window: Window) {}

  public loadYoutubeApi(): void {
    if (youtubeApiAdded !== true) {
      youtubeApiAdded = true;
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }

  onReady(): Observable<any> {
    return new Observable((observer) => {
      if (window['YT']) {
        observer.next(window['YT']);
        observer.complete();
        return;
      }

      this.loadYoutubeApi();
      window['onYouTubeIframeAPIReady'] = () => {
        observer.next(window['YT']);
        observer.complete();
      };
    });
  }

  getDefaultParams(): PlayerVars {
    return this.defaultParams;
  }

  getAutoplayParams(): PlayerVars {
    return this.autoplayParams;
  }

  public getMediaCarouselParams() {
    return this.mediaCarouselParams;
  }
}
