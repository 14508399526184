import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconSize } from '@ultraos/design-system';

@Component({
  selector: 'ultra-metadata-image-manager',
  templateUrl: './metadata-image-manager.component.html',
  styleUrls: ['./metadata-image-manager.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataImageManagerComponent {
  @Input() size: IconSize = 24;
}
