import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ITokenPrice } from '../../../../models';

@Component({
  selector: 'ultra-game-price-discount',
  templateUrl: './game-price-discount.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GamePriceDiscountComponent {
  @Input()
  price: ITokenPrice;
}
