import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import iassign from 'immutable-assign';

import { ImmutableSelector } from '@ultra/core/helpers';

import { Pagination } from '../../../../lib/models/base-interfaces';
import { IDFuseState } from '../interfaces/dfuse-state.interface';

import * as DFuseActions from './dfuse.actions';

const DFUSE_INITIAL_STATE: IDFuseState = {
  tokens: [],
  totalTokens: 0,
};

@State<IDFuseState>({
  name: 'dFuse',
  defaults: DFUSE_INITIAL_STATE,
})
@Injectable()
export class DFuseState {
  @Selector()
  @ImmutableSelector()
  public static getTotalTokens(state: IDFuseState) {
    return state.totalTokens;
  }

  @Selector()
  public static getTokens(pagination: Pagination = null) {
    return createSelector([DFuseState], ({ dFuse }: { dFuse: IDFuseState }) => {
      if (!pagination) {
        return dFuse.tokens;
      }
      return dFuse.tokens.slice(pagination.skip, pagination.skip + pagination.limit);
    });
  }

  @Action([DFuseActions.SetTokens])
  public setTokens({ setState, getState }: StateContext<IDFuseState>, { payload }: DFuseActions.SetTokens): void {
    const newState = iassign(getState(), (state) => ({ ...state, tokens: payload, totalTokens: payload.length }));
    setState(newState);
  }
}
