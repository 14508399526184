<div class="d-flex align-items-center position-relative wrapper extended-details-layout">
  <div class="token-type d-inline-flex rounded align-items-center pe-2 me-1">
    <ultra-uniq-type-icon [type]="idCardEntity?.content?.contentType?.toLowerCase() || config.iconType" />
    <span class="token-type__label text-capitalize caption-regular" ultraAutotestAttribute="token-type">
      {{ idCardEntity?.content?.contentType | lowercase }}
    </span>
  </div>

  @if (!config.hideCopyToClipboard && !idCardEntity?.metadata?.restricted) {
    <ultra-copy-to-clipboard
      class="ms-1 me-2"
      i18n-copiedTooltipText
      copiedTooltipText="Link copied!"
      tooltipPlacement="top right left"
      tooltipVariant="success"
      mainIconType="icon-share-sm"
      hoverIconType="icon-share-sm"
      variant="transparent-light-static"
      [text]="productUrl"
      [showInitialTooltip]="false"
      [showLabel]="false" />
  }

  @if (!config.hideAdvancedDetails) {
    <span
      class="title f-size-12 text-white content-ellipsis d-inline-block me-1"
      [class.ms-1]="config.hideCopyToClipboard">
      {{ idCardEntity?.info?.title | ellipsis: ellipsisLimitChars }}
    </span>
    @if (idCardEntity?.info?.subTitle) {
      <span class="text-white-alpha-3 mx-1">&bull;</span>
      <span class="title f-size-14 text-white-alpha-5 content-ellipsis d-inline-block">
        {{ idCardEntity?.info?.subTitle | ellipsis: ellipsisLimitChars }}
      </span>
    }
    @if (idCardEntity?.creator?.name || companyName) {
      <span class="text-white-alpha-3 mx-1">&bull;</span>
      <span class="company-name-prefix f-size-14 text-white-alpha-3 me-1">by</span>
      <span class="company-name f-size-14 text-white-alpha-5 content-ellipsis" ultraAutotestAttribute="token-creator">
        {{ idCardEntity?.creator?.name || companyName | ellipsis: ellipsisLimitChars }}
      </span>
      @if (idCardEntity?.creator?.verified) {
        <div class="check-wrap ms-1">
          <i class="icon icon-check-circle-outline bg-white-alpha-5"></i>
        </div>
      }
    }
  }
</div>

@if (!config.hideCloseIcon) {
  <button
    class="btn-preview-close rounded d-flex"
    ultraAutotestAttribute="id-card-close-button"
    (click)="closed.emit()">
    <i class="icon icon-cross-16 bg-white"></i>
  </button>
}
