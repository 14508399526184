import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { DomEvent } from './dom-events-bus.intaface';

/**
 * This service is to be provided at root level and to be used
 * to pass any kind of events (messages) to the global scope, and these
 * events (messages) could be handled anywhere in the app, thus facilitating
 * one of the ways of communication between components;
 *
 * Usually you'd want to use it when you have a deeply nested component/directive
 * which could emit events (like click/change/focus) and you want to avoid
 * multiple emits of this event via @Output() until the event is received
 * by the desired parent, as well as input drilling via @Input();
 *
 * A use case of that deep nest could happen when you have a lot of
 * granulated, small, presentational layers of components (Smart/Dumb pattern);
 *
 * The communication via event bus is possible in both directions
 * (parent -> child, child -> parent , sibling <-> sibling);
 *
 * This service doesn't have to be stateful and therefore new subscribers
 * should start receiving values from the point in time when they subscribe.
 *
 * TODO: Rename to smth more meaningful like "GlobalMessageStreamService"
 */
@Injectable({
  providedIn: 'root',
})
export class DomEventsBusService extends Subject<DomEvent<any>> {
  constructor() {
    super();
  }
}
