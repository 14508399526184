import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationMessageComponent } from './notification-message.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NotificationMessageComponent],
  exports: [NotificationMessageComponent],
})
export class NotificationMessageModule {}
