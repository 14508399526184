import { gql } from 'apollo-angular';

export const ACCOUNT_STATUS_SUBSCRIPTION = gql`
  subscription userAccountStatus {
    userAccountStatus {
      id
      blockchainId
      blockchainStatus
      deviceSyncStatus
    }
  }
`;
