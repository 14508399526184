export enum ConfigFieldsType {
  os = 'os version',
  processor = 'processor',
  memory = 'memory',
  graphics = 'graphic card',
  minimumResolution = 'minimum resolution',
  soundCard = 'sound card',
  storage = 'storage',
}

export interface IConfigField {
  name: ConfigFieldsType;
  id: string;
  suffix?: string;
}
