import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { getImgBySize, imgBySizeDictionary, ImgSize, isValidImageSize } from './lazy-image.helper';

@Component({
  selector: 'ultra-lazy-image',
  templateUrl: './lazy-image.component.html',
  styleUrls: ['./lazy-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LazyImageComponent implements OnInit {
  @Input() styleClass: string | string[] = null;
  @Input() defaultImage: string;
  @Input() imageSize: ImgSize;
  @Input() imageAlt = '';
  @Input() imgClassName = '';
  @Input() srcset = '';
  @Input() fading = true;
  @Input() videoPreview: boolean;
  @Input() showPlaceHolder = true;
  @Input() loading = 'lazy';
  @Input() dataId: string;
  @Input() shouldUseObjectFitContain = false;

  public placeholder: string = imgBySizeDictionary.none;
  public fullWidth = false;

  constructor(public el: ElementRef) {}

  ngOnInit(): void {
    // Todo remove this hotFix when logic of component will be fixed
    if (this.showPlaceHolder) {
      this.placeholder = isValidImageSize(this.imageSize)
        ? imgBySizeDictionary[this.imageSize]
        : getImgBySize(this.el.nativeElement.clientWidth, this.el.nativeElement.clientHeight);

      if (!this.defaultImage) {
        this.fullWidth = true;
        this.defaultImage = this.placeholder;
      }
    }
  }

  onImgError(event) {
    this.loading = this.srcset = '';
    this.fullWidth = true;
    event.target.src = this.placeholder;
  }

  onImgLoaded(): void {
    this.showPlaceHolder = false;
  }
}
