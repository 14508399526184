import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { IQuantityAvailability } from '../../../models/uniq/interfaces/uniq.interface';

import {
  IdCardQuantityDisplayHelper,
  IQuantityBars,
  IUniqQuantityStatus,
  UniqQuantityStatusColorEnum,
  UniqQuantityStatusEnum,
} from './helpers/id-card-quantity-display.helper';

@Component({
  selector: 'ultra-id-card-quantity',
  templateUrl: './id-card-quantity.component.html',
  styleUrls: ['./id-card-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardQuantityComponent implements OnChanges {
  @Input() quantityAvailability: IQuantityAvailability;
  quantityTitle: string;
  quantityStatusColor: string;
  uniqQuantityStatusEnum = UniqQuantityStatusEnum;
  uniqQuantityStatusColorEnum = UniqQuantityStatusColorEnum;
  quantityStatus: IUniqQuantityStatus;
  barsToFill: IQuantityBars;
  private readonly totalBarsCount: number = 20;

  ngOnChanges() {
    this.quantityStatus = this.quantityAvailability
      ? IdCardQuantityDisplayHelper.getQuantityStatus(
          this.quantityAvailability.quantityLeft,
          this.quantityAvailability.maxCirculatingSupply
        )
      : UniqQuantityStatusEnum.NOT_AVAILABLE;
    this.quantityStatusColor = IdCardQuantityDisplayHelper.getStatusColor(this.quantityStatus);
    this.quantityTitle = IdCardQuantityDisplayHelper.getQuantityTitle(
      this.quantityStatus,
      this.quantityAvailability.maxCirculatingSupply
    );
    this.barsToFill = IdCardQuantityDisplayHelper.getBarRatiosToFill(this.quantityAvailability, this.totalBarsCount);
  }
}
