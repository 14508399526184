import { gql } from 'apollo-angular';

import { PAGINATION_GQL } from '@ultra/core/graphql';

export const TOKEN_FACTORIES_CONTENT_TYPES_QUERY = gql`
  query ($pagination: PaginationInput = null) {
    tokenFactoryContentType {
      list(pagination: $pagination) {
        data {
          id
          code
          index
          name
          icon
          shortDescription
          description
          validationSchema {
            price {
              minValue
              maxValue
            }
            content {
              gameContentsMinLength
              gameContentsMaxLength
              gameBuilds {
                type
              }
              gameComponents {
                elements {
                  type
                  minCount
                  maxCount
                }
              }
            }
          }
        }
        ${PAGINATION_GQL}
      }
    }
  }
`;
