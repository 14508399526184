import { IEnvironment } from './interfaces/environment.interface';

// These are default configuration which are common to every env
// To override or complete the set of environment vars, use the assets/config JSONs
// This allows the pipelines to replace assets at deployment time (instead of using Angular build time)
export const environmentShared: IEnvironment = {
  client_id: 'ultraWebapp',
  ssnPathApi: 'https://api.snxd.com/',
  gtm: 'GTM-567SL4F',
  oauthClientId: 'ultraWebapp',
};
