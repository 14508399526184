import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { errorCodes } from '../helpers/error-codes.helper';
import {
  ErrorResponse,
  IErrorResponse,
  IFormattedErrorResponse,
  IMsgFErrorResponse,
  IMsgTErrorResponse,
} from '../interfaces/error-response.interface';

@Injectable()
export class ErrorHandlerApiService {
  /**
   * REST server can return 2 type of errors: technical and functional, they have different response format
   */
  handle(errorResponse: IErrorResponse): Observable<IFormattedErrorResponse> {
    const messages = errorResponse ? errorResponse.message || errorResponse.errorMessage || errorResponse : []; // || errorResponse.error
    const messagesArr = Array.isArray(messages) ? messages : [messages];

    return messagesArr.length > 0 && Object.prototype.hasOwnProperty.call(messagesArr[0], 'constraints')
      ? this.handleTechnicalErrors(<IMsgTErrorResponse[]>messagesArr)
      : this.formatFunctionalErrors(<IMsgFErrorResponse[] | string[]>messagesArr, errorResponse.statusCode);
  }

  private handleTechnicalErrors(messages: IMsgTErrorResponse[]): Observable<IFormattedErrorResponse> {
    const errors: { [key: string]: string[] } = {};

    messages.forEach((message) => {
      const key = message['property'];
      const constraints = message['constraints'];

      errors[key] = [];

      for (const error in constraints) {
        if (Object.prototype.hasOwnProperty.call(constraints, error)) {
          errors[key].push(constraints[error]);
        }
      }
    });

    return throwError(errors);
  }

  private formatFunctionalErrors(
    messages: IMsgFErrorResponse[] | string[],
    statusCode: number,
  ): Observable<IFormattedErrorResponse> {
    const formattedMsg =
      typeof messages[0] === 'string'
        ? <IMsgFErrorResponse[]>[{ message: messages[0], code: 0, statusCode }]
        : <IMsgFErrorResponse[]>messages;

    return this.handleFunctionalErrors(formattedMsg);
  }

  private handleFunctionalErrors(messages: IMsgFErrorResponse[]): Observable<IFormattedErrorResponse> {
    const errorMessages: { [key: string]: string[] } = {};
    const codes = {
      statusCodes: [],
    };
    const result = messages.reduce((acc, currentValue) => {
      const key =
        currentValue.code || currentValue.code === 0
          ? errorCodes[currentValue.code]
          : errorCodes[currentValue.statusCode];
      const value = currentValue.message ? currentValue.message : ErrorResponse[currentValue.error];
      errorMessages[key] ? errorMessages[key].push(value) : (errorMessages[key] = [value]);
      acc.statusCodes.push(currentValue.statusCode);
      return { ...errorMessages, ...acc };
    }, codes);
    return throwError(result);
  }
}
