import { GridConfig } from './responsive-layout.interfaces';

export const Large: GridConfig = {
  name: 'large',
  breakpoints: {
    minWidth: 1638,
    maxWidth: 9999,
  },
  pagination: {
    perPage: 6,
    pages: 3,
  },
  margin: 64,
  gutter: 32,
  units: {
    collapsed: 4,
    expanded: 14,
  },
};

export const Medium: GridConfig = {
  name: 'medium',
  breakpoints: {
    minWidth: 1208,
    maxWidth: 1637,
  },
  pagination: {
    perPage: 4,
    pages: 4,
  },
  margin: 48,
  gutter: 32,
  units: {
    collapsed: 6,
    expanded: 20,
  },
};

export const Small: GridConfig = {
  name: 'small',
  breakpoints: {
    minWidth: 0,
    maxWidth: 1207,
  },
  pagination: {
    perPage: 3,
    pages: 6,
  },
  margin: 32,
  gutter: 24,
  units: {
    collapsed: 8,
    expanded: 24,
  },
};

export const gridSetup: GridConfig[] = [Large, Medium, Small];
