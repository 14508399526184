import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { IGeofencingDisplayEntity } from '../../../models/geofencing/interfaces/geofencing-display-entity.intefrace';
import { IGeofencingEntity } from '../../../models/geofencing/interfaces/geofencing-entity.interfaces';
import { IdCardAction } from '../../../modules/id-card-content/id-card-actions/id-card-actions.model';
import { IdCardEntity } from '../adapters/id-card.adapter';
import { IdCardPanelTransformerHelper } from '../id-card-panel-dispatcher/helpers/id-card-panel-transformer.helper';
import {
  IdCardPanelDispatcherService,
  IdCardPropertyTabs,
  PropertyTabs,
} from '../id-card-panel-dispatcher/providers/id-card-panel-dispatcher.service';

/**
 * Show Uniq's properties.
 *
 * Properties are: Identity, Quantity, Tradability, Transferability and Geofencing.
 *
 * For non tokenized games we don't show quantity since it is always available.
 */
@UntilDestroy()
@Component({
  selector: 'ultra-id-card-properties',
  templateUrl: './id-card-properties.component.html',
  styleUrls: ['./id-card-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardPropertiesComponent implements AfterViewInit, OnDestroy {
  @Input() idCardEntity: IdCardEntity;
  @Input() countries: IGeofencingEntity[];
  @Input() regions: IGeofencingEntity[];
  @Input() showTradability = false;
  @Input() showTransferability = false;
  @Input() showGeofencing = true;

  @Input() isFlatActionCard = false;
  /**
   * Default property tab to show when the card is expanded.
   */
  @Input() activeTab: PropertyTabs = IdCardPropertyTabs.QUANTITY;
  private cdr = inject(ChangeDetectorRef);

  currentUserCountry: IGeofencingDisplayEntity;

  readonly actions = IdCardAction;

  constructor(@Optional() private panelDispatcher: IdCardPanelDispatcherService) {}

  ngAfterViewInit() {
    this.panelDispatcher?.pipe(filter(Boolean), untilDestroyed(this)).subscribe((dispatchedInfo) => {
      const { propertyName } = IdCardPanelTransformerHelper.transform(dispatchedInfo);

      if (propertyName) {
        this.activeTab = propertyName;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.panelDispatcher?.next(null);
  }

  getAvailabilityClass(): string {
    if (!this.isGeofencingSet()) {
      return null;
    }
    return this.currentUserCountry?.available ? 'available' : 'not-available';
  }

  isGeofencingSet(): boolean {
    return !!this.idCardEntity?.geofencing?.length;
  }

  handleUserCurrentCountry(country: IGeofencingDisplayEntity) {
    this.currentUserCountry = country;
  }

  changeTab(tabName: PropertyTabs) {
    this.activeTab = tabName;
    this.cdr.detectChanges();
  }

  isTabActive(tabName: PropertyTabs): boolean {
    return this.activeTab === tabName;
  }
}
