import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BackgroundSkeletonComponent } from '../../../skeleton/background-skeleton/background-skeleton.component';

@Component({
  selector: 'ultra-sync-attributes-skeleton',
  templateUrl: './sync-attributes-skeleton.component.html',
  styleUrls: ['./sync-attributes-skeleton.component.scss'],
  standalone: true,
  imports: [BackgroundSkeletonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncAttributesSkeletonComponent {
  readonly rows = Array(7);
}
