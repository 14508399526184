import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[ultraAutotestAttribute]',
  standalone: true,
})
export class AutotestAttributeDirective {
  private _dataId: string;

  @Input()
  set ultraAutotestAttribute(value: string) {
    this._dataId = value;
  }

  @HostBinding('attr.data-id')
  get dataId() {
    return this._dataId;
  }
}
