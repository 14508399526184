import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ErrorCodeType } from './helpers/error-code-type.enum';
import { UltraosApiService } from './ultraos';

export const InternalServerErrorInterceptorService: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const ultraosApiService = inject(UltraosApiService);
  return next(request).pipe(
    tap((res: HttpResponse<any>) => {
      if (res.body && res.body.errors) {
        for (const error of res.body.errors) {
          if (error.extensions.code === ErrorCodeType.internalServerError) {
            ultraosApiService.toaster('Error message', 'Request Timeout, please retry again later', 'danger');
          }
        }
      }
    }),
  );
};
