import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { DatePipeFormat } from '../../../../models/date/date-types.enum';
import { GameDetail } from '../../../../models/game/models/game-detail.model';
import { GameDetailRatingsModalComponent } from '../../../../modules/game-detail/components/game-detail-detail/game-detail-ratings-modal/game-detail-ratings-modal.component';
import { ContentSkeletonTheme } from '../../../../modules/skeleton/content-skeleton/content-skeleton-theme.enum';

import { GameDetailTagsModalComponent } from './game-detail-tags-modal/game-detail-tags-modal.component';

@Component({
  selector: 'ultra-game-detail-detail',
  templateUrl: './game-detail-detail.component.html',
  styleUrls: ['./game-detail-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameDetailDetailComponent implements OnDestroy {
  DatePipeFormat = DatePipeFormat;

  @Input()
  game: GameDetail;
  readonly placeholderTheme = ContentSkeletonTheme;

  @Input() isRouteChange = false;

  private tagsModalRef: NgbModalRef;
  private ratingsModalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  ngOnDestroy() {
    this.tagsModalRef?.close();
    this.ratingsModalRef?.close();
  }

  openRatingsModal(): void {
    this.ratingsModalRef = this.modalService.open(GameDetailRatingsModalComponent, {
      centered: true,
      windowClass: 'medium-dark-modal age-rating-modal',
      backdropClass: 'blur',
    });

    this.ratingsModalRef.componentInstance.ageRating = this.game.ageRatingSorted;
  }

  openTagsModal(): void {
    this.tagsModalRef = this.modalService.open(GameDetailTagsModalComponent, {
      centered: true,
      windowClass: 'medium-dark-modal tags-modal',
      backdropClass: 'blur',
    });

    this.tagsModalRef.componentInstance.tags = this.game.tags;
  }
}
