export const INPUT_INVALID_TYPES = [
  'button',
  'checkbox',
  'file',
  'hidden',
  'image',
  'radio',
  'range',
  'reset',
  'submit',
];

export function getInputUnsupportedTypeError(type: string): Error {
  return Error(`Input type "${type}" isn't supported by ultraInput.`);
}
