import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutosizeDirective } from './autosize/autosize.directive';
import { UltraErrorDirective } from './error/error.directive';
import { ErrorStateMatcher } from './error-state-matcher/error-state-matcher';
import { ShowOnTouchedErrorStateMatcherService } from './error-state-matcher/show-on-touched-error-state-matcher.service';
import { UltraFormFieldComponent } from './form-field/form-field.component';
import { UltraHintDirective } from './hint/hint.directive';
import { UltraInputDirective } from './input/input.directive';
import { LabelDirective } from './label/label.directive';
import { UltraPrefixDirective } from './prefix/prefix.directive';
import { UltraSuffixDirective } from './suffix/suffix.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    UltraInputDirective,
    UltraFormFieldComponent,
    LabelDirective,
    UltraPrefixDirective,
    UltraSuffixDirective,
    UltraErrorDirective,
    UltraHintDirective,
    AutosizeDirective,
  ],
  exports: [
    UltraInputDirective,
    UltraFormFieldComponent,
    LabelDirective,
    UltraPrefixDirective,
    UltraSuffixDirective,
    UltraErrorDirective,
    UltraHintDirective,
    AutosizeDirective,
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnTouchedErrorStateMatcherService,
    },
  ],
})
export class UltraFormsModule {}
