import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  videos: HTMLVideoElement[] = [];
  readonly pause$: Observable<HTMLVideoElement>;

  private readonly pauseSubject$ = new Subject<HTMLVideoElement>();
  private readonly playCB = this.onPlay.bind(this);

  constructor() {
    this.pause$ = this.pauseSubject$.asObservable();
  }

  register(video: HTMLVideoElement): void {
    if (!this.videos.includes(video)) {
      this.videos.push(video);
      video.addEventListener('play', this.playCB);
    }
  }

  remove(video: HTMLVideoElement): void {
    video.removeEventListener('play', this.playCB);
    const index = this.videos.indexOf(video);
    if (index >= 0) {
      this.videos.splice(index, 1);
    }
  }

  onPlay(event: Event): void {
    // not visible videos should not put on pause the rest videos
    if (window.getComputedStyle(event.target as HTMLVideoElement).display === 'none') {
      return;
    }
    this.videos.forEach((video) => {
      if (video !== event.target) {
        video.pause();
        this.pauseSubject$.next(video);
      }
    });
  }
}
