export const featuresMock: any[] = [
  {
    name: 'Controller support',
    cssClass: 'gamepad-16',
  },
  {
    name: 'VR Support',
    cssClass: 'vr-cardboard-sm',
  },
  {
    name: 'Cloud save',
    cssClass: 'cloud-upload',
  },
  {
    name: 'Leaderboard',
    cssClass: 'podium',
  },
  {
    name: 'Achievements',
    cssClass: 'award-sm',
  },
  {
    name: 'Ultra stats system',
    cssClass: 'chart',
  },
  {
    name: 'Secure anti-cheat',
    cssClass: 'shield-sm',
  },
  {
    name: 'Beta',
    cssClass: 'beta-sm',
  },
  {
    name: 'Early-access',
    cssClass: 'clock',
  },
  {
    name: 'Workshop mods',
    cssClass: 'workshop-sm',
  },
  {
    name: 'Ingame Items',
    cssClass: 'sword',
  },
  {
    name: 'Resalable',
    cssClass: 'tradability-sm',
  },
  {
    name: 'Transferable',
    cssClass: 'transferability-sm',
  },
  {
    name: 'Limited quantity',
    cssClass: 'round-medal',
  },
];
