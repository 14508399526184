import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamelCaseToTextPipe } from './camel-case-to-text/camel-case-to-text.pipe';
import { DownloadSpeedPipe } from './download-speed/download-speed.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { GamePreviewPipe } from './game-preview/game-preview.pipe';
import { GenreIconPipe } from './genre-icon/genre-icon.pipe';
import { HyphenTextPipe } from './hyphen-text/hyphen-text.pipe';
import { ImgSourceReplacePipe } from './img-source-replace/img-source-replace.pipe';
import { MinutesToHoursPipe } from './minutesToHours/minutes-to-hours.pipe';
import { PricePipe } from './price/price.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { UltraCurrencyPipe } from './ultra-currency/ultra-currency.pipe';
import { VersionPipe } from './version/version.pipe';

@NgModule({
  imports: [CommonModule, DownloadSpeedPipe, EllipsisPipe],
  declarations: [
    VersionPipe,
    UltraCurrencyPipe,
    GenreIconPipe,
    GamePreviewPipe,
    HyphenTextPipe,
    PricePipe,
    ReplacePipe,
    CamelCaseToTextPipe,
    MinutesToHoursPipe,
    ImgSourceReplacePipe,
  ],
  exports: [
    VersionPipe,
    EllipsisPipe,
    UltraCurrencyPipe,
    GenreIconPipe,
    GamePreviewPipe,
    DownloadSpeedPipe,
    HyphenTextPipe,
    PricePipe,
    ReplacePipe,
    CamelCaseToTextPipe,
    MinutesToHoursPipe,
    ImgSourceReplacePipe,
  ],
  providers: [CurrencyPipe],
})
export class SharedPipesModule {}
