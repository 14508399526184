@if (backgroundImage) {
  <div class="background-overlay">
    @if (!isRouteChange) {
      <ultra-lazy-image
        class="background-overlay__image"
        [defaultImage]="backgroundImage"
        styleClass="full-width"
        imageSize="l"></ultra-lazy-image>
    }
  </div>
}

<div class="content">
  <ng-content></ng-content>
</div>
