import { ITokenFactory } from '../../token-factory/interfaces/token-factory.interface';
import { TokenStatus } from '../enums/token-status.enum';
import { IBuyableTokenFactory } from '../interfaces/buyable-token-factory.interface';
import { ICompanyData } from '../interfaces/company.interface';
import { IGame } from '../interfaces/game.interface';
import { IGameVersion } from '../interfaces/game-version.interface';

import { GameVersionModel } from './game-version.model';

export class DefaultGame implements IGame {
  id: string;
  blockchainId?: string;
  storePageStatus?: string;
  lastStorePageStatusEditComment?: string;
  publisher: ICompanyData;
  liveGame?: IGameVersion | GameVersionModel;
  developmentGame?: IGameVersion | GameVersionModel;
  tokenFactories?: ITokenFactory[];
  tokenStatus?: TokenStatus;
  buyableTokenFactory?: IBuyableTokenFactory;
  ownedTokenFactories?: ITokenFactory[];

  constructor(game: IGame) {
    this.id = game.id;
    this.blockchainId = game.blockchainId;
    this.publisher = game.publisher;
    this.liveGame = new GameVersionModel(game.liveGame || {});
    this.developmentGame = new GameVersionModel(game.developmentGame || {});
    this.tokenFactories = [...(game.tokenFactories || [])];
    this.tokenStatus = game.tokenStatus;
    this.buyableTokenFactory = game.buyableTokenFactory;
    this.ownedTokenFactories = game.ownedTokenFactories;
    this.storePageStatus = game.storePageStatus;
    this.lastStorePageStatusEditComment = game.lastStorePageStatusEditComment;
  }
}
