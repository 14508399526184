<div class="modal-header d-flex justify-content-between align-items-center">
  <h5 class="modal-title f-size-16 mb-0" i18n>Rating</h5>

  <button type="button" class="btn-close" (click)="activeModal.dismiss()">
    <i class="icon icon-cross-sm"></i>
  </button>
</div>

<div class="modal-body p-0">
  <ultra-tab-group
    headerPosition="above"
    indicatorSize="thick"
    tabHeaderClass="tab-header-no-padding"
    [selectedIndex]="activeTab"
    [fixedHeight]="false"
    [padContent]="false"
    [sameTabWidth]="true"
    (tabChange)="tabChanged($event.index)">
    @for (ratingCategory of ageRating; track ratingCategory) {
      <ultra-tab>
        <ng-template ultraTabLabel>
          <span class="s2-bold">{{ ratingCategory.country }}</span>
        </ng-template>
        <ng-template ultraTabContent>
          <div class="scroll-container py-3 ps-3" ultraScroll>
            <div class="overline-medium text-uppercase text-white-alpha-5 mt-2 mb-2">Rating</div>
            <img
              [src]="ratingCategory.category.image"
              [alt]="ratingCategory.category.name"
              [title]="ratingCategory.category.name"
              class="rating-category-image" />
            @if (ratingCategory.descriptors?.length || ratingCategory.descriptorsDescription) {
              <div class="overline-medium text-uppercase text-white-alpha-5 mt-4 mb-2">Content Descriptors</div>
              @if (ratingCategory.descriptors?.length) {
                <div class="d-flex flex-wrap">
                  @for (descriptor of ratingCategory.descriptors; track descriptor) {
                    <img
                      [src]="descriptor.image"
                      [alt]="descriptor.name"
                      [title]="descriptor.name"
                      class="rating-descriptor-image me-2 mb-2" />
                  }
                </div>
              }
              @if (ratingCategory.descriptorsDescription) {
                <div class="b2 my-3">
                  {{ ratingCategory.descriptorsDescription }}
                </div>
              }
            }
            <div class="overline-medium text-uppercase text-white-alpha-5 mt-4 mb-2">Rating Summary</div>
            <div class="b2-regular">
              {{ categoriesDescription[ratingCategory.name][ratingCategory.category.categoryId] || '' }}
            </div>
          </div>
        </ng-template>
      </ultra-tab>
    }
  </ultra-tab-group>
</div>
