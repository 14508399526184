<div class="content-wrap">
  <ultra-background-skeleton class="content-item d-flex-center"></ultra-background-skeleton>

  <div class="content-item d-flex flex-column">
    <div class="attributes-table">
      <div class="attributes-table__caption mb-2 overline-medium text-white-alpha-7">Attributes</div>
      @for (row of rows; track row; let i = $index) {
        <ultra-background-skeleton
          [delay]="i * 3"
          class="content-item content-item--row d-flex-center"></ultra-background-skeleton>
      }
    </div>
  </div>
</div>
