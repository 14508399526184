import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export enum IdCardPropertyTabs {
  OVERVIEW = 'overview',
  ATTRIBUTES = 'attributes',
  MEDIAS = 'medias',
  QUANTITY = 'quantity',
  TRADABILITY = 'tradability',
  TRANSFERABILITY = 'transferability',
  GEOFENCING = 'geofencing',
  IDENTITY = 'identity',
  DATA = 'data',
  MARKETPLACE = 'marketplace',
}

export type PropertyTabs = `${IdCardPropertyTabs}`;

export interface ITokenDispatcherState {
  defaultTabIndex: number;
}

export interface IdCardPanelDispatch {
  tabNumber: number | null;
  propertyName?: PropertyTabs;
}

@Injectable()
export class IdCardPanelDispatcherService extends ReplaySubject<IdCardPropertyTabs | null> {
  constructor() {
    super(1);
  }
}
