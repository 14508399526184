import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';

@Injectable()
export class DownloadOffchainDataService {
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  getJsonDataSource(uri: string): Observable<SafeUrl> {
    return this.http.get(uri).pipe(
      map((jsonResponse: JSON) => {
        return this.domSanitizer.sanitize(
          SecurityContext.URL,
          'data:text/json;charset=UTF-8,' + encodeURIComponent(JSON.stringify(jsonResponse))
        );
      })
    );
  }
}
