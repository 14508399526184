@if (showOrnament) {
  <i class="icon icon-ornament position-relative"></i>
}
@if (isTypeGame) {
  <i
    class="icon icon-gamepad-{{ size }} center position-absolute opacity-{{ opacity }}"
    [class.has-ornament]="showOrnament"></i>
}
@if (isTypeDLC) {
  <i
    class="icon icon-dlc-{{ size }} center position-absolute opacity-{{ opacity }}"
    [class.has-ornament]="showOrnament"></i>
}
@if (isTypeCollectible) {
  <i
    class="icon icon-collectible-{{ size }} center position-absolute opacity-{{ opacity }}"
    [class.has-ornament]="showOrnament"></i>
}
@if (isNotUniq) {
  <i class="icon icon-block-16 center position-absolute bg-white-{{ opacity }}" [class.has-ornament]="showOrnament"></i>
}
