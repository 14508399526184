import { ChangeDetectionStrategy, Component, Inject, Input, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import { WINDOW } from '@ultra/core/providers';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';

import { IdCardEntity } from '../adapters';

import { DownloadOffchainDataService } from './download-offchain-data.service';

enum OffchainDataFormat {
  ZIP = 'application/zip',
  JSON = 'application/json',
}

@UntilDestroy()
@Component({
  selector: 'ultra-id-card-data',
  templateUrl: './id-card-data.component.html',
  styleUrls: ['./id-card-data.component.scss'],
  providers: [DownloadOffchainDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCardDataComponent {
  @Input() idCardEntity: IdCardEntity;

  constructor(
    private downloadMetadataService: DownloadOffchainDataService,
    @Inject(APP_CONFIG) public readonly environment: IEnvironment,
    private renderer: Renderer2,
    @Inject(WINDOW) private window: Window
  ) {}

  openLastTransactionInBlockExplorer(): void {
    this.window.open(this.idCardEntity.blockchainLineage.explorerUri, '_blank');
  }

  downloadOffchainMetadata(): void {
    if ('persistentSource' in this.idCardEntity.metadata) {
      const uri = this.idCardEntity.metadata.persistentSource?.uri;
      const contentType = this.idCardEntity.metadata.persistentSource?.contentType;

      if (!uri) {
        return;
      }

      if (contentType === OffchainDataFormat.ZIP) {
        this.window.location.href = uri;
      } else if (contentType === OffchainDataFormat.JSON) {
        const fileName: string = uri.split('/').pop();

        this.downloadMetadataService
          .getJsonDataSource(uri)
          .pipe(take(1), untilDestroyed(this))
          .subscribe((dataSource: string) => this.activateDownloadLink(dataSource, fileName));
      }
    }
  }

  private activateDownloadLink(url: string, fileName: string): void {
    const link: HTMLAnchorElement = this.renderer.createElement('a');

    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:' + url);
    link.setAttribute('download', fileName);

    link.click();
    link.remove();
  }
}
